import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography/Typography';
import { getPreviewSrcForImage, parseCorrectImage } from '@src/components/forms/OfferImagesUpload';
import ReactMarkdown from 'react-markdown';
import { FormattedMessage } from 'react-intl';
import { MarkdownWithStyles } from '@src/components/layouts/ui';

const PreviewWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const PreviewContent = styled.div`
  margin-top: 1.6em;
`;

const PreviewContentTitle = styled.h2`
  font-size: 2.8em;
`;

const PreviewContentText = styled.div`
  margin-top: 1.6em;
`;

const PreviewImage = styled.img`
  width: 100%;
`;

const PreviewBreadText = styled.p`
  font-size: 1.6em;
  line-height: 1.3;
`;

const PreviewLink = styled.span`
  background-color: green;
  border-radius: 4px;
  color: white;
  text-transform: uppercase;
  font-size: 1.6em;
  padding: ${1.4 / 1.6}em ${2 / 1.6}em;
  text-align: center;
  display: inline-block;
`;

const FeedPreview = styled.div`
  ${PreviewWrapper} {
    justify-content: space-between;
  }

  ${PreviewImage} {
    width: 47.5%;
    height: 47.5%;
  }
  
  ${PreviewContent} {
    width: 47.5%;
    margin-top: 0;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
  }
  
  ${PreviewLink} {
    margin-top: 1.6em;
  }
`;

const FullPreview = styled.div`

`;

const FullPreviewWrapper = styled.div`
  
`;

const FullPreviewContentText = styled.div`
  margin-bottom: 1.6em;
`;

interface PreviewProps {
  title?: string;
  ingress?: string;
  content?: string;
  author?: Partner;
  buttonTitle?: string;
  images?: OfferImages;
}

class OfferPreview extends Component<PreviewProps> {
  render() {
    const {
      title,
      ingress,
      content,
      author,
      images,
      buttonTitle,
    } = this.props;

    const listImage = parseCorrectImage('ListImage', images);
    const contentImage = parseCorrectImage('ContentImage', images);

    return (
      <Fragment>
        <Typography component="h2" style={{margin: '0 0 1em 0', fontWeight: 'bold'}}>
          <FormattedMessage id={'scenes.offers.modal.generic.previewListTitle'} />
        </Typography>
        <FeedPreview>
          <PreviewWrapper>
            <PreviewImage src={getPreviewSrcForImage('ListImage', listImage)} />
            <PreviewContent>
              <div>
                <PreviewContentTitle>
                  {title}
                </PreviewContentTitle>
                <PreviewContentText>
                  <PreviewBreadText>
                    {ingress}
                  </PreviewBreadText>
                </PreviewContentText>
              </div>
              <PreviewLink>
                {author ? `${author.name} >` : '>'}
              </PreviewLink>
            </PreviewContent>
          </PreviewWrapper>
        </FeedPreview>

        <Typography component="h2" style={{margin: '2em 0 1em 0', fontWeight: 'bold'}}>
          <FormattedMessage id={'scenes.offers.modal.generic.previewContentTitle'} />
        </Typography>
        <FullPreview>
          <FullPreviewWrapper>
            <PreviewImage src={getPreviewSrcForImage('ContentImage', contentImage)} />
            <PreviewContent>
              <FullPreviewContentText>
                <MarkdownWithStyles>
                  <ReactMarkdown source={content ? content : ''} />
                </MarkdownWithStyles>
              </FullPreviewContentText>
              {buttonTitle &&
                <div style={{textAlign: 'center'}}>
                  <PreviewLink>
                    {buttonTitle}
                  </PreviewLink>
                </div>
              }
            </PreviewContent>
          </FullPreviewWrapper>
        </FullPreview>
      </Fragment>
    );
  }
}

export default OfferPreview;
