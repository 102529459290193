import React, { PureComponent, Fragment } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import HeadHelmet from 'src/components/seo/HeadHelmet';
import {
  ContentHeader,
  ContentHeaderTitle,
  ContentWrap,
} from 'src/components/layouts/ui';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import PersonPicker from '@src/components/persons/PersonPicker';
import { FunctionaryModal } from '@src/components/scenes/federation/functionary/FunctionaryModal';
import * as functionariesActions from '@src/store/functionaries/actions';
import { targetIdParamsChanged } from '@src/utils/storeUtils';

interface Props extends WrappedComponentProps {
  functionariesTargetIdParams: TargetIdParams;
  allFunctionaries: Functionary[];
  targetIdParams: TargetIdParams;
  setTargetIdParams: (params: SetTargetIdParams) => any;
  fetchCondensedFunctionaries: (params: FetchCondensedFunctionaries) => any;
}

type State = {
  modalOpen: boolean;
  personToAdd?: Person;
};

class AddNewFunctionarySceneConnected extends PureComponent<Props, State> {
  state = {
    modalOpen: false,
    personToAdd: undefined,
  };

  UNSAFE_componentWillMount() {
    const {
      functionariesTargetIdParams,
      setTargetIdParams,
    } = this.props;

    if (targetIdParamsChanged(functionariesTargetIdParams, this.props.targetIdParams)) {
      setTargetIdParams({
        resetState: true,
        targetIdParams: this.props.targetIdParams,
      });
    }
  }

  render() {
    const { targetIdParams } = this.props;
    const { modalOpen, personToAdd } = this.state;

    return (
      <Fragment>
        <HeadHelmet titleId={'navigation.organizations'} />
        <ContentWrap>
          <ContentHeader>
            <ContentHeaderTitle>
              <FormattedMessage id={'scenes.functionaries.adding.setAsFunctionary'} />
            </ContentHeaderTitle>

          </ContentHeader>

          <PersonPicker
            targetIdParams={targetIdParams}
            pickerTitle={<FormattedMessage id={'scenes.functionaries.adding.setAsFunctionaryHelpText'} />}
            showAdd={false}
            onPersonPicked={this._handleNewPersonPicked}
            customNode={(<Button
                variant="contained"
                style={{marginLeft: 16, marginBottom: 8}}
                onClick={() => this.setState({
                  modalOpen: true
                })}
            >
              <FormattedMessage id={'scenes.functionaries.adding.newPerson'} />
            </Button>)}
          />

          <FunctionaryModal
            open={modalOpen}
            targetIdParams={this.props.targetIdParams}
            personToSet={personToAdd}
            onClose={() => {
              this.setState({
                modalOpen: false,
                personToAdd: undefined,
              });
            }}
          />
        </ContentWrap>
      </Fragment>
    );
  }

  private _handleNewPersonPicked = (personToAdd: Person) => {
    this.setState({
      personToAdd,
      modalOpen: true,
    });
  }
}

export const AddNewFunctionaryScene = injectIntl(connect((state: StoreState) => ({
  functionariesTargetIdParams: state.functionariesReducer.targetIdParams,
  allFunctionaries: state.functionariesReducer.allFunctionaries,
}), {
  setTargetIdParams: functionariesActions.setTargetIdParams,
  fetchCondensedFunctionaries: functionariesActions.fetchCondensedFunctionaries,
})(AddNewFunctionarySceneConnected));