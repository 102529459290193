import React from 'react';

export type ErrorInputs = {[inputName: string]: boolean};
export type InputValue = any;

type ChildrenArgs = {
  hasErrors: () => boolean;
  errorInputs: ErrorInputs;
  validateThenApply: (fn: () => any) => any;
  validateValues: () => any;
};

interface Props {
  values: {[inputName: string]: InputValue};
  options?: {[inputName: string]: any};
  children: (args: ChildrenArgs) => any;
}

type State = {
  errorInputs: ErrorInputs;
  onClickValidPassed: boolean;
};

class AcceptableScoreInputsValidator extends React.Component<Props, State> {
  state = {
    errorInputs: {},
    onClickValidPassed: false,
  };

  render() {
    return this.props.children({
      hasErrors: this._hasErrors,
      errorInputs: this.state.errorInputs,
      validateThenApply: this._validateThenApply,
      validateValues: this._validateValues,
    });
  }

  private _validateThenApply = (fn: () => any) => {
    this.setState({ onClickValidPassed: true }, this._validateValues.bind(this, () => {
      if (this._hasErrors()) {
        window.console.warn('invalid inputs');
        return window.console.table(this.state.errorInputs);
      }

      return fn();
    }));
  }

  private _validateValues = (fn?: () => any) => {
    const { onClickValidPassed } = this.state;
    const { values } = this.props;
    let errorInputs: ErrorInputs = {};

    if (onClickValidPassed) {
      Object.keys(values).forEach((valueKey: string) => {
        if (valueKey === 'listOfHoles') {
          console.log('validation');
          let numOfHoleKey = 'numberOfHoles';
          let numberOfHoles = values[numOfHoleKey];
          console.log(numberOfHoles);
          console.log(values[valueKey]);
          for (let i = 0; i < numberOfHoles; i++) {
            let key = 'listOfHoles.' + i + '.strokeIndex';
            if (!values[valueKey][i] || !values[valueKey][i].strokeIndex ||
                !this._validateStrokeIndexValue(values[valueKey][i].strokeIndex)) {
              errorInputs[key] = true;
            }

            key = 'listOfHoles.' + i + '.par';
            if (!values[valueKey][i] || !values[valueKey][i].par ||
                values[valueKey][i].par < 3 || values[valueKey][i].par > 6) {
              errorInputs[key] = true;
            }
          }
        } else if (valueKey === 'slopeRating') {
          let value = parseInt(values[valueKey], 10);
          if (values[valueKey] === undefined || values[valueKey] === '' || values[valueKey] === null) {
            errorInputs[valueKey] = true;
          } else if (value < 55 || value > 155) {
            errorInputs[valueKey] = true;
          }
        } else if (values[valueKey] === undefined || values[valueKey] === '' || values[valueKey] === null) {
          errorInputs[valueKey] = true;
        }

      });
      if (!this._validateStrokes()) {
        let key = 'playedHoles';
        errorInputs[key] = true;
      }
    }

    this.setState({ errorInputs }, fn);
  }

  /**
   * At least 9 holes must be played.
   *
   * @private
   */
  private _validateStrokes(): boolean {
    const { values } = this.props;
    let listOfHoleScoresKey = 'listOfHoleScores';
    let list = values[listOfHoleScoresKey];
    // Count played holes
    let result = list.filter(obj => {
      return (obj.strokes !== '0' && obj.strokes !== undefined && obj.strokes !== '');
    });
    return result.length >= 9;
  }

  /**
   * All stroke index values should be between 1 and the number of holes the course has and they should all be unique.
   *
   * @param strokeIndex
   * @private
   */
  private _validateStrokeIndexValue(strokeIndex: number): boolean {
    const { values } = this.props;
    let listOfHolesKey = 'listOfHoles';
    let numOfHoleKey = 'numberOfHoles';
    let numberOfHoles = values[numOfHoleKey];
    let list = values[listOfHolesKey];
    // Check uniqueness
    let result = list.filter(obj => {
      return obj.strokeIndex === strokeIndex;
    });
    if (result.length !== 1) {
      return false;
    }
    // check value interval
    return !(isNaN(strokeIndex) || strokeIndex < 1 || strokeIndex > numberOfHoles);
  }

  private _hasErrors = (): boolean => {
    return Boolean(Object.keys(this.state.errorInputs).length);
  }
}

export default AcceptableScoreInputsValidator;