export const CLUB_FEES_REQUESTING = 'CLUB_FEES_REQUESTING';
export const CLUB_FEES_CHANGE_PAGE = 'CLUB_FEES_CHANGE_PAGE';
export const CLUB_FEES_SUCCESS = 'CLUB_FEES_SUCCESS';
export const CLUB_FEES_ERROR = 'CLUB_FEES_ERROR';

export const CLUB_FEES_SEARCH_CHANGED = 'CLUB_FEES_SEARCH_CHANGED';
export const CLUB_FEES_SEARCH_REQUEST = 'CLUB_FEES_SEARCH_REQUEST';
export const CLUB_FEES_SEARCH_RESET = 'CLUB_FEES_SEARCH_RESET';

export const CLUB_FEES_RESET = 'CLUB_FEES_RESET';

export const CLUB_FEES_SET_INITIAL_STATE = 'CLUB_FEES_SET_INITIAL_STATE';