/**
 * An organization comparison function for sort() with name ASC, email ASC, id ASC ordering.
 *
 * @param a The first organization for comparison
 * @param b The second organization for comparison
 * @returns Number Negative when a < b, zero when a == b, positive when a > b
 */
export const organizationCompare = (a: Organization, b: Organization, l?: string): number => {
  let c: number = !a.name ? !b.name ? 0 : -1 : a.name.localeCompare(b.name, l);
  if (c === 0) {
    c = !a.email ? !b.email ? 0 : -1 : a.email.localeCompare(b.email, l);
    if (c === 0) {
      c = b.id - a.id;
    }
  }
  return c;
};
