import { take, fork, call, put } from 'redux-saga/effects';
import {
  USERS_ERROR,
  USERS_SEARCH_RESET,
} from '@src/store/users/constants';
import {
  USER_ADD_REQUESTING,
  USER_DELETE_REQUESTING,
  USER_EDIT_REQUESTING, USER_GET_REQUESTING, USER_SEND_ACTIVATION_REQUESTING,
} from '@src/store/user/constants';
import { API_ROOT, APIRoute } from '@src/assets/config';
import { AUTH_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { tokenAPIPost } from '@src/utils/storeUtils';

/*
  PUT /admin/1/user/{id}/activationmail
 */
function * sendActivationMailFlow(token: string, userId: number, params: any = {}) {
  try {
    yield call(() => {
      const url = `${API_ROOT}${APIRoute.GET_USER}${userId}/activationmail`;
      return tokenAPIPost(url, token, params);
    });
  } catch (error) {
    window.console.error(error);
    yield put({ type: USERS_ERROR, error });
  }

  return true;
}

/*
  GET|POST|PUT|DELETE /admin/1/user/<id>
 */
const fetchUsersCRUDFlow = SagaFlowFactory.createCRUDFlow({
  apiRoute: APIRoute.USER,
  typePrefix: 'USER',
  resetType: USERS_SEARCH_RESET,
});

function * userWatcher() {
  let token: string = '';

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
      userId,
      params
    } = yield take([
      USER_GET_REQUESTING,
      USER_ADD_REQUESTING,
      USER_EDIT_REQUESTING,
      USER_DELETE_REQUESTING,
      USER_SEND_ACTIVATION_REQUESTING,
    ]);

    switch (type) {
      case USER_GET_REQUESTING:
        yield fork(fetchUsersCRUDFlow, token, 'GET', userId, params);
        break;
      case USER_ADD_REQUESTING:
        yield fork(fetchUsersCRUDFlow, token, 'ADD', null, params);
        break;
      case USER_EDIT_REQUESTING:
        yield fork(fetchUsersCRUDFlow, token, 'EDIT', userId, params);
        break;
      case USER_DELETE_REQUESTING:
        yield fork(fetchUsersCRUDFlow, token, 'DELETE', userId, params);
        break;
      case USER_SEND_ACTIVATION_REQUESTING:
        yield fork(sendActivationMailFlow, token, userId);
        break;
      default:
    }
  }
}

export default userWatcher;