import {
  FEDERATION_ADD_ERROR,
  FEDERATION_ADD_REQUESTING,
  FEDERATION_ADD_SUCCESS,
  FEDERATION_CONTACTS_GET_ERROR,
  FEDERATION_CONTACTS_GET_REQUESTING,
  FEDERATION_CONTACTS_GET_SUCCESS,
  FEDERATION_DELETE_ERROR,
  FEDERATION_DELETE_REQUESTING,
  FEDERATION_DELETE_SUCCESS,
  FEDERATION_EDIT_ERROR,
  FEDERATION_EDIT_REQUESTING,
  FEDERATION_EDIT_SUCCESS,
  FEDERATION_GET_ERROR,
  FEDERATION_GET_REQUESTING,
  FEDERATION_GET_SUCCESS
} from './constants';
import { formDefaultErrorState, formDefaultRequestingState } from '@src/utils/storeUtils';

const initialState: FederationState = {
  federation: undefined,
  requesting: false,
  successful: false,
  error: [],
  contacts: [],
  contactsRequesting: false,
};

export const federationReducer = (state: FederationState = initialState, action: any): FederationState => {
  switch (action.type) {
    case FEDERATION_GET_REQUESTING:
      return {
        ...formDefaultRequestingState(state),
        federation: undefined,
      };

    case FEDERATION_ADD_REQUESTING:
    case FEDERATION_DELETE_REQUESTING:
    case FEDERATION_EDIT_REQUESTING:
      return formDefaultRequestingState(state);

    case FEDERATION_GET_ERROR:
    case FEDERATION_ADD_ERROR:
    case FEDERATION_DELETE_ERROR:
    case FEDERATION_EDIT_ERROR:
      return formDefaultErrorState(state, action);

    case FEDERATION_GET_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
        federation: action.data,
      };

    case FEDERATION_ADD_SUCCESS:
    case FEDERATION_DELETE_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
      };

    case FEDERATION_EDIT_SUCCESS:
      const { data, } = action;
      return {
        ...state,
        requesting: false,
        successful: true,
        federation: data,
      };

    case FEDERATION_CONTACTS_GET_REQUESTING: {
      return {
        ...state,
        contactsRequesting: true,
        contacts: [],
      };
    }

    case FEDERATION_CONTACTS_GET_SUCCESS: {
      return {
        ...state,
        contactsRequesting: false,
        contacts: action.data,
      };
    }

    case FEDERATION_CONTACTS_GET_ERROR: {
      return {
        ...state,
        contactsRequesting: false,
        contacts: [],
      };
    }

    default:
      return state;
  }
};