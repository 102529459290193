import React, { PureComponent, ChangeEvent, RefObject } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as functionaryTitleActions from '@src/store/functionarytitle/actions';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  FormGroup, FormControlLabel
} from '@material-ui/core';
import { renderFormActions } from '@src/components/modals/ui';
import DeleteConfirm, { DeleteConfirmChildren } from '@src/components/headless/DeleteConfirm';
import InputsValidator from '@src/components/forms/InputsValidator';
import RoleScopeGuard from '@src/components/access-control/RoleScopeGuard';

type Props = {
  params: TargetIdParams;
  functionaryTitleId?: number | undefined;
  parentContext?: 'MODAL' | 'PAGE';
  onClose?: () => void;
  functionaryTitleState: FunctionaryTitleState;
  addFunctionaryTitle: (params: FunctionaryTitleAdd) => any;
  editFunctionaryTitle: (params: FunctionaryTitleEdit) => any;
  deleteFunctionaryTitle: (params: FunctionaryTitleDelete) => any;
  actionsContainerRef?: RefObject<HTMLElement>;
};

type State = {
  name: string;
  description: string;
  status: ActivityStatus;
  priority: number;
  globalForClubs: boolean;
  globalForAssociations: boolean;
  globalForFederations: boolean;
};

const initialState = {
  name: '',
  description: '',
  priority: 0,
  status: 'ACTIVE' as ActivityStatus,
  globalForClubs: false,
  globalForAssociations: false,
  globalForFederations: false,
};

class FunctionaryTitleFormConnected extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const {
      functionaryTitleId,
      functionaryTitleState: {
        functionaryTitle,
      }
    } = this.props;

    if (!functionaryTitleId) {
      this.state = initialState;
    } else if (functionaryTitle) {
      this.state = {
        ...functionaryTitle,
      } as any;

    } else {
      this.state = initialState;
    }
    console.log(this.state);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      functionaryTitleId,
      functionaryTitleState: {
        functionaryTitle,
        requesting,
      },
    } = nextProps;

    if (!functionaryTitleId) {
      return; // Do nothing if we are adding new person
    } else if (requesting) {
      return; // Do nothing state is loading
    } else if (functionaryTitle) {
      this.setState({
        ...functionaryTitle as any
      });
    }
  }

  render() {
    const {
      name,
      description,
      status,
      globalForClubs,
      globalForAssociations,
      globalForFederations
    } = this.state;

    const {
      functionaryTitleState: {
        requesting
      },
      functionaryTitleId,
      parentContext,
      actionsContainerRef,
    } = this.props;

    return (
      <InputsValidator values={{ status, name }}>
        {({ errorInputs, validateThenApply, validateValues }) => (
          <>
              <FormGroup row={true} style={{ display: 'flex', justifyContent: 'space-between'}}>
                {(this.props.functionaryTitleId &&
                    <FormControl required={true} margin={'normal'} error={errorInputs.state}>
                <InputLabel htmlFor="state">
                  <FormattedMessage id={'strings.state'}/>
                </InputLabel>
                <Select
                  fullWidth={true}
                  inputProps={{
                    name: 'state',
                    id: 'state',
                  }}
                  value={status}
                  onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                    this.setState({status: event.target.value as ActivityStatus}, validateValues);
                  }}
                >
                  <MenuItem value={'ACTIVE'}><FormattedMessage id={'strings.statusActive'}/></MenuItem>
                  <MenuItem value={'INACTIVE'}><FormattedMessage id={'strings.statusInactive'}/></MenuItem>
                </Select>
              </FormControl>)}

            </FormGroup>

            <TextField
              disabled={requesting}
              required={true}
              error={errorInputs.name}
              fullWidth={true}
              label={<FormattedMessage id={'strings.functionaryTitle'}/>}
              value={name}
              margin="normal"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                this.setState({name: event.currentTarget.value}, validateValues);
              }}
            />

            <TextField
              disabled={requesting}
              fullWidth={true}
              rowsMax={4}
              multiline={true}
              label={<FormattedMessage id={'strings.description'}/>}
              value={description}
              margin="normal"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                this.setState({description: event.currentTarget.value});
              }}
            />
            <RoleScopeGuard>
              {({ isFederationScope }) =>
                  Boolean(isFederationScope) && (
                      <FormGroup>
                        <FormControl>
                          <FormControlLabel
                              control={
                                <Checkbox
                                    checked={globalForClubs}
                                    onChange={this._handleCheckboxChange('globalForClubs')}
                                    value="globalForClubs"
                                />
                              }
                              label={<FormattedMessage id={'scenes.functionaryTitles.form.global.clubs'} />}
                          />
                          <FormControlLabel
                              control={
                                <Checkbox
                                    checked={globalForAssociations}
                                    onChange={this._handleCheckboxChange('globalForAssociations')}
                                    value="globalForAssociations"
                                />
                              }
                              label={<FormattedMessage id={'scenes.functionaryTitles.form.global.associations'} />}
                          />
                          <FormControlLabel
                              control={
                                <Checkbox
                                    checked={globalForFederations}
                                    onChange={this._handleCheckboxChange('globalForFederations')}
                                    value="globalForFederations"
                                />
                              }
                              label={<FormattedMessage id={'scenes.functionaryTitles.form.global.federations'} />}
                          />
                        </FormControl>
                      </FormGroup>
                  )}
            </RoleScopeGuard>

            <DeleteConfirm>
              {({ showConfirm }: DeleteConfirmChildren) => (
                renderFormActions({
                  id: functionaryTitleId,
                  parentContext,
                  onClose: this._handleOnClose,
                  onUpdate: validateThenApply.bind(this, this._handleUpdate),
                  onCreate: validateThenApply.bind(this, this._handleCreate),
                  disabled: requesting,
                  containerRef: actionsContainerRef,
                  onDelete: () => {
                    showConfirm({
                      callback: this._handleDelete,
                    });
                  },
                })
              )}
            </DeleteConfirm>
          </>
        )}
      </InputsValidator>
    );
  }

  private _handleCheckboxChange = name => event => {
    this.setState({ [name]: event.target.checked } as any);
  }

  private _handleOnClose = (): void => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  }

  private _handleDelete = (bool: boolean) => {
    const { deleteFunctionaryTitle, functionaryTitleId } = this.props;
    if (bool && functionaryTitleId) {
      deleteFunctionaryTitle({
        id: functionaryTitleId,
        onComplete: this._handleOnClose,
      });
    }
  }

  private _handleCreate = () => {
    const {
      addFunctionaryTitle,
      params
    } = this.props;

    addFunctionaryTitle({
      ...this._formParams(),
      ...params,
      onComplete: this._handleOnComplete,
    });
  }

  private _handleUpdate = () => {
    const { editFunctionaryTitle } = this.props;
    const {
      functionaryTitleState: {
        functionaryTitle
      }
    } = this.props;

    if (!functionaryTitle) {
      return;
    }

    const params = this._formParams();

    editFunctionaryTitle({
      id: functionaryTitle.id,
      ...params,
      onComplete: this._handleOnComplete,
    });
  }

  private _formParams = () => {
    const {
      name,
      description,
      status,
      priority,
      globalForClubs,
      globalForAssociations,
      globalForFederations
    } = this.state;

    return {
      name,
      description,
      status,
      priority,
      globalForClubs,
      globalForAssociations,
      globalForFederations
    };
  }

  private _handleOnComplete = (result: any): void => {
    if (result.error) {
      window.alert(result.error.message);
    } else {
      this._handleOnClose();
    }
  }
}

const FunctionaryTitleForm = connect((state: any) => ({
  functionaryTitleState: state.functionaryTitleReducer,
}), {
  addFunctionaryTitle: functionaryTitleActions.addFunctionaryTitle,
  editFunctionaryTitle: functionaryTitleActions.editFunctionaryTitle,
  deleteFunctionaryTitle: functionaryTitleActions.deleteFunctionaryTitle,
})(FunctionaryTitleFormConnected);

export {
  FunctionaryTitleForm
};