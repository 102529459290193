import React, { createRef, Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as companyActions from '@src/store/company/actions';
import {
  Dialog,
  DialogTitle,
  DialogContent
} from '@material-ui/core';
import { ModalContentOverlayLoader } from '@src/components/modals/ui';
import { CompanyForm } from '@src/components/scenes/federation/company/components/CompanyForm';

type Props = {
  companyId: number | undefined;
  open: boolean;
  onClose: () => void;
  refetch: () => any;
  getCompany: (params: CompanyGet) => any;
  companyState: CompanyState;
};

class CompanyModalConnected extends Component<Props> {
  actionsContainerRef = createRef<HTMLDivElement>();

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      companyId,
      getCompany
    } = this.props;

    if (nextProps.companyId !== companyId) {
      if (nextProps.companyId) {
        getCompany({
          id: nextProps.companyId,
        });
      }
    }
  }

  render() {
    const {
      open,
      companyId,
      onClose,
      refetch,
      companyState: {
        requesting
      }
    } = this.props;

    return (
      <Dialog
        open={open}
        fullWidth={true}
      >
        <DialogTitle>
          {companyId && <FormattedMessage id={'scenes.golfFederation.companies.modal.edit.title'} />}
          {!companyId && <FormattedMessage id={'scenes.golfFederation.companies.modal.add.title'} />}
        </DialogTitle>

        <DialogContent>
          {requesting && <ModalContentOverlayLoader/>}
          <CompanyForm
            companyId={companyId}
            parentContext={'MODAL'}
            onClose={onClose}
            refetch={refetch}
            actionsContainerRef={this.actionsContainerRef}
          />
        </DialogContent>

        <div ref={this.actionsContainerRef} />
      </Dialog>
    );
  }
}

const CompanyModal = connect((state: any) => ({
  companyState: state.companyReducer
}), {
  getCompany: companyActions.getCompany
})(CompanyModalConnected);

export {
  CompanyModal
};
