import { take, fork } from 'redux-saga/effects';
import {
  COMPANIES_REQUESTING,
  COMPANIES_SUCCESS,
  COMPANIES_ERROR,
  FEDERATION_COMPANIES_SEARCH_RESET,
  FEDERATION_COMPANIES_SEARCH_REQUEST,
} from './constants';
import { APIRoute, DEFAULT_PAGINATION_LIMIT, DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import { AUTH_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { normalizeSearchParams } from '@src/utils/storeUtils';

/*
  GET /admin/1/companies
 */
const fetchCompaniesFlow = SagaFlowFactory.createPagedGETFlow({
  apiRoute: APIRoute.GET_COMPANIES,
  itemType: 'companies',
  successType: COMPANIES_SUCCESS,
  errorType: COMPANIES_ERROR
});

/*
  GET /api/1/companies/search
 */
const fetchOffersSearchFlow = SagaFlowFactory.createPagedGETFlow({
  apiRoute: APIRoute.SEARCH_COMPANIES,
  itemType: 'companies',
  successType: COMPANIES_SUCCESS,
  errorType: COMPANIES_ERROR
});

export default function * companiesWatcher() {
  let token: string = '';

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
      page,
      limit,
      params
    } = yield take([
      COMPANIES_REQUESTING,
      FEDERATION_COMPANIES_SEARCH_REQUEST,
      FEDERATION_COMPANIES_SEARCH_RESET,
    ]);

    switch (type) {
      case COMPANIES_REQUESTING:
        yield fork(fetchCompaniesFlow, token, page, limit, params);
        break;

      case FEDERATION_COMPANIES_SEARCH_REQUEST:
        yield fork(fetchOffersSearchFlow, token, page, limit, {...normalizeSearchParams(params)});
        break;
      case FEDERATION_COMPANIES_SEARCH_RESET:
        yield fork(fetchCompaniesFlow, token, DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_LIMIT, params);
        break;
      default:
    }
  }
}