import React, { PureComponent } from 'react';
import FunctionaryTitlesScene from '@src/components/scenes/functionary-titles/FunctionaryTitlesScene';
import { RouteComponentProps, withRouter } from 'react-router';

class ClubFunctionaryTitles extends PureComponent<{} & RouteComponentProps<any>> {
  render() {
    const {
      match: {
        params: {
          clubId
        }
      }
    } = this.props;

    return (
      <FunctionaryTitlesScene targetIdParams={{clubId}}/>
    );
  }
}

export default withRouter(ClubFunctionaryTitles);