export const ORGANIZATION_GET_REQUESTING = 'ORGANIZATION_GET_REQUESTING ';
export const ORGANIZATION_GET_SUCCESS = 'ORGANIZATION_GET_SUCCESS';
export const ORGANIZATION_GET_ERROR = 'ORGANIZATION_GET_ERROR';

export const ORGANIZATION_ADD_REQUESTING = 'ORGANIZATION_ADD_REQUESTING';
export const ORGANIZATION_ADD_SUCCESS = 'ORGANIZATION_ADD_SUCCESS';
export const ORGANIZATION_ADD_ERROR = 'ORGANIZATION_ADD_ERROR';

export const ORGANIZATION_EDIT_REQUESTING = 'ORGANIZATION_EDIT_REQUESTING';
export const ORGANIZATION_EDIT_SUCCESS = 'ORGANIZATION_EDIT_SUCCESS';
export const ORGANIZATION_EDIT_ERROR = 'ORGANIZATION_EDIT_ERROR';

export const ORGANIZATION_DELETE_REQUESTING = 'ORGANIZATION_DELETE_REQUESTING';
export const ORGANIZATION_DELETE_SUCCESS = 'ORGANIZATION_DELETE_SUCCESS';
export const ORGANIZATION_DELETE_ERROR = 'ORGANIZATION_DELETE_ERROR';

export const ORGANIZATION_PERSONS_REQUESTING = 'ORGANIZATION_PERSONS_REQUESTING';
export const ORGANIZATION_PERSONS_SUCCESS = 'ORGANIZATION_PERSONS_SUCCESS';
export const ORGANIZATION_PERSONS_ERROR = 'ORGANIZATION_PERSONS_ERROR';

export const ORGANIZATION_PERSON_ATTRIBUTE_SET = 'ORGANIZATION_PERSON_ATTRIBUTE_SET';