import {
  OFFERS_CHANGE_PAGE,
  OFFERS_REQUESTING,
  OFFERS_SEARCH_CHANGED,
  OFFERS_SEARCH_REQUESTING,
  OFFERS_SEARCH_RESET,
  OFFERS_SET_INITIAL_STATE,
} from './constants';
import { DEFAULT_PAGINATION_LIMIT } from '@src/assets/config';

export const fetchOffers = ({
  page,
  limit = DEFAULT_PAGINATION_LIMIT
}: TablePaginationAction) => {
  return {
    type: OFFERS_REQUESTING,
    page,
    limit
  };
};

export const changePage = ({
  page
}: ChangePageAction) => {
  return {
    type: OFFERS_CHANGE_PAGE,
    page,
  };
};

export const searchChanged = (search: OffersSearch) => {
  return {
    type: OFFERS_SEARCH_CHANGED,
    search
  };
};

export const fetchSearch = ({
  page,
  limit = DEFAULT_PAGINATION_LIMIT,
  params
}: SearchPaginationAction) => {
  return {
    type: OFFERS_SEARCH_REQUESTING,
    page,
    limit,
    params,
  };
};

export const resetSearch = () => {
  return {
    type: OFFERS_SEARCH_RESET
  };
};

export const setOffersInitialState = () => {
  return {
    type: OFFERS_SET_INITIAL_STATE,
  };
};