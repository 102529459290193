import { tokenAPIPost } from '@src/utils/storeUtils';
import { API_ROOT, APIRoute } from '@src/assets/config';
import { call, put } from 'redux-saga/effects';
import {
  UPLOAD_HIO_EXCEL_SUCCESS,
  UPLOAD_HIO_EXCEL_ERROR
} from '@src/store/club/constants';
import { UploadHIOExcelAction } from '@src/store/club/actions';
import { select } from '@redux-saga/core/effects';
import { userAuthToken } from '@src/store/auth/selectors';
import { handleOnFunction } from '@src/utils/utils';
import { enqueueNotification } from '@src/store/notifications/actions';

export function * uploadHIOExcelFlow({
  excel,
  columnHeaderClubId,
  columnHeaderMemberNo,
  onComplete,
}: UploadHIOExcelAction) {
  const params = {
    __multiPart: true,
    excel,
    columnHeaderClubId,
    columnHeaderMemberNo,
  };

  try {
    const token = yield select(userAuthToken);
    const data = yield call(() => {
      const url = `${API_ROOT}${APIRoute.HIO_EXCEL_IMPORT}`;
      return tokenAPIPost(url, token, params);
    });

    if (data) {
      handleOnFunction(onComplete, { data });
      yield put({ type: UPLOAD_HIO_EXCEL_SUCCESS });
    }

  } catch (error) {
    yield put(enqueueNotification(error));
    handleOnFunction(onComplete, { error });
    yield put({ type: UPLOAD_HIO_EXCEL_ERROR, error });
  }

  return true;
}
