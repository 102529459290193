import React, { PureComponent } from 'react';
import Organizations from '@src/components/scenes/organization/Organizations';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps } from 'react-intl';

interface Props extends RouteComponentProps<any>, WrappedComponentProps {
  locale: AppLocale;
}

class ClubOrganizations extends PureComponent<Props & RouteComponentProps<any>> {
  render() {
    const {
      match: {
        params: {
          clubId,
        },
      },
      locale: {
        appLanguage: {
          collation
        }
      }
    } = this.props;

    return (
      <Organizations
        targetIdParams={{ clubId, collation }}
      />
    );
  }
}

export default injectIntl(withRouter(connect((state: any) => ({
  locale: state.locale
}), null)(ClubOrganizations)));
