import React, { Component } from 'react';
import FederationVendors from '@src/components/scenes/federation/FederationVendors';

class AdminFederationVendors extends Component {
  render() {
    return (
      <FederationVendors />
    );
  }
}

export {
  AdminFederationVendors,
};