import { Action } from 'redux';
import {
  NOTIFICATION_ENQUEUE,
  NOTIFICATION_REMOVE,
} from './constants';
import { OptionsObject } from 'notistack';
import { tryToCapitalize } from '@src/utils/utils';

export interface NotificationEnqueueAction extends Action {
  type: 'NOTIFICATION_ENQUEUE';
  notification: SnackNotification;
}
export const enqueueNotification = (error: any, options: OptionsObject = {}) => {
  let message = '';

  if (error && error.message) {
    message = error.message;
  } else if (typeof error === 'string') {
    message = tryToCapitalize(error);
  } else {
    message = error.toString();
  }

  return {
    type: NOTIFICATION_ENQUEUE,
    notification: {
      key: new Date().getTime() + Math.random(),
      message: message,
      options: { variant: 'error', ...options },
    },
  };
};

export interface NotificationRemoveAction extends Action {
  type: 'NOTIFICATION_REMOVE';
  key: number;
}
export const removeNotification = (key: number): NotificationRemoveAction => ({
  type: NOTIFICATION_REMOVE,
  key,
});
