import React from 'react';
import { Theme, withStyles } from '@material-ui/core';

const anchorStyle = (theme: Theme) => ({
  display: `block`,
  color: `#333`,
  fontSize: `1.6em`,
  textDecoration: `none`,
  position: `relative`,
  alignSelf: `center`,
  padding: `${.8 / 1.6}em 0`,
  '&.active': {
    color: theme.palette.primary.main,
  },
});

const styles = (theme: Theme) => ({
  nav: {
    padding: '1em 0 0 2em',
    '@global': {
      'a': {
        ...anchorStyle(theme),
      },
      '.summary-label': {
        ...anchorStyle(theme),
      },
      'div': {
        '&.active .summary-label': {
          color: theme.palette.primary.main,
        },
      },
    }
  },
});

export const Nav = withStyles(styles as any)((props: any) => (<nav className={props.classes.nav} {...props} />));