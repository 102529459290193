import {
  CLUB_GREENCARDS_REQUESTING,
  CLUB_GREENCARDS_SUCCESS,
  CLUB_GREENCARDS_ERROR,
  CLUB_GREENCARDS_CHANGE_PAGE,
  CLUB_GREENCARDS_RESET,
  CLUB_GREENCARDS_SET_INITIAL_STATE,
  CLUB_GREENCARDS_SEARCH_CHANGED,
  CLUB_GREENCARDS_SEARCH_REQUESTING,
  CLUB_GREENCARDS_SEARCH_RESET,
} from './constants';
import { SHARED_DEFAULT_REDUCER_STATE } from '@src/assets/config';
import {
  formDefaultErrorState,
  formDefaultPagedState,
  formDefaultRequestingState,
  fromDefaultPageChangeState,
  optimisticPagedObjectUpdate, searchStateChangedReducerHelper
} from '@src/utils/storeUtils';
import { CLUB_FEE_EDIT_SUCCESS } from '@src/store/clubFee/constants';
import { CLUB_GREENCARD_EDIT_SUCCESS } from '@src/store/clubGreencard/constants';

const initialState: ClubGreenCardsState = {
  ...SHARED_DEFAULT_REDUCER_STATE,
  pagedGreenCards: {},
  search: {
    searchTerm: '',
    startDate: null,
    endDate: null,
    status: undefined
  }
};

export const clubGreenCardsReducer = (state: ClubGreenCardsState = initialState, action: any): ClubGreenCardsState => {
  switch (action.type) {
    case CLUB_GREENCARDS_REQUESTING:
      return formDefaultRequestingState(state);

    case CLUB_GREENCARDS_SUCCESS:
      return formDefaultPagedState(state, action, 'pagedGreenCards');

    case CLUB_GREENCARDS_CHANGE_PAGE:
      return fromDefaultPageChangeState(state, action);

    case CLUB_GREENCARDS_ERROR:
      return formDefaultErrorState(state, action);

    case CLUB_GREENCARDS_SEARCH_CHANGED: {
      return searchStateChangedReducerHelper(state, action);
    }

    case CLUB_GREENCARDS_SEARCH_REQUESTING: {
      let searchActive = state.searchActive;
      let pagedGreenCards = state.pagedGreenCards;
      let pagination = state.pagination;

      if (!searchActive) {
        searchActive = true;
        pagedGreenCards = {};
        pagination.totalCount = 0;
      }

      pagination.page = action.page;

      return {
        ...state,
        pagedGreenCards,
        pagination,
        searchActive,
        requesting: true
      };
    }

    case CLUB_GREENCARDS_SEARCH_RESET:
      return {
        ...initialState,
        requesting: true
      };

    case CLUB_GREENCARDS_RESET:
      return {
        ...initialState,
        requesting: true
      };

    case CLUB_GREENCARDS_SET_INITIAL_STATE:
      return {
        ...initialState
      };

    case CLUB_GREENCARD_EDIT_SUCCESS: {
      const {
        data,
      } = action;

      const page = state.pagination.page;
      const pagedGreenCards = optimisticPagedObjectUpdate(state.pagedGreenCards, page, { ...data });

      return {
        ...state,
        pagedGreenCards
      };
    }

    case CLUB_FEE_EDIT_SUCCESS: {
      const {
        data,
      } = action;
      const page = state.pagination.page;

      const pagedGreenCards = optimisticPagedObjectUpdate(state.pagedGreenCards, page, { ...data });

      return {
        ...state,
        pagedGreenCards
      };
    }

    default:
      return state;
  }
};