import React, { PureComponent, Fragment } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, TablePagination, Typography } from '@material-ui/core';
import { get } from 'lodash';

// What is the max file size that can be uploaded (client side checking)
const MAX_UPLOAD_FILE_SIZE  = 1000000;  // Max upload file size in MB
const MAX_CODES_COUNT       = 50000;    // Max number of codes in upload
const MAX_CODES_LENGTH      = 30;       // Max length of codes

const maxUploadFileSizeInMB = () => MAX_UPLOAD_FILE_SIZE / 1000000;

// Error info definitons for the CodesError class below
type CodesErrorInfo = {
  type: 'tooLong',
  code: string,
  count: number,
} | {
  type: 'tooBig',
  size: number,
} | {
  type: 'invalidFile'
} | {
  type: 'tooManyFiles'
} | {
  type: 'tooManyCodes'
  codes: number,
};

// Helper class to encapsulate file drop errors
class CodesError extends Error {
  readonly info: CodesErrorInfo;

  constructor(e: CodesErrorInfo) {
    super(e.type);
    this.info = e;

    // tslint:disable-next-line:max-line-length
    // Set the prototype explicitly. https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, CodesError.prototype);
  }

  // Helper to format the error to string
  formatError(intl: IntlShape) {
    const {type, ...data} = this.info;
    return intl.formatMessage({id: `scenes.offers.errors.${type}`}, {...data});
  }
}

const StyledRawCodesTable = styled.table`
  width: 100%;
  text-align: left;

  & th, & td{
    padding: 10px;
  }
`;

export const DropzoneWrapper = styled.div`
  width: 100%;
  
  .drop-zone {
    position: relative;
    width: 100%;
    height: 20em;
    border: 0.1em dashed black;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
  }
`;

export const DropzoneLabel = styled.span`
  display: block;
  font-size: 18px;
  padding: 20px;
`;

export const DropzoneHelpLabel = styled.span`
  display: block;
  font-size: 14px;
  margin-top: 20px;
  line-height: 1.1;
`;

interface Props {
  codes?: OfferCodes;
  onChange?: (file?: File) => void;
  intl: IntlShape;
}

interface State {
  disabled: boolean;
  files?: File[];
  dropZoneActive: boolean;
  raw_codes?: string[];
  show_raw_codes: boolean;
  raw_codes_page: number;
}

class CodesFormComponent extends PureComponent<Props, State> {
  state: State = {
    disabled: false,
    files: undefined,
    dropZoneActive: false,
    show_raw_codes: false,
    raw_codes_page: 0,
  };
  
  private _isMounted = false;
  
  componentDidMount() {
    this.isMounted = true;
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  private get isMounted() { return this._isMounted; }
  private set isMounted(v: boolean) { this._isMounted = v; }

  render() {
    const { disabled, files } = this.state;

    const overlayStyle: any = {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      padding: '2.5em 0',
      background: 'rgba(0,0,0,0.5)',
      textAlign: 'center',
      color: '#fff'
    };

    return (
      <Fragment>
        <Typography
          variant={'h6'}
          gutterBottom={true}
        >
          <FormattedMessage id={'scenes.offers.form.sections.codes.subTitle'}/>
        </Typography>
        <div>
          {!files && <Typography paragraph={true} gutterBottom={true}>
            {this._renderCodesUsedStatus()}
          </Typography>}
          {files && <Typography paragraph={true} gutterBottom={true}>
            <FormattedMessage
              id={'scenes.offers.form.sections.codes.selectedFile'}
              values={{
                'fileName': `"${get(files, '[0].name')}"`,
              }}
            />
          </Typography>}

          {/** Raw codes review */}
          {this.state.raw_codes &&
              <div>
                {/** Button to show codes to the user. For checking purposes */}
                <button onClick={e => this.setState({show_raw_codes: true})}>
                  <FormattedMessage id="buttons.show"/>
                </button>
                {/** Show the codes in a array inside a dialog */}
                {this.state.show_raw_codes && this._renderCodesDialog()}
              </div>
            }
        </div>

        <DropzoneWrapper>
          <Dropzone
            disabled={disabled}
            onDrop={this._onDrop}
          >
            {({ isDragActive, getRootProps, getInputProps }) => (
              <div className={'drop-zone'} {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive && (
                  <div style={overlayStyle}>
                    <FormattedMessage id={'scenes.offers.form.sections.codes.dropZoneActiveLabel'}/>
                  </div>
                )}
                <DropzoneLabel>
                  <FormattedMessage id={'scenes.offers.form.sections.codes.dropZoneLabel'}/>
                </DropzoneLabel>
              </div>
            )}
          </Dropzone>
        </DropzoneWrapper>
        <DropzoneHelpLabel>
          <FormattedMessage 
            id={'scenes.offers.form.sections.codes.helpLabel'}
            values={{ size: maxUploadFileSizeInMB() }}
          />
        </DropzoneHelpLabel>
      </Fragment>
    );
  }

  // Handle drop events
  _onDrop = async (files: File[]) => {
    // Files might be null or empty if file was not accepted (too large, wrong mime type, etc.)
    if (!files || files.length === 0) {
      window.alert(new CodesError({type: 'invalidFile'}).formatError(this.props.intl));
      return;
    }

    // Allow only single file uploads
    if (files.length !== 1) {
      window.alert(new CodesError({type: 'tooManyFiles'}).formatError(this.props.intl));
      return;
    }

    // Check that file is not too large
    if (files[0].size > MAX_UPLOAD_FILE_SIZE) {
      window.alert(new CodesError({type: 'tooBig', size: maxUploadFileSizeInMB()}).formatError(this.props.intl));
      return;
    }
    
    try {
      // Check that individual codes are valid
      const codes = await this._checkCodes(files[0]);
      if (!this.isMounted) { return; } // Guard against unmounting

      this.setState({raw_codes: codes, raw_codes_page: 0});

      const { onChange } = this.props;
      this.setState({ files });
  
      if (onChange) {
        onChange(files ? (files.length === 1 ? files[0] : undefined) : undefined);
      }
    } catch (e) {
      console.error(e);

      // Check for Codes validity errors
      if (e instanceof CodesError) {
        window.alert(e.formatError(this.props.intl));
        return;
      }

      // All remaining errors
      window.alert(e);
      return;
    }
  }

  // Check that new codes are valid
  _checkCodes = async (file: File) => {
    return new Promise<string[]>((resolve, reject) => {
      // Codes must be separated with one of these. Same regex used in uhs\src\Service\OfferCodes.php:generateCodes
      const codeSplitRegex = /\s*(?:,|;|\r\n|\n|\r|$)\s*/;

      // Use file reader as it is more widely supported
      const fileReader = new FileReader();
      fileReader.onloadend = (ev => {
        try {
    
          if (fileReader.error) {
            throw fileReader.error;
          }
    
          const result = fileReader.result as string; // Result is string. because 'readAsText' call below
          const codes = result.split(codeSplitRegex);
  
          // Limit code count
          if (codes.length > MAX_CODES_COUNT) {
            throw new CodesError({type: 'tooManyCodes', codes: MAX_CODES_COUNT});
          }

          // Check that codes are less than 30 char. SQL limiation: CREATE TABLE `offerCode` IN contrib\Database\UHS.sql
          codes.forEach(code => {
            if (code.length > 30) {
              throw new CodesError({type: 'tooLong', code, count: MAX_CODES_LENGTH});
            }
          });
          
          resolve(codes);
        } catch (e) {
          reject(e);
        }
      });
  
      fileReader.readAsText(file);
    });
  }

  // Draw dialog that contains uploaded codes. This is so that user can check them for erros
  _renderCodesDialog = () => {
    const ITEMS_PER_PAGE = 100;

    const renderPage = () => {
      const itemsStart = this.state.raw_codes_page * ITEMS_PER_PAGE;
      const itemsEnd = Math.min(itemsStart + ITEMS_PER_PAGE, this.state.raw_codes!.length);
      const itemsCount = itemsEnd - itemsStart;

      const items: JSX.Element[] = [];

      for (let i = 0; i < itemsCount; i++) {
        const idx = itemsStart + i;
        const code = this.state.raw_codes![idx];
        items.push(
          <tr key={idx}>
            <td>
              <DialogContentText>{idx}</DialogContentText>
            </td>
            <td>
              <DialogContentText>{code}</DialogContentText>
            </td>
          </tr>
        );
      }

      return items;
    };

    return(
      <Dialog
        open={this.state.show_raw_codes}
        onClose={() => this.setState({show_raw_codes: false})}
        scroll={'paper'}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogContent dividers={true}>
          <StyledRawCodesTable>
            <thead>
              <tr>
                <th><DialogContentText>#</DialogContentText></th>
                <th>
                  <DialogContentText>
                    <FormattedMessage id="scenes.offers.form.sections.codes.title"/>
                  </DialogContentText>
                </th>
              </tr>
            </thead>
            <tbody>
              {renderPage()}
            </tbody>
            <tfoot>
              <tr>
                <TablePagination
                  rowsPerPageOptions={[ITEMS_PER_PAGE]}
                  colSpan={3}
                  count={this.state.raw_codes!.length}
                  rowsPerPage={ITEMS_PER_PAGE}
                  page={this.state.raw_codes_page}
                  onChangePage={(e, p) => this.setState(s => s = {...s, raw_codes_page: p})}
                />
              </tr>
            </tfoot>
          </StyledRawCodesTable>
        </DialogContent>

        <DialogActions>
          <Button onClick={e => this.setState({show_raw_codes: false})} color="primary">
            <FormattedMessage id={'buttons.close'}/>
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  _renderCodesUsedStatus = () => {
    const { codes } = this.props;

    if (codes && codes.total !== 0) {
      return (
        <FormattedMessage
          id={'scenes.offers.form.sections.codes.codesUsed'}
          values={codes as any}
        />
      );
    } else {
      return (
        <FormattedMessage id={'scenes.offers.form.sections.codes.noCodesAdded'}/>
      );
    }
  }
}

export const CodesForm = injectIntl(CodesFormComponent);
