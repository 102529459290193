import React, { createRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as messageActions from '@src/store/message/actions';
import {
  Dialog,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { ModalContentOverlayLoader } from '@src/components/modals/ui';
import { MessageForm } from '@src/components/forms/MessageForm';

interface Props {
  messageId: number | undefined;
  open: boolean;
  onClose: () => void;
  getMessage: (params: MessageGet) => any;
  message: MessageState;
}

class MessageModalConnected extends React.Component<Props, {}> {
  actionsContainerRef = createRef<HTMLDivElement>();

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      messageId,
      getMessage
    } = this.props;

    if (nextProps.messageId !== messageId) {
      if (nextProps.messageId) {
        getMessage({
          id: nextProps.messageId,
        });
      }
    }
  }

  render() {
    const {
      open,
      messageId,
      onClose,
      message: {
        requesting
      }
    } = this.props;

    return (
      <Dialog
        open={open}
        fullWidth={true}
      >
        <DialogTitle>
          {messageId && <FormattedMessage id={'scenes.messages.modal.edit.title'} />}
          {!messageId && <FormattedMessage id={'scenes.messages.modal.add.title'} />}
        </DialogTitle>
        <DialogContent>
          {requesting && <ModalContentOverlayLoader/>}
          <MessageForm
            messageId={messageId}
            parentContext={'MODAL'}
            onClose={onClose}
            actionsContainerRef={this.actionsContainerRef}
          />
        </DialogContent>

        <div ref={this.actionsContainerRef} />
      </Dialog>
    );
  }
}

const MessageModal = connect((state: any) => ({
  message: state.messageReducer,
}), {
  getMessage: messageActions.getMessage
})(MessageModalConnected);

export {
  MessageModal,
};