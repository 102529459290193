import {
  MAINTENANCE_ADD_ERROR,
  MAINTENANCE_ADD_REQUESTING,
  MAINTENANCE_ADD_SUCCESS,
  MAINTENANCE_DELETE_ERROR,
  MAINTENANCE_DELETE_REQUESTING,
  MAINTENANCE_DELETE_SUCCESS,
  MAINTENANCE_EDIT_ERROR,
  MAINTENANCE_EDIT_REQUESTING,
  MAINTENANCE_EDIT_SUCCESS,
  MAINTENANCE_GET_ERROR,
  MAINTENANCE_GET_REQUESTING,
  MAINTENANCE_GET_SUCCESS,
  MAINTENANCE_READ_REQUESTING,
  MAINTENANCE_READ_SUCCESS,
  MAINTENANCE_READ_ERROR
} from './constants';
import { formDefaultErrorState, formDefaultRequestingState } from '@src/utils/storeUtils';

const initialState: MaintenanceState = {
  maintenance: undefined,
  requesting: false,
  successful: false,
  error: [],
};

export const maintenanceReducer = (state: MaintenanceState = initialState, action: any) => {
  switch (action.type) {
    case MAINTENANCE_GET_REQUESTING:
      return {
        ...formDefaultRequestingState(state),
        maintenance: undefined,
      };

    case MAINTENANCE_ADD_REQUESTING:
    case MAINTENANCE_DELETE_REQUESTING:
    case MAINTENANCE_EDIT_REQUESTING:
    case MAINTENANCE_READ_REQUESTING:
      return formDefaultRequestingState(state);

    case MAINTENANCE_GET_ERROR:
    case MAINTENANCE_ADD_ERROR:
    case MAINTENANCE_DELETE_ERROR:
    case MAINTENANCE_EDIT_ERROR:
    case MAINTENANCE_READ_ERROR:
      return formDefaultErrorState(state, action);

    case MAINTENANCE_GET_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
        maintenance: action.data,
      };

    case MAINTENANCE_ADD_SUCCESS:
    case MAINTENANCE_DELETE_SUCCESS:
    case MAINTENANCE_READ_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
      };

    case MAINTENANCE_EDIT_SUCCESS:
      const { data, } = action;
      return {
        ...state,
        requesting: false,
        successful: true,
        maintenance: data,
      };

    default:
      return state;
  }
};