import React, { ChangeEvent, Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { LoginFooter, LoginPageWrapper, LogoContainer } from '@src/scenes/authentication/ui';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  TextField
} from '@material-ui/core';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import { isValidEmailAddress } from '@src/utils/FormUtils';
import Collapse from '@material-ui/core/Collapse';
import {
  API_ROOT,
  APIRoute,
  isCustomContext,
  formCustomImageURL,
  URL_LOGO,
  URL_LOGO_2X,
  URL_HEADER,
  URL_HEADER_2X
} from '@src/assets/config';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';

interface Props {

}

interface State {
  email: string;
  loading: boolean;
  error: boolean;
  success: boolean;
  response?: any;
}

const initialState = {
  error: false,
  success: false,
  loading: false,
};

class ForgotPasswordRouted extends Component<Props & RouteComponentProps<any>, State> {

  constructor(props: Props & RouteComponentProps<any>) {
    super(props);

    this.state = {
      email: '',
      ...initialState
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', (event) => this._handleKeyDown(event));
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this._handleKeyDown);
  }

  _handleForgot = () => {
    const {email} = this.state;

    const data = new FormData();
    data.append('email', email);

    this.setState({...initialState, loading: true}, () => {
      const options: RequestInit = {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Accept': 'application/json',
        },
        body: data,
      };

      fetch(`${API_ROOT}${APIRoute.POST_RESET_PASSWORD}`, options)
        .then(response => response.json())
        .then(json => {
          if (json.error) {
            this.setState({
              loading: false,
              success: true,
              error: true,
              response: json.error,
            });
          } else {
            this.setState({
              loading: false,
              success: true,
              error: false,
              response: json,
            });
          }
        })
        .catch(error => {
          this.setState({
            loading: false,
            success: true,
            error: true,
            response: error,
          });
        });
    });
  }

  _handleToLogin = () => {
    const {
      history,
    } = this.props;

    history.push('/login');
  }

  render() {
    const {
      success,
      loading
    } = this.state;

    return (
      <LoginPageWrapper>
        <HeadHelmet titleId={'navigation.forgotPassword'}/>
        <LogoContainer>
        {this._renderCustomLogo()}
        </LogoContainer>
        <Card style={{padding: '1em'}}>
          <CardContent>
            <Typography component="h2" style={{margin: 0}}>
              <FormattedMessage id={'login.forgotPassword.helpText'}/>
            </Typography>
            <TextField
              disabled={loading}
              name={'email'}
              style={{marginTop: '1em'}}
              fullWidth={true}
              label={<FormattedMessage id={'login.forgotPassword.emailFieldLabel'}/>}
              value={this.state.email}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const {
                  currentTarget: {
                    value
                  }
                } = e;
                this.setState({email: value});
              }}
            />
          </CardContent>
          <CardActions style={{justifyContent: 'flex-end', display: 'flex'}}>
            <Button
              disabled={!isValidEmailAddress(this.state.email) || loading}
              variant="contained"
              color="primary"
              onClick={this._handleForgot}
            >
              <FormattedMessage id={'login.forgotPassword.submitButtonLabel'}/>
            </Button>
          </CardActions>

          <Collapse
            in={loading}
            timeout={'auto'}
            unmountOnExit={true}
          >
            <div style={{margin: '2em 0', textAlign: 'center'}}>
              <CircularProgress
                size={28}
                thickness={2}
              />
            </div>
          </Collapse>

          <Collapse
            in={success}
            timeout={'auto'}
            unmountOnExit={true}
          >
            <CardContent>
              {success && <Typography component={'p'}>
                  <FormattedMessage id={'login.forgotPassword.success'}/>
              </Typography>}
            </CardContent>
          </Collapse>
        </Card>
        <LoginFooter>
          <Button onClick={this._handleToLogin}>
            <NavigateBefore/>
            <FormattedMessage id={'login.forgotPassword.backToLoginButtonLabel'}/>
          </Button>
        </LoginFooter>

      </LoginPageWrapper>
    );
  }

  /**
   * This function renders GGB logo or custom logo depending are we
   * in GGB or customized context.
   *
   * Custom logo dimensions are 130x130.
   * GGB Office header is 265x65.
   *
   * @private
   */
  private _renderCustomLogo = () => {
    if (isCustomContext) {
      return (
            <img
                src={formCustomImageURL(URL_LOGO)}
                srcSet={`${formCustomImageURL(URL_LOGO_2X)} 2x`}
                width={130}
                height={130}
            />
      );
    }
    return (

          <img
              src={formCustomImageURL(URL_HEADER)}
              srcSet={`${formCustomImageURL(URL_HEADER_2X)} 2x`}
              // width={265}
              height={65}
          />
    );
  }

  private _handleKeyDown = (event: KeyboardEvent) => {
    const {keyCode} = event;
    if (keyCode &&
        keyCode === 13 &&
        this.state.email !== '') {
      this._handleForgot();
    }
  }
}

const ForgotPassword = withRouter(ForgotPasswordRouted);

export {
  ForgotPassword,
};
