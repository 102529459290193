import React, { ChangeEvent } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { FormControlProps } from '@material-ui/core/FormControl';

type Props = {
  label?: React.ReactNode;
  selectedState: ActivityStatus;
  formControlProps?: FormControlProps;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  error?: boolean;
  disabled?: boolean;
};

export default class StateSelect extends React.Component<Props, {}> {
  render(): React.ReactNode {
    const {
      label,
      formControlProps,
      selectedState,
      onChange,
      error = false,
      disabled = false,
    } = this.props;

    return (
      <FormControl {...formControlProps} error={error}>
        <InputLabel htmlFor="state">
          {label ? label : (<FormattedMessage id={'strings.state'}/>)}
        </InputLabel>
        <Select
          inputProps={{
            name: 'state-select',
            id: 'state-select',
          }}
          value={selectedState}
          disabled={disabled}
          onChange={onChange}
        >
          <MenuItem value={'ACTIVE'}><FormattedMessage id={'strings.statusActive'}/></MenuItem>
          <MenuItem value={'INACTIVE'}><FormattedMessage id={'strings.statusInactive'}/></MenuItem>
        </Select>
      </FormControl>
    );
  }
}
