import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import ScoreRow from '@src/components/scenes/member/components/ScoreRow';
import { connect } from 'react-redux';
import {
  getAcceptableScores, GetAcceptableScoresActionPayload, resetAcceptableScores,
} from '@src/store/acceptableScores/actions';
import { Pagination } from '@src/components/pagination/Pagination';
import { DEFAULT_PAGINATION_PAGE } from '@src/assets/config';

import { FormattedMessage } from 'react-intl';
import { getPageSequence } from '@src/utils/storeUtils';
import { ModalContentOverlayLoader } from '@src/components/modals/ui';
import InfoIcon from '@material-ui/icons/Info';
import {
  EditAcceptableScoreStatusPayload, editAcceptableScoreStatus,
  EditAcceptableScorePersonPayload, editAcceptableScorePerson
} from '@src/store/acceptableScore/actions';

interface OwnProps {
  personId?: number;
  showTitle?: boolean;
}

interface StateProps {
  acceptableScoresState: AcceptableScoresState;
}

interface DispatchProps {
  getAcceptableScores(params: GetAcceptableScoresActionPayload): void;

  resetAcceptableScores(): void;
  editAcceptableScoreStatus(args: EditAcceptableScoreStatusPayload): void;
  editAcceptableScorePerson(args: EditAcceptableScorePersonPayload): void;
}

type Props = OwnProps & StateProps & DispatchProps;

function AcceptableScores({
  personId,
  showTitle = true,
  acceptableScoresState,
  getAcceptableScores,
  resetAcceptableScores,
  editAcceptableScoreStatus,
  editAcceptableScorePerson,
}: Props) {
  const { pagedScores, pagination } = acceptableScoresState;
  const scores = pagedScores[pagination.page];

  // fetch on mount
  useEffect(() => {
    if (personId) {
      getAcceptableScores({ personId, page: DEFAULT_PAGINATION_PAGE });
    }
  }, [personId]);

  // clear on unmount
  useEffect(() => () => resetAcceptableScores(), []);

  const renderScores = () => {
    if (scores) {
      return getPageSequence(scores).map((score: AcceptableScore) => (
        <ScoreRow
          type={'acceptable'}
          key={score.id}
          acceptableScore={score}
          editAcceptableScoreStatus={editAcceptableScoreStatus}
          editAcceptableScorePerson={editAcceptableScorePerson}
        />
      ));
    }

    return null;
  };

  return (
    <>
      {showTitle && (
        <Typography gutterBottom={true} variant="h5">
          Acceptable Score Rounds
        </Typography>
      )}
      <div style={{ position: 'relative', marginTop: 20 }}>
        <Table size={'small'} style={{ width: 'auto' }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 24, paddingRight: 0 }} />
              <TableCell>
                <FormattedMessage id={'strings.timePlayed'} />
              </TableCell>
              <TableCell>
                <FormattedMessage id={'strings.course'} />
              </TableCell>
              <TableCell>
                <InfoIcon fontSize={'small'} />
              </TableCell>
              <TableCell align={'center'}>
                <FormattedMessage id={'strings.typeOfRound'} />
              </TableCell>
              <TableCell align={'center'}>
                <FormattedMessage id={'strings.grossScore'} />
              </TableCell>
              <TableCell align={'center'}>
                <FormattedMessage id={'strings.grossScoreAdjusted'} />
              </TableCell>
              <TableCell align={'center'} style={{ width: 60 }}>
                <FormattedMessage id={'strings.playingConditionCalculation'} />
              </TableCell>
              <TableCell align={'center'}>
                <FormattedMessage id={'strings.adjustments'} />
              </TableCell>
              <TableCell align={'center'}>
                <FormattedMessage id={'strings.adjustedScoreDifferential'} />
              </TableCell>
              <TableCell align={'center'}>
                <FormattedMessage id={'strings.revisedHandicapIndex'} />
              </TableCell>
              <TableCell align={'center'} style={{ width: 24 }} />
            </TableRow>
          </TableHead>

          <TableBody>
            {(scores) ? (
              renderScores()
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <Typography variant={'body1'} style={{ margin: '1.2em 0', opacity: .4 }}>
                    <FormattedMessage id="strings.noRecords" />
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        <Pagination
          page={pagination.page}
          limit={pagination.limit}
          totalCount={pagination.totalCount}
          onPageChange={(page) => {
            if (personId) {
              getAcceptableScores({ page, personId });
            }
          }}
        />

        {acceptableScoresState.requesting && (
          <ModalContentOverlayLoader bgOpacity={0.6} />
        )}
      </div>
    </>
  );
}

export default connect<StateProps, DispatchProps, OwnProps, StoreState>(state => ({
  acceptableScoresState: state.acceptableScoresReducer,
}), {
  getAcceptableScores, resetAcceptableScores, editAcceptableScoreStatus, editAcceptableScorePerson
})(AcceptableScores);
