export function flattenMessages(nestedMessages: Object, prefix: string = '') {
  // @ts-ignore
  return Object.keys(nestedMessages).reduce((messages: string[], key: string) => {
    let value: Object | string = nestedMessages[key];
    let prefixedKey: string = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }
    return messages;
  }, {});
}