import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { ContentWrap } from '@src/components/layouts/ui';
// import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

/*
  Testing with some sort of static texts before we can go dynamic
 */
const federation = '/admin/federation';
const federationFunctionaries = `${federation}/functionaries`;
const federationFunctionariesTitles = `${federationFunctionaries}/titles`;
const federationFunctionariesAdd = `${federationFunctionaries}/add-functionary`;

const clubs = '/admin/clubs';
const club = /\/admin\/clubs\/(\d)+/g;
const clubMembers = /\/admin\/clubs\/(\d)+\/members/g;
const clubMember = /\/admin\/clubs\/(\d)+\/members\/(\d)+/g;
const clubCourses = /\/admin\/clubs\/(\d)+\/courses/g;
const clubCourse = /\/admin\/clubs\/(\d)+\/courses\/(\d)+/g;
const clubFunctionaries = /\/admin\/clubs\/(\d)+\/functionaries/g;
const clubFunctionaryTitles = /\/admin\/clubs\/(\d)+\/functionaries\/titles/g;
const clubFunctionaryAdd = /\/admin\/clubs\/(\d)+\/functionaries\/add-functionary/g;
const clubOrganizations = /\/admin\/clubs\/(\d)+\/organizations/g;
const clubServices = /\/admin\/clubs\/(\d)+\/services/g;
const clubPayments = /\/admin\/clubs\/(\d)+\/payments/g;
const clubGreenCard = /\/admin\/clubs\/(\d)+\/green-card/g;

const members = '/admin/members';
const member = /\/admin\/members\/(\d)+\/(\d)+/g;

const problematicMembers = '/admin/members/problematic-members';
const problematicMember = /\/admin\/members\/problematic-members\/(\d)+\/(\d|-)+/g;

const homeClub = /\/admin\/home-club\/(\d)+/g;
const homeClubMembers = /\/admin\/home-club\/(\d)+\/members/g;
const homeClubMember = /\/admin\/home-club\/(\d)+\/members\/(\d)+/g;
const homeClubCourses = /\/admin\/home-club\/(\d)+\/courses/g;
const homeClubCourse = /\/admin\/home-club\/(\d)+\/courses\/(\d)+/g;
const homeClubFunctionaries = /\/admin\/home-club\/(\d)+\/functionaries/g;
const homeClubFunctionaryTitles = /\/admin\/home-club\/(\d)+\/functionaries\/titles/g;
const homeClubFunctionaryAdd = /\/admin\/home-club\/(\d)+\/functionaries\/add-functionary/g;

type Props = {
  classes: any;
  variables?: any;
};

class Breadcrumb extends React.Component<Props & RouteComponentProps<any>, {}> {
  render() {
    const {
      location: {
        pathname,
      },
      match: {
        params
      },
    } = this.props;

    let breadcrumbList: any[] = [];

    /*
      Could be implemented as a great mystery

      1. Check if the path starts with either:
        - federation/
        - clubs/

      2. Then see what the next path param is

      3. repeat 2.

      -> this would lead to a waterfall like pattern in which some breadcrumb would be displayed...

     */

    if (pathname.match(federation)) {
      /*
        Federation routes
       */
      if (pathname.match(federationFunctionaries)) {
        breadcrumbList.push({
          label: <FormattedMessage id={'navigation.functionaries'}/>,
          link: federationFunctionaries,
        });

        // Check how down the road we go with functionaries
        if (pathname.match(federationFunctionariesTitles)) {
          breadcrumbList.push({
            label: <FormattedMessage id={'navigation.functionaryTitles'}/>,
            link: federationFunctionariesTitles,
          });
        } else if (pathname.match(federationFunctionariesAdd)) {
          breadcrumbList.push({
            label: <FormattedMessage id={'scenes.functionaries.addFunctionaryButtonLabel'} />,
            link: federationFunctionariesAdd,
          });
        }
      }
    } else if (pathname.match(clubs)) {
      /*
        CLUB routes
       */
      const { clubId, courseId, memberId } = params;

      breadcrumbList.push({
        label: <FormattedMessage id={'navigation.clubs'}/>,
        link: clubs,
      });

      if (pathname.match(club)) {

        const clubRouteRoot = `/admin/clubs/${clubId}`;

        breadcrumbList.push({
          label: <FormattedMessage id={'navigation.golfClub'}/>,
          link: `${clubRouteRoot}`,
        });

        if (pathname.match(clubMembers)) {
          breadcrumbList.push({
            label: <FormattedMessage id={'navigation.golfClubMembers'}/>,
            link: `${clubRouteRoot}/members`,
          });

          if (pathname.match(clubMember)) {
            breadcrumbList.push({
              label: <FormattedMessage id={'navigation.member'}/>,
              link: `${clubRouteRoot}/members/${memberId}`,
            });
          }
        } else if (pathname.match(clubCourses)) {
          breadcrumbList.push({
            label: <FormattedMessage id={'navigation.golfClubCourses'}/>,
            link: `${clubRouteRoot}/courses`,
          });

          if (pathname.match(clubCourse)) {
            breadcrumbList.push({
              label: <FormattedMessage id={'navigation.golfClubCourse'}/>,
              link: `${clubRouteRoot}/courses/${courseId}`,
            });
          }
        } else if (pathname.match(clubFunctionaries)) {
          breadcrumbList.push({
            label: <FormattedMessage id={'navigation.functionaries'}/>,
            link: `${clubRouteRoot}/functionaries`,
          });

          if (pathname.match(clubFunctionaryTitles)) {
            breadcrumbList.push({
              label: <FormattedMessage id={'navigation.functionaryTitles'}/>,
              link: `${clubRouteRoot}/functionaries/titles`,
            });
          } else if (pathname.match(clubFunctionaryAdd)) {
            breadcrumbList.push({
              label: <FormattedMessage id={'navigation.functionaryAdd'}/>,
              link: `${clubRouteRoot}/functionaries/add-functionary`,
            });
          }
        } else if (pathname.match(clubOrganizations)) {
          breadcrumbList.push({
            label: <FormattedMessage id={'navigation.organizations'}/>,
            link: `${clubRouteRoot}/organizations`,
          });
        } else if (pathname.match(clubServices)) {
          breadcrumbList.push({
            label: <FormattedMessage id={'navigation.golfClubServices'}/>,
            link: `${clubRouteRoot}/services`,
          });
        } else if (pathname.match(clubPayments)) {
          breadcrumbList.push({
            label: <FormattedMessage id={'navigation.golfClubPayments'}/>,
            link: `${clubRouteRoot}/payments`,
          });
        } else if (pathname.match(clubGreenCard)) {
          breadcrumbList.push({
            label: <FormattedMessage id={'navigation.golfClubGreenCard'}/>,
            link: `${clubRouteRoot}/green-card`,
          });
        }
      }
    } else if (pathname.match(members)) {
      /*
        MEMBERS routes
       */
      const { clubId, memberId } = params;

      breadcrumbList.push({
        label: <FormattedMessage id={'navigation.members'}/>,
        link: members,
      });

      if (pathname.match(member)) {
        breadcrumbList.push({
          label: <FormattedMessage id={'navigation.member'}/>,
          link: `${members}/${memberId}/${clubId}`,
        });
      } else if (pathname.match(problematicMembers)) {
        breadcrumbList.push({
          label: <FormattedMessage id={'scenes.problematicMembers.title'}/>,
          link: problematicMembers,
        });
        if (pathname.match(problematicMember)) {
          breadcrumbList.push({
            label: <FormattedMessage id={'navigation.member'}/>,
            link: `${problematicMembers}/${memberId}/${clubId}`,
          });
        }
      }
    } else if (pathname.match(homeClub)) {
      const { clubId, memberId, courseId } = params;

      const homeClubRouteRoot = `/admin/home-club/${clubId}`;

      if (pathname.match(homeClubMembers)) {
        breadcrumbList.push({
          label: <FormattedMessage id={'navigation.members'}/>,
          link: `${homeClubRouteRoot}/members`,
        });

        if (pathname.match(homeClubMember)) {
          breadcrumbList.push({
            label: <FormattedMessage id={'navigation.member'}/>,
            link: `${homeClubRouteRoot}/members/${memberId}`,
          });
        }
      } else if (pathname.match(homeClubCourses)) {
        breadcrumbList.push({
          label: <FormattedMessage id={'navigation.golfClubCourses'}/>,
          link: `${homeClubRouteRoot}/courses`,
        });

        if (pathname.match(homeClubCourse)) {
          breadcrumbList.push({
            label: <FormattedMessage id={'navigation.golfClubCourse'}/>,
            link: `${homeClubRouteRoot}/courses/${courseId}`,
          });
        }
      } else if (pathname.match(homeClubFunctionaries)) {
        breadcrumbList.push({
          label: <FormattedMessage id={'navigation.functionaries'}/>,
          link: `${homeClubRouteRoot}/functionaries`,
        });

        if (pathname.match(homeClubFunctionaryTitles)) {
          breadcrumbList.push({
            label: <FormattedMessage id={'navigation.functionaryTitles'}/>,
            link: `${homeClubRouteRoot}/functionaries/titles`,
          });
        } else if (pathname.match(homeClubFunctionaryAdd)) {
          breadcrumbList.push({
            label: <FormattedMessage id={'navigation.functionaryAdd'}/>,
            link: `${homeClubRouteRoot}/functionaries/add-functionary`,
          });
        }
      }
    }

    if (breadcrumbList.length > 1) {
      return this._renderBreadcrumbs(breadcrumbList);
    }

    return null;
  }

  private _renderBreadcrumbs = (list: any[]) => {
    const { classes } = this.props;
    if (!list || !list.length) {
      window.console.log('no breadcrumb list to render');
      return null;
    }

    return (
      <ContentWrap style={{ margin: '0 auto 2em' }}>
        {list.map((item: any, idx: number) => (
          <NavLink
            className={classes.breadcrumbItem}
            to={item.link}
            key={idx}
            exact={true}
          >
            {item.label}
          </NavLink>
        ))}
      </ContentWrap>
    );
  }
}

const styles = ({ palette }: Theme) => ({
  breadcrumbItem: {
    fontSize: '1.2em',
    color: palette.primary.dark,
    textDecoration: 'none',
    padding: '.6em 0',
    marginRight: '1em',
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'baseline',
    '&:after': {
      content: '"»"',
      color: palette.primary.main,
      paddingLeft: '.4em',
      fontSize: '1.4em',
    },
    '&.active': {
      color: palette.secondary.contrastText,
      '&:after': {
        display: 'none',
      },
    },
  },
});

export default withRouter(withStyles(styles as any)(Breadcrumb));