/*
  PUT /admin/1/member/{clubId}/members/importExcel
 */
import { API_ROOT, APIRoute } from '@src/assets/config';
import {
  CLUB_MEMBERS_EXCEL_ERROR,
  CLUB_MEMBERS_EXCEL_SUCCESS,
  CLUB_MEMBERS_SEARCH_RESET
} from '@src/store/club/constants';
import { call, put, select } from 'redux-saga/effects';
import { stateTargetIdParams, tokenAPIPost } from '@src/utils/storeUtils';
import { enqueueNotification } from '@src/store/notifications/actions';
import { handleOnFunction } from '@src/utils/utils';

export function * setClubMembersExcelActivationFlow(token: string, clubId: number, params: any) {
  try {
    const response = yield call(() => {
      const url = `${API_ROOT}${APIRoute.GET_CLUB}/${clubId}/members/importExcel`;
      return tokenAPIPost(url, token, { ...params, __multiPart: true });
    });

    if (response) {
      yield put({
        type: CLUB_MEMBERS_EXCEL_SUCCESS,
        response,
      });

      handleOnFunction(params.onComplete, { data: response });

      const targetIdParams: TargetIdParams = yield select(stateTargetIdParams('clubReducer'));

      yield put({
        type: CLUB_MEMBERS_SEARCH_RESET,
        clubId: targetIdParams.clubId,
      });
    }

  } catch (error) {
    yield put(enqueueNotification(error));
    handleOnFunction(params.onComplete, { error });
    yield put({ type: CLUB_MEMBERS_EXCEL_ERROR, error });
  }

  return true;
}
