import {
  MESSAGE_ADD_REQUESTING,
  MESSAGE_DELETE_REQUESTING,
  MESSAGE_EDIT_REQUESTING,
  MESSAGE_GET_REQUESTING,
} from '@src/store/message/constants';

export const getMessage = ({
  id
}: MessageGet) => {
  return {
    type: MESSAGE_GET_REQUESTING,
    messageId: id,
  };
};

export const addMessage = (params: MessageAdd) => {
  return {
    type: MESSAGE_ADD_REQUESTING,
    params
  };
};

export const editMessage = (params: MessageEdit) => {
  return {
    type: MESSAGE_EDIT_REQUESTING,
    messageId: params.id,
    params
  };
};

export const deleteMessage = ({
  id,
  onComplete,
}: MessageDelete) => {
  return {
    type: MESSAGE_DELETE_REQUESTING,
    messageId: id,
    params: {
      onComplete,
    },
  };
};