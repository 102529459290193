import React from 'react';
import { connect } from 'react-redux';
import * as reportsActions from '@src/store/reports/actions';
import * as clubActions from '@src/store/clubs/actions';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import { ButtonLoaderWrap, ContentHeader, ContentHeaderTitle, ContentLoader, ContentWrap } from '@src/components/layouts/ui';
import LocalizedDatePicker from '@src/components/forms/LocalizedDatePicker';
import { ClubSelect } from '@src/components/forms/ClubSelect';
import { FederationRoleScopeGuard } from '@src/components/access-control/FederationRoleScopeGuard';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Card,
  CardContent,
  Button,
  FormControl,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';

import { get } from 'lodash';
import { getActiveClubId } from '@src/store/auth/selectors';

interface Props extends WrappedComponentProps {
  clubId: number;
  activeClub: Club;
  reports: ReportsState;
  locale: AppLocale;
  searchChanged: (params: ClubMembersSearch) => any;
  getScoreStatistics(params: ScoreStatisticsReport): void;
  getScoreStatisticsReport(params: ScoreStatisticsReport): void;
  getVendorStatisticsReport(params: ScoreStatisticsReport): void;
  getScoreHolesStatisticsReport(params: ScoreStatisticsReport): void;
}

interface State {
  loading: boolean;
  scoresDownloading: boolean;
  vendorDownloading: boolean;
  scoreHolesDownloading: boolean;
  scoresStartDate?: Date;
  scoresEndDate?: Date;
  vendorDate?: Date;
  scoreHolesDate?: Date;
  scoreClubId?: number;
  vendorClubId?: number;
  scoreHolesClubId?: number;
  allDates: boolean;
}

class ReportsHandicapStatisticsScene extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      scoresDownloading: false,
      vendorDownloading: false,
      scoreHolesDownloading: false,
      scoresStartDate: new Date(new Date().getFullYear(), 0, 1),
      scoresEndDate: undefined,
      vendorDate: undefined,
      scoreHolesDate: undefined,
      scoreClubId: props.targetIdParams?.clubId,
      vendorClubId: props.targetIdParams?.clubId,
      scoreHolesClubId: props.targetIdParams?.clubId,
      allDates: false,
    };

    props.getScoreStatistics({
      clubId: props.clubId,
      onComplete: ({ data , error}) => {
        window.console.log(data, error);
        this.setState({ loading: false });
      }
    });
  }

  render() {
    const {
      reports: {
        successful,
        requesting,
      },
      intl,
      activeClub,
      searchChanged
    } = this.props;
    const {
      loading,
      scoresDownloading,
      vendorDownloading,
      scoreHolesDownloading,
      scoresStartDate,
      scoresEndDate,
      vendorDate,
      scoreHolesDate,
      allDates
    } = this.state;

    return (
      <>
        <HeadHelmet titleId={'navigation.reportHandicapStatistics'} />

        <ContentWrap>
          <ContentHeader>
            <ContentHeaderTitle>
              <FormattedMessage id={'navigation.reportHandicapStatistics'} />
            </ContentHeaderTitle>
          </ContentHeader>

            <Grid container={true} spacing={3}>
              <Grid item={true} xs={12}>
                <Card>
                  <CardContent>
                  <Typography variant={'h5'}>
                    <FormattedMessage id={'scenes.reports.scoreStatistics.sectionTitle'} />
                  </Typography>
                  <div style={{width: '100px'}}>
                    <ContentLoader visible={requesting} />
                  </div>
                  {(successful && !requesting) && (
                    this._handicapStatsTable
                  )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

          <div style={{ marginTop: 18, maxWidth: 500 }}>
            <Typography component="p" gutterBottom={true} style={{ marginTop: 50 }}>
              <FormattedMessage id={'scenes.reports.scoreStatistics.scoreDescription'} />
            </Typography>

            <FormControl disabled={loading}>
              <div style={{display: 'flex', justifyContent: 'space-between', width: 400}}>
                <LocalizedDatePicker
                  label={intl.formatMessage({ id: 'strings.startDate' })}
                  disabled={loading || allDates}
                  value={scoresStartDate}
                  onChange={(newDate: Date) => {
                    this.setState({ scoresStartDate: newDate });
                  }}
                  maxDate={new Date()}
                  minDate="2007-01-01"
                />
                <LocalizedDatePicker
                  label={intl.formatMessage({ id: 'strings.endDate' })}
                  disabled={loading || allDates}
                  value={scoresEndDate}
                  onChange={(newDate: Date) => {
                    this.setState({ scoresEndDate: newDate });
                  }}
                  maxDate={new Date()}
                  minDate="2007-01-01"
                />
              </div>
            </FormControl>

            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  disabled={loading}
                  checked={allDates}
                  onChange={() => {
                    this.setState({allDates: !allDates});
                  }}
                  value="allDates"
                />
              }
              label={<FormattedMessage id={'scenes.reports.scoreStatistics.allDates'}/>}
            />

            <FederationRoleScopeGuard>
              <div style={{width: 400}}>
                <ClubSelect
                  margin={'normal'}
                  disabled={loading}
                  selectedClubs={activeClub}
                  onChangeCallback={(value: any) => {
                    searchChanged({
                      club: value
                    });
                    this.setState({scoreClubId: value?.idClub});
                  }}
                />
              </div>
            </FederationRoleScopeGuard>
          </div>

          <ButtonLoaderWrap loading={scoresDownloading} style={{ marginTop: 30 }}>
            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              onClick={this._handleScoresDownload}
            >
              <FormattedMessage id={'buttons.download'} />
            </Button>
          </ButtonLoaderWrap>

          <div style={{ marginTop: 18, maxWidth: 500 }}>
            <Typography component="p" gutterBottom={true} style={{ marginTop: 50 }}>
              <FormattedMessage id={'scenes.reports.scoreStatistics.vendorDescription'} />
            </Typography>

            <FormControl disabled={loading}>
              <div style={{width: 400}}>
                <LocalizedDatePicker
                  label={intl.formatMessage({ id: 'strings.year' })}
                  disabled={loading}
                  value={vendorDate}
                  onChange={(newDate: Date) => {
                    this.setState({ vendorDate: newDate });
                  }}
                  maxDate={new Date()}
                  minDate="2020-01-01"
                  views={['year']}
                  format="y"
                />
              </div>
            </FormControl>

            <FederationRoleScopeGuard>
              <div style={{width: 400}}>
                <ClubSelect
                  margin={'normal'}
                  disabled={loading}
                  selectedClubs={activeClub}
                  onChangeCallback={(value: any) => {
                    searchChanged({
                      club: value
                    });
                    this.setState({vendorClubId: value?.idClub});
                  }}
                />
              </div>
            </FederationRoleScopeGuard>
          </div>

          <ButtonLoaderWrap loading={vendorDownloading} style={{ marginTop: 30 }}>
            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              onClick={this._handleVendorRoundsDownload}
            >
              <FormattedMessage id={'buttons.download'} />
            </Button>
          </ButtonLoaderWrap>

          <div style={{ marginTop: 18, maxWidth: 500 }}>
            <Typography component="p" gutterBottom={true} style={{ marginTop: 50 }}>
              <FormattedMessage id={'scenes.reports.scoreStatistics.scoreHolesDescription'} />
            </Typography>

            <FormControl disabled={loading}>
              <div style={{width: 400}}>
                <LocalizedDatePicker
                  label={intl.formatMessage({ id: 'strings.year' })}
                  disabled={loading}
                  value={scoreHolesDate}
                  onChange={(newDate: Date) => {
                    this.setState({ scoreHolesDate: newDate });
                  }}
                  maxDate={new Date()}
                  minDate="2020-01-01"
                  views={['year']}
                  format="y"
                />
              </div>
            </FormControl>

            <FederationRoleScopeGuard>
              <div style={{width: 400}}>
                <ClubSelect
                  margin={'normal'}
                  disabled={loading}
                  selectedClubs={activeClub}
                  onChangeCallback={(value: any) => {
                    searchChanged({
                      club: value
                    });
                    this.setState({scoreHolesClubId: value?.idClub});
                  }}
                />
              </div>
            </FederationRoleScopeGuard>
          </div>

          <ButtonLoaderWrap loading={scoreHolesDownloading} style={{ marginTop: 30 }}>
            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              onClick={this._handleScoreHolesDownload}
            >
              <FormattedMessage id={'buttons.download'} />
            </Button>
          </ButtonLoaderWrap>

        </ContentWrap>
      </>
    );
  }

  private get _handicapStatsTable() {
    return (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell size={'small'} />
              <TableCell size={'small'}>
                <FormattedMessage id={'strings.totalCount'} />
              </TableCell>
              <TableCell size={'small'}>
                <FormattedMessage id={'strings.males'} />
              </TableCell>
              <TableCell size={'small'}>
                <FormattedMessage id={'strings.females'} />
              </TableCell>
              <TableCell size={'small'}>
                <FormattedMessage id={'strings.boys'} />
              </TableCell>
              <TableCell size={'small'}>
                <FormattedMessage id={'strings.girls'} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this._renderHandicapStatsTableRows}
          </TableBody>
        </Table>
    );
  }

  private get _renderHandicapStatsTableRows() {
    const { reports } = this.props;

    const labels = [
      'memberships',
      '20+',
      '12-19',
      '6-11',
      '3-5',
      '1-2',
      '0',
    ];

    return labels.map(label => (
      <TableRow key={label}>
        <TableCell size={'small'}>
          {label !== 'memberships' && `${label} `}
          {label === 'memberships' ? <FormattedMessage id={`strings.memberships`}/> : <FormattedMessage id={`strings.rounds`}/>}
        </TableCell>
        {['total', 'men', 'women', 'boys', 'girls'].map(item => (
          <TableCell size={'small'} key={`${label}-${item}`}>
            {get(reports, `handicapStatistics.${label}.${item}`) || 0}
          </TableCell>
        ))}
      </TableRow>
    ));
  }

  private _handleScoresDownload = () => {
    this.setState({ loading: true, scoresDownloading: true }, this._handleScoresSending);
  }

  private _handleScoresSending = () => {
    const { getScoreStatisticsReport, locale } = this.props;
    const { scoresStartDate, scoresEndDate, allDates, scoreClubId } = this.state;
    const clubId = scoreClubId ? scoreClubId : this.props.clubId;

    getScoreStatisticsReport({
      startDate: allDates ? new Date(1900, 0, 1) : scoresStartDate,
      endDate: allDates ? new Date() : scoresEndDate,
      clubId,
      locale: locale.appLanguage.code === 'fi-SV' ? 'sv-FI' : locale.appLanguage.code,
      onComplete: ({ data , error}) => {
        window.console.log(data, error);
        this.setState({ loading: false, scoresDownloading: false });
      }
    });
  }

  private _handleVendorRoundsDownload = () => {
    this.setState({ loading: true, vendorDownloading: true }, this._handleVendorRoundsSending);
  }

  private _handleVendorRoundsSending = () => {
    const { getVendorStatisticsReport, locale } = this.props;
    const { vendorDate, vendorClubId } = this.state;
    const clubId = vendorClubId ? vendorClubId : this.props.clubId;

    getVendorStatisticsReport({
      year: vendorDate?.getFullYear(),
      clubId,
      locale: locale.appLanguage.code === 'fi-SV' ? 'sv-FI' : locale.appLanguage.code,
      onComplete: ({ data , error}) => {
        window.console.log(data, error);
        this.setState({ loading: false, vendorDownloading: false });
      }
    });
  }

  private _handleScoreHolesDownload = () => {
    this.setState({ loading: true, scoreHolesDownloading: true }, this._handleScoreHolesSending);
  }

  private _handleScoreHolesSending = () => {
    const { getScoreHolesStatisticsReport, locale } = this.props;
    const { scoreHolesDate, scoreHolesClubId } = this.state;
    const clubId = scoreHolesClubId ? scoreHolesClubId : this.props.clubId;

    getScoreHolesStatisticsReport({
      year: scoreHolesDate?.getFullYear(),
      clubId,
      locale: locale.appLanguage.code === 'fi-SV' ? 'sv-FI' : locale.appLanguage.code,
      onComplete: ({ data , error}) => {
        window.console.log(data, error);
        this.setState({ loading: false, scoreHolesDownloading: false });
      }
    });
  }

}

export default injectIntl(connect((store: StoreState) => ({
  reports: store.reportsReducer,
  clubId: getActiveClubId(store),
  activeClub: store.clubReducer.search.club,
  locale: store.locale,
}), {
  getScoreStatistics: reportsActions.getScoreStatistics,
  getScoreStatisticsReport: reportsActions.getScoreStatisticsReport,
  getVendorStatisticsReport: reportsActions.getVendorStatisticsReport,
  getScoreHolesStatisticsReport: reportsActions.getScoreHolesStatisticsReport,
  searchChanged: clubActions.searchChanged,
})(ReportsHandicapStatisticsScene));