import { call, put } from 'redux-saga/effects';
import {
  REPORT_SCORE_STATISTICS_REQUESTING_SUCCESS,
  REPORT_SCORE_STATISTICS_REQUESTING_ERROR
} from '@src/store/reports/constants';
import { API_ROOT, APIRoute } from '@src/assets/config';
import { tokenAPIGet, formatURLParams } from '@src/utils/storeUtils';

export function* getScoreStatisticsFlow(token: string, params: any) {
  try {
    const rootUrl = `${API_ROOT}${APIRoute.REPORT_SCORE_STATISTICS}`;
    const urlParams = params ? formatURLParams(params, '?') : '';
    const url = `${rootUrl}${urlParams}`;

    const response = yield call(() => {
      return tokenAPIGet(url, token);
    });

    if (response) {
      const { stats } = response;
      yield put({ type: REPORT_SCORE_STATISTICS_REQUESTING_SUCCESS, data: stats });
    }
  } catch (error) {
    yield put({ type: REPORT_SCORE_STATISTICS_REQUESTING_ERROR, error });
  }
}