import {
  CLUB_GREENCARDS_CHANGE_PAGE,
  CLUB_GREENCARDS_REQUESTING,
  CLUB_GREENCARDS_SEARCH_CHANGED,
  CLUB_GREENCARDS_SEARCH_REQUESTING,
  CLUB_GREENCARDS_SEARCH_RESET
} from './constants';
import { DEFAULT_PAGINATION_LIMIT } from '@src/assets/config';

export const fetchClubGreenCards = ({
  clubId,
  page,
  limit = DEFAULT_PAGINATION_LIMIT,
}: FetchClubGreenCards) => {
  return {
    type: CLUB_GREENCARDS_REQUESTING,
    clubId,
    page,
    limit,
  };
};

export const changePage = ({
  page
}: ChangePageAction) => {
  return {
    type: CLUB_GREENCARDS_CHANGE_PAGE,
    page,
  };
};

export const searchChanged = (search: ClubsGreenCardSearch) => {
  return {
    type: CLUB_GREENCARDS_SEARCH_CHANGED,
    search
  };
};

export const fetchSearch = ({
  page,
  limit = DEFAULT_PAGINATION_LIMIT,
  params
}: SearchPaginationAction) => {
  return {
    type: CLUB_GREENCARDS_SEARCH_REQUESTING,
    page,
    limit,
    clubId: params.clubId,
    params,
  };
};

export const resetSearch = (clubId: number) => {
  return {
    type: CLUB_GREENCARDS_SEARCH_RESET,
    clubId: clubId
  };
};