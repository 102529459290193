import { API_ROOT, APIRoute } from '@src/assets/config';

export function loginCheckApi (token: string) {
  return fetch(`${API_ROOT}${APIRoute.GET_LOGIN_CHECK}/${token}`, {
    method: 'GET',
    cache: 'no-cache',
    headers: {
      'Accept': 'application/json'
    },
  })
    .then(response => response.json() )
    .then(json => {
      if (json.error) {
        throw json.error;
      }

      return json;
    });
}