import {
  CLUB_COURSE_TEE_EDIT_REQUESTING,
  CLUB_COURSE_TEE_GET_REQUESTING
} from './constants';

export const getClubCourseTee = ({
  clubCourseId,
  id
}: ClubCourseTeeGet) => {
  return {
    type: CLUB_COURSE_TEE_GET_REQUESTING,
    clubCourseId: clubCourseId,
    teeNumber: id,
  };
};

export const editClubCourseTee = (params: ClubCourseTeeEdit) => {
  return {
    type: CLUB_COURSE_TEE_EDIT_REQUESTING,
    clubCourseId: params.clubCourseId,
    teeNumber: params.id,
    params
  };
};