import React, { ChangeEvent } from 'react';
import { TextField } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { ErrorInputs } from '@src/components/forms/InputsValidator';

type AddressFieldChanged = {
  [name: string]: string;
};

type Props = {
  streetAddress?: string;
  city?: string;
  zip?: string;
  errorInputs?: ErrorInputs;
  requiredInputs?: {
    streetAddress?: boolean;
    city?: boolean;
    zip?: boolean;
  },
  disabledInputs?: {
    streetAddress?: boolean;
    city?: boolean;
    zip?: boolean;
  },
  onChange?: (args: AddressFieldChanged) => void;
};

export default class AddressFields extends React.Component<Props, {}> {
  render(): React.ReactNode {
    const {
      streetAddress,
      city,
      zip,
      errorInputs = {},
      requiredInputs = {},
      disabledInputs = {}
    } = this.props;

    return (
      <>
        <TextField
          required={requiredInputs.streetAddress}
          disabled={disabledInputs.streetAddress}
          error={errorInputs.streetAddress}
          fullWidth={true}
          label={<FormattedMessage id={'strings.streetAddress'}/>}
          value={streetAddress}
          margin="normal"
          onChange={this._handleChange('streetAddress')}
        />
        <TextField
          required={requiredInputs.city}
          disabled={disabledInputs.city}
          error={errorInputs.city}
          fullWidth={true}
          label={<FormattedMessage id={'strings.city'}/>}
          value={city}
          margin="normal"
          onChange={this._handleChange('city')}
        />
        <TextField
          required={requiredInputs.zip}
          disabled={disabledInputs.zip}
          error={errorInputs.zip}
          fullWidth={true}
          label={<FormattedMessage id={'strings.postalCode'}/>}
          value={zip}
          type={'number'}
          margin="normal"
          onChange={this._handleChange('zip')}
          inputProps={{ maxLength: 15 }}
        />
      </>
    );
  }

  private _handleChange = (name: string) => (
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { onChange } = this.props;
      if (onChange) {
        onChange({
          [name as any]: event.target.value,
        });
      }
    }
  )
}