import { tokenAPIPost } from '@src/utils/storeUtils';
import { call, put } from 'redux-saga/effects';
import { API_ROOT, APIRoute } from '@src/assets/config';
import { CLUB_COURSE_COPY_ERROR, CLUB_COURSE_COPY_SUCCESS } from '@src/store/clubCourse/constants';

/*
  POST /admin/1/course/<courseId>/copy
 */
export function * copyCourseFlow(token: string, courseId: number, onComplete?: (args: APICallResult) => void) {
  try {
    const response = yield call(() => {
      const url = `${API_ROOT}${APIRoute.CLUB_COURSE}/${courseId}/copy`;
      return tokenAPIPost(url, token);
    });

    if (response) {
      yield put({
        type: CLUB_COURSE_COPY_SUCCESS,
        data: response,
      });

      if (onComplete) {
        onComplete({ data: response });
      }
    }

  } catch (error) {
    yield put({ type: CLUB_COURSE_COPY_ERROR, error });
  }

  return true;
}