import React, { ChangeEvent, Fragment, ReactNode } from 'react';
import Dialog from '@material-ui/core/Dialog';
// import { FormControlLabel, Switch } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import { ActionContainer, ModalContentOverlayLoader } from '@src/components/modals/ui';
import { Button, TextField } from '@material-ui/core';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import * as clubActions from '@src/store/club/actions';
import { DEFAULT_INPUT_LEFT_MARGIN } from '@src/assets/config';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import FormControl from '@material-ui/core/FormControl/FormControl';
import { ClubSelect } from '@src/components/forms/ClubSelect';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import { confirm } from '@src/components/modals/Confirm';

interface State {
  file: any | undefined;
  columnHeader: string | undefined;
  requesting: boolean;
  clubId?: number;
  club?: Club;
}

interface StateProps extends WrappedComponentProps {
  club: ClubState;
  disableSwitch?: boolean;
  clubId?: number;
  open: boolean;
}

interface DispatchProps {
  fetchClub: (params: FetchClub) => void;
  uploadClubMembersExcel: (params: MemberExcelImport) => any;
  onClose: () => void;
}

type Props = StateProps & DispatchProps;

class UploadMembersExcelModalConnected extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      file: '',
      columnHeader: '',
      requesting: false,
      clubId: props.clubId,
    };
  }

  UNSAFE_componentWillMount() {
    console.log('WillMount');
    const { fetchClub } = this.props;
    if (this.props.clubId) {
      fetchClub({ clubId: this.props.clubId });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.clubId !== this.props.clubId) {
      this.setState({
        clubId: nextProps.clubId
      });
    }
  }

  render() {
    const {
      file,
      columnHeader,
      clubId,
    } = this.state;

    const {
      club: {
        requesting,
        successful,
      },
      open,
    } = this.props;

    const disabled = (
        !(Boolean(file) && Boolean(columnHeader) && Boolean(clubId))
    );

    return (
      <Dialog
        open={open}
        fullWidth={true}
      >
        <DialogTitle>
          <FormattedMessage id={'scenes.members.modal.upload.title'} />
        </DialogTitle>

        <DialogContent>
          <Fragment>
            {(successful || !this.props.clubId) && (
                <>
                  {this._renderContent()}
                  {requesting && <ModalContentOverlayLoader/>}
                </>
            )}
          </Fragment>
        </DialogContent>

        <DialogActions>
          <ActionContainer>
            <Button
              variant="contained"
              onClick={this._handleClose}
              style={{marginRight: DEFAULT_INPUT_LEFT_MARGIN}}
            >
              <FormattedMessage id={'buttons.cancel'} />
            </Button>
            <Button variant="contained" color="primary" onClick={this._handleOnUpload} disabled={disabled}>
              <FormattedMessage id={'buttons.upload'} />
            </Button>
          </ActionContainer>
        </DialogActions>
      </Dialog>
    );
  }

  private _doClose = () => {
    const { onClose } = this.props;
    this._resetState(() => {
      if (onClose) {
        onClose();
      }
    });
  }

  private _handleClose = (result?: any) => {
    if (result.data && result.data.hasOwnProperty('rowCount')) {
      const { intl } = this.props;

      confirm({
        message: intl.formatMessage({
          id: 'scenes.members.modal.upload.affectedRows',
        }, result.data),
        options: {
          cancelText: '',
          okText: intl.formatMessage({ id: 'buttons.ok' }),
        }
      }).then(() => {
        this._doClose();
      }, () => {
        this._doClose();
      });
    } else {
      this._doClose();
    }
  }

  private _resetState = (callback: () => any) => {
    this.setState({
      requesting: false,
      file: '',
      columnHeader: '',
    }, callback);
  }

  private _handleOnUpload = () => {
    const { uploadClubMembersExcel } = this.props;
    const { file, columnHeader, clubId, } = this.state;

    if (file && columnHeader && clubId) {
      this.setState({ requesting: true }, () => {
        uploadClubMembersExcel({
          excel: file,
          columnHeader,
          clubId,
          activateIfBillsPaid: false,
          onComplete: this._handleOnComplete
        });
      });
    }
  }

  private _handleOnComplete = (result: any) => {
    if (result.error) {
      this.setState({ requesting: false });
    } else {
      this.setState({club: result.data});
      this._handleClose(result);
    }
  }

  private _renderContent = (): ReactNode => {
    const {
      columnHeader,
    } = this.state;

    return (
      <Fragment>
        {!this.props.clubId && this._renderClubSelectForAdminUser()}
        <FormControl fullWidth={true} margin={'normal'}>
          <TextField
            label={<FormattedMessage id={'scenes.members.modal.upload.fields.file'} />}
            type={'file'}
            defaultValue={''}
            placeholder={''}
            onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              const {
                currentTarget
              } = e;

              const elem = currentTarget as HTMLInputElement;

              if (elem.files && elem.files.length > 0) {
                this.setState({
                  file: elem.files[0],
                });
              }
            }}
          />
        </FormControl>
        <FormControl fullWidth={true} margin={'normal'}>
          <TextField
            label={<FormattedMessage id={'scenes.members.modal.upload.fields.memberIdColumn'} />}
            value={columnHeader}
            placeholder={''}
            onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              const {
                currentTarget: {
                  value,
                }
              } = e;

              this.setState({
                columnHeader: value,
              });
            }}
          />
        </FormControl>
      </Fragment>
    );
  }

  private _renderClubSelectForAdminUser = (): ReactNode => {
    const { clubId } = this.props;
    const { club } = this.state;
    if (clubId) {
      return null;
    }

    return (
      <FormControl fullWidth={true} margin={'normal'}>
        <ClubSelect
          margin={'normal'}
          selectedClubs={club}
          onChangeCallback={(value: any) => {
            this.setState({
              club: value,
              clubId: value ? value.id : undefined,
            });
          }}
        />
      </FormControl>
    );
  }
}

export const UploadMembersExcelModal = connect(
  null,
  {
  uploadClubMembersExcel: clubActions.uploadClubMembersExcel,
  fetchClub: clubActions.fetchClub,
  })(injectIntl(UploadMembersExcelModalConnected));
