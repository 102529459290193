import React, { Fragment, Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, IconButton, Link, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import {
  ContentHeader,
  ContentHeaderTitle,
  ContentLoader,
  ContentWrap
} from '@src/components/layouts/ui';
import { connect } from 'react-redux';
import * as reportActions from '@src/store/report/actions';
import * as reportsActions from '@src/store/report2s/actions';
import { DEFAULT_PAGINATION_PAGE, LOCALSTORAGE_TOKEN_NAME, REPORT_URL } from '@src/assets/config';
import { DeleteIconButton, EditIconButton } from '@src/components/buttons/buttons';
import { Pagination } from '@src/components/pagination/Pagination';
import { ReportModal } from '@src/components/modals/ReportModal';
import { PlayArrow } from '@material-ui/icons';
import { confirm } from '@src/components/modals/Confirm';

const reportCompare = (a: Report2, b: Report2, l?: string): number => {
  let c: number = !a.name ? !b.name ? 0 : -1 : a.name.localeCompare(b.name, l);
  if (c === 0) {
    c = b.id - a.id;
  }
  return c;
};

// const REPORT_SERVICE = 'http://localhost:4000/';
// http://localhost:8000/report/report?idReport=1

interface Props {
  reports: Report2sState;
  fetchReports: (params: TablePaginationAction) => any;
  changePage: (params: ChangePageAction) => any;
  editReport: (params: Report2Edit) => any;
  addReport: (params: Report2Add) => any;
  removeReport: (params: Report2Delete) => any;
  locale: AppLocale;
}

interface State {
  isEditPartnerDialogOpen?: boolean;
  isNewPartnerDialogOpen?: boolean;
  reportIdToEdit?: number;
  modalOpen: boolean;
  loading: boolean;
}

class ReportsConnected extends Component<Props & WrappedComponentProps, State> {
  constructor(props: Props & WrappedComponentProps) {
    super(props);

    this.state = {
      modalOpen: false,
      loading: false,
    };
  }

  UNSAFE_componentWillMount() {
    const {
      reports: {
        requesting,
        successful
      },
      fetchReports,
      locale: {
        appLanguage: {
          collation
        }
      }
    } = this.props;

    if (!requesting && !successful) {
      fetchReports({
        page: DEFAULT_PAGINATION_PAGE,
        params: { collation }
      });
    }
  }

  render() {
    const {
      reports: {
        requesting
      }
    } = this.props;
    const { reportIdToEdit, modalOpen } = this.state;

    return (
      <Fragment>
        <HeadHelmet titleId={'navigation.reportEngine'} />
        <ContentWrap>
          <ContentHeader>
            <ContentHeaderTitle><FormattedMessage id={'scenes.reports.engine.sectionTitle'} /></ContentHeaderTitle>
            <Button variant="contained" onClick={() => this.setState({reportIdToEdit: undefined, modalOpen: true})}>
              <FormattedMessage id={'scenes.reports.engine.addNewButtonLabel'} />
            </Button>
          </ContentHeader>

          {this._renderReportsTable(this.props)}

          <ContentLoader visible={requesting}/>

          <ReportModal
            reportId={reportIdToEdit}
            open={modalOpen}
            onClose={() => {
              this.setState({
                modalOpen: false,
              });
            }}
          />
        </ContentWrap>
      </Fragment>
    );
  }

  private _renderReportsTable = (props: Props) => {
    const {
      reports: {
        requesting,
        successful,
        pagedReports,
        pagination: {
          page
        }
      }
    } = props;

    if (!requesting) {
      const token = localStorage.getItem(LOCALSTORAGE_TOKEN_NAME);
      // ToDo: Implement error handling
      return (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><FormattedMessage id={'scenes.reports.engine.nameLabel'} /></TableCell>
                <TableCell><FormattedMessage id={'scenes.reports.engine.sourceLabel'} /></TableCell>
                <TableCell size={'small'}/>
                <TableCell size={'small'}/>
              </TableRow>
            </TableHead>
            <TableBody>
              {successful && pagedReports && Object.keys(pagedReports[page]).sort((a, b): number => {
                return reportCompare(
                    pagedReports[page][a],
                    pagedReports[page][b],
                    this.props.locale.appLanguage.langName
                );
              }).map((key: string) => (
                <TableRow key={pagedReports[page][key].id}>
                  <TableCell>{pagedReports[page][key].name}</TableCell>
                  <TableCell>{pagedReports[page][key].source.substring(0, 20)}</TableCell>
                  <TableCell size={'small'}>
                    <EditIconButton
                      onClick={() => this.setState({reportIdToEdit: pagedReports[page][key].id, modalOpen: true})}
                    />
                    <DeleteIconButton
                      onClick={() => {
                        const { id, name } = pagedReports[page][key];
                        this._handleDestroyClick(id, name);
                      }}
                    />
                  </TableCell>
                  <TableCell size={'small'}>
                    <Link href={REPORT_URL + pagedReports[page][key].id + '&auth=' + token} target="_report">
                      <IconButton
                        color="primary"
                        aria-label="Run"
                      >
                        <PlayArrow />
                      </IconButton>
                    </Link>
                    {/*
                    <a target="_blank" href={REPORT_SERVICE + 'report?idReport=' + pagedReports[page][key].id}>RUN</a>
                    */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {this._renderPagination()}
        </>
      );
    } else {
      return null;
    }
  }

  private _handleDestroyClick = (id: number, name: string) => {
    const { removeReport, intl } = this.props;
    confirm({
      message: intl.formatMessage({
        id: 'scenes.reports.engine.deleteReportMessage'
      }),
      options: {
        cancelText: intl.formatMessage({ id: 'buttons.cancel' }),
        okText: intl.formatMessage({ id: 'buttons.ok' })
      }
    }).then((result: any) => {
      if (result) {
        removeReport({
          id
        });
      }
    }, () => {
      window.console.log('cancelled');
    });
  }

  private _handlePageChange = (page: number) => {
    const {
      reports: {
        pagedReports
      },
      fetchReports,
      changePage,
      locale: {
        appLanguage: {
          collation
        }
      }
    } = this.props;

    if (pagedReports[page]) {
      changePage({ page });
    } else {
      fetchReports({ page, params: { collation } });
    }
  }

  private _renderPagination = () => {
    const {
      reports: {
        pagination: {
          page,
          limit,
          totalCount
        }
      }
    } = this.props;

    return <Pagination page={page} limit={limit} totalCount={totalCount} onPageChange={this._handlePageChange}/>;
  }
}

const Report2sScene = injectIntl(connect((state: any) => ({
  reports: state.report2sReducer,
  locale: state.locale
}), {
  fetchReports: reportsActions.fetchReports,
  changePage: reportsActions.changePage,
  editReport: reportActions.editReport,
  removeReport: reportActions.deleteReport,
  addReport: reportActions.addReport,
})(ReportsConnected));

export {
  Report2sScene,
};
