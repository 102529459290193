import { userAuthToken } from '@src/store/auth/selectors';
import { select, call } from 'redux-saga/effects';
import { EditPersonHandicapOverrideAction, getPersonHandicapInfo } from '@src/store/person/actions';
import { tokenAPIPut } from '@src/utils/storeUtils';
import { API_ROOT, APIRoute } from '@src/assets/config';
import { put } from '@redux-saga/core/effects';
import { EDIT_HANDICAP_OVERRIDE_ERROR, EDIT_HANDICAP_OVERRIDE_SUCCESS } from '@src/store/person/constants';

export function* editPersonHandicapOverrideFlow({
  personId,
  override,
}: EditPersonHandicapOverrideAction) {
  try {
    const token = yield select(userAuthToken);
    const data = yield call(() => tokenAPIPut(
      `${API_ROOT}${APIRoute.handicapOverride(personId)}`,
      token,
      override,
    ));

    yield put({ type: EDIT_HANDICAP_OVERRIDE_SUCCESS, data });
    yield put(getPersonHandicapInfo(personId));
  } catch (error) {
    yield put({ type: EDIT_HANDICAP_OVERRIDE_ERROR, error });
  }
}