import React, { PureComponent } from 'react';
import { InputLabel } from '@material-ui/core';
import { Editor } from '@toast-ui/react-editor';
import { injectIntl, IntlShape } from 'react-intl';
// const AnyEditor: React.FC<any> = Editor as any;
import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';

const AnyEditor = Editor;

interface Props {
  content?: string;
  required: boolean;
  label?: any;
  height: number;
  intl: IntlShape;
  onChangeCallback(value?: string): void;
}

interface State {
  isActive: boolean;
}

class MarkdownEditor extends PureComponent<Props, State> {
  editorRef: React.MutableRefObject<Editor|null> = React.createRef<Editor>();
  constructor(props: Props) {
    super(props);

    this.state = {
      isActive: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.content !== prevProps.content) {
      this.editorRef.current?.getInstance().setMarkdown(this.props.content ?? '');
    }
  }

  render() {
    const { content, required, height, label, intl } = this.props;
    const { isActive } = this.state;
    return (
      <>
        {label && (
          <InputLabel required={required} style={{margin: '10px 0', fontSize: '12px', color: isActive ? '#268d51' : 'rgba(0, 0, 0, 0.54)'}}>
            {label}
          </InputLabel>
        )}
        <AnyEditor
          usageStatistics={false}
          ref={this.editorRef}
          initialValue={content}
          previewStyle="vertical"
          height={`${height}px`}
          initialEditType="markdown"
          useCommandShortcut={false}
          hooks={{addImageBlobHook: (file, cb) => {
            alert(intl.formatMessage({id: 'system.error.featureUnavailable'}));
            console.log(`MarkdownEditor: prevent file upload`);
          }}}
          events={{
            focus: this._handleFocus,
            blur: this._handleBlur,
          }}
        />
      </>
    );
  }

  // Could use this to set InputLabel color when editor has focus but toolbar popups won't close if onFocus event is set
  // Leaving this here for possible future fix on the @toast-ui package
  private _handleFocus = () => {
    if (!this.state.isActive) {
      this.setState({ isActive: true });
    }
  }

  private _handleBlur = () => {
    this.setState({ isActive: false }, this._updateValue);
  }

  private _updateValue = () => {
    const currentValue = this.editorRef.current?.getInstance().getMarkdown();
    this.props.onChangeCallback(currentValue);
  }

}

export default injectIntl(MarkdownEditor);