import { tokenAPIPut } from '@src/utils/storeUtils';
import { API_ROOT, APIRoute } from '@src/assets/config';
import { call, put } from 'redux-saga/effects';
import {
  SET_HIO_INSURANCE_SUCCESS,
  SET_HIO_INSURANCE_ERROR
} from '@src/store/club/constants';
import { SetHIOInsuranceAction } from '@src/store/club/actions';
import { select } from '@redux-saga/core/effects';
import { userAuthToken } from '@src/store/auth/selectors';
import { enqueueNotification } from '@src/store/notifications/actions';

export function * setHIOInsuranceFlow({
  idMembership,
  action,
}: SetHIOInsuranceAction) {
  try {
    const token = yield select(userAuthToken);
    const response = yield call(() => {
      const url = `${API_ROOT}${APIRoute.setHIOInsurance(idMembership, action)}`;
      return tokenAPIPut(url, token);
    });

    if (response) {
      yield put({
        type: SET_HIO_INSURANCE_SUCCESS,
        id: idMembership,
        insuranceIsValid: action === 'enable',
      });
    }

  } catch (error) {
    yield put(enqueueNotification(error));
    yield put({ type: SET_HIO_INSURANCE_ERROR, error });
  }

  return true;
}
