import { all, spawn } from 'redux-saga/effects';
import LoginSaga, { authUnsetWatcher, loginCheckWatcher, silentLoginWatcher } from './login/sagas';
import UsersSaga from './users/sagas';
import UserSaga from './user/sagas';
import ClubsSaga from './clubs/sagas';
import ClubSaga, { clubSpawnWatcher } from './club/sagas';
import PartnersSaga from './partners/sagas';
import PartnerSaga from './partner/sagas';
import MaintenancesSaga from './maintenances/sagas';
import MaintenanceSaga from './maintenance/sagas';
import OffersSaga from './offers/sagas';
import OfferSaga from './offer/sagas';
import MessagesSaga from './messages/sagas';
import MessageSaga from './message/sagas';
import ReportsSaga from './reports/sagas';
import Report2sSaga from './report2s/sagas';
import ReportSaga from './report/sagas';
import FederationsSaga from './federations/sagas';
import PersonsSaga from './persons/sagas';
import { personSpawnWatcher } from './person/sagas';
import VendorsSaga from './vendors/sagas';
import VendorSaga from './vendor/sagas';
import CompaniesSaga from './companies/sagas';
import CompanySaga from './company/sagas';
import FederationSaga from './federation/sagas';
import MembershipSaga from './membership/sagas';
import ClubFeeWatcher from './clubFee/sagas';
import ClubFeesWatcher from './clubFees/sagas';
import ClubServicesWatcher from './clubServices/sagas';
import ClubGreenCardsWatcher from './clubGreencards/sagas';
import ClubGreenCardWatcher from './clubGreencard/sagas';
import ClubCoursesWatcher, { clubDataChangedWatcher } from './clubCourses/sagas';
import ClubCourseWatcher, { spawnClubCourseWatcher } from './clubCourse/sagas';
import clubCourseTeeWatcher from './clubCourseTee/sagas';
import clubCourseHoleWatcher from './clubCourseHole/sagas';
import ClubServiceWatcher from './clubService/sagas';
import OrganizationsWatcher from './organizations/sagas';
import OrganizationWatcher from './organization/sagas';
import FunctionaryWatcher from './functionary/sagas';
import FunctionariesWatcher from './functionaries/sagas';
import FunctionaryTitlesWatcher from './functionarytitles/sagas';
import FunctionaryTitleWatcher from './functionarytitle/sagas';
import { acceptableScoresWatcher } from '@src/store/acceptableScores/sagas';
import { logoutWatcher } from './login/sagas';
import { currentMaintenancesWatcher } from './login/sagas';
import acceptableScoreWatcher , { acceptableScoreSpawnWatcher } from '@src/store/acceptableScore/sagas';
import { invalidatedScoresWatcher } from '@src/store/invalidatedScores/sagas';
import RegionsWatcher from '@src/store/regions/sagas';

export default function* indexSaga () {
  yield all([
    LoginSaga(),
    loginCheckWatcher(),
    silentLoginWatcher(),
    authUnsetWatcher(),
    UsersSaga(),
    UserSaga(),
    ClubsSaga(),
    ClubSaga(),
    PartnersSaga(),
    PartnerSaga(),
    MaintenancesSaga(),
    MaintenanceSaga(),
    OffersSaga(),
    OfferSaga(),
    MessagesSaga(),
    MessageSaga(),
    ReportsSaga(),
    Report2sSaga(),
    ReportSaga(),
    FederationsSaga(),
    PersonsSaga(),
    VendorsSaga(),
    VendorSaga(),
    CompaniesSaga(),
    CompanySaga(),
    FederationSaga(),
    MembershipSaga(),
    acceptableScoreWatcher(),
    ClubFeeWatcher(),
    ClubFeesWatcher(),
    ClubServicesWatcher(),
    ClubGreenCardsWatcher(),
    ClubGreenCardWatcher(),
    ClubCoursesWatcher(),
    OrganizationsWatcher(),
    OrganizationWatcher(),
    ClubCourseWatcher(),
    clubCourseTeeWatcher(),
    clubCourseHoleWatcher(),
    ClubServiceWatcher(),
    FunctionaryWatcher(),
    FunctionariesWatcher(),
    FunctionaryTitlesWatcher(),
    FunctionaryTitleWatcher(),
    RegionsWatcher(),
    currentMaintenancesWatcher(),
    spawn(acceptableScoresWatcher),
    spawn(invalidatedScoresWatcher),
    spawn(logoutWatcher),
    spawn(acceptableScoreSpawnWatcher),
    spawn(clubDataChangedWatcher),
    spawn(personSpawnWatcher),
    spawn(spawnClubCourseWatcher),
    spawn(clubSpawnWatcher),
  ]);
}
