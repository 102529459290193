import React, { ChangeEvent, Component, Fragment, ReactNode } from 'react';
import { FormattedMessage, FormattedDate, FormattedTime, WrappedComponentProps } from 'react-intl';
import {
  ContentWrap,
  ContentHeader,
  ContentHeaderTitle,
  ContentLoader,
  SearchWrapper,
  SearchActions
} from '@src/components/layouts/ui';
import {
  Button,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  FormControl,
  Theme,
  withStyles,
} from '@material-ui/core';
import { connect } from 'react-redux';
import * as offersActions from '@src/store/offers/actions';
import * as offerActions from '@src/store/offer/actions';
import { Pagination } from '@src/components/pagination/Pagination';
import { DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import { OfferModal } from '@src/components/modals/OfferModal';
import { PartnerSelect } from '@src/components/forms/PartnerSelect';
import { injectIntl } from 'react-intl';
import { confirm } from '@src/components/modals/Confirm';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import { handleSearchFieldOnKeyDownEnterSniff } from '@src/utils/storeUtils';
import { DeleteIconButton, EditIconButton } from '@src/components/buttons/buttons';
import LocalizedDateTimePicker from '@src/components/forms/LocalizedDateTimePicker';
import { isCustomContext, URL_SUBHEADER, URL_SUBHEADER_2X, formCustomImageURL } from '@src/assets/config';
import { RouteComponentProps, withRouter } from 'react-router';
import { FederationRoleScopeGuard } from '@src/components/access-control/FederationRoleScopeGuard';
import { sortByDesc } from '@src/utils/storeUtils';
import moment from 'moment';

interface Props extends RouteComponentProps {
  locale: AppLocale;
  offersReducer: OffersState;
  fetchOffers: (params: TablePaginationAction) => any;
  changePage: (params: ChangePageAction) => any;
  searchChanged: (params: OffersSearch) => any;
  fetchSearch: (params: SearchPaginationAction) => any;
  deleteOffer: (params: OfferDelete) => any;
  resetSearch: () => any;
  classes: any;
  search?: OffersSearch;
}

interface State {
  startDate: Date;
  endDate: Date;
  offerIdToEdit?: number;
  modalOpen: boolean;
}

const styles = ({ palette }: Theme) => ({
  topHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }
});

class OffersSceneConnected extends Component<Props & WrappedComponentProps, State> {

  constructor(props: Props & WrappedComponentProps) {
    super(props);

    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      modalOpen: false,
    };
  }

  UNSAFE_componentWillMount() {
    const {
      offersReducer: {
        requesting,
        successful
      },
      fetchOffers
    } = this.props;

    if (!requesting && !successful) {
      fetchOffers({
        page: DEFAULT_PAGINATION_PAGE
      });
    }
  }

  // componentDidMount() {
  //   this.setState({modalOpen: true, offerIdToEdit: undefined});
  // }

  render() {
    const { offerIdToEdit, modalOpen } = this.state;
    const {
      offersReducer: {
        requesting
      },
      classes
    } = this.props;

    return (
      <Fragment>
        <HeadHelmet titleId={'navigation.offers'} />
        <ContentWrap>
          <ContentHeader>
            <div className={classes.topHeader}>
              <div>
                <ContentHeaderTitle><FormattedMessage id={'scenes.offers.sectionTitle'} /></ContentHeaderTitle>
                <Button
                  variant="contained"
                  onClick={() => this.setState({modalOpen: true, offerIdToEdit: undefined})}
                >
                  <FormattedMessage id={'scenes.offers.addNewOfferButtonLabel'} />
                </Button>
              </div>
              <div>
                {isCustomContext && (
                  <img
                    src={formCustomImageURL(URL_SUBHEADER)}
                    srcSet={`${formCustomImageURL(URL_SUBHEADER_2X)} 2x`}
                    height={48}
                  />
                )}
              </div>
            </div>

            {this._renderOffersSearch()}

          </ContentHeader>

          {this._renderOffersTable()}

          <ContentLoader visible={requesting}/>

          {this._renderPagination()}

          <OfferModal
            offerId={offerIdToEdit}
            open={modalOpen}
            onClose={() => {
              this.setState({
                modalOpen: false,
              });
            }}
          />
        </ContentWrap>
      </Fragment>
    );
  }

  private _renderOffersTable = (): ReactNode => {
    const {
      offersReducer: {
        requesting,
        successful,
        pagedOffers,
        pagination: {
          page
        }
      }
    } = this.props;

    const _getOfferAuthorName = (offer: Offer): string => {
      if (offer.partnerInfo) {
        return offer.partnerInfo.name;
      }

      if (offer.clubInfo) {
        return offer.clubInfo.nameClub;
      }

      return '';
    };

    if (!requesting) {
      // ToDo: Implement error handling
      return (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><FormattedMessage id={'scenes.offers.table.headers.title'} /></TableCell>
              <TableCell><FormattedMessage id={'scenes.offers.table.headers.ingress'} /></TableCell>
              <TableCell><FormattedMessage id={'scenes.offers.table.headers.author'} /></TableCell>
              <TableCell><FormattedMessage id={'scenes.offers.table.headers.startsAt'} /></TableCell>
              <TableCell><FormattedMessage id={'scenes.offers.table.headers.endsAt'} /></TableCell>
              <TableCell><FormattedMessage id={'strings.hasRead'} /></TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {successful && pagedOffers && sortByDesc(Object.keys(pagedOffers[page])).map((key: string) => (
              <TableRow key={pagedOffers[page][key].id}>
                <TableCell>{pagedOffers[page][key].title}</TableCell>
                <TableCell>{pagedOffers[page][key].ingress}</TableCell>
                <TableCell>{_getOfferAuthorName(pagedOffers[page][key])}</TableCell>
                <TableCell size={'small'}>
                  {pagedOffers[page][key].starts != null &&
                  <FormattedDate value={moment.utc(pagedOffers[page][key].starts).local(false).toDate()}/>}
                  &nbsp;
                  {pagedOffers[page][key].starts != null &&
                  <FormattedTime value={moment.utc(pagedOffers[page][key].starts).local(false).toDate()}/>}
                </TableCell>
                <TableCell padding={'none'}>
                  {pagedOffers[page][key].ends != null &&
                  <FormattedDate value={moment.utc(pagedOffers[page][key].ends).local(false).toDate()}/>}
                  &nbsp;
                  {pagedOffers[page][key].ends != null &&
                  <FormattedTime value={moment.utc(pagedOffers[page][key].ends).local(false).toDate()}/>}
                </TableCell>
                <TableCell size={'small'}>{pagedOffers[page][key].hasRead}</TableCell>
                <TableCell size={'small'}>
                  <EditIconButton
                    onClick={() => this.setState({offerIdToEdit: pagedOffers[page][key].id, modalOpen: true})}
                  />
                </TableCell>
                <TableCell size={'small'}>
                  <DeleteIconButton
                    onClick={() => {
                      this._handleDestroyClick(pagedOffers[page][key].id, pagedOffers[page][key].title);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    } else {
      return null;
    }
  }

  private _renderOffersSearch = (): ReactNode => {
    const {
      offersReducer: {
        requesting,
        searchActive,
        search: {
          title,
          starts,
          ends,
          partner
        }
      },
      intl
    } = this.props;

    const disableSearch = this._disableSearch();

    return (
      <SearchWrapper>
        <FormControl disabled={requesting} style={{ marginRight: '1em' }}>
          <TextField
            name="title"
            disabled={requesting}
            value={title}
            label={<FormattedMessage id={'scenes.offers.search.textSearch'} />}
            onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              const { searchChanged } = this.props;
              const {
                currentTarget: {
                  value,
                }
              } = e;

              searchChanged({
                title: value
              });
            }}
            onKeyDown={(e: React.KeyboardEvent<any>) =>
              handleSearchFieldOnKeyDownEnterSniff(e, this._performSearch, disableSearch)
            }
          />
        </FormControl>

        <FederationRoleScopeGuard>
          <FormControl disabled={requesting} style={{ marginRight: '1em' }}>
            <PartnerSelect
              disabled={requesting}
              selectedPartners={partner}
              multi={false}
              onChangeCallback={(value: any) => {
                const { searchChanged } = this.props;
                searchChanged({
                  partner: value,
                });
              }}
            />
          </FormControl>
        </FederationRoleScopeGuard>

        <FormControl disabled={requesting} style={{ marginRight: '1em' }}>
          <LocalizedDateTimePicker
            disabled={requesting}
            value={(starts != null ? moment.utc(starts).local(false).toDate() : null)}
            onChange={(newStartDate: Date) => {
              const { searchChanged } = this.props;
              searchChanged({
                starts: newStartDate != null ? moment(newStartDate).utc(false).toDate() : null
              });
            }}
            emptyLabel={intl.formatMessage({ id: 'scenes.offers.search.startDate' })}
            clearable={true}
          />
        </FormControl>

        <FormControl disabled={requesting} style={{ marginRight: '1em' }}>
          <LocalizedDateTimePicker
            disabled={requesting}
            value={(ends != null ? moment.utc(ends).local(false).toDate() : null)}
            onChange={(newEndDate: Date) => {
              const { searchChanged } = this.props;
              searchChanged({
                ends: newEndDate != null ? moment(newEndDate).utc(false).toDate() : null
              });
            }}
            emptyLabel={intl.formatMessage({ id: 'scenes.offers.search.endsDate' })}
            clearable={true}
          />
        </FormControl>

        <SearchActions
          isSearchActive={searchActive}
          isApiRequestActive={requesting}
          performSearchAction={this._performSearch}
          resetSearchAction={this._resetSearch}
          isSearchDisabled={disableSearch}
        />
      </SearchWrapper>
    );
  }

  private _handleDestroyClick = (id: number, title: string) => {
    const { deleteOffer, intl } = this.props;
    confirm({
      message: intl.formatMessage({
        id: 'scenes.offers.deleteOfferMessage',
      }, {
        title
      }),
      options: {
        cancelText: intl.formatMessage({ id: 'buttons.cancel' }),
        okText: intl.formatMessage({ id: 'buttons.ok' }),
      }
    }).then((result: any) => {
      if (result) {
        deleteOffer({
          id
        });
      }
    }, () => {
      window.console.log('cancelled');
    });
  }

  private _handlePageChange = (page: number) => {
    const {
      offersReducer: {
        pagedOffers,
        searchActive,
        search
      },
      fetchOffers,
      fetchSearch,
      changePage,
    } = this.props;

    /*
      If the page was already fetched we don't need to get it again.
      Just dispatch an action to chage page.
     */
    if (pagedOffers[page]) {
      changePage({ page });
    } else if (searchActive) {
      fetchSearch({ page, params: search });
    } else {
      fetchOffers({ page });
    }
  }

  private _renderPagination = () => {
    /*
      - Shared component of anchors for dispatching new page requests.
      - we know what the current page is from clubsReducer.pagination
      - on each click we dispatch { type: CLUBS_REQUEST_PAGINATED, page: 1..n }
     */
    const {
      offersReducer: {
        pagination: {
          page,
          limit,
          totalCount
        }
      }
    } = this.props;

    if (totalCount > limit) {
      return <Pagination page={page} limit={limit} totalCount={totalCount} onPageChange={this._handlePageChange}/>;
    }

    return null;
  }

  private _performSearch = () => {
    const { fetchSearch, offersReducer: { search } } = this.props;
    fetchSearch({
      page: DEFAULT_PAGINATION_PAGE,
      params: search as any
    });
  }

  private _resetSearch = () => {
    const { resetSearch } = this.props;
    resetSearch();
  }

  private _disableSearch = (): boolean => {
    const {
      offersReducer: {
        requesting,
        search,
      }
    } = this.props;

    return (requesting || (!search.title && !search.partner && !search.starts && !search.ends));
  }
}

const OffersScene = withRouter(withStyles(styles as any)(connect((state: any) => ({
  locale: state.locale,
  offersReducer: state.offersReducer
}), {
  fetchOffers: offersActions.fetchOffers,
  changePage: offersActions.changePage,
  searchChanged: offersActions.searchChanged,
  fetchSearch: offersActions.fetchSearch,
  resetSearch: offersActions.resetSearch,
  deleteOffer: offerActions.deleteOffer,
})((injectIntl(OffersSceneConnected)))));

export {
  OffersScene,
};
