import {
  FUNCTIONARY_ADD_REQUESTING,
  FUNCTIONARY_DELETE_REQUESTING,
  FUNCTIONARY_EDIT_REQUESTING,
  FUNCTIONARY_GET_REQUESTING
} from '@src/store/functionary/constants';

export const getFunctionary = ({
  id
}: FunctionaryGet) => {
  return {
    type: FUNCTIONARY_GET_REQUESTING,
    functionaryId: id,
  };
};

export const addFunctionary = (params: FunctionaryAdd) => {
  return {
    type: FUNCTIONARY_ADD_REQUESTING,
    params
  };
};

export const editFunctionary = (params: FunctionaryEdit) => {
  return {
    type: FUNCTIONARY_EDIT_REQUESTING,
    functionaryId: params.id,
    params
  };
};

export const deleteFunctionary = ({
  id,
  onComplete,
}: FunctionaryDelete) => {
  return {
    type: FUNCTIONARY_DELETE_REQUESTING,
    functionaryId: id,
    params: {
      onComplete,
    },
  };
};