import React from 'react';
import { uploadHIOExcel, UploadHIOExcelPayload } from '@src/store/club/actions';
import { connect } from 'react-redux';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import { FormattedMessage, useIntl } from 'react-intl';
import DialogContent from '@material-ui/core/DialogContent';
import { ChangeEvent, useState } from 'react';
import { ActionContainer, ModalContentOverlayLoader } from '@src/components/modals/ui';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import { Button, TextField } from '@material-ui/core';
import { DEFAULT_INPUT_LEFT_MARGIN } from '@src/assets/config';
import Dialog from '@material-ui/core/Dialog';
import FormControl from '@material-ui/core/FormControl/FormControl';
import { handleOnFunction } from '@src/utils/utils';
import { confirm } from '@src/components/modals/Confirm';
import { handleSearchFieldOnKeyDownEnterSniff } from '@src/utils/storeUtils';

interface ChildrenArgs {
  showModal(): void;
}

interface OwnProps {
  children(args: ChildrenArgs): React.ReactNode;
  onSuccess?(): void;
}

interface DispatchProps {
  uploadHIOExcel(payload: UploadHIOExcelPayload): void;
}

type Props = OwnProps & DispatchProps;

function UploadHIOExcel ({
  children,
  onSuccess,
  uploadHIOExcel,
}: Props) {
  const { formatMessage: f } = useIntl();
  const [open, setOpen] = useState<boolean>(false);
  const [excel, setExcel] = useState<undefined | null | File>(null);
  const [columnHeaderClubId, setColumnHeaderClubId] = useState<string>('');
  const [columnHeaderMemberNo, setColumnHeaderMemberNo] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  function handleClose() {
    setOpen(false);
    setExcel(undefined);
    setColumnHeaderClubId('');
    setColumnHeaderMemberNo('');
  }

  function handleCloseWithSuccess() {
    handleOnFunction(onSuccess);
    handleClose();
  }

  function onComplete(args: APICallResult) {
    setLoading(false);
    console.log(args);

    if (args?.data?.rowCount) {
      confirm({
        message: f({
          id: 'scenes.members.modal.upload.affectedRows',
        }, args.data),
        options: {
          cancelText: '',
          okText: f({ id: 'buttons.ok' }),
        }
      }).then(() => {
        handleCloseWithSuccess();
      }, () => {
        handleCloseWithSuccess();
      });
    } else {
      handleCloseWithSuccess();
    }
  }

  function handleUpload() {
    if (excel && columnHeaderClubId && columnHeaderMemberNo) {
      setLoading(true);
      uploadHIOExcel({
        excel,
        columnHeaderClubId,
        columnHeaderMemberNo,
        onComplete,
      });
    }
  }

  return (
    <>
      {children({
        showModal(): void {
          setOpen(true);
        }
      })}
      <Dialog
        open={open}
        fullWidth={true}
      >
        <DialogTitle>
          <FormattedMessage id={'scenes.members.modal.upload.title'} />
        </DialogTitle>

        <DialogContent>
          <form>
            <FormControl fullWidth={true} margin={'normal'}>
              <TextField
                label={<FormattedMessage id={'scenes.members.modal.upload.fields.file'} />}
                type={'file'}
                defaultValue={''}
                placeholder={''}
                onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  const {
                    currentTarget
                  } = e;

                  const elem = currentTarget as HTMLInputElement;

                  if (elem.files && elem.files.length > 0) {
                    setExcel(elem.files[0]);
                  }
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} margin={'normal'}>
              <TextField
                label={<FormattedMessage id={'scenes.members.modal.upload.fields.clubIdColumn'} />}
                value={columnHeaderClubId}
                placeholder={''}
                InputProps={{
                  onKeyDown: (e) => handleSearchFieldOnKeyDownEnterSniff(e, handleUpload)
                }}
                onChange={({ currentTarget }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  const { value } = currentTarget;
                  setColumnHeaderClubId(value);
                }}
              />
            </FormControl>
            <FormControl fullWidth={true} margin={'normal'}>
              <TextField
                label={<FormattedMessage id={'scenes.members.modal.upload.fields.memberIdColumn'} />}
                value={columnHeaderMemberNo}
                placeholder={''}
                InputProps={{
                  onKeyDown: (e) => handleSearchFieldOnKeyDownEnterSniff(e, handleUpload)
                }}
                onChange={({ currentTarget }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  const { value } = currentTarget;
                  setColumnHeaderMemberNo(value);
                }}
              />
            </FormControl>
            {loading && <ModalContentOverlayLoader/>}
          </form>
        </DialogContent>

        <DialogActions>
          <ActionContainer>
            <Button
              variant="contained"
              onClick={handleClose}
              style={{marginRight: DEFAULT_INPUT_LEFT_MARGIN}}
            >
              <FormattedMessage id={'buttons.cancel'} />
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpload}
              disabled={!(excel && columnHeaderClubId && columnHeaderMemberNo)}
            >
              <FormattedMessage id={'buttons.upload'} />
            </Button>
          </ActionContainer>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default connect<{}, DispatchProps, OwnProps, StoreState>(
  null,
  {
    uploadHIOExcel
  }
)(UploadHIOExcel);
