import { take, fork } from 'redux-saga/effects';
import {
  CLUB_SERVICES_REQUESTING,
  CLUB_SERVICES_SUCCESS,
  CLUB_SERVICES_ERROR,
  CLUB_SERVICES_RESET,
  CLUB_SERVICES_SEARCH_REQUESTING,
  CLUB_SERVICES_SEARCH_RESET,
} from './constants';
import { DEFAULT_PAGINATION_LIMIT, DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import { AUTH_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { normalizeSearchParams } from '@src/utils/storeUtils';

/*
  GET /admin/1/club/<id>/services
 */
const pagedFlow = (clubId: number) => {
  return SagaFlowFactory.createPagedGETFlow({
    apiRoute: `admin/1/club/${clubId}/services`,
    itemType: 'services',
    successType: CLUB_SERVICES_SUCCESS,
    errorType: CLUB_SERVICES_ERROR
  });
};

/*
  GET /admin/1/clubservices/<id>/search
 */
const pagedSearchFlow = (clubId: number) => {
  return SagaFlowFactory.createPagedGETFlow({
    apiRoute: `admin/1/clubservices/search/${clubId}`,
    itemType: 'services',
    successType: CLUB_SERVICES_SUCCESS,
    errorType: CLUB_SERVICES_ERROR
  });
};

export default function * clubServicesWatcher() {
  let token: string = '';
  let targetingParams: any = {};

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
      page,
      clubId,
      limit,
      params
    } = yield take([
      CLUB_SERVICES_REQUESTING,
      CLUB_SERVICES_RESET,
      CLUB_SERVICES_SEARCH_REQUESTING,
      CLUB_SERVICES_SEARCH_RESET,
      // AUTH_ACTIVE_STATE_CHANGE,
      // INITIAL_AUTH_ACTIVE_STATE_SET,
    ]);

    switch (type) {
      case CLUB_SERVICES_REQUESTING:
        yield fork(pagedFlow(clubId), token, page, limit, targetingParams);
        break;
      case CLUB_SERVICES_RESET:
        yield fork(pagedFlow(params.clubId), token, DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_LIMIT, targetingParams);
        break;
      case CLUB_SERVICES_SEARCH_REQUESTING:
        yield fork(pagedSearchFlow(clubId), token, page, limit, {...normalizeSearchParams(params)});
        break;
      case CLUB_SERVICES_SEARCH_RESET:
        yield fork(pagedFlow(clubId), token, DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_LIMIT, params);
        break;
      default:
    }
  }
}