import React, { ChangeEvent, PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as federationActions from '@src/store/federation/actions';
import { renderFormActions } from '@src/components/modals/ui';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
} from '@material-ui/core';
import { ModalContentOverlayLoader } from '@src/components/modals/ui';
import InputsValidator from '@src/components/forms/InputsValidator';

type Props = {
  federationState: FederationState;
  parentContext?: 'MODAL' | 'PAGE';
  onClose?: () => void;
  open: boolean;
  editFederation: (params: EditFederation) => any;
  getFederation: (params: GetFederation) => any;
};

type State = {
  name: string;
  abbreviation: string;
  email: string;
  phone: string;
  homepage: string;
  fax: string;
  streetAddress: string;
  zip: string;
  city: string;
  region: string;
  showErrors: boolean;
};

const initialState = {
  name: '',
  abbreviation: '',
  homepage: '',
  email: '',
  phone: '',
  fax: '',
  streetAddress: '',
  zip: '',
  city: '',
  region: '',
  showErrors: false,
};

class DashboardFormConnected extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const {
      federationState: {
        federation,
      }
    } = this.props;

    if (federation) {
      this.state = {
        ...this._parseFederationAttributes(federation),
      } as any;
    } else {
      this.state = initialState;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      federationState: {
        federation,
        requesting,
      },
      open,
    } = nextProps;

    // todo: need to figure out how not to interfere with store change
    if (requesting || open) {
      return; // Do nothing state is loading
    } else if (federation) {
      this.setState(this._parseFederationAttributes(federation));
    }
  }

  render() {
    const {
      name,
      abbreviation,
      homepage,
      email,
      phone,
      fax,
      streetAddress,
      zip,
      city,
      region,
    } = this.state;

    const {
      open,
      parentContext,
      federationState: {
        requesting,
        federation,
      }
    } = this.props;

    return (
      <Dialog
        open={open}
        fullWidth={true}
      >
        <DialogTitle>
          <FormattedMessage id={'scenes.golfFederation.info.form.title'}/>
        </DialogTitle>

        <InputsValidator values={{ name, abbreviation, streetAddress, city, zip, region, phone, email, homepage }}>
          {({ errorInputs, validateThenApply, validateValues }) => (
            <>
              <DialogContent>

                {requesting && <ModalContentOverlayLoader/>}
                <TextField
                  required={true}
                  fullWidth={true}
                  label={<FormattedMessage id={'scenes.golfFederation.info.form.nameLabel'}/>}
                  value={name}
                  margin="normal"
                  onChange={this._handleChange('name', validateValues)}
                  error={errorInputs.name}
                />
                <TextField
                  required={true}
                  fullWidth={true}
                  label={<FormattedMessage id={'scenes.golfFederation.info.form.abbreviationLabel'}/>}
                  value={abbreviation}
                  margin="normal"
                  onChange={this._handleChange('abbreviation', validateValues)}
                  error={errorInputs.abbreviation}
                />
                <TextField
                  required={true}
                  fullWidth={true}
                  label={<FormattedMessage id={'scenes.golfFederation.info.form.streetAddressLabel'}/>}
                  value={streetAddress}
                  margin="normal"
                  onChange={this._handleChange('streetAddress', validateValues)}
                  error={errorInputs.streetAddress}
                />
                <TextField
                  required={true}
                  fullWidth={true}
                  label={<FormattedMessage id={'scenes.golfFederation.info.form.postalCodeLabel'}/>}
                  value={zip}
                  margin="normal"
                  onChange={this._handleChange('zip', validateValues)}
                  error={errorInputs.zip}
                  inputProps={{ maxLength: 15 }}
                />
                <TextField
                  required={true}
                  fullWidth={true}
                  label={<FormattedMessage id={'scenes.golfFederation.info.form.cityLabel'}/>}
                  value={city}
                  margin="normal"
                  onChange={this._handleChange('city', validateValues)}
                  error={errorInputs.city}
                />
                <TextField
                  required={true}
                  fullWidth={true}
                  label={<FormattedMessage id={'scenes.golfFederation.info.form.regionLabel'}/>}
                  value={region}
                  margin="normal"
                  onChange={this._handleChange('region', validateValues)}
                  error={errorInputs.region}
                />
                <TextField
                  required={true}
                  fullWidth={true}
                  label={<FormattedMessage id={'scenes.golfFederation.info.form.phoneLabel'}/>}
                  value={phone}
                  margin="normal"
                  onChange={this._handleChange('phone', validateValues)}
                  error={errorInputs.phone}
                />
                <TextField
                  fullWidth={true}
                  label={<FormattedMessage id={'scenes.golfFederation.info.form.faxNumberLabel'}/>}
                  value={fax}
                  margin="normal"
                  onChange={this._handleChange('fax')}
                />
                <TextField
                  required={true}
                  fullWidth={true}
                  label={<FormattedMessage id={'scenes.golfFederation.info.form.emailLabel'}/>}
                  value={email}
                  margin="normal"
                  onChange={this._handleChange('email', validateValues)}
                  error={errorInputs.email}
                />
                <TextField
                  required={true}
                  fullWidth={true}
                  label={<FormattedMessage id={'scenes.golfFederation.info.form.homepageLabel'}/>}
                  value={homepage}
                  margin="normal"
                  onChange={this._handleChange('homepage', validateValues)}
                  error={errorInputs.homepage}
                />
              </DialogContent>

              {renderFormActions({
                id: federation && federation.id,
                parentContext,
                onClose: this._handleOnClose,
                onUpdate: validateThenApply.bind(this, this._handleUpdate),
                disabled: false,
              })}
            </>
          )}
        </InputsValidator>
      </Dialog>
    );
  }

  private _handleOnClose = (): void => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  }

  private _handleUpdate = () => {
    const {
      federationState: {
        federation,
      },
    } = this.props;

    if (federation) {
      const {
        editFederation,
      } = this.props;

      const {
        id,
        federationNumber,
        seasonStart,
        country,
        associationInfo: {
          idAssociation,
        },
      } = federation;

      const {
        name,
        abbreviation,
        homepage,
        email,
        phone,
        fax,
        streetAddress,
        zip,
        city,
        region,
      } = this.state;

      editFederation({
        associationId: idAssociation,
        country,
        federationId: id,
        federationNumber,
        name,
        abbreviation,
        homepage,
        email,
        phone,
        fax,
        streetAddress,
        zip,
        city,
        region,
        seasonStart,
        onComplete: this._handleOnComplete,
      });
    }
  }

  private _handleChange = (name: string, fn?: () => any) => (
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      this.setState({
        [name as any]: event.target.value,
      } as any, fn);
    }
  )

  private _handleOnComplete = ({ data, error }: APICallResult) => {
    if (error) {
      window.alert(error.message || 'Something seems to be wrong..');
    } else {
      // This is a bit brown but lets see how this pans out
      const { getFederation, federationState: { federation } } = this.props;

      // Close and fetch updated data from API
      this._handleOnClose();
      if (federation) {
        setTimeout(() => {
          getFederation({
            federationId: federation.id,
          });
        }, 200);
      }
    }
  }

  private _parseFederationAttributes = (federation: Federation) => {
    return {
      name: federation.name || '',
      abbreviation: federation.abbreviation || '',
      homepage: federation.homepage || '',
      email: federation.email || '',
      phone: federation.phone || '',
      fax: federation.fax || '',
      streetAddress: federation.streetAddress || '',
      zip: federation.zip || '',
      city: federation.city || '',
      region: federation.region || '',
    };
  }
}

const DashboardForm = connect((state: any) => ({
  federationState: state.federationReducer,
}), {
  editFederation: federationActions.editFederation,
  getFederation: federationActions.getFederation,
})(DashboardFormConnected);

export {
  DashboardForm
};