import {
  REPORT_ADD_ERROR,
  REPORT_ADD_REQUESTING,
  REPORT_ADD_SUCCESS,
  REPORT_DELETE_ERROR,
  REPORT_DELETE_REQUESTING,
  REPORT_DELETE_SUCCESS,
  REPORT_EDIT_ERROR,
  REPORT_EDIT_REQUESTING,
  REPORT_EDIT_SUCCESS,
  REPORT_GET_ERROR,
  REPORT_GET_REQUESTING,
  REPORT_GET_SUCCESS
} from './constants';
import { formDefaultErrorState, formDefaultRequestingState } from '@src/utils/storeUtils';

const initialState: Report2State = {
  report: undefined,
  requesting: false,
  successful: false,
  error: [],
};

export const report2Reducer = (state: Report2State = initialState, action: any) => {
  console.info('report2Reducer', action.type);
  switch (action.type) {
    case REPORT_GET_REQUESTING:
      return {
        ...formDefaultRequestingState(state),
        report: undefined,
      };

    case REPORT_ADD_REQUESTING:
    case REPORT_DELETE_REQUESTING:
    case REPORT_EDIT_REQUESTING:
      return formDefaultRequestingState(state);

    case REPORT_GET_ERROR:
    case REPORT_ADD_ERROR:
    case REPORT_DELETE_ERROR:
    case REPORT_EDIT_ERROR:
      return formDefaultErrorState(state, action);

    case REPORT_GET_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
        report: action.data,
      };

    case REPORT_ADD_SUCCESS:
    case REPORT_DELETE_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
      };

    case REPORT_EDIT_SUCCESS:
      const { data, } = action;
      return {
        ...state,
        requesting: false,
        successful: true,
        report: data,
      };

    default:
      return state;
  }
};