import {
  CLUB_COURSES_CHANGE_PAGE,
  CLUB_COURSES_REQUESTING,
  CLUB_COURSES_SEARCH_REQUESTING,
  CLUB_COURSES_SEARCH_RESET,
  CLUB_COURSES_SEARCH_CHANGED, CONDENSED_CLUB_COURSES_REQUESTING
} from './constants';
import { DEFAULT_PAGINATION_LIMIT } from '@src/assets/config';

export const fetchClubCourses = ({
  clubId,
  page,
  limit = DEFAULT_PAGINATION_LIMIT,
}: FetchClubCourses) => ({
  type: CLUB_COURSES_REQUESTING,
  clubId,
  page,
  limit,
});

export const changePage = ({
  page
}: ChangePageAction) => ({
  type: CLUB_COURSES_CHANGE_PAGE,
  page,
});

export const fetchSearch = ({
  page,
  limit = DEFAULT_PAGINATION_LIMIT,
  params
}: SearchPaginationAction) => ({
  type: CLUB_COURSES_SEARCH_REQUESTING,
  page,
  limit,
  params,
});

export const resetSearch = () => ({
  type: CLUB_COURSES_SEARCH_RESET
});

export const searchChanged = (search: ClubCoursesSearch) => ({
  type: CLUB_COURSES_SEARCH_CHANGED,
  search
});

export const fetchCondensedClubCourses = (clubId: number) => ({
  type: CONDENSED_CLUB_COURSES_REQUESTING,
  clubId,
});