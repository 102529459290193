import styled from 'styled-components';

export const LoginPageWrapper = styled.div`
  height: calc(100vh - 52px);
  min-height: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 2em;
  max-width: 42em;
  margin: 0 auto;
  box-sizing: border-box;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 2em;
`;

export const LoginFooter = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 4em;
`;

export const ErrorMessage = styled.p`
  color: darkred;
  text-align: center;
  font-size: 1.6em;
`;