import React, { Component } from 'react';
import { OffersScene } from '@src/components/scenes/offers/OffersScene';

class AdminOffers extends Component {
  render() {
    return (
      <OffersScene />
    );
  }
}

export {
  AdminOffers,
};