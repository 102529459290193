import { take, fork, select } from 'redux-saga/effects';
import {
  PARTNERS_CONDENSED_SUCCESS,
  PARTNERS_ERROR,
  PARTNERS_REQUESTING,
  PARTNERS_RESET,
  PARTNERS_SUCCESS,
} from './constants';
import { API_ROOT, APIRoute, DEFAULT_PAGINATION_LIMIT, DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import { AUTH_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { LOGIN_SUCCESS } from '@src/store/login/constants';
import { userIsFederationAdmin } from '@src/store/auth/selectors';
import { PARTNER_ADD_SUCCESS, PARTNER_DELETE_SUCCESS } from '@src/store/partner/constants';

/*
  GET /admin/1/partners
 */
const fetchPartnersFlow = SagaFlowFactory.createPagedGETFlow({
  apiRoute: APIRoute.GET_PARTNERS,
  itemType: 'partners',
  successType: PARTNERS_SUCCESS,
  errorType: PARTNERS_ERROR
});

/*
  GET /admin/1/partners?type=condensed
 */
const fetchPartnersCondensed = SagaFlowFactory.createGETFlow({
  urlRoute: `${API_ROOT}${APIRoute.GET_PARTNERS}`,
  successType: PARTNERS_CONDENSED_SUCCESS,
  errorType: PARTNERS_ERROR,
});

function* partnersWatcher() {
  let token: string = '';
  let isAdmin: boolean = false;

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
    isAdmin = yield select(userIsFederationAdmin);
  });

  while (true) {
    const {
      type,
      page,
      limit,
      params
    } = yield take([
      PARTNERS_REQUESTING,
      LOGIN_SUCCESS,
      PARTNERS_RESET,
      PARTNER_ADD_SUCCESS,
      PARTNER_DELETE_SUCCESS,
    ]);

    switch (type) {
      case LOGIN_SUCCESS:
      case PARTNER_ADD_SUCCESS:
      case PARTNER_DELETE_SUCCESS:
        if (isAdmin) {
          yield fork(fetchPartnersCondensed, token, 0, 0, {type: 'condensed'});
        }
        break;
      case PARTNERS_REQUESTING:
        yield fork(fetchPartnersFlow, token, page, limit, { ...params });
        break;
      case PARTNERS_RESET:
        yield fork(fetchPartnersFlow, token, DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_LIMIT);
        break;
      default:
    }
  }
}

export default partnersWatcher;