import { call, put } from 'redux-saga/effects';
import { API_ROOT, APIRoute } from '@src/assets/config';
import { defaultOnCompleteCall } from '@src/utils/storeUtils';
import { MESSAGES_GET_RECIPIENTS_SUCCESS, MESSAGES_GET_RECIPIENTS_ERROR } from '@src/store/messages/constants';
import download from 'downloadjs';

/*
  GET /admin/1/messages/senders
 */
export function* getRecipientsFlow(token: string, params: any, fileName: string, fileMimeType: string,
                                   onComplete?: (params: APICallResult) => any) {
    try {

        const blob = yield call(() => {
            const url = `${API_ROOT}${APIRoute.GET_MESSAGE_RECIPIENTS}`;
            let options: any = {
                method: 'POST',
                cache: 'no-cache',
                body: JSON.stringify(params),
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            };
            return fetch(url, options).then(response => response.blob());
        });

        if (blob) {
            download(blob, fileName, fileMimeType);

            defaultOnCompleteCall(onComplete, blob);
            yield put({
                type: MESSAGES_GET_RECIPIENTS_SUCCESS
            });
        } else {
            defaultOnCompleteCall(onComplete, undefined, '-');

            yield put({
                type: MESSAGES_GET_RECIPIENTS_ERROR,
                error: blob
            });

        }
    } catch (error) {
        defaultOnCompleteCall(onComplete, undefined, error);
        yield put({ type: MESSAGES_GET_RECIPIENTS_ERROR, error });
    }
}