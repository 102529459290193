import {
  VENDORS_REQUESTING,
  FEDERATION_VENDORS_CHANGE_PAGE, VENDORS_RESET,
} from './constants';
import { DEFAULT_PAGINATION_LIMIT } from '@src/assets/config';

export const fetchVendors = ({
  page,
  limit = DEFAULT_PAGINATION_LIMIT,
  params
}: TablePaginationAction) => {
  return {
    type: VENDORS_REQUESTING,
    page,
    limit,
    params
  };
};

export const changePage = ({
  page
}: ChangePageAction) => {
  return {
    type: FEDERATION_VENDORS_CHANGE_PAGE,
    page,
  };
};

export const resetVendors = () => {
  return {
    type: VENDORS_RESET,
  };
};