import React, { Component, createRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as clubGreenCardActions from '@src/store/clubGreencard/actions';
import {
  Dialog,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { ModalContentOverlayLoader } from '@src/components/modals/ui';
import { GreenCardForm } from '@src/components/scenes/club/greenCard/GreenCardForm';

interface Props {
  greenCardId: number | undefined;
  open: boolean;
  onClose: () => void;
  getClubGreenCard: (params: MessageGet) => any;
  clubGreenCardState: ClubGreenCardState;
}

class GreenCardModalConnected extends Component<Props, {}> {
  actionsContainerRef = createRef<HTMLDivElement>();

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      greenCardId,
      getClubGreenCard
    } = this.props;

    if (nextProps.greenCardId !== greenCardId) {
      if (nextProps.greenCardId) {
        getClubGreenCard({
          id: nextProps.greenCardId,
        });
      }
    }
  }

  render() {
    const {
      open,
      greenCardId,
      onClose,
      clubGreenCardState: {
        requesting
      }
    } = this.props;

    return (
      <Dialog
        open={open}
        fullWidth={true}
      >
        <DialogTitle>
          {greenCardId && <FormattedMessage id={'scenes.golfClub.greenCard.modal.edit.title'} />}
          {!greenCardId && <FormattedMessage id={'scenes.golfClub.greenCard.modal.add.title'} />}
        </DialogTitle>
        <DialogContent>
          {requesting && <ModalContentOverlayLoader/>}
          <GreenCardForm
            greenCardId={greenCardId}
            parentContext={'MODAL'}
            onClose={onClose}
            actionsContainerRef={this.actionsContainerRef}
          />
        </DialogContent>

        <div ref={this.actionsContainerRef} />
      </Dialog>
    );
  }
}

const GreenCardModal = connect((state: any) => ({
  clubGreenCardState: state.clubGreenCardReducer,
}), {
  getClubGreenCard: clubGreenCardActions.getClubGreenCard
})(GreenCardModalConnected);

export {
  GreenCardModal,
};