import React from 'react';
import { connect } from 'react-redux';
import PersonsSearch from '@src/components/persons/PersonsSearch';
import CreatePerson from '@src/components/persons/CreatePerson';
import Typography from '@material-ui/core/Typography/Typography';
import Tabs from '@material-ui/core/Tabs/Tabs';
import Tab from '@material-ui/core/Tab/Tab';
import { Theme, withStyles } from '@material-ui/core';
import { Classes } from 'jss';
import * as personsActions from '../../store/persons/actions';
import { targetIdParamsChanged } from '@src/utils/storeUtils';

type State = {
  tabIdx: number;
};

type Props = {
  targetIdParams: TargetIdParams;
  pickerTitle?: React.ReactNode;
  onPersonPicked: (person: Person) => any;
  classes: Classes;
  showAdd?: boolean;
  selectedPersonIds?: number[];
  setTargetIdParams: (args: SetTargetIdParams) => any;
  currentTargetIdParams: TargetIdParams;
  customNode?: React.ReactNode;
};

class PersonPicker extends React.Component<Props, State> {
  state: State = {
    tabIdx: 0,
  };

  UNSAFE_componentWillMount() {
    const {
      setTargetIdParams,
      currentTargetIdParams,
    } = this.props;

    if (targetIdParamsChanged(currentTargetIdParams, this.props.targetIdParams)) {
      setTargetIdParams({
        resetState: true,
        targetIdParams: this.props.targetIdParams,
      });
    }
  }

  render() {
    const {
      pickerTitle = 'Lisää jäsen',
      classes,
    } = this.props;

    return (
      <div className={classes.root}>
        {pickerTitle && (
          <Typography component="h2" className={classes.title}>
            {pickerTitle}
          </Typography>
        )}
        {this._renderContent()}
      </div>
    );
  }

  private _renderContent = () => {
    const { tabIdx } = this.state;
    const {
      classes,
      showAdd = true,
      targetIdParams,
      currentTargetIdParams,
    } = this.props;

    // We need to block rendering until the store has updated to correct
    // targettingId params
    if (targetIdParamsChanged(currentTargetIdParams, targetIdParams)) {
      return null;
    }

    if (!showAdd) {
      return this._renderPersonsSearch();
    }

    return (
      <>
        <Tabs
          value={tabIdx}
          onChange={this._handleChange}
          indicatorColor={'primary'}
          textColor={'primary'}
        >
          <Tab label={'Hae jäseniä'} />
          <Tab label={'Lisää uusi'} />
        </Tabs>
        <div className={classes.tabContentContainer}>
          {tabIdx === 0 && this._renderPersonsSearch()}
          {tabIdx === 1 && <CreatePerson />}
        </div>
      </>
    );
  }

  private _renderPersonsSearch = () => {
    const {
      onPersonPicked,
      selectedPersonIds = [],
      customNode,
    } = this.props;

    return (
      <PersonsSearch
        onPersonPicked={onPersonPicked}
        selectedPersonIds={selectedPersonIds}
        customNode={customNode}
      />
    );
  }

  private _handleChange = (event: any, tabIdx: number) => {
    this.setState({ tabIdx });
  }
}

const styles = (theme: Theme) => ({
  root: {

  },
  title: {
    fontSize: '1.8em',
    marginBottom: '.6em',
  },
  tabContentContainer: {
    marginTop: '2.6em',
  }
});

export default withStyles(styles)(connect(
  (state: StoreState) => ({
    currentTargetIdParams: state.personsReducer.targetIdParams,
  }),
  {
    setTargetIdParams: personsActions.setTargetIdParams,
  }
)(PersonPicker));
