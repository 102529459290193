import React, { ReactNode, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField, FormHelperText, MenuItem, FormControlLabel, Checkbox
} from '@material-ui/core';
import { renderFormActions } from '@src/components/modals/ui';
import ScoreAdjustmentPicker from '@src/components/scenes/member/components/ScoreAdjustmentPicker';
import { FormattedMessage } from 'react-intl';
import InputsValidator from '@src/components/forms/InputsValidator';
import { UpdateAdjustmentContext } from './ScoreAdjustmentsModal';

export interface ShowEditArgs {
  adjustmentId?: number;
  adjustment: string;
  adjustmentAmount: number | string;
  previousAffectedScoresAmount: number;
  resetLowHandicapIndex: boolean;
}

export interface ChildrenArgs {
  ref: React.RefObject<any>;
  showEdit(args: ShowEditArgs): void;
}

interface OwnProps {
  children(args: ChildrenArgs): ReactNode;
}

type Props = OwnProps;

function AcceptableScoreAdjustmentEditor({ children }: Props, ref) {
  const [open, setOpen] = useState<boolean>(false);
  const [resetLowHCPIndex, setResetLowHCPIndex] = useState<boolean>(false);
  const [adjustmentId, setAdjustmentId] = useState<number | undefined>(undefined);
  const [adjustment, setAdjustment] = useState<string>('exceptionalScore');
  const [adjustmentAmount, setAdjustmentAmount] = useState<number | string>('');
  const [affectedScores, setAffectedScores] = useState<number | string>('1');

  const onClose = () => setOpen(false);

  return (
    <UpdateAdjustmentContext.Consumer>
      {({ handleAdjustmentEdit }) => (
        <InputsValidator values={{ adjustment, adjustmentAmount }}>
          {({ errorInputs, validateThenApply }) => (
            <>
              <Dialog open={open} disableBackdropClick={true} maxWidth={'xs'} fullWidth={true}>
                <DialogTitle>
                  <FormattedMessage id={'strings.editAdjustment'} />
                </DialogTitle>
                <DialogContent style={{ paddingTop: 16 }}>
                  <Grid container={true} spacing={2}>
                    <Grid item={true} xs={12}>
                      <ScoreAdjustmentPicker
                        disabled={true}
                        required={true}
                        error={errorInputs.adjustment}
                        fullWidth={true}
                        value={adjustment}
                        margin={'none'}
                        onChange={value => {
                          if (value) {
                            setAdjustment(value);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <TextField
                        error={errorInputs.adjustmentAmount}
                        fullWidth={true}
                        required={true}
                        label={<FormattedMessage id={'strings.amount'} />}
                        value={adjustmentAmount}
                        onChange={({ target: { value } }) => {
                          setAdjustmentAmount(value);
                        }}
                        inputProps={{
                          onFocus: ({ target }) => target.select()
                        }}
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <FormControl
                        fullWidth={true}
                        disabled={adjustment === 'penaltyScore'}
                      >
                        <InputLabel>
                          <FormattedMessage id={'strings.affectedScores'} />
                        </InputLabel>
                        <Select
                          value={affectedScores}
                          onChange={({ target: { value } }) => {
                            setAffectedScores(value as number | string);
                          }}
                        >
                          {Array.from({ length: 20 }).map((_, idx) => (
                            <MenuItem value={idx} key={idx}>
                              {idx + 1}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          <FormattedMessage id={'strings.affectedScoresHelp'} />
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    {adjustment === 'handicapReview' && (
                      <Grid item={true} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={resetLowHCPIndex}
                              onChange={(_, checked) => setResetLowHCPIndex(checked)}
                              value={'resetLowHCPIndex'}
                              color="primary"
                            />
                          }
                          label={<FormattedMessage id={'strings.resetLowHandicapIndex'} />}
                        />
                      </Grid>
                    )}
                  </Grid>
                </DialogContent>
                {renderFormActions({
                  id: -1,
                  parentContext: 'MODAL',
                  onClose,
                  onUpdate: validateThenApply.bind(window, () => {
                    onClose();
                    handleAdjustmentEdit({
                      acceptableScoreAdjustmentId: adjustmentId,
                      adjustmentAmount: parseInt(`${adjustmentAmount}`, 10),
                      resetLowHandicapIndex: resetLowHCPIndex,
                      previousAffectedScoresAmount: parseInt(`${affectedScores}`, 10),
                      adjustmentType: adjustment,
                    });
                  }),
                })}
              </Dialog>
              {children({
                showEdit(args): void {
                  const {
                    adjustmentId,
                    adjustment,
                    adjustmentAmount,
                    previousAffectedScoresAmount,
                    resetLowHandicapIndex,
                  } = args;

                  setAdjustmentId(adjustmentId);
                  setAdjustment(adjustment);
                  setAdjustmentAmount(adjustmentAmount);
                  setAffectedScores(previousAffectedScoresAmount);
                  setResetLowHCPIndex(resetLowHandicapIndex);
                  setOpen(true);
                },
                ref,
              })}
            </>
          )}
        </InputsValidator>
      )}
    </UpdateAdjustmentContext.Consumer>
  );
}

export default React.forwardRef(AcceptableScoreAdjustmentEditor);
