import {
  COMPANY_ADD_REQUESTING,
  COMPANY_DELETE_REQUESTING,
  COMPANY_EDIT_REQUESTING,
  COMPANY_GET_REQUESTING
} from '@src/store/company/constants';

export const getCompany = ({
  id
}: CompanyGet) => {
  return {
    type: COMPANY_GET_REQUESTING,
    companyId: id,
  };
};

export const addCompany = (params: CompanyAdd) => {
  return {
    type: COMPANY_ADD_REQUESTING,
    params
  };
};

export const editCompany = (params: CompanyEdit) => {
  return {
    type: COMPANY_EDIT_REQUESTING,
    companyId: params.id,
    params
  };
};

export const deleteCompany = ({
  id,
  onComplete,
}: CompanyDelete) => {
  return {
    type: COMPANY_DELETE_REQUESTING,
    companyId: id,
    params: {
      onComplete,
    },
  };
};