import React, { PureComponent, ReactNode } from 'react';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import {
  ContentHeader,
  ContentWrap,
  TransparentOverlayLoader
} from '@src/components/layouts/ui';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ClubCourseTeeModal } from '@src/components/scenes/club/course/tee/ClubCourseTeeModal';
import { ClubCourseHoleModal } from '@src/components/scenes/club/course/hole/ClubCourseHoleModal';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  withStyles,
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Divider,
  Button, Tooltip,
  Input, FormControl, InputLabel, Theme, WithStyles,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { EditIconButton, EditIconFab } from '@src/components/buttons/buttons';
import CourseTitle from '@src/components/scenes/club/course/CourseTitle';
import { isCourseDraft } from '@src/utils/courseUtils';
import { get, find } from 'lodash';
import { cleanTrailingSlash } from '@src/utils/storeUtils';
import { getClubCourse, deleteClubCourse } from '@src/store/clubCourse/actions';
import { fetchCondensedClubCourses } from '@src/store/clubCourses/actions';
import ClubCourseBasicInfoCard from '@src/components/scenes/club/course/ClubCourseBasicInfoCard';
import ClubCoursePartialsCard from '@src/components/scenes/club/course/ClubCoursePartialsCard';
import ClubCourseParentsCard from '@src/components/scenes/club/course/ClubCourseParentsCard';
import { FederationRoleScopeGuard } from '@src/components/access-control/FederationRoleScopeGuard';
import DeleteConfirm, { DeleteConfirmChildren } from '@src/components/headless/DeleteConfirm';
import CourseEditAction from '@src/components/scenes/club/course/CourseEditAction';
import { FederationClubAdminRoleGuard } from '@src/components/access-control/FederationClubAdminRoleGuard';

const styles = (theme: Theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main
  },
  card: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  divider: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4)
  },
  courseHoleTable: {
    '@global': {
      'tr th:first-child, tr td:first-child, tr th:last-child, tr td:last-child': {
        paddingLeft: theme.spacing(),
        paddingRight: theme.spacing(),
      },
      // 'tr th:last-child, tr td:last-child': {
      //   paddingLeft: theme.spacing.unit,
      //   paddingRight: theme.spacing.unit,
      // },
    }
  }
});

interface StateProps {
  roleInfo?: RoleInfo;
}

interface StateProps {
  clubCourseState: ClubCourseState;
}

interface DispatchProps {
  getClubCourse(params: ClubCourseGet): void;
  fetchCondensedClubCourses(clubId: number): void;
  deleteClubCourse(params: ClubCourseDelete): void;
}

type Props = StateProps & DispatchProps & RouteComponentProps<any> & WithStyles<typeof styles>;

type State = {
  showOverlayLoader: boolean;
  teeModalOpen: boolean;
  holeModalOpen: boolean;
  courseTeeIdToEdit?: number;
  courseHoleIdToEdit?: number;
};

class ClubCourseConnected extends PureComponent<Props, State> {
  readonly state: State = {
    teeModalOpen: false,
    courseTeeIdToEdit: undefined,
    holeModalOpen: false,
    courseHoleIdToEdit: undefined,
    showOverlayLoader: false,
  };

  UNSAFE_componentWillMount() {
    this._fetchCourseData();
  }

  render() {
    const {
      teeModalOpen,
      courseTeeIdToEdit,
      holeModalOpen,
      courseHoleIdToEdit,
      showOverlayLoader,
    } = this.state;

    const {
      clubCourseState: {
        clubCourse,
        requesting,
      },
      match: {
        params
      }
    } = this.props;

    const { courseId } = params;

    return (
      <>
        <HeadHelmet titleId={'navigation.golfClubCourses'} />

        <ContentWrap>
          <ContentHeader>
            <CourseTitle
              course={clubCourse}
              showDraftCreatedOn={true}
              children={this._copyFieldAction}
            />
          </ContentHeader>

          <TransparentOverlayLoader visible={showOverlayLoader || requesting} />

          {clubCourse && (
            <ClubCourseBasicInfoCard
              course={clubCourse}
              isEditable={this._isCourseEditEnabled}
            />
          )}

          <FederationRoleScopeGuard>
            {this._renderCourseRelations()}
          </FederationRoleScopeGuard>

          {this._renderCourseTees()}
          {this._renderCourseHoles()}

          <ClubCourseTeeModal
            clubCourseId={courseId}
            clubCourseTeeId={courseTeeIdToEdit}
            open={teeModalOpen}
            disableStatus={this._isCoursePartial}
            disableDescription={this._isCoursePartial}
            onClose={(shouldRefetch) => {
              this.setState({ teeModalOpen: false }, () => {
                if (shouldRefetch === true) {
                  this._fetchCourseData();
                }
              });
            }}
          />

          <ClubCourseHoleModal
            clubCourseId={courseId}
            holeId={courseHoleIdToEdit}
            open={holeModalOpen}
            onClose={(shouldRefetch) => {
              this.setState({ holeModalOpen: false }, () => {
                if (shouldRefetch === true) {
                  this._fetchCourseData();
                }
              });
            }}
          />

        </ContentWrap>
      </>
    );
  }

  get _isCoursePartial() {
    const { clubCourseState: { clubCourse } } = this.props;
    return get(clubCourse, 'type', '') === 'PARTIAL';
  }

  get _clubId() {
    const { match: { params } } = this.props;
    return parseInt(params.clubId, 10);
  }

  get _pathCourseId() {
    const {
      match: {
        params
      }
    } = this.props;

    const { courseId } = params;

    return parseInt(courseId, 10);
  }

  get _isCourseDataIncomplete() {
    return !get(this.props, 'clubCourseState.clubCourse.completed', true);
  }

  get _isCourseEditEnabled() {
    const { clubCourseState: { clubCourse } } = this.props;

    if (!clubCourse) {
      return false;
    }

    return isCourseDraft(clubCourse);
  }

  get _draftCourseId() {
    return get(this.props, 'clubCourseState.clubCourse.draftCourseId', null);
  }

  get _copyFieldAction() {
    if (!this._isCourseEditEnabled) {
      return (
        <FederationRoleScopeGuard>
          <CourseEditAction
            courseId={this._pathCourseId}
            draftCourseId={this._draftCourseId}
            onSuccess={this._redirectToCourseId}
            onBeforeEdit={() => this.setState({ showOverlayLoader: true })}
            onEditDone={() => this.setState({ showOverlayLoader: false })}
          >
            {({ handleEditClick }) => (
              <Button
                color={'primary'}
                variant="contained"
                onClick={handleEditClick}
              >
                <FormattedMessage id={'buttons.edit'}/>
              </Button>
            )}
          </CourseEditAction>
        </FederationRoleScopeGuard>
      );
    }

    if (this._isCourseDataIncomplete) {
      return (
        <FederationRoleScopeGuard>
          <Tooltip title={<FormattedMessage id={'strings.courseDataIncomplete'}/>}>
            <Button variant={'contained'}>
              <FormattedMessage id={'buttons.publish'}/>
            </Button>
          </Tooltip>
        </FederationRoleScopeGuard>
      );
    }

    return (
      <>
        <FederationRoleScopeGuard>
          <DeleteConfirm>
            {({ showConfirm }: DeleteConfirmChildren) => (
              <Button
                style={{ marginRight: 16 }}
                onClick={() => {
                  showConfirm({
                    callback: this._handleDeleteFromConfirm
                  });
                }}
              >
                <FormattedMessage id={'buttons.discardChanges'}/>
              </Button>
            )}
          </DeleteConfirm>
          <CourseEditAction
            courseId={this._pathCourseId}
            draftCourseId={this._draftCourseId}
          >
            {({ handlePublishClick }) => (
              <Button
                color={'primary'}
                variant={'contained'}
                onClick={handlePublishClick}
              >
                <FormattedMessage id={'buttons.publish'}/>
              </Button>
            )}
          </CourseEditAction>
        </FederationRoleScopeGuard>
      </>
    );
  }

  get _course() {
    const { clubCourseState: { clubCourse } } = this.props;
    return clubCourse;
  }

  get _courseChildren(): ClubCourseSibling[] {
    const children = get(this._course, 'children');
    return (children !== null && children) ? children : [];
  }

  get _frontNineChild(): ClubCourseSibling | undefined {
    return find(this._courseChildren, { 'courseType': 'FRONT' });
  }

  get _frontNineChildId(): number | undefined {
    return get(this._frontNineChild, 'idCourse');
  }

  get _backNineChild(): ClubCourseSibling | undefined {
    return find(this._courseChildren, { 'courseType': 'BACK' });
  }

  get _backNineChildId() {
    return get(this._backNineChild, 'idCourse');
  }

  _redirectToCourseId = (newCourseId: number | string) => {
    const {
      match: {
        url,
        params: {
          courseId,
        },
      },
      history,
    } = this.props;

    if (courseId) {
      history.push(cleanTrailingSlash(url.replace(courseId, `${newCourseId}`)));
    }
  }

  _fetchCourseData = () => {
    const { getClubCourse, fetchCondensedClubCourses } = this.props;

    getClubCourse({ id: this._pathCourseId });
    fetchCondensedClubCourses(this._clubId);
  }

  _renderCourseRelations = (): ReactNode => {
    const { classes } = this.props;

    if (!this._course) {
      return null;
    } else if (this._isCoursePartial && this._course) {
      return (
        <ClubCourseParentsCard
          className={classes.card}
          courseId={this._course.id}
          parents={this._course.parents}
          disabled={this._isCourseEditEnabled}
          onViewClick={this._redirectToCourseId}
          onChangeSuccess={this._fetchCourseData}
        />
      );
    }

    return (
      <ClubCoursePartialsCard
        className={classes.card}
        clubId={this._clubId}
        disabled={this._isCourseEditEnabled}
        parentCourse={this._course}
        frontNinePartial={this._frontNineChild}
        backNinePartial={this._backNineChild}
        onViewClick={this._redirectToCourseId}
        onChangeSuccess={this._fetchCourseData}
      />
    );
  }

  _renderCourseTees = (): ReactNode => {
    const {
      clubCourseState: {
        clubCourse,
      },
      classes
    } = this.props;

    if (clubCourse) {
      const { tees } = clubCourse;

      if (!tees || tees.length === 0) {
        return null;
      }

      return (
        <Card className={classes.card}>
          <CardHeader
            title={<FormattedMessage id={'scenes.golfClub.course.teeForm.title'} />}
          />
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><FormattedMessage id={'strings.description'}/></TableCell>
                  <TableCell><FormattedMessage id={'scenes.golfClub.course.teeForm.ratingMen'} /></TableCell>
                  <TableCell><FormattedMessage id={'scenes.golfClub.course.teeForm.slopeMen'} /></TableCell>
                  <TableCell><FormattedMessage id={'scenes.golfClub.course.teeForm.ratingWomen'} /></TableCell>
                  <TableCell><FormattedMessage id={'scenes.golfClub.course.teeForm.slopeWomen'} /></TableCell>
                  <TableCell><FormattedMessage id={'strings.state'}/></TableCell>
                  <TableCell/>
                </TableRow>
              </TableHead>
              <TableBody>
                {Boolean(tees) && tees.map((tee: CourseTee) =>
                  <TableRow key={tee.id}>
                    <TableCell>{tee.description}</TableCell>
                    <TableCell>{tee.ratingMen}</TableCell>
                    <TableCell>{tee.slopeMen}</TableCell>
                    <TableCell>{tee.ratingWomen}</TableCell>
                    <TableCell>{tee.slopeWomen}</TableCell>
                    <TableCell>{tee.status}</TableCell>
                    <TableCell align={'right'}>
                      {this._isCourseEditEnabled && (
                          <FederationClubAdminRoleGuard>
                            <EditIconButton
                              onClick={() => this.setState({ courseTeeIdToEdit: tee.number, teeModalOpen: true })}
                            />
                          </FederationClubAdminRoleGuard>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      );
    }
    return null;
  }

  _renderCourseHoles = (): ReactNode => {
    const {
      clubCourseState: {
        clubCourse,
      },
      classes,
    } = this.props;

    if (clubCourse) {
      const { holes } = clubCourse;

      if (!holes || holes.length === 0) {
        return null;
      }

      return (
       <>
         <Divider className={classes.divider}/>

         <Typography gutterBottom={true} variant="h5">
           <FormattedMessage id={'scenes.golfClub.course.holes'} />
         </Typography>

         <Grid container={true} spacing={2}>
           {Boolean(holes) && holes.map((hole: CourseHole, index: number) =>
             <Grid item={true} xs={12} sm={6} md={4} key={index}>
               <Card className={classes.card}>
                 <CardHeader
                   avatar={<Avatar aria-label="Hole" className={classes.avatar}>{hole.holeNumber}</Avatar>}
                   action={this._isCourseEditEnabled && (
                       <FederationClubAdminRoleGuard>
                         <EditIconFab
                          style={{ marginRight: 8 }}
                          onClick={() => this.setState({ courseHoleIdToEdit: hole.id, holeModalOpen: true })}
                         />
                       </FederationClubAdminRoleGuard>
                   )}
                 />
                 <CardContent>
                   <Grid container={true}>
                     <Grid item={true} xs={4}>
                       <FormControl fullWidth={true} margin={'normal'}>
                         <InputLabel htmlFor={`coursePar-${hole.holeNumber}`}>
                           <FormattedMessage id={'scenes.golfClub.course.par'} />
                         </InputLabel>
                         <Input
                           readOnly={true}
                           disableUnderline={true}
                           id={`coursePar-${hole.holeNumber}`}
                           value={hole.holePar}
                           className={classes.textField}
                         />
                       </FormControl>
                       <FormControl fullWidth={true} margin={'normal'}>
                         <InputLabel htmlFor={`coursesHCP-${hole.holeNumber}`}>
                           <FormattedMessage id={'scenes.golfClub.course.hole.sHCP'} />
                         </InputLabel>
                         <Input
                           readOnly={true}
                           disableUnderline={true}
                           id={`coursesHCP-${hole.holeNumber}`}
                           value={hole.holeStrokeHcp}
                           className={classes.textField}
                         />
                       </FormControl>
                       <FormControl fullWidth={true} margin={'normal'}>
                         <InputLabel htmlFor={`coursemHCP-${hole.holeNumber}`}>
                           <FormattedMessage id={'scenes.golfClub.course.hole.mHCP'} />
                         </InputLabel>
                         <Input
                           readOnly={true}
                           disableUnderline={true}
                           id={`coursemHCP-${hole.holeNumber}`}
                           value={hole.holeHcp}
                           className={classes.textField}
                         />
                       </FormControl>
                     </Grid>
                     <Grid item={true} xs={8}>
                       <Table size={'small'} className={classes.courseHoleTable}>
                         <TableHead>
                           <TableRow>
                             <TableCell style={{ width: 110 }}>
                               <FormattedMessage id={'scenes.golfClub.course.tee'} />
                             </TableCell>
                             <TableCell><FormattedMessage id={'scenes.golfClub.course.par'} /></TableCell>
                             <TableCell><FormattedMessage id={'scenes.golfClub.course.length'} /></TableCell>
                           </TableRow>
                         </TableHead>
                         <TableBody>
                           {Boolean(hole.tees) && hole.tees.map((tee: CourseHoleTee) =>
                             <TableRow key={tee.id}>
                               <TableCell>{tee.description}</TableCell>
                               <TableCell>{tee.par}</TableCell>
                               <TableCell>{tee.length}</TableCell>
                             </TableRow>
                           )}
                         </TableBody>
                       </Table>
                     </Grid>
                   </Grid>
                 </CardContent>
               </Card>
             </Grid>
           )}
         </Grid>
       </>
      );
    }
    return null;
  }

  _handleDeleteFromConfirm = (confirmed: boolean) => {
    if (confirmed) {
      this._handleCourseDelete();
    }
  }

  _handleCourseDelete = () => {
    this.props.deleteClubCourse({
      id: this._pathCourseId,
      clubId: this._clubId,
      onComplete: () => {
        this._redirectToCourseId('');
      },
    });
  }
}

export const ClubCourse = withStyles(styles)(withRouter(connect<StateProps, DispatchProps, {}, StoreState>(state => ({
  clubCourseState: state.clubCourseReducer,
  roleInfo: state.authReducer.roleInfo,
}), {
  getClubCourse, fetchCondensedClubCourses, deleteClubCourse,
})(ClubCourseConnected)));
