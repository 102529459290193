import React, { PureComponent, Fragment, ReactNode, ChangeEvent } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Button,
  FormControl,
  TextField,
} from '@material-ui/core';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import {
  ContentHeader,
  ContentHeaderTitle,
  ContentWrap,
  SearchWrapper,
  SearchActions, ContentLoader
} from '@src/components/layouts/ui';
import Select from '@src/components/forms/Select';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import * as clubServicesActions from '@src/store/clubServices/actions';
import { DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import { ClubServiceModal } from '@src/components/scenes/club/services/ClubServiceModal';
import { Pagination } from '@src/components/pagination/Pagination';
import { handleSearchFieldOnKeyDownEnterSniff } from '@src/utils/storeUtils';
import { EditIconButton } from '@src/components/buttons/buttons';

interface Props extends RouteComponentProps<any>, WrappedComponentProps {
  clubServices: ClubServicesState;
  fetchClubServices: (params: FetchClubServices) => any;
  changePage: (params: ChangePageAction) => any;
  searchChanged: (params: ClubServicesSearch) => any;
  fetchSearch: (params: ClubSearchPaginationAction) => any;
  resetSearch: (clubId: number) => any;
}

type State = {
  modalOpen: boolean;
  serviceIdToEdit?: number;
};

class ClubServices extends PureComponent<Props, State> {
  state = {
    modalOpen: false,
    serviceIdToEdit: undefined
  };

  UNSAFE_componentWillMount() {
    const {
      match: {
        params
      },
      fetchClubServices,
    } = this.props;

    const { clubId } = params;
    fetchClubServices({ clubId, page: DEFAULT_PAGINATION_PAGE });
  }

  render() {
    const { modalOpen, serviceIdToEdit } = this.state;
    const {
      clubServices: {
        requesting
      },
      match: {
        params
      },
    } = this.props;

    const { clubId } = params;

    return (
      <Fragment>
        <HeadHelmet titleId={'navigation.golfClubServices'} />
        <ContentWrap>
          <ContentHeader>
            <ContentHeaderTitle>
              <FormattedMessage id={'navigation.golfClubServices'}/>
            </ContentHeaderTitle>
            <Button variant="contained" onClick={() => this.setState({serviceIdToEdit: undefined, modalOpen: true})}>
              <FormattedMessage id={'scenes.golfClub.services.addNewButtonLabel'}/>
            </Button>
            {this._renderServiceSearch()}
          </ContentHeader>

          <ContentLoader visible={requesting}/>
          {this._renderServices()}
          {this._renderPagination()}

          <ClubServiceModal
            clubId={clubId}
            clubServiceId={serviceIdToEdit}
            open={modalOpen}
            onClose={() => {
              this.setState({modalOpen: false});
            }}
          />
        </ContentWrap>
      </Fragment>
    );
  }

  private _renderServices = (): ReactNode => {
    const {
      clubServices: {
        requesting,
        successful,
        pagedServices,
        pagination: {
          page
        }
      }
    } = this.props;

    if (requesting) {
      return null;
    }

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><FormattedMessage id={'strings.name'} /></TableCell>
            <TableCell><FormattedMessage id={'strings.price'} /></TableCell>
            <TableCell><FormattedMessage id={'strings.phone'} /></TableCell>
            <TableCell><FormattedMessage id={'strings.email'} /></TableCell>
            <TableCell><FormattedMessage id={'strings.state'} /></TableCell>
            <TableCell/>
          </TableRow>
        </TableHead>
        <TableBody>
          {successful && pagedServices && Object.keys(pagedServices[page]).map((key: string) => (
            <TableRow key={pagedServices[page][key].id}>
            <TableCell>{pagedServices[page][key].name}</TableCell>
            <TableCell>{pagedServices[page][key].price}</TableCell>
            <TableCell>{pagedServices[page][key].phone}</TableCell>
            <TableCell>{pagedServices[page][key].email}</TableCell>
            <TableCell>{pagedServices[page][key].status}</TableCell>
            <TableCell size={'small'}>
              <EditIconButton
                onClick={() => this.setState({serviceIdToEdit: pagedServices[page][key].id, modalOpen: true})}
              />
            </TableCell>
          </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  private _renderServiceSearch = (): ReactNode => {
    const {
      clubServices: {
        requesting,
        search,
        searchActive
      },
    } = this.props;

    const disableSearch: boolean = this._disableSearch();

    return (
      <SearchWrapper>
        <TextField
          disabled={requesting}
          label={<FormattedMessage id={'strings.searchTerm'} />}
          value={search.name}
          onChange={this._searchTermChanged}
          style={{ marginRight: '1em' }}
          onKeyDown={(e: React.KeyboardEvent<any>) =>
            handleSearchFieldOnKeyDownEnterSniff(e, this._performSearch, disableSearch)
          }
        />
        <FormControl disabled={requesting}>
          <Select
            disabled={requesting}
            selected={search.status}
            options={[
              {
                id: 'INACTIVE',
                name: this.props.intl.formatMessage({ id: 'strings.statusInactive' }),
              },
              {
                id: 'ACTIVE',
                name: this.props.intl.formatMessage({ id: 'strings.statusActive' }),
              }
            ]}
            onChangeCallback={(value) => {
              this._searchStatusChanged(value || '');
            }}
            labelText={<FormattedMessage id={'scenes.members.search.activatedInputPlaceholder'}/>}
            placeholderText={<FormattedMessage id={'scenes.members.search.activatedInputPlaceholder'}/>}
          />
        </FormControl>
        <SearchActions
          isSearchActive={searchActive}
          isApiRequestActive={requesting}
          performSearchAction={this._performSearch}
          resetSearchAction={this._resetSearch}
          isSearchDisabled={disableSearch}
        />
      </SearchWrapper>
    );
  }

  private _disableSearch = (): boolean => {
    const {
      clubServices: {
        requesting,
        search,
      }
    } = this.props;

    return (requesting || (!search.name && !search.status));
  }

  private _searchTermChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const { searchChanged } = this.props;
    const {
      currentTarget: {
        value,
      }
    } = e;

    searchChanged({
      name: value
    });
  }

  private _searchStatusChanged = (value: any) => {
    const { searchChanged } = this.props;
    const status: ActivityStatus = value ? value : '';
    searchChanged({
      status
    });
  }

  private _performSearch = () => {
    const {
      fetchSearch,
      clubServices: {
        search
      },
      match: {
        params
      }
    } = this.props;

    const { clubId } = params;

    fetchSearch({
      page: DEFAULT_PAGINATION_PAGE,
      clubId: clubId,
      params: search as any
    });
  }

  private _resetSearch = () => {
    const {
      resetSearch,
      match: {
        params
      }
    } = this.props;

    const { clubId } = params;

    resetSearch(clubId);
  }

  private _renderPagination = (): ReactNode => {
    const {
      clubServices: {
        pagination: {
          page,
          limit,
          totalCount
        }
      }
    } = this.props;

    if (totalCount > limit) {
      return <Pagination page={page} limit={limit} totalCount={totalCount} onPageChange={this._handlePageChange}/>;
    }

    return null;
  }

  private _handlePageChange = (page: number) => {
    const {
      clubServices: {
        pagedServices,
        searchActive,
        search
      },
      fetchClubServices,
      fetchSearch,
      changePage,
      match: {
        params
      }
    } = this.props;

    const { clubId } = params;

    if (pagedServices[page]) {
      changePage({ page });
    } else if (searchActive) {
      fetchSearch({ page, clubId: clubId, params: search });
    } else {
      fetchClubServices({ clubId, page });
    }
  }
}

export default injectIntl(withRouter(connect((state: StoreState) => ({
  clubServices: state.clubServicesReducer,
}), {
  fetchClubServices: clubServicesActions.fetchClubServices,
  changePage: clubServicesActions.changePage,
  searchChanged: clubServicesActions.searchChanged,
  fetchSearch: clubServicesActions.fetchSearch,
  resetSearch: clubServicesActions.resetSearch,
})(ClubServices)));