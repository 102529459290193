import React, { Component } from 'react';
import ReportsFunctionariesScene from '@src/components/scenes/reports/ReportsFunctionariesScene';

class AdminReportsFunctionaries extends Component {
  render() {

    return (
      <ReportsFunctionariesScene />
    );
  }
}

export {
  AdminReportsFunctionaries
};