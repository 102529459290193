import React from 'react';
import RoleScopeGuard from '@src/components/access-control/RoleScopeGuard';

type FederationRoleScopeGuard = {
  children?: React.ReactNode;
};

export const FederationRoleScopeGuard = ({
  children,
}: FederationRoleScopeGuard) => {
  return (
    <RoleScopeGuard>
      {({ isFederationScope }) => Boolean(isFederationScope) && (children)}
    </RoleScopeGuard>
  );
};