import React, { Component, Fragment, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import {
  ContentHeader,
  ContentHeaderTitle,
  ContentLoader,
  ContentWrap,
  renderListItem
} from '@src/components/layouts/ui';
import {
  Card,
  CardContent,
  List,
  Avatar,
  withStyles,
  CardHeader,
} from '@material-ui/core';
import { connect } from 'react-redux';
import * as federationActions from '@src/store/federation/actions';
import * as functionariesActions from '@src/store/functionaries/actions';
import { DashboardForm } from '@src/components/scenes/federation/dashboard/DashboardForm';
import { Link } from 'react-router-dom';
import {
  FunctionaryCardItem,
  functionaryVisibleInGuides
} from '@src/components/scenes/club/components/FunctionaryCardItem';
import { formatAddressGMapsUrl, formatGMapsStaticImageURL } from '@src/components/maps';
import { getActiveFederationId } from '@src/store/auth/selectors';
import { EditIconFab } from '@src/components/buttons/buttons';
import { CanEditGuard } from '@src/components/access-control';
import { functionaryCompareWithPriority } from '@src/utils/FunctionaryUtils';

const styles = {
  twoColWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& > div': {
      width: '48%'
    },
    '@global': {
      '.functionary-card-item': {
        marginBottom: 30,
      },
    }
  },
  card: {
    minWidth: 275,
    marginBottom: 30,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  avatar: {
    width: 60,
    height: 60,
    marginRight: 20
  }
};

type Props = {
  federationId: number;
  classes: any;
  federationReducer: FederationState;
  getFederation: (params: GetFederation) => any;
  getFederationContacts: (params: GetFederationContacts) => any;
};

type State = {
  modalOpen: boolean;
};

class FederationBasicInfo extends Component<Props, State> {
  state = {
    modalOpen: false
  };

  UNSAFE_componentWillMount() {
    const {
      federationReducer: {
        requesting,
        successful,
      },
      getFederation,
      getFederationContacts,
      federationId,
    } = this.props;

    if (!requesting && !successful) {
      getFederation({ federationId });
      getFederationContacts( { federationId });
    }
  }

  render() {
    const {
      federationReducer: {
        federation,
        requesting,
      },
    } = this.props;
    const { modalOpen } = this.state;

    return (
      <Fragment>
        <HeadHelmet titleId={'navigation.golfFederationBasicInfo'} />
        <ContentWrap>
          <ContentHeader>
            {federation &&
              <div className="content-header-wrapper">
                  {federation.logoImageUrl &&
                  <Avatar alt={federation.name} src={federation.logoImageUrl} style={styles.avatar} />
                  }
                  <ContentHeaderTitle>{federation.name} ({federation.abbreviation})</ContentHeaderTitle>
              </div>
            }
          </ContentHeader>
          <ContentLoader visible={!(federation) && requesting} />
          {federation && this._renderContents()}
        </ContentWrap>

        <DashboardForm
          open={modalOpen}
          parentContext={'MODAL'}
          onClose={() => {
            this.setState({
              modalOpen: false
            });
          }}
        />
      </Fragment>
    );
  }

  private _renderContents = () => {
    const { classes } = this.props;

    return (
      <>
        <div className={classes.twoColWrap}>
          {this._renderFederationBasicInfoCard()}
          {this._renderMapLocationCard()}
        </div>
        {this._renderFunctionaryInfoCard()}
      </>
    );
  }

  private _renderFederationBasicInfoCard = (): React.ReactNode => {
    const { classes, federationReducer: { federation } } = this.props;

    if (!federation) {
      return null;
    }

    const listData = [
      {
        label: 'strings.address',
        value: `${federation.streetAddress}, ${federation.zip} ${federation.city}`,
      },
      {
        label: 'strings.phone',
        value: federation.phone,
      },
      {
        label: 'strings.homepage',
        value: federation.homepage,
      },
      {
        label: 'strings.email',
        value: federation.email,
      },
      {
        label: 'strings.fax',
        value: federation.fax ? federation.fax : '-',
      },
      {
        label: 'strings.numberOfClubs',
        value: federation.clubCount,
      },
      {
        label: 'strings.numberOfMembers',
        value: federation.memberCount,
      },
    ];

    return (
      <Card className={classes.card}>
        <CardHeader
          title={<FormattedMessage id={'navigation.golfFederationBasicInfo'}/>}
          action={(
            <CanEditGuard permissions={federation._permissions}>
              <EditIconFab onClick={() => (this.setState({ modalOpen: true }))} />
            </CanEditGuard>
          )}
        />
        <CardContent>
          <List>
            {listData.map(renderListItem)}
          </List>
        </CardContent>
      </Card>
    );
  }

  private _renderMapLocationCard = (): React.ReactNode => {
    const { classes, federationReducer: { federation } } = this.props;

    if (!federation) {
      return null;
    }

    // const name = federation.name;
    const address = `${federation.streetAddress}, ${federation.zip} ${federation.city}`;

    return (
      <Card className={classes.card}>
        <a
          target={'_blank'}
          href={formatAddressGMapsUrl(address)}
          style={{
            display: 'flex',
            height: '100%',
            cursor: 'pointer',
          }}
        >
          <CardContent
            style={{
              backgroundImage: formatGMapsStaticImageURL(address),
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              width: '100%',
            }}
          />
        </a>
      </Card>
    );
  }

  private _renderFunctionaryInfoCard = (): React.ReactNode => {
    const { classes, federationReducer } = this.props;

    if (!federationReducer.federation) {
      return null;
    }

    const { federation } = federationReducer;

    return (
      <Card className={classes.card}>
        <CardHeader
          title={<FormattedMessage id={'scenes.golfFederation.info.contacts'}/>}
          action={(
            !federationReducer.contactsRequesting && (
              <CanEditGuard permissions={federation._permissions}>
                <EditIconFab
                  to={`/admin/federation/functionaries`}
                  component={Link}
                />
              </CanEditGuard>
            )
          )}
        />
        <CardContent className={classes.twoColWrap}>
          {this._renderFunctionaries()}
        </CardContent>
      </Card>
    );
  }

  private _renderFunctionaries = (): ReactNode => {
    const {
      federationReducer: {
        contacts,
        contactsRequesting,
      }
    } = this.props;

    if (contactsRequesting) {
      return <ContentLoader />;
    }

    return contacts.filter(functionaryVisibleInGuides).sort(functionaryCompareWithPriority).map(FunctionaryCardItem);
  }
}

export default withStyles(styles as any)(connect((state: StoreState) => ({
  federationReducer: state.federationReducer,
  federationId: getActiveFederationId(state),
}), {
  getFederation: federationActions.getFederation,
  fetchFunctionaries: functionariesActions.fetchFunctionaries,
  getFederationContacts: federationActions.getFederationContacts,
})(FederationBasicInfo));
