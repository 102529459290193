import {
  FUNCTIONARIES_CHANGE_PAGE, FUNCTIONARIES_CONDENSED_REQUESTING,
  FUNCTIONARIES_REQUESTING,
  FUNCTIONARIES_SEARCH_CHANGED,
  FUNCTIONARIES_SEARCH_REQUESTING,
  FUNCTIONARIES_SEARCH_RESET, FUNCTIONARIES_SET_TARGET_ID_PARAMS,
} from './constants';
import { DEFAULT_PAGINATION_LIMIT } from '@src/assets/config';
import { createTargetIdParamsActionsWithType } from '@src/utils/storeUtils';

export const setTargetIdParams = createTargetIdParamsActionsWithType(FUNCTIONARIES_SET_TARGET_ID_PARAMS);

export const fetchFunctionaries = ({
  page,
  limit = DEFAULT_PAGINATION_LIMIT,
  params
}: TablePaginationAction) => {
  return {
    type: FUNCTIONARIES_REQUESTING,
    page,
    limit,
    params
  };
};

export const changePage = ({
  page,
  params
}: ChangePageAction) => {
  return {
    type: FUNCTIONARIES_CHANGE_PAGE,
    page,
    params
  };
};

export const fetchSearch = ({
  page,
  limit = DEFAULT_PAGINATION_LIMIT,
  params
}: SearchPaginationAction) => {
  return {
    type: FUNCTIONARIES_SEARCH_REQUESTING,
    page,
    limit,
    params,
  };
};

export const searchChanged = (search: FunctionariesSearch) => {
  return {
    type: FUNCTIONARIES_SEARCH_CHANGED,
    search
  };
};

export const resetSearch = () => {
  return {
    type: FUNCTIONARIES_SEARCH_RESET,
  };
};

export const fetchCondensedFunctionaries = ({
  federationId,
  associationId,
  clubId,
}: FetchCondensedFunctionaries) => {
  return {
    type: FUNCTIONARIES_CONDENSED_REQUESTING,
    params: {
      federationId,
      associationId,
      clubId,
    },
  };
};