import { take, fork, all, select, put, takeEvery } from 'redux-saga/effects';
import {
  CLUB_COURSES_REQUESTING,
  CLUB_COURSES_SUCCESS,
  CLUB_COURSES_ERROR,
  CLUB_COURSES_RESET,
  CLUB_COURSES_SEARCH_RESET,
  CLUB_COURSES_SEARCH_REQUESTING, CONDENSED_CLUB_COURSES_REQUESTING,
} from './constants';
import { DEFAULT_PAGINATION_LIMIT, DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import { AUTH_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { fetchCondensedClubCourseFlow } from '@src/store/clubCourses/flows/fetch-condensed-club-courses-flow';
import { CLUB_COURSE_DELETE_SUCCESS, CLUB_COURSE_EDIT_SUCCESS } from '@src/store/clubCourse/constants';
import { fetchCondensedClubCourses } from '@src/store/clubCourses/actions';
import { getCourseClubId } from '@src/store/clubCourse/selector';

/*
  GET /admin/1/courses/club/<id>
 */
const pagedFlow = (clubId: number) => {
  return SagaFlowFactory.createPagedGETFlow({
    apiRoute: `admin/1/courses/club/${clubId}`,
    itemType: 'courses',
    successType: CLUB_COURSES_SUCCESS,
    errorType: CLUB_COURSES_ERROR
  });
};

export default function * clubCoursesWatcher() {
  let token: string = '';
  let targetingParams: any = {};

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
      page,
      clubId,
      limit,
      params
    } = yield take([
      CLUB_COURSES_REQUESTING,
      CLUB_COURSES_RESET,
      CLUB_COURSES_SEARCH_REQUESTING,
      CLUB_COURSES_SEARCH_RESET,
      CONDENSED_CLUB_COURSES_REQUESTING,
      // AUTH_ACTIVE_STATE_CHANGE,
      // INITIAL_AUTH_ACTIVE_STATE_SET,
    ]);

    switch (type) {
      case CLUB_COURSES_REQUESTING:
        yield fork(pagedFlow(clubId), token, page, limit, targetingParams);
        break;
      case CLUB_COURSES_RESET:
        yield fork(pagedFlow(params.clubId), token, DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_LIMIT, targetingParams);
        break;
      /* case CLUB_COURSES_SEARCH_REQUESTING:
        yield fork(fetchClubsSearchFlow, token, page, limit, normalizeSearchParams(params));
        break; */
      case CLUB_COURSES_SEARCH_RESET:
        yield fork(pagedFlow(clubId), token, DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_LIMIT);
        break;
      case CONDENSED_CLUB_COURSES_REQUESTING:
        yield fork(fetchCondensedClubCourseFlow, token, clubId);
        break;
      default:
    }
  }
}

function* refreshCondensedClubData() {
  const clubId = yield select(getCourseClubId);
  if (clubId) {
    yield put(fetchCondensedClubCourses(clubId));
  }
}

export function* clubDataChangedWatcher() {
  yield all([
    takeEvery(CLUB_COURSE_EDIT_SUCCESS, refreshCondensedClubData),
    takeEvery(CLUB_COURSE_DELETE_SUCCESS, refreshCondensedClubData),
  ]);
}