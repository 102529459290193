import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import AcceptableScoreAdjustmentEditor from '@src/components/scenes/member/components/AcceptableScoreAdjustmentEditor';
import { EditIconButton } from '@src/components/buttons/buttons';
import AdjustmentsInheritanceBar from '@src/components/scenes/member/components/AdjustmentsInheritanceBar';
import AdjustmentsInheritanceWrap from '@src/components/scenes/member/components/AdjustmentsInheritanceWrap';

interface Props {
  adjustmentId?: number;
  type: string | 'exceptionalScore' | 'penaltyScore' | 'handicapReview';
  value: number | string;
  disableEdit?: boolean;
  previousAffectedScoresAmount?: number;
  originScoreId?: number;
  resetLowHandicapIndex?: boolean;
}

function ScoreAdjustmentsRow({
  adjustmentId,
  type,
  value,
  disableEdit = false,
  previousAffectedScoresAmount = 0,
  originScoreId,
  resetLowHandicapIndex = false,
}: Props) {
  const disabled = disableEdit || type === 'exceptionalScore';

  return (
    <TableRow>
      <TableCell variant={'head'} style={{ opacity: disabled ? .65 : 1 }}>
        <FormattedMessage id={`strings.${type}`} />
      </TableCell>
      <TableCell align={'center'}>
        {value}
      </TableCell>
      <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>
        <AdjustmentsInheritanceWrap>
          {(originScoreId) && (
            <AdjustmentsInheritanceBar adjustmentId={originScoreId} />
          )}
        </AdjustmentsInheritanceWrap>
      </TableCell>
      <TableCell>
        {!disabled && (
          <AcceptableScoreAdjustmentEditor>
            {({ showEdit }) => (
              <EditIconButton
                size={'small'}
                onClick={() => showEdit({
                  adjustmentId,
                  adjustment: type,
                  adjustmentAmount: value,
                  previousAffectedScoresAmount,
                  resetLowHandicapIndex
                })}
              />
            )}
          </AcceptableScoreAdjustmentEditor>
        )}
      </TableCell>
    </TableRow>
  );
}

export default ScoreAdjustmentsRow;
