import React, { ReactNode, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle, FormControl, OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, Typography,
} from '@material-ui/core';
import { renderFormActions } from '@src/components/modals/ui';
import { connect } from 'react-redux';
import { editAcceptableScoreHoleScore, EditAcceptableScoreHoleScorePayload } from '@src/store/acceptableScore/actions';
import { FormattedMessage, useIntl } from 'react-intl';

export interface ChildrenArgs {
  ref: React.RefObject<any>;
  showEdit(acceptableScoreId: number, holes?: AcceptableScoreHole[]): void;
}

interface OwnProps {
  defaultValue?: string | number;
  children(args: ChildrenArgs): ReactNode;
}

interface DispatchProps {
  editAcceptableScoreHoleScore(args: EditAcceptableScoreHoleScorePayload): void;
}

type Props = OwnProps & DispatchProps;

function ScoreEditor(props: Props, ref) {
  const {
    children,
    editAcceptableScoreHoleScore,
    defaultValue = '-',
  } = props;
  const emptyHolesScores = Array.from({ length: 18 }).map(_ => defaultValue);
  const [open, setOpen] = useState<boolean>(false);
  const [acceptableScoreId, setAcceptableScoreId] = useState<number | undefined>(undefined);
  const [scores, setScores] = useState<(number | string)[]>([...emptyHolesScores]);
  const { formatMessage: f } = useIntl();

  const onClose = () => {
    setOpen(false);
  };

  const handleOnUpdate = () => {
    if (scores && acceptableScoreId) {
      editAcceptableScoreHoleScore({
        acceptableScoreId,
        holes: scores,
      });
    }

    onClose();
  };

  return (
    <>
      <Dialog open={open} disableBackdropClick={true} maxWidth={'lg'} fullWidth={true}>
        <DialogTitle>
          <FormattedMessage id={'strings.scoreEditor'}/>
        </DialogTitle>
        <DialogContent style={{ paddingTop: 16 }}>
          <Table size={'small'} style={{ minWidth: 1232 }}>
            <TableHead>
              <TableRow>
                {emptyHolesScores.map((_, idx) => (
                  <TableCell key={`${idx}`} style={{ textAlign: 'center', padding: '6px 3px 6px 10px' }}>
                    {idx + 1}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {scores.map((score, idx) => (
                  <TableCell key={`${idx}`} style={{ padding: '6px 3px 6px 10px', borderColor: 'transparent' }}>
                    <FormControl variant={'filled'}>
                      <OutlinedInput
                        labelWidth={0}
                        value={score}
                        style={{ margin: '4px 6px 0', minWidth: 'auto' }}
                        autoComplete={'off'}
                        inputProps={{
                          style: {
                            textAlign: 'center',
                            padding: '10px 7px',
                          },
                          onFocus: ({ target }) => target.select()
                        }}
                        onChange={event => {
                          const { value } = event.target;
                          setScores([
                            ...scores.slice(0, idx),
                            value,
                            ...scores.slice(idx + 1),
                          ]);
                        }}
                      />
                    </FormControl>
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogContent>
          <Typography variant={'body1'}>
            {f({ id: 'strings.scoreEditorInfo' })}
          </Typography>
        </DialogContent>
        {renderFormActions({
          id: -1,
          parentContext: 'MODAL',
          onClose,
          onUpdate: handleOnUpdate,
        })}
      </Dialog>
      {children({
        showEdit(acceptableScoreId, holes: AcceptableScoreHole[] = []): void {
          const holeScores: (number | string)[] = [...emptyHolesScores];
          holes.forEach(item => holeScores[item.hole - 1] = item.strokes);
          setAcceptableScoreId(acceptableScoreId);
          setScores(holeScores);
          setOpen(true);
        },
        ref,
      })}
    </>
  );
}

export default connect<{}, DispatchProps, OwnProps, StoreState>(null, {
  editAcceptableScoreHoleScore,
})(React.forwardRef(ScoreEditor));
