import {
  COMPANIES_REQUESTING,
  COMPANIES_SUCCESS,
  COMPANIES_ERROR,
  COMPANIES_CHANGE_PAGE,
  FEDERATION_COMPANIES_SEARCH_CHANGED,
  FEDERATION_COMPANIES_SEARCH_REQUEST,
  FEDERATION_COMPANIES_SEARCH_RESET,
} from './constants';
import { SHARED_DEFAULT_REDUCER_STATE } from '@src/assets/config';
import {
  formDefaultErrorState,
  formDefaultPagedState,
  formDefaultRequestingState,
  fromDefaultPageChangeState, optimisticPagedObjectUpdate, searchStateChangedReducerHelper,
} from '@src/utils/storeUtils';
import { COMPANY_EDIT_SUCCESS } from '@src/store/company/constants';

const initialState: CompaniesState = {
  ...SHARED_DEFAULT_REDUCER_STATE,
  pagedCompanies: {},
  search: {
    name: '',
    status: undefined
  }
};

export const companiesReducer = (state: CompaniesState = initialState, action: any): CompaniesState => {
  switch (action.type) {
    case COMPANIES_REQUESTING:
      return formDefaultRequestingState(state);

    case COMPANIES_SUCCESS:
      return formDefaultPagedState(state, action, 'pagedCompanies');

    case COMPANIES_CHANGE_PAGE:
      return fromDefaultPageChangeState(state, action);

    case COMPANIES_ERROR:
      return formDefaultErrorState(state, action);

    case FEDERATION_COMPANIES_SEARCH_CHANGED: {
      return searchStateChangedReducerHelper(state, action);
    }

    case COMPANY_EDIT_SUCCESS: {
      try {
        const { data } = action;
        const { page } = state.pagination;

        const pagedCompanies = optimisticPagedObjectUpdate(state.pagedCompanies, page, data);

        return {
          ...state,
          pagedCompanies,
        };
      } catch (e) {
        return state;
      }
    }

    case FEDERATION_COMPANIES_SEARCH_REQUEST: {
      let searchActive = state.searchActive;
      let pagedCompanies = state.pagedCompanies;
      let pagination = state.pagination;

      if (!searchActive) {
        searchActive = true;
        pagedCompanies = {};
        pagination.totalCount = 0;
      }

      pagination.page = action.page;

      return {
        ...state,
        pagedCompanies,
        pagination,
        searchActive,
        requesting: true
      };
    }

    case FEDERATION_COMPANIES_SEARCH_RESET:
      return {
        ...initialState,
        requesting: true
      };

    default:
      return state;
  }
};