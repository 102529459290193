import {
  AUTH_SET,
  AUTH_UNSET,
  AUTH_ACTIVE_STATE_CHANGE,
  INITIAL_AUTH_ACTIVE_STATE_SET,
} from '@src/store/auth/constants';

const initialState: AuthState = {
  id: undefined,
  email: undefined,
  token: undefined,
  clubs: undefined,
  partners: undefined,
  activeState: undefined,
  roleInfo: undefined,
  config: undefined,
};

export const getInitialActiveState = (user: AuthState): any => {
  const {
    roleInfo,
    clubs,
    partners,
  } = user;

  let club: AuthUserClub | undefined = undefined;
  let partner: AuthUserPartner | undefined = undefined;

  // If role info is not defined we do not wan't to continue
  if (!roleInfo) {
    throw 'could-not-parse-roleInfo-from-user-auth';
  }

  if (clubs && clubs.length) {
    const [ firstClubInArray ] = clubs;
    club = firstClubInArray;
  } else if (partners && partners.length) {
    const [ firstPartnerInArray ] = partners;
    partner = firstPartnerInArray;
  }

  return {
    roleInfo,
    club,
    partner,
  };
};

const authReducer = (state: AuthState = initialState, action: any) => {
  switch (action.type) {
    case AUTH_SET: {
      return {
        ...action.user,
      };
    }

    case AUTH_UNSET:
      return initialState;

    case INITIAL_AUTH_ACTIVE_STATE_SET:
    case AUTH_ACTIVE_STATE_CHANGE: {
      const {
        activeState,
      } = action;

      return {
        ...state,
        activeState
      };
    }

    default:
      return state;
  }
};

export {
  authReducer
};