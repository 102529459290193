export const ORGANIZATIONS_REQUESTING = 'ORGANIZATIONS_REQUESTING ';
export const ORGANIZATIONS_SUCCESS = 'ORGANIZATIONS_SUCCESS';
export const ORGANIZATIONS_ERROR = 'ORGANIZATIONS_ERROR';
export const ORGANIZATIONS_CHANGE_PAGE = 'ORGANIZATIONS_CHANGE_PAGE';

export const ORGANIZATIONS_SEARCH_CHANGED = 'ORGANIZATIONS_SEARCH_CHANGED';
export const ORGANIZATIONS_SEARCH_RESET = 'ORGANIZATIONS_SEARCH_RESET';
export const ORGANIZATIONS_SEARCH_REQUESTING = 'ORGANIZATIONS_SEARCH_REQUESTING';

export const ORGANIZATIONS_RESET = 'ORGANIZATIONS_RESET';

export const ORGANIZATIONS_SET_TARGET_ID_PARAMS = 'ORGANIZATIONS_SET_TARGET_ID_PARAMS';