import React, { PureComponent, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Button,
} from '@material-ui/core';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import {
  ContentHeader,
  ContentHeaderTitle,
  ContentWrap,
  ContentLoader
} from '@src/components/layouts/ui';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import { Pagination } from '@src/components/pagination/Pagination';
import { ClubCourseModal } from '@src/components/scenes/club/course/ClubCourseModal';
import { cleanTrailingSlash, getPageSequence } from '@src/utils/storeUtils';
import { fetchClubCourses, changePage, fetchCondensedClubCourses, resetSearch } from '@src/store/clubCourses/actions';
import { changeCourseState, ChangeCourseStatePayload, deleteClubCourse } from '@src/store/clubCourse/actions';
import DeleteConfirm, { DeleteConfirmChildren } from '@src/components/headless/DeleteConfirm';
import { OverlayLoader } from '@src/components/layouts/ui';
import ClubCourseTableRow from '@src/components/scenes/club/course/ClubCourseTableRow';
import { FederationRoleScopeGuard } from '@src/components/access-control/FederationRoleScopeGuard';

type State = {
  modalOpen: boolean;
  courseIdToEdit?: number;
  clubCourseLoading: boolean;
};

interface StateProps {
  clubCourses: ClubCoursesState;
}

interface DispatchProps {
  fetchClubCourses(params: FetchClubCourses): void;
  changePage(params: ChangePageAction): void;
  deleteClubCourse(args: ClubCourseDelete): void;
  fetchCondensedClubCourses(clubId: number): void;
  changeCourseState(args: ChangeCourseStatePayload): void;
  resetSearch(): void;
}

type Props = StateProps & DispatchProps & RouteComponentProps<any>;

class ClubCourses extends PureComponent<Props, State> {
  state = {
    modalOpen: false,
    courseIdToEdit: undefined,
    clubCourseLoading: false,
  };

  UNSAFE_componentWillMount() {
    const { fetchClubCourses, fetchCondensedClubCourses } = this.props;

    fetchClubCourses({
      clubId: this._clubId,
      page: DEFAULT_PAGINATION_PAGE
    });

    fetchCondensedClubCourses(this._clubId);
  }

  render() {
    const { modalOpen, courseIdToEdit, clubCourseLoading } = this.state;
    const {
      clubCourses: {
        requesting,
        successful,
      },
    } = this.props;

    return (
      <>
        <HeadHelmet titleId={'navigation.golfClubCourses'} />
        <ContentWrap>
          <ContentHeader>
            <ContentHeaderTitle>
              <FormattedMessage id={'navigation.golfClubCourses'}/>
            </ContentHeaderTitle>
            <FederationRoleScopeGuard>
              <Button
                  variant="contained"
                  onClick={() => this.setState({courseIdToEdit: undefined, modalOpen: true})}
              >
                <FormattedMessage id={'scenes.golfClub.courses.addNewCourseButtonLabel'}/>
              </Button>
            </FederationRoleScopeGuard>
          </ContentHeader>

          <ContentLoader visible={requesting}/>
          <OverlayLoader visible={clubCourseLoading} transparent={true} loaderSize={40} />

          {successful && (
            <>
              {this._renderCourses()}
              {this._renderPagination()}
            </>
          )}
        </ContentWrap>

        <ClubCourseModal
          clubId={this._clubId}
          clubCourseId={courseIdToEdit}
          open={modalOpen}
          onClose={() => {
            this.setState({modalOpen: false});
          }}
        />
      </>
    );
  }

  _renderCourses = (): ReactNode => {
    const {
      clubCourses: {
        successful,
        pagedCourses,
        pagination: {
          page
        }
      },
    } = this.props;

    return (
      <Table>
        <TableHead>
          <TableRow>
            <FederationRoleScopeGuard>
              <TableCell style={{ width: 18, paddingRight: 0 }} />
            </FederationRoleScopeGuard>
            <TableCell><FormattedMessage id={'strings.name'} /></TableCell>
            <TableCell align="center">
              <FormattedMessage id={'scenes.golfClub.courses.table.headers.courseHoleCount'} />
            </TableCell>
            <TableCell align="center">
              <FormattedMessage id={'scenes.golfClub.courses.table.headers.coursePar'} />
            </TableCell>
            <TableCell>
              <FormattedMessage id={'strings.published'} />
            </TableCell>
            <TableCell align={'center'}>
              <FormattedMessage id={'strings.state'} />
            </TableCell>
            <TableCell align="right" />
            <TableCell align="right" style={{ width: 40, paddingLeft: 0 }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {(successful && pagedCourses) && (
            getPageSequence(pagedCourses[page]).map(this._renderCourseRow)
          )}
        </TableBody>
      </Table>
    );
  }

  _renderCourseRow = (course: ClubCourse) => {
    return (
      <DeleteConfirm key={course.id}>
        {({ showConfirm }: DeleteConfirmChildren) => (
          <ClubCourseTableRow
            course={course}
            onLoading={(clubCourseLoading) => this.setState({ clubCourseLoading })}
            onClickView={this._handleOnCourseClickView}
            onClickDelete={this._handleOnClickDelete(showConfirm)}
            onChangeStatus={this._handleOnChangeCourseStatus}
          />
        )}
      </DeleteConfirm>
    );
  }

  _handleOnChangeCourseStatus = (courseId: number, status: 'activate' | 'deactivate') => {
    this.setState({ clubCourseLoading: true }, () => {
      this.props.changeCourseState({
        courseId,
        status,
        onComplete: () => {
          this.setState({ clubCourseLoading: false }, () => {
            this._refetchCourses();
          });
        }
      });
    });
  }

  _handleOnClickDelete = (showConfirm) => (courseId: number) => {
    showConfirm({
      callback: (shouldRemove) => {
        if (shouldRemove) {
          this.setState({ clubCourseLoading: true }, () => {
            this.props.deleteClubCourse({
              id: courseId,
              clubId: this._clubId,
              onComplete: () => {
                this.setState({ clubCourseLoading: false });
              }
            });
          });
        }
      },
    });
  }

  _handleOnCourseClickView = (courseId: number) => {
    const { history, match: { url } } = this.props;
    history.push(cleanTrailingSlash(url, `/${courseId}`));
  }

  _handlePageChange = (page: number) => {
    const {
      clubCourses: {
        pagedCourses,
      },
      changePage,
    } = this.props;

    if (pagedCourses[page]) {
      changePage({ page });
    } else {
      this._refetchCourses(page);
    }
  }

  _renderPagination = (): ReactNode => {
    const {
      clubCourses: {
        pagination: {
          page,
          limit,
          totalCount
        }
      }
    } = this.props;

    if (totalCount > limit) {
      return <Pagination page={page} limit={limit} totalCount={totalCount} onPageChange={this._handlePageChange}/>;
    }

    return null;
  }

  _refetchCourses = (page: number = this._page) => {
    this.props.fetchClubCourses({ clubId: this._clubId, page });
  }

  get _clubId() {
    const { match: { params } } = this.props;
    return params.clubId;
  }

  get _page() {
    const {
      clubCourses: {
        pagination: {
          page,
        },
      },
    } = this.props;

    return page;
  }
}

export default withRouter(connect<StateProps, DispatchProps, {}, StoreState>(state => ({
  clubCourses: state.clubCoursesReducer,
}), {
  fetchClubCourses, changePage, deleteClubCourse, fetchCondensedClubCourses,
  changeCourseState, resetSearch,
})(ClubCourses));
