import React from 'react';
import { makeStyles } from '@material-ui/core';
import { getEnv, isDev, isProduction } from '@src/assets/config';

const useStyles = makeStyles({
  root: {
    zIndex: 10000,
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    pointerEvents: 'none',
    border: `2px solid #ff0000b3`,
  },
  label: {
    position: 'fixed',
    bottom: 2,
    left: 2,
    padding: `8px 12px`,
    background: `#ff0000b3`,
    fontSize: 16,
  }
});

function NonProductionNotification() {
  const classes = useStyles();

  if (isProduction || isDev) {
    return null;
  }

  return (
    <div className={classes.root}>
      <span className={classes.label}>{getEnv()}</span>
    </div>
  );
}

export default NonProductionNotification;