import React from 'react';
import { connect } from 'react-redux';
import { copyClubCourse, publishClubCourse } from '@src/store/clubCourse/actions';
import DateTimePickerConfirm from '@src/components/headless/DateTimePickerConfirm';
import { handleOnFunction } from '@src/utils/utils';

interface ChildrenArgs {
  handleEditClick(): void;
  handlePublishClick(): void;
}

interface OwnProps {
  courseId: number;
  draftCourseId?: number;
  publishMinDate?: string | Date;
  children(args: ChildrenArgs): React.ReactElement;
  onSuccess?(courseId: number): void;
  onBeforeEdit?(): void;
  onEditDone?(): void;
}

interface DispatchProps {
  copyClubCourse(params: ClubCourseCopy): void;
  publishClubCourse(params: ClubCoursePublish): void;
}

type Props = OwnProps & DispatchProps;

function CourseEditAction({
  children,
  courseId,
  draftCourseId,
  publishMinDate = new Date(),
  onSuccess,
  onBeforeEdit,
  onEditDone,
  copyClubCourse,
  publishClubCourse,
}: Props) {
  const handleCopy = () => {
    copyClubCourse({
      courseId,
      onComplete: ({ data }) => {
        if (data && data.id) {
          handleOnFunction(onSuccess, data.id);
        }

        handleOnFunction(onEditDone);
      },
    });
  };

  /*
    Edit click handler.
    - Redirect to the draft if it already exists
    - copy original if a draft was not found
   */
  const handleEditClick = () => {
    handleOnFunction(onBeforeEdit);

    if (draftCourseId) {
      handleOnFunction(onSuccess, draftCourseId);
      handleOnFunction(onEditDone);
    } else {
      handleCopy();
    }
  };

  const handleCoursePublish = (date: Date) => {
    publishClubCourse({
      courseId,
      effectiveDate: date
    });
  };

  return (
    <DateTimePickerConfirm
      onSuccess={handleCoursePublish}
      minDate={publishMinDate}
    >
      {({ showPicker }) => children({
        handleEditClick,
        handlePublishClick: showPicker,
      })}
    </DateTimePickerConfirm>
  );
}

export default connect<{}, DispatchProps, OwnProps, StoreState>(null, {
  copyClubCourse, publishClubCourse,
})(CourseEditAction);
