import React, { ReactNode, useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import { sumAdjustments } from '@src/utils/scoreUtils';
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Toolbar } from '@material-ui/core';
import {
  ViewList,
  ViewColumn,
  Flight as FlightIcon,
  Update as UpdateIcon,
  Check as CheckIcon,
  Clear as ClearIcon
} from '@material-ui/icons';

interface ParsedAcceptable {
  label: string;
  value: React.ReactNode;
}

const formParsedAcceptableScoreData = (acceptableScore: AcceptableScore): ParsedAcceptable[] => {
  const strokeCount = acceptableScore.holes.reduce((sum, hole) => {
    if (typeof(hole.strokes) === 'number') { // strokes can be "-" for some reason
      sum += hole.strokes;
    }
    
    return sum;
  }, 0);

  return [
    {
      label: 'strings.timePosted',
      value: (
        <FormattedDate
          value={acceptableScore.timePosted}
          day={'numeric'}
          month={'numeric'}
          year={'numeric'}
          minute={'numeric'}
          hour={'numeric'}
        />
      ),
    },
    {
      label: 'strings.timePlayed',
      value: <FormattedDate value={acceptableScore.timePlayed}/>,
    },
    {
      label: 'strings.course',
      value: acceptableScore.courseJurisdiction === 'HOME' ?
        get(acceptableScore, 'course.name') :
        <>"{get(acceptableScore, 'course.name')}"</>,
    },
    {
      label: 'strings.courseJurisdiction',
      value: acceptableScore.courseJurisdiction === 'HOME' ?
        <>{acceptableScore.courseJurisdiction} <CheckIcon color={'primary'} fontSize={'small'}/> </> :
        <>{acceptableScore.courseJurisdiction} <FlightIcon color={'secondary'} fontSize={'small'}/></>,
    },
    {
      label: 'strings.typeOfRound',
      value: acceptableScore.typeOfRound,
    },
    {
      label: 'strings.formatOfPlay',
      value: acceptableScore.formatOfPlay,
    },
    {
      label: 'strings.nameOfCompetition',
      value: acceptableScore.nameOfCompetition,
    },
    {
      label: 'strings.nameOfMarker',
      value: acceptableScore.nameOfMarker,
    },
    {
      label: 'strings.numberOfHoles',
      value: acceptableScore.numberOfHoles,
    },
    {
      label: 'strings.vendor',
      value: get(acceptableScore, 'vendor.name'),
    },
    {
      label: 'strings.handicapIndexLow',
      value: get(acceptableScore, 'result.handicapIndexLow')
    },
    {
      label: 'strings.courseHandicap',
      value: get(acceptableScore, 'result.courseHandicap')
    },
    {
      label: 'strings.coursePar',
      value: get(acceptableScore, 'result.coursePar') ?
        acceptableScore.courseJurisdiction === 'HOME' || acceptableScore.typeOfRound === 'COMPETITION' ?
          get(acceptableScore, 'result.coursePar') :
          <>"{get(acceptableScore, 'result.coursePar')}"</> : null
    },
    {
      label: 'strings.courseRating',
      value: get(acceptableScore, 'result.courseRating') ?
        acceptableScore.courseJurisdiction === 'HOME' || acceptableScore.typeOfRound === 'COMPETITION' ?
          get(acceptableScore, 'result.courseRating') :
          <>"{get(acceptableScore, 'result.courseRating')}"</> : null
    },
    {
      label: 'strings.slopeRating',
      value: get(acceptableScore, 'result.slopeRating') ?
        acceptableScore.courseJurisdiction === 'HOME' || acceptableScore.typeOfRound === 'COMPETITION' ?
          get(acceptableScore, 'result.slopeRating') :
          <>"{get(acceptableScore, 'result.slopeRating')}"</> : null
    },
    {
      label: 'strings.stablefordPointsAdjusted',
      value: get(acceptableScore, 'result.stablefordPointsAdjusted')
    },
    {
      label: 'strings.playingConditionCalculation',
      value: acceptableScore.courseJurisdiction === 'HOME' || acceptableScore.typeOfRound === 'COMPETITION' ?
        get(acceptableScore, 'result.playingConditionCalculation') :
        <ClearIcon fontSize={'small'} color={'secondary'}/>,
    },
    {
      label: 'strings.grossScore',
      value: get(acceptableScore, 'result.grossScore')
    },
    {
      label: 'strings.scoreDifferential',
      value: get(acceptableScore, 'result.scoreDifferential')
    },
    {
      label: 'strings.adjustments',
      value: sumAdjustments([
        ...get(acceptableScore, 'adjustments', []),
        ...get(acceptableScore, 'inheritedAdjustments', []),
      ])
    },
    {
      label: 'strings.grossScoreAdjusted',
      value: get(acceptableScore, 'result.grossScoreAdjusted', strokeCount)
    },
    {
      label: 'strings.scoreDifferentialAdjusted',
      value: get(acceptableScore, 'result.scoreDifferentialAdjusted')
    },
    {
      label: 'strings.handicapIndexRevised',
      value: get(acceptableScore, 'result.handicapIndexRevised')
    },
    {
      label: 'strings.resultTimeCreated',
      value: acceptableScore.isWaitingForReComputation ? (
        <span style={{ textDecoration: 'line-through' }}>{acceptableScore.result?.timeCreated ? (
          <FormattedDate
            value={acceptableScore.result.timeCreated}
            day={'numeric'}
            month={'numeric'}
            year={'numeric'}
            minute={'numeric'}
            hour={'numeric'}
          />
        ) : null}
          {acceptableScore.isWaitingForReComputation ? <UpdateIcon fontSize={'small'}/> : ''}
        </span>
      ) : (
        <>{acceptableScore.result?.timeCreated ? (
          <FormattedDate
            value={acceptableScore.result.timeCreated}
            day={'numeric'}
            month={'numeric'}
            year={'numeric'}
            minute={'numeric'}
            hour={'numeric'}
          />
        ) : null}
          {acceptableScore.isWaitingForReComputation ? <UpdateIcon fontSize={'small'}/> : ''}
        </>
      ),
    },
  ];
};

interface ChildrenArgs {
  renderToolbar(): ReactNode;

  renderTable(): ReactNode;
}

interface OwnProps {
  acceptableScore: AcceptableScore;

  children(args: ChildrenArgs): ReactNode;
}

type Props = OwnProps;

function AcceptableScoreDetails({
                                  children,
                                  acceptableScore,
                                }: Props) {
  const [vertical, setVertical] = useState<boolean>(true);
  const parsedData = formParsedAcceptableScoreData(acceptableScore);

  return (
    <>
      {children({
        renderToolbar: () => (
          <Toolbar disableGutters={true} variant={'dense'} style={{ justifyContent: 'flex-end' }}>
            <IconButton
              color={!vertical ? 'primary' : undefined}
              aria-label="view columns"
              onClick={() => setVertical(false)}
            >
              <ViewColumn/>
            </IconButton>
            <IconButton
              color={vertical ? 'primary' : undefined}
              aria-label="view list"
              onClick={() => setVertical(true)}
            >
              <ViewList/>
            </IconButton>
          </Toolbar>
        ),
        renderTable: () => (
          <Table size={'small'}>
            {!vertical ? (
              <>
                <TableHead>
                  <TableRow>
                    {parsedData.map(item => (
                      <TableCell key={item.label}>
                        <FormattedMessage id={item.label}/>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {parsedData.map(item => (
                      <TableCell key={item.label}>
                        {item.value}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </>
            ) : (
              <TableBody>
                {parsedData.map(item => (
                  <TableRow key={item.label}>
                    <TableCell variant={'head'} component={'th'}>
                      <FormattedMessage id={item.label}/>
                    </TableCell>
                    <TableCell>
                      {item.value}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        )
      })}
    </>
  );
}

export default AcceptableScoreDetails;