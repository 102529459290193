import React, { Component, ReactNode } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import * as clubActions from '@src/store/club/actions';
import {
  ContentHeader,
  ContentHeaderTitle,
  ContentWrap, TransparentOverlayLoader,
} from '@src/components/layouts/ui';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import { Button, Card, CardContent, CardHeader, FormControlLabel, Grid, Switch } from '@material-ui/core';
import { UploadMembersExcelModal } from '@src/components/modals/UploadMembersExcelModal';
import PlayersSearch from '@src/components/scenes/members/ui/PlayersSearch';
import PlayersTable from '@src/components/scenes/members/ui/PlayersTable';
import { Link } from 'react-router-dom';
import { getActiveFederationId } from '@src/store/auth/selectors';
import { targetIdParamsChanged } from '@src/utils/storeUtils';
import UploadHIOExcel from '@src/components/modals/UploadHIOExcel';
import RoleScopeGuard from '@src/components/access-control/RoleScopeGuard';
import FormControl from '@material-ui/core/FormControl/FormControl';
import { confirm } from '@src/components/modals/Confirm';
import { ClubSelect } from '@src/components/forms/ClubSelect';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import SwipeableViews from 'react-swipeable-views';
import { SwipeableContentContainer } from '@src/components/forms/ui/OfferForm';
import { FederationRoleScopeGuard } from '@src/components/access-control/FederationRoleScopeGuard';

interface State {
  excelUploadModalOpen: boolean;
  club?: Club|undefined;
  requesting: boolean;
  slideIndex: number;
}

interface Props extends RouteComponentProps<any>, WrappedComponentProps {
  auth: AuthState;
  clubState: ClubState;
  fetchClubMembers: (params: MembersTablePaginationAction) => any;
  fetchClub: (params: FetchClub) => void;
  changePage: (params: MembersChangePageAction) => any;
  searchChanged: (params: ClubMembersSearch) => any;
  fetchSearch: (params: SearchPaginationAction) => any;
  resetSearch: () => any;
  updateActivateIfBillsPaid: (params: ActivateIfBillsPaidUpdate) => any;
  setClubMemberActivation: (params: SetUserActivation) => any;
  setTargetIdParams: (params: SetTargetIdParams) => any;
  targetIdParams: TargetIdParams;
  locale: AppLocale;
}

class AdminMembersConnected extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      excelUploadModalOpen: false,
      club: undefined,
      requesting: false,
      slideIndex: 0,
    };
  }

  UNSAFE_componentWillMount() {
    const {
      setTargetIdParams,
      clubState: {
        targetIdParams,
      }
    } = this.props;

    if (targetIdParamsChanged(targetIdParams, this.props.targetIdParams)) {
      setTargetIdParams({
        resetState: true,
        targetIdParams: this.props.targetIdParams,
      });
    }
  }

  render() {
    const {
      match: {
        url,
      },
      history,
      locale
    } = this.props;
    const {
      slideIndex,
    } = this.state;

    return (
      <>
        <HeadHelmet titleId={'navigation.members'} />
        <ContentWrap>
          <ContentHeader>
            <ContentHeaderTitle>
              <FormattedMessage id={'scenes.members.sectionTitle'} />
            </ContentHeaderTitle>

            <FederationRoleScopeGuard>
              <Tabs
                  onChange={this._handleSectionChange}
                  value={slideIndex}
                  indicatorColor={'primary'}

              >
                <Tab
                    label={<FormattedMessage id={'strings.searchTitle'}/>}
                    value={0}
                />
                <Tab
                    label={<FormattedMessage id={'strings.membershipActivationTitleShort'}/>}
                    value={1}
                />
              </Tabs>
            </FederationRoleScopeGuard>
            
          </ContentHeader>

          <SwipeableViews
              index={slideIndex}
              onChangeIndex={this._handleSectionChange}
          >
            <SwipeableContentContainer>
              <FederationRoleScopeGuard>
                <Grid container={true} spacing={2}>
                  <Grid item={true}>
                    <Button variant="contained" to={`${url}/problematic-members`} component={Link}>
                      <FormattedMessage id={'scenes.problematicMembers.title'}/>
                    </Button>
                  </Grid>
                  <Grid item={true}>
                    <UploadHIOExcel onSuccess={this._refetchSearch}>
                      {({ showModal }) => (
                          <Button variant="contained" onClick={showModal}>
                            <FormattedMessage id={'scenes.members.uploadHIOExcelButton'} />
                          </Button>
                      )}
                    </UploadHIOExcel>
                  </Grid>
                </Grid>
              </FederationRoleScopeGuard>
              <PlayersSearch/>
              <RoleScopeGuard>
                {({ isFederationScope }) => (
                    <PlayersTable
                        locale={locale}
                        showHIOColumn={isFederationScope}
                        onPlayerClicked={(memberId, clubId) => history.push(`${url}/${memberId}/${clubId}`)}
                        onPageChange={this._handlePageChange}
                    />
                )}
              </RoleScopeGuard>
            </SwipeableContentContainer>
            <SwipeableContentContainer>
              <Card style={{ marginTop: '3em' }}>
                <CardHeader
                    title={<FormattedMessage id={'strings.membershipActivationTitle'}/>}
                />
                <CardContent>
                  <TransparentOverlayLoader visible={this.state.requesting} />
                  {!this.state.requesting && (
                      <>
                        {this._renderClubSelectForAdminUser()}

                        <FormControl fullWidth={true} margin={'normal'}>
                          <FormControlLabel
                              control={
                                <Switch
                                    disabled={this.state.requesting || !this.state.club}
                                    color={'primary'}
                                    checked={(this.state.club && this.state.club.activateIfBillsPaid) || false}
                                    onChange={this._handleChange('activateIfBillsPaid')}
                                    value="activateIfBillsPaid"
                                />
                              }
                              label={<FormattedMessage id={'scenes.members.modal.upload.fields.automaticActivation'} />}
                          />
                        </FormControl>

                        <Button
                            disabled={
                                this.state.requesting ||
                                !this.state.club ||
                                (this.state.club && this.state.club.activateIfBillsPaid)
                            }
                            variant="contained"
                            onClick={() => this.setState({ excelUploadModalOpen: true })}
                        >
                          <FormattedMessage id={'scenes.members.uploadExcelButton'} />
                        </Button>
                      </>)}

                </CardContent>
              </Card>
            </SwipeableContentContainer>
          </SwipeableViews>

          <UploadMembersExcelModal
            open={this.state.excelUploadModalOpen}
            club={this.props.clubState}
            clubId={this.state.club?.idClub}
            onClose={() => {
              this.setState({ excelUploadModalOpen: false });
            }}
          />

        </ContentWrap>
      </>
    );
  }

  _refetchSearch = () => {
    const {
      clubState: {
        searchActive,
        search,
        pagination: {
          page
        }
      },
      fetchSearch,
      locale: {
        appLanguage: {
          collation
        }
      }
    } = this.props;

    if (searchActive) {
      fetchSearch({ page, params: { ...search, collation, clubId: this.state.club?.idClub } });
    }
  }

  _handlePageChange = (page: number) => {
    const {
      clubState: {
        pagedMembers,
        searchActive,
        search
      },
      fetchClubMembers,
      fetchSearch,
      changePage,
      locale: {
        appLanguage: {
          collation
        }
      }
    } = this.props;

    if (pagedMembers[page]) {
      changePage({ page });
    } else if (searchActive) {
      fetchSearch({ page, params: { ...search, collation } });
    } else {
      fetchClubMembers({ page });
    }
  }

  private _handleSectionChange = (event, value: number) => {
    this.setState({
      slideIndex: value,
    });
  }
  private _handleChange = name => event => {
    const { updateActivateIfBillsPaid } = this.props;
    const {
      club,
    } = this.state;

    let state = event.target.checked;

    let activateConfirm = this.props.locale.appLanguage.data.scenes.members.modal.activate.confirm;
    let deactivateConfirm = this.props.locale.appLanguage.data.scenes.members.modal.deactivate.confirm;

    confirm({
      message: state ? activateConfirm : deactivateConfirm,
      options: {
        cancelText: this.props.locale.appLanguage.data.buttons.cancel,
        okText: this.props.locale.appLanguage.data.buttons.ok,
      }
    }).then(() => {
      this.setState({ requesting: true });

      updateActivateIfBillsPaid({
        activateIfBillsPaid: state ?? false,
        clubId: club?.idClub as number,
        onComplete: () => {
          let club = this.state.club as Club;
          club.activateIfBillsPaid = state;
          this.setState({ requesting: false, club: club });
        }
      });
    }, () => {

    });
  }

  private _renderClubSelectForAdminUser = (): ReactNode => {
    const { club } = this.state;

    return (
        <FormControl fullWidth={true} margin={'normal'}>
          <ClubSelect
              margin={'normal'}
              selectedClubs={club}
              onChangeCallback={(value: any) => {
                this.setState({
                  club: value,
                });
                if (value) {
                  const { fetchClub } = this.props;
                  fetchClub({ clubId: value.idClub });
                }
              }}
          />
        </FormControl>
    );
  }

}

const AdminMembersTargetted = injectIntl(withRouter(connect((state: StoreState) => ({
  clubState: state.clubReducer,
  auth: state.authReducer,
  locale: state.locale
}), {
  fetchClubMembers: clubActions.fetchAdminClubMembers,
  fetchClub: clubActions.fetchClub,
  changePage: clubActions.changePage,
  searchChanged: clubActions.searchChanged,
  fetchSearch: clubActions.fetchAdminMembersSearch,
  resetSearch: clubActions.resetAdminSearch,
  setClubMemberActivation: clubActions.setClubMemberActivation,
  setTargetIdParams: clubActions.setTargetIdParams,
  updateActivateIfBillsPaid: clubActions.updateActivateIfBillsPaid,
})(AdminMembersConnected)));

export const AdminMembers = connect((state: StoreState) => ({
  federationId: getActiveFederationId(state),
  locale: state.locale
}), null)(({
  federationId,
}) => (<AdminMembersTargetted targetIdParams={{ federationId }} />));
