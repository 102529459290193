import { call, put } from 'redux-saga/effects';
import {
  REPORT_EBIRDIE_STATISTICS_REQUESTING_ERROR,
  REPORT_EBIRDIE_STATISTICS_REQUESTING_SUCCESS
} from '@src/store/reports/constants';
import { API_ROOT, APIRoute } from '@src/assets/config';
import { tokenAPIGet } from '@src/utils/storeUtils';

export function* getEBirdieStatisticsFlow(token: string, clubId?: number) {
  try {
    const rootUrl = `${API_ROOT}${APIRoute.GET_EBIRDIE_STATISTICS}`;
    const url = clubId ? `${rootUrl}?clubId=${clubId}` : rootUrl;

    const response = yield call(() => {
      return tokenAPIGet(url, token);
    });

    if (response) {
      const { stats } = response;
      yield put({ type: REPORT_EBIRDIE_STATISTICS_REQUESTING_SUCCESS, data: stats });
    }
  } catch (error) {
    yield put({ type: REPORT_EBIRDIE_STATISTICS_REQUESTING_ERROR, error });
  }
}