import {
  REPORT_ADD_REQUESTING,
  REPORT_DELETE_REQUESTING,
  REPORT_EDIT_REQUESTING,
  REPORT_GET_REQUESTING,
} from './constants';

export const getReport = ({
  id
}: Report2Get) => {
  return {
    type: REPORT_GET_REQUESTING,
    reportId: id,
  };
};

export const addReport = (params: Report2Add) => {
  return {
    type: REPORT_ADD_REQUESTING,
    params
  };
};

export const editReport = (params: Report2Edit) => {
  return {
    type: REPORT_EDIT_REQUESTING,
    reportId: params.id,
    params
  };
};

export const deleteReport = ({
  id,
  onComplete,
}: Report2Delete) => {
  return {
    type: REPORT_DELETE_REQUESTING,
    reportId: id,
    params: {
      onComplete,
    },
  };
};