import React, { PureComponent } from 'react';
import Functionaries from '@src/components/scenes/functionaries/Functionaries';
import { RouteComponentProps, withRouter } from 'react-router';

class ClubFunctionaries extends PureComponent<{} & RouteComponentProps<any>> {
  render() {
    const {
      match: {
        params: {
          clubId
        }
      }
    } = this.props;

    return (
      <Functionaries targetIdParams={{ clubId }}/>
    );
  }
}

export default withRouter(ClubFunctionaries);