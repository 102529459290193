import React, { useState } from 'react';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { EditIconFab } from '@src/components/buttons/buttons';
import { FormattedMessage } from 'react-intl';
import { ListItemType, renderListWithGrid } from '@src/components/layouts/ui';
import { get } from 'lodash';
import { sumCourseHoles } from '@src/utils/courseUtils';
import { ClubCourseModal } from '@src/components/scenes/club/course/ClubCourseModal';
import { FederationClubAdminRoleGuard } from '@src/components/access-control/FederationClubAdminRoleGuard';

interface Props {
  isEditable?: boolean;
  course?: ClubCourse;
}

function ClubCourseBasicInfoCard({
  isEditable = true,
  course,
}: Props) {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const getCourseValue = (key: string, defaultValue?: any): any => (
    get(course, key, defaultValue || '-')
  );

  const listData: ListItemType[] = [
    {
      label: 'strings.name',
      value: getCourseValue('name'),
    },
    {
      label: 'scenes.golfClub.course.basicInfo.holeCount',
      value: getCourseValue('holes.length'),
    },
    {
      label: 'scenes.golfClub.course.basicInfo.par',
      value: sumCourseHoles(getCourseValue('holes', []))
    },
    {
      label: 'scenes.golfClub.course.basicInfo.designedBy',
      value: getCourseValue('designerName'),
    },
    {
      label: 'scenes.golfClub.course.basicInfo.completionYear',
      value: getCourseValue('completionTime') || '-',
    },
    {
      label: 'scenes.golfClub.course.basicInfo.characterOfCourse',
      value: getCourseValue('characterOfCourse') || '-',
    },
    {
      label: 'scenes.golfClub.course.basicInfo.description',
      value: getCourseValue('description') || '-',
    },
    {
      label: 'scenes.golfClub.course.basicInfo.location',
      value: '-',
    },
  ];

  const clubId = get(course, 'clubInfo.idClub');
  const courseId = get(course, 'id');

  return (
    <>
      <Card>
        <CardHeader
          action={isEditable ? (
            <FederationClubAdminRoleGuard>
              <EditIconFab onClick={() => setModalOpen(true)}/>
            </FederationClubAdminRoleGuard>
          ) : undefined
          }
          title={<FormattedMessage id={'scenes.golfClub.course.basicInfo.title'}/>}
        />
        <CardContent>
          {renderListWithGrid(listData, 2)}
        </CardContent>
      </Card>

      {course && (
        <ClubCourseModal
          clubId={parseInt(`${clubId}`, 10)}
          clubCourseId={parseInt(`${courseId}`, 10)}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </>
  );
}

export default ClubCourseBasicInfoCard;