export const COMPANY_GET_REQUESTING = 'COMPANY_GET_REQUESTING';
export const COMPANY_GET_SUCCESS = 'COMPANY_GET_SUCCESS';
export const COMPANY_GET_ERROR = 'COMPANY_GET_ERROR';

export const COMPANY_ADD_REQUESTING = 'COMPANY_ADD_REQUESTING';
export const COMPANY_ADD_SUCCESS = 'COMPANY_ADD_SUCCESS';
export const COMPANY_ADD_ERROR = 'COMPANY_ADD_ERROR';

export const COMPANY_EDIT_REQUESTING = 'COMPANY_EDIT_REQUESTING';
export const COMPANY_EDIT_SUCCESS = 'COMPANY_EDIT_SUCCESS';
export const COMPANY_EDIT_ERROR = 'COMPANY_EDIT_ERROR';

export const COMPANY_DELETE_REQUESTING = 'COMPANY_DELETE_REQUESTING';
export const COMPANY_DELETE_SUCCESS = 'COMPANY_DELETE_SUCCESS';
export const COMPANY_DELETE_ERROR = 'COMPANY_DELETE_ERROR';