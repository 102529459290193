import { take, fork, select } from 'redux-saga/effects';
import {
  FUNCTIONARY_TITLES_REQUESTING,
  FUNCTIONARY_TITLES_SUCCESS,
  FUNCTIONARY_TITLES_ERROR
} from './constants';
import { APIRoute } from '@src/assets/config';
import {
  AUTH_ACTIVE_STATE_CHANGE,
  AUTH_SET,
  INITIAL_AUTH_ACTIVE_STATE_SET
} from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import {
  handlePagedDataRefetch,
  stateTargetIdParams
} from '@src/utils/storeUtils';
import {
  FUNCTIONARIES_SET_TARGET_ID_PARAMS
} from '@src/store/functionaries/constants';
import { getCondensedFunctionaryTitles } from '@src/store/functionarytitles/flows/fetch-condensed-titles';
import {
  FUNCTIONARY_TITLE_ADD_SUCCESS,
  FUNCTIONARY_TITLE_DELETE_SUCCESS,
  FUNCTIONARY_TITLE_EDIT_SUCCESS
} from '@src/store/functionarytitle/constants';

/*
  GET /api/1/functionarytitles
 */

const functionaryTitlesFetchFlow = SagaFlowFactory.createPagedGETFlow({
  apiRoute: APIRoute.GET_FUNCTIONARY_TITLES,
  itemType: 'titles',
  successType: FUNCTIONARY_TITLES_SUCCESS,
  errorType: FUNCTIONARY_TITLES_ERROR
});

const REDUCER_NAME = 'functionaryTitlesReducer';

function * functionaryTitlesWatcher() {
  let token: string = '';

  yield fork(function* () {
    const {
      user,
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
      page,
      limit,
      params
    } = yield take([
      FUNCTIONARY_TITLES_REQUESTING,
      AUTH_ACTIVE_STATE_CHANGE,
      INITIAL_AUTH_ACTIVE_STATE_SET,
      FUNCTIONARIES_SET_TARGET_ID_PARAMS,
      FUNCTIONARY_TITLE_ADD_SUCCESS,
      FUNCTIONARY_TITLE_DELETE_SUCCESS,
      FUNCTIONARY_TITLE_EDIT_SUCCESS,
    ]);

    const targetIdParams = yield select(stateTargetIdParams(REDUCER_NAME));

    switch (type) {
      case FUNCTIONARY_TITLES_REQUESTING:
        yield fork(
          functionaryTitlesFetchFlow,
          token,
          page,
          limit,
          { ...params, ...targetIdParams }
        );
        break;

      case INITIAL_AUTH_ACTIVE_STATE_SET:
      case AUTH_ACTIVE_STATE_CHANGE:
        // targetingParams = parseTargettingParamsFromActiveState(activeState);
        break;

      case FUNCTIONARIES_SET_TARGET_ID_PARAMS:
        yield fork(getCondensedFunctionaryTitles, token, targetIdParams);
        break;

      case FUNCTIONARY_TITLE_ADD_SUCCESS:
      case FUNCTIONARY_TITLE_DELETE_SUCCESS: {
        yield fork(
          handlePagedDataRefetch,
          REDUCER_NAME,
          FUNCTIONARY_TITLES_REQUESTING,
        );
        yield fork(getCondensedFunctionaryTitles, token, targetIdParams);
        break;
      }

      case FUNCTIONARY_TITLE_EDIT_SUCCESS: {
        yield fork(getCondensedFunctionaryTitles, token, targetIdParams);
        break;
      }

      default:
    }
  }
}

export default functionaryTitlesWatcher;