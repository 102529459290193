import React, { PureComponent } from 'react';
import Organizations from '@src/components/scenes/organization/Organizations';
import { TargetIdContext } from '@src/components/contexts';
import { connect } from 'react-redux';
import { getActiveFederationId } from '@src/store/auth/selectors';

type Props = {
  federationId: number;
  locale: AppLocale;
};

class FederationOrganizations extends PureComponent<Props, {}> {
  render() {
    const { federationId, locale: { appLanguage: { collation } } } = this.props;

    return (
      <TargetIdContext.Provider value={{ federationId, collation }}>
        <Organizations
          targetIdParams={{ federationId, collation }}
        />
      </TargetIdContext.Provider>
    );
  }
}

export default connect((state: StoreState) => ({
  federationId: getActiveFederationId(state),
  locale: state.locale
}), null)(FederationOrganizations);