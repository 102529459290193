import React, { PureComponent, ChangeEvent, RefObject } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  TextField
} from '@material-ui/core';
import { renderFormActions } from '@src/components/modals/ui';
import { connect } from 'react-redux';
import * as partnerActions from '@src/store/partner/actions';
import InputsValidator from '@src/components/forms/InputsValidator';

interface Props {
  partnerId?: number | undefined;
  partner: PartnerState;
  parentContext?: 'MODAL' | 'PAGE';
  onClose?: () => void;
  addPartner: (params: PartnerAdd) => any;
  editPartner: (params: PartnerEdit) => any;
  actionsContainerRef?: RefObject<HTMLElement>;
}

interface State {
  name: string;
  users: Array<User>;
}

const initialState: State = {
  name: '',
  users: [],
};

class PartnerFormConnected extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const {
      partnerId,
      partner: {
        partner
      }
    } = this.props;

    if (!partnerId) {
      this.state = initialState;
    } else if (partner) {
      this.state = partner;
    } else {
      this.state = initialState;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      partnerId,
      partner: {
        partner,
        requesting,
      },
    } = nextProps;

    if (!partnerId) {
      return; // Do nothing if we are adding new person
    } else if (requesting) {
      return; // Do nothing state is loading
    } else if (partner) {
      this.setState({
        ...partner as any
      });
    }
  }

  render() {
    const {
      partnerId,
      parentContext,
      actionsContainerRef,
    } = this.props;

    const {
      name,
      users,
    } = this.state;

    return (
        <InputsValidator values={{name}}>
          {({errorInputs, validateThenApply, validateValues}) => (
              <>
                <TextField
                    error={errorInputs.name}
                    required={true}
                    margin={'normal'}
                    fullWidth={true}
                    label={<FormattedMessage id={'scenes.partners.form.nameInput'}/>}
                    value={name}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const {
                        currentTarget: {
                          value
                        }
                      } = e;
                      this.setState({name: value}, validateValues);
                    }}
                />
                {partnerId && this._renderUsers(users)}
                {renderFormActions({
                  id: partnerId,
                  parentContext,
                  onClose: this._handleOnClose,
                  onUpdate: validateThenApply.bind(this, this._handleUpdate),
                  onCreate: validateThenApply.bind(this, this._handleCreate),
                  containerRef: actionsContainerRef,
                })}

              </>
          )}
        </InputsValidator>
    );
  }

  private _renderUsers = (users: any) => {
    const {
      partnerId,
    } = this.props;

    if (!partnerId) {
      return;
    }
    return (
        <>
          <FormattedMessage id={'scenes.partners.form.usersLabel'} />
          {' (' + users.length + ')'}
          <ul>{
            users.length > 0 ?
                users.map(function (item) {
                  return item.name + ' (' + item.username + ')';
                }).join(', ') : '-'}
          </ul>
        </>);
  }

  private _handleOnClose = () => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  }

  private _handleUpdate = () => {
    const { editPartner } = this.props;
    const params = this._formPartnerParams();
    const {
      partner: {
        partner
      }
    } = this.props;

    if (!partner) {
      return;
    }

    editPartner({
      id: partner.id,
      ...params,
      onComplete: this._handleOnComplete,
    });
  }

  private _handleCreate = () => {
    const { addPartner } = this.props;
    const params = this._formPartnerParams();

    addPartner({
      ...params,
      onComplete: this._handleOnComplete,
    });
  }

  private _formPartnerParams = () => {
    const { name, users } = this.state;

    return {
      name,
      userIds: users.map(user => user.id),
    };
  }

  private _handleOnComplete = (result: any) => {
    if (result.error) {
      window.alert(result.error.message);
    } else {
      this._handleOnClose();
    }
  }
}

const PartnerForm = connect((state: any) => ({
  partner: state.partnerReducer,
}), {
  addPartner : partnerActions.addPartner,
  editPartner : partnerActions.editPartner
})(PartnerFormConnected);

export {
  PartnerForm
};
