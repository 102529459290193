import React from 'react';
import Helmet from 'react-helmet';
import { SITE_TITLE, TITLE_DIVIDER } from '@src/assets/config';
import { injectIntl, WrappedComponentProps, IntlFormatters } from 'react-intl';
import { connect } from 'react-redux';

const renderTitleField = (titleId?: string, intl?: IntlFormatters, values: any = {}) => {
  if (titleId && intl) {
    return `${intl.formatMessage({ id: titleId }, values)}${TITLE_DIVIDER}${SITE_TITLE}`;
  }

  return SITE_TITLE;
};

export interface HelmetHead extends WrappedComponentProps {
  titleId?: string;
  locale: AppLocale;
  values?: any;
}

const HeadHelmet = ({
  titleId,
  intl,
  locale,
  values,
}: HelmetHead) => {
  return (
    <Helmet htmlAttributes={{ lang : locale.appLanguage.code }}>
      <title>{renderTitleField(titleId, intl, values)}</title>
    </Helmet>
  );
};

export default connect((state: any) => ({
  locale: state.locale,
}))(injectIntl(HeadHelmet));