import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import {
  ContentHeader,
  ContentHeaderTitle,
  ContentWrap, TransparentOverlayLoader,
} from '@src/components/layouts/ui';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as clubActions from '@src/store/club/actions';
import PlayersSearch from '@src/components/scenes/members/ui/PlayersSearch';
import PlayersTable from '@src/components/scenes/members/ui/PlayersTable';
import { UploadMembersExcelModal } from '@src/components/modals/UploadMembersExcelModal';
import { Button, Card, CardContent, CardHeader, FormControlLabel, Switch } from '@material-ui/core';
import { cleanTrailingSlash, targetIdParamsChanged } from '@src/utils/storeUtils';
import FormControl from '@material-ui/core/FormControl/FormControl';
import { confirm } from '@src/components/modals/Confirm';

interface Props extends RouteComponentProps<any> {
  clubState: ClubState;
  fetchClubMembers: (params: MembersTablePaginationAction) => any;
  searchChanged: (params: ClubMembersSearch) => any;
  fetchSearch: (params: SearchPaginationAction) => any;
  resetSearch: () => any;
  updateActivateIfBillsPaid: (params: ActivateIfBillsPaidUpdate) => any;
  setTargetIdParams: (params: SetTargetIdParams) => any;
  targetIdParams: TargetIdParams;
  locale: AppLocale;
}

interface State {
  excelUploadModalOpen: boolean;
  activateIfBillsPaid?: boolean|undefined;
  requesting: boolean;
}

class ClubPlayers extends PureComponent<Props, State> {
  state: State = {
    excelUploadModalOpen: false,
    activateIfBillsPaid: this.props.clubState.club?.activateIfBillsPaid,
    requesting: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.clubState.club?.idClub) {
      this.setState({ requesting: false, activateIfBillsPaid: nextProps.clubState.club?.activateIfBillsPaid });
    }
  }

  UNSAFE_componentWillMount() {
    const {
      setTargetIdParams,
      clubState: {
        targetIdParams,
      }
    } = this.props;

    if (targetIdParamsChanged(targetIdParams, this.props.targetIdParams)) {
      setTargetIdParams({
        resetState: true,
        targetIdParams: this.props.targetIdParams,
      });
    }
    this.setState({ requesting: true});
  }

  render() {
    const { excelUploadModalOpen } = this.state;
    const {
      history,
      targetIdParams: {
        clubId,
      },
      locale
    } = this.props;

    return (
      <>
        <HeadHelmet titleId={'navigation.golfClubMembers'} />
        <ContentWrap>
          <ContentHeader>
            <ContentHeaderTitle>
              <FormattedMessage id={'navigation.golfClubMembers'}/>
            </ContentHeaderTitle>

            <Card style={{ marginTop: '3em' }}>
              <CardHeader
                  title={<FormattedMessage id={'strings.membershipActivationTitle'}/>}
              />
              <CardContent>
                <TransparentOverlayLoader visible={this.state.requesting} />
                {!this.state.requesting && (
                <>
                  <FormControl fullWidth={true} margin={'normal'}>
                    <FormControlLabel
                        control={
                          <Switch
                              disabled={this.state.requesting}
                              color={'primary'}
                              checked={this.state.activateIfBillsPaid || false}
                              onChange={this._handleChange('activateIfBillsPaid')}
                              value="activateIfBillsPaid"
                          />
                        }
                        label={<FormattedMessage id={'scenes.members.modal.upload.fields.automaticActivation'} />}
                    />
                  </FormControl>

                  <Button
                      disabled={this.state.requesting || this.state.activateIfBillsPaid}
                      variant="contained"
                      onClick={() => this.setState({ excelUploadModalOpen: true })}
                  >
                    <FormattedMessage id={'scenes.members.uploadExcelButton'} />
                  </Button>
                </>)}

              </CardContent>
            </Card>

            <PlayersSearch clubId={clubId} />
          </ContentHeader>

          <PlayersTable
            locale={locale}
            onPlayerClicked={(memberId) => {
              const { match: { url } } = this.props;
              history.push(cleanTrailingSlash(url, `/${memberId}`));
            }}
          />

          <UploadMembersExcelModal
            clubId={clubId}
            club={this.props.clubState}
            open={excelUploadModalOpen}
            onClose={() => {
              this.setState({ excelUploadModalOpen: false });
            }}
          />

        </ContentWrap>
      </>
    );
  }
  private _handleChange = name => event => {
    const { updateActivateIfBillsPaid } = this.props;
    const {
      targetIdParams: {
        clubId,
      },
    } = this.props;

    let state = event.target.checked;

    let activateConfirm = this.props.locale.appLanguage.data.scenes.members.modal.activate.confirm;
    let deactivateConfirm = this.props.locale.appLanguage.data.scenes.members.modal.deactivate.confirm;

    confirm({
      message: state ? activateConfirm : deactivateConfirm,
      options: {
        cancelText: this.props.locale.appLanguage.data.buttons.cancel,
        okText: this.props.locale.appLanguage.data.buttons.ok,
      }
    }).then(() => {
      this.setState({ requesting: true });

      updateActivateIfBillsPaid({
        activateIfBillsPaid: state ?? false,
        clubId: clubId as number,
        onComplete: () => {
          this.setState({ requesting: false, activateIfBillsPaid: state });
        }
      });
    }, () => {

    });
  }

}

const ConnectedClubPlayers = withRouter(connect((state: StoreState) => ({
  clubState: state.clubReducer,
  locale: state.locale
}), {
  fetchClubMembers: clubActions.fetchClubMembers,
  searchChanged: clubActions.searchChanged,
  fetchSearch: clubActions.fetchMembersSearch,
  resetSearch: clubActions.resetSearch,
  updateActivateIfBillsPaid: clubActions.updateActivateIfBillsPaid,
  setTargetIdParams: clubActions.setTargetIdParams,
})(ClubPlayers));

export default withRouter((props: RouteComponentProps<any>) => {
  const {
    match: {
      params,
    }
  } = props;
  return (<ConnectedClubPlayers targetIdParams={{ clubId: params.clubId }} />);
});
