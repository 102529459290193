import {
  FUNCTIONARY_TITLE_ADD_REQUESTING,
  FUNCTIONARY_TITLE_DELETE_REQUESTING,
  FUNCTIONARY_TITLE_EDIT_REQUESTING,
  FUNCTIONARY_TITLE_GET_REQUESTING
} from '@src/store/functionarytitle/constants';

export const getFunctionaryTitle = ({
  id
}: FunctionaryTitleGet) => {
  return {
    type: FUNCTIONARY_TITLE_GET_REQUESTING,
    functionaryTitleId: id,
  };
};

export const addFunctionaryTitle = (params: FunctionaryTitleAdd) => {
  return {
    type: FUNCTIONARY_TITLE_ADD_REQUESTING,
    params
  };
};

export const editFunctionaryTitle = (params: FunctionaryTitleEdit) => {
  return {
    type: FUNCTIONARY_TITLE_EDIT_REQUESTING,
    functionaryTitleId: params.id,
    params
  };
};

export const deleteFunctionaryTitle = ({
  id,
  onComplete,
}: FunctionaryTitleDelete) => {
  return {
    type: FUNCTIONARY_TITLE_DELETE_REQUESTING,
    functionaryTitleId: id,
    params: {
      onComplete,
    },
  };
};