import {
  FUNCTIONARIES_REQUESTING,
  FUNCTIONARIES_SUCCESS,
  FUNCTIONARIES_ERROR,
  FUNCTIONARIES_CHANGE_PAGE,
  FUNCTIONARIES_SEARCH_CHANGED,
  FUNCTIONARIES_SEARCH_REQUESTING,
  FUNCTIONARIES_SEARCH_RESET, FUNCTIONARIES_SET_TARGET_ID_PARAMS, FUNCTIONARIES_CONDENSED_SUCCESS,
} from './constants';
import { SHARED_DEFAULT_REDUCER_STATE } from '@src/assets/config';
import {
  formDefaultErrorState,
  formDefaultPagedState,
  formDefaultRequestingState, formDefaultTargetIdState,
  fromDefaultPageChangeState, optimisticPagedObjectUpdate,
  searchStateChangedReducerHelper,
} from '@src/utils/storeUtils';
import { FUNCTIONARY_TITLES_SET_TARGET_ID_PARAMS } from '@src/store/functionarytitles/constants';
import {
  FUNCTIONARY_ADD_SUCCESS,
  FUNCTIONARY_DELETE_SUCCESS,
  FUNCTIONARY_EDIT_SUCCESS,
} from '@src/store/functionary/constants';

const initialState: FunctionariesState = {
  ...SHARED_DEFAULT_REDUCER_STATE,
  pagedFunctionaries: {},
  search: {
    searchTerm: '',
    functionaryTitleId: 0,
    status: undefined
  },
  allFunctionaries: [],
};

export const functionariesReducer = (state: FunctionariesState = initialState, action: any): FunctionariesState => {
  switch (action.type) {
    case FUNCTIONARY_TITLES_SET_TARGET_ID_PARAMS:
    case FUNCTIONARIES_SET_TARGET_ID_PARAMS:
      return formDefaultTargetIdState(initialState, state, action);

    case FUNCTIONARIES_REQUESTING:
      return formDefaultRequestingState(state);

    case FUNCTIONARIES_SUCCESS:
      return formDefaultPagedState(state, action, 'pagedFunctionaries');

    case FUNCTIONARIES_CHANGE_PAGE:
      return fromDefaultPageChangeState(state, action);

    case FUNCTIONARIES_ERROR:
      return formDefaultErrorState(state, action);

    case FUNCTIONARIES_SEARCH_CHANGED: {
      return searchStateChangedReducerHelper(state, action);
    }

    case FUNCTIONARIES_SEARCH_REQUESTING: {
      let searchActive = state.searchActive;
      let pagedFunctionaries = state.pagedFunctionaries;
      let pagination = state.pagination;

      if (!searchActive) {
        searchActive = true;
        pagedFunctionaries = {};
        pagination.totalCount = 0;
      }

      pagination.page = action.page;

      return {
        ...state,
        pagedFunctionaries,
        pagination,
        searchActive,
        requesting: true
      };
    }

    case FUNCTIONARIES_SEARCH_RESET:
      return {
        ...initialState,
        requesting: true,
        targetIdParams: state.targetIdParams,
      };

    case FUNCTIONARY_ADD_SUCCESS:
    case FUNCTIONARY_DELETE_SUCCESS: {
      return {
        ...initialState,
        targetIdParams: state.targetIdParams,
        search: state.search,
        searchActive: state.searchActive,
      };
    }

    case FUNCTIONARY_EDIT_SUCCESS: {
      const { data } = action;
      const page = state.pagination.page;

      const pagedFunctionaries = optimisticPagedObjectUpdate(state.pagedFunctionaries, page, { ...data });

      return {
        ...state,
        pagedFunctionaries,
      };
    }

    case FUNCTIONARIES_CONDENSED_SUCCESS: {
      const { allFunctionaries } = action;
      return {
        ...state,
        allFunctionaries,
      };
    }

    default:
      return state;
  }
};