export const MESSAGE_GET_REQUESTING = 'MESSAGE_GET_REQUESTING';
export const MESSAGE_GET_SUCCESS = 'MESSAGE_GET_SUCCESS';
export const MESSAGE_GET_ERROR = 'MESSAGE_GET_ERROR';

export const MESSAGE_ADD_REQUESTING = 'MESSAGE_ADD_REQUESTING';
export const MESSAGE_ADD_SUCCESS = 'MESSAGE_ADD_SUCCESS';
export const MESSAGE_ADD_ERROR = 'MESSAGE_ADD_ERROR';

export const MESSAGE_EDIT_REQUESTING = 'MESSAGE_EDIT_REQUESTING';
export const MESSAGE_EDIT_SUCCESS = 'MESSAGE_EDIT_SUCCESS';
export const MESSAGE_EDIT_ERROR = 'MESSAGE_EDIT_ERROR';

export const MESSAGE_DELETE_REQUESTING = 'MESSAGE_DELETE_REQUESTING';
export const MESSAGE_DELETE_SUCCESS = 'MESSAGE_DELETE_SUCCESS';
export const MESSAGE_DELETE_ERROR = 'MESSAGE_DELETE_ERROR';
