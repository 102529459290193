import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { DateTimePicker, DateTimePickerProps } from '@material-ui/pickers';

type Props = DateTimePickerProps & WrappedComponentProps;

class LocalizedDateTimePicker extends React.PureComponent<Props, {}> {
  render() {
    const {
      intl,
      ...rest
    } = this.props;

    return (
      <DateTimePicker
        ampm={false}
        format={intl.formatMessage({ id: 'strings.dateTimeFormat' })}
        cancelLabel={intl.formatMessage({ id: 'buttons.cancel' })}
        okLabel={intl.formatMessage({ id: 'buttons.ok' })}
        clearLabel={intl.formatMessage({ id: 'buttons.clear' })}
        {...rest}
      />
    );
  }
}

export default injectIntl(LocalizedDateTimePicker);