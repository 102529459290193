import React from 'react';

interface Props {
  par?: number | string;
  holes?: CourseHole[];
}

function ClubCourseParCount({
  par,
  holes,
}: Props) {
  if (!par && !holes) {
    return (<>-</>);
  } else if (par) {
    return (<>{par}</>);
  }

  return (
    <>
      {(holes && holes.length) ? holes.reduce((sum, hole) => sum + hole.holePar, 0) : 0}
    </>
  );
}

export default ClubCourseParCount;