import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  wrap: {
    display: 'flex',
    height: 43,
  },
});

function AdjustmentsInheritanceWrap({ children }) {
  const classes = useStyles();

  return (
    <span className={classes.wrap}>
      {children}
    </span>
  );
}

export default AdjustmentsInheritanceWrap;