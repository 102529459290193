import React from 'react';
import RoleScopeGuard from '@src/components/access-control/RoleScopeGuard';

type FederationClubAdminRoleGuard = {
  children?: React.ReactNode;
};

export const FederationClubAdminRoleGuard = ({
  children,
}: FederationClubAdminRoleGuard) => {
  return (
    <RoleScopeGuard>
      {({ isClubScope, isFederationScope, isAdminRole }) =>
          Boolean((isClubScope || isFederationScope) && isAdminRole) && (children)}
    </RoleScopeGuard>
  );
};