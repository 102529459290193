import { sortBy, find, flatMapDeep, uniqBy } from 'lodash';

export const isCourseDraft = (course: ClubCourse | Pick<ClubCourse , 'timeEffective'>): boolean => (
  course.timeEffective === null
);

export const getCourseChildren = (course: ClubCourse): ClubCourseSibling[] => (
  course.children === null ? [] : sortBy(course.children, 'courseType').reverse()
);

export const getCourseParents = (course: ClubCourse): ClubCourseSibling[] => (
  course.parents === null ? [] : course.parents
);

export const sumCourseHoles = (holes: CourseHole[]): number => (
  holes.reduce((sum, hole) => sum + hole.holePar, 0)
);

const mapToIdNameObjects = ((items: CondensedClubCourseSibling[], parent = false) => items.map(({
  idCourse,
  name,
  parents = [],
  children = [],
}: CondensedClubCourseSibling) => ([
  { id: idCourse, name, parent },
  ...parents.map(({ id, name }) => ({ id, name, parent: true })),
  ...children.map(({ id, name }) => ({ id, name, parent })),
])));

export const findAffectedClubCourses = (
  courseId: number,
  courses: (CondensedClubCourse & { parent?: boolean })[],
): (CondensedClubCourse & { parent?: boolean })[] => {
  const course = find(courses, { 'id': courseId });
  const affected: any[] = [];

  if (course) {
    const { id, name, children, parents } = course;

    affected.push({ id, name, parent: !!children });

    if (parents) {
      affected.push(mapToIdNameObjects(parents, true));
    }

    if (children) {
      affected.push(mapToIdNameObjects(children));
    }

    return sortBy(uniqBy(flatMapDeep(affected), (item) => item.id), [
      (item) => !item.parent,
      'name',
    ]);
  }

  return [];
};