import React from 'react';
import { connect } from 'react-redux';
import PersonsSearchBar from '@src/components/persons/PersonsSearchBar';
import PersonsTable from '@src/components/persons/PersonsTable';

type Props = {
  selectedPersonIds?: number[];
  onPersonPicked?: (person: Person) => any;
  customNode?: React.ReactNode;
};

class PersonsSearch extends React.Component<Props, {}> {
  render() {
    const {
      onPersonPicked,
      selectedPersonIds = [],
      customNode,
    } = this.props;

    return (
      <>
        <PersonsSearchBar
          customNode={customNode}
        />
        <PersonsTable
          onPersonPicked={onPersonPicked}
          selectedPersonIds={selectedPersonIds}
        />
      </>
    );
  }
}

export default connect(null, null)(PersonsSearch);