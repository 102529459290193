import React, { createRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as clubServiceActions from '@src/store/clubService/actions';
import {
  Dialog,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { ModalContentOverlayLoader } from '@src/components/modals/ui';
import { ClubServiceForm } from '@src/components/scenes/club/services/ClubServiceForm';

interface Props {
  clubId: number;
  clubServiceId: number | undefined;
  open: boolean;
  onClose: () => void;
  getClubService: (params: ClubServiceGet) => any;
  clubServiceState: ClubServiceState;
}

class ClubServiceModalConnected extends React.Component<Props, {}> {
  actionsContainerRef = createRef<HTMLDivElement>();

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      clubServiceId,
      getClubService,
      clubId
    } = this.props;

    if (nextProps.clubServiceId !== clubServiceId) {
      if (nextProps.clubServiceId) {
        getClubService({
          clubId: clubId,
          id: nextProps.clubServiceId,
        });
      }
    }
  }

  render() {
    const {
      open,
      clubServiceId,
      clubId,
      onClose,
      clubServiceState: {
        requesting
      }
    } = this.props;

    return (
      <Dialog
        open={open}
        fullWidth={true}
      >
        <DialogTitle>
          {clubServiceId && <FormattedMessage id={'scenes.golfClub.services.modal.edit.title'} />}
          {!clubServiceId && <FormattedMessage id={'scenes.golfClub.services.modal.add.title'} />}
        </DialogTitle>
        <DialogContent>
          {requesting && <ModalContentOverlayLoader/>}
          <ClubServiceForm
            clubId={clubId}
            clubServiceId={clubServiceId}
            parentContext={'MODAL'}
            onClose={onClose}
            actionsContainerRef={this.actionsContainerRef}
          />
        </DialogContent>

        <div ref={this.actionsContainerRef} />
      </Dialog>
    );
  }
}

const ClubServiceModal = connect((state: any) => ({
  clubServiceState: state.clubServiceReducer,
}), {
  getClubService: clubServiceActions.getClubService
})(ClubServiceModalConnected);

export {
  ClubServiceModal,
};