import React, { PureComponent, ChangeEvent, RefObject } from 'react';
import {
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormLabel,
  Button,
  Typography,
} from '@material-ui/core';
import { ModalContentOverlayLoader, renderFormActions } from '@src/components/modals/ui';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import { FormattedMessage, WrappedComponentProps } from 'react-intl';
import { injectIntl } from 'react-intl';
import { ReceivesClubLetterEnum, TargetingForm } from '@src/components/forms/ui/TargetingForm';
import * as messageAction from '@src/store/message/actions';
import * as messagesAction from '@src/store/messages/actions';
import { connect } from 'react-redux';
import { MessagePreview } from '@src/components/forms/ui/MessagePreview';
import { SwipeableContentContainer } from '@src/components/forms/ui/OfferForm';
import FormGroup from '@material-ui/core/FormGroup/FormGroup';
import LocalizedDateTimePicker from '@src/components/forms/LocalizedDateTimePicker';
import InputsValidator from '@src/components/forms/InputsValidator';
import moment from 'moment';
import MarkdownEditor from '@src/components/forms/MarkdownEditor';
import { MessageImageUpload } from './MessageImageUpload';
import { GetFromHandicapError, GetToHandicapError, HandicapValue } from '@src/utils/Handicap';
import { parseCorrectMessageSenderObject } from '@src/utils/storeUtils';

interface Props extends WrappedComponentProps {
  messageId?: number | undefined;
  message: MessageState;
  parentContext?: 'MODAL' | 'PAGE';
  onClose?: () => void;
  addMessage: (params: MessageAdd) => any;
  editMessage: (params: MessageEdit) => any;
  fetchAllRecipients: (params: MessageRecipients) => any;
  auth: AuthState;
  actionsContainerRef?: RefObject<HTMLElement>;
}

interface State {
  title: string;
  content: string;
  images: MessageImage[];
  limitHandicapFrom: HandicapValue;
  limitHandicapTo: HandicapValue;
  limitAgeFrom?: number;
  limitAgeTo?: number;
  limitGender: TargetGender[];
  greenCardOnly?: boolean;
  functionaryOnly?: boolean;
  senders?: MessageSender;
  status: MessageStatus;
  slideIndex: number;
  published: Date | null;
  listedReceivers: boolean;
  receiversRequiresUpdate: boolean;
  receiversData?: number[];
  limitFunctionaryTitles?: FunctionaryTitle[] | undefined;
  limitReceivesClubLetter: ReceivesClubLetterEnum;
  limitRegions: RegionEntry[];
  recipientsPreview: Person[];
  limitClubs: Club[];
  requestingRecipients: boolean;
}

const initialState: State = {
  slideIndex: 0,
  title: '',
  content: '',
  images: [],
  limitHandicapFrom: new HandicapValue(),
  limitHandicapTo: new  HandicapValue(),
  limitAgeFrom: undefined,
  limitAgeTo: undefined,
  limitGender: ['MALE', 'FEMALE', 'NONE'] as TargetGender[],
  greenCardOnly: false,
  functionaryOnly: false,
  published: null,
  status: 'DRAFT' as MessageStatus,
  senders: undefined,
  listedReceivers: false,
  receiversRequiresUpdate: false,
  limitFunctionaryTitles: [],
  limitReceivesClubLetter: ReceivesClubLetterEnum.ALL,
  limitRegions: [],
  recipientsPreview: [],
  limitClubs: [],
  requestingRecipients: false
};

class MessageFormComponent extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.targetingUpdated = this.targetingUpdated.bind(this);

    const {
      messageId,
      message: {
        message,
      }
    } = this.props;

    if (!messageId) {
      this.state = {
        ...initialState,
        senders: parseCorrectMessageSenderObject(this.props.auth.activeState),
      };
    } else if (message) {
      this.state = {
        ...message as unknown as State,
        slideIndex: 0,
        senders: message.clubInfo ?
            {
              id: 'club:' + message.clubInfo.idClub,
              entityId: message.clubInfo.idClub,
              name: message.clubInfo.nameClub,
              type: 'club'
            } :
            {
              id: 'federation:' + message.federationInfo.idFederation,
              entityId: message.federationInfo.idFederation,
              name: message.federationInfo.name,
              type: 'federation'
            },
      };

      if (this.state.limitAgeFrom === null) {
        this.state = {
          ...this.state,
          limitAgeFrom: undefined,
        };
      }

      if (this.state.limitAgeTo === null) {
        this.state = {
          ...this.state,
          limitAgeTo: undefined,
        };
      }

      // Initialize handicap limits
      this.state = {
        ...this.state,
        limitHandicapFrom: HandicapValue.parseValue(message.limitHandicapFrom?.toString(10)),
        limitHandicapTo: HandicapValue.parseValue(message.limitHandicapTo?.toString(10)),
        listedReceivers: message.listedReceivers || false,
        receiversData: this.state.receiversData || message.receivers,
      };
    } else {
      this.state = {
        ...initialState,
        senders: parseCorrectMessageSenderObject(this.props.auth.activeState),
      };
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      messageId,
      message: {
        message,
        requesting,
      },
    } = nextProps;

    if (!messageId) {
      return; // Do nothing if we are adding new person
    } else if (requesting) {
      return; // Do nothing state is loading
    } else if (message && message !== this.props.message.message) {
      this.setState({
        ...message as unknown as State,
        senders: message.clubInfo ?
            {
              id: 'club:' + message.clubInfo.idClub,
              entityId: message.clubInfo.idClub,
              name: message.clubInfo.nameClub,
              type: 'club'
            } :
            {
              id: 'federation:' + message.federationInfo.idFederation,
              entityId: message.federationInfo.idFederation,
              name: message.federationInfo.name,
              type: 'federation'
            },
        limitHandicapFrom: HandicapValue.parseValue(message.limitHandicapFrom?.toString(10)),
        limitHandicapTo: HandicapValue.parseValue(message.limitHandicapTo?.toString(10)),
        listedReceivers: message.listedReceivers || false,
        receiversData: this.state.receiversData || message.receivers,
      });
    }
  }

  targetingUpdated(value: any) {
    this.setState(value);
  }

  render() {
    const {
      slideIndex,
      title,
      content,
      status,
      published,
      limitGender,
      senders,
      limitHandicapFrom,
      limitHandicapTo,
      limitAgeFrom,
      limitAgeTo,
      greenCardOnly,
      functionaryOnly,
      receiversData,
      listedReceivers,
      limitFunctionaryTitles,
      limitReceivesClubLetter,
      limitRegions,
      limitClubs,
    } = this.state;

    const {
      parentContext,
      messageId,
      actionsContainerRef,
    } = this.props;

    // Check if handicaps have errors and provide result to InputValidator
    //   There is an error if result !== undefined
    const isHandicapFromError = GetFromHandicapError(limitHandicapFrom, limitHandicapTo, true) ? undefined : 'no error';
    const isHandicapToError = GetToHandicapError(limitHandicapFrom, limitHandicapTo, true) ? undefined : 'no error';

    return (
      <InputsValidator 
        values={{ title, status, content,
        limitHandicapFrom: isHandicapFromError, 
        limitHandicapTo: isHandicapToError }}
      >
        {({ errorInputs, validateThenApply, validateValues }) => (
          <>
            {this.state.requestingRecipients && <ModalContentOverlayLoader/>}

            <Tabs
              onChange={this._handleSectionChange}
              value={slideIndex}
              indicatorColor={'primary'}

            >
              <Tab
                label={<FormattedMessage id={'scenes.offers.form.sections.content.title'} />}
                value={0}
              />
              <Tab
                label={<FormattedMessage id={'scenes.offers.form.sections.targeting.title'} />}
                value={1}
                style={{ minWidth: '0' }}
              />
              <Tab
                label={<FormattedMessage id={'scenes.offers.form.sections.preview.title'} />}
                value={2}
              />
            </Tabs>
            <SwipeableViews
              index={slideIndex}
              onChangeIndex={this._handleSectionChange}
            >
              <SwipeableContentContainer>
                <form noValidate={true} autoComplete="off">

                  <TextField
                    error={errorInputs.title}
                    required={true}
                    fullWidth={true}
                    id="title-input"
                    label={<FormattedMessage id={'scenes.messages.form.title'} />}
                    value={title}
                    margin="normal"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const { currentTarget: { value } } = e;
                      this.setState({
                        title: value,
                      }, validateValues);
                    }}
                  />

                  <MarkdownEditor
                    content={content}
                    required={true}
                    label={<FormattedMessage id={'scenes.messages.form.content'} />}
                    height={250}
                    onChangeCallback={(value: string) => {
                      this.setState({ content: value }, validateValues);
                    }}
                  />

                  <MessageImageUpload images={this.state.images} onImagesUpdated={images => this.setState({images})} />

                  <FormGroup row={true} style={{ marginTop: '1.8em' }}>
                    <FormControl style={{ marginRight: '1em' }}>
                      <InputLabel htmlFor="input-status" error={errorInputs.status} required={true}>
                        <FormattedMessage id={'scenes.messages.form.status.title'} />
                      </InputLabel>
                      <Select
                        error={errorInputs.status}
                        value={status}
                        onChange={(event) => {
                          this.setState({
                            status: event.target.value as MessageStatus
                          }, validateValues);
                        }}
                        inputProps={{
                          id: 'input-status'
                        }}
                      >
                        <MenuItem value={'DRAFT'}>
                          <FormattedMessage id={'scenes.messages.form.status.draft'} />
                        </MenuItem>
                        <MenuItem value={'PUBLISHED'}>
                          <FormattedMessage id={'scenes.messages.form.status.published'} />
                        </MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl style={{ alignContent: 'flex-start' }}>
                      <FormLabel>
                        <FormattedMessage id={'scenes.messages.form.publishDate.title'} />
                      </FormLabel>
                      <LocalizedDateTimePicker
                        value={(published != null
                          ? moment.utc(published as Date).local(false).toDate()
                          : null)}
                        disablePast={true}
                        onChange={(newDate: Date) => {
                          this.setState({
                            published: moment(newDate).utc(false).toDate()
                          });
                        }}
                        clearable={true}
                      />
                    </FormControl>
                  </FormGroup>

                  {renderFormActions({
                    id: messageId,
                    parentContext,
                    onClose: this._handleOnClose,
                    onUpdate: validateThenApply.bind(this, this._handleUpdateMessage),
                    onCreate: validateThenApply.bind(this, this._handleCreateMessage),
                    containerRef: actionsContainerRef,
                  })}

                </form>
              </SwipeableContentContainer>

              <SwipeableContentContainer>
                <TargetingForm
                  limitHandicapFrom={limitHandicapFrom}
                  limitHandicapTo={limitHandicapTo}
                  limitGender={limitGender}
                  senders={senders}
                  limitAgeFrom={limitAgeFrom}
                  limitAgeTo={limitAgeTo}
                  greenCardOnly={greenCardOnly}
                  functionaryOnly={functionaryOnly}
                  listedReceivers={listedReceivers}
                  receiversData={receiversData}
                  limitFunctionaryTitles={limitFunctionaryTitles}
                  limitReceivesClubLetter={limitReceivesClubLetter}
                  limitClubs={limitClubs}
                  limitRegions={limitRegions}
                  targetingUpdated={this.targetingUpdated}
                />
              </SwipeableContentContainer>

              <SwipeableContentContainer>
                <MessagePreview
                  title={title}
                  content={content}
                  images={this.state.images}
                  published={published ? published : undefined}
                />
                <hr/>
                <Typography
                    variant={'h6'}
                    gutterBottom={true}
                >
                  <FormattedMessage id={'scenes.messages.preview.recipients.title'} />
                </Typography>
                <Typography
                    variant={'body1'}
                    gutterBottom={true}
                >
                  <FormattedMessage id={'scenes.messages.preview.recipients.description'} />
                </Typography>
                <Button
                    variant="contained"
                    color="default"
                    onClick={this._handleRefreshRecipients}
                >
                  <FormattedMessage id={'scenes.messages.preview.recipients.button'} />
                </Button>
              </SwipeableContentContainer>
            </SwipeableViews>
          </>
        )}
      </InputsValidator>
    );
  }

  private _handleRefreshRecipients = () => {

    this.setState({ requestingRecipients: true });

    const { fetchAllRecipients } = this.props;
    const params = this._formMessageParams();

    fetchAllRecipients({
      params: params as MessageAdd,
      onComplete: (params: APICallResult) => {
        this.setState({ requestingRecipients: false });
      }
    });
  }

  private _handleOnClose = () => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  }

  private _handleCreateMessage = () => {
    const { addMessage } = this.props;
    const params = this._formMessageParams();

    addMessage({
      ...params,
      onComplete: this._handleOnComplete,
    });
  }

  private _handleUpdateMessage = () => {
    const { editMessage } = this.props;
    const {
      message: {
        message
      }
    } = this.props;

    if (!message) {
      return;
    }

    const params = this._formMessageParams();

    editMessage({
      id: message.id,
      ...params,
      onComplete: this._handleOnComplete,
    });
  }

  private _handleSectionChange = (event, value: number) => {
    this.setState({
      slideIndex: value,
    });
  }

  private _formMessageParams = () => {
    const {
      title,
      content,
      images,
      limitGender,
      senders,
      limitHandicapFrom,
      limitHandicapTo,
      limitAgeFrom,
      limitAgeTo,
      published,
      status,
      greenCardOnly,
      functionaryOnly,
      receiversData,
      receiversRequiresUpdate,
      limitFunctionaryTitles,
      limitClubs,
      limitReceivesClubLetter,
      limitRegions,
    } = this.state;

    /*
      Club parameter is used for caddies
     */
    const clubId = (senders as MessageSender).type === 'club' ? (senders as MessageSender).entityId : undefined;
    const federationId = (senders as MessageSender).type === 'federation' ?
        (senders as MessageSender).entityId : undefined;

    return {
      title,
      content,
      images: JSON.stringify(images),
      limitHandicapFrom: limitHandicapFrom.handicap || null,
      limitHandicapTo: limitHandicapTo.handicap || null,
      limitGender,
      limitAgeFrom,
      limitAgeTo,
      published,
      status,
      clubId: clubId,
      federationId: federationId,
      greenCardOnly,
      functionaryOnly,
      // Update receivers only if update flag is set
      receivers: receiversRequiresUpdate ? (receiversData || []) : undefined,
      limitFunctionaryTitles,
      limitClubs,
      limitReceivesClubLetter,
      limitRegions,
    };
  }

  private _handleOnComplete = (result: any) => {
    if (result.error) {
      window.alert(result.error.message);
    } else {

      // Check if server added all of the receivers
      const badReceivers = this._checkRecieversData(result);

      // If server did not add all, show error
      if (badReceivers.length > 0) {
        const intl = this.props.intl;

        let msg = intl.formatMessage({id: `scenes.messages.form.userTargeting.errorServerRejectInfo`});
        msg += `\n\n` + intl.formatMessage({id: `scenes.messages.form.userTargeting.errorServerRejectHeading`}) + `:\n`;
        msg += badReceivers.join(`, `);

        window.alert(msg); // Exec blocks here, until closed. Then _handleOnClose is called
      }

      this._handleOnClose();
    }
  }

  private _checkRecieversData(result: any) {
    const serverReceivers = result?.data?.receivers;
    const {receiversRequiresUpdate} = this.state;

    // Only validate data if server sent some and it is required
    if (!serverReceivers || !Array.isArray(serverReceivers) || !receiversRequiresUpdate) {
      return [];
    }

    // Check that receivers data is same as from server
    const receiversData = this.state.receiversData || [];

    // From receivers, filter those that server added
    // return receiversData.filter(a => serverReceivers.find(b => a === b));
    return receiversData.filter(sentReceiver => {
      const found = serverReceivers.find(e => sentReceiver === e);

      return found == null;
    });
  }
}

const MessageForm = injectIntl(connect((state: any) => ({
  message: state.messageReducer,
  auth: state.authReducer,
}), {
  addMessage: messageAction.addMessage,
  editMessage: messageAction.editMessage,
  fetchAllRecipients: messagesAction.fetchAllRecipients,
})(MessageFormComponent));

export {
  MessageForm
};
