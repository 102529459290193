import React, { Component, Fragment } from 'react';
import { MessagesScene } from '@src/components/scenes/messages/MessagesScene';
import { ActiveStateListener } from '@src/components/scenes/caddy/ActiveStateListener';
import { RouteComponentProps, withRouter } from 'react-router';
import * as membersActions from '@src/store/club/actions';
import * as offersActions from '@src/store/offers/actions';
import * as messagesActions from '@src/store/messages/actions';
import { connect } from 'react-redux';

interface Props extends RouteComponentProps<any> {
  resetMembers: () => any;
  resetOffers: () => any;
  resetMessages: () => any;
}

class CaddyMessagesConnected extends Component<Props, {}> {
  render() {
    const {
      match: {
        params: {
          clubId
        }
      },
      resetMembers,
      resetOffers,
      resetMessages,
    } = this.props;

    return (
      <Fragment>
        <ActiveStateListener
          resetAction={() => {
            resetMembers();
            resetOffers();
            resetMessages();
          }}
          clubId={parseInt(clubId, 10)}
        />
        <MessagesScene />
      </Fragment>
    );
  }
}

export const CaddyMessages = withRouter(connect(() => ({
}), {
  resetMembers: membersActions.setClubMembersInitialState,
  resetOffers: offersActions.setOffersInitialState,
  resetMessages: messagesActions.resetSearch,
})(CaddyMessagesConnected));