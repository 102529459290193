import React, { Component } from 'react';
import FederationBasicInfo from '@src/components/scenes/federation/FederationBasicInfo';

class AdminFederationBasicInfo extends Component {
  render() {
    return (
      <FederationBasicInfo />
    );
  }
}

export {
  AdminFederationBasicInfo,
};