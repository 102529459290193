import { userAuthToken } from '@src/store/auth/selectors';
import { select, call } from 'redux-saga/effects';
import { GetPersonHandicapInfoAction } from '@src/store/person/actions';
import { tokenAPIGet } from '@src/utils/storeUtils';
import { API_ROOT, APIRoute } from '@src/assets/config';
import { put } from '@redux-saga/core/effects';
import { HANDICAP_INFO_GET_ERROR, HANDICAP_INFO_GET_SUCCESS } from '@src/store/person/constants';

export function* fetchPersonHandicapInfoFlow({
  personId
}: GetPersonHandicapInfoAction) {
  try {
    const token = yield select(userAuthToken);
    const { handicapInfo } = yield call(() => tokenAPIGet(
      `${API_ROOT}${APIRoute.handicapInfo(personId)}`,
      token,
    ));

    yield put({ type: HANDICAP_INFO_GET_SUCCESS, handicapInfo });
  } catch (error) {
    yield put({ type: HANDICAP_INFO_GET_ERROR, error });
  }
}