import React from 'react';
import { ContentHeaderTitle } from '@src/components/layouts/ui';
import { isCourseDraft } from '@src/utils/courseUtils';
import { FormattedHTMLMessage, injectIntl, WrappedComponentProps } from 'react-intl';

const ExtendedTitle = injectIntl(({
  course,
  showDraftCreatedOn = false,
  intl,
}: { course: ClubCourse, showDraftCreatedOn?: boolean } & WrappedComponentProps) => {
  const { name, timeEffective, timeCreated } = course;

  if (isCourseDraft(course)) {
    if (showDraftCreatedOn) {
      return (
        <FormattedHTMLMessage
          id={'strings.draftCreatedOn'}
          values={{
            name,
            date: intl.formatDate(timeCreated, {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
              minute: 'numeric',
              hour: 'numeric',
            })
          }}
        />
      );
    } else {
      return (
        <FormattedHTMLMessage
          id={'strings.courseDraft'}
          values={{
            name,
          }}
        />
      );
    }
  } else if (timeEffective !== null) {
    return (
      <FormattedHTMLMessage
        id={'strings.courseEffective'}
        values={{
          name,
          date: intl.formatDate(timeEffective, {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            minute: 'numeric',
            hour: 'numeric',
          })
        }}
      />
    );
  } else {
    return (
      <span>
        {name}
      </span>
    );
  }
});

type Props = {
  course?: ClubCourse;
  showDraftCreatedOn?: boolean;
  children?: React.ReactNode;
};

function CourseTitle({
  course,
  showDraftCreatedOn = false,
  children,
}: Props) {
  if (!course) {
    return null;
  }

  return (
    <ContentHeaderTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
      <span>
        <ExtendedTitle course={course} showDraftCreatedOn={showDraftCreatedOn} />
      </span>
      {children && (
        <span>
          {children}
        </span>
      )}
    </ContentHeaderTitle>
  );
}

export default CourseTitle;