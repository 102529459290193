import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

type Props = {

};

class ClubBasicInfo extends PureComponent<Props> {
  render() {
    return (
      <div>
        <p>Basic info</p>
      </div>
    );
  }
}

export default connect((state: StoreState) => ({
}), {
})(ClubBasicInfo);