import React, { ReactNode, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import AcceptableScoreDetails from '@src/components/scenes/member/components/AcceptableScoreDetails';
import Button from '@material-ui/core/Button';

export interface ChildrenArgs {
  ref: React.RefObject<any>;
  showDetails(acceptableScore: AcceptableScore): void;
}

interface OwnProps {
  children(args: ChildrenArgs): ReactNode;
}

type Props = OwnProps;

function AcceptableScoreDetailsModal({ children }: Props, ref) {
  const [open, setOpen] = useState<boolean>(false);
  const [acceptableScore, setAcceptableScore] = useState<AcceptableScore | undefined>(undefined);
  const onClose = () => setOpen(false);

  return (
    <>
      <Dialog open={open} disableBackdropClick={true} maxWidth={false}>
        <DialogTitle>
          <FormattedMessage id={'strings.acceptableScoreAllDetails'} />
        </DialogTitle>
        {acceptableScore && (
          <AcceptableScoreDetails acceptableScore={acceptableScore}>
            {({ renderTable }) => (
              <DialogContent style={{ paddingTop: 16 }}>
                {renderTable()}
              </DialogContent>
            )}
          </AcceptableScoreDetails>
        )}
        <DialogActions>
          <Button variant="contained" onClick={onClose}>
            <FormattedMessage id={'buttons.close'}/>
          </Button>
        </DialogActions>
      </Dialog>
      {children({
        showDetails(acceptableScore): void {
          setAcceptableScore(acceptableScore);
          setOpen(true);
        },
        ref,
      })}
    </>
  );
}

export default React.forwardRef(AcceptableScoreDetailsModal);