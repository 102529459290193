import {
  PARTNER_ADD_REQUESTING,
  PARTNER_DELETE_REQUESTING,
  PARTNER_EDIT_REQUESTING,
  PARTNER_GET_REQUESTING,
} from './constants';

export const getPartner = ({
  id
}: PartnerGet) => {
  return {
    type: PARTNER_GET_REQUESTING,
    partnerId: id,
  };
};

export const addPartner = (params: PartnerAdd) => {
  return {
    type: PARTNER_ADD_REQUESTING,
    params
  };
};

export const editPartner = (params: PartnerEdit) => {
  return {
    type: PARTNER_EDIT_REQUESTING,
    partnerId: params.id,
    params
  };
};

export const deletePartner = ({
  id,
  onComplete,
}: PartnerDelete) => {
  return {
    type: PARTNER_DELETE_REQUESTING,
    partnerId: id,
    params: {
      onComplete,
    },
  };
};