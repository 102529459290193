import React, { PureComponent, ChangeEvent } from 'react';
import { TextField, FormControl, FormControlLabel, Checkbox, Typography, Dialog, Button, 
  DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { injectIntl, FormattedMessage, WrappedComponentProps } from 'react-intl';
import memoize from 'memoize-one';
import OfferImagesUpload from '@src/components/forms/OfferImagesUpload';
import { PartnerSelect } from '@src/components/forms/PartnerSelect';
import { DEFAULT_INPUT_LEFT_MARGIN } from '@src/assets/config';
import LocalizedDateTimePicker from '@src/components/forms/LocalizedDateTimePicker';
import { FederationRoleScopeGuard } from '@src/components/access-control/FederationRoleScopeGuard';
import Grid from '@material-ui/core/Grid';
import { ErrorInputs } from '@src/components/forms/InputsValidator';
import moment from 'moment';
import MarkdownEditor from '@src/components/forms/MarkdownEditor';

class DirectToLinkHandle {

  readonly promise = new Promise<boolean>((res, rej) => {
    this._resolver = res;
    this._rejector = rej;
  });
  private _resolver: (v: boolean) => void;
  private _rejector: (e: any) => void;

  resolve(v: boolean) {this._resolver(v); }
  reject(e?: any) {this._rejector(e); }
}
interface OwnProps {
  appLanguage: AppLanguage;
  title?: string;
  ingress?: string;
  content?: string;
  partner?: Partner;
  starts?: Date | null;
  ends?: Date | null;
  images?: OfferImages | undefined;
  buttonLink?: string;
  buttonTitle?: string;
  errorInputs?: ErrorInputs;

  offerId?: number;
  offer?: OfferState;

  contentUpdated?(values: Object): void;
}

interface State {
  isContentInfoExpanded: boolean;
  directToLink: boolean;
  directToLinkPrompt: boolean;
}

type Props = OwnProps & WrappedComponentProps;

class ContentFormComponent extends PureComponent<Props, State> {
  private directToLinkHandleRef: React.MutableRefObject<DirectToLinkHandle|null> 
    = React.createRef<DirectToLinkHandle>();

  private initDirectToLink = memoize((offerId?: number, offer?: OfferState) => {
    if (!offerId || !offer || offer.requesting) {
      return;
    }

    this.setState({directToLink: this._isDirectToLinkEnabled()});
  });

  constructor(props: Props) {
    super(props);

    this.state = {
      isContentInfoExpanded: false,
      directToLink: false,
      directToLinkPrompt: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    const {offerId, offer} = this.props;
    this.initDirectToLink(offerId, offer);
  }

  componentWillUnmount() {
    // When unmounting reject promise
    this.directToLinkHandleRef.current?.reject();
  }
  
  render() {
    const {
      intl,
      title,
      ingress,
      content,
      starts,
      ends,
      partner,
      images,
      buttonLink,
      buttonTitle,
      errorInputs = {},
    } = this.props;

    return (
      <>
        <Grid container={true} spacing={3}>
          <Grid xs={6} item={true}>
            <FormControl fullWidth={true} margin={'normal'}>
              <LocalizedDateTimePicker
                value={(starts != null ? moment.utc(starts).local(false).toDate() : null)}
                onChange={(newDate: Date) => {
                  this._updateTargeting({
                    starts: newDate != null ? moment(newDate).utc(false).toDate() : null
                  });
                }}
                emptyLabel={intl.formatMessage({ id: 'scenes.offers.form.sections.content.form.starts' })}
                clearable={true}
              />
            </FormControl>
          </Grid>

          <Grid xs={6} item={true}>
            <FormControl fullWidth={true} margin={'normal'}>
              <LocalizedDateTimePicker
                minDate={starts}
                value={(ends != null ? moment.utc(ends).local(false).toDate() : null)}
                onChange={(newDate: Date) => {
                  this._updateTargeting({
                    ends: newDate != null ? moment(newDate).utc(false).toDate() : null
                  });
                }}
                emptyLabel={intl.formatMessage({ id: 'scenes.offers.form.sections.content.form.ends' })}
                clearable={true}
              />
            </FormControl>
          </Grid>
        </Grid>

        <FederationRoleScopeGuard>
          <PartnerSelect
            margin={'normal'}
            selectedPartners={partner}
            multi={false}
            onChangeCallback={(value: any) => {
              this._updateTargeting({ partner: value });
            }}
          />
        </FederationRoleScopeGuard>

        <TextField
          required={true}
          margin={'normal'}
          name="title"
          value={title}
          label={<FormattedMessage id={'scenes.offers.form.sections.content.form.title'} />}
          fullWidth={true}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            this._handleTextInputChanged('title', e);
          }}
          error={errorInputs.title}
        />

        <TextField
          required={true}
          margin={'normal'}
          name="ingress"
          value={ingress}
          label={<FormattedMessage id={'scenes.offers.form.sections.content.form.ingress'} />}
          multiline={true}
          rows={4}
          fullWidth={true}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            this._handleTextInputChanged('ingress', e);
          }}
          error={errorInputs.ingress}
        />

        <div style={{display: 'flex'}}>
          <FormControlLabel
            control={<Checkbox checked={this.state.directToLink} onChange={this._handleDirectToLinkChecked}/>}
            label={<FormattedMessage id={'scenes.offers.form.sections.content.form.directToLink.title'} />}
          />

          <TextField
            fullWidth={true}
            variant={'outlined'}
            margin={'normal'}
            value={this.state.directToLink ? buttonLink : ''}
            label={<FormattedMessage id={'scenes.offers.form.sections.content.form.directToLink.linkFieldName'} />}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              this._handleTextInputChanged('buttonLink', e);
            }}
            disabled={!this.state.directToLink}
            style={{
              visibility: this.state.directToLink ? 'initial' : 'hidden', 
              flex: 1, 
              marginRight: '1em'
            }}
          />
        </div>

        <Typography variant="body1">
          <FormattedMessage id={'scenes.offers.form.sections.content.form.directToLink.info'} />
        </Typography>

        <Dialog
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          maxWidth="xs"
          fullWidth={true}
          aria-labelledby="confirmation-dialog-title"
          open={this.state.directToLinkPrompt}
        >
          <DialogTitle id="confirmation-dialog-title">
            <FormattedMessage id={'scenes.offers.form.sections.content.form.directToLink.deletionWarning.title'} />
          </DialogTitle>
          <DialogContent dividers={true}>
            <Typography>
              <FormattedMessage id={'scenes.offers.form.sections.content.form.directToLink.deletionWarning.warning1'}/>
            </Typography>
            <br/>
            <Typography style={{ fontWeight: 600 }}>
              <FormattedMessage id={'scenes.offers.form.sections.content.form.directToLink.deletionWarning.warning2'}/>
            </Typography>
          </DialogContent>
          <DialogActions style={{display: 'flex'}}>
            <Button 
              autoFocus={true}
              variant="contained"
              onClick={e => this.directToLinkHandleRef.current!.resolve(false)} 
            >
              <FormattedMessage id={'buttons.cancel'} />
            </Button>
            <div style={{flex: 1}}/>
            <Button onClick={e => this.directToLinkHandleRef.current!.resolve(true)} color="secondary" variant="contained">
              <FormattedMessage id={'buttons.delete'} />
            </Button>
          </DialogActions>
        </Dialog>

        {/** Other content fields are hidden when direct to link is enabled */}
        {!this.state.directToLink && <>

        {/** Small divider */}
        <div style={{borderBottom: '1px solid #aaa', margin: '.5em 0'}}/>
        
        {/** Normal editor elements */}
        <MarkdownEditor
          content={content}
          required={false}
          label={<FormattedMessage id={'scenes.offers.form.sections.content.form.content'} />}
          height={250}
          onChangeCallback={(value: string) => {
            this._updateTargeting({ content: value });
          }}
        />

        <OfferImagesUpload
          style={{marginBottom: '3em'}}
          images={images}
          onChange={(changedImages: OfferImages | undefined) => {
            this._handleImagesChanged(changedImages);
          }}
        />

        <Grid container={true} spacing={3}>
          <Grid xs={6} item={true}>
            <TextField
              fullWidth={true}
              margin={'normal'}
              value={buttonTitle}
              style={{ marginRight: DEFAULT_INPUT_LEFT_MARGIN }}
              name="button-title"
              label={<FormattedMessage id={'scenes.offers.form.sections.content.form.btnTextTitleLabel'} />}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                this._handleTextInputChanged('buttonTitle', e);
              }}
            />
          </Grid>

          <Grid xs={6} item={true}>
            <TextField
              fullWidth={true}
              margin={'normal'}
              value={buttonLink}
              name="button-link"
              label={<FormattedMessage id={'scenes.offers.form.sections.content.form.btnLinkTitleLabel'} />}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                this._handleTextInputChanged('buttonLink', e);
              }}
            />
          </Grid>
        </Grid>
        
        </>}
      </>
    );
  }

  private _isDirectToLinkEnabled = () => {
    const {content, images, buttonTitle} = this.props;
    const hasContent = !!content || 
      ( !!images && images.length > 0 ) || 
      !!buttonTitle;

    return !hasContent;
  }

  private _handleDirectToLinkChecked = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;

    // User is unchecking, re-eanble editing
    if (!checked) {
      this.setState({directToLink: false});

      // TODO reenable editing
      return;
    }

    // User is checking the field.
    // Prompt for content deletion.

    const hasContent = !this._isDirectToLinkEnabled();

    // If there is content it needs to be removed.. so prompt user if this is ok
    if (hasContent) {
      this.directToLinkHandleRef.current = new DirectToLinkHandle();
      this.setState({directToLinkPrompt: true});

      try {
        const result = await this.directToLinkHandleRef.current!.promise;
        this.directToLinkHandleRef.current = null;
        this.setState({directToLinkPrompt: false});
        
        // Check if user clicked 'cancel'
        if (result === false) {
          this.setState({directToLink: false});
          return;
        }

        // User has consented to data deletion
        this._updateTargeting({
          content: '',
          images: [],
          buttonTitle: '',
        });
      } catch {
        return; // Promise rejects only when component unmounts, just return
      }
    }

    // Set new state
    this.setState({directToLink: true});

    // TODO Disable content, image, buttonTitle fields
  }

  private _handleTextInputChanged = (propType: string, e: ChangeEvent<HTMLInputElement>): void => {
    const {
      currentTarget: {
        value
      }
    } = e;
    this._updateTargeting({ [propType]: value });
  }

  private _updateTargeting = (values: any) => {
    if (this.props.contentUpdated) {
      this.props.contentUpdated(values);
    }
  }

  private _handleImagesChanged = (images: OfferImages | undefined) => {
    this._updateTargeting({ 'images': images });
  }
}

export const ContentForm = injectIntl(ContentFormComponent);
