import { all, fork, take, takeEvery } from 'redux-saga/effects';
import { editScoreFlow } from '@src/store/acceptableScore/flows/edit-score-flow';
import { editAdjustmentFlow } from '@src/store/acceptableScore/flows/edit-adjustment-flow';
import {
  ADD_ACCEPTABLE_SCORE_REQUESTING,
  ADD_ACCEPTABLE_SCORE_AWAY_REQUESTING,
  EDIT_ACCEPTABLE_SCORE_ADJUSTMENT_REQUESTING,
  EDIT_ACCEPTABLE_SCORE_HOLE_SCORE_REQUESTING,
  EDIT_ACCEPTABLE_SCORE_STATUS_REQUESTING,
  EDIT_ACCEPTABLE_SCORE_PERSON_REQUESTING,
} from '@src/store/acceptableScore/constants';
import { editScoreStatusFlow } from './flows/edit-score-status-flow';
import { editScorePersonFlow } from './flows/edit-score-person-flow';
import { AUTH_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { ACCEPTABLE_SCORES_REFETCH } from '@src/store/acceptableScores/constants';

const acceptableScoreCRUDFlow = () => {
  return SagaFlowFactory.createCRUDFlow({
    apiRoute: `admin/1/acceptableScore`,
    typePrefix: 'ACCEPTABLE_SCORE',
    resetType: ACCEPTABLE_SCORES_REFETCH,
  });
};

const acceptableScoreAwayCRUDFlow = () => {
  return SagaFlowFactory.createCRUDFlow({
    apiRoute: `admin/1/acceptableScore/away`,
    typePrefix: 'ACCEPTABLE_SCORE_AWAY',
    resetType: ACCEPTABLE_SCORES_REFETCH,
  });
};

function * acceptableScoreWatcher() {
  let token: string = '';

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {

    const {
      type,
      params,
    } = yield take([
      ADD_ACCEPTABLE_SCORE_REQUESTING,
      ADD_ACCEPTABLE_SCORE_AWAY_REQUESTING
    ]);

    switch (type) {
      case ADD_ACCEPTABLE_SCORE_REQUESTING:
        yield fork(acceptableScoreCRUDFlow(), token, 'ADD', null, { ...params, __json: true });
        break;

      case ADD_ACCEPTABLE_SCORE_AWAY_REQUESTING:
        yield fork(acceptableScoreAwayCRUDFlow(), token, 'ADD', null, { ...params, __json: true });
        break;

      default:
        break;
    }
  }
}

export function* acceptableScoreSpawnWatcher() {
  yield all([
    takeEvery(EDIT_ACCEPTABLE_SCORE_ADJUSTMENT_REQUESTING, editAdjustmentFlow),
    takeEvery(EDIT_ACCEPTABLE_SCORE_HOLE_SCORE_REQUESTING, editScoreFlow),
    takeEvery(EDIT_ACCEPTABLE_SCORE_STATUS_REQUESTING, editScoreStatusFlow),
    takeEvery(EDIT_ACCEPTABLE_SCORE_PERSON_REQUESTING, editScorePersonFlow),
  ]);
}

export default acceptableScoreWatcher;
