import React from 'react';

export type ErrorInputs = {[inputName: string]: boolean};
export type InputValue = any;

type ChildrenArgs = {
  hasErrors: () => boolean;
  errorInputs: ErrorInputs;
  validateThenApply: (fn: () => any) => any;
  validateValues: () => any;
};

interface Props {
  values: {[inputName: string]: InputValue};
  options?: {[inputName: string]: any};
  children: (args: ChildrenArgs) => any;
}

type State = {
  errorInputs: ErrorInputs;
  onClickValidPassed: boolean;
};

class InputsValidator extends React.Component<Props, State> {
  state = {
    errorInputs: {},
    onClickValidPassed: false,
  };

  render() {
    return this.props.children({
      hasErrors: this._hasErrors,
      errorInputs: this.state.errorInputs,
      validateThenApply: this._validateThenApply,
      validateValues: this._validateValues,
    });
  }

  private _validateThenApply = (fn: () => any) => {
    this.setState({ onClickValidPassed: true }, this._validateValues.bind(this, () => {
      if (this._hasErrors()) {
        window.console.warn('invalid inputs');
        return window.console.table(this.state.errorInputs);
      }

      return fn();
    }));
  }

  private _validateValues = (fn?: () => any) => {
    const { onClickValidPassed } = this.state;
    const { values } = this.props;
    let errorInputs: ErrorInputs = {};

    if (onClickValidPassed) {
      Object.keys(values).forEach((valueKey: string) => {
        if (values[valueKey] === undefined || values[valueKey] === '' || values[valueKey] === null) {
          errorInputs[valueKey] = true;
        }
      });
    }

    this.setState({ errorInputs }, fn);
  }

  private _hasErrors = (): boolean => {
    return Boolean(Object.keys(this.state.errorInputs).length);
  }
}

export default InputsValidator;