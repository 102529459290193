import {
  MEMBERSHIP_ADD_ERROR,
  MEMBERSHIP_ADD_REQUESTING,
  MEMBERSHIP_ADD_SUCCESS, RESET_MEMBERSHIP_STATE,
  MEMBERSHIP_DELETE_ERROR,
  MEMBERSHIP_DELETE_REQUESTING,
  MEMBERSHIP_DELETE_SUCCESS,
  MEMBERSHIP_EDIT_ERROR,
  MEMBERSHIP_EDIT_POST_ERROR,
  MEMBERSHIP_EDIT_POST_SUCCESS,
  MEMBERSHIP_EDIT_REQUESTING,
  MEMBERSHIP_EDIT_SUCCESS,
  MEMBERSHIP_GET_ERROR,
  MEMBERSHIP_GET_REQUESTING,
  MEMBERSHIP_GET_SUCCESS,
  MEMBERSHIP_HANDICAP_ROUNDS_ERROR,
  MEMBERSHIP_HANDICAP_ROUNDS_REQUESTING,
  MEMBERSHIP_HANDICAP_ROUNDS_SUCCESS,
  MEMBERSHIP_INACTIVATE_ERROR,
  MEMBERSHIP_INACTIVATE_REQUESTING,
  MEMBERSHIP_INACTIVATE_SUCCESS
} from './constants';
import { formDefaultErrorState, formDefaultRequestingState } from '@src/utils/storeUtils';

const initialState: MembershipState = {
  membership: undefined,
  handicapRounds: [],
  requesting: false,
  successful: false,
  error: [],
};

export const membershipReducer = (state: MembershipState = initialState, action: any): MembershipState => {
  switch (action.type) {
    case MEMBERSHIP_GET_REQUESTING:
      return {
        ...formDefaultRequestingState(state),
        membership: undefined,
      };

    case MEMBERSHIP_HANDICAP_ROUNDS_REQUESTING:
      return {
        ...formDefaultRequestingState(state),
        handicapRounds: [],
      };

    case MEMBERSHIP_ADD_REQUESTING:
    case MEMBERSHIP_DELETE_REQUESTING:
    case MEMBERSHIP_EDIT_REQUESTING:
    case MEMBERSHIP_INACTIVATE_REQUESTING:
      return formDefaultRequestingState(state);

    case MEMBERSHIP_HANDICAP_ROUNDS_ERROR:
    case MEMBERSHIP_GET_ERROR:
    case MEMBERSHIP_ADD_ERROR:
    case MEMBERSHIP_DELETE_ERROR:
    case MEMBERSHIP_EDIT_ERROR:
    case MEMBERSHIP_EDIT_POST_ERROR:
    case MEMBERSHIP_INACTIVATE_ERROR:
      return formDefaultErrorState(state, action);

    case MEMBERSHIP_GET_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
        membership: action.data,
      };

    case MEMBERSHIP_ADD_SUCCESS:
    case MEMBERSHIP_DELETE_SUCCESS:
    case MEMBERSHIP_INACTIVATE_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
      };

    case MEMBERSHIP_EDIT_POST_SUCCESS:
    case MEMBERSHIP_EDIT_SUCCESS:
      const { data } = action;
      return {
        ...state,
        requesting: false,
        successful: true,
        membership: data,
      };

    case MEMBERSHIP_HANDICAP_ROUNDS_SUCCESS:
      return {
        ...state,
        handicapRounds: action.data,
        requesting: false,
      };

    case RESET_MEMBERSHIP_STATE: {
      return {
        ...state,
        membership: undefined,
      };
    }

    default:
      return state;
  }
};