import React, { ChangeEvent, Fragment, ReactNode, RefObject } from 'react';
import {
  TextField,
} from '@material-ui/core';
import { connect } from 'react-redux';
import * as userActions from '@src/store/user/actions';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ClubSelect } from '@src/components/forms/ClubSelect';
import { PartnerSelect } from '@src/components/forms/PartnerSelect';
import { renderFormActions } from '@src/components/modals/ui';
import InputsValidator from '@src/components/forms/InputsValidator';
import UserRoleAndScopePicker from '@src/components/access-control/UserRoleAndScopePicker';
import { FederationRoleScopeGuard } from '@src/components/access-control/FederationRoleScopeGuard';
import { isClubScope } from '@src/components/access-control';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const SettingsFormWrap = styled.div`
  width: 100%;
  max-width: 54em;
  margin: 0 auto;
`;

interface Props {
  userId?: number | undefined;
  parentContext?: 'MODAL' | 'PAGE';
  clubs?: Array<Club>;
  partners?: Array<Partner>;
  user: UserState;
  addUser: (params: UserAdd) => any;
  editUser: (params: UserEdit) => any;
  onClose?: () => void;
  auth: AuthState;
  actionsContainerRef?: RefObject<HTMLElement>;
}

interface State {
  name: string;
  email: string;
  password?: string;
  passwordRetyped?: string;
  clubs: Array<Club>;
  partners: Array<Partner>;
  passwordRetypedInvalid: boolean;
  roleId?: number;
  sendActivationEmail: boolean;
}

const initialProps: State = {
  name: '',
  email: '',
  password: '',
  passwordRetyped: '',
  roleId: undefined,
  clubs: [],
  partners: [],
  passwordRetypedInvalid: false,
  sendActivationEmail: false,
};

class UserSettingsFormConnected extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    const {
      userId,
      user: {
        user
      },
    } = props;

    if (!userId) {
      this.state = this._formatInitialState();
    } else if (user) {
      this.state = {
        ...user as any,
        roleId: this._parseRoleId(user),
        password: '',
        passwordRetyped: '',
      };
    } else {
      this.state = this._formatInitialState();
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    const {
      userId,
      user: {
        user,
        requesting,
      },
    } = nextProps;

    if (!userId) {
      return; // Do nothing if we are adding new person
    } else if (requesting) {
      return; // Do nothing state is loading
    } else if (user) {
      this.setState({
        ...user as any,
        roleId: this._parseRoleId(user),
        password: '',
        passwordRetyped: '',
      });
    }
  }

  render() {
    const {
      userId,
      parentContext,
      actionsContainerRef,
    } = this.props;

    const {
      name,
      email,
      clubs,
      partners,
      roleId,
      sendActivationEmail,
    } = this.state;

    return (
      <InputsValidator values={{ name, email, roleId }}>
        {({ errorInputs, validateThenApply, validateValues }) => (
        <SettingsFormWrap>
          <TextField
            required={true}
            fullWidth={true}
            value={name}
            margin="normal"
            label={<FormattedMessage id={'scenes.users.form.nameInput'}/>}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const {
                currentTarget: {
                  value,
                }
              } = e;
              this.setState({
                name: value,
              }, validateValues);
            }}
            error={errorInputs.name}
          /><br />
          <TextField
            required={true}
            fullWidth={true}
            value={email}
            margin="normal"
            disabled={true}
            label={<FormattedMessage id={'scenes.users.form.emailInput'}/>}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const {
                currentTarget: {
                  value,
                }
              } = e;
              this.setState({
                email: value,
              }, validateValues);
            }}
            error={errorInputs.email}
          />

          {this._renderSettingsFields()}

          <FederationRoleScopeGuard>
            <>
              <ClubSelect
                margin={'normal'}
                multi={true}
                selectedClubs={clubs}
                onChangeCallback={(values: any) => {
                  this.setState({clubs: values});
                }}
              />

              <PartnerSelect
                margin={'normal'}
                multi={true}
                selectedPartners={partners}
                onChangeCallback={(values: any) => {
                  this.setState({partners: values});
                }}
              />
            </>
          </FederationRoleScopeGuard>

          <UserRoleAndScopePicker
            selectedRoleId={roleId}
            required={true}
            fullWidth={true}
            error={errorInputs.roleId}
            disabled={true}
            onChange={(newRoleId?: number) => (this.setState({ roleId: newRoleId }))}
          />

          <FormControlLabel
            label={(<FormattedMessage id={'buttons.deliveryActivationEmail'}/>)}
            control={
              <Switch
                checked={sendActivationEmail}
                color={'primary'}
                onChange={(event) => {
                  this.setState({sendActivationEmail: event.target.checked});
                }}
              />
            }
          />

          {renderFormActions({
            id: userId,
            parentContext,
            onClose: this._handleOnClose,
            onUpdate: validateThenApply.bind(this, this._handleUpdateUser),
            onCreate: validateThenApply.bind(this, this._handleCreateUser),
            containerRef: actionsContainerRef,
          })}
        </SettingsFormWrap>
        )}
      </InputsValidator>
    );
  }

  private _handleOnClose = () => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  }

  private _handleCreateUser = () => {
    const { addUser } = this.props;
    const params = this._formUserParams();
    const { name, email, clubIds, partnerIds, roleId, sendActivationEmail } = params;

    if (!roleId) {
      return false;
    }

    return addUser({
      name,
      email,
      clubIds,
      partnerIds,
      roleId,
      sendActivationEmail,
      onComplete: this._handleOnComplete,
    });
  }

  private _handleUpdateUser = () => {
    const { editUser } = this.props;
    const params = this._formUserParams();
    const {
      user: {
        user
      }
    } = this.props;

    if (!user) {
      return;
    }

    const { name, email, clubIds, partnerIds, roleId, password, passwordRetyped, sendActivationEmail } = params;

    if (!roleId) {
      return false;
    }

    if (this.props.auth.id !== this.props.userId) {
      return editUser({
        id: user.id,
        name,
        email,
        clubIds,
        partnerIds,
        roleId,
        sendActivationEmail,
        onComplete: this._handleOnComplete,
      });
    } else {

      if (password !== passwordRetyped) {
        this.setState({
          passwordRetypedInvalid: true,
        });

        return;

      }

      return editUser({
        id: user.id,
        name,
        email,
        clubIds,
        partnerIds,
        password,
        passwordRetyped,
        roleId,
        sendActivationEmail,
        onComplete: this._handleOnComplete,
      });
    }
  }

  private _formUserParams = () => {
    const { name, email, password, passwordRetyped, clubs, partners, roleId, sendActivationEmail } = this.state;

    return {
      name,
      email,
      password,
      passwordRetyped,
      clubIds: clubs.map(club => club.id),
      partnerIds: partners.map(partner => partner.id),
      roleId,
      sendActivationEmail
    };
  }

  private _handleOnComplete = (result: any) => {
    if (result.error) {
      window.alert(result.error.message);
    } else {
      this._handleOnClose();
    }
  }

  private _renderSettingsFields = (): ReactNode => {
    const {
      password,
      passwordRetyped,
      passwordRetypedInvalid,
    } = this.state;

    if (this.props.auth.id !== this.props.userId) {
      return null;
    }

    return (
      <Fragment>
        <TextField
          fullWidth={true}
          label={<FormattedMessage id={'login.resetPassword.passwordFieldLabel'} />}
          value={password}
          margin="normal"
          type={'password'}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const {
              currentTarget: {
                value,
              }
            } = e;
            this.setState({
              password: value,
            });
          }}
        />

        <TextField
          fullWidth={true}
          label={<FormattedMessage id={'login.resetPassword.passwordFieldLabelConfirm'} />}
          value={passwordRetyped}
          margin="normal"
          type={'password'}
          error={passwordRetypedInvalid}
          helperText={passwordRetypedInvalid ? <FormattedMessage id={'login.resetPassword.inputMismatch'} /> : ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const {
              currentTarget: {
                value,
              }
            } = e;
            this.setState({
              passwordRetyped: value,
            });
          }}
        />
      </Fragment>
    );
  }

  private _parseRoleId = (user: User): undefined | number => {
    if (!user || !user.roleInfo) {
      return undefined;
    }

    return user.roleInfo.idRole;
  }

  private _formatInitialState = (): State => {
    const { auth: { roleInfo, activeState } } = this.props;

    const clubs: any[] = [];
    if (isClubScope(roleInfo) && activeState) {
      const { club } = activeState;
      if (club) {
        clubs.push(club);
      }
    }

    return {
      ...initialProps,
      clubs,
    };
  }
}

const UserSettingsForm = connect((state: any) => ({
  locale: state.locale,
  user: state.userReducer,
  auth: state.authReducer,
}), {
  addUser: userActions.addUser,
  editUser: userActions.editUser
})(UserSettingsFormConnected);

export {
  UserSettingsForm
};