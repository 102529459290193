import React, { ChangeEvent, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControl,
  InputLabel,
  MenuItem,
  Select, TextField
} from '@material-ui/core';
import {
  editPersonHandicapOverride,
  EditPersonHandicapOverridePayload
} from '@src/store/person/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { pickBy } from 'lodash';
import { DEFAULT_INPUT_LEFT_MARGIN } from '@src/assets/config';
import LocalizedDateTimePicker from '@src/components/forms/LocalizedDateTimePicker';

const initialOverride: HandicapOverride = {
  type: 'FROZEN',
  timeStart: new Date().toISOString(),
  timeEnd: new Date().toISOString(),
  handicapIndex: undefined,
  handicapIndexLow: undefined,
};

interface Children {
  ref: React.RefObject<any>;
  editOverride(
    personId: number,
    args: HandicapOverride,
    argHandicapIndex?: number | null,
    argHandicapIndexLow?: number | null,
  ): void;
}

interface OwnProps {
  children(args: Children): React.ReactNode;
}

interface DispatchProps {
  editPersonHandicapOverride(args: EditPersonHandicapOverridePayload): void;
}

type Props = OwnProps & DispatchProps;

function EditHandicapOverride(props: Props, ref) {
  const { children, editPersonHandicapOverride } = props;
  const { formatMessage: f } = useIntl();
  const [open, setOpen] = useState<boolean>(false);
  const [personId, setPersonId] = useState<number | undefined>(undefined);
  const [override, setOverride] = useState<HandicapOverride>(initialOverride);

  const onClose = () => setOpen(false);

  const onHandleEdit = () => {
    if (personId) {
      editPersonHandicapOverride({ personId, override });
    }
    onClose();
  };

  const onOverrideInputChange = (name: string) => ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setOverride({ ...override, [name]: value });
  };

  const isWithdrawn = override.type === 'WITHDRAWN';

  return (
    <>
      <Dialog open={open} disableBackdropClick={true} maxWidth={'xs'} fullWidth={true}>
        <DialogTitle>
          {f({ id: 'strings.handicapOverride' })}
        </DialogTitle>
        <DialogContent>
          <FormControl margin={'normal'} fullWidth={true}>
            <InputLabel>
              {f({ id: 'strings.override' })}
            </InputLabel>
            <Select
              value={override.type}
              onChange={({ target: { value } }: React.ChangeEvent<{ name?: string; value: string }>) => {
                setOverride({ ...override, type: value });
              }}
            >
              {['FROZEN', 'FROZENUP', 'WITHDRAWN'].map(value => (
                <MenuItem value={value} key={value}>
                  {f({ id: `strings.handicap${value}` })}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {['timeStart', 'timeEnd'].map(value => (
            <FormControl margin={'normal'} fullWidth={true} key={value}>
              <LocalizedDateTimePicker
                label={f({ id: `strings.${value}` })}
                value={override[value]}
                minDate={value === 'timeStart' ? undefined : override.timeStart}
                onChange={(newDate: Date) => {
                  setOverride({ ...override, [value]: newDate.toISOString() });
                }}
              />
            </FormControl>
          ))}
          <TextField
            disabled={isWithdrawn}
            value={isWithdrawn ? '' : (override?.handicapIndex || '')}
            margin={'normal'}
            fullWidth={true}
            label={f({ id: 'strings.hcp' })}
            onChange={onOverrideInputChange('handicapIndex')}
          />
          <TextField
            disabled={isWithdrawn}
            value={isWithdrawn ? '' : (override?.handicapIndexLow || '')}
            margin={'normal'}
            fullWidth={true}
            label={f({ id: 'strings.handicapIndexLow' })}
            onChange={onOverrideInputChange('handicapIndexLow')}
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-end' }}>
          <Button onClick={onClose} variant={'contained'}>
            <FormattedMessage id={'buttons.close'} />
          </Button>
          <Button
            onClick={onHandleEdit}
            variant={'contained'}
            color={'primary'}
            style={{ marginLeft: DEFAULT_INPUT_LEFT_MARGIN }}
          >
            <FormattedMessage id={'buttons.update'} />
          </Button>
        </DialogActions>
      </Dialog>
      {children({
        ref,
        editOverride(argPersonId, argOverride): void {
          setPersonId(argPersonId);
          setOverride(Object.assign({}, initialOverride, pickBy(argOverride)));
          setOpen(true);
        },
      })}
    </>
  );
}

export default connect<{}, DispatchProps, OwnProps, StoreState>(null, {
  editPersonHandicapOverride,
})(React.forwardRef(EditHandicapOverride));
