import { take, fork, select } from 'redux-saga/effects';
import {
  USERS_REQUESTING,
  USERS_SUCCESS,
  USERS_ERROR,
  USERS_SEARCH_REQUESTING,
  USERS_SEARCH_RESET,
  USERS_CONDENSED_SUCCESS,
} from './constants';
import { API_ROOT, APIRoute, DEFAULT_PAGINATION_LIMIT, DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import { AUTH_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { LOGIN_SUCCESS } from '@src/store/login/constants';
import { normalizeSearchParams } from '@src/utils/storeUtils';
import { getActiveStateTargeting } from '@src/store/auth/selectors';

/*
  GET /admin/1/users
 */
const fetchUsersFlow = SagaFlowFactory.createPagedGETFlow({
  apiRoute: APIRoute.GET_USERS,
  itemType: 'users',
  successType: USERS_SUCCESS,
  errorType: USERS_ERROR
});

/*
  GET /admin/1/users/search
 */
const fetchUsersSearchFlow = SagaFlowFactory.createPagedGETFlow({
  apiRoute: APIRoute.SEARCH_USERS,
  itemType: 'users',
  successType: USERS_SUCCESS,
  errorType: USERS_ERROR
});

/*
  GET /admin/1/users?type=condensed
 */
const fetchUsersCondensed = SagaFlowFactory.createGETFlow({
  urlRoute: `${API_ROOT}${APIRoute.GET_USERS}`,
  successType: USERS_CONDENSED_SUCCESS,
  errorType: USERS_ERROR,
});

function * usersWatcher() {
  let token: string = '';
  let isAdmin: boolean = false;

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
    isAdmin = user.role === 'admin';
  });

  while (true) {
    const {
      type,
      page,
      limit,
      params
    } = yield take([
      USERS_REQUESTING,
      USERS_SEARCH_REQUESTING,
      USERS_SEARCH_RESET,
      LOGIN_SUCCESS,
    ]);

    const targetParams = yield select(getActiveStateTargeting);

    switch (type) {
      case LOGIN_SUCCESS:
        if (isAdmin) {
          yield fork(fetchUsersCondensed, token, 0, 0, { type: 'condensed', ...targetParams });
        }
        break;
      case USERS_REQUESTING:
        yield fork(fetchUsersFlow, token, page, limit, { ...targetParams, ...params } );
        break;
      case USERS_SEARCH_REQUESTING:
        yield fork(
          fetchUsersSearchFlow,
          token,
          page,
          limit,
          { ...normalizeSearchParams(params), ...targetParams }
        );
        break;
      case USERS_SEARCH_RESET:
        yield fork(fetchUsersFlow, token, DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_LIMIT, targetParams);
        break;
      default:
    }
  }
}

export default usersWatcher;