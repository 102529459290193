import React, { PureComponent, ChangeEvent, RefObject } from 'react';
import { connect } from 'react-redux';
import * as clubGreenCardAction from '@src/store/clubGreencard/actions';
import {
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { injectIntl, FormattedMessage, WrappedComponentProps } from 'react-intl';
import { renderFormActions } from '@src/components/modals/ui';
import { ClubFunctionarySelect } from '@src/components/forms/ClubFunctionarySelect';
import { RouteComponentProps, withRouter } from 'react-router';
import LocalizedDatePicker from '@src/components/forms/LocalizedDatePicker';
import InputsValidator from '@src/components/forms/InputsValidator';
import AddressFields from '@src/components/forms/ui/AddressFields';

interface OwnProps {
  greenCardId?: number | undefined;
  clubGreenCardState: ClubGreenCardState;
  parentContext?: 'MODAL' | 'PAGE';
  onClose?: () => void;
  addClubGreenCard: (params: ClubGreenCardAdd) => any;
  editClubGreenCard: (params: ClubGreenCardEdit) => any;
  auth: AuthState;
  actionsContainerRef?: RefObject<HTMLElement>;
}

type State = {
  status: GreenCardActivityStatus;
  timeRegistration: Date | null;
  streetAddress: string;
  city: string;
  zip: string;
  email: string;
  phone: string;
  nameFirst: string;
  nameMiddle: string | null;
  nameLast: string;
  birthDate: Date | null;
  gender: Gender;
  proPersonInfo: Functionary | undefined;
  emailAdvertisingAllowed: boolean;
  smsAdvertisingAllowed: boolean;
  postAdvertisingAllowed: boolean;
};

const initialState = {
  status: 'PENDING',
  timeRegistration: null,
  streetAddress: '',
  city: '',
  zip: '',
  email: '',
  phone: '',
  gender: '',
  nameFirst: '',
  nameMiddle: null,
  nameLast: '',
  birthDate: null,
  proPersonInfo: undefined,
  emailAdvertisingAllowed: true,
  smsAdvertisingAllowed: true,
  postAdvertisingAllowed: true
};

type Props = OwnProps & WrappedComponentProps & RouteComponentProps<any>;

class GreenCardFormConnected extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const {
      greenCardId,
      clubGreenCardState: {
        clubGreenCard,
      }
    } = this.props;

    if (!greenCardId) {
      this.state = initialState;
    } else if (clubGreenCard) {
      this.state = {
        ...clubGreenCard
      } as any;

    } else {
      this.state = initialState;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      greenCardId,
      clubGreenCardState: {
        clubGreenCard,
        requesting,
      },
    } = nextProps;

    if (!greenCardId) {
      return; // Do nothing if we are adding new person
    } else if (requesting) {
      return; // Do nothing state is loading
    } else if (clubGreenCard) {
      this.setState({
        ...clubGreenCard as any,
        emailAdvertisingAllowed: clubGreenCard.contact && clubGreenCard.contact.indexOf('EMAIL') !== -1,
        smsAdvertisingAllowed: clubGreenCard.contact && clubGreenCard.contact.indexOf('PHONE') !== -1,
        postAdvertisingAllowed: clubGreenCard.contact && clubGreenCard.contact.indexOf('POST') !== -1
      });
    }
  }

  render() {
    const {
      greenCardId,
      intl,
      parentContext,
      match: {
        params
      },
      actionsContainerRef,
    } = this.props;
    const {
      timeRegistration,
      streetAddress,
      city,
      zip,
      email,
      phone,
      gender,
      nameFirst,
      nameLast,
      nameMiddle,
      birthDate,
      proPersonInfo,
      status,
      emailAdvertisingAllowed,
      smsAdvertisingAllowed,
      postAdvertisingAllowed
    } = this.state;

    const { clubId } = params;

    return (
      <InputsValidator
        values={{ nameFirst, nameLast, status, timeRegistration, proPersonInfo, zip, email, phone, gender }}
      >
        {({ errorInputs, validateThenApply, validateValues }) => (
          <>
            <FormControl required={true} error={errorInputs.status} margin={'normal'}>
              <InputLabel htmlFor="greencard-status">
                <FormattedMessage id={'strings.state'} />
              </InputLabel>
              <Select
                value={status}
                onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                  this.setState({status: event.target.value as GreenCardActivityStatus}, validateValues);
                }}
                style={{ marginRight: '1em' }}
                inputProps={{
                  name: 'status',
                  id: 'greencard-status',
                }}
              >
                <MenuItem value={'PENDING'}>
                  <FormattedMessage id={'scenes.golfClub.greenCard.search.stateOptions.pending'} />
                </MenuItem>
                <MenuItem value={'PASSED'}>
                  <FormattedMessage id={'scenes.golfClub.greenCard.search.stateOptions.approved'} />
                </MenuItem>
                <MenuItem value={'CANCELLED'}>
                  <FormattedMessage id={'scenes.golfClub.greenCard.search.stateOptions.canceled'} />
                </MenuItem>
                <MenuItem value={'FAILED'}>
                  <FormattedMessage id={'scenes.golfClub.greenCard.search.stateOptions.rejected'} />
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl margin={'normal'}>
              <LocalizedDatePicker
                error={errorInputs.timeRegistration}
                required={true}
                onChange={(date: Date) => {
                  this.setState({
                    timeRegistration: new Date(date)
                  }, validateValues);
                }}
                label={intl.formatMessage({ id: 'scenes.golfClub.greenCard.form.registeredDate' })}
                value={timeRegistration}
                emptyLabel={intl.formatMessage({ id: 'scenes.golfClub.greenCard.form.registeredDate' })}
                clearable={true}
              />
            </FormControl>
            <ClubFunctionarySelect
              error={errorInputs.proPersonInfo}
              margin="normal"
              clubId={clubId}
              selectedFunctionaries={proPersonInfo}
              onChangeCallback={(functionary: Functionary) => {
                this.setState({proPersonInfo: functionary}, validateValues);
              }}
            />
            <FormControl fullWidth={true} required={true} margin="normal" error={errorInputs.gender}>
              <InputLabel htmlFor="gender">
                <FormattedMessage id={'strings.gender'}/>
              </InputLabel>
              <Select
                error={errorInputs.gender}
                inputProps={{
                  name: 'gender',
                  id: 'gender',
                }}
                value={gender}
                onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                  this.setState({gender: event.target.value as Gender}, validateValues);
                }}
              >
                <MenuItem value={'MALE'}><FormattedMessage id={'strings.male'}/></MenuItem>
                <MenuItem value={'FEMALE'}><FormattedMessage id={'strings.female'}/></MenuItem>
              </Select>
            </FormControl>
            <TextField
              required={true}
              fullWidth={true}
              label={<FormattedMessage id={'strings.firstName'}/>}
              margin="normal"
              value={nameFirst}
              name="nameFirst"
              onChange={this._handleChange('nameFirst')}
              error={errorInputs.nameFirst}
            />
            <TextField
              fullWidth={true}
              label={<FormattedMessage id={'strings.middleName'}/>}
              margin="normal"
              value={nameMiddle || ''}
              name="nameMiddle"
              onChange={this._handleChange('nameMiddle')}
            />
            <TextField
              required={true}
              fullWidth={true}
              label={<FormattedMessage id={'strings.lastName'}/>}
              margin="normal"
              value={nameLast}
              name="nameLast"
              onChange={this._handleChange('nameLast')}
              error={errorInputs.nameLast}
            />
            <FormControl required={true}>
              <LocalizedDatePicker
                onChange={(date: Date) => {
                  this.setState({
                    birthDate: new Date(date)
                  });
                }}
                margin="normal"
                label={intl.formatMessage({ id: 'strings.dateOfBirth' })}
                value={birthDate}
                emptyLabel={intl.formatMessage({ id: 'strings.dateOfBirth' })}
                clearable={true}
              />
            </FormControl>
            <TextField
              required={true}
              fullWidth={true}
              label={<FormattedMessage id={'strings.phone'}/>}
              margin="normal"
              value={phone}
              name="phone"
              onChange={this._handleChange('phone')}
              error={errorInputs.phone}
            />
            <TextField
              required={true}
              fullWidth={true}
              label={<FormattedMessage id={'strings.email'}/>}
              margin="normal"
              value={email}
              name="email"
              onChange={this._handleChange('email')}
              error={errorInputs.email}
            />

            <AddressFields
              streetAddress={streetAddress || ''}
              city={city || ''}
              zip={zip || ''}
              onChange={(args) => this.setState({ ...args } as any, validateValues)}
              requiredInputs={{ streetAddress: false, city: false, zip: true }}
              errorInputs={errorInputs}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={emailAdvertisingAllowed}
                  onChange={() => {
                    this.setState({emailAdvertisingAllowed: !emailAdvertisingAllowed});
                  }}
                  value="emailAdvertisingAllowed"
                />
              }
              label={<FormattedMessage id={'scenes.golfClub.greenCard.form.emailDirectMarketingAllowed'}/>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={smsAdvertisingAllowed}
                  onChange={() => {
                    this.setState({smsAdvertisingAllowed: !smsAdvertisingAllowed});
                  }}
                  value="smsAdvertisingAllowed"
                />
              }
              label={<FormattedMessage id={'scenes.golfClub.greenCard.form.textMessageDirectMarketingAllowed'}/>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={postAdvertisingAllowed}
                  onChange={() => {
                    this.setState({postAdvertisingAllowed: !postAdvertisingAllowed});
                  }}
                  value="postAdvertisingAllowed"
                />
              }
              label={<FormattedMessage id={'scenes.golfClub.greenCard.form.directMarketingAllowed'}/>}
            />
            {renderFormActions({
              id: greenCardId,
              parentContext: parentContext,
              onClose: this._handleOnClose,
              onUpdate: validateThenApply.bind(this, this._handleUpdate),
              onCreate: validateThenApply.bind(this, this._handleCreate),
              containerRef: actionsContainerRef,
            })}
          </>
        )}
      </InputsValidator>
    );
  }

  private _handleChange = (name: string, fn?: () => any) => (
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      this.setState({
        [name as any]: event.target.value,
      } as any, fn);
    }
  )

  /*
  private _handleTextFieldChange = event => {
    const { currentTarget: { name, value } } = event;
    this.setState({ [name]: value } as any);
  }
  */

  private _handleOnClose = () => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  }

  private _handleCreate = () => {
    const { addClubGreenCard } = this.props;
    const params = this._formParams();

    addClubGreenCard({
      ...params,
      onComplete: this._handleOnComplete,
    });
  }

  private _handleUpdate = () => {
    const { editClubGreenCard } = this.props;
    const {
      clubGreenCardState: {
        clubGreenCard
      }
    } = this.props;

    if (!clubGreenCard) {
      return;
    }

    const params = this._formParams();

    editClubGreenCard({
      id: clubGreenCard.id,
      ...params,
      onComplete: this._handleOnComplete,
    });
  }

  private _formParams = () => {
    const {
      proPersonInfo,
      timeRegistration,
      gender,
      phone,
      email,
      streetAddress,
      zip,
      city,
      birthDate,
      nameFirst,
      nameMiddle,
      nameLast,
      status,
      emailAdvertisingAllowed,
      smsAdvertisingAllowed,
      postAdvertisingAllowed
    } = this.state;

    const {
      match: {
        params
      }
    } = this.props;

    const { clubId } = params;

    return {
      status,
      clubId: clubId,
      recipientPersonId: proPersonInfo ? proPersonInfo.personId : 0,
      gender: gender as TargetGender,
      timeRegistration,
      nameFirst,
      nameMiddle,
      nameLast,
      birthDate,
      phone,
      email,
      streetAddress,
      city,
      zip,
      emailAdvertisingAllowed,
      smsAdvertisingAllowed,
      postAdvertisingAllowed
    };
  }

  private _handleOnComplete = (result: any) => {
    if (result.error) {
      window.alert(result.error.message);
    } else {
      this._handleOnClose();
    }
  }
}

const GreenCardForm = withRouter(injectIntl(connect((state: any) => ({
  clubGreenCardState: state.clubGreenCardReducer,
  auth: state.authReducer,
}), {
  addClubGreenCard: clubGreenCardAction.addClubGreenCard,
  editClubGreenCard: clubGreenCardAction.editClubGreenCard,
})(GreenCardFormConnected)));

export {
  GreenCardForm
};