import { call, put } from 'redux-saga/effects';
import { API_ROOT, APIRoute } from '@src/assets/config';
import { tokenAPIGet } from '@src/utils/storeUtils';
import { ORGANIZATION_PERSONS_ERROR, ORGANIZATION_PERSONS_SUCCESS } from '@src/store/organization/constants';

export function * fetchOrganizationPersonsFlow(token: string, organizationId: number) {
  try {
    const response = yield call(() => {
      const url = `${API_ROOT}${APIRoute.ORGANIZATION}/persons/${organizationId}`;
      return tokenAPIGet(url, token);
    });

    if (response) {
      yield put({
        type: ORGANIZATION_PERSONS_SUCCESS,
        data: response,
      });
    }

  } catch (error) {
    yield put({ type: ORGANIZATION_PERSONS_ERROR, error });
  }

  return true;
}