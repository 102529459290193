import React, { createRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { UserInfoForm } from '@src/components/forms/UserInfoForm';
import { connect } from 'react-redux';
import * as userActions from '@src/store/user/actions';
import { ModalContentOverlayLoader } from '@src/components/modals/ui';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import { FormattedMessage } from 'react-intl';

interface Props {
  userId: number | undefined;
  open: boolean;
  onClose: () => void;
  user: UserState;
  getUser: (params: UserGet) => any;
}

class UserModalConnected extends React.Component<Props, {}> {
  actionsContainerRef = createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      userId,
      getUser
    } = this.props;

    if (nextProps.userId !== userId) {
      if (nextProps.userId) {
        getUser({
          id: nextProps.userId,
        });
      }
    }
  }

  render() {
    const {
      open,
      userId,
      onClose,
      user: {
        requesting,
      },
    } = this.props;

    return (
      <Dialog
        open={open}
        fullWidth={true}
      >
        <DialogTitle>
          {userId && <FormattedMessage id={'scenes.users.modal.edit.title'} />}
          {!userId && <FormattedMessage id={'scenes.users.modal.add.title'} />}
        </DialogTitle>
        <DialogContent>
          {requesting && <ModalContentOverlayLoader/>}
          <UserInfoForm
            userId={userId}
            parentContext={'MODAL'}
            onClose={onClose}
            actionsContainerRef={this.actionsContainerRef}
          />
        </DialogContent>

        <div ref={this.actionsContainerRef} />
      </Dialog>
    );
  }
}

const UserModal = connect((state: any) => ({
  user: state.userReducer,
}), {
  getUser: userActions.getUser
})(UserModalConnected);

export {
  UserModal,
};
