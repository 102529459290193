import React, { ChangeEvent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { ErrorMessage, LoginFooter, LoginPageWrapper, LogoContainer } from '@src/scenes/authentication/ui';
import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import Typography from '@material-ui/core/Typography/Typography';
import CardActions from '@material-ui/core/CardActions/CardActions';
import Button from '@material-ui/core/Button/Button';
import { FormattedMessage } from 'react-intl';
import Collapse from '@material-ui/core/Collapse/Collapse';
import TextField from '@material-ui/core/TextField/TextField';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import { NavigateNext, NavigateBefore } from '@material-ui/icons';
import {
  API_ROOT,
  APIRoute,
  isCustomContext,
  formCustomImageURL,
  URL_LOGO,
  URL_LOGO_2X,
  URL_HEADER,
  URL_HEADER_2X
} from '@src/assets/config';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';

interface Props extends RouteComponentProps<any> {

}

interface State {
  loading: boolean;
  success: boolean;
  error: boolean;
  password: string;
  passwordConfirm: string;
  tokenValidityCheckFailed: boolean;
  response: any;
  passwordRetypedInvalid: boolean;
}

const initialState: State = {
  loading: false,
  success: false,
  error: false,
  password: '',
  passwordConfirm: '',
  tokenValidityCheckFailed: false,
  response: undefined,
  passwordRetypedInvalid: false,
};

const errorState = {
  loading: false,
  error: true,
  success: false,
};

class ResetPassword extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = initialState;
  }

  UNSAFE_componentWillMount() {
    this._handleTokeValidityCheck();
  }

  render() {
    const {
      success,
      error,
      loading,
      password,
      passwordConfirm,
      tokenValidityCheckFailed,
      passwordRetypedInvalid
    } = this.state;

    return (
      <LoginPageWrapper>
        <HeadHelmet titleId={'navigation.resetPassword'} />
        <LogoContainer>
          {this._renderCustomLogo()}
        </LogoContainer>
        <Card style={{padding: '1em'}}>
          <CardContent>
            <Typography component="h2" style={{margin: 0}}>
              <FormattedMessage id={'login.resetPassword.helpText'} />
            </Typography>
            <TextField
              style={{marginTop: '1em'}}
              name={'password'}
              disabled={loading || success || tokenValidityCheckFailed}
              fullWidth={true}
              label={<FormattedMessage id={'login.resetPassword.passwordFieldLabel'} />}
              type={'password'}
              value={password}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const {
                  currentTarget: {
                    value
                  }
                } = e;
                this.setState({ password: value });
              }}
            />
            <TextField
              style={{marginTop: '1em'}}
              name={'passwordConfirm'}
              disabled={loading || success || tokenValidityCheckFailed}
              fullWidth={true}
              label={<FormattedMessage id={'login.resetPassword.passwordFieldLabelConfirm'} />}
              type={'password'}
              value={passwordConfirm}
              error={passwordRetypedInvalid}
              helperText={passwordRetypedInvalid ? <FormattedMessage id={'login.resetPassword.inputMismatch'} /> : ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const {
                  currentTarget: {
                    value
                  }
                } = e;
                this.setState({ passwordConfirm: value });
              }}
            />
          </CardContent>
          <CardActions style={{justifyContent: 'flex-end', display: 'flex'}}>
            <Button
              disabled={this._isSubmitDisabled()}
              variant="contained"
              color="primary"
              onClick={this._handleActivation}
            >
              <FormattedMessage id={'login.resetPassword.submitButtonLabel'} />
            </Button>
          </CardActions>

          <Collapse
            in={loading}
            timeout={'auto'}
            unmountOnExit={true}
          >
            <div style={{ margin: '2em 0', textAlign: 'center'}}>
              <CircularProgress
                size={28}
                thickness={2}
              />
            </div>
          </Collapse>

          <Collapse
            in={success}
            timeout={'auto'}
            unmountOnExit={true}
          >
            <CardContent>
              <Typography component={'p'}>
                <FormattedMessage id={'login.resetPassword.success'} />
              </Typography>
              <LoginFooter style={{ paddingTop: '2em' }}>
                <Button onClick={this._handleToLogin}>
                  <FormattedMessage id={'login.resetPassword.successCTA'} />
                  <NavigateNext />
                </Button>
              </LoginFooter>
            </CardContent>
          </Collapse>

          <Collapse
            in={error && !tokenValidityCheckFailed}
            timeout={'auto'}
            unmountOnExit={true}
          >
            <CardContent>
              <ErrorMessage>
                <FormattedMessage id={'login.resetPassword.error'} />
              </ErrorMessage>
            </CardContent>
          </Collapse>

          <Collapse
            in={tokenValidityCheckFailed && error}
            timeout={'auto'}
            unmountOnExit={true}
          >
            <CardContent>
              <ErrorMessage>
                <FormattedMessage id={'login.resetPassword.invalidToken'} />
              </ErrorMessage>
              <LoginFooter style={{ paddingTop: '2em' }}>
                <Button onClick={this._handleToReset}>
                  <NavigateBefore/>
                  <FormattedMessage id={'login.resetPassword.invalidTokenCTA'} />
                </Button>
              </LoginFooter>
            </CardContent>
          </Collapse>
        </Card>

      </LoginPageWrapper>
    );
  }

  /**
   * This function renders GGB logo or custom logo depending are we
   * in GGB or customized context.
   *
   * Custom logo dimensions are 130x130.
   * GGB Office header is 265x65.
   *
   * @private
   */
  private _renderCustomLogo = () => {
    if (isCustomContext) {
      return (
          <img
              src={formCustomImageURL(URL_LOGO)}
              srcSet={`${formCustomImageURL(URL_LOGO_2X)} 2x`}
              width={130}
              height={130}
          />
      );
    }
    return (
        <img
            src={formCustomImageURL(URL_HEADER)}
            srcSet={`${formCustomImageURL(URL_HEADER_2X)} 2x`}
            // width={265}
            height={65}
        />
    );
  }

  private _isSubmitDisabled = () => {
    const {
      success,
      loading,
      password,
      passwordConfirm,
      tokenValidityCheckFailed
    } = this.state;

    return (
      loading || success || password.length < 4 || tokenValidityCheckFailed || passwordConfirm.length < 4
    );
  }

  private _handleToLogin = () => {
    const {
      history,
    } = this.props;

    history.push('/login');
  }

  private _handleToReset = () => {
    const {
      history,
    } = this.props;

    history.push('/reset-password');
  }

  private _handleActivation = () => {
    const {
      match: {
        params: {
          resetToken
        },
      },
    } = this.props;
    const {
      password,
      passwordConfirm,
    } = this.state;

    if (password !== passwordConfirm) {
      this.setState({
        passwordRetypedInvalid: true,
      });

      return;
    } else {
      this.setState({
        passwordRetypedInvalid: false,
      });
    }

    const data = new FormData();
    data.append('token', resetToken);
    data.append('password', password);
    data.append('passwordConfirm', passwordConfirm);

    this.setState({ loading: true, error: false }, () => {
      const options: RequestInit = {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Accept': 'application/json',
        },
        body: data,
      };

      fetch(`${API_ROOT}${APIRoute.ACTIVATE_ACCOUNT}`, options)
        .then(response => response.json())
        .then(json => {
          if (json.error) {
            this.setState({
              ...errorState,
              response: json,
            });
          } else {
            this.setState({
              loading: false,
              success: true,
              error: false,
              response: json,
            });
          }
        })
        .catch(error => {
          this.setState({
            ...errorState,
            response: error,
          });
        });
    });
  }

  private _handleTokeValidityCheck = () => {
    const {
      match: {
        params: {
          resetToken
        },
      },
    } = this.props;

    const data = new FormData();
    data.append('perishableToken', resetToken);

    this.setState({ ...initialState, loading: true }, () => {
      const options: RequestInit = {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Accept': 'application/json',
        },
        body: data,
      };

      fetch(`${API_ROOT}${APIRoute.VALIDATE_TOKEN}`, options)
        .then(response => response.json())
        .then(json => {
          if (json.error) {
            this.setState({
              ...errorState,
              tokenValidityCheckFailed: true,
              response: json,
            });
          } else {
            this.setState({
              loading: false,
              success: false,
              error: false,
              tokenValidityCheckFailed: false,
              response: json,
            });
          }
        })
        .catch(error => {
          this.setState({
            ...errorState,
            response: error,
          });
        });
    });
  }
}

export default withRouter(ResetPassword);
