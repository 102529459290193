import { take, fork } from 'redux-saga/effects';
import {
  CLUB_FEES_REQUESTING,
  CLUB_FEES_SUCCESS,
  CLUB_FEES_ERROR,
  CLUB_FEES_RESET,
  CLUB_FEES_SEARCH_REQUEST,
  CLUB_FEES_SEARCH_RESET,
} from './constants';
import { DEFAULT_PAGINATION_LIMIT, DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import { AUTH_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { normalizeSearchParams } from '@src/utils/storeUtils';

/*
  GET /admin/1/club/<id>/fees
 */
const clubFeeCRUDFlow = (clubId: number) => {
  return SagaFlowFactory.createPagedGETFlow({
    apiRoute: `admin/1/club/${clubId}/fees`,
    itemType: 'fees',
    successType: CLUB_FEES_SUCCESS,
    errorType: CLUB_FEES_ERROR
  });
};

/*
  GET /admin/1/club/<id>/fees/search
 */
const fetchClubFeeSearchFlow = (clubId: number) => {
  return SagaFlowFactory.createPagedGETFlow({
    apiRoute: `admin/1/club/${clubId}/fees/search`,
    itemType: 'fees',
    successType: CLUB_FEES_SUCCESS,
    errorType: CLUB_FEES_ERROR
  });
};

export default function * clubFeesWatcher() {
  let token: string = '';
  let targetingParams: any = {};

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
      page,
      clubId,
      limit,
      params
    } = yield take([
      CLUB_FEES_REQUESTING,
      CLUB_FEES_RESET,
      CLUB_FEES_SEARCH_REQUEST,
      CLUB_FEES_SEARCH_RESET
      // AUTH_ACTIVE_STATE_CHANGE,
      // INITIAL_AUTH_ACTIVE_STATE_SET,
    ]);

    switch (type) {
      case CLUB_FEES_REQUESTING:
        yield fork(clubFeeCRUDFlow(clubId), token, page, limit, targetingParams);
        break;
      case CLUB_FEES_RESET:
        yield fork(
          clubFeeCRUDFlow(params.clubId),
          token,
          DEFAULT_PAGINATION_PAGE,
          DEFAULT_PAGINATION_LIMIT,
          targetingParams
        );
        break;
      case CLUB_FEES_SEARCH_REQUEST:
        yield fork(fetchClubFeeSearchFlow(clubId), token, page, limit, normalizeSearchParams(params));
        break;
      case CLUB_FEES_SEARCH_RESET:
        yield fork(clubFeeCRUDFlow(clubId), token, DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_LIMIT, targetingParams);
        break;
      default:
    }
  }
}