/**
 * A club comparison function for sort() with name ASC, abbreviation ASC, idClub ASC ordering.
 *
 * @param a The first club for comparison
 * @param b The second club for comparison
 * @returns Number Negative when a < b, zero when a == b, positive when a > b
 */
export const clubCompare = (a: Club, b: Club, l?: string): number => {
  let c: number = !a.name ? !b.name ? 0 : -1 : a.name.localeCompare(b.name, l);
  if (c === 0) {
    c = !a.abbreviation ? !b.abbreviation ? 0 : -1 : a.abbreviation.localeCompare(b.abbreviation, l);
    if (c === 0) {
      c = b.idClub - a.idClub;
    }
  }
  return c;
};
