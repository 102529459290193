export const ADD_ACCEPTABLE_SCORE_REQUESTING = 'ADD_ACCEPTABLE_SCORE_REQUESTING';
export const ADD_ACCEPTABLE_SCORE_SUCCESS = 'ADD_ACCEPTABLE_SCORE_SUCCESS';
export const ADD_ACCEPTABLE_SCORE_ERROR = 'ADD_ACCEPTABLE_SCORE_ERROR';

export const ADD_ACCEPTABLE_SCORE_AWAY_REQUESTING = 'ADD_ACCEPTABLE_SCORE_AWAY_REQUESTING';
export const ADD_ACCEPTABLE_SCORE_AWAY_SUCCESS = 'ADD_ACCEPTABLE_SCORE_AWAY_SUCCESS';
export const ADD_ACCEPTABLE_SCORE_AWAY_ERROR = 'ADD_ACCEPTABLE_SCORE_AWAY_ERROR';

export const EDIT_ACCEPTABLE_SCORE_ADJUSTMENT_REQUESTING = 'EDIT_ACCEPTABLE_SCORE_ADJUSTMENT_REQUESTING';
export const EDIT_ACCEPTABLE_SCORE_ADJUSTMENT_SUCCESS = 'EDIT_ACCEPTABLE_SCORE_ADJUSTMENT_SUCCESS';
export const EDIT_ACCEPTABLE_SCORE_ADJUSTMENT_ERROR = 'EDIT_ACCEPTABLE_SCORE_ADJUSTMENT_ERROR';

export const EDIT_ACCEPTABLE_SCORE_HOLE_SCORE_REQUESTING = 'EDIT_ACCEPTABLE_SCORE_HOLE_SCORE_REQUESTING';
export const EDIT_ACCEPTABLE_SCORE_HOLE_SCORE_SUCCESS = 'EDIT_ACCEPTABLE_SCORE_HOLE_SCORE_SUCCESS';
export const EDIT_ACCEPTABLE_SCORE_HOLE_SCORE_ERROR = 'EDIT_ACCEPTABLE_SCORE_HOLE_SCORE_ERROR';

export const EDIT_ACCEPTABLE_SCORE_STATUS_REQUESTING = 'EDIT_ACCEPTABLE_SCORE_STATUS_REQUESTING';
export const EDIT_ACCEPTABLE_SCORE_STATUS_SUCCESS = 'EDIT_ACCEPTABLE_SCORE_STATUS_SUCCESS';
export const EDIT_ACCEPTABLE_SCORE_STATUS_ERROR = 'EDIT_ACCEPTABLE_SCORE_STATUS_ERROR';

export const EDIT_ACCEPTABLE_SCORE_PERSON_REQUESTING = 'EDIT_ACCEPTABLE_SCORE_PERSON_REQUESTING';
export const EDIT_ACCEPTABLE_SCORE_PERSON_SUCCESS = 'EDIT_ACCEPTABLE_SCORE_PERSON_SUCCESS';
export const EDIT_ACCEPTABLE_SCORE_PERSON_ERROR = 'EDIT_ACCEPTABLE_SCORE_PERSON_ERROR';