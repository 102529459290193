import {
  REPORT_CONFIG_REQUESTING_SUCCESS,
  REPORT_EBIRDIE_STATISTICS_REQUESTING,
  REPORT_EBIRDIE_STATISTICS_REQUESTING_ERROR,
  REPORT_EBIRDIE_STATISTICS_REQUESTING_SUCCESS,
  REPORT_SCORE_STATISTICS_REQUESTING,
  REPORT_SCORE_STATISTICS_REQUESTING_ERROR,
  REPORT_SCORE_STATISTICS_REQUESTING_SUCCESS,
} from '@src/store/reports/constants';
import { formDefaultErrorState } from '@src/utils/storeUtils';

const initialState: ReportsState = {
  requesting: false,
  successful: false,
  error: [],
  eBirdieStatistics: undefined,
  handicapStatistics: undefined,
};

export const reportsReducer = (state: ReportsState = initialState, action: any): ReportsState => {
  switch (action.type) {
    case REPORT_EBIRDIE_STATISTICS_REQUESTING:
    case REPORT_SCORE_STATISTICS_REQUESTING:
      return {
        ...state,
        successful: false,
        error: [],
        requesting: true,
      };
    case REPORT_EBIRDIE_STATISTICS_REQUESTING_ERROR:
    case REPORT_SCORE_STATISTICS_REQUESTING_ERROR:
      return formDefaultErrorState(state, action);
    case REPORT_EBIRDIE_STATISTICS_REQUESTING_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
        eBirdieStatistics: action.data,
      };
    case REPORT_SCORE_STATISTICS_REQUESTING_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
        handicapStatistics: action.data,
      };
    case REPORT_CONFIG_REQUESTING_SUCCESS:
      return {
        ...state,
        config: action.data,
      };
    default:
      return state;
  }
};