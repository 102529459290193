import { put, call } from '@redux-saga/core/effects';
import { DETACH_COURSE_ERROR, DETACH_COURSE_SUCCESS } from '@src/store/clubCourse/constants';
import { tokenAPIDelete } from '@src/utils/storeUtils';
import { API_ROOT } from '@src/assets/config';

export function* detachCourseFlow(
  token: string,
  parentCourseId: number,
  childCourseId: number,
  onComplete?: (args: APICallResult) => void
) {
  try {
    yield call(() =>
      tokenAPIDelete(`${API_ROOT}admin/1/course/${parentCourseId}/detach/${childCourseId}`, token, {})
    );

    yield put({
      type: DETACH_COURSE_SUCCESS,
    });

    if (onComplete) {
      onComplete({ data: { success: true } });
    }
  }  catch (error) {
    yield put({ type: DETACH_COURSE_ERROR, error });

    if (onComplete) {
      onComplete({ error });
    }
  }
}