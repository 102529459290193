import { fork, take } from 'redux-saga/effects';
import { AUTH_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { APIRoute } from '@src/assets/config';
import {
  MEMBERSHIP_ADD_REQUESTING,
  MEMBERSHIP_DELETE_REQUESTING,
  MEMBERSHIP_EDIT_REQUESTING,
  MEMBERSHIP_GET_REQUESTING,
  MEMBERSHIP_HANDICAP_ROUNDS_REQUESTING,
  MEMBERSHIP_INACTIVATE_REQUESTING
} from './constants';
import { CLUB_MEMBERS_SEARCH_RESET } from '@src/store/club/constants';
import { getHandicapRoundsFlow } from '@src/store/membership/flows/get-handicap-rounds';

/*
  GET|POST|PUT|DELETE /admin/1/member/<personId>/<clubId>
 */
const fetchCRUDFlow = SagaFlowFactory.createCRUDFlow({
  apiRoute: APIRoute.ADMIN_MEMBERS,
  typePrefix: 'MEMBERSHIP',
  resetType: CLUB_MEMBERS_SEARCH_RESET,
});

export default function * () {
  let token: string = '';

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
      personId,
      clubId,
      idMembership,
      params,
    } = yield take([
      MEMBERSHIP_GET_REQUESTING,
      MEMBERSHIP_ADD_REQUESTING,
      MEMBERSHIP_EDIT_REQUESTING,
      MEMBERSHIP_DELETE_REQUESTING,
      MEMBERSHIP_HANDICAP_ROUNDS_REQUESTING,
      MEMBERSHIP_INACTIVATE_REQUESTING
    ]);

    switch (type) {
      case MEMBERSHIP_GET_REQUESTING:
        yield fork(fetchCRUDFlow, token, 'GET', `${personId}/${clubId}` as any, params);
        break;
      case MEMBERSHIP_ADD_REQUESTING:
        yield fork(fetchCRUDFlow, token, 'ADD', null, params);
        break;
      case MEMBERSHIP_EDIT_REQUESTING:
        yield fork(fetchCRUDFlow, token, 'EDIT', `${personId}/${clubId}` as any, params);
        break;
      case MEMBERSHIP_DELETE_REQUESTING:
        yield fork(fetchCRUDFlow, token, 'DELETE', `${idMembership}` as any, params);
        break;
      case MEMBERSHIP_HANDICAP_ROUNDS_REQUESTING:
        yield fork(getHandicapRoundsFlow, token, personId);
        break;
      case MEMBERSHIP_INACTIVATE_REQUESTING:
        yield fork(fetchCRUDFlow, token, 'EDIT', `${idMembership}/inactivate` as any, params);
        break;
      default:
    }
  }
}