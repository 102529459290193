import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import { ActionContainer, ModalContentOverlayLoader } from '@src/components/modals/ui';
import * as vendorActions from '@src/store/vendor/actions';
import moment from 'moment';
import Button from '@material-ui/core/Button/Button';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import { clubCompare } from '@src/utils/ClubUtils';

type Props = {
  vendorId: number | undefined;
  open: boolean;
  onClose: () => void;
  vendorState: VendorState;
  fetchVendorClubs: (params: FetchVendorClubs) => any;
  locale: AppLocale;
};

class VendorClubsModalConnected extends PureComponent<Props> {
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.vendorId && nextProps.vendorId !== this.props.vendorId) {
      const {
        fetchVendorClubs,
      } = this.props;
      fetchVendorClubs({
        vendorId: nextProps.vendorId,
      });
    }
  }

  render() {
    const {
      open,
      onClose,
      vendorState: {
        vendorClubs: {
          clubs,
          requesting,
          successful
        }
      }
    } = this.props;

    return (
      <Dialog
        open={open}
        fullWidth={true}
        onClose={() => {
          if (onClose) {
            onClose();
          }
        }}
      >
        <DialogTitle><FormattedMessage id={'scenes.golfFederation.vendors.clubs.title'}/></DialogTitle>
        <DialogContent>
          {requesting && <ModalContentOverlayLoader/>}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id={'scenes.golfFederation.vendors.clubs.table.headers.abbreviation'}/>
                </TableCell>
                <TableCell><FormattedMessage id={'scenes.golfFederation.vendors.clubs.table.headers.name'}/></TableCell>
                <TableCell>
                  <FormattedMessage id={'scenes.golfFederation.vendors.clubs.table.headers.lastUpdate'}/>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {successful && clubs && Object.keys(clubs).sort((a, b): number => {
                  return clubCompare(clubs[a], clubs[b], this.props.locale.appLanguage.langName);
                }
              ).map((key: string) => (
                <TableRow key={clubs[key].id}>
                  <TableCell>{clubs[key].nameAbbreviation}</TableCell>
                  <TableCell>{clubs[key].name}</TableCell>
                  <TableCell>
                    {clubs[key].timeUpdated ?
                      moment(clubs[key].timeUpdated).locale('fi').format('l') :
                      '-'
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <ActionContainer>
            <Button variant="contained" onClick={onClose}>
              <FormattedMessage id={'buttons.close'}/>
            </Button>
          </ActionContainer>
        </DialogActions>
      </Dialog>
    );
  }
}

const VendorClubsModal = connect((state: any) => ({
  vendorState: state.vendorReducer,
  locale: state.locale
}), {
  fetchVendorClubs: vendorActions.fetchVendorClubs,
})(VendorClubsModalConnected);

export {
  VendorClubsModal
};
