/*
  PUT /admin/1/member/{id}/activation
 */
import { defaultOnCompleteCall, tokenAPIPut } from '@src/utils/storeUtils';
import {
  CLUB_MEMBER_ACTIVATION_ERROR,
  CLUB_MEMBER_ACTIVATION_SUCCESS,
} from '@src/store/club/constants';
import { API_ROOT, APIRoute } from '@src/assets/config';
import { call, put } from 'redux-saga/effects';

export function * setClubMemberActivationFlow(token: string, memberId: number, params: any = {}) {
  try {
    const member = yield call(() => {
      const url = `${API_ROOT}${APIRoute.GET_MEMBER}/${memberId}/activation`;
      return tokenAPIPut(url, token, params);
    });

    if (member) {
      const {
        id,
        memberStatus,
        validTo,
      } = member;

      yield put({
        type: CLUB_MEMBER_ACTIVATION_SUCCESS,
        id,
        memberStatus,
        validTo,
        member,
      });

      defaultOnCompleteCall(params.onComplete, member);
    }

  } catch (error) {
    yield put({ type: CLUB_MEMBER_ACTIVATION_ERROR, error });
    defaultOnCompleteCall(params.onComplete, undefined, error);
  }

  return true;
}