export const ACCEPTABLE_SCORES_REQUESTING = 'ACCEPTABLE_SCORES_REQUESTING';
export const ACCEPTABLE_SCORES_SUCCESS = 'ACCEPTABLE_SCORES_SUCCESS';
export const ACCEPTABLE_SCORES_ERROR = 'ACCEPTABLE_SCORES_ERROR';

export const ACCEPTABLE_SCORES_CHANGE_PAGE = 'ACCEPTABLE_SCORES_CHANGE_PAGE';

export const PENDING_ACCEPTABLE_SCORES_REQUESTING = 'PENDING_ACCEPTABLE_SCORES_REQUESTING';
export const PENDING_ACCEPTABLE_SCORES_SUCCESS = 'PENDING_ACCEPTABLE_SCORES_SUCCESS';
export const PENDING_ACCEPTABLE_SCORES_ERROR = 'PENDING_ACCEPTABLE_SCORES_ERROR';

export const RESET_ACCEPTABLE_SCORES = 'RESET_ACCEPTABLE_SCORES';
export const ACCEPTABLE_SCORES_REFETCH = 'ACCEPTABLE_SCORES_REFETCH';
