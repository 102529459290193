import { createSelector } from 'reselect';
import memoize from 'lodash/memoize';
import { findAffectedClubCourses } from '@src/utils/courseUtils';

export const getCondensedCourses = (store: StoreState) => store.clubCoursesReducer.condensedCourseClubs;

export const getPublishedCondensedCourses = createSelector(
  getCondensedCourses,
  (courses) => courses.filter(course => (course.status === 'ACTIVE'))
);

export const getFrontNineCourses = createSelector(
  getPublishedCondensedCourses,
  (courses) => courses.filter(course => course.courseType === 'FRONT')
);

export const getBackNineCourses = createSelector(
  getPublishedCondensedCourses,
  (courses) => courses.filter(course => course.courseType === 'BACK')
);

export const getAffectedClubCoursesByCourseId = createSelector(
  getCondensedCourses,
  courses => memoize<(courseId: number) => (CondensedClubCourse & { parent?: boolean })[]>(
    courseId => findAffectedClubCourses(courseId, courses)
  ),
);