import React, { ChangeEvent, PureComponent, RefObject, Fragment } from 'react';
import { connect } from 'react-redux';
import { renderFormActions } from '@src/components/modals/ui';
import * as clubCourseHoleAction from '@src/store/clubCourseHole/actions';
import {
  TextField,
  Divider,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import InputsValidator from '@src/components/forms/InputsValidator';
import { FormattedMessage } from 'react-intl';

type Props = {
  clubCourseId: number;
  holeId?: number | undefined;
  parentContext?: 'MODAL' | 'PAGE';
  onClose?: (shouldRefetch?: boolean) => void;
  clubCourseHoleState: ClubCourseHoleState;
  editClubCourseHole: (params: ClubCourseHoleEdit) => any;
  actionsContainerRef?: RefObject<HTMLElement>;
};

type CourseHoleTeeState = {
  colorId: number;
  par: string;
  length: string;
};

type State = {
  holePar: number;
  holeStrokeHcp: string;
  holeHcp: string;
  tees: CourseHoleTee[];
  teeValues: {
    GREEN: CourseHoleTeeState;
    RED: CourseHoleTeeState;
    BLUE: CourseHoleTeeState;
    YELLOW: CourseHoleTeeState;
    WHITE: CourseHoleTeeState;
    BLACK: CourseHoleTeeState;
    GOLD: CourseHoleTeeState;
  };
};

const initialTeeProps: CourseHoleTeeState = {
  colorId: 0,
  par: '',
  length: '',
};

const initialState = {
  holePar: 0,
  holeStrokeHcp: '',
  holeHcp: '',
  tees: [],
  teeValues: {
    GREEN: initialTeeProps,
    RED: initialTeeProps,
    BLUE: initialTeeProps,
    YELLOW: initialTeeProps,
    WHITE: initialTeeProps,
    BLACK: initialTeeProps,
    GOLD: initialTeeProps
  },
};

class ClubCourseHoleFormConnected extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const {
      holeId,
      clubCourseHoleState: {
        clubCourseHole,
      }
    } = this.props;

    if (!holeId) {
      this.state = initialState;
    } else if (clubCourseHole) {
      this.state = {
        ...this._parsePropertiesFromHoleData(clubCourseHole),
      };
    } else {
      this.state = initialState;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      holeId,
      clubCourseHoleState: {
        clubCourseHole,
        requesting,
      },
    } = nextProps;

    if (!holeId) {
      return; // Do nothing if we are adding new person
    } else if (requesting) {
      return; // Do nothing state is loading
    } else if (clubCourseHole) {
      this.setState({
        ...this._parsePropertiesFromHoleData(clubCourseHole),
      });
    }
  }

  render() {
    const {
      parentContext,
      holeId,
      actionsContainerRef,
    } = this.props;

    const {
      holePar,
      holeStrokeHcp,
      holeHcp,
      tees,
      teeValues,
    } = this.state;

    return (
      <InputsValidator values={{ holePar, holeStrokeHcp, holeHcp }}>
        {({ errorInputs, validateThenApply, validateValues }) => (
          <>
            <TextField
              required={true}
              fullWidth={true}
              type="number"
              label={<FormattedMessage id={'scenes.golfClub.courses.holes.modal.edit.holePar'} />}
              name={'holePar'}
              value={holePar}
              margin="normal"
              onChange={this._handleChange('holePar', validateValues)}
              error={errorInputs.holePar}
            />

            <TextField
              required={true}
              fullWidth={true}
              label={<FormattedMessage id={'scenes.golfClub.course.hole.sHCP'} />}
              type="number"
              margin="normal"
              name={'holeStrokeHcp'}
              value={holeStrokeHcp}
              onChange={this._handleChange('holeStrokeHcp', validateValues)}
              error={errorInputs.holeStrokeHcp}
            />

            <TextField
              required={true}
              fullWidth={true}
              type="number"
              label={<FormattedMessage id={'scenes.golfClub.course.hole.mHCP'} />}
              margin="normal"
              name={'holeHcp'}
              value={holeHcp}
              onChange={this._handleChange('holeHcp', validateValues)}
              error={errorInputs.holeHcp}
            />

            <div>
              {Boolean(tees) && tees.map((tee: CourseHoleTee, index: number) => {
                if (!tee.color) {
                  return null;
                }

                return (
                  <Fragment key={index}>
                    {index > 0 && <Divider style={{marginBottom: 20}}/>}
                    {/* todo: figure out minWidth:0 for InputBase */}
                    <Grid key={tee.id} style={{marginTop: 20, marginBottom: 20}} container={true} spacing={2}>

                      <Grid item={true} style={{ alignSelf: 'center', marginRight: '2em' }}>
                        <p style={{ fontSize: '1.4em' }}>
                          {tee.description}
                        </p>
                      </Grid>

                      <Grid item={true}>
                        <TextField
                          fullWidth={true}
                          required={true}
                          label={<FormattedMessage id={'scenes.golfClub.courses.holes.modal.edit.holePar'} />}
                          type="number"
                          margin="normal"
                          name={'tee.par'}
                          value={teeValues[tee.color].par}
                          onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                            const { target: { value } } = event;
                            this.setState({
                              teeValues: {
                                ...this.state.teeValues,
                                [`${tee.color}`] : {
                                  ...teeValues[tee.color],
                                  par: value,
                                },
                              },
                            });
                          }}
                        />
                      </Grid>

                      <Grid item={true}>
                        <TextField
                          fullWidth={true}
                          required={true}
                          label={<FormattedMessage id={'scenes.golfClub.courses.holes.modal.edit.length'} />}
                          type="number"
                          margin="normal"
                          name={'tee.length'}
                          value={teeValues[tee.color].length}
                          onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                            const { target: { value } } = event;
                            this.setState({
                              teeValues: {
                                ...this.state.teeValues,
                                [`${tee.color}`] : {
                                  ...teeValues[tee.color],
                                  length: value,
                                },
                              },
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Fragment>
                );
              })}
            </div>
            {renderFormActions({
              id: holeId,
              parentContext,
              onUpdate: validateThenApply.bind(this, this._handleUpdate),
              onClose: this._handleOnClose,
              disabled: false,
              containerRef: actionsContainerRef,
            })}
          </>
        )}
      </InputsValidator>
    );
  }

  private _handleChange = (name: string, fn?: () => any) => (
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      this.setState({
        [name as any]: event.target.value,
      } as any, fn);
    }
  )

  private _handleOnClose = (shouldRefetch: boolean = false) => {
    const { onClose } = this.props;
    if (onClose) {
      onClose(shouldRefetch);
    }
  }

  private _handleUpdate = () => {
    const { editClubCourseHole, clubCourseId } = this.props;
    const {
      clubCourseHoleState: {
        clubCourseHole,
      },
    } = this.props;

    if (!clubCourseHole) {
      return;
    }

    const params = this._formParams();

    editClubCourseHole({
      holeId: clubCourseHole.id,
      clubCourseId: clubCourseId,
      ...params,
      onComplete: this._handleOnComplete,
    });
  }

  private _formParams = () => {
    const {
      holePar,
      holeStrokeHcp,
      holeHcp,
      teeValues,
    } = this.state;

    const tees = Object.keys(teeValues).map((color: string) => {
      return teeValues[color];
    });

    return {
      par: holePar,
      holeHcp,
      holeStrokeHcp,
      tees,
    };
  }

  private _handleOnComplete = (result: any) => {
    if (result.error) {
      window.alert(result.error.message);
    } else {
      this._handleOnClose(true);
    }
  }

  private _parsePropertiesFromHoleData = (hole: CourseHole): State => {
    const {
      holePar,
      holeStrokeHcp,
      holeHcp,
      tees,
    } = hole;

    let teeValues = {};

    tees.forEach(tee => {
      const { color, colorId, par, length } = tee;
      teeValues[color] = { colorId, par, length };
    });

    return {
      holePar,
      holeStrokeHcp,
      holeHcp,
      tees,
      teeValues,
    } as any;
  }
}

const styles = () => ({

});

const ClubCourseHoleForm = withStyles(styles)(connect((state: any) => ({
  clubCourseHoleState: state.clubCourseHoleReducer,
}), {
  editClubCourseHole: clubCourseHoleAction.editClubCourseHole,
})(ClubCourseHoleFormConnected));

export {
  ClubCourseHoleForm
};