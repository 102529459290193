import { formatURLParams, tokenAPIGet } from '@src/utils/storeUtils';
import { call, put } from 'redux-saga/effects';
import { API_ROOT, APIRoute } from '@src/assets/config';
import { } from '@src/store/functionarytitles/constants';
import { FUNCTIONARIES_CONDENSED_SUCCESS } from '@src/store/functionaries/constants';

export function * getCondensedFunctionaries(token: string, targetIdParams: TargetIdParams) {
  try {
    const urlParams = formatURLParams({ ...targetIdParams, type: 'condensed' }, '?');

    const allFunctionaries = yield call(() => {
      const url = `${API_ROOT}${APIRoute.GET_FUNCTIONARIES}${urlParams}`;
      return tokenAPIGet(url, token);
    });

    if (allFunctionaries) {
      yield put({
        type: FUNCTIONARIES_CONDENSED_SUCCESS,
        allFunctionaries,
      });
    }

  } catch (error) {
    window.console.warn(error);
  }

  return true;
}