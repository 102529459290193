import React, { createRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import { ModalContentOverlayLoader } from '@src/components/modals/ui';
import * as maintenanceActions from '@src/store/maintenance/actions';
import { MaintenanceForm } from '@src/components/forms/MaintenanceForm';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import { FormattedMessage } from 'react-intl';

interface Props {
  maintenanceId: number | undefined;
  open: boolean;
  onClose: () => void;
  getMaintenance: (params: MaintenanceGet) => any;
  maintenance: MaintenanceState;
}

class MaintenanceModalConnected extends React.Component<Props, {}> {
  actionsContainerRef = createRef<HTMLDivElement>();

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      maintenanceId,
      getMaintenance
    } = this.props;

    if (nextProps.maintenanceId !== maintenanceId) {
      if (nextProps.maintenanceId) {
        getMaintenance({
          id: nextProps.maintenanceId,
        });
      }
    }
  }

  render() {
    const {
      open,
      onClose,
      maintenanceId,
      maintenance: {
        requesting,
      },
    } = this.props;

    return (
      <Dialog
        open={open}
        fullWidth={true}
      >
        <DialogTitle>
          {maintenanceId && <FormattedMessage id={'scenes.maintenances.modal.edit.title'} />}
          {!maintenanceId && <FormattedMessage id={'scenes.maintenances.modal.add.title'} />}
        </DialogTitle>
        <DialogContent>
          {requesting && <ModalContentOverlayLoader/>}
          <MaintenanceForm
            maintenanceId={maintenanceId}
            parentContext={'MODAL'}
            onClose={onClose}
            actionsContainerRef={this.actionsContainerRef}
          />
        </DialogContent>

        <div ref={this.actionsContainerRef} />
      </Dialog>
    );
  }
}

const MaintenanceModal = connect((state: any) => ({
  maintenance: state.maintenanceReducer,
}), {
  getMaintenance: maintenanceActions.getMaintenance
})(MaintenanceModalConnected);

export {
  MaintenanceModal,
};
