import React, { ReactNode, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import ScoreAdjustments from '@src/components/scenes/member/components/ScoreAdjustments';
import {
  editAcceptableScoreAdjustment,
  EditAcceptableScoreAdjustmentPayload
} from '@src/store/acceptableScore/actions';
import { connect } from 'react-redux';
import { ModalContentOverlayLoader } from '@src/components/modals/ui';

export const UpdateAdjustmentContext = React.createContext({
  handleAdjustmentEdit: (args: Omit<EditAcceptableScoreAdjustmentPayload, 'acceptableScoreId'>) => {},
});

export interface ChildrenArgs {
  ref: React.RefObject<any>;
  showAdjustments(
    acceptableScore: AcceptableScore,
    adjustments: ScoreAdjustment[],
    inheritedAdjustments?: ScoreAdjustment[],
  ): void;
}

interface OwnProps {
  children(args: ChildrenArgs): ReactNode;
}

interface DispatchProps {
  editAcceptableScoreAdjustment(args: EditAcceptableScoreAdjustmentPayload): void;
}

type Props = OwnProps & DispatchProps;

function ScoreAdjustmentsModal(props: Props, ref) {
  const { children, editAcceptableScoreAdjustment } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [adjustments, setAdjustments] = useState<ScoreAdjustment[] | undefined>(undefined);
  const [inheritedAdjustments, setInheritedAdjustments] = useState<ScoreAdjustment[] | undefined>(undefined);
  const [acceptableScore, setAcceptableScore] = useState<AcceptableScore | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const onClose = () => setOpen(false);

  const handleAdjustmentEdit = (args: Omit<EditAcceptableScoreAdjustmentPayload, 'acceptableScoreId'>) => {
    if (acceptableScore) {
      setLoading(true);
      editAcceptableScoreAdjustment({
        acceptableScoreId: acceptableScore.id,
        ...args,
        onComplete: ({ data }) => {
          setLoading(false);

          if (data && data.adjustments) {
            setAdjustments(data.adjustments);
            setInheritedAdjustments(data.inheritedAdjustments);
          }
        }
      });
    }
  };

  return (
    <UpdateAdjustmentContext.Provider value={{ handleAdjustmentEdit }}>
      <Dialog open={open} disableBackdropClick={true} maxWidth={'xs'} fullWidth={true}>
        <DialogTitle>
          <FormattedMessage id={'strings.scoreAdjustments'} />
        </DialogTitle>
        <DialogContent>
          <ScoreAdjustments
            adjustments={adjustments}
            inheritedAdjustments={inheritedAdjustments}
            readonly={acceptableScore?.typeOfRound === 'ARTIFICIAL'}
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-end' }}>
          <Button onClick={onClose} variant={'contained'}>
            <FormattedMessage id={'buttons.close'} />
          </Button>
          {loading && <ModalContentOverlayLoader/>}
        </DialogActions>
      </Dialog>
      {children({
        showAdjustments(acceptableScore, adjustments, inheritedAdjustments): void {
          setAcceptableScore(acceptableScore);
          setAdjustments(adjustments);
          setInheritedAdjustments(inheritedAdjustments);
          setOpen(true);
        },
        ref,
      })}
    </UpdateAdjustmentContext.Provider>
  );
}

export default connect<{}, DispatchProps, OwnProps, StoreState>(null, {
  editAcceptableScoreAdjustment,
})(React.forwardRef(ScoreAdjustmentsModal));
