import React, { createRef, PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { ClubCourseTeeForm } from '@src/components/scenes/club/course/tee/ClubCourseTeeForm';
import { ModalContentOverlayLoader } from '@src/components/modals/ui';
import { getClubCourseTee } from '@src/store/clubCourseTee/actions';

interface OwnProps {
  open: boolean;
  clubCourseId: number;
  clubCourseTeeId: number | undefined;
  disableStatus?: boolean;
  disableDescription?: boolean;
  onClose(shouldRefetch?: boolean): void;
}

interface StateProps {
  clubCourseTeeState: ClubCourseTeeState;
}

interface DispatchProps {
  getClubCourseTee(params: ClubCourseTeeGet): void;
}

type Props = OwnProps & StateProps & DispatchProps;

class ClubCourseTeeModalConnected extends PureComponent<Props> {
  actionsContainerRef = createRef<HTMLDivElement>();

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      clubCourseTeeId,
      getClubCourseTee
    } = this.props;

    if (nextProps.clubCourseTeeId !== clubCourseTeeId) {
      if (nextProps.clubCourseTeeId) {
        getClubCourseTee({
          clubCourseId: nextProps.clubCourseId,
          id: nextProps.clubCourseTeeId,
        });
      }
    }
  }

  render() {
    const {
      open,
      onClose,
      clubCourseId,
      clubCourseTeeId,
      clubCourseTeeState: {
        requesting
      },
      disableStatus,
      disableDescription,
    } = this.props;

    return (
      <Dialog
        open={open}
        fullWidth={true}
      >
        <DialogTitle>
          {clubCourseTeeId && <FormattedMessage id={'scenes.golfClub.courses.tees.modal.edit.title'} />}
        </DialogTitle>
        <DialogContent>
          {requesting && <ModalContentOverlayLoader/>}
          <ClubCourseTeeForm
            clubCourseId={clubCourseId}
            clubCourseTeeId={clubCourseTeeId}
            parentContext={'MODAL'}
            onClose={onClose}
            actionsContainerRef={this.actionsContainerRef}
            disableStatus={disableStatus}
            disableDescription={disableDescription}
          />
        </DialogContent>

        <div ref={this.actionsContainerRef} />
      </Dialog>
    );
  }
}

export const ClubCourseTeeModal = connect<StateProps, DispatchProps, OwnProps, StoreState>(state => ({
  clubCourseTeeState: state.clubCourseTeeReducer,
}), {
  getClubCourseTee,
})(ClubCourseTeeModalConnected);