import React, { createRef, PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as functionaryTitleActions from '@src/store/functionarytitle/actions';
import {
  Dialog,
  DialogTitle,
  DialogContent
} from '@material-ui/core';
import { ModalContentOverlayLoader } from '@src/components/modals/ui';
import { FunctionaryTitleForm } from '@src/components/scenes/federation/functionaryTitle/FunctionaryTitleForm';

type Props = {
  params: TargetIdParams;
  functionaryTitleId: number | undefined;
  open: boolean;
  onClose: () => void;
  getFunctionaryTitle: (params: FunctionaryTitleGet) => any;
  functionaryTitleState: FunctionaryTitleState;
};

class FunctionaryTitleModalConnected extends PureComponent<Props> {
  actionsContainerRef = createRef<HTMLDivElement>();

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      functionaryTitleId,
      getFunctionaryTitle
    } = this.props;

    if (nextProps.functionaryTitleId !== functionaryTitleId) {
      if (nextProps.functionaryTitleId) {
        getFunctionaryTitle({
          id: nextProps.functionaryTitleId,
        });
      }
    }
  }

  render() {
    const {
      open,
      functionaryTitleId,
      onClose,
      functionaryTitleState: {
        requesting
      },
      params
    } = this.props;

    return (
      <Dialog
        open={open}
        fullWidth={true}
      >
        <DialogTitle>
          {functionaryTitleId && <FormattedMessage id={'scenes.functionaryTitles.modal.edit.title'} />}
          {!functionaryTitleId && <FormattedMessage id={'scenes.functionaryTitles.modal.add.title'} />}
        </DialogTitle>

        <DialogContent>
          {requesting && <ModalContentOverlayLoader/>}
          <FunctionaryTitleForm
            functionaryTitleId={functionaryTitleId}
            params={params}
            parentContext={'MODAL'}
            onClose={onClose}
            actionsContainerRef={this.actionsContainerRef}
          />
        </DialogContent>

        <div ref={this.actionsContainerRef} />
      </Dialog>
    );
  }
}

const FunctionaryTitleModal = connect((state: any) => ({
  functionaryTitleState: state.functionaryTitleReducer
}), {
  getFunctionaryTitle: functionaryTitleActions.getFunctionaryTitle
})(FunctionaryTitleModalConnected);

export {
  FunctionaryTitleModal
};
