import { fork, take } from 'redux-saga/effects';
import { AUTH_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { APIRoute } from '@src/assets/config';
import {
  COMPANY_ADD_REQUESTING,
  COMPANY_DELETE_REQUESTING,
  COMPANY_EDIT_REQUESTING,
  COMPANY_GET_REQUESTING
} from '@src/store/company/constants';
import { COMPANIES_RESET } from '@src/store/companies/constants';

/*
  GET|POST|PUT|DELETE /admin/1/company/<id>
 */
const fetchCompanyCRUDFlow = SagaFlowFactory.createCRUDFlow({
  apiRoute: APIRoute.COMPANY,
  typePrefix: 'COMPANY',
  resetType: COMPANIES_RESET,
});

function * companyWatcher() {
  let token: string = '';

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
      companyId,
      params
    } = yield take([
      COMPANY_GET_REQUESTING,
      COMPANY_ADD_REQUESTING,
      COMPANY_EDIT_REQUESTING,
      COMPANY_DELETE_REQUESTING
    ]);

    switch (type) {
      case COMPANY_GET_REQUESTING:
        yield fork(fetchCompanyCRUDFlow, token, 'GET', companyId, params);
        break;
      case COMPANY_ADD_REQUESTING:
        yield fork(fetchCompanyCRUDFlow, token, 'ADD', null, params);
        break;
      case COMPANY_EDIT_REQUESTING:
        yield fork(fetchCompanyCRUDFlow, token, 'EDIT', companyId, params);
        break;
      case COMPANY_DELETE_REQUESTING:
        yield fork(fetchCompanyCRUDFlow, token, 'DELETE', companyId, params);
        break;
      default:
    }
  }
}

export default companyWatcher;