import React from 'react';
import styled from 'styled-components';
import ReactPaginate from 'react-paginate';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .position {
    display: flex;
    margin: 1.6em 0;
    font-weight: normal;
    font-size: 1.4em;
  }
  .pagination {
    display: flex;
    margin: 2em 0;

    li {
      font-weight: bold;
      display: inline-block;
      text-align: center;
      padding: 0 4px;
      box-sizing: border-box; 
      
      a {
        display: block;
        cursor: pointer;
        outline: none;
        font-size: 1.4em;
        min-width: ${2.4 / 1.4}em;
        line-height: ${2.4 / 1.4}em;
        
        &:hover {
          text-decoration: underline;
        }
      }
      
      &.current-page {
        a {
          color: #166939;
        }
      }
      
      &.disabled {
        opacity: .25;
      }
    }
  }
`;

interface Props {
  page: number;
  limit: number;
  totalCount: number;
  onPageChange: (page: number) => void;
}

class Pagination extends React.Component<Props, {}> {
  render() {
    const {
      limit,
      totalCount,
      page,
    } = this.props;

    const pageCount: number = Math.ceil(totalCount / limit);
    const rowStart: number = limit * (page - 1) + 1;
    const rowEnd: number = Math.min(totalCount, rowStart + limit - 1);

    return (
      <PaginationContainer>
        <ReactPaginate
          forcePage={page - 1}
          pageCount={pageCount}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          onPageChange={this._handleAndActToPageClick}
          containerClassName={'pagination'}
          activeClassName={'current-page'}
          previousLabel={<NavigateBefore />}
          nextLabel={<NavigateNext />}
        />
        <Typography className={'position'}>
            <FormattedMessage id={'navigation.pagination.showRows'} />: {rowStart}-{rowEnd}{' '}(
            <FormattedMessage id={'navigation.pagination.totalRows'}/>: {totalCount})
        </Typography>
      </PaginationContainer>
    );
  }

  private _handleAndActToPageClick = (data): void => {
    const { selected } = data;
    const {
      page,
      onPageChange
    } = this.props;

    let newPageNumber: number = selected + 1;

    if (page !== newPageNumber) {
      onPageChange(newPageNumber);
    }
  }
}

export {
  Pagination,
};