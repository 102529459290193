import React, { CSSProperties } from 'react';
import { Button, Card, CardContent, CardHeader, IconButton, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import ClubCourseName from '@src/components/scenes/club/course/ClubCourseName';
import { RemoveCircle } from '@material-ui/icons';
import DetachCourseChildAction, { ChildrenProps } from '@src/components/headless/DetachCourseChildAction';

interface OwnProps {
  courseId: number;
  style?: CSSProperties;
  className?: string;
  disabled?: boolean;
  parents: null | ClubCourseSibling[];
  onViewClick?(courseId: number): void;
  onChangeSuccess?(): void;
}

type Props = OwnProps;

function ClubCourseParentsCard({
  courseId,
  style,
  className,
  disabled = false,
  parents,
  onViewClick,
  onChangeSuccess,
}: Props) {
  return (
    <Card style={style} className={className}>
      <CardHeader title={<FormattedMessage id={'scenes.golfClub.course.parents.title'} />} />
      <CardContent>
        <Grid container={true} spacing={4}>
          {parents !== null && parents.map(parent => (
            <Grid item={true} key={parent.idCourse} xs={12}>
              <Grid container={true} justify={'space-between'}>
                <Grid item={true}>
                  <Typography variant="h6" gutterBottom={true}>
                    <ClubCourseName name={parent.name} type={parent.type} />
                  </Typography>
                </Grid>
                <Grid item={true}>
                  <Button
                    style={{ marginRight: 12 }}
                    color={'default'}
                    variant="contained"
                    onClick={() => {
                      if (onViewClick) {
                        onViewClick(parent.idCourse);
                      }
                    }}
                  >
                    <FormattedMessage id={'buttons.show'} />
                  </Button>
                  {!disabled && (
                    <DetachCourseChildAction parentId={parent.idCourse} childId={courseId} onSuccess={onChangeSuccess}>
                      {({ handleDetach }: ChildrenProps) => (
                        <IconButton
                          color={'secondary'}
                          onClick={handleDetach}
                        >
                          <RemoveCircle />
                        </IconButton>
                      )}
                    </DetachCourseChildAction>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default ClubCourseParentsCard;