import React, { createRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import { ModalContentOverlayLoader } from '@src/components/modals/ui';
import { ClubForm } from '@src/components/forms/ClubForm';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import { FormattedMessage } from 'react-intl';

interface Props {
  open: boolean;
  onClose: () => void;
  club: ClubState;
}

class ClubModalConnected extends React.Component<Props, {}> {
  actionsContainerRef = createRef<HTMLDivElement>();

  render() {
    const {
      open,
      onClose,
      club: {
        requesting,
      },
    } = this.props;

    return (
      <Dialog
        open={open}
        fullWidth={true}
      >
        <DialogTitle>
        <FormattedMessage id={'scenes.clubs.modal.add.title'} />
        </DialogTitle>
        <DialogContent>
          {requesting && <ModalContentOverlayLoader/>}
          <ClubForm
            parentContext={'MODAL'}
            onClose={onClose}
            actionsContainerRef={this.actionsContainerRef}
          />
        </DialogContent>

        <div ref={this.actionsContainerRef} />
      </Dialog>
    );
  }
}

const ClubModal = connect((state: any) => ({
  club: state.clubReducer,
}), {
})(ClubModalConnected);

export {
  ClubModal,
};
