import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { logoutRequest } from '@src/store/login/actions';
import { LoginPageWrapper, LogoContainer } from '@src/scenes/authentication/ui';
import {
  formCustomImageURL,
  isCustomContext,
  URL_LOGO,
  URL_LOGO_2X,
  URL_HEADER,
  URL_HEADER_2X
} from '@src/assets/config';

interface State {
  email: string;
  password: string;
}

interface StateProps {
  auth: AuthState;
}

interface DispatchProps {
  logoutRequest(onLoggedOut?: () => void): void;
}

type Props = StateProps & DispatchProps & RouteComponentProps<any>;

class LogoutRouted extends Component<Props, State> {
  componentDidMount() {
    const {
      logoutRequest,
      history,
      auth,
    } = this.props;

    if (!auth.id) {
      history.push('/login');
    } else {
      logoutRequest(() => {
        /*
        Force refresh for absolutely clean state
       */
        setTimeout(() => {
          window.location.href = '/login';
        }, 400);
      });
    }
  }

  render() {
    return (
      <LoginPageWrapper>
        <LogoContainer>
          {this._renderCustomLogo()}
        </LogoContainer>
      </LoginPageWrapper>
    );
  }

  /**
   * This function renders GGB logo or custom logo depending are we
   * in GGB or customized context.
   *
   * Custom logo dimensions are 130x130.
   * GGB Office header is 265x65.
   *
   * @private
   */
  private _renderCustomLogo = () => {
    if (isCustomContext) {
      return (
          <img
              src={formCustomImageURL(URL_LOGO)}
              srcSet={`${formCustomImageURL(URL_LOGO_2X)} 2x`}
              width={130}
              height={130}
          />
      );
    }
    return (
        <img
            src={formCustomImageURL(URL_HEADER)}
            srcSet={`${formCustomImageURL(URL_HEADER_2X)} 2x`}
            // width={265}
            height={65}
        />
    );
  }
}

export const Logout = connect<StateProps, DispatchProps, {}, StoreState>(state => ({
  auth: state.authReducer
}), {
  logoutRequest
})(withRouter(LogoutRouted));