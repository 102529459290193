import React from 'react';
import { connect } from 'react-redux';
import EditPersonForm from '@src/components/persons/EditPersonForm';
import { Theme, withStyles } from '@material-ui/core';
import { Classes } from 'jss';

type Props = {
  classes: Classes;
};

class CreatePerson extends React.Component<Props, {}> {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.wrapper}>
        <EditPersonForm onCreate={this._onPersonCreated}/>
      </div>
    );
  }

  private _onPersonCreated = (params: APICallResult) => {
    window.console.log(params);
  }
}

const styles = (theme: Theme) => ({
  wrapper: {
    maxWidth: '600px',
    margin: '0 auto',
  },
});

export default withStyles(styles)(connect(null, null)(CreatePerson));