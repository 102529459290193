import React, { PureComponent, ChangeEvent, RefObject } from 'react';
import { FormattedMessage, WrappedComponentProps , injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import * as vendorActions from '@src/store/vendor/actions';
import {
  TextField,
  Typography,
  Divider,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import { renderFormActions } from '@src/components/modals/ui';
import { DeleteConfirmChildren } from '@src/components/headless/DeleteConfirm';
import DeleteConfirm from '@src/components/headless/DeleteConfirm';
import { ClubSelect } from '@src/components/forms/ClubSelect';
import InputsValidator from '@src/components/forms/InputsValidator';
import AddressFields from '@src/components/forms/ui/AddressFields';
import { Button } from '@material-ui/core';
import { confirm } from '@src/components/modals/Confirm';
import { handleNewAPIKeyGeneration } from '@src/store/vendor/fetchActions';
import { ContentLoader } from '@src/components/layouts/ui';

interface Props extends WrappedComponentProps {
  vendorId?: number | undefined;
  vendorState: VendorState;
  parentContext?: 'MODAL' | 'PAGE';
  onClose?: () => void;
  addVendor: (params: VendorAdd) => any;
  editVendor: (params: VendorEdit) => any;
  deleteVendor: (params: VendorDelete) => any;
  refetch?: () => any;
  actionsContainerRef?: RefObject<HTMLElement>;
}

type State = {
  name: string;
  streetAddress: string;
  zip: string | null;
  city: string | null;
  fax: string | null;
  phone: string | null;
  email: string | null;
  username: string;
  passwordMismatch: boolean;
  clubCount: number;
  hasApiKey: boolean;
  generatingNewAPIKey: boolean;
  scope: string;
  clubs: Array<Club>;
};

const initialState = {
  name: '',
  streetAddress: '',
  zip: null,
  fax: null,
  city: null,
  phone: null,
  email: null,
  username: '',
  passwordMismatch: false,
  clubCount: 0,
  hasApiKey: false,
  generatingNewAPIKey: false,
  scope: '',
  clubs: [],
};

class VendorFormConnected extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const {
      vendorId,
      vendorState: {
        vendor,
      },
    } = this.props;

    if (!vendorId) {
      this.state = initialState;
    } else if (vendor) {
      this.state = {
        ...vendor,
      } as any;
    } else {
      this.state = initialState;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      vendorId,
      vendorState: {
        vendor,
        requesting,
      },
    } = nextProps;

    if (!vendorId) {
      return; // Do nothing if we are adding new person
    } else if (requesting) {
      return; // Do nothing state is loading
    } else if (vendor) {
      this.setState({
        ...vendor as any
      });
    }
  }

  render() {
    const {
      username,
      name,
      streetAddress,
      zip,
      city,
      phone,
      fax,
      clubCount,
      hasApiKey,
      generatingNewAPIKey,
      scope,
     clubs,
    } = this.state;

    const {
      vendorId,
      parentContext,
      actionsContainerRef,
    } = this.props;

    return (
      <InputsValidator values={{ username, name, streetAddress, scope }}>
        {({ errorInputs, validateThenApply, validateValues }) => (
          <>
            <TextField
              required={true}
              error={errorInputs.name}
              fullWidth={true}
              label={<FormattedMessage id={'strings.name'}/>}
              value={name || ''}
              margin="normal"
              onChange={this._handleChange('name', validateValues)}
            />

            <AddressFields
              streetAddress={streetAddress || ''}
              city={city || ''}
              zip={zip || ''}
              errorInputs={errorInputs}
              onChange={(args) => this.setState({ ...args } as any, validateValues)}
              requiredInputs={{
                streetAddress: true,
              }}
            />

            <TextField
              fullWidth={true}
              label={<FormattedMessage id={'strings.phone'}/>}
              value={phone || ''}
              type={'tel'}
              margin="normal"
              onChange={this._handleChange('phone')}
            />

            <TextField
              fullWidth={true}
              label={<FormattedMessage id={'strings.fax'}/>}
              value={fax || ''}
              margin="normal"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                this.setState({fax: event.currentTarget.value});
              }}
            />

            <Divider style={{ marginTop: '4.4em' }} />

            <TextField
              required={true}
              error={errorInputs.username}
              fullWidth={true}
              label={<FormattedMessage id={'scenes.golfFederation.vendors.form.username'}/>}
              value={username || ''}
              margin="normal"
              onChange={this._handleChange('username', validateValues)}
            />

            <ClubSelect
              margin={'normal'}
              multi={true}
              selectedClubs={clubs}
              onChangeCallback={(values: any) => {
                this.setState({clubs: values});
              }}
            />

            <FormControl fullWidth={true} required={true}>
              <InputLabel htmlFor="scope-picker">
                <FormattedMessage id={'scenes.golfFederation.vendors.form.scope'} />
              </InputLabel>
              <Select
                required={true}
                error={errorInputs.scope}
                value={scope || ''}
                onChange={this._handleChange('scope', validateValues)}
                inputProps={{ id: 'scope-picker' }}
              >
                <MenuItem value="SOAP">SOAP</MenuItem>
                <MenuItem value="EXTERNAL">EXTERNAL</MenuItem>
                <MenuItem value="ANALYTICS">ANALYTICS</MenuItem>
              </Select>
            </FormControl>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <TextField
                label={<FormattedMessage id={'scenes.golfFederation.vendors.form.password'}/>}
                value={hasApiKey ? '************' : '-'}
                margin="normal"
                disabled={true}
                style={{ marginRight: '2em' }}
              />
              <div style={{ alignSelf: 'center' }}>
                {!generatingNewAPIKey ? (
                  <Button onClick={this._handleGenerateAPIKey} disabled={!vendorId}>
                    <FormattedMessage id={'scenes.golfFederation.vendors.form.generateNewAPIKey'}/>
                  </Button>
                ) : (
                  <ContentLoader visible={true} />
                )}
              </div>
            </div>

            { vendorId && (
              <Typography style={{ marginTop: '2em' }}>
                <FormattedMessage id={'scenes.golfFederation.vendors.form.passwordAlert'}/>
              </Typography>
            )}

            <DeleteConfirm>
              {({ showConfirm }: DeleteConfirmChildren) => (
                renderFormActions({
                  id: vendorId,
                  parentContext,
                  onClose: this._handleOnClose,
                  onUpdate: validateThenApply.bind(this, this._handleUpdateVendor),
                  onCreate: validateThenApply.bind(this, this._handleCreateVendor),
                  disabled: false,
                  containerRef: actionsContainerRef,
                  onDelete: clubCount === 0 ? () => {
                    showConfirm({
                      callback: this._handleDeleteVendor,
                    });
                  } : undefined,
                })
              )}
            </DeleteConfirm>

          </>
        )}
      </InputsValidator>
    );
  }

  private _handleOnClose = (): void => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  }

  private _handleDeleteVendor = (bool: boolean) => {
    const {
      deleteVendor,
      vendorId,
    } = this.props;

    if (bool && vendorId) {
      deleteVendor({
        id: vendorId,
        onComplete: this._handleOnClose,
      });
    }
  }

  private _handleCreateVendor = () => {
    if (!this._areInputsValid()) {
      return;
    }

    const { addVendor } = this.props;
    const params = this._formVendorParams();

    addVendor({
      ...params,
      onComplete: this._handleAfterCreate,
    });
  }

  private _handleUpdateVendor = () => {
    if (!this._areInputsValid()) {
      return;
    }

    const { editVendor } = this.props;
    const {
      vendorState: {
        vendor,
      },
    } = this.props;

    if (!vendor) {
      return;
    }

    const params = this._formVendorParams();

    editVendor({
      id: vendor.id,
      ...params,
      onComplete: this._handleOnComplete,
    });
  }

  private _formVendorParams = () => {
    const {
      name,
      streetAddress,
      zip,
      email,
      phone,
      fax,
      city,
      username,
      scope,
      clubs,
    } = this.state;

    return {
      name,
      streetAddress,
      zip,
      fax,
      phone,
      email,
      username,
      city,
      scope,
      clubIds: clubs.length ? clubs.map(club => club.id) : [0]
    };
  }

  private _handleOnComplete = ({ error }: APICallResult): void => {
    if (error) {
      window.alert(error.message);
    } else {
      this._handleOnClose();
    }
  }

  private _handleAfterCreate = async ({ data , error }: APICallResult) => {
    if (error) {
      return window.alert(error.message);
    }

    const { id } = data;
    this.setState({ generatingNewAPIKey: true }, () => {
      return this._handleNewAPIKeyGeneration(id, () => {
        this.setState({ generatingNewAPIKey: false }, this._handleOnClose);
      });
    });
  }

  private _handleChange = (name: string, fn?: any) => (
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => {
      this.setState({ [name as any]: event.target.value } as any, fn);
    }
  )

  private _areInputsValid = (): boolean => {
    const {
      username,
      name,
      streetAddress,
      scope
    } = this.state;

    if (!username || !name || !streetAddress || !scope) {
      return false;
    }

    return true;
  }

  private _handleGenerateAPIKey = () => {
    const { vendorId, intl } = this.props;

    if (vendorId) {
      confirm({
        message: intl.formatMessage({
          id: 'scenes.golfFederation.vendors.modal.generateAPIKey.confirmText',
        }),
        options: {
          cancelText: intl.formatMessage({ id: 'buttons.cancel' }),
          okText: intl.formatMessage({ id: 'buttons.ok' }),
        }
      }).then((result: any) => {
        if (result) {
          this.setState(
            {
              generatingNewAPIKey: true
            },
            this._handleNewAPIKeyGeneration.bind(
              this,
              vendorId,
              this.setState.bind(this, { generatingNewAPIKey: false }),
            ),
          );
        }
      }, () => {
        window.console.log('cancelled');
      });
    }
  }

  private _handleNewAPIKeyGeneration = async (vendorId: number, fn?: () => any) => {
    return await handleNewAPIKeyGeneration({ vendorId })
      .then(this._showNewAPIKey)
      .catch(() => {
        window.alert('Could not generate new API key');
      })
      .finally(fn);
  }

  private _showNewAPIKey = async (data?: { apiKey: string }) => {
    const { intl } = this.props;
    if (data) {
      const { apiKey } = data;
      return confirm({
        messageHTML: `${intl.formatMessage({
          id: 'scenes.golfFederation.vendors.modal.generateAPIKey.success',
        })}<br/><br/><b>${apiKey}</b>`,
        options: {
          cancelText: '',
          okText: intl.formatMessage({ id: 'buttons.ok' }),
        },
        maxWidth: 'sm',
      });
    } else {
      window.alert('Could not generate new API key');
    }
  }
}

const VendorForm = injectIntl(connect((state: any) => ({
  vendorState: state.vendorReducer,
}), {
  addVendor: vendorActions.addVendor,
  editVendor: vendorActions.editVendor,
  deleteVendor: vendorActions.deleteVendor,
})(VendorFormConnected));

export {
  VendorForm
};
