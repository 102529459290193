import React from 'react';
import { connect } from 'react-redux';
import { FormControl, MenuItem, PropTypes, Select, Typography } from '@material-ui/core';
import { getBackNineCourses, getFrontNineCourses } from '@src/store/clubCourses/selectors';
import { get, find } from 'lodash';
import { FormattedMessage } from 'react-intl';

interface OwnProps {
  value?: number | string;
  courseType: 'FRONT' | 'BACK';
  margin?: PropTypes.Margin;
  disabled?: boolean;
  readOnly?: boolean;
  onChange?(courseId: number | string): void;
}

interface StateProps {
  condensedCourseClubs: CondensedClubCourse[];
  frontNineCourses: CondensedClubCourse[];
  backNineCourses: CondensedClubCourse[];
}

type Props = OwnProps & StateProps;

function ClubCoursePartialSelect({
  value = 'new',
  courseType,
  frontNineCourses,
  backNineCourses,
  margin = 'normal',
  disabled = false,
  readOnly = false,
  onChange,
}: Props) {
  const courses = courseType === 'FRONT' ? frontNineCourses : backNineCourses;

  const renderOptions = (options: CondensedClubCourse[]) => (
    options.map(option => (
      <MenuItem value={option.id} key={option.id}>{option.name}</MenuItem>
    ))
  );

  if (readOnly) {
    const name = get(find(courses, { 'idCourse': value }), 'name', `Missing id:${value}?`);
    return (
      <Typography variant="h6" gutterBottom={true}>
        {name || '-'}
      </Typography>
    );
  }

  return (
    <FormControl margin={margin}  disabled={disabled} fullWidth={true}>
      <Select
        disableUnderline={disabled}
        value={value}
        onChange={e => {
          const { target: { value } } = e;
          if (onChange) {
            onChange(value as string | number);
          }
        }}
      >
        <MenuItem value={'new'}><FormattedMessage id={'strings.createNew'} /></MenuItem>
        {renderOptions(courses)}
      </Select>
    </FormControl>
  );
}

export default connect<StateProps, {}, OwnProps, StoreState>(state => ({
  condensedCourseClubs: state.clubCoursesReducer.condensedCourseClubs,
  frontNineCourses: getFrontNineCourses(state),
  backNineCourses: getBackNineCourses(state),
}))(ClubCoursePartialSelect);