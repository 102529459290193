import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import * as personActions from '@src/store/person/actions';
import {
  Checkbox,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { ModalContentOverlayLoader, renderFormActions } from '@src/components/modals/ui';
import InputsValidator from '@src/components/forms/InputsValidator';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import StateSelect from '@src/components/forms/StateSelect';
import { updateOptionValues } from '@src/utils/storeUtils';

interface Props extends WrappedComponentProps {
  onClose?: () => void;
  open: boolean;
  personState: PersonState;
  editPerson: (params: PersonEdit) => any;
}

type State = {
  clubName: string;
  isLoading: boolean;
  personStatus: ActivityStatus;
  playerStatus: PlayerStatus;
  hcp: number;
  gender: Gender;
  nameFirst: string;
  nameMiddle: string;
  nameLast: string;
  birthDate?: Date | string | null;
  phone: string;
  email: string;
  streetAddress: string;
  city?: string;
  zip?: string;
  privacy?: string;
  advertising?: Array<ContactMethods>;
  contact?: Array<ContactMethods>;
  timeUpdated: Date | string | null;
  vendorUpdated: Date | string | null;
  nguCard?: NGUCard;
  handicapNotifications?: boolean;
};

const initialState: State = {
  clubName: '',
  isLoading: false,
  personStatus: 'ACTIVE',
  playerStatus: 'AM',
  hcp: 54,
  gender: 'NONE',
  nameFirst: '',
  nameMiddle: '',
  nameLast: '',
  birthDate: null,
  phone: '',
  email: '',
  streetAddress: '',
  city: '',
  zip: '',
  privacy: '',
  advertising: [],
  contact: [],
  timeUpdated: '',
  vendorUpdated: '',
  nguCard: ['NO'],
  handicapNotifications: true,
};

class PersonInfoModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const {
      personState: {
        person,
      },
    } = this.props;

    if (person) {
      this.state = {
        isLoading: false,
        ...this._parseAttributes(person),
      } as any;
    } else {
      this.state = initialState;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      personState: {
        requesting,
        person,
      },
      open,
    } = nextProps;

    if (requesting || open) {
      return; // Do nothing state is loading
    } else if (person) {
      this.setState(this._parseAttributes(person));
    }
  }

  render(): React.ReactNode {
    const {
      open,
    } = this.props;
    const {
      clubName,
      isLoading,
      personStatus,
      playerStatus,
      hcp,
      gender,
      nameFirst,
      nameMiddle,
      nameLast,
      birthDate,
      phone,
      email,
      streetAddress,
      advertising,
      contact,
      timeUpdated,
      vendorUpdated,
      // nguCard,
      privacy,
      handicapNotifications,
    } = this.state;

    return (
      <Dialog open={open}>
        <DialogTitle>
          <FormattedMessage id={'navigation.golfClubInfo'} />
        </DialogTitle>

        {isLoading && <ModalContentOverlayLoader/>}
        <InputsValidator values={{ personStatus, playerStatus }}>
          {({ errorInputs, validateThenApply, validateValues }) => (
            <>
              <DialogContent>

                <StateSelect
                  error={errorInputs.personStatus}
                  selectedState={personStatus}
                  disabled={true}
                  onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                    this.setState({ personStatus: event.target.value as ActivityStatus }, validateValues);
                  }}
                  formControlProps={{
                    required: true,
                    margin: 'normal',
                    fullWidth: true,
                  }}
                />

                <FormControl
                  required={true}
                  margin={'normal'}
                  fullWidth={true}
                  error={errorInputs.playerStatus}
                  disabled={true}
                >
                  <InputLabel htmlFor="organization-state">
                    <FormattedMessage id={'strings.playerStatus'} />
                  </InputLabel>
                  <Select
                    value={playerStatus}
                    inputProps={{
                      id: 'player-status',
                    }}
                    onChange={(event) => {
                      this.setState({
                        playerStatus: event.target.value as PlayerStatus
                      }, validateValues);
                    }}
                  >
                    <MenuItem value={'AM'} selected={playerStatus === 'AM' as any}>
                      AM
                    </MenuItem>
                    <MenuItem value={'NON_PRO'} selected={playerStatus === 'NON_PRO' as any}>
                      NON_PRO
                    </MenuItem>
                    <MenuItem value={'PRO'} selected={playerStatus === 'PRO' as any}>
                      PRO
                    </MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  margin={'normal'}
                  fullWidth={true}
                  label={<FormattedMessage id={'strings.homeClub'} />}
                  value={clubName}
                  disabled={true}
                />

                <TextField
                  margin={'normal'}
                  fullWidth={true}
                  label={<FormattedMessage id={'strings.hcp'} />}
                  value={hcp}
                  disabled={true}
                />

                <FormControl fullWidth={true} disabled={true}>
                  <InputLabel htmlFor="gender">
                    <FormattedMessage id={'strings.gender'}/>
                  </InputLabel>
                  <Select
                    inputProps={{
                      name: 'gender',
                      id: 'gender',
                    }}
                    value={gender}
                    onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                      this.setState({ gender: event.target.value as Gender });
                    }}
                  >
                    <MenuItem value={'MALE'}>MALE</MenuItem>
                    <MenuItem value={'FEMALE'}>FEMALE</MenuItem>
                    <MenuItem value={'NONE'}>NONE</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  margin={'normal'}
                  fullWidth={true}
                  label={<FormattedMessage id={'strings.firstName'} />}
                  value={nameFirst}
                  disabled={true}
                />

                <TextField
                  margin={'normal'}
                  fullWidth={true}
                  label={<FormattedMessage id={'strings.middleName'} />}
                  value={nameMiddle}
                  disabled={true}
                />

                <TextField
                  margin={'normal'}
                  fullWidth={true}
                  label={<FormattedMessage id={'strings.lastName'} />}
                  value={nameLast}
                  disabled={true}
                />

                <TextField
                  margin={'normal'}
                  fullWidth={true}
                  disabled={true}
                  label={<FormattedMessage id={'strings.dateOfBirth'} />}
                  defaultValue={this._formatDateString(birthDate)}
                />

                <TextField
                  margin={'normal'}
                  fullWidth={true}
                  label={<FormattedMessage id={'strings.phone'} />}
                  value={phone}
                  disabled={true}
                />

                <TextField
                  margin={'normal'}
                  fullWidth={true}
                  label={<FormattedMessage id={'strings.email'} />}
                  value={email}
                  disabled={true}
                />

                <TextField
                  margin={'normal'}
                  fullWidth={true}
                  label={<FormattedMessage id={'strings.address'} />}
                  value={streetAddress}
                  disabled={true}
                />

                <InputLabel style={{ marginTop: '16px', display: 'block' }}>
                  <FormattedMessage id={'strings.preferences'}/>
                </InputLabel>
                <FormGroup row={true}>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={privacy === 'PUBLIC'}
                        disabled={true}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          const {
                            target: {
                              checked
                            }
                          } = event;
                          this.setState({ privacy: checked ? 'PUBLIC' : 'PRIVATE'});
                        }}
                      />
                    }
                    label={<FormattedMessage id={'userPreferences.publicInfo'}/>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this._checkCheckboxState(contact, 'POST')}
                        onChange={this._handleContactRulesChange('POST')}
                        disabled={true}
                      />
                    }
                    label={<FormattedMessage id={'userPreferences.acceptPostFromUnion'}/>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this._checkCheckboxState(contact, 'PHONE')}
                        onChange={this._handleContactRulesChange('PHONE')}
                        disabled={true}
                      />
                    }
                    label={<FormattedMessage id={'userPreferences.acceptSMSFromUnion'}/>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this._checkCheckboxState(contact, 'EMAIL')}
                        onChange={this._handleContactRulesChange('EMAIL')}
                        disabled={true}
                      />
                    }
                    label={<FormattedMessage id={'userPreferences.acceptEmailFromUnion'}/>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this._checkCheckboxState(advertising, 'POST')}
                        onChange={this._handleAdvertisingRulesChange('POST')}
                        disabled={true}
                      />
                    }
                    label={<FormattedMessage id={'userPreferences.acceptMarketingPost'}/>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this._checkCheckboxState(advertising, 'PHONE')}
                        onChange={this._handleAdvertisingRulesChange('PHONE')}
                        disabled={true}
                      />
                    }
                    label={<FormattedMessage id={'userPreferences.acceptMarketingPhone'}/>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this._checkCheckboxState(advertising, 'EMAIL')}
                        onChange={this._handleAdvertisingRulesChange('EMAIL')}
                        disabled={true}
                      />
                    }
                    label={<FormattedMessage id={'userPreferences.acceptMarketingEmail'}/>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={handicapNotifications}
                        value="handicapNotifications"
                        disabled={true}
                      />
                    }
                    label={<FormattedMessage id={'userPreferences.handicapNotifications'}/>}
                  />

                </FormGroup>

                <TextField
                  margin={'normal'}
                  fullWidth={true}
                  disabled={true}
                  label={<FormattedMessage id={'strings.vendorLastUpdate'} />}
                  defaultValue={this._formatDateString(vendorUpdated)}
                />

                <TextField
                  margin={'normal'}
                  fullWidth={true}
                  disabled={true}
                  label={<FormattedMessage id={'strings.latestUpdate'} />}
                  defaultValue={this._formatDateString(timeUpdated)}
                />
              </DialogContent>

              {renderFormActions({
                id: 1,
                parentContext: 'MODAL',
                onClose: this._handleOnClose,
                // onUpdate: validateThenApply.bind(this, this._handleUpdate),
                disabled: false,
              })}
            </>
          )}
        </InputsValidator>
      </Dialog>
    );
  }
  /*
    private _handleUpdate = () => {
      return this._handleAfterUpdate({ error: undefined });

      const {
        editClubBasicInfo,
        clubState: {
          courseGuide,
        },
      } = this.props;

      this.setState({ isLoading: true }, () => {
        const {
          name,
          nameCompany,
          abbreviation,
          yearFoundation,
          email,
          phone,
          homepage,
          fax,
          streetAddress,
          zip,
          city,
          drivingInstructions,
          otherInfo,
        } = this.state;

        editClubBasicInfo({
          clubId: courseGuide!.club.id,
          params: {
            name,
            nameCompany,
            abbreviation,
            yearFoundation: `${yearFoundation}`,
            email,
            phone,
            homepage,
            fax,
            streetAddress,
            zip,
            city,
            drivingInstructions,
            otherInfo,
          },
          onComplete: this._handleAfterUpdate,
        });
      });
  }

  private _handleAfterUpdate = ({ error }) => {
    this.setState({ isLoading: false }, () => {
      if (error) {
        return window.alert(error);
      }

      this._handleOnClose();
    });
  }
  */

  private _handleOnClose = () => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  }

  private _parseAttributes = (person: Person) => {
    const {
      personStatus,
      playerStatus,
      hcp,
      gender,
      nameFirst,
      nameMiddle = '',
      nameLast,
      birthDate,
      phone = '',
      email = '',
      streetAddress,
      city = '',
      zip = '',
      advertising,
      contact,
      timeUpdated,
      vendorUpdated,
      nguCard,
      privacy = '',
      club: {
        name,
      },
      handicapNotifications,
    } = person;

    return {
      clubName: name,
      personStatus,
      playerStatus,
      hcp,
      gender,
      nameFirst,
      nameMiddle,
      nameLast,
      birthDate,
      phone,
      email,
      streetAddress,
      city,
      zip,
      advertising,
      contact,
      timeUpdated,
      vendorUpdated,
      nguCard,
      privacy,
      handicapNotifications,
    };
  }

  /*
  private _handleCheckboxChange = (name: string, fn?: () => any) => (
    (event: ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [name as any]: event.target.checked,
      } as any, fn);
    }
  )

  private _handleChange = (name: string, fn?: () => any) => (
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      this.setState({
        [name as any]: event.target.value,
      } as any, fn);
    }
  )
  */

  private _checkCheckboxState = (group: Array<string> | any, value: string): boolean => {
    return group && group.indexOf(value) !== -1;
  }

  private _handleContactRulesChange = name => event => {
    const { contact } = this.state;
    this.setState({
      contact: updateOptionValues([...contact as any], name, event.target.checked)
    } as any);
  }

  private _handleAdvertisingRulesChange = name => event => {
    const { advertising } = this.state;
    this.setState({
      advertising: updateOptionValues([...advertising as any], name, event.target.checked)
    } as any);
  }

  private _formatDateString = (str?: Date | string | null) => {
    if (str === null || !str) {
      return '';
    }

    return this.props.intl.formatDate(str);
  }
}

export default injectIntl(connect((state: StoreState) => ({
  personState: state.personReducer,
}), {
  editPerson: personActions.editPerson,
})(PersonInfoModal));
