import React, { PureComponent, ChangeEvent, RefObject } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import {
  TextField
} from '@material-ui/core';
import { renderFormActions } from '@src/components/modals/ui';
import { connect } from 'react-redux';
import * as clubActions from '@src/store/club/actions';
import InputsValidator from '@src/components/forms/InputsValidator';

interface Props extends RouteComponentProps<any> {
    club: ClubState;
    parentContext?: 'MODAL' | 'PAGE';
    onClose?: () => void;
    addClub: (params: ClubAdd) => any;
    actionsContainerRef?: RefObject<HTMLElement>;
}
interface State {
    name: string;
    abbreviation: string;
  }

const initialState: State = {
    name: '',
    abbreviation: '',
};
    
class ClubFormConnected extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        const {
        } = this.props;
        this.state = initialState;
    }
    render() {
        const {
           parentContext,
           actionsContainerRef,
        } = this.props;
        
        const {
            name,
            abbreviation
          } = this.state;
    
        return (
            <InputsValidator values={{name, abbreviation}}>
              {({errorInputs, validateThenApply, validateValues}) => (
              <>
                <TextField
                    error={errorInputs.name}
                    required={true}
                    margin={'normal'}
                    fullWidth={true}
                    label={<FormattedMessage id={'scenes.clubs.form.name'}/>}
                    value={name}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const {
                        currentTarget: {
                          value
                        }
                      } = e;
                      this.setState({name: value}, validateValues);
                    }}
                /><br />
                <TextField
                    error={errorInputs.abbreviation}
                    required={true}
                    margin={'normal'}
                    fullWidth={true}
                    label={<FormattedMessage id={'scenes.clubs.form.abbreviation'}/>}
                    value={abbreviation}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const {
                        currentTarget: {
                          value
                        }
                      } = e;
                      this.setState({abbreviation: value}, validateValues);
                    }}
                />               
                {renderFormActions({
                  parentContext,
                  onClose: this._handleOnClose,
                  onCreate: validateThenApply.bind(this, this._handleCreate),
                  containerRef: actionsContainerRef,
                })}

              </>
          )}
           </InputsValidator>
    );
}

private _handleOnClose = () => {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
}
  private _handleCreate = () => {
    const { history, addClub } = this.props;
    const params = this._formClubParams();

    addClub({
      ...params,
      onComplete: res => this._handleOnComplete(res, history)
    });
}
  private _formClubParams = () => {
    const { name, abbreviation} = this.state;

    return {
      name,
      abbreviation
    };
}

private _handleOnComplete = (result: any, history: any) => {
    if (result.error) {
      window.alert(result.error.message);
      return;
    } 
    this._handleOnClose();
    history.push(`/admin/clubs/${result.data.idClub}`);
  }
}

const ClubForm = withRouter(connect((state: any) => ({
    club: state.clubReducer,
  }), {
    addClub : clubActions.addClub,
  })(ClubFormConnected));
  
export {
    ClubForm
};