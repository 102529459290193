import {
  MESSAGES_REQUESTING,
  MESSAGES_CHANGE_PAGE,
  MESSAGES_RESET,
  MESSAGES_SET_INITIAL_STATE,
  MESSAGES_SEARCH_CHANGED,
  MESSAGES_SEARCH_REQUESTING,
  MESSAGES_FETCH_ALL_SENDERS,
  MESSAGES_GET_RECIPIENTS_REQUESTING
} from './constants';
import { DEFAULT_PAGINATION_LIMIT } from '@src/assets/config';

export const fetchMessages = ({
  page,
  limit = DEFAULT_PAGINATION_LIMIT
}: TablePaginationAction) => ({
  type: MESSAGES_REQUESTING,
  page,
  limit
});

export const changePage = ({
  page
}: ChangePageAction) => ({
  type: MESSAGES_CHANGE_PAGE,
  page,
});

export const searchChanged = (search: MessagesSearch) => ({
  type: MESSAGES_SEARCH_CHANGED,
  search,
});

export const fetchSearch = ({
  page,
  limit = DEFAULT_PAGINATION_LIMIT,
  params
}: SearchPaginationAction) => ({
  type: MESSAGES_SEARCH_REQUESTING,
  page,
  limit,
  params,
});

export const resetSearch = () => ({
  type: MESSAGES_RESET,
});

export const setMessagesInitialState = () => ({
  type: MESSAGES_SET_INITIAL_STATE,
});

export const fetchAllSenders = () => ({
  type: MESSAGES_FETCH_ALL_SENDERS,
});

export const fetchAllRecipients = ({params, onComplete}: MessageRecipients) => ({
  type: MESSAGES_GET_RECIPIENTS_REQUESTING,
  onComplete,
  params: params
});