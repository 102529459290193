import { take, fork, cancel, call, all, takeEvery, put, select } from 'redux-saga/effects';
import {
  LOGIN_REQUESTING,
  LOGIN_ERROR,
  LOGIN_CHECK_REQUESTING,
  LOGOUT_REQUESTED,
  SILENT_LOGIN_CHECK_REQUESTING,
  MAINTENANCES_CURRENT_REQUESTING,
  MAINTENANCES_CURRENT_SUCCESS,
  MAINTENANCES_CURRENT_ERROR,
} from './constants';
import { loginFlow } from '@src/store/login/flows/login-flow';
import { loginCheckFlow } from '@src/store/login/flows/login-check-flow';
import { silentLoginCheckFlow } from '@src/store/login/flows/silent-login-check-flow';
import { AUTH_UNSET } from '@src/store/auth/constants';
import { unsetPersistedAuthToken, unsetPersistedRedirectRoute } from '@src/utils/authUtils';
import { logoutAPICall } from '@src/store/login/api/logout-api';
import { unsetAuth } from '@src/store/auth/actions';
import { userAuthToken } from '@src/store/auth/selectors';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { API_ROOT, APIRoute } from '@src/assets/config';

export function* loginCheckWatcher() {
  const { token } = yield take(LOGIN_CHECK_REQUESTING);
  const task = yield fork(loginCheckFlow, token);
  const action = yield take([LOGOUT_REQUESTED, LOGIN_ERROR]);

  if (action.type === LOGIN_ERROR) {
    yield cancel(task);
  }

  if (action.type === LOGOUT_REQUESTED) {
    call(handleLogout, action);
  }
}

export function* silentLoginWatcher() {
  while (true) {
    const {
      type,
      token,
      redirect,
    } = yield take([
      SILENT_LOGIN_CHECK_REQUESTING
    ]);

    switch (type) {
      case SILENT_LOGIN_CHECK_REQUESTING: {
        yield fork(silentLoginCheckFlow, token, redirect);
        break;
      }

      default:
    }
  }
}

export function* authUnsetWatcher() {
  while (true) {
    yield take(AUTH_UNSET);
    unsetPersistedAuthToken();
    unsetPersistedRedirectRoute();
  }
}

export default function* loginWatcher() {
  while (true) {
    const {
      email,
      password,
    } = yield take(LOGIN_REQUESTING);

    const task = yield fork(loginFlow, email, password);
    const action = yield take([LOGOUT_REQUESTED, LOGIN_ERROR]);

    if (action.type === LOGOUT_REQUESTED) {
      yield cancel(task);
      yield call(handleLogout, action);
    }
  }
}

function* handleLogout({
  onLoggedOut,
}: { type: 'LOGOUT_REQUESTED', onLoggedOut?: () => void }) {
  const token = yield select(userAuthToken);
  try {
    yield call(logoutAPICall, token);
  } catch (error) {
    console.log(error);
  }

  if (onLoggedOut) {
    onLoggedOut();
  }

  yield put(unsetAuth());
}

export function* logoutWatcher() {
  yield all([
    takeEvery(LOGOUT_REQUESTED, handleLogout),
  ]);
}

export function* currentMaintenancesWatcher() {
  yield all([
     takeEvery(MAINTENANCES_CURRENT_REQUESTING, handleMaintenances)
  ]);
}

function* handleMaintenances() {
  console.log('here 2');
  const fetchCurrentMaintenances = SagaFlowFactory.createGETFlow({
    urlRoute: `${API_ROOT}${APIRoute.PUBLIC_MAINTENANCES}`,
    successType: MAINTENANCES_CURRENT_SUCCESS,
    errorType: MAINTENANCES_CURRENT_ERROR,
  });

  yield fork(fetchCurrentMaintenances, '', 0, 0, {type: 'current'});
}