import {
  CLUBS_REQUESTING,
  CLUBS_SUCCESS,
  CLUBS_ERROR,
  CLUBS_CHANGE_PAGE,
  CLUBS_SEARCH_REQUESTING,
  CLUBS_SEARCH_CHANGED,
  CLUBS_SEARCH_RESET,
  CLUBS_CONDENSED_SUCCESS,
} from './constants';
import { SHARED_DEFAULT_REDUCER_STATE } from '@src/assets/config';
import {
  formDefaultErrorState,
  formDefaultPagedState,
  formDefaultRequestingState, fromDefaultPageChangeState,
  optimisticPagedObjectUpdate,
  searchStateChangedReducerHelper
} from '@src/utils/storeUtils';
import { CLUB_ACTIVATION_SUCCESS, CLUB_VALIDATION_SUCCESS } from '@src/store/club/constants';

const initialState: ClubsState = {
  ...SHARED_DEFAULT_REDUCER_STATE,
  pagedClubs: {},
  search: {
    searchTerm: '',
    city: '',
    clubStatus: 'ACTIVE',
    clubValidity: 'VALID',
  },
  allClubs: [],
};

const clubsReducer = (state: ClubsState = initialState, action: any): ClubsState => {
  switch (action.type) {
    case CLUBS_REQUESTING:
      return formDefaultRequestingState(state);

    case CLUBS_SUCCESS:
      return formDefaultPagedState(state, action, 'pagedClubs');

    case CLUBS_CHANGE_PAGE:
      return fromDefaultPageChangeState(state, action);

    case CLUBS_ERROR:
      return formDefaultErrorState(state, action);

    case CLUBS_SEARCH_CHANGED: {
      return searchStateChangedReducerHelper(state, action);
    }

    case CLUBS_SEARCH_REQUESTING: {
      let searchActive = state.searchActive;
      let pagedClubs = state.pagedClubs;
      let pagination = state.pagination;

      if (!searchActive) {
        searchActive = true;
        pagedClubs = {};
        pagination.totalCount = 0;
      }

      pagination.page = action.page;

      return {
        ...state,
        pagedClubs,
        pagination,
        searchActive,
        requesting: true
      };
    }

    case CLUBS_SEARCH_RESET:
      return {
        ...initialState
      };

    case CLUB_ACTIVATION_SUCCESS: {
      const {
        id,
        status,
      } = action;
      const page = state.pagination.page;

      const pagedClubs = optimisticPagedObjectUpdate(state.pagedClubs, page, { id, status });

      return {
        ...state,
        pagedClubs
      };
    }

    case CLUB_VALIDATION_SUCCESS: {
      const {
        id,
        validTo,
      } = action;
      const page = state.pagination.page;

      const pagedClubs = optimisticPagedObjectUpdate(state.pagedClubs, page, { id, validTo });

      return {
        ...state,
        pagedClubs
      };
    }

    case CLUBS_CONDENSED_SUCCESS: {
      return {
        ...state,
        allClubs: action.data.clubs,
      };
    }

    default:
      return state;
  }
};

export {
  clubsReducer,
};