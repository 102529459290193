import React from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { LOCALSTORAGE_ROUTE_NAME } from '@src/assets/config';
import { connect } from 'react-redux';

interface Props {
  userStoreToken: string;
  component: React.ReactNode;
}

class RouteLoginGuard extends React.Component<Props & RouteComponentProps<any>, any> {
  render() {
    const {
      userStoreToken,
      component,
      history: {
        location: {
          pathname
        }
      },
    } = this.props;

    if (!userStoreToken) {
      try {
        window.localStorage.setItem(LOCALSTORAGE_ROUTE_NAME, pathname);
      } catch (e) {
        window.console.log('could not set', LOCALSTORAGE_ROUTE_NAME, 'to localStorage');
      }
    }

    if (userStoreToken) {
      return component;
    }

    return <Redirect to={`/login`} />;
  }
}

export default withRouter(connect((store: StoreState) => ({
  userStoreToken: store.authReducer.token,
}), null)(RouteLoginGuard));