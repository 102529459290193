import { call, put } from 'redux-saga/effects';
import { setAuth, setInitialActiveState } from '@src/store/auth/actions';
import { getInitialActiveState } from '@src/store/auth/reducer';
import { LOGIN_ERROR, LOGIN_SUCCESS } from '@src/store/login/constants';
import { LOCALSTORAGE_TOKEN_NAME, CLIENT_ID } from '@src/assets/config';
import { loginApi } from '@src/store/login/api/login-api';

export function* loginFlow (email: string, password: string) {
  let user: AuthState | undefined;

  try {
    user = yield call(loginApi, email, password, CLIENT_ID);
    if (user) {
      yield put(setAuth(user));
      yield put(setInitialActiveState(getInitialActiveState(user)));

      yield put({ type: LOGIN_SUCCESS });

      localStorage.setItem(LOCALSTORAGE_TOKEN_NAME, user.token ? user.token : '');
    }
  } catch (error) {
    yield put({ type: LOGIN_ERROR, error });
  }

  return user;
}