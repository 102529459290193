import {
  USERS_CHANGE_PAGE,
  USERS_REQUESTING,
  USERS_SEARCH_CHANGED,
  USERS_SEARCH_REQUESTING,
  USERS_SEARCH_RESET,
} from './constants';
import { DEFAULT_PAGINATION_LIMIT } from '@src/assets/config';

const fetchUsers = ({
  page,
  limit = DEFAULT_PAGINATION_LIMIT,
  params
}: TablePaginationAction) => {
  return {
    type: USERS_REQUESTING,
    page,
    limit,
    params
  };
};

const changePage = ({
  page
}: ChangePageAction) => {
  return {
    type: USERS_CHANGE_PAGE,
    page,
  };
};

const searchChanged = (search: UsersSearch) => {
  return {
    type: USERS_SEARCH_CHANGED,
    search
  };
};

const fetchSearch = ({
  page,
  limit = DEFAULT_PAGINATION_LIMIT,
  params
}: SearchPaginationAction) => {
  return {
    type: USERS_SEARCH_REQUESTING,
    page,
    limit,
    params,
  };
};

const resetSearch = () => {
  return {
    type: USERS_SEARCH_RESET
  };
};

export {
  fetchUsers,
  changePage,
  searchChanged,
  fetchSearch,
  resetSearch
};