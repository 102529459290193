import { getSelectedLanguageFromStorage, storeSelectedLanguage } from '@src/utils/storeUtils';
import localization from '@src/localization';
import { DEFAULT_APP_LANGUAGE_CODE } from '@src/assets/config';

const availableLanguages = localization;
const initialLanguageCode = getSelectedLanguageFromStorage() || DEFAULT_APP_LANGUAGE_CODE;
const appLanguage =
  availableLanguages.find((l: AppLanguage) => l.code === initialLanguageCode) ||
  availableLanguages.find((l: AppLanguage) => l.code === DEFAULT_APP_LANGUAGE_CODE);

const initialState = { availableLanguages, appLanguage };

const locale = (state = initialState, action) => {
  const {
    type
  } = action;

  if (type === 'SET_LANGUAGE') {
    const { code } = action;

    if (availableLanguages.find((l: AppLanguage) => l.code === code) === undefined) {
      console.warn('Trying to set unknown locale: ' + code);
      return state;
    }
    storeSelectedLanguage(code);

    return {
      ...state,
      appLanguage: state.availableLanguages.find((l: AppLanguage) => l.code === code),
    };
  } else {
    return state;
  }
};

export {
  locale,
};