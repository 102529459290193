import { createSelector } from 'reselect';
import { getPageSequence } from '@src/utils/storeUtils';

export const getAcceptableScoresActivePage = (store: StoreState) => store.acceptableScoresReducer.pagination.page;
export const getPagedAcceptableScores = (store: StoreState) => store.acceptableScoresReducer.pagedScores;

export const getAdjustmentsInheritance = createSelector(
  getAcceptableScoresActivePage,
  getPagedAcceptableScores,
  (page, pagedScores) => {
    const scores = pagedScores[page];

    const inheritanceOrder: number[] = [];
    const inheritanceMap: AdjustmentsInheritanceMap = {};

    getPageSequence(scores).forEach((score: AcceptableScore) => {
      inheritanceMap[`${score.id}`] = [];

      if (score.inheritedAdjustments) {
        for (const adjustment of score.inheritedAdjustments) {
          const { idWhsAcceptableScoreOrigin } = adjustment;

          if (idWhsAcceptableScoreOrigin) {
            let inheritanceIndex = inheritanceOrder.indexOf(idWhsAcceptableScoreOrigin);

            if (inheritanceIndex === -1) {
              inheritanceIndex = inheritanceOrder.push(idWhsAcceptableScoreOrigin) - 1;
            }

            inheritanceMap[score.id][inheritanceIndex] = idWhsAcceptableScoreOrigin;
          }
        }
      }
    });

    for (const adjustmentId in inheritanceMap) {
      if (inheritanceMap.hasOwnProperty(adjustmentId)) {
        const adjustmentIds = inheritanceMap[adjustmentId];
        inheritanceOrder.forEach((id, index) => {
          if (parseInt(adjustmentId, 10) === id) {
            adjustmentIds[index] = parseInt(adjustmentId, 10);
          } else if (adjustmentIds[index] !== id) {
            adjustmentIds[index] = -1;
          }
        });
      }
    }

    return { inheritanceMap, order: inheritanceOrder };
  }
);