import React from 'react';
import { confirmable } from 'react-confirm';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { createConfirmation } from 'react-confirm';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import { DialogTitle, MuiThemeProvider } from '@material-ui/core';
import materialUITheme from '@src/theme/materialUITheme';

export interface OptionsProps {
  title?: string;
  cancelText: string | JSX.Element | any;
  okText: string | JSX.Element | any;
}

export interface Props {
  show: boolean;
  proceed: (params: any) => any;
  cancel: (params: any) => any;
  dismiss: (params: any) => any;
  message?: string | React.ReactElement;
  messageHTML?: string;
  options: OptionsProps;
  maxWidth?: DialogProps['maxWidth'];
}

export const ConfirmDialog = ({
  show,
  proceed,
  dismiss,
  cancel,
  message,
  messageHTML,
  options,
  maxWidth = 'xs',
}: Props) => (
  <MuiThemeProvider theme={materialUITheme}>
    <Dialog
      open={show}
      onClose={dismiss}
      maxWidth={maxWidth}
      fullWidth={true}
      disableBackdropClick={true}
    >
      {options.title && <DialogTitle>{options.title}</DialogTitle>}
      <DialogContent>
        {messageHTML && (
          <DialogContentText>
            <span dangerouslySetInnerHTML={{ __html: messageHTML }} />
          </DialogContentText>
        )}
        {message && (
          <DialogContentText>
            {message}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        {Boolean(options.cancelText) && (
          <Button onClick={cancel}>
            {options.cancelText}
          </Button>
        )}
        {Boolean(options.okText) && (
          <Button onClick={() => proceed(true)} color="secondary" autoFocus={true}>
            {options.okText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  </MuiThemeProvider>
);

// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
const ConfirmableDialog = confirmable(ConfirmDialog);
const confirmation = createConfirmation(ConfirmableDialog);

interface ConfirmProps {
  message?: string | React.ReactElement;
  messageHTML?: string;
  options: OptionsProps;
  maxWidth?: DialogProps['maxWidth'];
}

export const confirm = ({
  message,
  messageHTML,
  options,
  maxWidth,
}: ConfirmProps) => {
  return confirmation({
    message,
    messageHTML,
    options,
    maxWidth,
  });
};
