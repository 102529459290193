import { put, call } from '@redux-saga/core/effects';
import { ATTACH_COURSE_ERROR, ATTACH_COURSE_SUCCESS } from '@src/store/clubCourse/constants';
import { API_ROOT } from '@src/assets/config';
import { tokenAPIPost } from '@src/utils/storeUtils';

export function* attachCourseFlow(
  token: string,
  parentCourseId: number,
  childCourseId: number,
  onComplete?: (args: APICallResult) => void
) {
  try {
    yield call(() =>
      tokenAPIPost(`${API_ROOT}admin/1/course/${parentCourseId}/attach/${childCourseId}`, token)
    );

    yield put({
      type: ATTACH_COURSE_SUCCESS,
    });

    if (onComplete) {
      onComplete({ data: { success: true } });
    }
  }  catch (error) {
    yield put({ type: ATTACH_COURSE_ERROR, error });

    if (onComplete) {
      onComplete({ error });
    }
  }
}