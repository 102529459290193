import React, { ChangeEvent, Component, RefObject } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import {
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import { connect } from 'react-redux';
import * as organizationActions from '@src/store/organization/actions';
import { renderFormActions } from '@src/components/modals/ui';
import { DeleteConfirmChildren } from '@src/components/headless/DeleteConfirm';
import DeleteConfirm from '@src/components/headless/DeleteConfirm';
import LocalizedDatePicker from '@src/components/forms/LocalizedDatePicker';
import InputsValidator from '@src/components/forms/InputsValidator';

interface Props extends WrappedComponentProps {
  targetIdParams: TargetIdParams;
  organizationId?: number | undefined;
  organizationState: OrganizationState;
  parentContext?: 'MODAL' | 'PAGE';
  onClose?: () => void;
  addOrganization: (params: OrganizationAdd) => any;
  editOrganization: (params: OrganizationEdit) => any;
  deleteOrganization: (params: OrganizationDelete) => any;
  actionsContainerRef?: RefObject<HTMLElement>;
}

type State = {
  values: {
    status: OrganizationStatus;
    name: string;
    nameAbbreviation: string;
    description: string;
    email: string;
    homepage: string;
    dateFoundation: Date | null;
    dateTermination: Date | null;
    visibleClubs: boolean;
    visibleMembers: boolean;
  }
};

const initialState = {
  values: {
    status: 'ACTIVE' as OrganizationStatus,
    name: '',
    nameAbbreviation: '',
    description: '',
    email: '',
    homepage: '',
    dateFoundation: null,
    dateTermination: null,
    visibleClubs: false,
    visibleMembers: false
  }
};

export class OrganizationFormConnected extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    const {
      organizationId,
      organizationState: {
        organization,
      }
    } = this.props;

    if (!organizationId) {
      this.state = initialState;
    } else if (organization) {
      this.state = {
        ...this._formStateFromObject(organization),
      } as any;
    } else {
      this.state = initialState;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      organizationId,
      organizationState: {
        organization,
        requesting,
      },
    } = nextProps;

    if (!organizationId) {
      return; // Do nothing if we are adding new organization
    } else if (requesting) {
      return; // Do nothing state is loading
    } else if (organization) {
      this.setState({
        ...this._formStateFromObject(organization) as any,
      });
    }
  }

  render() {
    const {
      organizationId,
      parentContext,
      actionsContainerRef,
    } = this.props;

    const {
      values: {
        status,
        name,
        nameAbbreviation,
        description,
        email,
        homepage,
        dateFoundation,
        dateTermination,
        visibleClubs,
        visibleMembers,
      },
    } = this.state;

    return (
      <InputsValidator values={{ status, name }}>
        {({ errorInputs, validateThenApply, validateValues }) => (
          <>
            <FormControl required={true} margin={'normal'} fullWidth={true} error={errorInputs.state}>
              <InputLabel htmlFor="organization-state">
                <FormattedMessage id={'strings.organizationState'} />
              </InputLabel>
              <Select
                value={status}
                inputProps={{
                  id: 'organization-state',
                }}
                onChange={(event) => {
                  this.setState({
                    values: {
                      ...this.state.values,
                      status: event.target.value as OrganizationStatus
                    }
                  }, validateValues);
                }}
              >
                <MenuItem value={'ACTIVE'} selected={status === 'ACTIVE' as any}>
                  <FormattedMessage id={'strings.statusActive'}/>
                </MenuItem>
                <MenuItem value={'INACTIVE'} selected={status === 'INACTIVE' as any}>
                  <FormattedMessage id={'strings.statusPassive'}/>
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              fullWidth={true}
              required={true}
              error={errorInputs.name}
              id="name"
              label={<FormattedMessage id={'strings.name'}/>}
              value={name}
              margin="normal"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                this._handleFieldChange(e, validateValues);
              }}
            />
            <TextField
              fullWidth={true}
              id="nameAbbreviation"
              label={<FormattedMessage id={'strings.abbreviation'}/>}
              value={nameAbbreviation}
              margin="normal"
              onChange={this._handleFieldChange}
            />
            <TextField
              fullWidth={true}
              id="description"
              label={<FormattedMessage id={'strings.description'}/>}
              value={description}
              margin="normal"
              onChange={this._handleFieldChange}
            />
            <TextField
              fullWidth={true}
              id="email"
              label={<FormattedMessage id={'strings.email'}/>}
              value={email}
              margin="normal"
              onChange={this._handleFieldChange}
            />
            <TextField
              fullWidth={true}
              id="homepage"
              label={<FormattedMessage id={'strings.homepage'}/>}
              value={homepage}
              margin="normal"
              onChange={this._handleFieldChange}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
              <FormControl margin={'normal'} style={{ width: '48%' }}>
                <FormLabel>
                  <FormattedMessage id={'scenes.functionaries.form.nominationDate'} />
                </FormLabel>
                <LocalizedDatePicker
                  value={dateFoundation}
                  onChange={(newDate: Date) => {
                    this._handleFieldChange({
                      currentTarget: { id: 'dateFoundation',
                        value: newDate ? new Date(newDate) : null,
                      }} as any);
                  }}
                  clearable={true}
                />
              </FormControl>
              <FormControl margin={'normal'} style={{ width: '48%' }}>
                <FormLabel>
                  <FormattedMessage id={'scenes.functionaries.form.nominationEndDate'} />
                </FormLabel>
                <LocalizedDatePicker
                  value={dateTermination}
                  onChange={(newDate: Date) => {
                    this._handleFieldChange({ currentTarget: {
                        id: 'dateTermination',
                        value: newDate ? new Date(newDate) : null,
                      }} as any);
                  }}
                  clearable={true}
                />
              </FormControl>
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={visibleClubs}
                  onChange={this._handleCheckboxChange('visibleClubs')}
                  value="visibleClubs"
                />
              }
              label={<FormattedMessage id={'strings.visibleInCourseGuide'} />}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={visibleMembers}
                  onChange={this._handleCheckboxChange('visibleMembers')}
                  value="visibleMembers"
                />
              }
              label={<FormattedMessage id={'strings.visibleForMembers'} />}
            />
            <DeleteConfirm>
              {({ showConfirm }: DeleteConfirmChildren) => (
                renderFormActions({
                  id: organizationId,
                  parentContext,
                  onClose: this._handleOnClose,
                  onUpdate: validateThenApply.bind(this, this._handleUpdateOrganization),
                  onCreate: validateThenApply.bind(this, this._handleCreateOrganization),
                  disabled: false,
                  containerRef: actionsContainerRef,
                  onDelete: () => {
                    showConfirm({
                      callback: this._handleDeleteOrganization,
                    });
                  },
                })
              )}
            </DeleteConfirm>
          </>
        )}
      </InputsValidator>
    );
  }

  private _handleOnClose = (): void => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  }

  private _handleDeleteOrganization = (bool: boolean) => {
    const { deleteOrganization, organizationId } = this.props;
    if (bool && organizationId) {
      deleteOrganization({
        id: organizationId,
        onComplete: this._handleOnClose,
      });
    }
  }

  private _handleCreateOrganization = () => {
    const { addOrganization } = this.props;
    const params = this._formOrganizationParams();

    addOrganization({
      ...params as any,
      onComplete: this._handleOnComplete,
    });
  }

  private _handleUpdateOrganization = () => {
    const { editOrganization } = this.props;
    const {
      organizationState: {
        organization
      }
    } = this.props;

    if (!organization) {
      return;
    }

    const params = this._formOrganizationParams();

    editOrganization({
      id: organization.idOrganization,
      ...params as any,
      onComplete: this._handleOnComplete,
    });
  }

  private _handleCheckboxChange = name => event => {
    this._handleFieldChange({
      currentTarget: {
        id: name,
        value: event.target.checked,
      },
    } as any);
  }

  private _handleFieldChange = (e: ChangeEvent<HTMLInputElement>, fn?: any) => {
    const {
      currentTarget: {
        id,
        value,
      }
    } = e;
    this.setState({
      values: {
        ...this.state.values,
        [id as any]: value,
      },
    }, fn);
  }

  private _formOrganizationParams = () => {
    return {
      ...this.state.values,
      ...this.props.targetIdParams,
    };
  }

  private _handleOnComplete = (result: any): void => {
    if (result.error) {
      window.alert(result.error.message);
    } else {
      this._handleOnClose();
    }
  }

  private _formStateFromObject = (organization: Organization) => {
    return {
      values: {
        status: organization.status || 'ACTIVE' as any,
        name: organization.name || '',
        nameAbbreviation: organization.nameAbbreviation || '',
        description: organization.description || '',
        email: organization.email || '',
        homepage: organization.homepage || '',
        dateFoundation: organization.dateFoundation || null,
        dateTermination: organization.dateTermination || null,
        visibleClubs: organization.visibleClubs || false,
        visibleMembers: organization.visibleMembers || false,
      },
    };
  }
}

export const OrganizationForm = injectIntl(connect((state: any) => ({
  organizationState: state.organizationReducer,
}), {
  addOrganization: organizationActions.addOrganization,
  editOrganization: organizationActions.editOrganization,
  deleteOrganization: organizationActions.deleteOrganization
})(OrganizationFormConnected));