export const FUNCTIONARY_GET_REQUESTING = 'FUNCTIONARY_GET_REQUESTING';
export const FUNCTIONARY_GET_SUCCESS = 'FUNCTIONARY_GET_SUCCESS';
export const FUNCTIONARY_GET_ERROR = 'FUNCTIONARY_GET_ERROR';

export const FUNCTIONARY_ADD_REQUESTING = 'FUNCTIONARY_ADD_REQUESTING';
export const FUNCTIONARY_ADD_SUCCESS = 'FUNCTIONARY_ADD_SUCCESS';
export const FUNCTIONARY_ADD_ERROR = 'FUNCTIONARY_ADD_ERROR';

export const FUNCTIONARY_EDIT_REQUESTING = 'FUNCTIONARY_EDIT_REQUESTING';
export const FUNCTIONARY_EDIT_SUCCESS = 'FUNCTIONARY_EDIT_SUCCESS';
export const FUNCTIONARY_EDIT_ERROR = 'FUNCTIONARY_EDIT_ERROR';

export const FUNCTIONARY_DELETE_REQUESTING = 'FUNCTIONARY_DELETE_REQUESTING';
export const FUNCTIONARY_DELETE_SUCCESS = 'FUNCTIONARY_DELETE_SUCCESS';
export const FUNCTIONARY_DELETE_ERROR = 'FUNCTIONARY_DELETE_ERROR';