import {
  CLUB_SERVICE_ADD_REQUESTING,
  CLUB_SERVICE_DELETE_REQUESTING,
  CLUB_SERVICE_EDIT_REQUESTING,
  CLUB_SERVICE_GET_REQUESTING
} from '@src/store/clubService/constants';

export const getClubService = ({
  id
}: ClubServiceGet) => {
  return {
    type: CLUB_SERVICE_GET_REQUESTING,
    clubServiceId: id,
  };
};

export const addClubService = (params: ClubServiceAdd) => {
  return {
    type: CLUB_SERVICE_ADD_REQUESTING,
    params
  };
};

export const editClubService = (params: ClubServiceEdit) => {
  return {
    type: CLUB_SERVICE_EDIT_REQUESTING,
    clubServiceId: params.id,
    params
  };
};

export const deleteClubService = ({
  id,
  clubId,
  onComplete,
}: ClubServiceDelete) => {
  return {
    type: CLUB_SERVICE_DELETE_REQUESTING,
    clubServiceId: id,
    params: {
      clubId,
      onComplete,
    },
  };
};