import React, { ChangeEvent, Component, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
} from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

interface Props {
  disabled?: boolean;
  initialValue: Array<MaintenanceScope> | undefined;
  onChange: (values: Array<MaintenanceScope> | undefined) => void;
  title?: ReactNode | string;
  groupProps?: Object;
}

class MaintenanceScopeSelect extends Component<Props, {}> {

  render() {
    const { initialValue, disabled, title, groupProps } = this.props;
    const scope = initialValue ? initialValue : [];

    return (
      <FormControl
        required={true}
        error={scope.length === 0}
        {...groupProps}
      >
        { title && (
          <FormLabel>
            {title}
          </FormLabel>
        )}

        <FormGroup row={true}>
          <FormControlLabel
            control={(
              <Checkbox
                color={'primary'}
                disabled={disabled}
                checked={scope.indexOf('ADMIN_UI') !== -1}
                onChange={this._handleScopeChange}
                value="ADMIN_UI"
              />
            )}
            label={(
              <FormattedMessage id={'scenes.maintenances.form.message.scopeSelections.admin_ui'}/>
            )}
          />
          <FormControlLabel
            control={(
              <Checkbox
                color={'primary'}
                disabled={disabled}
                checked={scope.indexOf('EBIRDIE_MOBILE') !== -1}
                onChange={this._handleScopeChange}
                value="EBIRDIE_MOBILE"
              />
            )}
            label={(
              <FormattedMessage id={'scenes.maintenances.form.message.scopeSelections.ebirdie_mobile'}/>
            )}
          />
        </FormGroup>
      </FormControl>
    );
  }

  private _handleScopeChange = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    const { initialValue = [] } = this.props;
    const { value, checked } = currentTarget;

    let payload: MaintenanceScope[] = [].concat(initialValue as any);

    if (checked) {
      payload.push(value as MaintenanceScope);
    } else {
      payload = payload.filter((val) => (val !== value));
    }

    if (this.props.onChange) {
      this.props.onChange(payload);
    }
  }
}

export default MaintenanceScopeSelect;