import { isAdminRole, isFederationScope } from '@src/components/access-control';

export const userAuthToken = (state: StoreState) => state.authReducer.token;
export const getActiveFederationId = (state: StoreState): number | undefined => (
  state.authReducer.config ? state.authReducer.config.federationId : undefined
);

export const getActiveClubId = (state: StoreState): number | undefined => {
  const {
    authReducer: {
      activeState,
    },
  } = state;

  if (activeState && activeState.club) {
    return activeState.club.id;
  }

  return undefined;
};

export const getActiveStateTargeting = (state: StoreState): any => {
  const {
    authReducer: {
      activeState,
    },
  } = state;

  if (activeState && activeState.club) {
    return { clubId: activeState.club.id };
  } else if (activeState && activeState.partner) {
    return { partnerId: activeState.partner.id };
  }

  return {};
};

export const userIsFederationAdmin = (state: StoreState): boolean => {
  if (state.authReducer.roleInfo) {
    const { roleInfo } = state.authReducer;
    return (isFederationScope(roleInfo) && isAdminRole(roleInfo));
  }

  return false;
};