import {
  CLUB_FEES_CHANGE_PAGE,
  CLUB_FEES_REQUESTING,
  CLUB_FEES_SEARCH_CHANGED,
  CLUB_FEES_SEARCH_REQUEST,
  CLUB_FEES_SEARCH_RESET
} from '@src/store/clubFees/constants';
import { DEFAULT_PAGINATION_LIMIT } from '@src/assets/config';

export const fetchClubFees = ({
  clubId,
  page,
  limit = DEFAULT_PAGINATION_LIMIT,
}: FetchClubFees) => {
  return {
    type: CLUB_FEES_REQUESTING,
    clubId,
    page,
    limit,
  };
};

export const changePage = ({
  page
}: ChangePageAction) => {
  return {
    type: CLUB_FEES_CHANGE_PAGE,
    page,
  };
};

export const searchChanged = (search: ClubFeeSearch) => {
  return {
    type: CLUB_FEES_SEARCH_CHANGED,
    search
  };
};

export const fetchSearch = ({
  clubId,
  page,
  limit = DEFAULT_PAGINATION_LIMIT,
  params
}: ClubSearchPaginationAction) => {
  return {
    type: CLUB_FEES_SEARCH_REQUEST,
    clubId,
    page,
    limit,
    params,
  };
};

export const resetSearch = (clubId: number) => {
  return {
    type: CLUB_FEES_SEARCH_RESET,
    clubId
  };
};