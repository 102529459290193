import { take, fork } from 'redux-saga/effects';
import {
  VENDORS_REQUESTING,
  VENDORS_SUCCESS,
  VENDORS_ERROR, VENDORS_RESET,
} from './constants';
import { APIRoute, DEFAULT_PAGINATION_LIMIT, DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import { AUTH_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';

/*
  GET /admin/1/vendors
 */
const fetchVendorsFlow = SagaFlowFactory.createPagedGETFlow({
  apiRoute: APIRoute.GET_VENDORS,
  itemType: 'vendors',
  successType: VENDORS_SUCCESS,
  errorType: VENDORS_ERROR
});

export default function * vendorsWatcher() {
  let token: string = '';

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
      page,
      limit,
      params
    } = yield take([
      VENDORS_REQUESTING,
      VENDORS_RESET,
    ]);

    switch (type) {
      case VENDORS_REQUESTING:
        yield fork(fetchVendorsFlow, token, page, limit, params);
        break;
      case VENDORS_RESET:
        yield fork(fetchVendorsFlow, token, DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_LIMIT);
        break;
      default:
    }
  }
}