import {
  EDIT_HANDICAP_OVERRIDE_REQUESTING,
  HANDICAP_INFO_GET_REQUESTING,
  PERSON_ADD_REQUESTING,
  PERSON_DELETE_REQUESTING,
  PERSON_EDIT_REQUESTING,
  PERSON_GET_REQUESTING,
  RESET_PERSON_STATE,
} from './constants';
import { Action } from 'redux';

export interface GetPersonAction extends Action, CRUDAction {
  type: 'PERSON_GET_REQUESTING';
  personId: number;
  params: Omit<PersonGet, 'personId'>;
}
export interface GetPersonSuccessAction extends Action {
  type: 'PERSON_GET_SUCCESS';
  data: Person;
}
export interface GetPersonErrorAction extends Action {
  type: 'PERSON_GET_ERROR';
}
export const getPerson = ({
  personId,
  onComplete,
}: PersonGet): GetPersonAction => ({
  type: PERSON_GET_REQUESTING,
  personId,
  params: {
    onComplete
  },
  crudAction: 'GET',
});

export interface AddPersonAction extends Action, CRUDAction {
  type: 'PERSON_ADD_REQUESTING';
  params: PersonAdd;
}
export interface AddPersonSuccessAction extends Action {
  type: 'PERSON_ADD_SUCCESS';
}
export interface AddPersonErrorAction extends Action {
  type: 'PERSON_ADD_ERROR';
}
export const addPerson = (params: PersonAdd): AddPersonAction => ({
  type: PERSON_ADD_REQUESTING,
  params,
  crudAction: 'ADD',
});

export interface EditPersonAction extends Action, CRUDAction {
  type: 'PERSON_EDIT_REQUESTING';
  personId: number;
  params: PersonEdit;
}
export interface EditPersonSuccessAction extends Action {
  type: 'PERSON_EDIT_SUCCESS';
  data: Person;
}
export interface EditPersonErrorAction extends Action {
  type: 'PERSON_EDIT_ERROR';
}
export const editPerson = (params: PersonEdit): EditPersonAction => ({
  type: PERSON_EDIT_REQUESTING,
  personId: params.id,
  params,
  crudAction: 'EDIT',
});

export interface DeletePersonAction extends Action, CRUDAction {
  type: 'PERSON_DELETE_REQUESTING';
  personId: number;
  params: Omit<PersonDelete, 'id'>;
}
export interface DeletePersonSuccessAction extends Action {
  type: 'PERSON_DELETE_SUCCESS';
}
export interface DeletePersonErrorAction extends Action {
  type: 'PERSON_DELETE_ERROR';
}
export const deletePerson = ({
  id,
  onComplete,
}: PersonDelete): DeletePersonAction => ({
  type: PERSON_DELETE_REQUESTING,
  personId: id,
  params: {
    onComplete,
  },
  crudAction: 'DELETE',
});

export interface GetPersonHandicapInfoAction extends Action {
  type: 'HANDICAP_INFO_GET_REQUESTING';
  personId: number;
}
export interface GetPersonHandicapInfoSuccess extends Action {
  type: 'HANDICAP_INFO_GET_SUCCESS';
  handicapInfo: HandicapInfo;
}
export interface GetPersonHandicapInfoError extends Action {
  type: 'HANDICAP_INFO_GET_ERROR';
}
export const getPersonHandicapInfo = (personId: number): GetPersonHandicapInfoAction => ({
  type: HANDICAP_INFO_GET_REQUESTING,
  personId,
});

export interface EditPersonHandicapOverridePayload extends APICall {
  personId: number;
  override: HandicapOverride;
}
export interface EditPersonHandicapOverrideAction extends Action, EditPersonHandicapOverridePayload {
  type: 'EDIT_HANDICAP_OVERRIDE_REQUESTING';
}
export interface EditPersonHandicapOverrideSuccess extends Action {
  type: 'EDIT_HANDICAP_OVERRIDE_SUCCESS';
}
export interface EditPersonHandicapOverrideError extends Action {
  type: 'EDIT_HANDICAP_OVERRIDE_ERROR';
}
export const editPersonHandicapOverride = (
  args: EditPersonHandicapOverridePayload,
): EditPersonHandicapOverrideAction => ({
  type: EDIT_HANDICAP_OVERRIDE_REQUESTING,
  ...args,
});

export interface ResetPersonStateAction extends Action {
  type: 'RESET_PERSON_STATE';
}
export const resetPersonState = () => ({
  type: RESET_PERSON_STATE,
});