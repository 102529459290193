import { fork, take } from 'redux-saga/effects';
import { AUTH_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { APIRoute } from '@src/assets/config';
import {
  OFFER_ADD_REQUESTING, OFFER_DELETE_REQUESTING, OFFER_EDIT_REQUESTING,
  OFFER_GET_REQUESTING
} from '@src/store/offer/constants';
import { OFFERS_SEARCH_RESET } from '@src/store/offers/constants';

/*
  GET|POST|PUT|DELETE /admin/1/offer/<id>
 */
const fetchPartnerCRUDFlow = SagaFlowFactory.createCRUDFlow({
  apiRoute: APIRoute.OFFER,
  typePrefix: 'OFFER',
  resetType: OFFERS_SEARCH_RESET,
});

function * offersWatcher() {
  let token: string = '';

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
      offerId,
      params
    } = yield take([
      OFFER_GET_REQUESTING,
      OFFER_ADD_REQUESTING,
      OFFER_EDIT_REQUESTING,
      OFFER_DELETE_REQUESTING
    ]);

    switch (type) {
      case OFFER_GET_REQUESTING:
        yield fork(fetchPartnerCRUDFlow, token, 'GET', offerId, params);
        break;
      case OFFER_ADD_REQUESTING:
        yield fork(fetchPartnerCRUDFlow, token, 'ADD', null, { ...params, __multiPart: true });
        break;
      case OFFER_EDIT_REQUESTING:
        yield fork(fetchPartnerCRUDFlow, token, 'EDIT_POST', offerId, { ...params, __multiPart: true });
        break;
      case OFFER_DELETE_REQUESTING:
        yield fork(fetchPartnerCRUDFlow, token, 'DELETE', offerId, params);
        break;
      default:
    }
  }
}

export default offersWatcher;