import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { removeCourseChildFromParent } from '@src/store/clubCourse/actions';
import DeleteConfirm, { DeleteConfirmChildren } from '@src/components/headless/DeleteConfirm';

export interface ChildrenProps {
  handleDetach(): void;
}

interface OwnProps {
  parentId: number;
  childId: number;
  onSuccess?(): void;
  children(args: ChildrenProps): ReactNode;
}

interface DispatchProps {
  removeCourseChildFromParent(args: RemoveCourseChildFromParent): void;
}

type Props = OwnProps & DispatchProps;

function DetachCourseChildAction({
  parentId,
  childId,
  children,
  onSuccess,
  removeCourseChildFromParent,
}: Props) {
  const notifyChange = () => {
    if (onSuccess) {
      onSuccess();
    }
  };

  const handleDetach = ({ showConfirm }: DeleteConfirmChildren) => {
    showConfirm({
      callback: (result) => {
        if (result && parentId) {
          removeCourseChildFromParent({
            courseId: parentId,
            partialCourseId: childId,
            onComplete: notifyChange,
          });
        }
      },
    });
  };

  return (
    <DeleteConfirm>
      {(args: DeleteConfirmChildren) => (
        children({
          handleDetach(): void {
            handleDetach(args);
          }
        })
      )}
    </DeleteConfirm>
  );
}

export default connect<{}, DispatchProps, OwnProps, StoreState>(null, {
  removeCourseChildFromParent
})(DetachCourseChildAction);