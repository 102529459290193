import { fork, take } from 'redux-saga/effects';
import { AUTH_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { REPORTS_RESET } from '@src/store/report2s/constants';
import { APIRoute } from '@src/assets/config';
import {
  REPORT_ADD_REQUESTING, REPORT_DELETE_REQUESTING, REPORT_EDIT_REQUESTING,
  REPORT_GET_REQUESTING
} from './constants';

/*
  GET|POST|PUT|DELETE /admin/1/report/<id>
 */
const reportCRUDFlow = SagaFlowFactory.createCRUDFlow({
  apiRoute: APIRoute.REPORT,
  typePrefix: 'REPORT',
  resetType: REPORTS_RESET,
});

function * reportWatcher() {
  let token: string = '';

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
      reportId,
      params
    } = yield take([
      REPORT_GET_REQUESTING,
      REPORT_ADD_REQUESTING,
      REPORT_EDIT_REQUESTING,
      REPORT_DELETE_REQUESTING
    ]);

    switch (type) {
      case REPORT_GET_REQUESTING:
        yield fork(reportCRUDFlow, token, 'GET', reportId, params);
        break;
      case REPORT_ADD_REQUESTING:
        yield fork(reportCRUDFlow, token, 'ADD', null, params);
        break;
      case REPORT_EDIT_REQUESTING:
        yield fork(reportCRUDFlow, token, 'EDIT', reportId, params);
        break;
      case REPORT_DELETE_REQUESTING:
        yield fork(reportCRUDFlow, token, 'DELETE', reportId, params);
        break;
      default:
    }
  }
}

export default reportWatcher;