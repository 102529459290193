import React, { PureComponent, ChangeEvent, RefObject } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  TextField
} from '@material-ui/core';
import { renderFormActions } from '@src/components/modals/ui';
import { connect } from 'react-redux';
import * as reportActions from '@src/store/report/actions';
import InputsValidator from '@src/components/forms/InputsValidator';
import { DropzoneLabel, DropzoneWrapper } from './ui/CodesForm';
import Dropzone, { DropzoneOptions } from 'react-dropzone';

type DropHandler = DropzoneOptions['onDrop'];

interface Props {
  reportId?: number | undefined;
  report: Report2State;
  parentContext?: 'MODAL' | 'PAGE';
  onClose?: () => void;
  addReport: (params: Report2Add) => any;
  editReport: (params: Report2Edit) => any;
  actionsContainerRef?: RefObject<HTMLElement>;
}

interface State {
  name: string;
  source: string;
  status: EnabledStatus;
}

const initialState: State = {
  name: '',
  source: '',
  status: 'ACTIVE',
};

class ReportFormConnected extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const {
      reportId,
      report: {
        report
      }
    } = this.props;

    if (!reportId) {
      this.state = initialState;
    } else if (report) {
      this.state = report;
    } else {
      this.state = initialState;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      reportId,
      report: {
        report,
        requesting,
      },
    } = nextProps;

    if (!reportId) {
      return; // Do nothing if we are adding new person
    } else if (requesting) {
      return; // Do nothing state is loading
    } else if (report) {
      this.setState({
        ...report as any
      });
    }
  }

  _onDrop: DropHandler = async (accepted, rejected, event) => {

    // Check for rejection errors
    const name = accepted[0].name.replace('.rptdesign', '');
    if (rejected.length > 0) {
      const errorCode: string | undefined = rejected[0].errors[0]?.code;
      console.error('User targeting drop rejection:', rejected, errorCode);
      // FIXME give user error
      return;
    }

    // Validate file TODO
    try {
      const file: Blob = accepted[0];
      /* tslint:disable:no-string-literal */
      const text = await file['text']();
      /* tslint:enable:no-string-literal */

      // TODO check that text is xml

      this.setState({
        ...this.state,
        name: name,
        source: text
      });
    } catch (e) {
      console.error('Something else was wrong with the file');
      // setError(intl.formatMessage({id: 'scenes.offers.errors.invalidFile'}));
    }
  }

  render() {
    const {
      reportId,
      parentContext,
      actionsContainerRef,
    } = this.props;

    const {
      name,
      source,
    } = this.state;

    const overlayStyle: any = {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      padding: '2.5em 0',
      background: 'rgba(0,0,0,0.5)',
      textAlign: 'center',
      color: '#fff'
    };

    return (
      <InputsValidator values={{ name }}>
        {({ errorInputs, validateThenApply, validateValues }) => (
          <>
            <DropzoneWrapper>
              <Dropzone
                onDrop={this._onDrop}
                accept=".rptdesign"
                multiple={false}
                maxFiles={1}
                maxSize={1000000}
              >
                {({ isDragActive, getRootProps, getInputProps }) => (
                  <div className={'drop-zone'} {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isDragActive && (
                      <div style={overlayStyle}>
                        <FormattedMessage id={'scenes.offers.form.sections.codes.dropZoneActiveLabel'} />
                      </div>
                    )}
                    <DropzoneLabel>
                      <FormattedMessage id={'scenes.offers.form.sections.codes.dropZoneLabel'} />
                    </DropzoneLabel>
                  </div>
                )}
              </Dropzone>
            </DropzoneWrapper>

            <TextField
              error={errorInputs.name}
              required={true}
              margin={'normal'}
              fullWidth={true}
              label={<FormattedMessage id={'scenes.reports.engine.nameLabel'} />}
              value={name}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const {
                  currentTarget: {
                    value
                  }
                } = e;
                this.setState({ name: value }, validateValues);
              }}
            />
            <TextField
              error={errorInputs.source}
              required={true}
              margin={'normal'}
              fullWidth={true}
              multiline={true}
              label={<FormattedMessage id={'scenes.reports.engine.sourceLabel'} />}
              value={source}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const {
                  currentTarget: {
                    value
                  }
                } = e;
                this.setState({ source: value }, validateValues);
              }}
            />
            {renderFormActions({
              id: reportId,
              parentContext,
              onClose: this._handleOnClose,
              onUpdate: validateThenApply.bind(this, this._handleUpdate),
              onCreate: validateThenApply.bind(this, this._handleCreate),
              containerRef: actionsContainerRef,
            })}

          </>
        )}
      </InputsValidator>
    );
  }

  private _handleOnClose = () => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  }

  private _handleUpdate = () => {
    const { editReport } = this.props;
    const params = this._formReportParams();
    const {
      report: {
        report
      }
    } = this.props;

    if (!report) {
      return;
    }

    editReport({
      id: report.id,
      ...params,
      onComplete: this._handleOnComplete,
    });
  }

  private _handleCreate = () => {
    const { addReport } = this.props;
    const params = this._formReportParams();

    addReport({
      ...params,
      onComplete: this._handleOnComplete,
    });
  }

  private _formReportParams = () => {
    const { name, source, status } = this.state;

    return {
      name,
      source,
      status
    };
  }

  private _handleOnComplete = (result: any) => {
    if (result.error) {
      window.alert(result.error.message);
    } else {
      this._handleOnClose();
    }
  }
}

const ReportForm = connect((state: any) => ({
  report: state.report2Reducer,
}), {
  addReport: reportActions.addReport,
  editReport: reportActions.editReport
})(ReportFormConnected);

export {
  ReportForm
};
