import {
  ORGANIZATION_ADD_REQUESTING,
  ORGANIZATION_DELETE_REQUESTING,
  ORGANIZATION_EDIT_REQUESTING,
  ORGANIZATION_GET_REQUESTING,
  ORGANIZATION_PERSONS_REQUESTING,
  ORGANIZATION_PERSON_ATTRIBUTE_SET,
} from './constants';

export const getOrganization = ({
  id
}: OrganizationGet) => {
  return {
    type: ORGANIZATION_GET_REQUESTING,
    organizationId: id,
  };
};

export const addOrganization = (params: OrganizationAdd) => {
  return {
    type: ORGANIZATION_ADD_REQUESTING,
    params
  };
};

export const editOrganization = (params: OrganizationEdit) => {
  return {
    type: ORGANIZATION_EDIT_REQUESTING,
    organizationId: params.id,
    params
  };
};

export const deleteOrganization = ({
  id,
  onComplete,
}: OrganizationDelete) => {
  return {
    type: ORGANIZATION_DELETE_REQUESTING,
    organizationId: id,
    params: {
      onComplete,
    },
  };
};

export const fetchOrganizationPersons = ({
  organizationId,
}: FetchOrganizationPersons) => {
  return {
    type: ORGANIZATION_PERSONS_REQUESTING,
    organizationId,
  };
};

export const organizationPersonActivate = ({
  organizationId,
  personId,
  onComplete,
}: OrganizationPersonAttributeSet) => {
  return {
    type: ORGANIZATION_PERSON_ATTRIBUTE_SET,
    action: 'activate',
    organizationId,
    personId,
    onComplete,
  };
};

export const organizationPersonDeactivate = ({
  organizationId,
  personId,
  onComplete,
}: OrganizationPersonAttributeSet) => {
  return {
    type: ORGANIZATION_PERSON_ATTRIBUTE_SET,
    action: 'deactivate',
    organizationId,
    personId,
    onComplete,
  };
};

export const organizationPersonPromote = ({
  organizationId,
  personId,
  onComplete,
}: OrganizationPersonAttributeSet) => {
  return {
    type: ORGANIZATION_PERSON_ATTRIBUTE_SET,
    action: 'promote',
    organizationId,
    personId,
    onComplete,
  };
};

export const organizationPersonDemote = ({
  organizationId,
  personId,
  onComplete,
}: OrganizationPersonAttributeSet) => {
  return {
    type: ORGANIZATION_PERSON_ATTRIBUTE_SET,
    action: 'demote',
    organizationId,
    personId,
    onComplete,
  };
};