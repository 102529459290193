import { take, fork } from 'redux-saga/effects';
import {
  CLUB_GREENCARDS_REQUESTING,
  CLUB_GREENCARDS_SUCCESS,
  CLUB_GREENCARDS_ERROR,
  CLUB_GREENCARDS_RESET,
  CLUB_GREENCARDS_SEARCH_REQUESTING,
  CLUB_GREENCARDS_SEARCH_RESET,
} from './constants';
import { DEFAULT_PAGINATION_LIMIT, DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import { AUTH_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { normalizeSearchParams } from '@src/utils/storeUtils';

/*
  GET /admin/1/club/<id>/greenCards
 */
const pagedFlow = (clubId: number) => {
  return SagaFlowFactory.createPagedGETFlow({
    apiRoute: `admin/1/club/${clubId}/greenCards`,
    itemType: 'greenCards',
    successType: CLUB_GREENCARDS_SUCCESS,
    errorType: CLUB_GREENCARDS_ERROR
  });
};

/*
  GET /admin/1/club/<id>/greenCards/search
 */
const pagedSearchFlow = (clubId: number) => {
  return SagaFlowFactory.createPagedGETFlow({
    apiRoute: `admin/1/club/${clubId}/greenCards/search`,
    itemType: 'greenCards',
    successType: CLUB_GREENCARDS_SUCCESS,
    errorType: CLUB_GREENCARDS_ERROR
  });
};

export default function * clubGreenCardsWatcher() {
  let token: string = '';
  let targetingParams: any = {};

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
      page,
      clubId,
      limit,
      params
    } = yield take([
      CLUB_GREENCARDS_REQUESTING,
      CLUB_GREENCARDS_RESET,
      CLUB_GREENCARDS_SEARCH_REQUESTING,
      CLUB_GREENCARDS_SEARCH_RESET
    ]);

    switch (type) {
      case CLUB_GREENCARDS_REQUESTING:
        yield fork(pagedFlow(clubId), token, page, limit, targetingParams);
        break;
      case CLUB_GREENCARDS_RESET:
        yield fork(pagedFlow(params.clubId), token, DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_LIMIT, targetingParams);
        break;
      case CLUB_GREENCARDS_SEARCH_REQUESTING:
        yield fork(pagedSearchFlow(clubId), token, page, limit, normalizeSearchParams(params));
        break;
      case CLUB_GREENCARDS_SEARCH_RESET:
        yield fork(pagedFlow(clubId), token, DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_LIMIT);
        break;
      default:
    }
  }
}