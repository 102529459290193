import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { cleanTrailingSlash } from '@src/utils/storeUtils';

const TempNav = styled.ul`
  margin-top: 1rem;
  
  li {
    display: inline-block;
    font-size: 1.2rem;
    padding: .4rem .2rem;
    
    a {
      color: #333333;
      
      &:hover {
        color: #166939;
      }
    }
    
    &:first-child {
      padding-left: 0;
    }
  }
`;

type Props = {
  url: string;
  club?: Club;
};

export default class ClubSubNavigation extends React.PureComponent<Props, {}> {
  render(): React.ReactNode {
    const { url } = this.props;

    return (
      <>
        <TempNav>
          <li>
            <NavLink
              to={cleanTrailingSlash(url, '/members')}
              activeClassName={'active'}
            >
              <FormattedMessage id={'navigation.golfClubMembers'}/>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={cleanTrailingSlash(url, '/courses')}
              activeClassName={'active'}
            >
              <FormattedMessage id={'navigation.golfClubCourses'}/>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={cleanTrailingSlash(url, '/functionaries')}
              activeClassName={'active'}
            >
              <FormattedMessage id={'navigation.functionaries'}/>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={cleanTrailingSlash(url, '/organizations')}
              activeClassName={'active'}
            >
              <FormattedMessage id={'navigation.organizations'}/>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={cleanTrailingSlash(url, '/services')}
              activeClassName={'active'}
            >
              <FormattedMessage id={'navigation.golfClubServices'}/>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={cleanTrailingSlash(url, '/payments')}
              activeClassName={'active'}
            >
              <FormattedMessage id={'navigation.golfClubPayments'}/>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={cleanTrailingSlash(url, '/green-card')}
              activeClassName={'active'}
            >
              <FormattedMessage id={'navigation.golfClubGreenCard'}/>
            </NavLink>
          </li>
        </TempNav>
      </>
    );
  }
}