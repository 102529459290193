import { IconButton, makeStyles, Menu, MenuItem, TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import ScoreEditor from '@src/components/scenes/member/components/ScoreEditor';
import { sumAdjustments } from '@src/utils/scoreUtils';
import { FormattedDate, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import {
  Label,
  MoreVert,
  Flight as FlightIcon,
  Update as UpdateIcon,
  Check as CheckIcon,
  Clear as ClearIcon
} from '@material-ui/icons';
import AcceptableScoreDetailsModal from '@src/components/scenes/member/components/AcceptableScoreDetailsModal';
import ScoreAdjustmentsModal from '@src/components/scenes/member/components/ScoreAdjustmentsModal';
import AdjustmentsInheritance from '@src/components/scenes/member/components/AdjustmentsInheritance';
import classNames from 'classnames';
import { confirm } from '@src/components/modals/Confirm';
import PersonIdModal from '@src/components/modals/PersonIdModal';
import { EditAcceptableScoreStatusPayload, EditAcceptableScorePersonPayload } from '@src/store/acceptableScore/actions';

const useStyles = makeStyles({
  default: {
    color: `rgba(0, 0, 0, 0.87)`,
    '@global': {
      'td': {
        color: 'inherit',
      },
    },
  },
  pending: {
    color: `rgba(0, 0, 0, .35)`,
  },
  condensedCell: {
    overflow: 'hidden',
    whiteSpace: 'pre',
    textOverflow: 'ellipsis',
  },
});

interface OwnProps {
  type: 'acceptable' | 'invalidated';
  acceptableScore: AcceptableScore;
  editAcceptableScoreStatus: (args: EditAcceptableScoreStatusPayload) => void;
  editAcceptableScorePerson: (args: EditAcceptableScorePersonPayload) => void;
}

type Props = OwnProps & WrappedComponentProps;

function ScoreRow({
  type,
  acceptableScore,
  intl,
  editAcceptableScoreStatus,
  editAcceptableScorePerson,
}: Props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [moveModalOpen, setMoveModalOpen] = React.useState(false);
  const [moveError, setMoveError] = React.useState('');

  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const classes = useStyles();
  const {
    id,
    result,
    adjustments = [],
    inheritedAdjustments = [],
    holes,
    typeOfRound,
    isWaitingForReComputation,
    affectsHandicapIndex,
    canEdit = true,
  } = acceptableScore;

  const isArtificial = typeOfRound === 'ARTIFICIAL';
  const isInvalidable = typeOfRound === 'COMPETITION' || typeOfRound === 'GENERALPLAY';

  return (
    <TableRow
      key={acceptableScore.id}
      className={classNames(classes.default, { [classes.pending]: isWaitingForReComputation })}
    >
      <TableCell style={{ width: 24, paddingRight: 0 }}>
        <Label fontSize={'small'} fontSizeAdjust={4} style={{ opacity: affectsHandicapIndex ? .72 : 0 }}/>
      </TableCell>
      <TableCell>
        <FormattedDate value={acceptableScore.timePlayed}/>
      </TableCell>
      <TableCell className={classes.condensedCell}>
        {acceptableScore?.courseJurisdiction === 'HOME' ?
          acceptableScore.course?.name :
          <>"{acceptableScore?.course?.name}"</>
        }
      </TableCell>
      <TableCell align={'center'}>
        {acceptableScore.isWaitingForReComputation ?
          <UpdateIcon fontSize={'small'}/> :
          acceptableScore?.courseJurisdiction === 'HOME' ?
            <CheckIcon fontSize={'small'} color={'primary'}/> :
            <FlightIcon fontSize={'small'} color={'secondary'}/>
        }
      </TableCell>
      <TableCell align={'center'}>
        {acceptableScore.typeOfRound}
      </TableCell>
      <TableCell align={'center'}>
        {acceptableScore?.result?.grossScore !== undefined && acceptableScore.result.grossScore > 0
          ? acceptableScore.result.grossScore
          : '-'}
      </TableCell>
      <TableCell align={'center'}>
        {acceptableScore?.result?.grossScoreAdjusted !== undefined && acceptableScore.result.grossScoreAdjusted > 0
          ? acceptableScore.result.grossScoreAdjusted
          : '-'}
      </TableCell>
      <TableCell align={'center'}>
        {acceptableScore.courseJurisdiction === 'HOME' || acceptableScore.typeOfRound === 'COMPETITION' ?
          acceptableScore.result?.playingConditionCalculation :
          <ClearIcon fontSize={'small'} color={'secondary'}/>}
      </TableCell>
      <TableCell align={'center'} style={{ paddingTop: 0, paddingBottom: 0 }}>
        <span style={{ display: 'flex' }}>
          <span style={{ flex: 1, alignSelf: 'center' }}>
            {result && (sumAdjustments([...adjustments, ...inheritedAdjustments]))}
          </span>
          <AdjustmentsInheritance id={acceptableScore.id} adjustments={acceptableScore.inheritedAdjustments}/>
        </span>
      </TableCell>
      <TableCell align={'center'}>
        {acceptableScore.result?.scoreDifferentialAdjusted}
      </TableCell>
      <TableCell align={'center'}>
        {acceptableScore.result?.handicapIndexRevised}
      </TableCell>
      <TableCell align={'center'}>
        <AcceptableScoreDetailsModal>
          {({ showDetails }) => (
            <ScoreAdjustmentsModal>
              {({ showAdjustments }) => (
                <ScoreEditor>
                  {({ showEdit }) => (
                    <>
                      <IconButton
                        size={'small'}
                        aria-controls="acceptable-score-row-more"
                        aria-haspopup="true"
                        onClick={handleClick}
                      >
                        <MoreVert/>
                      </IconButton>
                      <Menu
                        id="acceptable-score-row-more"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                      >
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            showDetails(acceptableScore);
                          }}
                        >
                          <FormattedMessage id={'strings.showAllDetails'}/>
                        </MenuItem>
                        <MenuItem
                          disabled={!canEdit}
                          onClick={() => {
                            handleClose();
                            showAdjustments(acceptableScore, adjustments, inheritedAdjustments);
                          }}
                        >
                          <FormattedMessage id={'strings.scoreAdjustments'}/>
                        </MenuItem>
                        {type === 'acceptable' &&
                          <MenuItem
                            disabled={isArtificial || !canEdit}
                            onClick={() => {
                              handleClose();
                              showEdit(id, holes);
                            }}
                          >
                            <FormattedMessage id={'strings.editScore'}/>
                          </MenuItem>
                        }
                        {type === 'acceptable' &&
                          <MenuItem
                            disabled={!isInvalidable}
                            onClick={() => {                            
                              confirm({
                                message: intl.formatMessage({
                                  id: 'scenes.member.modal.invalidateScore',
                                }),
                                options: {
                                  cancelText: intl.formatMessage({ id: 'buttons.cancel' }),
                                  okText: intl.formatMessage({ id: 'buttons.ok' }),
                                }
                              }).then(() => {
                                editAcceptableScoreStatus({
                                  acceptableScoreId: acceptableScore.id,
                                  status: 'INVALID',
                                  onComplete: () => handleClose()
                                });
                              }, () => {
                                handleClose();
                              });
                            }}
                          >
                            <FormattedMessage id={'strings.invalidateScore'}/>
                          </MenuItem>
                        }
                        {type === 'invalidated' &&
                          <MenuItem
                            onClick={() => {    
                              confirm({
                                message: intl.formatMessage({
                                  id: 'scenes.member.modal.validateScore',
                                }),
                                options: {
                                  cancelText: intl.formatMessage({ id: 'buttons.cancel' }),
                                  okText: intl.formatMessage({ id: 'buttons.ok' }),
                                }
                              }).then(() => {
                                editAcceptableScoreStatus({
                                  acceptableScoreId: acceptableScore.id,
                                  status: 'VALID',
                                  onComplete: () => handleClose()
                                });
                              }, () => {
                                handleClose();
                              });
                            }}
                          >
                            <FormattedMessage id={'strings.acceptScore'}/>
                          </MenuItem>
                        }
                        <MenuItem 
                          disabled={isArtificial}
                          onClick={() => setMoveModalOpen(true)}
                        >
                          <FormattedMessage id="strings.moveScoreToOtherPlayerTitle"/>
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                </ScoreEditor>
              )}
            </ScoreAdjustmentsModal>
          )}
        </AcceptableScoreDetailsModal>
      </TableCell>
      <PersonIdModal
        open={moveModalOpen}
        error={moveError}
        onCancel={() => {
          setMoveError('');
          setMoveModalOpen(false);
          handleClose();
        }}
        onProceed={(personId) => {
          setMoveError('');
          editAcceptableScorePerson({
            acceptableScoreId: acceptableScore.id,
            personId: personId,
            onComplete: (result: any) => {
              if (result.error) {
                setMoveError(result.error.message);
              } else {
                setMoveError('');
                handleClose();
              }
            }
          });
        }}
      /> 
    </TableRow>
  );
}

export default injectIntl(ScoreRow);
