import {
  FUNCTIONARY_TITLES_REQUESTING,
  FUNCTIONARY_TITLES_SUCCESS,
  FUNCTIONARY_TITLES_ERROR,
  FUNCTIONARY_TITLES_CHANGE_PAGE,
  FUNCTIONARY_TITLES_SET_INITIAL_STATE,
  FUNCTIONARY_TITLES_SET_TARGET_ID_PARAMS, FUNCTIONARY_TITLES_CONDENSED_SUCCESS,
} from './constants';
import { SHARED_DEFAULT_REDUCER_STATE } from '@src/assets/config';
import {
  formDefaultErrorState,
  formDefaultPagedState,
  formDefaultRequestingState,
  formDefaultTargetIdState,
  fromDefaultPageChangeState,
  optimisticPagedObjectUpdate,
} from '@src/utils/storeUtils';
import {
  FUNCTIONARY_TITLE_ADD_SUCCESS,
  FUNCTIONARY_TITLE_DELETE_SUCCESS,
  FUNCTIONARY_TITLE_EDIT_SUCCESS
} from '@src/store/functionarytitle/constants';
import { FUNCTIONARIES_SET_TARGET_ID_PARAMS } from '@src/store/functionaries/constants';

const initialState: FunctionaryTitlesState = {
  ...SHARED_DEFAULT_REDUCER_STATE,
  pagedTitles: {},
  allTitles: [],
};

const functionaryTitlesReducer = (
  state: FunctionaryTitlesState = initialState,
  action: any
): FunctionaryTitlesState => {
  switch (action.type) {
    // Titles and functionaries destinies intertwined
    case FUNCTIONARIES_SET_TARGET_ID_PARAMS:
    case FUNCTIONARY_TITLES_SET_TARGET_ID_PARAMS:
      return formDefaultTargetIdState(initialState, state, action);

    case FUNCTIONARY_TITLES_REQUESTING:
      return formDefaultRequestingState(state);

    case FUNCTIONARY_TITLES_SUCCESS:
      return formDefaultPagedState(state, action, 'pagedTitles');

    case FUNCTIONARY_TITLES_CHANGE_PAGE:
      return fromDefaultPageChangeState(state, action);

    case FUNCTIONARY_TITLES_ERROR:
      return formDefaultErrorState(state, action);

    case FUNCTIONARY_TITLE_EDIT_SUCCESS: {
      const {
        data,
      } = action;
      const page = state.pagination.page;

      const pagedTitles = optimisticPagedObjectUpdate(state.pagedTitles, page, { ...data });

      return {
        ...state,
        pagedTitles
      };
    }

    case FUNCTIONARY_TITLES_SET_INITIAL_STATE:
      return {
        ...initialState
      };

    case FUNCTIONARY_TITLES_CONDENSED_SUCCESS: {
      return {
        ...state,
        allTitles: action.data,
      };
    }

    // Our sagas will handle the re-fetching after add
    case FUNCTIONARY_TITLE_ADD_SUCCESS:
    case FUNCTIONARY_TITLE_DELETE_SUCCESS: {
      return {
        ...formDefaultRequestingState(state),
        pagedTitles: {},
      };
    }

    default:
      return state;
  }
};

export {
  functionaryTitlesReducer,
};