import { fork, take } from 'redux-saga/effects';
import { AUTH_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { APIRoute } from '@src/assets/config';
import {
  FUNCTIONARY_ADD_REQUESTING,
  FUNCTIONARY_DELETE_REQUESTING,
  FUNCTIONARY_EDIT_REQUESTING,
  FUNCTIONARY_GET_REQUESTING
} from '@src/store/functionary/constants';
import { FUNCTIONARIES_RESET } from '@src/store/functionaries/constants';

/*
  GET|POST|PUT|DELETE /admin/1/functionary/<id>
 */
const fetchFunctionaryCRUDFlow = SagaFlowFactory.createCRUDFlow({
  apiRoute: APIRoute.FUNCTIONARY,
  typePrefix: 'FUNCTIONARY',
  resetType: FUNCTIONARIES_RESET,
});

function * functionaryWatcher() {
  let token: string = '';

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
      params,
      functionaryId,
    } = yield take([
      FUNCTIONARY_GET_REQUESTING,
      FUNCTIONARY_ADD_REQUESTING,
      FUNCTIONARY_EDIT_REQUESTING,
      FUNCTIONARY_DELETE_REQUESTING
    ]);

    switch (type) {
      case FUNCTIONARY_GET_REQUESTING:
        yield fork(fetchFunctionaryCRUDFlow, token, 'GET', functionaryId, params);
        break;
      case FUNCTIONARY_ADD_REQUESTING:
        yield fork(fetchFunctionaryCRUDFlow, token, 'ADD', null, params);
        break;
      case FUNCTIONARY_EDIT_REQUESTING:
        yield fork(fetchFunctionaryCRUDFlow, token, 'EDIT', functionaryId, params);
        break;
      case FUNCTIONARY_DELETE_REQUESTING:
        yield fork(fetchFunctionaryCRUDFlow, token, 'DELETE', functionaryId, params);
        break;
      default:
    }
  }
}

export default functionaryWatcher;