import {
  CLUB_FEE_ADD_REQUESTING,
  CLUB_FEE_DELETE_REQUESTING,
  CLUB_FEE_EDIT_REQUESTING,
  CLUB_FEE_GET_REQUESTING
} from '@src/store/clubFee/constants';

export const getClubFee = ({
  clubId,
  clubFeeId,
}: ClubFeeGet) => {
  return {
    type: CLUB_FEE_GET_REQUESTING,
    clubId,
    clubFeeId,
  };
};

export const addClubFee = (params: ClubFeeAdd) => {
  return {
    type: CLUB_FEE_ADD_REQUESTING,
    clubId: params.clubId,
    params,
  };
};

export const editClubFee = (params: ClubFeeEdit) => {
  return {
    type: CLUB_FEE_EDIT_REQUESTING,
    clubId: params.clubId,
    clubFeeId: params.clubFeeId,
    params,
  };
};

export const deleteClubFee = ({
  clubId,
  clubFeeId,
  onComplete,
}: ClubFeeDelete) => {
  return {
    type: CLUB_FEE_DELETE_REQUESTING,
    clubId,
    clubFeeId,
    params: {
      clubId,
      onComplete,
    },
  };
};