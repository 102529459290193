export const REPORT_MEMBERSHIP_STATISTICS_REQUESTING = 'REPORT_MEMBERSHIP_STATISTICS_REQUESTING';

export const REPORT_CLUB_COURSE_GUIDE_REQUESTING = 'REPORT_CLUB_COURSE_GUIDE_REQUESTING';

export const REPORT_GREENCARD_REQUESTING = 'REPORT_GREENCARD_REQUESTING';

export const REPORT_FUNCTIONARIES_REQUESTING = 'REPORT_FUNCTIONARIES_REQUESTING';

export const REPORT_MEMBERSHIPS_REQUESTING = 'REPORT_MEMBERSHIPS_REQUESTING';

export const REPORT_JOIN_DATE_REQUESTING = 'REPORT_JOIN_DATE_REQUESTING';

export const REPORT_CLUB_MEMBERSHIPS_REQUESTING = 'REPORT_CLUB_MEMBERSHIPS_REQUESTING';

export const REPORT_EBIRDIE_STATISTICS_REQUESTING = 'REPORT_EBIRDIE_STATISTICS_REQUESTING';
export const REPORT_EBIRDIE_STATISTICS_REQUESTING_SUCCESS = 'REPORT_EBIRDIE_STATISTICS_REQUESTING_SUCCESS';
export const REPORT_EBIRDIE_STATISTICS_REQUESTING_ERROR = 'REPORT_EBIRDIE_STATISTICS_REQUESTING_ERROR';

export const REPORT_CONFIG_REQUESTING = 'REPORT_CONFIG_REQUESTING';
export const REPORT_CONFIG_REQUESTING_SUCCESS = 'REPORT_CONFIG_REQUESTING_SUCCESS';
export const REPORT_CONFIG_REQUESTING_ERROR = 'REPORT_CONFIG_REQUESTING_ERROR';

export const REPORT_SCORE_STATISTICS_FILE_REQUESTING = 'REPORT_SCORE_STATISTICS_FILE_REQUESTING';
export const REPORT_SCORE_STATISTICS_FILE_REQUESTING_SUCCESS = 'REPORT_SCORE_STATISTICS_FILE_REQUESTING_SUCCESS';
export const REPORT_SCORE_STATISTICS_FILE_REQUESTING_ERROR = 'REPORT_SCORE_STATISTICS_FILE_REQUESTING_ERROR';

export const REPORT_SCORE_STATISTICS_REQUESTING = 'REPORT_SCORE_STATISTICS_REQUESTING';
export const REPORT_SCORE_STATISTICS_REQUESTING_SUCCESS = 'REPORT_SCORE_STATISTICS_REQUESTING_SUCCESS';
export const REPORT_SCORE_STATISTICS_REQUESTING_ERROR = 'REPORT_SCORE_STATISTICS_REQUESTING_ERROR';

export const REPORT_VENDOR_STATISTICS_FILE_REQUESTING = 'REPORT_VENDOR_STATISTICS_FILE_REQUESTING';
export const REPORT_VENDOR_STATISTICS_FILE_REQUESTING_SUCCESS = 'REPORT_VENDOR_STATISTICS_FILE_REQUESTING_SUCCESS';
export const REPORT_VENDOR_STATISTICS_FILE_REQUESTING_ERROR = 'REPORT_VENDOR_STATISTICS_FILE_REQUESTING_ERROR';

// tslint:disable-next-line:max-line-length
export const REPORT_SCORE_HOLES_STATISTICS_FILE_REQUESTING = 'REPORT_SCORE_HOLES_STATISTICS_FILE_REQUESTING';
// tslint:disable-next-line:max-line-length
export const REPORT_SCORE_HOLES_STATISTICS_FILE_REQUESTING_SUCCESS = 'REPORT_SCORE_HOLES_STATISTICS_FILE_REQUESTING_SUCCESS';
// tslint:disable-next-line:max-line-length
export const REPORT_SCORE_HOLES_STATISTICS_FILE_REQUESTING_ERROR = 'REPORT_SCORE_HOLES_STATISTICS_FILE_REQUESTING_ERROR';