import React, { PureComponent, ChangeEvent, RefObject } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as companyActions from '@src/store/company/actions';
import {
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox, InputLabel, Select, MenuItem, FormControl
} from '@material-ui/core';
import { renderFormActions } from '@src/components/modals/ui';
import { DeleteConfirmChildren } from '@src/components/headless/DeleteConfirm';
import DeleteConfirm from '@src/components/headless/DeleteConfirm';
import InputsValidator from '@src/components/forms/InputsValidator';
import AddressFields from '@src/components/forms/ui/AddressFields';
import { getActiveFederationId } from '@src/store/auth/selectors';
import { updateOptionValues } from '@src/utils/storeUtils';

type Props = {
  federationId,
  companyId?: number | undefined;
  companyState: CompanyState;
  parentContext?: 'MODAL' | 'PAGE';
  onClose?: () => void;
  refetch?: () => void;
  addCompany: (params: CompanyAdd) => any;
  editCompany: (params: CompanyEdit) => any;
  deleteCompany: (params: CompanyDelete) => any;
  actionsContainerRef?: RefObject<HTMLElement>;
};

type State = {
  businessId: string;
  name: string;
  status: ActivityStatus;
  additionalInfo?: string | null;
  streetAddress: string;
  streetAddress2?: string | null;
  zip: string;
  poBox?: string | null;
  email: string;
  phone: string;
  advertising: string[];
  contact: string[];
  privacy: 'PRIVATE' |'PUBLIC' | string;
  magazineSubscriber: boolean;
  magazineOptions: string[];
  city: string;
};

const initialState = {
  businessId: '',
  name: '',
  status: 'ACTIVE' as ActivityStatus,
  additionalInfo: null,
  streetAddress: '',
  streetAddress2: null,
  zip: '',
  poBox: null,
  email: '',
  phone: '',
  advertising: [],
  contact: [],
  privacy: 'PRIVATE',
  magazineSubscriber: false,
  magazineOptions: [],
  city: '',
};

class CompanyFormConnected extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const {
      companyId,
      companyState: {
        company,
      }
    } = this.props;

    if (!companyId) {
      this.state = initialState;
    } else if (company) {
      this.state = {
        ...company,
      } as any;
    } else {
      this.state = initialState;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      companyId,
      companyState: {
        company,
        requesting,
      },
    } = nextProps;

    if (!companyId) {
      return; // Do nothing if we are adding new person
    } else if (requesting) {
      return; // Do nothing state is loading
    } else if (company) {
      this.setState({
        ...company as any
      });
    }
  }

  render() {
    const {
      name,
      additionalInfo,
      streetAddress,
      zip,
      email,
      phone,
      advertising,
      contact,
      privacy,
      magazineSubscriber,
      magazineOptions,
      city,
      businessId,
      status,
    } = this.state;

    const {
      companyId,
      parentContext,
      actionsContainerRef,
    } = this.props;

    return (
      <InputsValidator values={{ status, name }}>
        {({ errorInputs, validateThenApply, validateValues }) => (
          <>
          <FormControl required={true} fullWidth={true} margin="normal" error={errorInputs.state}>
            <InputLabel htmlFor="state">
              <FormattedMessage id={'strings.state'}/>
            </InputLabel>
            <Select
              inputProps={{
                name: 'state',
                id: 'state',
              }}
              value={status}
              onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                this.setState({ status: event.target.value as ActivityStatus }, validateValues);
              }}
            >
              <MenuItem value={'ACTIVE'}><FormattedMessage id={'strings.statusActive'}/></MenuItem>
              <MenuItem value={'INACTIVE'}><FormattedMessage id={'strings.statusInactive'}/></MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth={true}
            label={<FormattedMessage id={'strings.businessId'}/>}
            value={businessId || ''}
            margin="normal"
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              this.setState({ businessId: event.currentTarget.value });
            }}
          />
          <TextField
            required={true}
            fullWidth={true}
            error={errorInputs.name}
            label={<FormattedMessage id={'strings.name'}/>}
            value={name || ''}
            margin="normal"
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              this.setState({ name: event.currentTarget.value }, validateValues);
            }}
          />
          <TextField
            fullWidth={true}
            label={<FormattedMessage id={'strings.phone'}/>}
            value={phone || ''}
            margin="normal"
            type="tel"
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              this.setState({ phone: event.currentTarget.value });
            }}
          />
          <TextField
            fullWidth={true}
            label={<FormattedMessage id={'strings.email'}/>}
            value={email || ''}
            type="email"
            margin="normal"
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              this.setState({ email: event.currentTarget.value });
            }}
          />

          <AddressFields
            streetAddress={streetAddress || ''}
            city={city || ''}
            zip={zip || ''}
            onChange={(args) => this.setState({ ...args } as any)}
          />

          <FormGroup row={true}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={privacy === 'PUBLIC'}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const {
                      target: {
                        checked
                      }
                    } = event;
                    this.setState({ privacy: checked ? 'PUBLIC' : 'PRIVATE'});
                  }}
                />
              }
              label={<FormattedMessage id={'userPreferences.publicInfo'}/>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this._checkCheckboxState(contact, 'POST')}
                  onChange={this._handleContactRulesChange('POST')}
                />
              }
              label={<FormattedMessage id={'userPreferences.acceptPostFromUnion'}/>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this._checkCheckboxState(contact, 'PHONE')}
                  onChange={this._handleContactRulesChange('PHONE')}
                />
              }
              label={<FormattedMessage id={'userPreferences.acceptSMSFromUnion'}/>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this._checkCheckboxState(contact, 'EMAIL')}
                  onChange={this._handleContactRulesChange('EMAIL')}
                />
              }
              label={<FormattedMessage id={'userPreferences.acceptEmailFromUnion'}/>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this._checkCheckboxState(advertising, 'POST')}
                  onChange={this._handleAdvertisingRulesChange('POST')}
                />
              }
              label={<FormattedMessage id={'userPreferences.acceptMarketingPost'}/>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this._checkCheckboxState(advertising, 'PHONE')}
                  onChange={this._handleAdvertisingRulesChange('PHONE')}
                />
              }
              label={<FormattedMessage id={'userPreferences.acceptMarketingPhone'}/>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this._checkCheckboxState(advertising, 'EMAIL')}
                  onChange={this._handleAdvertisingRulesChange('EMAIL')}
                />
              }
              label={<FormattedMessage id={'userPreferences.acceptMarketingEmail'}/>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={magazineSubscriber}
                  onChange={this._handleCheckboxChange('magazineSubscriber')}
                />
              }
              label={<FormattedMessage id={'userPreferences.magazineSubscription'}/>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this._checkCheckboxState(magazineOptions, 'DUPLICATE')}
                  onChange={this._handleMagazineOptionsChange('DUPLICATE')}
                />
              }
              label={<FormattedMessage id={'userPreferences.magazineSubscriptionDouble'}/>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this._checkCheckboxState(magazineOptions, 'FREE')}
                  onChange={this._handleMagazineOptionsChange('FREE')}
                />
              }
              label={<FormattedMessage id={'userPreferences.magazineSubscriptionFree'}/>}
            />
          </FormGroup>
          <TextField
            fullWidth={true}
            multiline={true}
            rowsMax={4}
            label={<FormattedMessage id={'strings.additionalInfo'}/>}
            value={additionalInfo || ''}
            margin="normal"
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              this.setState({ additionalInfo: event.currentTarget.value });
            }}
          />
          <DeleteConfirm>
            {({ showConfirm }: DeleteConfirmChildren) => (
              renderFormActions({
                id: companyId,
                parentContext,
                onClose: this._handleOnClose,
                onUpdate: validateThenApply.bind(this, this._handleUpdateCompany),
                onCreate: validateThenApply.bind(this, this._handleCreateCompany),
                disabled: false,
                containerRef: actionsContainerRef,
                onDelete: () => {
                  showConfirm({
                    callback: this._handleDeleteCompany,
                  });
                },
              })
            )}
          </DeleteConfirm>
          </>
        )}
      </InputsValidator>
    );
  }

  private _handleOnClose = (): void => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  }

  private _handleDeleteCompany = (bool: boolean) => {
    const { companyId, deleteCompany } = this.props;

    if (bool && companyId) {
      deleteCompany({
        id: companyId,
        onComplete: this._handleOnDelete
      });
    }
  }

  private _handleCreateCompany = () => {
    const { addCompany, federationId } = this.props;
    const params = this._formCompanyParams();

    addCompany({
      federationId,
      ...params,
      onComplete: this._handleOnComplete,
    });
  }

  private _handleUpdateCompany = () => {
    const { editCompany, federationId } = this.props;
    const {
      companyState: {
        company
      }
    } = this.props;

    if (!company) {
      return;
    }

    editCompany({
      id: company.id,
      federationId,
      ...this._formCompanyParams(),
      onComplete: this._handleOnComplete,
    });
  }

  private _formCompanyParams = () => {
    const {
      businessId,
      name,
      status,
      additionalInfo,
      streetAddress,
      streetAddress2,
      zip,
      poBox,
      email,
      phone,
      advertising,
      contact,
      privacy,
      magazineSubscriber,
      magazineOptions,
      city
    } = this.state;

    return {
      businessId,
      name,
      status,
      additionalInfo,
      streetAddress,
      streetAddress2,
      zip,
      poBox,
      email,
      phone,
      advertising: advertising.toString(),
      contact: contact.toString(),
      privacy,
      magazineSubscriber,
      magazineOptions: magazineOptions.toString(),
      city
    };
  }

  private _handleOnComplete = (result: APICallResult): void => {
    window.console.log('_handleOnComplete', result);
    if (result.error) {
      window.alert(result.error.message);
    } else {
      this._handleOnClose();
      if (!this.props.companyId && this.props.refetch) {
        this.props.refetch();
      }
    }
  }

  private _handleOnDelete = (result: APICallResult): void => {
    if (result.error) {
      window.alert(result.error.message);
    } else {
      this._handleOnClose();
      if (this.props.refetch) {
        this.props.refetch();
      }
    }
  }

  private _checkCheckboxState = (group: Array<string>, value: string): boolean => {
    return group && group.indexOf(value) !== -1;
  }

  private _handleCheckboxChange = name => event => {
    this.setState({ [name]: event.target.checked } as any);
  }

  private _handleContactRulesChange = name => event => {
    const { contact } = this.state;
    this.setState({contact: updateOptionValues([...contact], name, event.target.checked)});
  }

  private _handleAdvertisingRulesChange = name => event => {
    const { advertising } = this.state;
    this.setState({advertising: updateOptionValues([...advertising], name, event.target.checked)});
  }

  private _handleMagazineOptionsChange = name => event => {
    const { magazineOptions } = this.state;
    this.setState({magazineOptions: updateOptionValues([...magazineOptions], name, event.target.checked)});
  }
}

const CompanyForm = connect((state: any) => ({
  companyState: state.companyReducer,
  federationId: getActiveFederationId(state),
}), {
  addCompany: companyActions.addCompany,
  editCompany: companyActions.editCompany,
  deleteCompany: companyActions.deleteCompany,
})(CompanyFormConnected);

export {
  CompanyForm
};