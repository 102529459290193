import React, { PureComponent, ChangeEvent, RefObject } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as clubFeeActions from '@src/store/clubFee/actions';
import { renderFormActions } from '@src/components/modals/ui';
import {
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem, InputAdornment
} from '@material-ui/core';
import InputsValidator from '@src/components/forms/InputsValidator';
import DeleteConfirm, { DeleteConfirmChildren } from '@src/components/headless/DeleteConfirm';

type Props = {
  clubId: number;
  clubFeeId?: number | undefined;
  clubFeeState: ClubFeeState;
  parentContext?: 'MODAL' | 'PAGE';
  onClose?: () => void;
  addClubFee: (params: ClubFeeAdd) => any;
  editClubFee: (params: ClubFeeEdit) => any;
  deleteClubFee: (params: ClubFeeDelete) => any;
  actionsContainerRef?: RefObject<HTMLElement>;
};

type State = {
  status: ActivityStatus;
  name: string;
  description: string | null;
  price: number;
  otherInfo: string | null;
  visibleGuides: boolean;
};

const initialState = {
  status: 'ACTIVE' as ActivityStatus,
  name: '',
  description: null,
  price: 0,
  otherInfo: null,
  visibleGuides: false
};

class ClubFeeFormConnected extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const {
      clubFeeId,
      clubFeeState: {
        clubFee,
      }
    } = this.props;

    if (!clubFeeId) {
      this.state = initialState;
    } else if (clubFee) {
      this.state = {
        ...clubFee,
      } as any;
    } else {
      this.state = initialState;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      clubFeeId,
      clubFeeState: {
        clubFee,
        requesting,
      },
    } = nextProps;

    if (!clubFeeId) {
      return; // Do nothing if we are adding new person
    } else if (requesting) {
      return; // Do nothing state is loading
    } else if (clubFee) {
      this.setState({
        ...clubFee as any
      });
    }
  }

  render() {
    const {
      status,
      name,
      description,
      price,
      otherInfo,
      visibleGuides
    } = this.state;

    const {
      clubFeeId,
      parentContext,
      actionsContainerRef,
    } = this.props;

    return (
      <InputsValidator values={{ name, status, price }}>
        {({ errorInputs, validateThenApply, validateValues }) => (
          <>
            <FormControl required={true} margin="normal">
              <InputLabel htmlFor="fee-status">
                <FormattedMessage id={'scenes.golfClub.fees.form.status'}/>
              </InputLabel>
              <Select
                error={errorInputs.status}
                value={status}
                inputProps={{
                  name: 'status',
                  id: 'fee-status',
                }}
                onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                  this.setState({status: event.target.value as ActivityStatus}, validateValues);
                }}
              >
                <MenuItem value={'ACTIVE'}><FormattedMessage id={'strings.statusActive'}/></MenuItem>
                <MenuItem value={'INACTIVE'}><FormattedMessage id={'strings.statusInactive'}/></MenuItem>
              </Select>
            </FormControl>
            <TextField
              required={true}
              fullWidth={true}
              label={<FormattedMessage id={'scenes.golfClub.fees.form.name'}/>}
              value={name}
              margin="normal"
              name="name"
              onChange={this._handleChange('name', validateValues)}
              error={errorInputs.name}
            />
            <TextField
              fullWidth={true}
              label={<FormattedMessage id={'strings.description'}/>}
              multiline={true}
              rowsMax={4}
              value={description || ''}
              margin="normal"
              name="description"
              onChange={this._handleChange('description')}
            />
            <TextField
              required={true}
              label={<FormattedMessage id={'strings.price'}/>}
              value={price}
              margin="normal"
              name="price"
              onChange={this._handleChange('price', validateValues)}
              InputProps={{
                startAdornment: <InputAdornment position="start" style={{minWidth: 'initial'}}>€</InputAdornment>,
              }}
              error={errorInputs.price}
            />
            <TextField
              fullWidth={true}
              label={<FormattedMessage id={'scenes.golfClub.fees.form.info'}/>}
              value={otherInfo || ''}
              multiline={true}
              rowsMax={4}
              margin="normal"
              name="otherInfo"
              onChange={this._handleChange('otherInfo')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={visibleGuides}
                  onChange={() => {
                    this.setState({visibleGuides: !visibleGuides});
                  }}
                  value="visibleGuides"
                  color="primary"
                />
              }
              label={<FormattedMessage id={'strings.visibleOnGuides'}/>}
            />
            <DeleteConfirm>
              {({ showConfirm }: DeleteConfirmChildren) => (
                  renderFormActions({
                    id: clubFeeId,
                    parentContext,
                    onClose: this._handleOnClose,
                    onUpdate: validateThenApply.bind(this, this._handleUpdateClubFee),
                    onCreate: validateThenApply.bind(this, this._handleCreateClubFee),
                    disabled: false,
                    containerRef: actionsContainerRef,
                    onDelete: () => {
                      showConfirm({
                        callback: this._handleDeleteClubFee,
                      });
                    },
                  })
                )}
            </DeleteConfirm>
          </>
        )}
      </InputsValidator>
    );
  }

  private _handleChange = (name: string, fn?: () => any) => (
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      this.setState({
        [name as any]: event.target.value,
      } as any, fn);
    }
  )

  private _handleOnClose = (): void => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  }

  private _handleDeleteClubFee = (bool: boolean) => {
    const { deleteClubFee, clubId, clubFeeId } = this.props;
    if (bool && clubFeeId) {
      deleteClubFee({
        clubId,
        clubFeeId,
        onComplete: this._handleOnClose,
      });
    }
  }

  private _handleCreateClubFee = () => {
    const { addClubFee, clubId } = this.props;
    const params = this._formClubFeeParams();

    addClubFee({
      clubId: clubId,
      ...params,
      onComplete: this._handleOnComplete,
    });
  }

  private _handleUpdateClubFee = () => {
    const { editClubFee } = this.props;
    const {
      clubId,
      clubFeeId
    } = this.props;

    if (!clubFeeId || !clubId) {
      return;
    }

    const params = this._formClubFeeParams();

    editClubFee({
      clubId: clubId,
      clubFeeId: clubFeeId,
      ...params,
      onComplete: this._handleOnComplete,
    });
  }

  private _formClubFeeParams = () => {
    const {
      status,
      name,
      description,
      price,
      otherInfo,
      visibleGuides
    } = this.state;

    return {
      status,
      name,
      description,
      price,
      otherInfo,
      visibleGuides
    };
  }

  private _handleOnComplete = (result: any): void => {
    if (result.error) {
      window.alert(result.error.message);
    } else {
      this._handleOnClose();
    }
  }
}

const ClubFeeForm = connect((state: any) => ({
  clubFeeState: state.clubFeeReducer,
}), {
  addClubFee: clubFeeActions.addClubFee,
  editClubFee: clubFeeActions.editClubFee,
  deleteClubFee: clubFeeActions.deleteClubFee,
})(ClubFeeFormConnected);

export {
  ClubFeeForm
};