import { call, put } from '@redux-saga/core/effects';
import { CONDENSED_CLUB_COURSES_ERROR, CONDENSED_CLUB_COURSES_SUCCESS } from '@src/store/clubCourses/constants';
import { API_ROOT } from '@src/assets/config';
import { tokenAPIGet } from '@src/utils/storeUtils';

export function* fetchCondensedClubCourseFlow(token: string, clubId: number) {
  try {
    const response = yield call(() => {
      const url = `${API_ROOT}admin/1/courses/club/${clubId}?type=condensed`;
      return tokenAPIGet(url, token);
    });

    if (response) {
      yield put({
        type: CONDENSED_CLUB_COURSES_SUCCESS,
        data: response.courses,
      });
    }
  }  catch (error) {
    yield put({ type: CONDENSED_CLUB_COURSES_ERROR, error });
  }
}