import { IntlShape, MessageDescriptor } from 'react-intl';
import { PrimitiveType } from 'intl-messageformat';

/** Error class for usage with react-intl */
export class IntlError extends Error {
    readonly _message: MessageDescriptor;
    readonly _values?: Record<string, PrimitiveType>;
  
    constructor(message: MessageDescriptor, values?: Record<string, PrimitiveType>) {
      super(`IntlError: ${message.id}`);

      this._message = message;
      this._values = values;
  
      // tslint:disable-next-line:max-line-length
      // Set the prototype explicitly. https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
      Object.setPrototypeOf(this, IntlError.prototype);
    }
  
    /** Helper to format the error to string */
    formatError(intl: IntlShape) {
      return intl.formatMessage(this._message, this._values);
    }
  }