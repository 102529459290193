import { formDefaultErrorState, formDefaultRequestingState } from '@src/utils/storeUtils';
import { REGIONS_ERROR, REGIONS_REQUESTING, REGIONS_SUCCESS } from './constants';
import { SHARED_DEFAULT_REDUCER_STATE } from '@src/assets/config';
const initialState: RegionsState = {
    ...SHARED_DEFAULT_REDUCER_STATE,
    targetIdParams: {},
    requesting: false,
    successful: false,
    error: [],
    searchActive: false,

    regions: [],
};

/** Possible regions reducer action types */
export type RegionsAction = {
    state: RegionsState;
} & {
    type: typeof REGIONS_REQUESTING;
} | {
    type: typeof REGIONS_SUCCESS;
    data: string[];
} | {
    type: typeof REGIONS_ERROR;
};

/** RegionsReducer function type */
export type RegionsReducer = (state: RegionsState, action: RegionsAction) => RegionsState;

export const regionsReducer: RegionsReducer = (state = initialState, action) => {
    
    switch (action.type) {
        // Requesting regions started
        case REGIONS_REQUESTING: return {
            ...formDefaultRequestingState(state),
            regions: [],
        };

        // Request completed succesfully, process data
        case REGIONS_SUCCESS: return {
            ...state,
            requesting: false,
            successful: true,
            regions: action.data.map((name, id) => { return {name, id}; }),
        };

        // There was an error while fetching regions
        case REGIONS_ERROR:
            return formDefaultErrorState(state, action);

        // This is not a regions action
        default:
            return {
                ...state,
                requesting: false,
                successful: true,
            };
    }
    
    return state;
};