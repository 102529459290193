import React, { PureComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { AddNewFunctionaryScene } from '@src/components/scenes/functionary/AddNewFunctionaryScene';

class ClubAddNewFunctionary extends PureComponent<{} & RouteComponentProps<any>> {
  render() {
    const {
      match: {
        params: {
          clubId
        }
      }
    } = this.props;

    return (
      <AddNewFunctionaryScene targetIdParams={{ clubId }}/>
    );
  }
}

export default withRouter(ClubAddNewFunctionary);