import React, { ReactNode } from 'react';
import classNames from 'classnames';
import ReactSelect from 'react-select';
import { Theme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { PropTypes } from '@material-ui/core';
import Margin = PropTypes.Margin;

/* tslint:disable */
const styles = (theme: Theme) => ({
  input: {
    display: 'flex',
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    //margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
    margin: theme.spacing(.5, .25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    // padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
});

const NoOptionsMessage = (props: any) => {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
};

const inputComponent = ({ inputRef, ...props }: any) => {
  return <div ref={inputRef} {...props} />;
};

const Control = (props: any) => {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
};

const Option = (props) => {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
};

const Placeholder = (props: any) => {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
};

const SingleValue = (props: any) => {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
};

const ValueContainer = (props: any) => {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
};

const MultiValue = (props: any) => {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
};

const Menu = (props) => {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
};

type Props = {
  error?: boolean;
  theme: any;
  margin?: Margin;
  options: Array<any>;
  selected?: Array<any> | any;
  classes?: any;
  labelText?: string | ReactNode;
  placeholderText?: string | ReactNode;
  multiSelect?: boolean;
  disabled?: boolean;
  helpText?: React.ReactNode;
  isSearchable?: boolean;
  isClearable?: boolean;
  required?: boolean;
  onChangeCallback(newValue: any): void;
};

type State = {
}

class Select extends React.Component<Props, State> {
  selectElement: any;

  async UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!!(this.props.selected && !(nextProps.selected))) {
      try {
        if (this.selectElement) {
          this.selectElement.select.clearValue();
        }
      } catch (e) {
        window.console.warn(e);
      }
    }
  }

  render() {
    const {
      classes,
      theme,
      selected,
      multiSelect,
      options,
      onChangeCallback,
      disabled,
      margin = 'none',
      isSearchable = true,
      isClearable = true,
      required = false,
      error = false,
    } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
      menuList: base => ({
        ...base,
        maxHeight: 500,
        minWidth: 380,
        maxWidth: 500,
        backgroundColor: '#fff',
      }),
    };

    let value: Array<any> | {value: number, label: string} | undefined = undefined;
    if (selected) {
      if (!multiSelect) {
        value = {value: selected.id, label: selected.name };
      } else {
        value = selected.map(selectedOption => ({
          value: selectedOption.id,
          label: selectedOption.name
        }));
      }
    }

    const selectOptions: Array<any> = options.map(option => ({
      value: option.id,
      label: option.name
    }));

    return React.cloneElement(
      <ReactSelect
        ref={(r: any) => this.selectElement = r}
        isDisabled={disabled}
        isSearchable={isSearchable}
        isClearable={isClearable}
        menuPortalTarget={document.getElementById('select-portal-target')}
        menuPosition={'fixed'}
        styles={selectStyles}
        options={selectOptions}
        components={{
          Control,
          Menu,
          MultiValue,
          NoOptionsMessage,
          Option,
          Placeholder,
          SingleValue,
          ValueContainer,
        }}
        value={value}
        onChange={(selections: any) => {
          if (selections === null) {
            return onChangeCallback(undefined);
          }

          if (multiSelect) {
            return onChangeCallback(selections.map(selection => ({ name: selection.label, id: selection.value })));
          }

          const { value } = selections;
          return onChangeCallback(options.find((item) => item.id === value));
        }}
        placeholder={this.props.placeholderText as any}
        isMulti={this.props.multiSelect}
      />,
      {
        classes,
        textFieldProps: {
          margin,
          label: this.props.labelText,
            InputLabelProps: {
            shrink: true,
          },
          required,
          error,
          disabled,
        }
      } as any,
    );
  }
}

export default withStyles(styles as any, {
  withTheme: true,
  name: 'GGBSelectNamed',
  classNamePrefix: 'SelectComp',
  meta: 'GGBSelect'
})(Select);