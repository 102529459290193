import {
  ORGANIZATIONS_REQUESTING,
  ORGANIZATIONS_SUCCESS,
  ORGANIZATIONS_ERROR,
  ORGANIZATIONS_CHANGE_PAGE,
  ORGANIZATIONS_SEARCH_REQUESTING,
  ORGANIZATIONS_SEARCH_CHANGED,
  ORGANIZATIONS_SEARCH_RESET,
  ORGANIZATIONS_SET_TARGET_ID_PARAMS
} from './constants';
import { SHARED_DEFAULT_REDUCER_STATE } from '@src/assets/config';
import {
  formDefaultErrorState,
  formDefaultPagedState,
  formDefaultRequestingState,
  formDefaultTargetIdState,
  fromDefaultPageChangeState, optimisticPagedObjectUpdate,
  searchStateChangedReducerHelper
} from '@src/utils/storeUtils';
import {
  ORGANIZATION_ADD_SUCCESS,
  ORGANIZATION_EDIT_SUCCESS,
  ORGANIZATION_DELETE_SUCCESS,
} from '@src/store/organization/constants';

const initialState: OrganizationsState = {
  ...SHARED_DEFAULT_REDUCER_STATE,
  pagedOrganizations: {},
  search: {
    searchTerm: '',
    status: '',
  },
  allOrganizations: [],
};

const organizationsReducer = (state: OrganizationsState = initialState, action: any): OrganizationsState => {
  switch (action.type) {
    case ORGANIZATIONS_SET_TARGET_ID_PARAMS:
      return formDefaultTargetIdState(initialState, state, action);

    case ORGANIZATIONS_REQUESTING:
      return formDefaultRequestingState(state);

    case ORGANIZATIONS_SUCCESS:
      return formDefaultPagedState(state, action, 'pagedOrganizations');

    case ORGANIZATIONS_CHANGE_PAGE:
      return fromDefaultPageChangeState(state, action);

    case ORGANIZATIONS_ERROR:
      return formDefaultErrorState(state, action);

    case ORGANIZATIONS_SEARCH_CHANGED: {
      return searchStateChangedReducerHelper(state, action);
    }

    case ORGANIZATIONS_SEARCH_REQUESTING: {
      let searchActive = state.searchActive;
      let pagedOrganizations = state.pagedOrganizations;
      let pagination = state.pagination;

      if (!searchActive) {
        searchActive = true;
        pagedOrganizations = {};
        pagination.totalCount = 0;
      }

      pagination.page = action.page;

      return {
        ...state,
        pagedOrganizations,
        pagination,
        searchActive,
        requesting: true
      };
    }

    case ORGANIZATIONS_SEARCH_RESET:
      return {
        ...initialState,
        requesting: true,
        targetIdParams: state.targetIdParams,
      };

    case ORGANIZATION_ADD_SUCCESS:
    case ORGANIZATION_DELETE_SUCCESS: {
      return {
        ...initialState,
        targetIdParams: state.targetIdParams,
        search: state.search,
        searchActive: state.searchActive,
        pagination: state.pagination,
      };
    }

    case ORGANIZATION_EDIT_SUCCESS: {
      const { data } = action;
      const page = state.pagination.page;

      const pagedOrganizations = optimisticPagedObjectUpdate(state.pagedOrganizations, page, { ...data });

      return {
        ...state,
        pagedOrganizations,
      };
    }

    default:
      return state;
  }
};

export {
  organizationsReducer,
};