import { fork, take } from 'redux-saga/effects';
import { AUTH_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import {
  CLUB_COURSE_TEE_EDIT_REQUESTING,
  CLUB_COURSE_TEE_GET_REQUESTING
} from './constants';

/*
  GET|PUT /admin/1/course/<id>/tee/<teeNumber>
 */

const clubCourseTeeCRUDFlow = (clubCourseId?: number) => {
  return SagaFlowFactory.createCRUDFlow({
    apiRoute: `admin/1/course/${clubCourseId}/tee`,
    typePrefix: 'CLUB_COURSE_TEE',
    resetType: '',
  });
};

function * clubCourseTeeWatcher() {
  let token: string = '';

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
      clubCourseId,
      teeNumber,
      params
    } = yield take([
      CLUB_COURSE_TEE_GET_REQUESTING,
      CLUB_COURSE_TEE_EDIT_REQUESTING
    ]);

    switch (type) {
      case CLUB_COURSE_TEE_GET_REQUESTING:
        yield fork(clubCourseTeeCRUDFlow(clubCourseId), token, 'GET', teeNumber, params);
        break;
      case CLUB_COURSE_TEE_EDIT_REQUESTING:
        yield fork(clubCourseTeeCRUDFlow(clubCourseId), token, 'EDIT', teeNumber, params);
        break;
      default:
    }
  }
}

export default clubCourseTeeWatcher;