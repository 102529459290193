// tslint:disable
import React, { useEffect, useRef, useState} from "react";
import { useSelector } from 'react-redux';
import { Button, Typography, CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab"
import { FormattedMessage } from "react-intl";

import { APIRoute, API_ROOT } from "@src/assets/config";

interface UploadResponse{
    status: "ok";
    url: string;
    fileName: string;
    mimeType: string;
    fileSize: number;
}

interface UploadErrorResponse{
    status: boolean,
    error: {
        code: number,
        message: string,
    },
}

class LocalizedError extends Error{
    readonly id: string;

    constructor(id:string){
        super(id);
        Object.setPrototypeOf(this, LocalizedError.prototype);

        this.id = id;
    }
}

interface ImagePreviewProps{
    src?: string,       // url of the image
    progress: number,   // value 0 to 1
    width: number,      // Image width
    height: number,     // Image height
}

const ImagePreview: React.FC<ImagePreviewProps> = ({src, progress, width, height})=>{
    const tall = height > width;
    const aspectWidth = (tall ? width/height : 1) * 100;
    const aspectHeight = (tall ? 1 : height/width) * 100;

    const containerStyles:React.CSSProperties={
        position: "relative",

        // width: aspectWidth + "%",
        // paddingTop: aspectHeight + "%",
        width: `calc(min(${aspectWidth}%, ${width}px))`,
        paddingTop: `calc(min(${aspectHeight}%, ${height}px))`,

        background: "#cccccc",
        fontSize:"3em",
    }
    const imageStyles:React.CSSProperties={
        width: "100%",
        height: "100%",
        objectFit: "contain",
    }
    const progressStyles:React.CSSProperties={
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,

        background: "#00ff00",
        opacity: 0.4,
        
        width: `${Math.min(progress, 1) * 100}%`,
        display: progress < 1 ? "initial" : "none",

        transition: "width .1s linear",
    }
    const helpTextStyles:React.CSSProperties={
        display: "flex",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,

        justifyContent: "center",
        alignItems: "center",
    }

    // Content is source if present, loading, or size info
    const content = src ? <img src={src} style={imageStyles}/> : 
        (progress < 1) ? <CircularProgress/> : <>{width}x{height}</>;

    return(
        <div style={containerStyles}>
            <div style={progressStyles}/>
            <div style={helpTextStyles}>{content}</div>
        </div>
    )
}

interface MessageImageControllerProps {
    headerIntl: string,
    width: number,
    height: number,
    imageUrl?: string,
    uploadProgress: number,
    handleUpload: React.ChangeEventHandler<HTMLInputElement>,
}

const MessageImageController: React.FC<MessageImageControllerProps> = ({headerIntl, width, height, imageUrl, uploadProgress, handleUpload})=>{
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleImageSelecdtion: React.MouseEventHandler<HTMLButtonElement> = (e)=>{
        e.preventDefault();

        const fileInput = fileInputRef.current;
        if ( fileInput ) fileInput.click();
    }
    
    return(
        <>
            <Typography component="h2" style={{margin: 0}}>
                <FormattedMessage id={headerIntl} />
            </Typography>
            <Button onClick={handleImageSelecdtion} disabled={uploadProgress < 1}>
                <FormattedMessage id={'scenes.offers.form.sections.content.form.uploadImage'} />
            </Button>
            <input
                type={'file'}
                multiple={false}
                hidden={true}
                accept="image/png, image/jpg, image/jpeg"
                onChange={handleUpload}
                ref={fileInputRef}
            />
            <ImagePreview src={imageUrl} progress={uploadProgress} width={width} height={height}/>
        </>
    )
}

interface ImageInfo{
    src?: string,
    progress: number,
    width: number,
    height: number,
    type: MessageImage["type"],
    error?: LocalizedError,
}
export interface MessageImageUploadProps {
    images: MessageImage[];
    onImagesUpdated: (images: MessageImage[])=>void;
}

export const MessageImageUpload: React.FC<MessageImageUploadProps> = ({images, onImagesUpdated}) =>{
    const token = useSelector<StoreState>(state=>state.authReducer.token);
    
    const aborter = useRef(new AbortController).current;

    const [listImageInfo, setListImageInfo] = useState<ImageInfo>({width: 200, height: 200, progress: 1, type: "ListImage"});
    const [contentImageInfo, setContentImageInfo] = useState<ImageInfo>({width: 500, height: 250, progress: 1, type: "ContentImage"});
    

    useEffect(()=>{
        return ()=>{aborter.abort()};
    },[]);

    // Set preview sources
    useEffect(()=>{
        const listImage = images.find(img=>img.type=="ListImage");
        const contentImage = images.find(img=>img.type=="ContentImage");

        setListImageInfo(p=>{ return {...p, src: listImage?.url}} );
        setContentImageInfo(p=>{ return {...p, src: contentImage?.url}} );
    },[images]);

    // const handleImageSelecdtion: React.MouseEventHandler<HTMLButtonElement> = (e)=>{
    //     e.preventDefault();

    //     const fileInput = fileInputRef.current;
    //     if ( fileInput ) fileInput.click();
    // }

    const handleUpload = async (
        event: React.ChangeEvent<HTMLInputElement>,
        values: ImageInfo,
        setter: React.Dispatch<React.SetStateAction<ImageInfo>>
    )=>{
        const target = event.target;
        const files = target.files;
        if(!files) return;

        const file = files[0];

        try
        {
            setter(p=>{return {...p, error:undefined}});
            
            // Validate image
            await new Promise<void>((resolve, reject)=>{
                const fileReader = new FileReader();

                const abortReader = ()=>fileReader.abort();
                aborter.signal.addEventListener("abort", abortReader);

                fileReader.readAsDataURL(file);
                fileReader.onloadend = e=>{
                    aborter.signal.removeEventListener("abort", abortReader);

                    if(!fileReader.result){
                        reject(new LocalizedError("scenes.messages.errors.imageLoadingFailed")); 
                        return;
                    }

                    // Check if the file is image, and it is correctly sized
                    const img = new Image();

                    const abortImg = ()=>{img.src = ""; reject();} // There is no abort function
                    aborter.signal.addEventListener("abort", abortImg);

                    img.src = fileReader.result.toString();
                    img.onload = imgEvent=>{
                        aborter.signal.removeEventListener("abort", abortImg);
                        if(aborter.signal.aborted) reject();

                        if(img.width != values.width || img.height != values.height){
                           reject(new LocalizedError("scenes.messages.errors.invalidDimentions"));
                           return;
                        }
                        
                        resolve();
                    }
                };
            });

            // Start uploading
            setter(p=>{return {...p, src: undefined, progress: 0}});

            // Create upload form data
            const data = new FormData();
            data.append("type", values.type);
            data.append("image", file);

            // Wait for upload to complete
            const request = await new Promise<XMLHttpRequest>((resolve, reject)=>{
                const upload = new XMLHttpRequest();

                const abortUpload = ()=>{upload.abort()};
                aborter.signal.addEventListener("abort", abortUpload);
                upload.onloadend = e=>{aborter.signal.removeEventListener("abort", abortUpload)};
                
                upload.onprogress = e=>{setter(p=>{return {...p, progress: e.loaded/e.total}})};
                upload.onerror = e=>{reject(new LocalizedError("scenes.messages.errors.genericUploadError"))};
                upload.onload = e=>{resolve(upload)};
                upload.open("POST", API_ROOT + APIRoute.MESSAGE_IMAGE_UPLOAD);
                upload.setRequestHeader("Authorization", `Bearer ${token}`);
                upload.send(data);
            });

            // Check upload response for errors
            if(request.status != 200){
                const contentType = request.getResponseHeader("Content-Type");

                if(contentType !== "application/json")
                    throw new LocalizedError("scenes.messages.errors.genericUploadError");

                const errorJson = JSON.parse(request.responseText) as unknown as UploadErrorResponse;

                switch(errorJson.error.message){
                    case "invalid mime type": 
                        throw new LocalizedError("scenes.messages.errors.invalidMimeType");

                    case "invalid file resolution for given type":
                        throw new LocalizedError("scenes.messages.errors.invalidDimentions");

                    case "file upload failed":
                    default:
                        throw new LocalizedError("scenes.messages.errors.genericUploadError");
                }
            }

            // Parse successful upload
            const json = JSON.parse(request.responseText) as unknown as UploadResponse;

            onImagesUpdated([...images, {
                id:       undefined,
                type:     values.type,
                url:      json.url,
                fileName: json.fileName,
                fileSize: json.fileSize,
            }]);
        }
        catch(e)
        {
            console.error(e);

            if(aborter.signal.aborted) return;

            if(e instanceof LocalizedError){
                setter(p=>{return {...p, error: e}});
                return;
            }

            const error = new LocalizedError("scenes.messages.errors.genericUploadError");
            setter(p=>{return {...p, error}});
        }
        finally
        {
            if(aborter.signal.aborted) return;

            setter(p=>{return {...p, progress: 1}});
            target.value = "";
        }
    }

    const errorComponent = (error:LocalizedError|undefined, close:()=>void)=>error && 
        <Alert 
            severity="error"
            onClose={close}
        >
            <FormattedMessage id={error.id}/>
        </Alert>;

    return(
        <>
            
        <div style={{margin:"3em 0", display:"flex", padding:"1em", justifyContent:"center"}}>
            <div style={{flex:1, paddingRight:"5px"}}>
                {errorComponent(listImageInfo.error, ()=>setListImageInfo(p=>{return {...p, error:undefined}}))}

                <MessageImageController
                    handleUpload={(e)=>handleUpload(e, listImageInfo, setListImageInfo)}
                    headerIntl="scenes.offers.form.sections.content.form.listImage"
                    height={listImageInfo.height}
                    width={listImageInfo.width}
                    imageUrl={listImageInfo.src}
                    uploadProgress={listImageInfo.progress}
                />
            </div>

            <div style={{flex:1, paddingLeft:"5px"}}>
                {errorComponent(contentImageInfo.error, ()=>setContentImageInfo(p=>{return {...p, error:undefined}}))}

                <MessageImageController
                    handleUpload={(e)=>handleUpload(e, contentImageInfo, setContentImageInfo)}
                    headerIntl="scenes.offers.form.sections.content.form.contentImage"
                    height={contentImageInfo.height}
                    width={contentImageInfo.width}
                    imageUrl={contentImageInfo.src}
                    uploadProgress={contentImageInfo.progress}
                />
            </div>
        </div>
        </>
    )
}