import { fork, take } from 'redux-saga/effects';
import { AUTH_SET } from '@src/store/auth/constants';
import {
  REPORT_CLUB_COURSE_GUIDE_REQUESTING,
  REPORT_CLUB_MEMBERSHIPS_REQUESTING,
  REPORT_CONFIG_REQUESTING,
  REPORT_EBIRDIE_STATISTICS_REQUESTING,
  REPORT_FUNCTIONARIES_REQUESTING,
  REPORT_GREENCARD_REQUESTING,
  REPORT_MEMBERSHIP_STATISTICS_REQUESTING,
  REPORT_MEMBERSHIPS_REQUESTING,
  REPORT_JOIN_DATE_REQUESTING,
  REPORT_SCORE_STATISTICS_REQUESTING,
  REPORT_SCORE_STATISTICS_FILE_REQUESTING,
  REPORT_VENDOR_STATISTICS_FILE_REQUESTING,
  REPORT_SCORE_HOLES_STATISTICS_FILE_REQUESTING,
} from '@src/store/reports/constants';
import { getReportFlow } from '@src/store/reports/flows/get-report-flow';
import { API_ROOT, APIRoute } from '@src/assets/config';
import { getDownloadMimeType } from '@src/utils/storeUtils';
import {
  getEBirdieStatisticsFlow,
} from '@src/store/reports/flows/get-eBirdie-statistics-flow';
import { getReportsConfigFlow } from '@src/store/reports/flows/get-reports-config-flow';
import { getScoreStatisticsFlow } from './flows/get-score-statistics-flow';

function * reportsWatcher() {
  let token: string = '';

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
      params,
      clubId,
      onComplete,
    } = yield take([
      REPORT_FUNCTIONARIES_REQUESTING,
      REPORT_GREENCARD_REQUESTING,
      REPORT_MEMBERSHIP_STATISTICS_REQUESTING,
      REPORT_CLUB_COURSE_GUIDE_REQUESTING,
      REPORT_MEMBERSHIPS_REQUESTING,
      REPORT_JOIN_DATE_REQUESTING,
      REPORT_CLUB_MEMBERSHIPS_REQUESTING,
      REPORT_EBIRDIE_STATISTICS_REQUESTING,
      REPORT_CONFIG_REQUESTING,
      REPORT_SCORE_STATISTICS_REQUESTING,
      REPORT_SCORE_STATISTICS_FILE_REQUESTING,
      REPORT_VENDOR_STATISTICS_FILE_REQUESTING,
      REPORT_SCORE_HOLES_STATISTICS_FILE_REQUESTING,
    ]);

    switch (type) {

      case REPORT_FUNCTIONARIES_REQUESTING: {
        yield fork(
            getReportFlow,
            token,
            `${API_ROOT}${APIRoute.REPORT_FUNCTIONARIES}`,
            params,
            'REPORT_FUNCTIONARIES',
            `functionaries-report.xlsx`,
            getDownloadMimeType('xlsx'),
            onComplete,
        );
        break;

      }

      case REPORT_GREENCARD_REQUESTING: {
        const exactType = params.reportType === 'excel' ? 'xlsx' : params.reportType;
        yield fork(
          getReportFlow,
          token,
          `${API_ROOT}${APIRoute.REPORT_GREEN_CARD}`,
          params,
          'REPORT_GREENCARD',
          `green-card-report.${exactType}`,
          getDownloadMimeType(exactType),
          onComplete,
        );
        break;
      }

      case REPORT_MEMBERSHIP_STATISTICS_REQUESTING: {
        yield fork(
          getReportFlow,
          token,
          `${API_ROOT}${APIRoute.REPORT_MEMBERSHIP_STATISTICS}`,
          params,
          'REPORT_MEMBERSHIP_STATISTICS',
          'membership-statistics.xlsx',
          getDownloadMimeType('xlsx'),
          onComplete,
        );
        break;
      }

      case REPORT_CLUB_COURSE_GUIDE_REQUESTING: {
        yield fork(getReportFlow,
          token,
          `${API_ROOT}${APIRoute.REPORT_COURSE_GUIDE}`,
          params,
          'REPORT_CLUB_COURSE_GUIDE',
          'missing.xls',
          getDownloadMimeType('xls'),
          onComplete,
        );
        break;
      }

      case REPORT_MEMBERSHIPS_REQUESTING: {
        yield fork(getReportFlow,
          token,
          `${API_ROOT}${APIRoute.REPORT_MEMBERSHIP}`,
          params,
          'REPORT_MEMBERSHIPS',
          'memberships.xlsx',
          getDownloadMimeType('xlsx'),
          onComplete,
        );
        break;
      }

      case REPORT_JOIN_DATE_REQUESTING: {
        yield fork(getReportFlow,
          token,
          `${API_ROOT}${APIRoute.REPORT_JOIN_DATE}`,
          params,
          'REPORT_JOIN_DATE',
          'join_date.xlsx',
          getDownloadMimeType('xlsx'),
          onComplete,
        );
        break;
      }

      case REPORT_CLUB_MEMBERSHIPS_REQUESTING: {
        yield fork(getReportFlow,
          token,
          `${API_ROOT}${APIRoute.REPORT_MEMBERSHIP}/export/${clubId}`,
          params,
          'REPORT_CLUB_MEMBERSHIPS',
          'missing.xlsx',
          getDownloadMimeType('xlsx'),
          onComplete,
        );
        break;
      }

      case REPORT_EBIRDIE_STATISTICS_REQUESTING: {
        yield fork(getEBirdieStatisticsFlow, token, clubId);
        break;
      }

      case REPORT_CONFIG_REQUESTING: {
        yield fork(getReportsConfigFlow, token);
        break;
      }

      case REPORT_SCORE_STATISTICS_FILE_REQUESTING: {
        yield fork(getReportFlow,
          token,
          `${API_ROOT}${APIRoute.REPORT_SCORE_STATISTICS}/export`,
          params,
          'REPORT_SCORE_STATISTICS_FILE',
          'score_statistics.xlsx',
          getDownloadMimeType('xlsx'),
          onComplete,
        );
        break;
      }

      case REPORT_SCORE_STATISTICS_REQUESTING: {
        yield fork(getScoreStatisticsFlow, token, params);
        break;
      }

      case REPORT_VENDOR_STATISTICS_FILE_REQUESTING: {
        yield fork(getReportFlow,
          token,
          `${API_ROOT}${APIRoute.REPORT_VENDOR_STATISTICS}/export`,
          params,
          'REPORT_VENDOR_STATISTICS_FILE',
          'vendor_statistics.xlsx',
          getDownloadMimeType('xlsx'),
          onComplete,
        );
        break;
      }

      case REPORT_SCORE_HOLES_STATISTICS_FILE_REQUESTING: {
        yield fork(getReportFlow,
          token,
          `${API_ROOT}${APIRoute.REPORT_SCORE_HOLES_STATISTICS}/export`,
          params,
          'REPORT_SCORE_HOLES_STATISTICS_FILE',
          'score_holes_statistics.xlsx',
          getDownloadMimeType('xlsx'),
          onComplete,
        );
        break;
      }

      default:
    }
  }
}

export default reportsWatcher;