/**
 * A company comparison function for sort() with name ASC, email ASC, id ASC ordering.
 *
 * @param a The first company for comparison
 * @param b The second company for comparison
 * @returns Number Negative when a < b, zero when a == b, positive when a > b
 */
export const companyCompare = (a: Company, b: Company, l?: string): number => {
  let c: number = !a.name ? !b.name ? 0 : -1 : a.name.localeCompare(b.name, l);
  if (c === 0) {
    c = !a.email ? !b.email ? 0 : -1 : a.email.localeCompare(b.email, l);
    if (c === 0) {
      c = b.id - a.id;
    }
  }
  return c;
};
