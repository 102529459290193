import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import ClubCoursePCC from '@src/components/scenes/club/course/ClubCoursePCC';
import { ModalContentOverlayLoader } from '@src/components/modals/ui';

interface ChildrenArgs {
  showPCC(courseId: number): void;
}

interface Props {
  children(args: ChildrenArgs): React.ReactNode;
}

function ClubCoursePCCDialog({
  children,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [courseId, setCourseId] = useState<number | undefined>(undefined);
  const { formatMessage: f } = useIntl();

  return (
    <>
      <Dialog
        open={open}
        disableBackdropClick={true}
        keepMounted={false}
        maxWidth={'xs'}
        fullWidth={true}
      >
        <DialogTitle>
          {f({ id: 'strings.coursePCC' })}
        </DialogTitle>
        <DialogContent>
          <ClubCoursePCC
            courseId={courseId}
            onLoading={shouldBeLoading => setIsLoading(shouldBeLoading)}
          />

          {isLoading && (<ModalContentOverlayLoader />)}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>
            <FormattedMessage id={'buttons.close'} />
          </Button>
        </DialogActions>
      </Dialog>
      {children({
        showPCC(courseIdToShow) {
          setCourseId(courseIdToShow);
          setOpen(true);
        }
      })}
    </>
  );
}

export default ClubCoursePCCDialog;