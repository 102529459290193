import {
  USERS_REQUESTING,
  USERS_SUCCESS,
  USERS_ERROR,
  USERS_CHANGE_PAGE,
  USERS_SEARCH_REQUESTING,
  USERS_SEARCH_CHANGED,
  USERS_SEARCH_RESET,
  USERS_CONDENSED_SUCCESS,
} from './constants';
import {
  USER_EDIT_SUCCESS
} from '@src/store/user/constants';
import { SHARED_DEFAULT_REDUCER_STATE } from '@src/assets/config';
import {
  formDefaultErrorState,
  formDefaultPagedState,
  formDefaultRequestingState,
  fromDefaultPageChangeState,
  optimisticPagedObjectUpdate
} from '@src/utils/storeUtils';

const initialState: UsersState = {
  ...SHARED_DEFAULT_REDUCER_STATE,
  pagedUsers: {},
  search: {
    searchTerm: '',
    club: undefined,
  },
  allUsers: [],
};

const usersReducer = (state: UsersState = initialState, action: any): UsersState => {
  switch (action.type) {
    case USERS_REQUESTING:
      return formDefaultRequestingState(state);

    case USERS_SUCCESS:
      return formDefaultPagedState(state, action, 'pagedUsers');

    case USERS_CHANGE_PAGE:
      return fromDefaultPageChangeState(state, action);

    case USERS_ERROR:
      return formDefaultErrorState(state, action);

    case USERS_SEARCH_CHANGED: {
      const search = { ...state.search, ...action.search };
      return {
        ...state,
        search
      };
    }

    case USERS_SEARCH_REQUESTING: {
      let searchActive = state.searchActive;
      let pagedUsers = state.pagedUsers;
      let pagination = state.pagination;

      if (!searchActive) {
        searchActive = true;
        pagedUsers = {};
        pagination.totalCount = 0;
      }

      pagination.page = action.page;

      return {
        ...state,
        pagedUsers,
        pagination,
        searchActive,
        requesting: true
      };
    }

    case USERS_SEARCH_RESET:
      return {
        ...initialState,
        requesting: true,
        targetIdParams: state.targetIdParams,
      };

    case USERS_CONDENSED_SUCCESS: {
      return {
        ...state,
        allUsers: action.data.users,
      };
    }

    case USER_EDIT_SUCCESS: {
      const {
        data,
      } = action;
      const page = state.pagination.page;

      const pagedUsers = optimisticPagedObjectUpdate(state.pagedUsers, page, { ...data });

      return {
        ...state,
        pagedUsers
      };
    }

    default:
      return state;
  }
};

export {
  usersReducer
};