import React, { CSSProperties } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton/IconButton';
import { Fab, Icon, PropTypes } from '@material-ui/core';

const defaultButtonColor = 'primary';

type IconButtons = {
  disabled?: boolean;
  onClick?: (args: React.MouseEvent<HTMLElement>) => any;
  color?: PropTypes.Color;
  style?: CSSProperties;
  component?: any;
  size?: 'small' | 'medium';
  ref?: any;
};

export const DeleteIconButton = ({
  disabled,
  onClick,
  color = defaultButtonColor,
  style,
  component,
  size = 'medium',
  ref,
}: IconButtons) => (
  <IconButton
    ref={ref}
    disabled={disabled}
    aria-label="Delete"
    onClick={onClick}
    color={color}
    style={style}
    component={component}
    size={size}
  >
    <DeleteIcon />
  </IconButton>
);

export const EditIconButton = ({
  disabled,
  onClick,
  color = defaultButtonColor,
  style,
  component,
  size = 'medium',
  ref,
}: IconButtons) => (
  <IconButton
    ref={ref}
    disabled={disabled}
    aria-label="Edit"
    onClick={onClick}
    color={color}
    style={style}
    component={component}
    size={size}
  >
    <EditIcon />
  </IconButton>
);

export const EditIconFab = ({
  disabled,
  onClick,
  color = defaultButtonColor,
  style,
  size = 'small',
  component,
  to = '',
  ref,
}: IconButtons & { to?: string }) => (
  <Fab
    ref={ref}
    to={to}
    disabled={disabled}
    style={style}
    size={size}
    aria-label="Edit"
    color={color}
    onClick={onClick}
    component={component}
  >
    <Icon>edit_icon</Icon>
  </Fab>
);