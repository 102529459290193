/**
 * A partner comparison function for sort() with name ASC, id ASC ordering.
 *
 * @param a The first partner for comparison
 * @param b The second partner for comparison
 * @returns Number Negative when a < b, zero when a == b, positive when a > b
 */
export const partnerCompare = (a: Partner, b: Partner, l?: string): number => {
  let c: number = !a.name ? !b.name ? 0 : -1 : a.name.localeCompare(b.name, l);
  if (c === 0) {
    c = b.id - a.id;
  }
  return c;
};