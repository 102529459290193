import {
  FEDERATION_ADD_REQUESTING, FEDERATION_CONTACTS_GET_REQUESTING,
  FEDERATION_DELETE_REQUESTING,
  FEDERATION_EDIT_REQUESTING,
  FEDERATION_GET_REQUESTING,
} from './constants';

export const createFederation = (params: CreateFederation) => {
  return {
    type: FEDERATION_ADD_REQUESTING,
    params,
  };
};

export const editFederation = (params: EditFederation) => {
  const {
    federationId,
    ...restParams
  } = params;

  return {
    type: FEDERATION_EDIT_REQUESTING,
    federationId,
    params: restParams,
  };
};

export const getFederation = ({
  federationId,
  onComplete,
}: GetFederation) => {
  return {
    type: FEDERATION_GET_REQUESTING,
    federationId,
    params: {
      onComplete,
    },
  };
};

export const deleteFederation = ({
  federationId,
  onComplete,
}: DeleteFederation) => {
  return {
    type: FEDERATION_DELETE_REQUESTING,
    federationId,
    params: {
      onComplete,
    },
  };
};

export const getFederationContacts = ({
  federationId,
}: GetFederationContacts) => {
  return {
    type: FEDERATION_CONTACTS_GET_REQUESTING,
    federationId,
  };
};