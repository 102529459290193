import { tokenAPIGet } from '@src/utils/storeUtils';
import { call, put } from 'redux-saga/effects';
import { API_ROOT, APIRoute } from '@src/assets/config';
import { MEMBERSHIP_HANDICAP_ROUNDS_ERROR, MEMBERSHIP_HANDICAP_ROUNDS_SUCCESS } from '@src/store/membership/constants';

/*
  GET /admin/1/handicap/person/<personId>/rounds
 */

export function * getHandicapRoundsFlow(token: string, personId: number) {
  try {
    const response = yield call(() => {
      const url = `${API_ROOT}${APIRoute.HANDICAP}/person/${personId}/rounds`;
      return tokenAPIGet(url, token);
    });

    if (response) {
      yield put({
        type: MEMBERSHIP_HANDICAP_ROUNDS_SUCCESS,
        data: response,
      });
    }

  } catch (error) {
    yield put({ type: MEMBERSHIP_HANDICAP_ROUNDS_ERROR, error });
  }

  return true;
}