/*
  GET /admin/1/club/{id}
 */
import { API_ROOT, APIRoute } from '@src/assets/config';
import { tokenAPIGet } from '@src/utils/storeUtils';
import { call, put } from 'redux-saga/effects';
import { CLUB_ERROR, CLUB_SUCCESS } from '@src/store/club/constants';

export function * fetchClubFlow(token: string, clubId: number) {
  try {
    const club = yield call(() => {
      const url = `${API_ROOT}${APIRoute.GET_CLUB}/${clubId}`;
      return tokenAPIGet(url, token);
    });

    if (club) {
      yield put({
        type: CLUB_SUCCESS,
        club
      });
    } else {
      yield put({ type: CLUB_ERROR, error: 'club-not-found' });
    }

  } catch (error) {
    yield put({ type: CLUB_ERROR, error });
  }

  return true;
}