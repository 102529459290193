import React, { PureComponent, ChangeEvent, RefObject } from 'react';
import { connect } from 'react-redux';
import * as clubCourseActions from '@src/store/clubCourse/actions';
import { renderFormActions } from '@src/components/modals/ui';
import { FormattedMessage } from 'react-intl';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import InputsValidator from '@src/components/forms/InputsValidator';
import { pick, get } from 'lodash';

interface OwnProps {
  clubId: number;
  clubCourseId?: number | undefined;
  parentContext?: 'MODAL' | 'PAGE';
  actionsContainerRef?: RefObject<HTMLElement>;
  onClose?(): void;
}

interface StateProps {
  auth: AuthState;
  clubCourseState: ClubCourseState;
}

interface DispatchProps {
  addClubCourse(params: ClubCourseAdd): void;
  editClubCourse(params: ClubCourseEdit): any;
}

type Props = OwnProps & StateProps & DispatchProps;

type State = {
  courseId?: number;
  status: ActivityStatus;
  name: string;
  holeAmount: number;
  designerName: string;
  completionTime: string;
  description: string | null;
  characterOfCourse: string | null;
  courseType: string;
  type: string;
};

const initialState: State = {
  courseId: undefined,
  status: 'ACTIVE' as ActivityStatus,
  name: '',
  holeAmount: 18,
  designerName: '',
  completionTime: '',
  description: null,
  characterOfCourse: null,
  courseType: 'FRONT',
  type: 'ORDINARY',
};

class ClubCourseFormConnected extends PureComponent<Props, State> {
  readonly state: State = initialState;

  static getDerivedStateFromProps(props: Props, state: State) {
    const { clubCourseId, clubCourseState } = props;

    if (clubCourseId !== state.courseId) {
      if (!clubCourseState.clubCourse) {
        return initialState;
      }

      const holeAmount = clubCourseState.clubCourse.holes.length;
      const firstHole = get(clubCourseState, 'clubCourse.holes[0].holeNumber', 1);
      const courseType = holeAmount === 18 ? 'FULL' : (firstHole === 1 ? 'FRONT' : 'BACK');

      return {
        ...clubCourseState.clubCourse,
        courseId: clubCourseState.clubCourse.id,
        holeAmount,
        courseType,
      };
    }

    return null;
  }

  render() {
    const {
      clubCourseId,
      parentContext,
      actionsContainerRef,
    } = this.props;

    const {
      name,
      holeAmount,
      designerName,
      completionTime,
      description,
      characterOfCourse,
      courseType,
      type,
    } = this.state;

    return (
      <InputsValidator values={{ name, holeAmount, designerName, completionTime }}>
        {({ errorInputs, validateThenApply, validateValues }) => (
          <>
            <TextField
              required={true}
              fullWidth={true}
              label={<FormattedMessage id={'strings.name'} />}
              value={name}
              margin="normal"
              name={'name'}
              onChange={this._handleChange('name', validateValues)}
              error={errorInputs.name}
            />

            <FormControl
              required={true}
              error={errorInputs.holeAmount}
              fullWidth={true}
              margin={'normal'}
              disabled={true}
            >
              <InputLabel htmlFor="hole-count">
                <FormattedMessage id={'scenes.golfClub.courses.form.holeCount'} />
              </InputLabel>
              <Select
                error={errorInputs.holeAmount}
                value={holeAmount}
                onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                  this.setState({ holeAmount: Number(event.target.value) }, validateValues);
                }}
                inputProps={{
                  name: 'holes',
                  id: 'hole-count',
                }}
              >
                <MenuItem value={18}>18</MenuItem>
                <MenuItem value={9}>9</MenuItem>
              </Select>
            </FormControl>

            {this._isNineHoles && (
              <FormControl fullWidth={true} margin={'normal'}>
                <InputLabel htmlFor={'front-or-back-nine'}>
                  <FormattedMessage id={'scenes.golfClub.courses.form.fairwayNumberingStart'} />
                </InputLabel>
                <Select
                  value={courseType}
                  onChange={this._handleChange('courseType')}
                >
                  <MenuItem value={'FRONT'}>1</MenuItem>
                  <MenuItem value={'BACK'}>10</MenuItem>
                </Select>
              </FormControl>
            )}

            {!this._isNineHoles && (
              <FormControl fullWidth={true} margin={'normal'} disabled={this._isNineHoles}>
                <InputLabel htmlFor={'front-or-back-nine'}>
                  <FormattedMessage id={'scenes.golfClub.courses.form.type'} />
                </InputLabel>
                <Select
                  value={this._isNineHoles ? 'PARTIAL' : type}
                  onChange={this._handleChange('type')}
                >
                  <MenuItem value={'ORDINARY'}>
                    <FormattedMessage id={'strings.ordinary'} />
                  </MenuItem>
                  <MenuItem value={'EXTRAORDINARY'}>
                    <FormattedMessage id={'strings.extraordinary'} />
                  </MenuItem>
                  {this._isNineHoles && (
                    <MenuItem value={'PARTIAL'}>
                      <FormattedMessage id={'strings.partial'} />
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            )}

            <TextField
              required={true}
              fullWidth={true}
              label={<FormattedMessage id={'scenes.golfClub.course.basicInfo.designedBy'} />}
              value={designerName}
              margin="normal"
              name={'designerName'}
              onChange={this._handleChange('designerName', validateValues)}
              error={errorInputs.designerName}
            />

            <TextField
              required={true}
              fullWidth={true}
              label={<FormattedMessage id={'scenes.golfClub.course.basicInfo.completionYear'} />}
              value={completionTime}
              margin="normal"
              name={'completionTime'}
              onChange={this._handleChange('completionTime', validateValues)}
              error={errorInputs.completionTime}
            />

            <TextField
              fullWidth={true}
              multiline={true}
              rowsMax={4}
              label={<FormattedMessage id={'scenes.golfClub.course.basicInfo.characterOfCourse'} />}
              value={characterOfCourse || ''}
              margin="normal"
              name={'characterOfCourse'}
              onChange={this._handleChange('characterOfCourse')}
            />

            <TextField
              fullWidth={true}
              multiline={true}
              rowsMax={4}
              label={<FormattedMessage id={'scenes.golfClub.course.basicInfo.description'} />}
              value={description || ''}
              margin="normal"
              name={'description'}
              onChange={this._handleChange('description')}
            />

            {renderFormActions({
              id: clubCourseId,
              parentContext,
              onClose: this._handleOnClose,
              onCreate: clubCourseId ? undefined : validateThenApply.bind(this, this._handleCreate),
              onUpdate: clubCourseId ? validateThenApply.bind(this, this._handleUpdate) : undefined,
              disabled: false,
              containerRef: actionsContainerRef,
            })}
          </>
        )}
      </InputsValidator>
    );
  }

  get _isNineHoles() {
    return this.state.holeAmount === 9;
  }

  _handleChange = (name: string, fn?: () => any) => (
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      this.setState({
        [name as any]: event.target.value,
      } as any, fn);
    }
  )

  _handleOnClose = () => {
    const { onClose } = this.props;
    if (onClose) {
      this.setState(initialState, onClose);
    }
  }

  _handleCreate = () => {
    const { addClubCourse } = this.props;
    const params = this._formParams();

    addClubCourse({
      ...params,
      onComplete: this._handleOnComplete,
    });
  }

  _handleUpdate = () => {
    const {
      clubCourseId,
      editClubCourse,
      clubCourseState: {
        clubCourse
      }
    } = this.props;

    if (!clubCourse || !clubCourseId) {
      return;
    }

    const params = this._formParams();

    editClubCourse({
      id: clubCourseId,
      ...params,
      onComplete: this._handleOnComplete,
    });
  }

  _formParams = () => {
    const { clubId  } = this.props;

    let params = pick(this.state, this._isNineHoles ? [
      'status',
      'name',
      'holeAmount',
      'designerName',
      'completionTime',
      'description',
      'characterOfCourse',
      'courseType',
    ] : [
      'status',
      'name',
      'holeAmount',
      'designerName',
      'completionTime',
      'description',
      'characterOfCourse',
      'courseType',
      'type',
    ]);

    return {
      clubId,
      ...params,
    };
  }

  _handleOnComplete = (result: any) => {
    if (result.error) {
      window.alert(result.error.message);
    } else {
      this._handleOnClose();
    }
  }
}

const ClubCourseForm = connect<StateProps, DispatchProps, OwnProps, StoreState>(state => ({
  clubCourseState: state.clubCourseReducer,
  auth: state.authReducer,
}), {
  addClubCourse: clubCourseActions.addClubCourse,
  editClubCourse: clubCourseActions.editClubCourse,
})(ClubCourseFormConnected);

export {
  ClubCourseForm
};
