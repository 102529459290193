import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  status: ActivityStatus;
  draftCourseId?: number;
}

function ClubCourseStatus({
  status,
  draftCourseId,
}: Props) {
  if (!!draftCourseId) {
    return (
      <span style={{ textTransform: 'uppercase' }}>
        <FormattedMessage id={'strings.draft'} />
      </span>
    );
  }

  return (<>{status}</>);
}

export default ClubCourseStatus;