import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from '@material-ui/core';

interface Props {
  disabled?: boolean;
  value?: boolean;
  onChange: () => void;
}

class FunctionaryCheckbox extends Component<Props, {}> {

  render() {
    const { value, disabled, onChange } = this.props;
    return (
      <FormControl
        required={false}
      >

        <FormGroup row={true}>
          <FormControlLabel
            control={(
              <Checkbox
                color={'primary'}
                disabled={disabled}
                checked={value}
                onChange={onChange}
                value={true}
              />
            )}
            label={<FormattedMessage id={'strings.only_functionaries'}/>}
          />
        </FormGroup>
      </FormControl>
    );
  }

}

export default FunctionaryCheckbox;