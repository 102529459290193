import React from 'react';
import { connect } from 'react-redux';
import * as authActions from '@src/store/auth/actions';

interface Props {
  auth: AuthState;
  partnerId?: number;
  clubId?: number;
  setActiveState: (activeState: AuthActiveState) => any;
  resetAction: () => any;
}

class ActiveStateListenerConnected extends React.Component<Props, {}> {

  constructor(props: Props) {
    super(props);

    const {
      auth: {
        activeState,
      },
      partnerId,
      clubId,
    } = this.props;

    if (activeState) {
      if (partnerId) {
        this._notifyPartnerStateChange(partnerId);
      } else if (activeState.partner && partnerId && activeState.partner.id !== partnerId) {
        this._notifyPartnerStateChange(partnerId);
      } else if (clubId) {
        this._notifyClubStateChange(clubId);
      } else if (activeState.club && clubId && activeState.club.id !== clubId) {
        this._notifyClubStateChange(clubId);
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      partnerId,
      clubId,
    } = this.props;

    if (!partnerId && !clubId) {
      return;
    } else if (partnerId) {
      if (nextProps.partnerId && partnerId !== nextProps.partnerId) {
        this._notifyPartnerStateChange(nextProps.partnerId);
      }
    } else if (clubId) {
      if (nextProps.clubId && clubId !== nextProps.clubId) {
        this._notifyClubStateChange(nextProps.clubId);
      }
    }
  }

  render() {
    return null;
  }

  _notifyPartnerStateChange = (partnerId: number) => {
    const {
      auth: {
        partners,
        roleInfo,
      },
      setActiveState,
      resetAction
    } = this.props;

    if (partners && partnerId) {
      /*
      Trigger state change for the whole UI.
     */
      setActiveState({
        club: undefined,
        partner: partners.find(partner => partner.id === partnerId),
        roleInfo: roleInfo!,
      });

      /*
        After change we'll need to clear out everything
     */
      resetAction();
    }
  }

  _notifyClubStateChange = (clubId: number) => {
    const {
      auth: {
        clubs,
        roleInfo,
      },
      setActiveState,
      resetAction,
    } = this.props;

    if (clubs) {
      setActiveState({
        club: clubs.find(club => club.id === clubId),
        partner: undefined,
        roleInfo: roleInfo!,
      });

      resetAction();
    }
  }
}

export const ActiveStateListener = connect((state: any) => ({
  auth: state.authReducer,
}), {
  setActiveState: authActions.setActiveState,
})(ActiveStateListenerConnected);