export const CLUB_SERVICE_GET_REQUESTING = 'CLUB_SERVICE_GET_REQUESTING';
export const CLUB_SERVICE_GET_SUCCESS = 'CLUB_SERVICE_GET_SUCCESS';
export const CLUB_SERVICE_GET_ERROR = 'CLUB_SERVICE_GET_ERROR';

export const CLUB_SERVICE_ADD_REQUESTING = 'CLUB_SERVICE_ADD_REQUESTING';
export const CLUB_SERVICE_ADD_SUCCESS = 'CLUB_SERVICE_ADD_SUCCESS';
export const CLUB_SERVICE_ADD_ERROR = 'CLUB_SERVICE_ADD_ERROR';

export const CLUB_SERVICE_EDIT_REQUESTING = 'CLUB_SERVICE_EDIT_REQUESTING';
export const CLUB_SERVICE_EDIT_SUCCESS = 'CLUB_SERVICE_EDIT_SUCCESS';
export const CLUB_SERVICE_EDIT_ERROR = 'CLUB_SERVICE_EDIT_ERROR';

export const CLUB_SERVICE_DELETE_REQUESTING = 'CLUB_SERVICE_DELETE_REQUESTING';
export const CLUB_SERVICE_DELETE_SUCCESS = 'CLUB_SERVICE_DELETE_SUCCESS';
export const CLUB_SERVICE_DELETE_ERROR = 'CLUB_SERVICE_DELETE_ERROR';