import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import * as clubActions from '@src/store/club/actions';
import { Dialog, DialogContent, DialogTitle, FormControl, TextField } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { ModalContentOverlayLoader, renderFormActions } from '@src/components/modals/ui';
import InputsValidator from '@src/components/forms/InputsValidator';
import AddressFields from '@src/components/forms/ui/AddressFields';
import { RegionSelect } from '@src/components/forms/RegionSelect';
import { FederationRoleScopeGuard } from '@src/components/access-control/FederationRoleScopeGuard';
import * as regionActions from '@src/store/regions/actions';

type Props = {
  clubState: ClubState;
  onClose?: () => void;
  open: boolean;
  editClubBasicInfo: (params: EditClubBasicInfo) => any;
  regionsState: RegionsState;
  fetchRegions: () => any;
};

type State = {
  isLoading: boolean;
  name: string;
  nameCompany?: string;
  abbreviation: string;
  yearFoundation: string | number;
  email: string;
  phone: string;
  homepage: string;
  fax?: string;
  streetAddress: string;
  zip: string;
  city: string;
  drivingInstructions: string;
  otherInfo?: string;
  region: string;
};

const initialState = {
  isLoading: false,
  name: '',
  nameCompany: '',
  abbreviation: '',
  yearFoundation: '',
  email: '',
  phone: '',
  homepage: '',
  fax: '',
  streetAddress: '',
  zip: '',
  city: '',
  drivingInstructions: '',
  otherInfo: '',
  region: ''
};

class ClubBasicInfoModal extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const {
      clubState: {
        courseGuide,
      },
    } = this.props;

    if (courseGuide) {
      this.state = {
        isLoading: false,
        ...this._parseAttributes(courseGuide),
      } as any;
    } else {
      this.state = initialState;
    }
  }

  componentDidMount() {
    const {
      regionsState,
      fetchRegions,
    } = this.props;

    if (regionsState.regions.length === 0) {
      fetchRegions();
    } else {
      console.log('regionsState = ', regionsState);
    }
  }
  
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      clubState: {
        requesting,
        courseGuide,
      },
      open,
    } = nextProps;

    if (requesting || open) {
      return; // Do nothing state is loading
    } else if (courseGuide) {
      this.setState(this._parseAttributes(courseGuide));
    }
  }

  render() {
    const { open } = this.props;
    const {
      name,
      nameCompany,
      abbreviation,
      yearFoundation,
      email,
      phone,
      homepage,
      fax,
      streetAddress,
      zip,
      city,
      drivingInstructions,
      otherInfo,
      region,
      isLoading,
    } = this.state;

    const selectedRegion: RegionEntry = {id: region, name: region};

    return (
      <Dialog open={open}>
        <DialogTitle>
          <FormattedMessage id={'navigation.golfClubInfo'} />
        </DialogTitle>

        {isLoading && <ModalContentOverlayLoader/>}

        <InputsValidator
          values={{
            name,
            nameCompany,
            abbreviation,
            yearFoundation,
            email,
            phone,
            homepage,
            streetAddress,
            zip,
            city,
            region
          }}
        >
          {({ errorInputs, validateThenApply, validateValues }) => (
            <>
              <DialogContent>
                <TextField
                  required={true}
                  fullWidth={true}
                  label={<FormattedMessage id={'strings.name'} />}
                  value={name || undefined}
                  margin="normal"
                  onChange={this._handleChange('name', validateValues)}
                  error={errorInputs.name}
                />

                <TextField
                  required={true}
                  fullWidth={true}
                  label={<FormattedMessage id={'strings.companyName'} />}
                  value={nameCompany || undefined}
                  margin="normal"
                  onChange={this._handleChange('nameCompany', validateValues)}
                  error={errorInputs.nameCompany}
                />

                <TextField
                  required={true}
                  fullWidth={true}
                  label={<FormattedMessage id={'strings.clubAbbreviation'} />}
                  value={abbreviation || undefined}
                  margin="normal"
                  onChange={this._handleChange('abbreviation', validateValues)}
                  error={errorInputs.abbreviation}
                />

                <TextField
                  required={true}
                  fullWidth={true}
                  label={<FormattedMessage id={'strings.founded'} />}
                  value={yearFoundation || undefined}
                  margin="normal"
                  onChange={this._handleChange('yearFoundation', validateValues)}
                  error={errorInputs.yearFoundation}
                />

                <TextField
                  required={true}
                  fullWidth={true}
                  label={<FormattedMessage id={'strings.phone'} />}
                  value={phone || undefined}
                  margin="normal"
                  onChange={this._handleChange('phone', validateValues)}
                  error={errorInputs.phone}
                />

                <TextField
                  fullWidth={true}
                  label={<FormattedMessage id={'strings.fax'} />}
                  value={fax || undefined}
                  margin="normal"
                  onChange={this._handleChange('fax')}
                />

                <TextField
                  required={true}
                  fullWidth={true}
                  label={<FormattedMessage id={'strings.email'} />}
                  value={email || undefined}
                  margin="normal"
                  onChange={this._handleChange('email', validateValues)}
                  error={errorInputs.email}
                />

                <TextField
                  required={true}
                  fullWidth={true}
                  label={<FormattedMessage id={'strings.homepage'} />}
                  value={homepage || undefined}
                  margin="normal"
                  onChange={this._handleChange('homepage', validateValues)}
                  error={errorInputs.homepage}
                />

                <TextField
                  multiline={true}
                  fullWidth={true}
                  label={<FormattedMessage id={'strings.drivingInstructions'} />}
                  value={drivingInstructions || undefined}
                  margin="normal"
                  onChange={this._handleChange('drivingInstructions')}
                  rows={2}
                />

                <AddressFields
                  streetAddress={streetAddress || ''}
                  city={city || ''}
                  zip={zip || ''}
                  onChange={(args) => this.setState({ ...args } as any, validateValues)}
                  requiredInputs={{ streetAddress: true, city: true, zip: true }}
                  errorInputs={errorInputs}
                />
        <FederationRoleScopeGuard>
          <FormControl 
            // disabled={requesting} 
            style={{ marginRight: '1em' }}
          >
            <RegionSelect
                onChangeCallback={(values: RegionEntry) => {
                  if (values) {
                    this.setState({region: values.name});
                  }
                }}
                multi={false}
                selectedRegions={selectedRegion}
            />
          </FormControl>
        </FederationRoleScopeGuard>
                <TextField
                  multiline={true}
                  fullWidth={true}
                  label={<FormattedMessage id={'strings.other'} />}
                  value={otherInfo}
                  margin="normal"
                  onChange={this._handleChange('otherInfo', validateValues)}
                  rows={2}
                />

              </DialogContent>

              {renderFormActions({
                id: 1,
                parentContext: 'MODAL',
                onClose: this._handleOnClose,
                onUpdate: validateThenApply.bind(this, this._handleUpdate),
                disabled: false,
              })}
            </>
          )}
        </InputsValidator>
      </Dialog>
    );
  }

  private _handleChange = (name: string, fn?: () => any) => (
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      this.setState({
        [name as any]: event.target.value,
      } as any, fn);
    }
  )

  private _parseAttributes = (courseGuide: ClubCourseGuide) => {
    const {
      club: {
        name,
        abbreviation,
        nameCompany,
        email,
        fax,
        phone,
        homepage,
        streetAddress,
        zip,
        city,
        otherInfo,
        region
      },
      extraInfo: {
        drivingInstructions,
        yearFoundation,
      }
    } = courseGuide;

    return {
      name,
      nameCompany,
      abbreviation,
      yearFoundation,
      email,
      phone,
      homepage,
      fax,
      streetAddress,
      zip,
      city,
      drivingInstructions,
      otherInfo,
      region
    };
  }

  private _handleOnClose = () => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  }

  private _handleUpdate = () => {
    const {
      editClubBasicInfo,
      clubState: {
        courseGuide,
      },
    } = this.props;

    this.setState({ isLoading: true }, () => {
      const {
        name,
        nameCompany,
        abbreviation,
        yearFoundation,
        email,
        phone,
        homepage,
        fax,
        streetAddress,
        zip,
        city,
        drivingInstructions,
        otherInfo,
        region
      } = this.state;

      editClubBasicInfo({
        clubId: courseGuide!.club.id,
        params: {
          name,
          nameCompany,
          abbreviation,
          yearFoundation: `${yearFoundation}`,
          email,
          phone,
          homepage,
          fax,
          streetAddress,
          zip,
          city,
          drivingInstructions,
          otherInfo,
          region
        },
        onComplete: this._handleAfterUpdate,
      });
    });
  }

  private _handleAfterUpdate = ({ error }) => {
    this.setState({ isLoading: false }, () => {
      if (error) {
        return window.alert(error);
      }

      this._handleOnClose();
    });
  }
}

export default connect((state: StoreState) => ({
    regionsState: state.regionsReducer,
    clubState: state.clubReducer
}), {
  editClubBasicInfo: clubActions.editClubBasicInfo,
  fetchRegions: regionActions.fetchRegions,
})(ClubBasicInfoModal);