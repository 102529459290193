import React, { createRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import { ModalContentOverlayLoader } from '@src/components/modals/ui';
import * as partnerActions from '@src/store/partner/actions';
import { PartnerForm } from '@src/components/forms/PartnerForm';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import { FormattedMessage } from 'react-intl';

interface Props {
  partnerId: number | undefined;
  open: boolean;
  onClose: () => void;
  getPartner: (params: PartnerGet) => any;
  partner: PartnerState;
}

class PartnerModalConnected extends React.Component<Props, {}> {
  actionsContainerRef = createRef<HTMLDivElement>();

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      partnerId,
      getPartner
    } = this.props;

    if (nextProps.partnerId !== partnerId) {
      if (nextProps.partnerId) {
        getPartner({
          id: nextProps.partnerId,
        });
      }
    }
  }

  render() {
    const {
      open,
      onClose,
      partnerId,
      partner: {
        requesting,
      },
    } = this.props;

    return (
      <Dialog
        open={open}
        fullWidth={true}
      >
        <DialogTitle>
          {partnerId && <FormattedMessage id={'scenes.partners.modal.edit.title'} />}
          {!partnerId && <FormattedMessage id={'scenes.partners.modal.add.title'} />}
        </DialogTitle>
        <DialogContent>
          {requesting && <ModalContentOverlayLoader/>}
          <PartnerForm
            partnerId={partnerId}
            parentContext={'MODAL'}
            onClose={onClose}
            actionsContainerRef={this.actionsContainerRef}
          />
        </DialogContent>

        <div ref={this.actionsContainerRef} />
      </Dialog>
    );
  }
}

const PartnerModal = connect((state: any) => ({
  partner: state.partnerReducer,
}), {
  getPartner: partnerActions.getPartner
})(PartnerModalConnected);

export {
  PartnerModal,
};
