import { call, put } from 'redux-saga/effects';
import { API_ROOT, APIRoute } from '@src/assets/config';
import { tokenAPIGet } from '@src/utils/storeUtils';
import {
  FEDERATION_CONTACTS_GET_ERROR,
  FEDERATION_CONTACTS_GET_SUCCESS
} from '@src/store/federation/constants';

/*
  GET /admin/1/federation/{id}/contacts
 */
export function * getFederationContactsFlow(token: string, federationId: number) {
  try {
    const response = yield call(() => {
      const url = `${API_ROOT}${APIRoute.FEDERATION}/${federationId}/contacts`;
      return tokenAPIGet(url, token);
    });

    if (response) {
      const {
        functionaries,
      } = response;

      yield put({
        type: FEDERATION_CONTACTS_GET_SUCCESS,
        data: functionaries || [],
      });
    }

  } catch (error) {
    yield put({ type: FEDERATION_CONTACTS_GET_ERROR, error });
  }

  return true;
}