export const COMPANIES_REQUESTING = 'COMPANIES_REQUESTING';
export const COMPANIES_SUCCESS = 'COMPANIES_SUCCESS';
export const COMPANIES_ERROR = 'COMPANIES_ERROR';

export const COMPANIES_CHANGE_PAGE = 'COMPANIES_CHANGE_PAGE';

export const FEDERATION_COMPANIES_SEARCH_REQUEST: string = 'FEDERATION_COMPANIES_SEARCH_REQUEST';
export const FEDERATION_COMPANIES_SEARCH_RESET: string = 'FEDERATION_COMPANIES_SEARCH_RESET';
export const FEDERATION_COMPANIES_SEARCH_CHANGED: string = 'FEDERATION_COMPANIES_SEARCH_CHANGED';

export const COMPANIES_RESET = 'COMPANIES_RESET';