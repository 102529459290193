import { take, fork, select } from 'redux-saga/effects';
import {
  CLUBS_REQUESTING,
  CLUBS_SEARCH_REQUESTING,
  CLUBS_SUCCESS,
  CLUBS_ERROR,
  CLUBS_SEARCH_RESET,
  CLUBS_CONDENSED_SUCCESS,
} from './constants';
import { API_ROOT, APIRoute } from '@src/assets/config';
import { AUTH_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { LOGIN_SUCCESS } from '@src/store/login/constants';
import { normalizeSearchParams, stateTargetIdParams } from '@src/utils/storeUtils';

/*
  GET /api/1/clubs/
 */
const fetchClubsFlow = SagaFlowFactory.createPagedGETFlow({
  apiRoute: APIRoute.GET_CLUBS,
  itemType: 'clubs',
  successType: CLUBS_SUCCESS,
  errorType: CLUBS_ERROR
});

/*
  GET /api/1/clubs/search
 */
const fetchClubsSearchFlow = SagaFlowFactory.createPagedGETFlow({
  apiRoute: APIRoute.SEARCH_CLUBS,
  itemType: 'clubs',
  successType: CLUBS_SUCCESS,
  errorType: CLUBS_ERROR
});

/*
  GET /admin/1/clubs?type=condensed
 */
const fetchClubsCondensed = SagaFlowFactory.createGETFlow({
  urlRoute: `${API_ROOT}${APIRoute.GET_CLUBS_CONDENSED}`,
  successType: CLUBS_CONDENSED_SUCCESS,
  errorType: CLUBS_ERROR,
});

function * clubsWatcher() {
  let token: string = '';

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
      page,
      limit,
      params
    } = yield take([
      CLUBS_REQUESTING,
      CLUBS_SEARCH_REQUESTING,
      CLUBS_SEARCH_RESET,
      LOGIN_SUCCESS,
    ]);

    const targetIdParams = yield select(stateTargetIdParams('clubsReducer'));

    switch (type) {
      case LOGIN_SUCCESS:
        yield fork(fetchClubsCondensed, token, 0, 0, { ...params, ...targetIdParams, type: 'condensed' });
        break;
      case CLUBS_REQUESTING:
        yield fork(fetchClubsFlow, token, page, limit, { ...params, ...targetIdParams });
        break;
      case CLUBS_SEARCH_REQUESTING:
        yield fork(
            fetchClubsSearchFlow,
            token,
            page,
            limit,
            { ...normalizeSearchParams(params), ...targetIdParams }
        );
        break;
      case CLUBS_SEARCH_RESET:
        // NOP
        break;
      default:
    }
  }
}

export default clubsWatcher;