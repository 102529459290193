import { combineReducers } from 'redux';
import { authReducer } from '@src/store/auth/reducer';
import { loginReducer } from '@src/store/login/reducer';
import { locale } from '@src/store/locale/reducer';
import { usersReducer } from '@src/store/users/reducer';
import { userReducer } from '@src/store/user/reducer';
import { clubsReducer } from '@src/store/clubs/reducer';
import { partnersReducer } from '@src/store/partners/reducer';
import { partnerReducer } from '@src/store/partner/reducer';
import { maintenancesReducer } from '@src/store/maintenances/reducer';
import { maintenanceReducer } from '@src/store/maintenance/reducer';
import { offersReducer } from '@src/store/offers/reducer';
import { offerReducer } from '@src/store/offer/reducer';
import { messagesReducer } from '@src/store/messages/reducer';
import { messageReducer } from '@src/store/message/reducer';
import { clubReducer } from '@src/store/club/reducer';
import { federationsReducer } from '@src/store/federations/reducer';
import { personsReducer } from '@src/store/persons/reducer';
import { personReducer } from '@src/store/person/reducer';
import { vendorsReducer } from '@src/store/vendors/reducer';
import { vendorReducer } from '@src/store/vendor/reducer';
import { companiesReducer } from '@src/store/companies/reducer';
import { companyReducer } from '@src/store/company/reducer';
import { federationReducer } from '@src/store/federation/reducer';
import { membershipReducer } from '@src/store/membership/reducer';
import { clubFeeReducer } from '@src/store/clubFee/reducer';
import { clubFeesReducer } from '@src/store/clubFees/reducer';
import { clubServicesReducer } from '@src/store/clubServices/reducer';
import { clubGreenCardsReducer } from '@src/store/clubGreencards/reducer';
import { clubGreenCardReducer } from '@src/store/clubGreencard/reducer';
import { clubCoursesReducer } from '@src/store/clubCourses/reducer';
import { organizationsReducer } from '@src/store/organizations/reducer';
import { organizationReducer } from '@src/store/organization/reducer';
import { clubCourseReducer } from '@src/store/clubCourse/reducer';
import { clubCourseTeeReducer } from '@src/store/clubCourseTee/reducer';
import { clubCourseHoleReducer } from '@src/store/clubCourseHole/reducer';
import { clubServiceReducer } from '@src/store/clubService/reducer';
import { functionariesReducer } from '@src/store/functionaries/reducer';
import { functionaryReducer } from '@src/store/functionary/reducer';
import { functionaryTitlesReducer } from '@src/store/functionarytitles/reducer';
import { functionaryTitleReducer } from '@src/store/functionarytitle/reducer';
import { reportsReducer } from '@src/store/reports/reducer';
import { report2sReducer } from '@src/store/report2s/reducer';
import { report2Reducer } from '@src/store/report/reducer';
import { invalidatedScoresReducer } from '@src/store/invalidatedScores/reducer';
import { acceptableScoresReducer } from '@src/store/acceptableScores/reducer';
import { acceptableScoreReducer } from '@src/store/acceptableScore/reducer';
import { notificationsReducer } from '@src/store/notifications/reducer';
import { regionsReducer } from '@src/store/regions/reducer';

const indexReducer = combineReducers({
  authReducer,
  loginReducer,
  locale,
  usersReducer,
  userReducer,
  clubsReducer,
  clubReducer,
  organizationsReducer,
  organizationReducer,
  partnersReducer,
  partnerReducer,
  maintenanceReducer,
  maintenancesReducer,
  offersReducer,
  offerReducer,
  messagesReducer,
  messageReducer,
  federationsReducer,
  personsReducer,
  personReducer,
  vendorsReducer,
  vendorReducer,
  companiesReducer,
  companyReducer,
  federationReducer,
  membershipReducer,
  clubFeeReducer,
  clubCourseReducer,
  clubCourseTeeReducer,
  clubCourseHoleReducer,
  clubFeesReducer,
  clubServicesReducer,
  clubGreenCardsReducer,
  clubGreenCardReducer,
  clubCoursesReducer,
  clubServiceReducer,
  functionariesReducer,
  functionaryReducer,
  functionaryTitlesReducer,
  functionaryTitleReducer,
  reportsReducer,
  report2sReducer,
  report2Reducer,
  invalidatedScoresReducer,
  acceptableScoresReducer,
  acceptableScoreReducer,
  notificationsReducer,
  regionsReducer,
});

export default indexReducer;