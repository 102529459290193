import React, { PureComponent, Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps, FormattedHTMLMessage } from 'react-intl';
import { Button, FormControl, Typography } from '@material-ui/core';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import {
  ButtonLoaderWrap,
  ContentHeader,
  ContentHeaderTitle,
  ContentWrap
} from '@src/components/layouts/ui';
import { connect } from 'react-redux';
import * as reportsActions from '@src/store/reports/actions';
import LocalizedDatePicker from '@src/components/forms/LocalizedDatePicker';
import moment from 'moment';
import get from 'lodash/get';

interface StateProps {
  reportsConfig?: ReportsConfig;
}

interface DispatchProps {
  getMembershipStatisticsReport(params: GetMembershipStatisticsReport): void;
}

type Props = StateProps & DispatchProps & WrappedComponentProps;

type State = {
  loading: boolean;
  date?: Date | string;
};

class ReportsMemberStatisticsScene extends PureComponent<Props, State> {
  state: State = {
    loading: false,
    date: new Date(),
  };

  render() {
    const { loading, date } = this.state;

    return (
      <Fragment>
        <HeadHelmet titleId={'navigation.reportMemberStatistics'} />
        <ContentWrap>
          <ContentHeader>
            <ContentHeaderTitle>
              <FormattedMessage id={'scenes.reports.memberStatistics.sectionTitle'} />
            </ContentHeaderTitle>
          </ContentHeader>

          <Typography component="p" gutterBottom={true}>
            <FormattedMessage id={'scenes.reports.memberStatistics.description'} />
          </Typography>

          <div style={{ marginTop: 18 }}>
            <FormControl disabled={loading}>
              <LocalizedDatePicker
                disabled={loading}
                value={date}
                onChange={(newDate: Date) => {
                  this.setState({ date: newDate });
                }}
                maxDate={new Date()}
                minDate={this._minDate}
              />
            </FormControl>

            <Typography component="p" style={{ marginTop: 12 }}>
              <FormattedHTMLMessage id={'scenes.reports.memberStatistics.dateDescription'} />
            </Typography>
          </div>

          <ButtonLoaderWrap loading={loading} style={{ marginTop: 30 }}>
            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              onClick={this._handleLoadOnClick}
            >
              <FormattedMessage id={'buttons.download'} />
            </Button>
          </ButtonLoaderWrap>
        </ContentWrap>
      </Fragment>
    );
  }

  get _minDate() {
    const minimumStatsDate = get(this.props, 'reportsConfig.minimumStatsDate');

    if (minimumStatsDate) {
      return moment(minimumStatsDate).toDate();
    }

    return undefined;
  }

  private _handleLoadOnClick = () => {
    this.setState({ loading: true }, this._handleSending);
  }

  private _handleSending = () => {
    const { getMembershipStatisticsReport } = this.props;
    const { date } = this.state;

    getMembershipStatisticsReport({
      date,
      onComplete: ({ data , error}) => {
        window.console.log(data, error);
        this.setState({ loading: false });
      }
    });
  }
}

export default connect<StateProps, DispatchProps, {}, StoreState>(
  (state) => ({
    reportsConfig: state.reportsReducer.config,
  }), {
    getMembershipStatisticsReport: reportsActions.getMembershipStatisticsReport,
  },
)(injectIntl(ReportsMemberStatisticsScene));