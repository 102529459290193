import React from 'react';
import { Tooltip } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { FormattedMessage } from 'react-intl';

type Props = {
  visible?: boolean;
};

function CourseDataIncompleteNotice({
  visible = true,
}: Props) {
  if (!visible) {
    return null;
  }

  return (
    <Tooltip title={<FormattedMessage id={'strings.courseDataIncomplete'} />}>
      <WarningIcon style={{ verticalAlign: 'middle', opacity: .65 }} />
    </Tooltip>
  );
}

export default CourseDataIncompleteNotice;