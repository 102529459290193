import React, { ChangeEvent, PureComponent } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import * as personActions from '@src/store/person/actions';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormLabel
} from '@material-ui/core';
import { renderFormActions } from '@src/components/modals/ui';
import FunctionaryTitleSelect from '@src/components/forms/FunctionaryTitleSelect';
import LocalizedDatePicker from '@src/components/forms/LocalizedDatePicker';
import memoizeOne from 'memoize-one';

type Props = {
  addPerson: (params: PersonAdd) => any;
  onCreate?: (person: APICallResult) => any;
  onClose?: () => any;
  functionaryTitlesState: FunctionaryTitlesState;
};

type State = {
  nameFirst: string;
  nameMiddle: string;
  nameLast: string;
  phone: string;
  email: string;
  streetAddress: string;
  personStatus: ActivityStatus;
  gender: Gender;
  visibleOnFieldGuide: boolean;
  birthDate: Date | null;
  functionaryTitles?: CondensedTitle | number | undefined;
};

type MemoizeFunctionaryTitleValueType = (titles: CondensedTitle[], selection: number) => CondensedTitle;

class EditPersonForm extends PureComponent<Props & WrappedComponentProps, State> {
  protected memoizeFunctionaryTitleValue =
      memoizeOne<MemoizeFunctionaryTitleValueType>((allTitles, functionaryTitle) =>
          allTitles.filter(allTitle => functionaryTitle === allTitle.id )[0]);

  state = {
    nameFirst: '',
    nameMiddle: '',
    nameLast: '',
    phone: '',
    email: '',
    streetAddress: '',
    personStatus: 'ACTIVE' as ActivityStatus,
    gender: '',
    visibleOnFieldGuide: false,
    birthDate: null,
    functionaryTitles: 0,
  };

  render() {
    const {
      nameFirst,
      nameMiddle,
      nameLast,
      phone,
      email,
      streetAddress,
      personStatus,
      gender,
      visibleOnFieldGuide,
      birthDate,
      functionaryTitles
    } = this.state;

    const {
      functionaryTitlesState
    } = this.props;

    const functionaryValue =
        this.memoizeFunctionaryTitleValue(functionaryTitlesState.allTitles, functionaryTitles as number ?? []);

    return (
      <>
        <FormControl required={true} fullWidth={true}>
          <InputLabel htmlFor="state">
            <FormattedMessage id={'strings.state'}/>
          </InputLabel>
          <Select
            inputProps={{
              name: 'state',
              id: 'state',
            }}
            value={personStatus}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
              this.setState({personStatus: event.target.value as ActivityStatus});
            }}
          >
            <MenuItem value={'ACTIVE'}><FormattedMessage id={'strings.statusActive'}/></MenuItem>
            <MenuItem value={'INACTIVE'}><FormattedMessage id={'strings.statusInactive'}/></MenuItem>
          </Select>
        </FormControl>
        <FormControl
          required={true}
          fullWidth={true}
        >
          <FunctionaryTitleSelect
            margin={'normal'}
            multi={false}
            selectedTitles={functionaryValue}
            onChangeCallback={(value: any = { id: undefined }) => {
                const { id } = value;
                this.setState({ functionaryTitles: id });
            }}
          />
        </FormControl>

        <FormControl fullWidth={true}>
          <InputLabel htmlFor="gender">
            <FormattedMessage id={'strings.gender'}/>
          </InputLabel>
          <Select
            inputProps={{
              name: 'gender',
              id: 'gender',
            }}
            value={gender}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
              this.setState({gender: event.target.value as Gender});
            }}
          >
            <MenuItem value={'MALE'}>MALE</MenuItem>
            <MenuItem value={'FEMALE'}>FEMALE</MenuItem>
          </Select>
        </FormControl>
        <TextField
          required={true}
          fullWidth={true}
          id="nameFirst"
          label={<FormattedMessage id={'strings.firstName'} />}
          value={nameFirst}
          margin="normal"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const { currentTarget: { value } } = e;
            this.setState({
              nameFirst: value,
            });
          }}
        />

        <TextField
          fullWidth={true}
          id="nameMiddle"
          label={<FormattedMessage id={'strings.middleName'} />}
          value={nameMiddle}
          margin="normal"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const { currentTarget: { value } } = e;
            this.setState({
              nameMiddle: value,
            });
          }}
        />

        <TextField
          required={true}
          fullWidth={true}
          id="nameLast"
          label={<FormattedMessage id={'strings.lastName'} />}
          value={nameLast}
          margin="normal"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const { currentTarget: { value } } = e;
            this.setState({
              nameLast: value,
            });
          }}
        />

        <FormControl margin="normal" style={{alignContent: 'flex-start'}}>
          <FormLabel><FormattedMessage id={'strings.dateOfBirth'} /></FormLabel>
          <LocalizedDatePicker
            value={birthDate}
            disablePast={true}
            onChange={(newDate: Date) => {
              this.setState({
                birthDate: new Date(newDate)
              });
            }}
            clearable={true}
          />
        </FormControl>

        <TextField
          required={true}
          fullWidth={true}
          id="phone"
          type="tel"
          label={<FormattedMessage id={'strings.phone'} />}
          value={phone}
          margin="normal"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const { currentTarget: { value } } = e;
            this.setState({
              phone: value,
            });
          }}
        />

        <TextField
          required={true}
          fullWidth={true}
          type="email"
          id="email"
          label={<FormattedMessage id={'strings.email'} />}
          value={email}
          margin="normal"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const { currentTarget: { value } } = e;
            this.setState({
              email: value,
            });
          }}
        />

        <TextField
          required={true}
          fullWidth={true}
          id="streetAddress"
          label={<FormattedMessage id={'strings.streetAddress'} />}
          value={streetAddress}
          margin="normal"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const { currentTarget: { value } } = e;
            this.setState({
              streetAddress: value,
            });
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={visibleOnFieldGuide}
              onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({visibleOnFieldGuide: e.target.checked})}
              value="visibleMembers"
            />
          }
          label={<FormattedMessage id={'strings.visibleInCourseGuide'} />}
        />
        {renderFormActions({
          parentContext: 'MODAL',
          onClose: this._handleOnClose,
          onCreate: this._handleCreate
        })}
      </>
    );
  }

  private _handleOnClose = (): void => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  }

  private _handleCreate = () => {
    const { addPerson } = this.props;
    const params = this._formParams();

    addPerson({
      ...params,
      onComplete: this.props.onCreate,
    });
  }

  private _formParams = () => {
    const {
      nameFirst,
      nameMiddle,
      nameLast,
      phone,
      email,
      streetAddress,
      personStatus,
      gender,
      visibleOnFieldGuide,
      birthDate
    } = this.state;

    return {
      nameFirst,
      nameMiddle,
      nameLast,
      phone,
      email,
      streetAddress,
      personStatus,
      gender,
      visibleOnFieldGuide,
      birthDate
    };
  }
}

export default injectIntl(connect( (state: any) => ({
    functionaryTitlesState: state.functionaryTitlesReducer
}), {
  addPerson: personActions.addPerson
})(EditPersonForm));