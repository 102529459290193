import {
  ORGANIZATIONS_REQUESTING,
  ORGANIZATIONS_CHANGE_PAGE,
  ORGANIZATIONS_SEARCH_CHANGED,
  ORGANIZATIONS_SEARCH_REQUESTING,
  ORGANIZATIONS_SEARCH_RESET,
  ORGANIZATIONS_SET_TARGET_ID_PARAMS,
} from './constants';
import {
  DEFAULT_PAGINATION_LIMIT,
} from '@src/assets/config';
import { createTargetIdParamsActionsWithType } from '@src/utils/storeUtils';

export const setTargetIdParams = createTargetIdParamsActionsWithType(ORGANIZATIONS_SET_TARGET_ID_PARAMS);

export const fetchOrganizations = ({
  page,
  limit = DEFAULT_PAGINATION_LIMIT,
  params
}: TablePaginationAction) => {
  return {
    type: ORGANIZATIONS_REQUESTING,
    page,
    limit,
    params
  };
};

export const changePage = ({
  page,
}: ChangePageAction) => {
  return {
    type: ORGANIZATIONS_CHANGE_PAGE,
    page,
  };
};

export const searchChanged = (search: OrganizationsSearch) => {
  return {
    type: ORGANIZATIONS_SEARCH_CHANGED,
    search
  };
};

export const fetchSearch = ({
  page,
  limit = DEFAULT_PAGINATION_LIMIT,
  params
}: SearchPaginationAction) => {
  return {
    type: ORGANIZATIONS_SEARCH_REQUESTING,
    page,
    limit,
    params,
  };
};

export const resetSearch = ({
}) => {
  return {
    type: ORGANIZATIONS_SEARCH_RESET,
  };
};