import {
  CLUB_COURSE_HOLE_EDIT_ERROR,
  CLUB_COURSE_HOLE_EDIT_REQUESTING,
  CLUB_COURSE_HOLE_EDIT_SUCCESS,
  CLUB_COURSE_HOLE_GET_ERROR,
  CLUB_COURSE_HOLE_GET_REQUESTING,
  CLUB_COURSE_HOLE_GET_SUCCESS
} from './constants';
import { formDefaultErrorState, formDefaultRequestingState } from '@src/utils/storeUtils';

const initialState: ClubCourseHoleState = {
  clubCourseHole: undefined,
  requesting: false,
  successful: false,
  error: [],
};

export const clubCourseHoleReducer = (state: ClubCourseHoleState = initialState, action: any) => {
  switch (action.type) {
    case CLUB_COURSE_HOLE_GET_REQUESTING:
      return {
        ...formDefaultRequestingState(state),
        clubCourseHole: undefined,
      };

    case CLUB_COURSE_HOLE_EDIT_REQUESTING:
      return formDefaultRequestingState(state);

    case CLUB_COURSE_HOLE_GET_ERROR:
    case CLUB_COURSE_HOLE_EDIT_ERROR:
      return formDefaultErrorState(state, action);

    case CLUB_COURSE_HOLE_GET_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
        clubCourseHole: action.data,
      };

    case CLUB_COURSE_HOLE_EDIT_SUCCESS:
      const { data } = action;
      return {
        ...state,
        requesting: false,
        successful: true,
        clubCourseHole: data,
      };

    default:
      return state;
  }
};