import { GMAPS_API_KEY } from '@src/assets/config';

export const formatAddressGMapsUrl = (address: string): string => {
  return `https://www.google.com/maps/search/?api=1&query=${address}`;
};

export const formatGMapsStaticImageURL = (address: string, size: string = '1000x1000'): string => {
  return `url('https://maps.googleapis.com/maps/api/staticmap?` +
    `key=${GMAPS_API_KEY}&center=${address}&zoom=13&scale=1&` +
    `size=${size}&maptype=roadmap&format=png&visual_refresh=true&` +
    `markers=color:0x268d51%7C${address}')`;
};