import { fork, take } from 'redux-saga/effects';
import { AUTH_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { PARTNERS_RESET } from '@src/store/partners/constants';
import { APIRoute } from '@src/assets/config';
import {
  PARTNER_ADD_REQUESTING, PARTNER_DELETE_REQUESTING, PARTNER_EDIT_REQUESTING,
  PARTNER_GET_REQUESTING
} from './constants';

/*
  GET|POST|PUT|DELETE /admin/1/partner/<id>
 */
const fetchPartnerCRUDFlow = SagaFlowFactory.createCRUDFlow({
  apiRoute: APIRoute.PARTNER,
  typePrefix: 'PARTNER',
  resetType: PARTNERS_RESET,
});

function * partnerWatcher() {
  let token: string = '';

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
      partnerId,
      params
    } = yield take([
      PARTNER_GET_REQUESTING,
      PARTNER_ADD_REQUESTING,
      PARTNER_EDIT_REQUESTING,
      PARTNER_DELETE_REQUESTING
    ]);

    switch (type) {
      case PARTNER_GET_REQUESTING:
        yield fork(fetchPartnerCRUDFlow, token, 'GET', partnerId, params);
        break;
      case PARTNER_ADD_REQUESTING:
        yield fork(fetchPartnerCRUDFlow, token, 'ADD', null, params);
        break;
      case PARTNER_EDIT_REQUESTING:
        yield fork(fetchPartnerCRUDFlow, token, 'EDIT', partnerId, params);
        break;
      case PARTNER_DELETE_REQUESTING:
        yield fork(fetchPartnerCRUDFlow, token, 'DELETE', partnerId, params);
        break;
      default:
    }
  }
}

export default partnerWatcher;