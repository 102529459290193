import {
  CLUB_GREENCARD_ADD_REQUESTING,
  CLUB_GREENCARD_DELETE_REQUESTING,
  CLUB_GREENCARD_EDIT_REQUESTING,
  CLUB_GREENCARD_GET_REQUESTING
} from '@src/store/clubGreencard/constants';

export const getClubGreenCard = ({
  id
}: ClubGreenCardGet) => {
  return {
    type: CLUB_GREENCARD_GET_REQUESTING,
    greenCardId: id,
  };
};

export const addClubGreenCard = (params: ClubGreenCardAdd) => {
  return {
    type: CLUB_GREENCARD_ADD_REQUESTING,
    params
  };
};

export const editClubGreenCard = (params: ClubGreenCardEdit) => {
  return {
    type: CLUB_GREENCARD_EDIT_REQUESTING,
    greenCardId: params.id,
    params
  };
};

export const deleteClubGreenCard = ({
  id,
  onComplete,
}: ClubGreenCardDelete) => {
  return {
    type: CLUB_GREENCARD_DELETE_REQUESTING,
    greenCardId: id,
    params: {
      onComplete,
    },
  };
};