import { call } from 'redux-saga/effects';
import { API_ROOT, APIRoute } from '@src/assets/config';
import { tokenAPIPut } from '@src/utils/storeUtils';

export function * putOrganizationPersonAttribute(
  token: string,
  organizationId: number,
  personId: number,
  action: string,
  onComplete?: (params: APICallResult) => any,
) {
  try {
    const response = yield call(() => {
      const url = `${API_ROOT}${APIRoute.ORGANIZATION}/${organizationId}/person/${personId}/${action}`;
      return tokenAPIPut(url, token, {});
    });

    if (onComplete) {
      onComplete({
        data: {
          response,
          action,
          organizationId,
          personId,
        },
      });
    }

  } catch (error) {
    if (onComplete) {
      onComplete({
        data: {
          action,
          organizationId,
          personId,
        },
        error,
      });
    }
  }

  return true;
}