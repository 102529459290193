import React, { PureComponent, Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import {
  ButtonLoaderWrap,
  ContentHeader,
  ContentHeaderTitle,
  ContentWrap
} from '@src/components/layouts/ui';
import {
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  FormLabel,
  InputLabel,
  Select,
  MenuItem,
  Grid
} from '@material-ui/core';
import * as reportsActions from '@src/store/reports/actions';
import { connect } from 'react-redux';
import LocalizedDatePicker from '@src/components/forms/LocalizedDatePicker';
import moment from 'moment';

type Props = {
  getMembershipsReport: (params: GetMembershipsReport) => any;
  getJoinDateReport: (params: GetJoinDateReport) => any;
};

type State = {
  loading: boolean;
  dateFrom: Date;
  dateTo: Date;
  options: {
    nro: boolean;
    clubId: boolean;
    clubAbbreviation: boolean;
    memberNro: boolean;
    name: boolean;
    gender: boolean;
    dateOfBirth: boolean;
    streetAddress: boolean;
    postalCode: boolean;
    city: boolean;
    country: boolean;
    hcp: boolean;
    email: boolean;
    phone: boolean;
    homeClub: boolean;
    magazine: boolean;
    advertising: boolean;
    isBillsPaid: boolean;
    joinDate: boolean;
  };
  filters: {
    clubStatus: string;
    clubSeasonStatus: string;
    personStatus: string;
    personMembershipStatus: string;
    billsPaidStatus: string;
    eBirdieStatus: string;
    paperMagazineStatus: string;
  }
};

class ReportsMemberInfoScene extends PureComponent<Props & WrappedComponentProps, State> {

  state: State = {
    loading: false,
    dateFrom: new Date(new Date().getFullYear(), 0, 1),
    dateTo: new Date(),
    options: {
      nro: true,
      clubId: true,
      clubAbbreviation: true,
      memberNro: true,
      name: true,
      gender: true,
      dateOfBirth: true,
      streetAddress: true,
      postalCode: true,
      city: true,
      country: true,
      hcp: true,
      email: true,
      phone: true,
      homeClub: true,
      magazine: true,
      advertising: true,
      isBillsPaid: true,
      joinDate: true,
    },
    filters: {
      clubStatus: 'ANY',
      clubSeasonStatus: 'ANY',
      personStatus: 'ANY',
      personMembershipStatus: 'ANY',
      billsPaidStatus: 'ANY',
      eBirdieStatus: 'ANY',
      paperMagazineStatus: 'ANY',
    }
  };

  render() {
    const { loading } = this.state;

    return (
      <Fragment>
        <HeadHelmet titleId={'navigation.reportGreenCard'} />
        <ContentWrap>
          <ContentHeader>
            <ContentHeaderTitle>
              <FormattedMessage id={'scenes.reports.memberInfo.sectionTitle'} />
            </ContentHeaderTitle>
          </ContentHeader>
          <FormGroup>
            <FormControl>
              <FormLabel component="legend"><FormattedMessage id={'scenes.reports.memberInfo.columns'} /></FormLabel>
              <FormGroup style={{ maxHeight: 170, marginTop: 15 }}>
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.nro}
                      onChange={this._handleChange('nro')}
                      value="nro"
                    />
                  }
                  label={<FormattedMessage id={'scenes.reports.memberInfo.selections.number'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.clubId}
                      onChange={this._handleChange('clubId')}
                      value="clubId"
                    />
                  }
                  label={<FormattedMessage id={'scenes.reports.memberInfo.selections.clubId'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.clubAbbreviation}
                      onChange={this._handleChange('clubAbbreviation')}
                      value="clubAbbreviation"
                    />
                  }
                  label={<FormattedMessage id={'scenes.reports.memberInfo.selections.clubAbbreviation'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.memberNro}
                      onChange={this._handleChange('memberNro')}
                      value="memberNro"
                    />
                  }
                  label={<FormattedMessage id={'strings.memberNo'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.joinDate}
                      onChange={this._handleChange('joinDate')}
                      value="joinDate"
                    />
                  }
                  label={<FormattedMessage id={'scenes.reports.memberInfo.selections.joinDate'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.name}
                      onChange={this._handleChange('name')}
                      value="name"
                    />
                  }
                  label={<FormattedMessage id={'strings.name'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.gender}
                      onChange={this._handleChange('gender')}
                      value="gender"
                    />
                  }
                  label={<FormattedMessage id={'strings.gender'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.dateOfBirth}
                      onChange={this._handleChange('dateOfBirth')}
                      value="dateOfBirth"
                    />
                  }
                  label={<FormattedMessage id={'strings.dateOfBirth'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.streetAddress}
                      onChange={this._handleChange('streetAddress')}
                      value="streetAddress"
                    />
                  }
                  label={<FormattedMessage id={'strings.streetAddress'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.postalCode}
                      onChange={this._handleChange('postalCode')}
                      value="postalCode"
                    />
                  }
                  label={<FormattedMessage id={'strings.postalCode'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.city}
                      onChange={this._handleChange('city')}
                      value="city"
                    />
                  }
                  label={<FormattedMessage id={'strings.city'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.country}
                      onChange={this._handleChange('country')}
                      value="country"
                    />
                  }
                  label={<FormattedMessage id={'strings.country'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.hcp}
                      onChange={this._handleChange('hcp')}
                      value="hcp"
                    />
                  }
                  label={<FormattedMessage id={'strings.hcp'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.email}
                      onChange={this._handleChange('email')}
                      value="email"
                    />
                  }
                  label={<FormattedMessage id={'strings.email'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.phone}
                      onChange={this._handleChange('phone')}
                      value="phone"
                    />
                  }
                  label={<FormattedMessage id={'strings.phone'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.homeClub}
                      onChange={this._handleChange('homeClub')}
                      value="homeClub"
                    />
                  }
                  label={<FormattedMessage id={'strings.homeClub'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.magazine}
                      onChange={this._handleChange('magazine')}
                      value="magazine"
                    />
                  }
                  label={<FormattedMessage id={'scenes.reports.memberInfo.selections.magazine'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.advertising}
                      onChange={this._handleChange('advertising')}
                      value="advertising"
                    />
                  }
                  label={<FormattedMessage id={'scenes.reports.memberInfo.selections.advertising'} />}
                />
                <FormControlLabel
                    control={
                      <Switch
                          color={'primary'}
                          disabled={loading}
                          checked={this.state.options.isBillsPaid}
                          onChange={this._handleChange('isBillsPaid')}
                          value="isBillsPaid"
                      />
                    }
                    label={<FormattedMessage id={'scenes.reports.memberInfo.selections.isBillsPaid'} />}
                />
              </FormGroup>
            </FormControl>
          </FormGroup>
          <FormGroup style={{ marginTop: 30 }}>
            <FormControl>
              <FormLabel component="legend"><FormattedMessage id={'scenes.reports.memberInfo.filters'} /></FormLabel>
              <Grid container={true} spacing={5}>
                <Grid item={true}>
                  <FormGroup style={{ marginTop: 10 }}>
                    <FormControl disabled={loading} margin="normal" style={{marginRight: 15 }}>
                      <InputLabel htmlFor="club-status">
                        <FormattedMessage id={'scenes.reports.memberInfo.selections.clubStatus'}/>
                      </InputLabel>
                      <Select
                          disabled={loading}
                          value={this.state.filters.clubStatus}
                          inputProps={{
                            name: 'club-status',
                            id: 'club-status',
                          }}
                          onChange={this._handleFilterChange('clubStatus')}
                      >
                        <MenuItem value={'ACTIVE'}><FormattedMessage id={'strings.statusActive'}/></MenuItem>
                        <MenuItem value={'PASSIVE'}><FormattedMessage id={'strings.statusPassive'}/></MenuItem>
                        <MenuItem value={'ANY'}><FormattedMessage id={'strings.statusAny'}/></MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl disabled={loading} margin="normal" style={{marginRight: 15}}>
                <InputLabel htmlFor="club-season-status">
                  <FormattedMessage id={'scenes.reports.memberInfo.selections.clubSeasonStatus'}/>
                </InputLabel>
                <Select
                    disabled={loading}
                    value={this.state.filters.clubSeasonStatus}
                    inputProps={{
                      name: 'club-season-status',
                      id: 'club-season-status',
                    }}
                    onChange={this._handleFilterChange('clubSeasonStatus')}
                >
                  <MenuItem value={'ACTIVE'}><FormattedMessage id={'strings.statusActive'}/></MenuItem>
                  <MenuItem value={'INACTIVE'}><FormattedMessage id={'strings.statusInactive'}/></MenuItem>
                  <MenuItem value={'ANY'}><FormattedMessage id={'strings.statusAny'}/></MenuItem>
                </Select>
              </FormControl>
                      <FormControl disabled={loading} margin="normal" style={{marginRight: 15}}>
                          <InputLabel htmlFor="bills-paid-status">
                              <FormattedMessage id={'scenes.reports.memberInfo.selections.billsPaidStatus'}/>
                          </InputLabel>
                          <Select
                              disabled={loading}
                              value={this.state.filters.billsPaidStatus}
                              inputProps={{
                                  name: 'bills-paid-status',
                                  id: 'bills-paid-status',
                              }}
                              onChange={this._handleFilterChange('billsPaidStatus')}
                          >
                              <MenuItem value={'YES'}><FormattedMessage id={'options.yes'}/></MenuItem>
                              <MenuItem value={'NO'}><FormattedMessage id={'options.no'}/></MenuItem>
                              <MenuItem value={'ANY'}><FormattedMessage id={'options.all'}/></MenuItem>
                          </Select>
                      </FormControl>
                  </FormGroup>
                </Grid>
                <Grid item={true}>
                  <FormGroup style={{ marginTop: 10 }}>
                    <FormControl disabled={loading} margin="normal" style={{marginRight: 15}}>
                      <InputLabel htmlFor="person-status">
                        <FormattedMessage id={'scenes.reports.memberInfo.selections.personStatus'}/>
                      </InputLabel>
                      <Select
                          disabled={loading}
                          value={this.state.filters.personStatus}
                          inputProps={{
                            name: 'person-status',
                            id: 'person-status',
                          }}
                          onChange={this._handleFilterChange('personStatus')}
                      >
                        <MenuItem value={'ACTIVE'}><FormattedMessage id={'strings.statusActive'}/></MenuItem>
                        <MenuItem value={'INACTIVE'}><FormattedMessage id={'strings.statusInactive'}/></MenuItem>
                        <MenuItem value={'ANY'}><FormattedMessage id={'strings.statusAny'}/></MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl disabled={loading} margin="normal" style={{marginRight: 15}}>
                <InputLabel htmlFor="person-membership-status">
                  <FormattedMessage id={'scenes.reports.memberInfo.selections.personMembershipStatus'}/>
                </InputLabel>
                <Select
                    disabled={loading}
                    value={this.state.filters.personMembershipStatus}
                    inputProps={{
                      name: 'person-membership-status',
                      id: 'person-membership-status',
                    }}
                    onChange={this._handleFilterChange('personMembershipStatus')}
                >
                  <MenuItem value={'ACTIVE'}><FormattedMessage id={'strings.statusActive'}/></MenuItem>
                  <MenuItem value={'INACTIVE'}><FormattedMessage id={'strings.statusInactive'}/></MenuItem>
                  <MenuItem value={'ANY'}><FormattedMessage id={'strings.statusAny'}/></MenuItem>
                </Select>
              </FormControl>
                  </FormGroup>
                </Grid>

                <Grid item={true}>
                  <FormGroup style={{ marginTop: 10 }}>
                    <FormControl disabled={loading} margin="normal" style={{marginRight: 15}}>
                      <InputLabel htmlFor="ebirdie-status">
                        <FormattedMessage id={'scenes.reports.memberInfo.selections.eBirdieStatus'}/>
                      </InputLabel>
                      <Select
                          disabled={loading}
                          value={this.state.filters.eBirdieStatus}
                          inputProps={{
                            name: 'ebirdie-status',
                            id: 'ebirdie-status',
                          }}
                          onChange={this._handleFilterChange('eBirdieStatus')}
                      >
                        <MenuItem value={'VALID'}><FormattedMessage id={'strings.statusValid'}/></MenuItem>
                        <MenuItem value={'INVALID'}><FormattedMessage id={'strings.statusNotValid'}/></MenuItem>
                        <MenuItem value={'ANY'}><FormattedMessage id={'strings.statusAny'}/></MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl disabled={loading} margin="normal" style={{marginRight: 15}}>
                      <InputLabel htmlFor="paper-magazine-status">
                        <FormattedMessage id={'scenes.reports.memberInfo.selections.paperMagazineStatus'}/>
                      </InputLabel>
                      <Select
                          disabled={loading}
                          value={this.state.filters.paperMagazineStatus}
                          inputProps={{
                            name: 'paper-magazine-status',
                            id: 'paper-magazine-status',
                          }}
                          onChange={this._handleFilterChange('paperMagazineStatus')}
                      >
                        <MenuItem value={'YES'}><FormattedMessage id={'options.yes'}/></MenuItem>
                        <MenuItem value={'NO'}><FormattedMessage id={'options.no'}/></MenuItem>
                        <MenuItem value={'DIGITAL'}><FormattedMessage id={'strings.digital'}/></MenuItem>
                        <MenuItem value={'PAPER'}><FormattedMessage id={'strings.paper'}/></MenuItem>
                        <MenuItem value={'ANY'}><FormattedMessage id={'strings.statusAny'}/></MenuItem>
                      </Select>
                    </FormControl>
                  </FormGroup>
                </Grid>
              </Grid>
            </FormControl>
          </FormGroup>
          <ButtonLoaderWrap loading={loading} style={{ marginTop: 30 }}>
            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              onClick={this._handleLoadOnClick}
            >
              <FormattedMessage id={'buttons.download'} />
            </Button>
          </ButtonLoaderWrap>
        </ContentWrap>
        <ContentWrap>
          <ContentHeader style={{ marginBottom: 15 }}>
            <ContentHeaderTitle style={{ marginTop: 40, fontSize: 22 }}>
              <FormattedMessage id={'scenes.reports.memberInfo.joinDateTitle'} />
            </ContentHeaderTitle>
          </ContentHeader>
          <FormGroup row={true}>
            <FormControl>
              <LocalizedDatePicker
                disabled={loading}
                style={{ marginRight: 20 }}
                value={this.state.dateFrom}
                disablePast={false}
                onChange={(newDate: Date) => {
                  this.setState({ dateFrom: newDate });
                }}
                clearable={false}
                emptyLabel={this.props.intl.formatMessage({ id: 'strings.startDate' })}
              />
            </FormControl>
            <FormControl>
              <LocalizedDatePicker
                disabled={loading}
                style={{ marginRight: 20 }}
                value={this.state.dateTo}
                disablePast={false}
                onChange={(newDate: Date) => {
                  this.setState({ dateTo: newDate });
                }}
                clearable={false}
                emptyLabel={this.props.intl.formatMessage({ id: 'strings.endDate' })}
              />
            </FormControl>
          </FormGroup>
          <ButtonLoaderWrap loading={loading} style={{ marginTop: 30 }}>
            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              onClick={this._handleJoinDateLoadOnClick}
            >
              <FormattedMessage id={'buttons.download'} />
            </Button>
          </ButtonLoaderWrap>
        </ContentWrap>
      </Fragment>
    );
  }

  private _handleChange = name => event => {
    const options = {
      ...this.state.options,
      [name]: event.target.checked,
    };

    this.setState({ options });
  }

  private _handleFilterChange = name => event => {
    const filters = {
      ...this.state.filters,
      [name]: event.target.value,
    };

    this.setState({ filters });
  }

  private _handleLoadOnClick = (): boolean => {
    const { options } = this.state;
    const activeColumnsCount = Object.values(options).filter(option => option === true).length;
    if (activeColumnsCount === 0) {
      window.alert(this.props.intl.formatMessage({ id: 'strings.noActiveColumns' }));
      return false;
    }
    this.setState({ loading: true }, this._handleSending);
    return true;
  }

  private _handleJoinDateLoadOnClick = (): boolean => {
    this.setState({ loading: true }, this._handleJoinDateSending);
    return true;
  }

  private _handleSending = () => {
    const { getMembershipsReport } = this.props;
    const { options, filters } = this.state;

    const includedFields = Object.keys(options).filter(opt => options[opt]).join(',');

    if (includedFields !== '') {
      getMembershipsReport({
        includedFields,
        filters,
        onComplete: () => {
          this.setState({ loading: false });
        }
      });
    }
  }

  private _handleJoinDateSending = () => {
    const { getJoinDateReport } = this.props;
    const { dateFrom, dateTo } = this.state;

    if (dateFrom && dateTo) {
      getJoinDateReport({
        dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
        dateTo: moment(dateTo).format('YYYY-MM-DD'),
        onComplete: () => {
          this.setState({ loading: false });
        }
      });
    }
  }
}

export default injectIntl(connect(
  null, {
    getMembershipsReport: reportsActions.getMembershipsReport,
    getJoinDateReport: reportsActions.getJoinDateReport,
  },
)(ReportsMemberInfoScene));
