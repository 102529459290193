import { take, fork, select } from 'redux-saga/effects';
import {
  ORGANIZATIONS_REQUESTING,
  ORGANIZATIONS_SEARCH_REQUESTING,
  ORGANIZATIONS_SUCCESS,
  ORGANIZATIONS_ERROR,
  ORGANIZATIONS_SEARCH_RESET
} from './constants';
import { APIRoute, DEFAULT_PAGINATION_LIMIT, DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import { AUTH_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import {
  handlePagedDataRefetch,
  normalizeSearchParams,
  stateTargetIdParams
} from '@src/utils/storeUtils';
import { ORGANIZATION_ADD_SUCCESS, ORGANIZATION_DELETE_SUCCESS } from '@src/store/organization/constants';

/*
  GET /api/1/organizations/
 */
const fetchOrganizationsFlow = SagaFlowFactory.createPagedGETFlow({
  apiRoute: APIRoute.GET_ORGANIZATIONS,
  itemType: 'organizations',
  successType: ORGANIZATIONS_SUCCESS,
  errorType: ORGANIZATIONS_ERROR
});

/*
  GET /api/1/organizations/search
 */
const fetchOrganizationsSearchFlow = SagaFlowFactory.createPagedGETFlow({
  apiRoute: APIRoute.SEARCH_ORGANIZATIONS,
  itemType: 'organizations',
  successType: ORGANIZATIONS_SUCCESS,
  errorType: ORGANIZATIONS_ERROR
});

const REDUCER_NAME = 'organizationsReducer';

function * organizationsWatcher() {
  let token: string = '';

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
      page,
      limit,
      params
    } = yield take([
      ORGANIZATIONS_REQUESTING,
      ORGANIZATIONS_SEARCH_REQUESTING,
      ORGANIZATIONS_SEARCH_RESET,
      ORGANIZATION_ADD_SUCCESS,
      ORGANIZATION_DELETE_SUCCESS,
    ]);

    const targetIdParams = yield select(stateTargetIdParams(REDUCER_NAME));

    switch (type) {
      case ORGANIZATIONS_REQUESTING:
        yield fork(
          fetchOrganizationsFlow,
          token,
          page,
          limit,
          { ...params, ...targetIdParams },
        );
        break;
      case ORGANIZATIONS_SEARCH_REQUESTING:
        yield fork(
          fetchOrganizationsSearchFlow,
          token,
          page,
          limit,
          { ...normalizeSearchParams(params), ...targetIdParams}
        );
        break;
      case ORGANIZATIONS_SEARCH_RESET:
        yield fork(
          fetchOrganizationsFlow,
          token,
          DEFAULT_PAGINATION_PAGE,
          DEFAULT_PAGINATION_LIMIT,
          { ...normalizeSearchParams(params), ...targetIdParams}
        );
        break;
      case ORGANIZATION_ADD_SUCCESS:
      case ORGANIZATION_DELETE_SUCCESS: {
        yield fork(
          handlePagedDataRefetch,
          REDUCER_NAME,
          ORGANIZATIONS_REQUESTING,
          ORGANIZATIONS_SEARCH_REQUESTING,
        );
        break;
      }
      default:
    }
  }
}

export default organizationsWatcher;