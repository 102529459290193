import { call, put } from 'redux-saga/effects';
import {
  REPORT_CONFIG_REQUESTING_ERROR, REPORT_CONFIG_REQUESTING_SUCCESS,
} from '@src/store/reports/constants';
import { API_ROOT, APIRoute } from '@src/assets/config';
import { tokenAPIGet } from '@src/utils/storeUtils';

export function* getReportsConfigFlow(token: string) {
  try {
    const data = yield call(() => {
      return tokenAPIGet(`${API_ROOT}${APIRoute.REPORTS_CONFIG}`, token);
    });

    if (data) {
      yield put({ type: REPORT_CONFIG_REQUESTING_SUCCESS, data });
    }
  } catch (error) {
    yield put({ type: REPORT_CONFIG_REQUESTING_ERROR, error });
  }
}