import { EditAcceptableScoreAdjustmentAction } from '@src/store/acceptableScore/actions';
import { call, put, select } from '@redux-saga/core/effects';
import { userAuthToken } from '@src/store/auth/selectors';
import { API_ROOT, APIRoute } from '@src/assets/config';
import { defaultOnCompleteCall, tokenAPIPost, tokenAPIPut } from '@src/utils/storeUtils';
import { omit } from 'lodash';
import {
  EDIT_ACCEPTABLE_SCORE_ADJUSTMENT_ERROR,
  EDIT_ACCEPTABLE_SCORE_ADJUSTMENT_SUCCESS,
} from '@src/store/acceptableScore/constants';
import { ACCEPTABLE_SCORES_REFETCH } from '@src/store/acceptableScores/constants';

export function* editAdjustmentFlow({
  acceptableScoreId,
  acceptableScoreAdjustmentId,
  onComplete,
  ...rest
}: EditAcceptableScoreAdjustmentAction) {
  try {
    const url = `${API_ROOT}${APIRoute.acceptableScoreAdjustment(acceptableScoreId, acceptableScoreAdjustmentId)}`;
    const methodFn = acceptableScoreAdjustmentId ? tokenAPIPut : tokenAPIPost;
    const token = yield select(userAuthToken);
    const response = yield call(() => {
      return methodFn(
        url,
        token,
        omit(rest, ['type']),
      );
    });

    defaultOnCompleteCall(onComplete, response);
    yield put({ type: EDIT_ACCEPTABLE_SCORE_ADJUSTMENT_SUCCESS });
    yield put({ type: ACCEPTABLE_SCORES_REFETCH });
  }  catch (error) {
    defaultOnCompleteCall(onComplete, undefined, error);
    yield put({ type: EDIT_ACCEPTABLE_SCORE_ADJUSTMENT_ERROR, error });
  }
}
