import React, { Component, createRef } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import {
  OrganizationMembersForm,
} from '@src/components/scenes/organization/components/OrganizationMembersForm';

interface Props {
  targetIdParams: TargetIdParams;
  organizationId: number | undefined;
  open: boolean;
  organizationName?: string;
  onClose: () => void;
}

export class OrganizationMembersModal extends Component<Props, {}> {
  actionsContainerRef = createRef<HTMLDivElement>();

  render() {
    const {
      open,
      organizationId,
      onClose,
      targetIdParams,
      organizationName,
    } = this.props;

    return (
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={'lg'}
      >
        <DialogTitle>
          <FormattedMessage id={'scenes.organizations.modal.persons.title'} values={{ organizationName }}/>
        </DialogTitle>

        <DialogContent>
          <OrganizationMembersForm
            targetIdParams={targetIdParams}
            organizationId={organizationId}
            parentContext={'MODAL'}
            onClose={onClose}
            actionsContainerRef={this.actionsContainerRef}
          />
        </DialogContent>

        <div ref={this.actionsContainerRef} />
      </Dialog>
    );
  }
}