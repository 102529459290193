import React, { PureComponent, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import Select from '@src/components/forms/Select';
import { connect } from 'react-redux';
import { PropTypes } from '@material-ui/core';
import Margin = PropTypes.Margin;

/** Required multi & clubs data formats */
type PropsData = {
  multi: true,
  selectedClubs?: Array<Club>,
} | {
  multi?: false | undefined,
  selectedClubs?: Club,
};

type Props = {
  clubs: Array<Club>;
  disabled?: boolean;
  helpText?: React.ReactNode;
  margin?: Margin;
  error?: boolean;
  required?: boolean;
  onChangeCallback?(values: Array<{ id: number }> | number): void;
} & PropsData;

class ClubSelectConnected extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      clubs,
      selectedClubs,
      multi,
      helpText,
      disabled,
      margin,
      error,
      required,
    } = this.props;

    const labelText = (): ReactNode => {
      return (
        <FormattedMessage
          id={multi ? 'forms.clubSelect.multi.label' : 'forms.clubSelect.single.label'}
        />
      );
    };

    const placeholderText = (): ReactNode => {
      return (
        <FormattedMessage
          id={multi ? 'forms.clubSelect.multi.placeholder' : 'forms.clubSelect.single.placeholder'}
        />
      );
    };

    return (
      <Select
        error={error}
        disabled={disabled}
        required={required}
        options={clubs.sort((a, b) => {
          return a.name ? a.name.localeCompare(b.name, 'fi') : -1;
        })}
        selected={selectedClubs}
        multiSelect={multi}
        onChangeCallback={this._handleChange}
        labelText={labelText()}
        placeholderText={placeholderText()}
        helpText={helpText}
        margin={margin}
      />
    );
  }

  private _handleChange = (value: Array<{ id: number }> | number) => {
    const {onChangeCallback} = this.props;
    if (onChangeCallback) {
      onChangeCallback(value);
    }
  }
}

const ClubSelect = connect((state: any) => ({
  clubs: state.clubsReducer.allClubs
}))(ClubSelectConnected);

export {
  ClubSelect
};