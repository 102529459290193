import React, { ChangeEvent, Component } from 'react';
import { injectIntl, IntlShape, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import GenderSelect from '@src/components/forms/ui/GenderSelect';
import GreenCardCheckbox from '@src/components/forms/ui/GreenCardCheckbox';
import FunctionaryCheckbox from '@src/components/forms/ui/FunctionaryCheckbox';

import {
  TextField,
  FormControl,
  FormGroup,
  Typography,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
} from '@material-ui/core';
import { DEFAULT_INPUT_LEFT_MARGIN } from '@src/assets/config';
import { FederationRoleScopeGuard } from '@src/components/access-control/FederationRoleScopeGuard';
import { isNumeric } from '@src/utils/storeUtils';
import { GetFromHandicapError, GetToHandicapError, HandicapValue, ErrorType, CheckIsInvalidError } from '@src/utils/Handicap';
import { UserTargeting } from '@src/components/forms/ui/UserTargetingField';
import * as functionariesActions from '@src/store/functionaries/actions';
import * as regionsActions from '@src/store/regions/actions';
import * as clubsActions from '@src/store/clubs/actions';
import { getActiveClubId, getActiveFederationId } from '@src/store/auth/selectors';
import memoizeOne from 'memoize-one';
import MessageSenderSelect from '@src/components/forms/MessageSenderSelect';
import { RegionSelect } from '@src/components/forms/RegionSelect';
import FunctionaryTitleSelect from '@src/components/forms/FunctionaryTitleSelect';
import { ClubSelect } from '@src/components/forms/ClubSelect';

export enum ReceivesClubLetterEnum {
  ALL = 'ALL',
  YES = 'YES',
  NO  = 'NO',
}

interface Props {
  intl: IntlShape;
  setTargetIdParams: (params: SetTargetIdParams) => any;
  fetchRegions: () => any;
  fetchClubs: () => any;
  functionaryTitlesState: FunctionaryTitlesState;
  clubsState: ClubsState;
  regionsState: RegionsState;
  activeFederationId: ReturnType<typeof getActiveFederationId>;
  activeClubId: ReturnType<typeof getActiveClubId>;
  disabled?: boolean;
  limitGender?: TargetGender[];
  senders?: MessageSender | Club | ClubInfo | Federation;
  limitClubs: Club[] | number[] | undefined;
  limitAgeFrom: number | undefined;
  limitAgeTo: number | undefined;
  limitHandicapFrom: HandicapValue;
  limitHandicapTo: HandicapValue;
  greenCardOnly?: boolean;
  functionaryOnly?: boolean;
  listedReceivers: boolean;
  receiversData: number[]|undefined;
  limitFunctionaryTitles: CondensedTitle[] | number[] | undefined;
  limitRegions: RegionEntry[] | string[] | undefined;
  limitReceivesClubLetter: ReceivesClubLetterEnum;
  targetingUpdated?(values: Object): void;
}

interface State {
  fromHandicapError?: ErrorType;
  toHandicapError?: ErrorType;
}

type FunctionaryOptionType = {name: string; id: number};
type MemoizeFunctionaryValuesType = (titles: CondensedTitle[], selection: number[]) => FunctionaryOptionType[];

type ClubOptionType = {name: string; id: number};
type MemoizeClubValuesType = (clubs: Club[], selection: number[]) => ClubOptionType[];

type MemoizeRegionValuesType = (regions: RegionEntry[], selection: string[]) => RegionEntry[];

class TargetingFormComponent extends Component<Props, State> {

  protected memoizeFunctionaryValues = memoizeOne<MemoizeFunctionaryValuesType>((allTitles, functionaryTitles) =>
    allTitles.filter(allTitle => functionaryTitles?.includes(allTitle.id))
      .map(filterTitle => ({name: filterTitle.name, id: filterTitle.id}))
  );

  protected memoizeClubValues = memoizeOne<MemoizeClubValuesType>((allClubs, clubs) =>
      allClubs.filter(allClub => clubs?.includes(allClub.id))
          .map(filterClub => ({name: filterClub.name, id: filterClub.id}))
  );

  protected memoizeRegionValues = memoizeOne<MemoizeRegionValuesType>((allRegions, regions) =>
      allRegions.filter(allRegion => regions?.includes(allRegion.name))
          .map(filterRegion => ({name: filterRegion.name, id: filterRegion.id}))
  );

  constructor(props: Props) {
    super(props);

    const initialState: State = {};
    this.state = initialState;
  }

  componentDidMount() {
    const {
      functionaryTitlesState,
      clubsState,
      regionsState,
      setTargetIdParams,
      fetchRegions,
      fetchClubs,
    } = this.props;

    // Fetch functionary titles if needed
    if (functionaryTitlesState.allTitles.length === 0) {
      setTargetIdParams({
        resetState: true,
        targetIdParams: {
          federationId: this.props.activeFederationId,
          clubId      : this.props.activeClubId,
        }
      });
    }

    if (regionsState.regions.length === 0) {
      fetchRegions();
    }

    if (clubsState.allClubs.length === 0) {
      fetchClubs();
    }
  }

  render() {
    const { intl, disabled, limitGender, limitAgeFrom, limitAgeTo, senders,
      limitHandicapFrom, limitHandicapTo, greenCardOnly, functionaryOnly, listedReceivers, receiversData,
      limitFunctionaryTitles, limitClubs, limitReceivesClubLetter, limitRegions, functionaryTitlesState, clubsState,
      regionsState } = this.props;

    const clubMultiSelect = false;
    const selectedSenders = senders as MessageSender;
    const {fromHandicapError, toHandicapError} = this.state;

    const regionValues = this.memoizeRegionValues(regionsState.regions, limitRegions as string[] ?? []);

    const functionaryValues =
        this.memoizeFunctionaryValues(functionaryTitlesState.allTitles, limitFunctionaryTitles as number[] ?? []);

    const clubValues =
        this.memoizeClubValues(clubsState.allClubs, limitClubs as number[] ?? []);

    return (
      <>
        <FederationRoleScopeGuard>
          <MessageSenderSelect
              margin={'normal'}
              multi={clubMultiSelect}
              value={
                selectedSenders
              }
              onChangeCallback={(value: any) => {
                // If value is unchecked, user targeting can't be used
                if (!value || value.type === 'federation') { this._handleUseUserTargetingChecked(false); }

                // Update targeting
                this._updateTargeting({ senders: value });
              }}
          />
        </FederationRoleScopeGuard>

        <div style={{margin: '1em 0'}}>
          <FormControlLabel
            control={
              <Checkbox
                  disabled={(!senders) || (senders && (senders as MessageSender).type !== 'club')}
                  checked={listedReceivers}
                  onChange={(e, c) => this._handleUseUserTargetingChecked(c)}
              />}
            label={<FormattedMessage id={'scenes.messages.form.userTargeting.checkboxLabel'} />}
          />
          <Typography variant="body1">
            <FormattedMessage id="scenes.messages.form.userTargeting.info"/>
          </Typography>

          {listedReceivers &&
            <>
              <br/>
              <UserTargeting
                receiversData={receiversData}
                OnFileChange={f => this._updateTargeting({receiversData: f, receiversRequiresUpdate: true})}
              />
            </>
          }
        </div>

        {/** Other targeting fields are hidden when user targeting file is used */}
        {!listedReceivers &&
        <>
          {(senders && (senders as MessageSender).type !== 'club') && <FederationRoleScopeGuard>
          <ClubSelect
              margin={'normal'}
              disabled={disabled}
              selectedClubs={clubValues as Club[]}
              multi={true}
              onChangeCallback={sel => this._updateTargeting(
                  {['limitClubs']: sel ? (sel as { id: number }[]).map(club => club.id) : []}
              )}
              // onChangeCallback={sel => this._updateTargeting({ limitClubs: sel })}
          />
          <RegionSelect
              margin={'normal'}
              disabled={disabled}
              selectedRegions={regionValues}
              multi={true}
              onChangeCallback={sel =>
                this._updateTargeting(
                    {['limitRegions']: sel ? (sel as { id: string }[]).map(region => region.id) : []}
                )
              }
          />
        </FederationRoleScopeGuard>}

          <FunctionaryCheckbox
              value={functionaryOnly}
              onChange={() => {
                this._updateTargeting({ functionaryOnly: !functionaryOnly });
              }}
          />

          { this.props.functionaryOnly && <>
            <FunctionaryTitleSelect
                margin={'normal'}
                selectedTitles={functionaryValues}
                disabled={disabled}
                multi={true}
                onChangeCallback={sel => this._updateTargeting(
                    {['limitFunctionaryTitles']: sel ? (sel as { id: number }[]).map(title => title.id) : []}
                )}
            />

            {/** Filter does user receive federation letters */}
            <FormControl disabled={disabled} style={{width: '100%'}}>
              <FormLabel>
                <FormattedMessage id="scenes.messages.form.receivesClubLetter"/>
              </FormLabel>
              <RadioGroup
                  row={true}
                  value={limitReceivesClubLetter}
                  onChange={(e, v) => this._updateTargeting({['limitReceivesClubLetter']: v})}
              >
                <FormControlLabel value={ReceivesClubLetterEnum.ALL} control={<Radio />} label={intl.formatMessage({id: 'options.all'})} />
                <FormControlLabel value={ReceivesClubLetterEnum.YES} control={<Radio />} label={intl.formatMessage({id: 'options.yes'})} />
                <FormControlLabel value={ReceivesClubLetterEnum.NO} control={<Radio />} label={intl.formatMessage({id: 'options.no'})} />
              </RadioGroup>
            </FormControl>
          </>}

        <FormControl disabled={disabled} style={{width: '100%'}}>
          <FormGroup row={true} style={{ marginBottom: '1.8em' }}>
            <TextField
              id="minimum-handicap-input"
              label={<FormattedMessage id={'scenes.messages.form.minHandicap'} />}
              value={limitHandicapFrom.value || ''}
              error={!!fromHandicapError}
              helperText={fromHandicapError &&
                intl.formatMessage(fromHandicapError.descriptor, fromHandicapError.values)}
              margin="normal"
              onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                this._handleHandicapRestrictionsChange('limitHandicapFrom', e);
              }}
              onBlur={e => {
                this._handleHandicapRestrictionsBlurr('limitHandicapFrom', e);
              }}
              style={{ marginRight: DEFAULT_INPUT_LEFT_MARGIN, flex: 1 }}
            />

            <TextField
              id="maximum-handicap-input"
              label={<FormattedMessage id={'scenes.messages.form.maxHandicap'} />}
              value={limitHandicapTo.value || ''}
              error={!!toHandicapError}
              helperText={toHandicapError &&
                intl.formatMessage(toHandicapError.descriptor, toHandicapError.values)}
              margin="normal"
              onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                this._handleHandicapRestrictionsChange('limitHandicapTo', e);
              }}
              onBlur={e => {
                this._handleHandicapRestrictionsBlurr('limitHandicapTo', e);
              }}
              style={{ flex: 1 }}
            />
          </FormGroup>
        </FormControl>

        <FormControl disabled={disabled} style={{width: '100%'}}>
          <FormGroup row={true} style={{ marginBottom: '1.8em' }}>
            <TextField
              id="minimum-age-input"
              label={<FormattedMessage id={'scenes.messages.form.minimumAge'} />}
              value={isNumeric(limitAgeFrom) ? limitAgeFrom : ''}
              margin="normal"
              onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                this._handleAgeRestrictionsChange('limitAgeFrom', e);
              }}
              style={{ marginRight: DEFAULT_INPUT_LEFT_MARGIN, flex: 1 }}
            />

            <TextField
              id="maximum-age-input"
              label={<FormattedMessage id={'scenes.messages.form.maximumAge'} />}
              value={isNumeric(limitAgeTo) ? limitAgeTo : ''}
              margin="normal"
              onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                this._handleAgeRestrictionsChange('limitAgeTo', e);
              }}
              style={{ flex: 1 }}
            />
          </FormGroup>
        </FormControl>

        <GenderSelect
          initialValue={limitGender}
          title={<FormattedMessage id={'scenes.messages.form.gender'} />}
          groupProps={{fullWidth: true}}
          onChange={(value: any) => {
            this._updateTargeting({ limitGender: value });
          }}
        />

        <GreenCardCheckbox
          value={greenCardOnly}
          onChange={() => {
            this._updateTargeting({ greenCardOnly: !greenCardOnly });
          }}
        />
        </>
        }
      </>
    );
  }

  private _handleUseUserTargetingChecked = (checked: boolean) => {
    const {senders} = this.props;

    if ((checked && !senders) || (checked && senders && (senders as MessageSender).type !== 'club')) {
      alert('Club needs to be selected');
      return;
    }

    let newTargeting: any = {receiversRequiresUpdate: true};

    if (!checked) {
      newTargeting = {...newTargeting, listedReceivers: false, receiversData: undefined};
    } else {
      newTargeting = {...newTargeting, listedReceivers: true};
    }

    this._updateTargeting(newTargeting);
  }

  // Handle lost focus events
  private _handleHandicapRestrictionsBlurr = (
    propType: 'limitHandicapFrom' | 'limitHandicapTo',
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    // If value is in invalid state reset it
    const handicapValue = this.props[propType];
    if (CheckIsInvalidError(handicapValue)) {
      this._updateTargeting({[propType]: {}});
      return;
    }

    // Check both fields for submit level errors
    const fromError = GetFromHandicapError(this.props.limitHandicapFrom, this.props.limitHandicapTo, true);
    const toError = GetToHandicapError(this.props.limitHandicapFrom, this.props.limitHandicapTo, true);
    this.setState({fromHandicapError: fromError, toHandicapError: toError});
  }

  // Handle handicap change events
  private _handleHandicapRestrictionsChange = (
    propType: 'limitHandicapFrom' | 'limitHandicapTo',
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    // Get new value
    const value = e.target.value;
    const newValue = HandicapValue.parseValue(value);

    // Check for errors
    let error: ErrorType|undefined = undefined;
    let key: string;

    if (propType === 'limitHandicapFrom') {
      error = GetFromHandicapError(newValue, this.props.limitHandicapTo);
      key = 'fromHandicapError';
    } else
    if (propType === 'limitHandicapTo') {
      error = GetToHandicapError(this.props.limitHandicapFrom, newValue);
      key = 'toHandicapError';
    } else {
      throw new Error('Invalid param');
    }

    // Update values
    this.setState(p => {return {...p, [key]: error}; });
    this._updateTargeting({[propType]: newValue});
  }

  private _handleAgeRestrictionsChange = (
    propType: string,
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    const {
      currentTarget: {
        value
      }
    } = e;
    this._updateTargeting({[propType]: parseInt(value, 10)});
  }

  private _updateTargeting = (values: any) => {
    if (this.props.targetingUpdated) {
      this.props.targetingUpdated(values);
    }
  }
}

// Connect to redux store
const TargetingForm = connect((state: StoreState) => ({
  regionsState: state.regionsReducer,
  functionaryTitlesState: state.functionaryTitlesReducer,
  clubsState: state.clubsReducer,
  activeFederationId: getActiveFederationId(state),
  activeClubId: getActiveClubId(state),
}), {
  setTargetIdParams: functionariesActions.setTargetIdParams,
  fetchRegions: regionsActions.fetchRegions,
  fetchClubs: clubsActions.fetchClubs,
})(
  // Inject internationalization
  injectIntl(TargetingFormComponent)
);

export {
  TargetingForm,
};
