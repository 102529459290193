import React, { Component } from 'react';
import ReportsMemberInfoScene from '@src/components/scenes/reports/ReportsMemberInfoScene';

class AdminReportsMemberInfo extends Component {
  render() {
    return (
      <ReportsMemberInfoScene />
    );
  }
}

export {
  AdminReportsMemberInfo
};