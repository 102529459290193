export const CLUB_COURSES_REQUESTING = 'CLUB_COURSES_REQUESTING';
export const CLUB_COURSES_CHANGE_PAGE = 'CLUB_COURSES_CHANGE_PAGE';
export const CLUB_COURSES_SUCCESS = 'CLUB_COURSES_SUCCESS';
export const CLUB_COURSES_ERROR = 'CLUB_COURSES_ERROR';

export const CLUB_COURSES_SEARCH_REQUESTING = 'CLUB_COURSES_SEARCH_REQUESTING';
export const CLUB_COURSES_SEARCH_RESET = 'CLUB_COURSES_SEARCH_RESET';
export const CLUB_COURSES_SEARCH_CHANGED = 'CLUB_COURSES_SEARCH_CHANGED';

export const CLUB_COURSES_RESET = 'CLUB_COURSES_RESET';

export const CLUB_COURSES_SET_INITIAL_STATE = 'CLUB_COURSES_SET_INITIAL_STATE';

export const CONDENSED_CLUB_COURSES_REQUESTING = 'CONDENSED_CLUB_COURSES_REQUESTING';
export const CONDENSED_CLUB_COURSES_SUCCESS = 'CONDENSED_CLUB_COURSES_SUCCESS';
export const CONDENSED_CLUB_COURSES_ERROR = 'CONDENSED_CLUB_COURSES_ERROR';
