import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { confirm } from '@src/components/modals/Confirm';

type ShowDeleteConfirmDialog = {
  messageId?: string;
  values?: any;
  callback: (bool: boolean) => void;
};

export type DeleteConfirmChildren = {
  showConfirm: (args: ShowDeleteConfirmDialog) => any;
};

type Props = {
  children: (args: DeleteConfirmChildren) => any;
};

class DeleteConfirm extends React.PureComponent<Props & WrappedComponentProps, {}> {
  render() {
    return this.props.children({
      showConfirm: this._showDeleteConfirmDialog,
    });
  }

  private _showDeleteConfirmDialog = ({
    callback,
    messageId = 'strings.defaultDeleteConfirm',
    values = {},
  }: ShowDeleteConfirmDialog) => {
    const { intl } = this.props;
    confirm({
      message: intl.formatMessage({
        id: messageId,
      }, values),
      options: {
        cancelText: intl.formatMessage({ id: 'buttons.cancel' }),
        okText: intl.formatMessage({ id: 'buttons.ok' }),
      }
    }).then((result: any) => {
      callback(result);
    }, () => {
      callback(false);
    });
  }
}

export default injectIntl(DeleteConfirm);