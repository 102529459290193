import React, { PureComponent } from 'react';
import { FormattedDate, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { cleanDateIfItIsArray, formatStatusToStringId } from '@src/utils/storeUtils';
import { renderListWithGrid } from '@src/components/layouts/ui';

interface Props extends WrappedComponentProps {
  membership?: Membership;
}

class MembershipInfo extends PureComponent<Props> {
  render() {
    if (!this.props.membership) {
      return null;
    }

    const {
      membership,
    } = this.props;

    let listData: any[] = [];
    if (membership) {
      const {
        memberStatus,
        clubInfo,
        memberNo,
        isHomeClub,
        memberType,
        timeUpdated,
        vendorUpdated,
        validTo,
        streetAddress,
        city,
        zip,
        phone,
        email
      } = membership;

      const dateJoined = cleanDateIfItIsArray(membership.dateJoined);
      const dateResigned = cleanDateIfItIsArray(membership.dateResigned);

      listData = [
        {
          label: 'strings.memberId',
          value: membership.idMembership
        },
        {
          label: 'strings.clubId',
          value: membership.clubInfo.idClub
        },
        {
          label: 'strings.memberNo',
          value: membership.memberNo
        },
        {
          label: 'strings.memberState',
          value: memberStatus ? <FormattedMessage id={formatStatusToStringId(memberStatus)} /> : '-',
        },
        {
          label: 'strings.validTo',
          value: validTo ? <FormattedDate value={validTo} /> : '-',
        },
        {
          label: 'strings.club',
          value: clubInfo ? `${clubInfo.nameClub} (${clubInfo.abbreviation})` : '-'
        },
        {
          label: 'strings.memberNo',
          value: memberNo || '-'
        },
        {
          label: 'strings.streetAddress',
          value: streetAddress || '-'
        },
        {
          label: 'strings.postalCode',
          value: zip || '-'
        },
        {
          label: 'strings.city',
          value: city || '-'
        },
        {
          label: 'strings.phone',
          value: phone || '-'
        },
        {
          label: 'strings.email',
          value: email || '-'
        },
        {
          label: 'strings.homeClub',
          value: isHomeClub ? (<FormattedMessage id={'options.yes'} />) : (<FormattedMessage id={'options.no'} />),
        },
        {
          label: 'strings.dateJoined',
          value: dateJoined ? <FormattedDate value={dateJoined} /> : '-',
        },
        {
          label: 'strings.dateResigned',
          value: dateResigned ? <FormattedDate value={dateResigned} /> : '-',
        },
        {
          label: 'strings.memberType',
          value: memberType,
        },
        {
          label: 'strings.vendorLastUpdate',
          value: vendorUpdated ? <FormattedDate value={vendorUpdated} /> : '-',
        },
        {
          label: 'strings.latestUpdate',
          value: <FormattedDate value={timeUpdated} />
        },
        {
          label: 'strings.preferences',
          value: this._formatPreferences(membership),
        },

      ];
    }

    return renderListWithGrid(listData, 2);
  }

  private _formatPreferences = (membership: Membership) => {
    const {
      magazineSubscriber,
      isBillsPaid,
      magazineFlags,
    } = membership;

    const preferences: string[] = [];

    const createMessage = (id: string) => (
      this.props.intl.formatMessage({ id })
    );

    if (magazineSubscriber) {
      preferences.push(createMessage('userPreferences.magazineSubscription'));
    }

    if (isBillsPaid) {
      preferences.push(createMessage('userPreferences.isBillsPaid'));
    }

    /*
    if (isShareholder) {
      preferences.push(createMessage('userPreferences.isShareholder'));
    }
    */
    if (magazineFlags && magazineFlags.indexOf('PAPER_COPY') !== -1) {
      preferences.push(createMessage('userPreferences.magazineSubscriptionPaperCopy'));
    }

    if (magazineFlags && magazineFlags.indexOf('DIGITAL_COPY') !== -1) {
      preferences.push(createMessage('userPreferences.magazineSubscriptionDigitalCopy'));
    }

    if (magazineFlags && magazineFlags.indexOf('DUPLICATE') !== -1) {
      preferences.push(createMessage('userPreferences.magazineSubscriptionDouble'));
    }

    if (magazineFlags && magazineFlags.indexOf('FREE') !== -1) {
      preferences.push(createMessage('userPreferences.magazineSubscriptionFree'));
    }

    return (
      <span>{Boolean(preferences.length) ? preferences.join(', ') : '-'}</span>
    );
  }
}

export default injectIntl(MembershipInfo);
