import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { DatePicker, DatePickerProps } from '@material-ui/pickers';

type Props = DatePickerProps & WrappedComponentProps;

class LocalizedDatePicker extends React.PureComponent<Props> {
  render() {
    const {
      intl,
      ...rest
    } = this.props;

    return (
      <DatePicker
        format={intl.formatMessage({ id: 'strings.dateFormat' })}
        cancelLabel={intl.formatMessage({ id: 'buttons.cancel' })}
        okLabel={intl.formatMessage({ id: 'buttons.ok' })}
        clearLabel={intl.formatMessage({ id: 'buttons.clear' })}
        {...rest}
      />
    );
  }
}

export default injectIntl(LocalizedDatePicker);