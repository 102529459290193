import {
  NOTIFICATION_ENQUEUE,
  NOTIFICATION_REMOVE,
} from './constants';
import {
  NotificationEnqueueAction,
  NotificationRemoveAction,
} from './actions';

const initialState: NotificationsState = {
  notifications: [],
  autoSaveInProgress: false,
};

type Actions = NotificationEnqueueAction | NotificationRemoveAction;

export const notificationsReducer = (state: NotificationsState = initialState, action: Actions) => {
  switch (action.type) {
    case NOTIFICATION_ENQUEUE:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          action.notification,
        ]
      };
    case NOTIFICATION_REMOVE:
      return {
        ...state,
        notifications: state.notifications.filter(notification => notification.key !== action.key),
      };
    default:
      return state;
  }
};