import React, { createRef, Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as vendorActions from '@src/store/vendor/actions';
import {
  Dialog,
  DialogTitle,
  DialogContent
} from '@material-ui/core';
import { ModalContentOverlayLoader } from '@src/components/modals/ui';
import { VendorForm } from '@src/components/scenes/federation/vendors/components/VendorForm';

type Props = {
  vendorId?: number;
  open: boolean;
  onClose: () => void;
  refetch?: () => any;
  getVendor: (params: VendorGet) => any;
  vendorState: VendorState;
};

class VendorModalConnected extends Component<Props> {
  actionsContainerRef = createRef<HTMLDivElement>();

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      vendorId,
      getVendor
    } = this.props;

    if (nextProps.vendorId !== vendorId) {
      if (nextProps.vendorId) {
        getVendor({
          id: nextProps.vendorId,
        });
      }
    }
  }

  render() {
    const {
      open,
      vendorId,
      onClose,
      vendorState: {
        requesting,
      },
      refetch,
    } = this.props;

    return (
      <Dialog
        open={open}
        fullWidth={true}
      >
        <DialogTitle>
          {vendorId && <FormattedMessage id={'scenes.golfFederation.vendors.modal.edit.title'} />}
          {!vendorId && <FormattedMessage id={'scenes.golfFederation.vendors.modal.add.title'} />}
        </DialogTitle>

        <DialogContent>
          {requesting && <ModalContentOverlayLoader/>}
          <VendorForm
            vendorId={vendorId}
            parentContext={'MODAL'}
            onClose={onClose}
            refetch={refetch}
            actionsContainerRef={this.actionsContainerRef}
          />
        </DialogContent>

        <div ref={this.actionsContainerRef} />
      </Dialog>
    );
  }
}

const VendorModal = connect((state: any) => ({
  vendorState: state.vendorReducer
}), {
  getVendor: vendorActions.getVendor
})(VendorModalConnected);

export {
  VendorModal
};
