import React, { PureComponent, ReactNode, RefObject } from 'react';
import { connect } from 'react-redux';
import * as organizationActions from '@src/store/organization/actions';
import Button from '@material-ui/core/Button/Button';
import { FormattedMessage, FormattedHTMLMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { ModalContentOverlayLoader, renderFormActions } from '@src/components/modals/ui';
import Typography from '@material-ui/core/Typography/Typography';
import { Switch, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import {
  addPersonToOrganization,
  OrganizationPersonStatus,
  removePersonFromOrganization
} from '@src/store/organization/fetchActions';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Radio from '@material-ui/core/Radio/Radio';
import { DeleteIconButton } from '@src/components/buttons/buttons';
import PersonPicker from '@src/components/persons/PersonPicker';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { confirm } from '@src/components/modals/Confirm';
import RoleScopeGuard from '@src/components/access-control/RoleScopeGuard';

interface Props {
  targetIdParams: TargetIdParams;
  organizationId?: number | undefined;
  organizationState: OrganizationState;
  personsState: OrganizationPersons;
  parentContext?: 'MODAL' | 'PAGE';
  onClose?: () => void;
  fetchOrganizationPersons: (params: FetchOrganizationPersons) => any;
  organizationPersonActivate: (params: OrganizationPersonAttributeSet) => any;
  organizationPersonDeactivate: (params: OrganizationPersonAttributeSet) => any;
  organizationPersonPromote: (params: OrganizationPersonAttributeSet) => any;
  organizationPersonDemote: (params: OrganizationPersonAttributeSet) => any;
  actionsContainerRef?: RefObject<HTMLElement>;
}

type State = {
  loading: boolean;
  showPicker: boolean;
};

const initialState: State = {
  loading: false,
  showPicker: false,
};

class OrganizationMembersFormConnected extends PureComponent<Props & WrappedComponentProps, State> {

  constructor(props: Props & WrappedComponentProps) {
    super(props);

    this.state = initialState;

    const {
      organizationId,
      fetchOrganizationPersons,
    } = props;

    if (organizationId) {
      fetchOrganizationPersons({
        organizationId,
      });
    }
  }

  render() {
    const { targetIdParams } = this.props;
    const { showPicker, loading } = this.state;

    if (showPicker) {
      return (
        <>
          {this._renderShowOrHideMemberAdd()}
          {loading && <ModalContentOverlayLoader />}
          <PersonPicker
            targetIdParams={targetIdParams}
            pickerTitle={''}
            showAdd={false}
            onPersonPicked={this._handleNewPersonPicked}
            selectedPersonIds={this._getSelectedPersonIds()}
          />
        </>
      );
    }

    return this._renderMemberContent();
  }

  private _renderMemberContent = () => {
    const {
      personsState: {
        requesting,
      }
    } = this.props;
    const { loading } = this.state;

    return (
      <>
        {Boolean(loading || requesting) && <ModalContentOverlayLoader />}
        {this._renderShowOrHideMemberAdd()}
        {this._renderContent()}
        {this._renderFormFooter()}
      </>
    );
  }

  private _renderShowOrHideMemberAdd = () => {
    const { showPicker } = this.state;

    const label = showPicker ? (
      <>
        <ArrowBack />
        <span style={{ marginLeft: '1em', lineHeight: '0' }}>
          <FormattedMessage id={'buttons.back'} />
        </span>
      </>
    ) : (
      <FormattedMessage id={'strings.addMember'} />
    );

    return (
      <div style={{ marginTop: '24px' }}>
        <Button
          variant="contained"
          onClick={() => this.setState({
            showPicker: !showPicker
          })}
        >
          {label}
        </Button>
      </div>
    );
  }

  private _renderContent = () => {
    const { targetIdParams } = this.props;
    const {
      personsState: {
        successful,
        persons,
      }
    } = this.props;

    if (!persons.length) {
      return (
        <Typography component={'p'}>
          <FormattedMessage id={'scenes.organizations.modal.persons.noMembers'} />
        </Typography>
      );
    }

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><FormattedMessage id={'strings.nro'} /></TableCell>
            <TableCell><FormattedMessage id={'strings.name'} /></TableCell>
            <TableCell><FormattedMessage id={'strings.phone'} /></TableCell>
            <TableCell><FormattedMessage id={'scenes.organizations.modal.persons.memberStatus'} /></TableCell>
            <RoleScopeGuard>
              {({ isFederationScope, isClubScope }) =>
                Boolean((isClubScope && targetIdParams.clubId) || isFederationScope) && (
                  <TableCell><FormattedMessage id={'strings.chairman'} /></TableCell>
              )}
            </RoleScopeGuard>
            <TableCell/>
          </TableRow>
        </TableHead>
        <TableBody>
          {successful && persons.map((organizationPerson: OrganizationPerson) => (
            <TableRow key={organizationPerson.personInfo.idPerson}>
              <TableCell>
                {organizationPerson.personInfo.idPerson}
              </TableCell>
              <TableCell>
                {this._formatPersonName(organizationPerson)}
              </TableCell>
              <TableCell>
                {this._getPersonPhone(organizationPerson)}
              </TableCell>
              <TableCell>
                <RoleScopeGuard>
                  {({ isFederationScope, isClubScope }) =>
                    Boolean((isClubScope && targetIdParams.clubId) || isFederationScope) && (
                <Switch
                  color={'primary'}
                  checked={organizationPerson.status === 'ACTIVE'}
                  onChange={(event: Object, isInputChecked: boolean) => {
                    this._updateOrganizationMemberStatus(organizationPerson, isInputChecked);
                  }}
                />
                  ) || ( organizationPerson.status === 'ACTIVE' ?
                      this.props.intl.formatMessage({ id: 'strings.statusActive' }) :
                      this.props.intl.formatMessage({ id: 'strings.statusInactive' })
                  )}
                </RoleScopeGuard>
              </TableCell>
              <RoleScopeGuard>
                  {({ isFederationScope, isClubScope }) =>
                      Boolean((isClubScope && targetIdParams.clubId) || isFederationScope) && (
              <TableCell align={'center'}>
                {this._getHeadOfOrganizationFormController(organizationPerson)}
              </TableCell>
                    )}
              </RoleScopeGuard>
              <TableCell>
                <RoleScopeGuard>
                    {({ isFederationScope, isClubScope }) =>
                        Boolean((isClubScope && targetIdParams.clubId) || isFederationScope) && (
                <DeleteIconButton
                  onClick={() => {
                    this._handleDelete(organizationPerson);
                  }}
                />
                      )}
                </RoleScopeGuard>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  private _getSelectedPersonIds = (): number[] => {
    const {
      personsState: {
        persons,
      }
    } = this.props;

    if (!persons) {
      return [];
    }

    return persons.map(person => person.personInfo.idPerson);
  }

  private _getHeadOfOrganizationFormController = (organizationPerson: OrganizationPerson): ReactNode => {
    const { isHeadOrganization } = organizationPerson;
    const value =  isHeadOrganization ? 'isHead' : 'isNotHead';

    return (
      <FormControlLabel
        checked={isHeadOrganization}
        value={value}
        control={<Radio color="primary" />}
        label={''}
        onChange={_ => this._promoteOrDemotePerson(organizationPerson)}
      />
    );
  }

  private _updateOrganizationMemberStatus = (organizationPerson: OrganizationPerson, isInputChecked: boolean) => {
    this._activateOrDeactivatePerson(organizationPerson, isInputChecked);
  }

  private _formatPersonName = (organizationPerson: OrganizationPerson): ReactNode => {
    const name = `${organizationPerson.personInfo.nameFirst} ${organizationPerson.personInfo.nameLast}`;

    if (organizationPerson.isHeadOrganization) {
      return (
        <FormattedHTMLMessage
          id={'scenes.organizations.modal.persons.headOfOrganization'}
          values={{ name }}
        />
      );
    }

    return name;
  }

  private _renderFormFooter = () => {
    const {
      parentContext,
      actionsContainerRef,
    } = this.props;

    if (parentContext === 'PAGE') {
      return null;
    }

    return renderFormActions({
      parentContext,
      onClose: this._handleOnClose,
      containerRef: actionsContainerRef,
    });
  }

  private _handleOnClose = () => {
    this.setState({ showPicker: false }, () => {
      if (this.props.onClose) {
        this.props.onClose();
      }
    });
  }

  private _getPersonPhone = (person: OrganizationPerson) => {
    if (person.personInfo && person.personInfo.addressInfo) {
      return person.personInfo.addressInfo.phone1;
    }

    return '-';
  }

  private _handlePersonUpdate = (fn: any, personId: number) => {
    const { fetchOrganizationPersons, organizationId } = this.props;
    this.setState({ loading: true }, () => {
      fn({
        organizationId: organizationId as number,
        personId: personId,
        onComplete: () => {
          this.setState({ loading: false });
          fetchOrganizationPersons({
            organizationId: organizationId as number,
          });
        }
      });
    });
  }

  private _promoteOrDemotePerson = (person: OrganizationPerson) => {
    const { isHeadOrganization } = person;
    let method: (args: OrganizationPersonStatus) => Promise<any>;

    if (isHeadOrganization) {
      method = this.props.organizationPersonDemote;
    } else {
      method = this.props.organizationPersonPromote;
    }

    this._handlePersonUpdate(method, person.personInfo.idPerson);
  }

  private _activateOrDeactivatePerson = (person: OrganizationPerson, isChecked) => {
    let method: (args: OrganizationPersonStatus) => Promise<any>;

    if (isChecked) {
      method = this.props.organizationPersonActivate;
    } else {
      method = this.props.organizationPersonDeactivate;
    }

    this._handlePersonUpdate(method, person.personInfo.idPerson);
  }

  private _handleNewPersonPicked = (person: Person) => {
    const {
      fetchOrganizationPersons,
      organizationId,
      targetIdParams,
    } = this.props;
    const { idPerson } = person;

    this.setState({ loading: true }, () => {
      addPersonToOrganization({
        organizationId: organizationId!,
        personId: idPerson,
        targetIdParams,
      }).then(() => {
        this.setState({ loading: false });
        fetchOrganizationPersons({
          organizationId: organizationId!,
        });
      }).catch(() => {
        window.alert('Could not save person to organization.');
        this.setState({ loading: false });
      });
    });
  }

  private _handleDelete = (orgPerson: OrganizationPerson) => {
    const { intl, organizationId, fetchOrganizationPersons } = this.props;
    const {
      personInfo: {
        idPerson,
        nameFirst,
        nameLast,
      },
    } = orgPerson;

    confirm({
      message: intl.formatMessage({
        id: 'scenes.organizations.modal.persons.removeConfirm',
      }, {
        name: `${nameFirst} ${nameLast}`,
      }),
      options: {
        cancelText: intl.formatMessage({ id: 'buttons.cancel' }),
        okText: intl.formatMessage({ id: 'buttons.ok' }),
      }
    }).then((result: any) => {
      if (result) {
        this.setState({ loading: true }, () => {
          removePersonFromOrganization({
            organizationId: organizationId!,
            personId: idPerson,
          }).then(() => {
            this.setState({ loading: false });
            fetchOrganizationPersons({
              organizationId: organizationId!,
            });
          }).catch(() => {
            window.alert('Could not remove person from.');
            this.setState({ loading: false });
          });
        });
      }
    }, () => {

      window.console.log('cancelled');
    });
  }
}

export const OrganizationMembersForm = injectIntl(connect((state: StoreState) => ({
  organizationState: state.organizationReducer,
  personsState: state.organizationReducer.organizationPersons,
}), {
  fetchOrganizationPersons: organizationActions.fetchOrganizationPersons,
  organizationPersonActivate: organizationActions.organizationPersonActivate,
  organizationPersonDeactivate: organizationActions.organizationPersonDeactivate,
  organizationPersonPromote: organizationActions.organizationPersonPromote,
  organizationPersonDemote: organizationActions.organizationPersonDemote,
})(OrganizationMembersFormConnected));
