export const FUNCTIONARIES_REQUESTING = 'FUNCTIONARIES_REQUESTING';
export const FUNCTIONARIES_SUCCESS = 'FUNCTIONARIES_SUCCESS';
export const FUNCTIONARIES_ERROR = 'FUNCTIONARIES_ERROR';

export const FUNCTIONARIES_CHANGE_PAGE = 'FUNCTIONARIES_CHANGE_PAGE';

export const FUNCTIONARIES_SEARCH_REQUESTING = 'FUNCTIONARIES_SEARCH_REQUESTING';
export const FUNCTIONARIES_SEARCH_RESET = 'FUNCTIONARIES_SEARCH_RESET';
export const FUNCTIONARIES_SEARCH_CHANGED = 'FUNCTIONARIES_SEARCH_CHANGED';

export const FUNCTIONARIES_RESET = 'FUNCTIONARIES_RESET';

export const FUNCTIONARIES_SET_TARGET_ID_PARAMS = 'FUNCTIONARIES_SET_TARGET_ID_PARAMS';

export const FUNCTIONARIES_CONDENSED_REQUESTING = 'FUNCTIONARIES_CONDENSED_REQUESTING';
export const FUNCTIONARIES_CONDENSED_SUCCESS = 'FUNCTIONARIES_CONDENSED_SUCCESS';