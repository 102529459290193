import {
  defaultOnCompleteCall,
  formatURLParams
} from '@src/utils/storeUtils';
import { call, put } from 'redux-saga/effects';
import download from 'downloadjs';

export function * getReportFlow(
  token: string,
  url: string,
  params: any,
  baseType: string,
  fileName: string,
  fileMimeType: string,
  onComplete?: (params: APICallResult) => any,
) {
  try {
    const blob = yield call(() => {
      let options: any = {
        method: 'GET',
        cache: 'no-cache',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      };

      const urlParams = params ? formatURLParams(params, '?') : '';

      return fetch(`${url}${urlParams}`, options)
        .then(response => response.blob());
    });

    if (blob) {
      download(blob, fileName, fileMimeType);

      defaultOnCompleteCall(onComplete, blob);

      yield put({
        type: `${baseType}_SUCCESS`,
      });

    } else {
      defaultOnCompleteCall(onComplete, undefined, '-');

      yield put({
        type: `${baseType}_ERROR`,
        error: blob,
      });
    }

  } catch (error) {
    defaultOnCompleteCall(onComplete, undefined, error);

    yield put({
      type: `${baseType}_ERROR`,
      error
    });
  }

  return true;
}