import React, { Component } from 'react';
import ReportsHandicapReviewScene from '@src/components/scenes/reports/ReportsHandicapReviewScene';

class AdminReportsHandicapReview extends Component {
  render() {
    return (
      <ReportsHandicapReviewScene />
    );
  }
}

export {
  AdminReportsHandicapReview,
};