import React, { PureComponent, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import Select from '@src/components/forms/Select';
import { connect } from 'react-redux';
import { PropTypes } from '@material-ui/core';
import Margin = PropTypes.Margin;
import * as clubActions from '@src/store/club/actions';

interface Props {
  club: Club | undefined;
  fetchClubCourseGuide: (params: FetchClubCourseGuide) => any;
  selectedCourse: ClubCourse | undefined;
  clubState: ClubState;
  disabled?: boolean;
  helpText?: React.ReactNode;
  margin?: Margin;
  error?: boolean;
  required?: boolean;
  onChangeCallback?(values: number): void;
}

class ClubCourseSelectConnected extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  UNSAFE_componentWillMount(): void {
    const {
      fetchClubCourseGuide,
      club
    } = this.props;

    fetchClubCourseGuide({
      clubId: club?.id
    } as FetchClubCourseGuide);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {

    if (!nextProps.clubState.requesting && !this.props.clubState.requesting &&
        nextProps.clubState.courseGuide?.club && nextProps.club &&
        nextProps.clubState.courseGuide?.club.id !== nextProps.club.id) {

      const {
        fetchClubCourseGuide,
        club
      } = nextProps;

      fetchClubCourseGuide({
        clubId: club?.id
      } as FetchClubCourseGuide);
    }
  }

  UNSAFE_componentWillUpdate(nextProps: Props): void {
    console.log('CS - UNSAFE_componentWillUpdate');
    console.log(nextProps);

    /*
    const {
      fetchClubCourseGuide,
      club
    } = this.props;

    fetchClubCourseGuide({
      clubId: club?.id
    } as FetchClubCourseGuide);
    */
  }

  render() {
    const {
      selectedCourse,
      helpText,
      disabled,
      margin,
      error,
      required,
    } = this.props;

    const labelText = (): ReactNode => {
      return (
        <FormattedMessage
          id={'forms.clubCourseSelect.single.label'}
        />
      );
    };

    const placeholderText = (): ReactNode => {
      return (
        <FormattedMessage
          id={'forms.clubCourseSelect.single.placeholder'}
        />
      );
    };

    return (
      <Select
        required={required}
        error={error}
        disabled={disabled}
        options={this._getParentCourses()}
        selected={selectedCourse}
        multiSelect={false}
        onChangeCallback={this._handleChange}
        labelText={labelText()}
        placeholderText={placeholderText()}
        helpText={helpText}
        margin={margin}
      />
    );
  }

  private _getParentCourses(): ClubCourse[] {
    return this.props.clubState.courseGuide?.courses.map(option => {
      if (option.parents && option.parents.length > 0) {
        return null;
      }
      if (option.status !== 'ACTIVE') {
        return null;
      }
      return option;
    }).filter(Boolean) as ClubCourse[];
  }

  private _handleChange = (value: number) => {
    const {onChangeCallback} = this.props;
    if (onChangeCallback) {
      onChangeCallback(value);
    }
  }
}

const ClubCourseSelect = connect((state: any) => ({
  clubState: state.clubReducer,
}), {
    fetchClubCourseGuide: clubActions.fetchClubCourseGuide,
})(ClubCourseSelectConnected);

export {
  ClubCourseSelect
};