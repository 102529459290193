import { API_ROOT, APIRoute } from '@src/assets/config';
import { tokenAPIGet } from '@src/utils/storeUtils';
import { CLUB_COURSE_GUIDE_ERROR, CLUB_COURSE_GUIDE_SUCCESS } from '@src/store/club/constants';
import { call, put } from 'redux-saga/effects';

export function * fetchClubCourseGuide(token: string, clubId: number) {
  try {
    const courseGuide = yield call(() => {
      const url = `${API_ROOT}${APIRoute.GET_CLUB}/${clubId}/courseguide`;
      return tokenAPIGet(url, token);
    });

    if (courseGuide) {
      yield put({
        type: CLUB_COURSE_GUIDE_SUCCESS,
        courseGuide
      });
    }

  } catch (error) {
    yield put({ type: CLUB_COURSE_GUIDE_ERROR, error });
  }

  return true;
}