import {
  CLUB_SERVICES_CHANGE_PAGE,
  CLUB_SERVICES_REQUESTING,
  CLUB_SERVICES_SEARCH_REQUESTING,
  CLUB_SERVICES_SEARCH_RESET,
  CLUB_SERVICES_SEARCH_CHANGED
} from './constants';
import { DEFAULT_PAGINATION_LIMIT } from '@src/assets/config';

export const fetchClubServices = ({
  clubId,
  page,
  limit = DEFAULT_PAGINATION_LIMIT,
}: FetchClubServices) => {
  return {
    type: CLUB_SERVICES_REQUESTING,
    clubId,
    page,
    limit,
  };
};

export const changePage = ({
  page
}: ChangePageAction) => {
  return {
    type: CLUB_SERVICES_CHANGE_PAGE,
    page,
  };
};

export const fetchSearch = ({
  page,
  limit = DEFAULT_PAGINATION_LIMIT,
  clubId,
  params
}: ClubSearchPaginationAction) => {
  return {
    type: CLUB_SERVICES_SEARCH_REQUESTING,
    page,
    limit,
    clubId,
    params,
  };
};

export const resetSearch = (clubId: number) => {
  return {
    type: CLUB_SERVICES_SEARCH_RESET,
    clubId: clubId
  };
};

export const searchChanged = (search: ClubCoursesSearch) => {
  return {
    type: CLUB_SERVICES_SEARCH_CHANGED,
    search
  };
};