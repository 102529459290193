import {
  MAINTENANCES_REQUESTING,
  MAINTENANCES_CHANGE_PAGE
} from './constants';
import { DEFAULT_PAGINATION_LIMIT } from '@src/assets/config';

const fetchMaintenances = ({
  page,
  limit = DEFAULT_PAGINATION_LIMIT,
  params
}: TablePaginationAction) => {
  return {
    type: MAINTENANCES_REQUESTING,
    page,
    limit,
    params
  };
};

const changePage = ({
  page,
}: ChangePageAction) => {
  return {
    type: MAINTENANCES_CHANGE_PAGE,
    page,
  };
};

export {
  fetchMaintenances,
  changePage
};