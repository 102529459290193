import React, { CSSProperties, ReactNode, RefObject } from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import { DEFAULT_INPUT_LEFT_MARGIN } from '@src/assets/config';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import ReactDOM from 'react-dom';
import { DeleteIconButton } from '@src/components/buttons/buttons';

const LoaderContainer = styled.div`
  padding: 8em 12em;
  display: flex;
  justify-content: center;
  align-content: center;
`;

export const ModalContentLoader = () => (
  <LoaderContainer>
    <CircularProgress
      size={16}
      thickness={2}
    />
  </LoaderContainer>
);

interface OverlayLoaderContainerProps {
  bgOpacity: number;
}
export const OverlayLoaderContainer = styled.div<OverlayLoaderContainerProps>`
  position: absolute;
  z-index: 200;
  background-color: rgba(255, 255, 255, ${({ bgOpacity }) => bgOpacity});
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalContentOverlayLoader = ({
  style,
  bgOpacity = 0.96,
}: { style?: CSSProperties, bgOpacity?: number }) => (
  <OverlayLoaderContainer style={style} bgOpacity={bgOpacity}>
    <CircularProgress
      size={28}
      thickness={2}
    />
  </OverlayLoaderContainer>
);

export const ActionContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const FormActionsWrap = ({
  leftSide,
  rightSide,
  parentContext = 'PAGE',
}: {
  rightSide?: React.ReactNode,
  leftSide?: React.ReactNode,
  parentContext?: 'PAGE' | 'MODAL',
}) => {
  const content = (
    <ActionContainer style={{ justifyContent: 'space-between' }}>
      {Boolean(leftSide) && (
        <div style={{ justifyContent: 'flex-start', display: 'flex', width: '100%' }}>
          {leftSide}
        </div>
      )}
      {Boolean(rightSide) && (
        <div style={{ justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
          {rightSide}
        </div>
      )}
    </ActionContainer>
  );

  if (parentContext === 'MODAL') {
    return (
      <DialogActions>
        {content}
      </DialogActions>
    );
  }

  return (
    <div style={{ marginTop: '2em' }}>
      {content}
    </div>
  );
};

const renderStepperActions = ({
  id,
  parentContext,
  onClose,
  isFirstStep,
  onStepBack,
  disabled,
  isLastStep,
  onStep,
  onCreate,
  onUpdate,
}: RenderFormActions) => (
  <FormActionsWrap
    parentContext={parentContext}
    rightSide={
      <>
        {parentContext === 'MODAL' && (
          <Button variant="contained" onClick={onClose} style={{marginRight: DEFAULT_INPUT_LEFT_MARGIN}}>
            <FormattedMessage id={'buttons.cancel'}/>
          </Button>
        )}

        {!isFirstStep && (
          <Button
            variant="contained"
            color="primary"
            onClick={onStepBack}
            disabled={disabled}
            style={{marginRight: DEFAULT_INPUT_LEFT_MARGIN}}
          >
            <FormattedMessage id={'buttons.back'} />
          </Button>
        )}

        {!isLastStep && (
          <Button variant="contained" color="primary" onClick={onStep} disabled={disabled}>
            <FormattedMessage id={'buttons.continue'} />
          </Button>
        )}

        {(isLastStep && !id) && (
          <Button variant="contained" color="primary" onClick={onCreate} disabled={disabled}>
            <FormattedMessage id={'buttons.add'} />
          </Button>
        )}
        {(isLastStep && id) && (
          <Button variant="contained" color="primary" onClick={onUpdate} disabled={disabled}>
            <FormattedMessage id={'buttons.update'} />
          </Button>
        )}
      </>
    }
  />
);

const renderEditItemActions = ({
  onDelete,
  disabled,
  parentContext,
  onClose,
  onUpdate,
}: RenderFormActions) => (
  <FormActionsWrap
    parentContext={parentContext}
    leftSide={(onDelete &&
      <DeleteIconButton
        onClick={onDelete}
        disabled={disabled}
        style={{ marginRight: DEFAULT_INPUT_LEFT_MARGIN }}
        color={'secondary'}
      />
    )}
    rightSide={(
      <>
        {parentContext === 'MODAL' && (
          <Button
            variant="contained"
            onClick={onClose}
            style={{marginRight: Boolean(onUpdate) ? DEFAULT_INPUT_LEFT_MARGIN : '0'}}
          >
            <FormattedMessage id={'buttons.cancel'} />
          </Button>
        )}
        {Boolean(onUpdate) &&  (
          <Button variant="contained" color="primary" onClick={onUpdate} disabled={disabled}>
            <FormattedMessage id={'buttons.update'} />
          </Button>
        )}
      </>
    )}
  />
);

const renderCreateItemActions = ({
  parentContext,
  onClose,
  onCreate,
  disabled,
}: RenderFormActions) => (
  <FormActionsWrap
    parentContext={parentContext}
    rightSide={(
      <>
        {parentContext === 'MODAL' && (
          <Button
            variant="contained"
            onClick={onClose}
            style={{marginRight: Boolean(onCreate) ? DEFAULT_INPUT_LEFT_MARGIN : '0'}}
          >
            <FormattedMessage id={'buttons.cancel'}/>
          </Button>
        )}
        {Boolean(onCreate) && (
          <Button variant="contained" color="primary" onClick={onCreate} disabled={disabled}>
            <FormattedMessage id={'buttons.add'} />
          </Button>
        )}
      </>
    )}
  />
);

const renderActions = (props: RenderFormActions) => {
  const {
    id,
    isStepper,
  } = props;

  if (isStepper) {
    return renderStepperActions(props);
  }

  if (id) {
    return renderEditItemActions(props);
  } else {
    return renderCreateItemActions(props);
  }
};

interface RenderFormActions {
  id?: number;
  isStepper?: boolean;
  isFirstStep?: boolean;
  isLastStep?: boolean;
  parentContext: 'PAGE' | 'MODAL' | undefined;
  onClose: () => void;
  onCreate?: () => void;
  onDelete?: () => void;
  onUpdate?: () => void;
  onContinue?: () => void;
  onStep?: () => void;
  onStepBack?: () => void;
  disabled?: boolean;
  containerRef?: RefObject<HTMLElement>;
}

class FormActions extends React.Component<RenderFormActions, {}> {
  render() {
    const { containerRef } = this.props;

    if (containerRef !== undefined) {
      if (containerRef.current) {
        return ReactDOM.createPortal(
          renderActions(this.props),
          containerRef.current,
        );
      }
    }

    return renderActions(this.props);
  }
}

export const renderFormActions = (props: RenderFormActions): ReactNode => (<FormActions {...props} />);
