import React from 'react';
import { FormControl, InputLabel, MenuItem, PropTypes, Select } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

interface OwnProps {
  value?: string;
  margin?: PropTypes.Margin;
  fullWidth?: boolean;
  required?: boolean;
  error?: boolean;
  disabled?: boolean;
  onChange?(value?: string): void;
}

type Props = OwnProps;

function ScoreAdjustmentPicker({
  value = '',
  margin = 'normal',
  fullWidth = true,
  required = false,
  error = false,
  disabled = false,
  onChange,
}: Props) {
  return (
    <FormControl
      margin={margin}
      fullWidth={fullWidth}
      required={required}
      error={error}
      disabled={disabled}
    >
      <InputLabel htmlFor={'adjustment-picker'}>
        <FormattedMessage id={'strings.selectAdjustment'} />
      </InputLabel>
      <Select
        value={value}
        inputProps={{
          id: 'adjustment-picker'
        }}
        onChange={(e: React.ChangeEvent<{ name?: string; value: string }>) => {
          const { value } = e.target;
          if (onChange) {
            onChange(value);
          }
        }}
      >
        <MenuItem value={'exceptionalScore'}>
          <FormattedMessage id={'strings.exceptionalScore'} />
        </MenuItem>
        <MenuItem value={'penaltyScore'}>
          <FormattedMessage id={'strings.penaltyScore'} />
        </MenuItem>
        <MenuItem value={'handicapReview'}>
          <FormattedMessage id={'strings.handicapReview'} />
        </MenuItem>
      </Select>
    </FormControl>
  );
}

export default ScoreAdjustmentPicker;