/**
 * A vendor comparison function for sort() with name ASC, username ASC, id ASC ordering.
 *
 * @param a The first vendor for comparison
 * @param b The second vendor for comparison
 * @returns Number Negative when a < b, zero when a == b, positive when a > b
 */
export const vendorCompare = (a: Vendor, b: Vendor, l?: string): number => {
  let c: number = !a.name ? !b.name ? 0 : -1 : a.name.localeCompare(b.name, l);
  if (c === 0) {
    c = !a.username ? !b.username ? 0 : -1 : a.username.localeCompare(b.username, l);
    if (c === 0) {
      c = b.id - a.id;
    }
  }
  return c;
};
