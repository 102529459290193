import React, { Component, RefObject } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import SwipeableViews from 'react-swipeable-views';
import { ContentForm } from '@src/components/forms/ui/ContentForm';
import { ReceivesClubLetterEnum, TargetingForm } from '@src/components/forms/ui/TargetingForm';
import { CodesForm } from '@src/components/forms/ui/CodesForm';
import {
  Tabs,
  Tab, Typography, Button
} from '@material-ui/core';
import { ModalContentOverlayLoader, renderFormActions } from '@src/components/modals/ui';
import { connect } from 'react-redux';
import OfferPreview from '@src/components/forms/ui/OfferPreview';
import * as offerActions from '@src/store/offer/actions';
import styled from 'styled-components';
import { parseCorrectMessageSenderObject, parseCorrectPartnerObject } from '@src/utils/storeUtils';
import InputsValidator from '@src/components/forms/InputsValidator';
import { GetFromHandicapError, GetToHandicapError, HandicapValue } from '@src/utils/Handicap';
import * as messagesAction from '@src/store/messages/actions';

export const SwipeableContentContainer = styled.div`
  margin-top: 2.8em;
`;

interface Props {
  intl: IntlShape;
  locale: AppLocale;
  offerId?: number | undefined;
  offer: OfferState;
  parentContext?: 'MODAL' | 'PAGE';
  onClose?: () => void;
  addOffer: (params: OfferAdd) => any;
  editOffer: (params: OfferEdit) => any;
  fetchAllRecipients: (params: MessageRecipients) => any;
  auth: AuthState;
  actionsContainerRef?: RefObject<HTMLElement>;
}

interface State {
  slideIndex: number;
  limitGender: TargetGender[];
  senders?: MessageSender;
  partner?: Partner;
  starts?: Date | null;
  ends?: Date | null;
  title: string;
  content: string;
  ingress?: string;
  limitHandicapFrom: HandicapValue;
  limitHandicapTo: HandicapValue;
  limitAgeFrom?: number;
  limitAgeTo?: number;
  greenCardOnly?: boolean;
  functionaryOnly?: boolean;
  images?: Array<any>;
  buttonTitle?: string;
  buttonLink?: string;
  codesFile?: any;
  codes?: OfferCodes;
  listedReceivers: boolean;
  receiversRequiresUpdate: boolean;
  receiversData?: number[];
  limitFunctionaryTitles?: FunctionaryTitle[] | undefined;
  limitReceivesClubLetter: ReceivesClubLetterEnum;
  limitRegions: RegionEntry[];
  limitClubs: Club[];
  requestingRecipients: boolean;
}

const initialState: State = {
  slideIndex: 0,
  limitGender: ['MALE', 'FEMALE', 'NONE'] as TargetGender[],
  starts: null,
  ends: null,
  title: '',
  content: '',
  ingress: '',
  limitHandicapFrom: new HandicapValue(),
  limitHandicapTo: new HandicapValue(),
  limitAgeFrom: undefined,
  limitAgeTo: undefined,
  greenCardOnly: false,
  functionaryOnly: false,
  buttonLink: '',
  buttonTitle: '',
  images: undefined,
  codesFile: undefined,
  codes: undefined,
  senders: undefined,
  listedReceivers: false,
  receiversRequiresUpdate: false,
  limitFunctionaryTitles: [],
  limitReceivesClubLetter: ReceivesClubLetterEnum.ALL,
  limitRegions: [],
  limitClubs: [],
  requestingRecipients: false
};

class OfferForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.targetingUpdated = this.targetingUpdated.bind(this);

    const {
      offerId,
      offer: {
        offer
      }
    } = this.props;

    if (!offerId) {
      this.state = {
        ...initialState,
        senders: parseCorrectMessageSenderObject(this.props.auth.activeState),
      };
    } else if (offer) {
      this.state = {
        ...offer as unknown as State,
        slideIndex: 0,
        senders: offer.clubInfo ?
            {
              id: 'club:' + offer.clubInfo.idClub,
              entityId: offer.clubInfo.idClub,
              name: offer.clubInfo.nameClub,
              type: 'club'
            } :
            {
              id: 'federation:' + offer.federationInfo.idFederation,
              entityId: offer.federationInfo.idFederation,
              name: offer.federationInfo.name,
              type: 'federation'
            },
        partner: offer.partnerInfo,
      };

      if (this.state.limitAgeFrom === null) {
        this.state = {
          ...this.state,
          limitAgeFrom: undefined,
        };
      }

      if (this.state.limitAgeTo === null) {
        this.state = {
          ...this.state,
          limitAgeTo: undefined,
        };
      }

      // Initialize handicap limits
      this.state = {
        ...this.state,
        limitHandicapFrom: HandicapValue.parseValue(offer.limitHandicapFrom?.toString(10)),
        limitHandicapTo: HandicapValue.parseValue(offer.limitHandicapTo?.toString(10)),
        listedReceivers: offer.listedReceivers || false,
        receiversData: this.state.receiversData || offer.receivers,
      };
    } else {
      this.state = {
        ...initialState,
        senders: parseCorrectMessageSenderObject(this.props.auth.activeState),
      };
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      offerId,
      offer: {
        offer,
        requesting,
      },
    } = nextProps;

    if (!offerId) {
      return; // Do nothing if we are adding new person
    } else if (requesting) {
      return; // Do nothing state is loading
    } else if (offer && offer !== this.props.offer.offer) {
      this.setState({
        ...offer as unknown as State,
        senders: offer.clubInfo ?
            {
              id: 'club:' + offer.clubInfo.idClub,
              entityId: offer.clubInfo.idClub,
              name: offer.clubInfo.nameClub,
              type: 'club'
            } :
            {
              id: 'federation:' + offer.federationInfo.idFederation,
              entityId: offer.federationInfo.idFederation,
              name: offer.federationInfo.name,
              type: 'federation'
            },
        partner: offer.partnerInfo,
        limitHandicapFrom: HandicapValue.parseValue(offer.limitHandicapFrom?.toString(10)),
        limitHandicapTo: HandicapValue.parseValue(offer.limitHandicapTo?.toString(10)),
        listedReceivers: offer.listedReceivers || false,
        receiversData: this.state.receiversData || offer.receivers,
      });
    }
  }

  targetingUpdated(value: any) {
    this.setState(value);
  }

  render() {
    const {
      senders,
      limitGender,
      partner,
      starts,
      ends,
      title,
      content,
      ingress,
      limitHandicapFrom,
      limitHandicapTo,
      limitAgeFrom,
      limitAgeTo,
      images,
      buttonLink,
      buttonTitle,
      codes,
      greenCardOnly,
      functionaryOnly,
      listedReceivers,
      receiversData,
      limitFunctionaryTitles,
      limitRegions,
      limitClubs,
      limitReceivesClubLetter,
    } = this.state;
    const {
      offerId,
      offer,
      parentContext,
      locale: {
        appLanguage
      },
      actionsContainerRef
    } = this.props;

    // Check if handicaps have errors and provide result to InputValidator
    //   There is an error if result !== undefined
    const isHandicapFromError = GetFromHandicapError(limitHandicapFrom, limitHandicapTo, true) ? undefined : 'no error';
    const isHandicapToError = GetToHandicapError(limitHandicapFrom, limitHandicapTo, true) ? undefined : 'no error';

    return (
      <InputsValidator 
        values={{ title, ingress, 
        limitHandicapFrom: isHandicapFromError,
        limitHandicapTo: isHandicapToError}}
      >
        {({ errorInputs, validateThenApply, validateValues }) => (
          <>
            {this.state.requestingRecipients && <ModalContentOverlayLoader/>}
            <Tabs
              onChange={this._handleSectionChange}
              value={this.state.slideIndex}
              indicatorColor={'primary'}
              variant={'fullWidth'}
            >
              <Tab
                label={<FormattedMessage id={'scenes.offers.form.sections.content.title'} />}
                value={0}
                style={{ minWidth: '0' }}
              />
              <Tab
                label={<FormattedMessage id={'scenes.offers.form.sections.targeting.title'} />}
                value={1}
                style={{ minWidth: '0' }}
              />
              <Tab
                label={<FormattedMessage id={'scenes.offers.form.sections.codes.title'} />}
                value={2}
                style={{ minWidth: '0' }}
              />
              <Tab
                label={<FormattedMessage id={'scenes.offers.form.sections.preview.title'} />}
                value={3}
                style={{ minWidth: '0' }}
              />
            </Tabs>

            <SwipeableViews
              index={this.state.slideIndex}
              onChangeIndex={this._handleSectionChange}
            >
              <SwipeableContentContainer style={{ overflow: 'hidden' }}>
                <ContentForm
                  buttonLink={buttonLink}
                  buttonTitle={buttonTitle}
                  images={images}
                  appLanguage={appLanguage}
                  partner={partner}
                  starts={starts}
                  ends={ends}
                  title={title}
                  content={content}
                  ingress={ingress}
                  contentUpdated={(value: any) => {
                    this.setState(value, validateValues);
                  }}
                  errorInputs={errorInputs}

                  offerId={offerId}
                  offer={offer}
                />
              </SwipeableContentContainer>

              <SwipeableContentContainer>
                <TargetingForm
                  limitHandicapFrom={limitHandicapFrom}
                  limitHandicapTo={limitHandicapTo}
                  limitGender={limitGender}
                  senders={senders}
                  limitAgeFrom={limitAgeFrom}
                  limitAgeTo={limitAgeTo}
                  greenCardOnly={greenCardOnly}
                  functionaryOnly={functionaryOnly}
                  listedReceivers={listedReceivers}
                  receiversData={receiversData}
                  limitFunctionaryTitles={limitFunctionaryTitles}
                  limitReceivesClubLetter={limitReceivesClubLetter}
                  limitRegions={limitRegions}
                  limitClubs={limitClubs}
                  targetingUpdated={this.targetingUpdated}
                />
              </SwipeableContentContainer>

              <SwipeableContentContainer>
                <CodesForm
                  codes={codes}
                  onChange={(codesFile: any) => {
                    this.setState({
                      codesFile,
                    });
                  }}
                />
              </SwipeableContentContainer>

              <SwipeableContentContainer>
                {this.state.slideIndex === 3 && (<>
                  <OfferPreview
                    title={title}
                    ingress={ingress}
                    content={content}
                    author={partner}
                    buttonTitle={buttonTitle}
                    images={images}
                  />
                  <hr/>
                  <Typography
                      variant={'h6'}
                      gutterBottom={true}
                  >
                    <FormattedMessage id={'scenes.offers.preview.recipients.title'} />
                  </Typography>
                  <Typography
                      variant={'body1'}
                      gutterBottom={true}
                  >
                    <FormattedMessage id={'scenes.offers.preview.recipients.description'} />
                  </Typography>
                  <Button
                      variant="contained"
                      color="default"
                      onClick={this._handleRefreshRecipients}
                  >
                    <FormattedMessage id={'scenes.offers.preview.recipients.button'} />
                  </Button>
                </>)}
              </SwipeableContentContainer>
            </SwipeableViews>

            {renderFormActions({
              id: offerId,
              parentContext,
              onClose: this._handleOnClose,
              onUpdate: validateThenApply.bind(this, this._handleOfferUpdate),
              onCreate: validateThenApply.bind(this, this._handleOfferCreate),
              containerRef: actionsContainerRef,
            })}
          </>
        )}
      </InputsValidator>
    );
  }

  private _handleRefreshRecipients = () => {

    this.setState({ requestingRecipients: true });

    const { fetchAllRecipients } = this.props;
    const params = this._formOfferParams();

    fetchAllRecipients({
      params: params as MessageAdd,
      onComplete: (params: APICallResult) => {
        this.setState({ requestingRecipients: false });
      }
    });
  }

  private _handleSectionChange = (event, value: number) => {
    this.setState({
      slideIndex: value,
    });
  }

  private _handleOnClose = () => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  }

  private _handleOfferCreate = () => {
    const { addOffer } = this.props;
    const params = this._formOfferParams();

    addOffer({
      ...params,
      onComplete: this._handleOnComplete,
    });
  }

  private _handleOfferUpdate = () => {
    const { editOffer } = this.props;
    const {
      offer: {
        offer
      }
    } = this.props;

    if (!offer) {
      return;
    }

    const params = this._formOfferParams();

    editOffer({
      id: offer.id,
      ...params,
      onComplete: this._handleOnComplete,
    });
  }

  private _formOfferParams = () => {
    const {
      senders,
      limitGender,
      starts,
      ends,
      title,
      content,
      ingress,
      limitHandicapFrom,
      limitHandicapTo,
      limitAgeFrom,
      limitAgeTo,
      greenCardOnly,
      functionaryOnly,
      buttonLink,
      buttonTitle,
      images,
      codesFile,
      receiversData,
      receiversRequiresUpdate,
      limitFunctionaryTitles,
      limitReceivesClubLetter,
      limitRegions,
      limitClubs
    } = this.state;

    /*
      Partner param can come from state (if admin) or activeState (if partner)
     */
    const partner = parseCorrectPartnerObject(this.state, this.props.auth.activeState);
    /*
      Club parameter is used for caddies
     */
    const clubId = (senders as MessageSender).type === 'club' ? (senders as MessageSender).entityId : undefined;
    const federationId = (senders as MessageSender).type === 'federation' ?
        (senders as MessageSender).entityId : undefined;

    return {
      limitGender,
      partnerId: partner ? partner.id : undefined,
      starts,
      ends,
      title,
      content,
      ingress,
      limitHandicapFrom: limitHandicapFrom.handicap || null,
      limitHandicapTo: limitHandicapTo.handicap || null,
      limitAgeFrom,
      limitAgeTo,
      greenCardOnly,
      functionaryOnly,
      buttonLink,
      buttonTitle,
      images: JSON.stringify(images),
      codesFile,
      clubId: clubId,
      federationId: federationId,
      // Update receivers only if update flag is set
      receivers: receiversRequiresUpdate ? (receiversData || []) : undefined,
      limitFunctionaryTitles,
      limitReceivesClubLetter,
      limitRegions,
      limitClubs
    };
  }

  private _handleOnComplete = (result: any) => {
    
    if (result.error) {
      window.alert(result.error.message);
    } else {

      // Check if server added all of the receivers
      const badReceivers = this._checkRecieversData(result);

      // If server did not add all, show error
      if (badReceivers.length > 0) {
        const intl = this.props.intl;

        let msg = intl.formatMessage({id: `scenes.messages.form.userTargeting.errorServerRejectInfo`});
        msg += `\n\n` + intl.formatMessage({id: `scenes.messages.form.userTargeting.errorServerRejectHeading`}) + `:\n`;
        msg += badReceivers.join(`, `);

        window.alert(msg); // Exec blocks here, until closed. Then _handleOnClose is called
      }

      this._handleOnClose();
    }
  }

  private _checkRecieversData(result: any) {
    const serverReceivers = result?.data?.receivers;
    const {receiversRequiresUpdate} = this.state;

    // Only validate data if server sent some and it is required
    if (!serverReceivers || !Array.isArray(serverReceivers) || !receiversRequiresUpdate) {
      return [];
    }

    // Check that receivers data is same as from server
    const receiversData = this.state.receiversData || [];

    // From receivers, filter those that server added
    // return receiversData.filter(a => serverReceivers.find(b => a === b));
    return receiversData.filter(sentReceiver => {
      const found = serverReceivers.find(e => sentReceiver === e);

      return found == null;
    });
  }
}

export default connect((state: any) => ({
  locale: state.locale,
  offer: state.offerReducer,
  auth: state.authReducer,
}), {
  addOffer: offerActions.addOffer,
  editOffer: offerActions.editOffer,
  fetchAllRecipients: messagesAction.fetchAllRecipients,
})(injectIntl(OfferForm));