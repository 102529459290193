import React, { PureComponent, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import Select from '@src/components/forms/Select';
import { connect } from 'react-redux';
import { PropTypes } from '@material-ui/core';
import Margin = PropTypes.Margin;
import * as massagesActions from '@src/store/messages/actions';

interface Props {
  senders: MessageSender[];
  value?: MessageSender | MessageSender[];
  multi?: boolean;
  disabled?: boolean;
  margin?: Margin;
  error?: boolean;
  onChangeCallback?(values: Array<{id: number}>): void;
  fetchAllSenders(): void;
}

class MessageSenderSelect extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);

    if (!props.senders.length) {
      props.fetchAllSenders();
    }
  }

  render() {
    const {
      senders,
      value,
      multi,
      disabled,
      margin,
      error,
    } = this.props;

    const labelText = (): ReactNode => {
      return (
        <FormattedMessage
          id={multi ? 'forms.senderSelect.multi.label' : 'forms.senderSelect.single.label'}
        />
      );
    };

    const placeholderText = (): ReactNode => {
      return (
        <FormattedMessage
          id={multi ? 'forms.senderSelect.multi.placeholder' : 'forms.senderSelect.single.placeholder'}
        />
      );
    };

    return (
      <Select
        error={error}
        margin={margin}
        disabled={disabled}
        options={senders.sort((a, b) => {
          let typeSort = a.type.localeCompare(b.type, 'en');
          return typeSort ? -typeSort : (a.name ? a.name.localeCompare(b.name, 'fi') : -1);
        })}
        selected={value}
        multiSelect={multi}
        onChangeCallback={this._handleChange}
        labelText={labelText()}
        placeholderText={placeholderText()}
      />
    );
  }

  private _handleChange = (values: Array<{id: number}>) => {
    const { onChangeCallback } = this.props;
    if (onChangeCallback) {
      onChangeCallback(values);
    }
  }
}

export default connect((state: StoreState) => ({
  senders: state.messagesReducer.allSenders,
}), { fetchAllSenders: massagesActions.fetchAllSenders })(MessageSenderSelect);