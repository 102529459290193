import { SHARED_DEFAULT_REDUCER_STATE } from '@src/assets/config';
import {
  FEDERATIONS_CHANGE_PAGE,
  FEDERATIONS_ERROR,
  FEDERATIONS_REQUESTING,
  FEDERATIONS_SUCCESS
} from '@src/store/federations/constants';
import {
  formDefaultErrorState,
  formDefaultPagedState,
  formDefaultRequestingState,
  fromDefaultPageChangeState
} from '@src/utils/storeUtils';

const initialState: FederationsState = {
  ...SHARED_DEFAULT_REDUCER_STATE,
  pagedFederations: {},
};

export const federationsReducer = (state: FederationsState = initialState, action: any): FederationsState => {
  const {
    type,
  } = action;

  switch (type) {
    case FEDERATIONS_REQUESTING: {
      return formDefaultRequestingState(state);
    }

    case FEDERATIONS_SUCCESS: {
      return formDefaultPagedState(state, action, 'pagedFederations');
    }

    case FEDERATIONS_CHANGE_PAGE:
      return fromDefaultPageChangeState(state, action);

    case FEDERATIONS_ERROR: {
      return formDefaultErrorState(state, action);
    }

    default:
      return state;
  }
};