import {
  VENDOR_ADD_REQUESTING,
  VENDOR_DELETE_REQUESTING,
  VENDOR_EDIT_REQUESTING,
  VENDOR_GET_REQUESTING,
  VENDOR_CLUBS_REQUESTING
} from '@src/store/vendor/constants';

export const getVendor = ({
  id
}: VendorGet) => {
  return {
    type: VENDOR_GET_REQUESTING,
    vendorId: id,
  };
};

export const addVendor = (params: VendorAdd) => {
  return {
    type: VENDOR_ADD_REQUESTING,
    params
  };
};

export const editVendor = (params: VendorEdit) => {
  return {
    type: VENDOR_EDIT_REQUESTING,
    vendorId: params.id,
    params
  };
};

export const deleteVendor = ({
  id,
  onComplete,
}: VendorDelete) => {
  return {
    type: VENDOR_DELETE_REQUESTING,
    vendorId: id,
    params: {
      onComplete,
    },
  };
};

export const fetchVendorClubs = ({
  vendorId,
}: FetchVendorClubs) => {
  return {
    type: VENDOR_CLUBS_REQUESTING,
    vendorId,
  };
};