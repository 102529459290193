import { fork, take } from 'redux-saga/effects';
import { AUTH_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { APIRoute } from '@src/assets/config';
import {
  CLUB_GREENCARD_ADD_REQUESTING,
  CLUB_GREENCARD_DELETE_REQUESTING,
  CLUB_GREENCARD_EDIT_REQUESTING,
  CLUB_GREENCARD_GET_REQUESTING
} from '@src/store/clubGreencard/constants';
import { CLUB_GREENCARDS_RESET } from '@src/store/clubGreencards/constants';

/*
  GET|POST|PUT|DELETE /admin/1/greenCard/<id>
 */
const clubGreenCardCRUDFlow = (clubId?: number) => {
  return SagaFlowFactory.createCRUDFlow({
    apiRoute: APIRoute.CLUB_GREEN_CARD,
    typePrefix: 'CLUB_GREENCARD',
    resetType: CLUB_GREENCARDS_RESET,
    options: {
      resetParams: {
        clubId: clubId
      }
    }
  });
};

function * clubGreenCardWatcher() {
  let token: string = '';

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
      greenCardId,
      params
    } = yield take([
      CLUB_GREENCARD_GET_REQUESTING,
      CLUB_GREENCARD_ADD_REQUESTING,
      CLUB_GREENCARD_EDIT_REQUESTING,
      CLUB_GREENCARD_DELETE_REQUESTING
    ]);

    switch (type) {
      case CLUB_GREENCARD_GET_REQUESTING:
        yield fork(clubGreenCardCRUDFlow(), token, 'GET', greenCardId, params);
        break;
      case CLUB_GREENCARD_ADD_REQUESTING:
        yield fork(clubGreenCardCRUDFlow(params.clubId), token, 'ADD', null, params);
        break;
      case CLUB_GREENCARD_EDIT_REQUESTING:
        yield fork(clubGreenCardCRUDFlow(), token, 'EDIT', greenCardId, params);
        break;
      case CLUB_GREENCARD_DELETE_REQUESTING:
        yield fork(clubGreenCardCRUDFlow(), token, 'DELETE', greenCardId, params);
        break;
      default:
    }
  }
}

export default clubGreenCardWatcher;