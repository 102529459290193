import React, { PureComponent, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import Select from '@src/components/forms/Select';
import { connect } from 'react-redux';
import { PropTypes } from '@material-ui/core';
import Margin = PropTypes.Margin;

interface Props {
  partners: Array<Partner>;
  selectedPartners?: Array<Partner> | Partner | undefined;
  multi?: boolean;
  disabled?: boolean;
  margin?: Margin;
  error?: boolean;
  onChangeCallback?(values: Array<{id: number}>): void;
}

class PartnerSelectConnected extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      partners,
      selectedPartners,
      multi,
      disabled,
      margin,
      error,
    } = this.props;

    const labelText = (): ReactNode => {
      return (
        <FormattedMessage
          id={multi ? 'forms.partnerSelect.multi.label' : 'forms.partnerSelect.single.label'}
        />
      );
    };

    const placeholderText = (): ReactNode => {
      return (
        <FormattedMessage
          id={multi ? 'forms.partnerSelect.multi.placeholder' : 'forms.partnerSelect.single.placeholder'}
        />
      );
    };

    return (
      <Select
        error={error}
        margin={margin}
        disabled={disabled}
        options={partners}
        selected={selectedPartners}
        multiSelect={multi}
        onChangeCallback={this._handleChange}
        labelText={labelText()}
        placeholderText={placeholderText()}
      />
    );
  }

  private _handleChange = (values: Array<{id: number}>) => {
    const { onChangeCallback } = this.props;
    if (onChangeCallback) {
      onChangeCallback(values);
    }
  }
}

const PartnerSelect = connect((state: any) => ({
  partners: state.partnersReducer.allPartners || [],
}))(PartnerSelectConnected);

export {
  PartnerSelect
};