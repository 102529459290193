import { tokenAPIDelete, tokenAPIPost, tokenAPIPut } from '@src/utils/storeUtils';
import { API_ROOT, APIRoute } from '@src/assets/config';

export type OrganizationPersonStatus = {
  organizationId: number;
  personId: number;
};

const handleOrganizationPersonStatus = ({
  organizationId,
  personId,
  action,
}: OrganizationPersonStatus & { action: 'promote' | 'demote' | 'activate' | 'deactivate' }) => {
  return tokenAPIPut(
    `${API_ROOT}${APIRoute.ORGANIZATION}/${organizationId}/person/${personId}/${action}`,
    window.localStorage.getItem('authToken') as string,
    {},
  );
};

export const promoteOrganizationPerson = (args: OrganizationPersonStatus) => {
  return handleOrganizationPersonStatus({
    ...args,
    action: 'promote',
  });
};

export const demoteOrganizationPerson = (args: OrganizationPersonStatus) => {
  return handleOrganizationPersonStatus({
    ...args,
    action: 'demote',
  });
};

export const activateOrganizationPerson = (args: OrganizationPersonStatus) => {
  return handleOrganizationPersonStatus({
    ...args,
    action: 'activate',
  });
};

export const deactivateOrganizationPerson = (args: OrganizationPersonStatus) => {
  return handleOrganizationPersonStatus({
    ...args,
    action: 'deactivate',
  });
};

type AddPersonToOrganization = {
  organizationId: number,
  personId: number,
  targetIdParams: TargetIdParams,
};

export const addPersonToOrganization = ({
  organizationId,
  personId,
  targetIdParams,
}: AddPersonToOrganization) => {
  return tokenAPIPost(
    `${API_ROOT}${APIRoute.ORGANIZATIONS}/person`,
    window.localStorage.getItem('authToken') as string,
    {
      idOrganization: organizationId,
      idClub: targetIdParams.clubId,
      idFederation: targetIdParams.federationId,
      idAssociation: targetIdParams.associationId,
      idPerson: personId,
      isHeadOrganization: false,
      isVisibleOnField: false,
      dateJoin: new Date().toISOString(),
      status: 'ACTIVE',
    },
  );
};

export const removePersonFromOrganization = ({
  organizationId,
  personId,
}: { organizationId: number, personId: number }) => {
  return tokenAPIDelete(
    `${API_ROOT}${APIRoute.ORGANIZATION}/${organizationId}/person/${personId}`,
    window.localStorage.getItem('authToken') as string,
    {},
  );
};