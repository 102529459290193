import {
  FUNCTIONARY_TITLES_CHANGE_PAGE,
  FUNCTIONARY_TITLES_CONDENSED_REQUESTING,
  FUNCTIONARY_TITLES_REQUESTING,
  FUNCTIONARY_TITLES_SET_INITIAL_STATE,
  FUNCTIONARY_TITLES_SET_TARGET_ID_PARAMS
} from './constants';
import { DEFAULT_PAGINATION_LIMIT } from '@src/assets/config';
import { createTargetIdParamsActionsWithType } from '@src/utils/storeUtils';

export const setTargetIdParams = createTargetIdParamsActionsWithType(FUNCTIONARY_TITLES_SET_TARGET_ID_PARAMS);

export const fetchFunctionaryTitles = ({
  page,
  limit = DEFAULT_PAGINATION_LIMIT
}: TablePaginationAction) => {
  return {
    type: FUNCTIONARY_TITLES_REQUESTING,
    page,
    limit
  };
};

export const changePage = ({
  params,
  page,
}: ChangePageAction) => {
  return {
    type: FUNCTIONARY_TITLES_CHANGE_PAGE,
    page,
    params
  };
};

export const setFunctionaryTitlesInitialState = () => {
  return {
    type: FUNCTIONARY_TITLES_SET_INITIAL_STATE,
  };
};

export const fetchCondensedFunctionaryTitles = () => {
  return {
    type: FUNCTIONARY_TITLES_CONDENSED_REQUESTING,
  };
};