import { Action } from 'redux';
import {
  ADD_ACCEPTABLE_SCORE_REQUESTING,
  ADD_ACCEPTABLE_SCORE_AWAY_REQUESTING,
  EDIT_ACCEPTABLE_SCORE_ADJUSTMENT_REQUESTING,
  EDIT_ACCEPTABLE_SCORE_HOLE_SCORE_REQUESTING,
  EDIT_ACCEPTABLE_SCORE_STATUS_REQUESTING,
  EDIT_ACCEPTABLE_SCORE_PERSON_REQUESTING
} from '@src/store/acceptableScore/constants';

export const addAcceptableScore = (params: AcceptableScoreAdd) => {
  return {
    type: ADD_ACCEPTABLE_SCORE_REQUESTING,
    params,
  };
};

export interface AddAcceptableScoreAction extends Action, AcceptableScoreAdd {
  type: 'ADD_ACCEPTABLE_SCORE_REQUESTING';
}

export interface AddAcceptableScoreSuccessAction {
  type: 'ADD_ACCEPTABLE_SCORE_SUCCESS';
}

export interface AddAcceptableScoreErrorAction {
  type: 'ADD_ACCEPTABLE_SCORE_ERROR';
}

export const addAcceptableScoreAway = (params: AcceptableScoreAwayAdd) => {
  return {
    type: ADD_ACCEPTABLE_SCORE_AWAY_REQUESTING,
    params,
  };
};

export interface AddAcceptableScoreAwayAction extends Action, AcceptableScoreAwayAdd {
  type: 'ADD_ACCEPTABLE_SCORE_AWAY_REQUESTING';
}

export interface AddAcceptableScoreAwaySuccessAction {
  type: 'ADD_ACCEPTABLE_SCORE_AWAY_SUCCESS';
}

export interface AddAcceptableScoreAwayErrorAction {
  type: 'ADD_ACCEPTABLE_SCORE_AWAY_ERROR';
}

export interface EditAcceptableScoreAdjustmentPayload extends APICall {
  acceptableScoreId: number;
  acceptableScoreAdjustmentId?: number;
  adjustmentType: string;
  adjustmentAmount: number;
  previousAffectedScoresAmount?: number;
  resetLowHandicapIndex?: boolean;
}
export interface EditAcceptableScoreAdjustmentAction extends Action, EditAcceptableScoreAdjustmentPayload {
  type: 'EDIT_ACCEPTABLE_SCORE_ADJUSTMENT_REQUESTING';
}
export interface EditAcceptableScoreAdjustmentSuccessAction {
  type: 'EDIT_ACCEPTABLE_SCORE_ADJUSTMENT_SUCCESS';
}
export interface EditAcceptableScoreAdjustmentErrorAction {
  type: 'EDIT_ACCEPTABLE_SCORE_ADJUSTMENT_ERROR';
}
export const editAcceptableScoreAdjustment = (
  args: EditAcceptableScoreAdjustmentPayload
): EditAcceptableScoreAdjustmentAction => ({
  type: EDIT_ACCEPTABLE_SCORE_ADJUSTMENT_REQUESTING,
  ...args,
});

export interface EditAcceptableScoreHoleScorePayload extends APICall {
  acceptableScoreId: number;
  holes: (number | string)[];
}
export interface EditAcceptableScoreHoleScoreAction extends Action, EditAcceptableScoreHoleScorePayload {
  type: 'EDIT_ACCEPTABLE_SCORE_HOLE_SCORE_REQUESTING';
}
export interface EditAcceptableScoreHoleScoreSuccessAction {
  type: 'EDIT_ACCEPTABLE_SCORE_HOLE_SCORE_SUCCESS';
}
export interface EditAcceptableScoreHoleScoreErrorAction {
  type: 'EDIT_ACCEPTABLE_SCORE_HOLE_SCORE_ERROR';
}
export const editAcceptableScoreHoleScore = (
  args: EditAcceptableScoreHoleScorePayload,
): EditAcceptableScoreHoleScoreAction => ({
  type: EDIT_ACCEPTABLE_SCORE_HOLE_SCORE_REQUESTING,
  ...args,
});

export interface EditAcceptableScoreStatusPayload extends APICall {
  acceptableScoreId: number;
  status: string;
}
export interface EditAcceptableScoreStatusAction extends Action, EditAcceptableScoreStatusPayload {
  type: 'EDIT_ACCEPTABLE_SCORE_STATUS_REQUESTING';
}
export interface EditAcceptableScoreStatusSuccessAction {
  type: 'EDIT_ACCEPTABLE_SCORE_STATUS_SUCCESS';
}
export interface EditAcceptableScoreStatusErrorAction {
  type: 'EDIT_ACCEPTABLE_SCORE_STATUS_ERROR';
}
export const editAcceptableScoreStatus = (
  args: EditAcceptableScoreStatusPayload,
): EditAcceptableScoreStatusAction => ({
  type: EDIT_ACCEPTABLE_SCORE_STATUS_REQUESTING,
  ...args,
});

export interface EditAcceptableScorePersonPayload extends APICall {
  acceptableScoreId: number;
  personId: number;
}
export interface EditAcceptableScorePersonAction extends Action, EditAcceptableScorePersonPayload {
  type: 'EDIT_ACCEPTABLE_SCORE_PERSON_REQUESTING';
}
export interface EditAcceptableScorePersonSuccessAction {
  type: 'EDIT_ACCEPTABLE_SCORE_PERSON_SUCCESS';
}
export interface EditAcceptableScorePersonErrorAction {
  type: 'EDIT_ACCEPTABLE_SCORE_PERSON_ERROR';
}
export const editAcceptableScorePerson = (
  args: EditAcceptableScorePersonPayload,
): EditAcceptableScorePersonAction => ({
  type: EDIT_ACCEPTABLE_SCORE_PERSON_REQUESTING,
  ...args,
});