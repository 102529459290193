import { fork, select, takeEvery, all, put } from 'redux-saga/effects';
import { userAuthToken } from '@src/store/auth/selectors';
import {
  INVALIDATED_SCORES_ERROR, INVALIDATED_SCORES_REFETCH,
  INVALIDATED_SCORES_REQUESTING, INVALIDATED_SCORES_SUCCESS,
} from '@src/store/invalidatedScores/constants';
import { APIRoute } from '@src/assets/config';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { personStatePersonId } from '@src/store/person/selectors';
import { getInvalidatedScoresActivePage } from '@src/store/invalidatedScores/selectors';
import { GetInvalidatedScoresAction } from './actions';

/*
  GET ${APIRoute.HANDICAP}/person/${personId}/acceptable-score-rounds
 */
const pagedFlow = (personId: number) => (
  SagaFlowFactory.createPagedGETFlow({
    apiRoute: APIRoute.acceptableScores(personId),
    itemType: 'scores',
    successType: INVALIDATED_SCORES_SUCCESS,
    errorType: INVALIDATED_SCORES_ERROR,
  })
);

function* fetchPagedScoresFlow({
  personId,
  page,
  limit = 25,
}: GetInvalidatedScoresAction) {
  const token = yield select(userAuthToken);
  yield fork(pagedFlow(personId), token, page, limit, { invalidated: true });
}

function* handleRefetch() {
  const personId = yield select(personStatePersonId);
  const page = yield select(getInvalidatedScoresActivePage);
  yield put({
    type: INVALIDATED_SCORES_REQUESTING,
    personId,
    page,
  });
}

export function* invalidatedScoresWatcher() {
  yield all([
    takeEvery(INVALIDATED_SCORES_REQUESTING, fetchPagedScoresFlow),
    takeEvery(INVALIDATED_SCORES_REFETCH, handleRefetch),
  ]);
}
