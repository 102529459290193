export const CLUB_GREENCARD_GET_REQUESTING = 'CLUB_GREENCARD_GET_REQUESTING';
export const CLUB_GREENCARD_GET_SUCCESS = 'CLUB_GREENCARD_GET_SUCCESS';
export const CLUB_GREENCARD_GET_ERROR = 'CLUB_GREENCARD_GET_ERROR';

export const CLUB_GREENCARD_ADD_REQUESTING = 'CLUB_GREENCARD_ADD_REQUESTING';
export const CLUB_GREENCARD_ADD_SUCCESS = 'CLUB_GREENCARD_ADD_SUCCESS';
export const CLUB_GREENCARD_ADD_ERROR = 'CLUB_GREENCARD_ADD_ERROR';

export const CLUB_GREENCARD_EDIT_REQUESTING = 'CLUB_GREENCARD_EDIT_REQUESTING';
export const CLUB_GREENCARD_EDIT_SUCCESS = 'CLUB_GREENCARD_EDIT_SUCCESS';
export const CLUB_GREENCARD_EDIT_ERROR = 'CLUB_GREENCARD_EDIT_ERROR';

export const CLUB_GREENCARD_DELETE_REQUESTING = 'CLUB_GREENCARD_DELETE_REQUESTING';
export const CLUB_GREENCARD_DELETE_SUCCESS = 'CLUB_GREENCARD_DELETE_SUCCESS';
export const CLUB_GREENCARD_DELETE_ERROR = 'CLUB_GREENCARD_DELETE_ERROR';