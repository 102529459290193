import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import * as personActions from '@src/store/person/actions';
import * as personsActions from '@src/store/persons/actions';
import {
  ContentHeader,
  ContentHeaderTitle,
  ContentWrap,
} from '@src/components/layouts/ui';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import PersonsSearchBar from '@src/components/persons/PersonsSearchBar';
import PersonsTable from '@src/components/persons/PersonsTable';

interface Props extends RouteComponentProps<any>, WrappedComponentProps {
  personsState: PersonsState;
  personRequesting: boolean;
  setTargetIdParams: (args: SetTargetIdParams) => any;
  deletePerson: (args: PersonDelete) => any;
  searchChanged: (args: PersonsSearch) => any;
  fetchSearch: (args: SearchPaginationAction) => any;
  resetSearch: () => any;
  locale: AppLocale;
}

class AdminProblematicMembers extends Component<Props, {}> {

  UNSAFE_componentWillMount(): void {
    // NOP
  }

  render() {
    const { personRequesting } = this.props;
    return (
      <>
        <HeadHelmet titleId={'scenes.problematicMembers.title'} />
        <ContentWrap>
          <ContentHeader>
            <ContentHeaderTitle>
              <FormattedMessage id={'scenes.problematicMembers.title'} />
            </ContentHeaderTitle>

            <PersonsSearchBar withProblemTypes={true} />
          </ContentHeader>

          <PersonsTable
            forceLoader={personRequesting}
            deferInitialLoad={true}
            onPersonPicked={(person: Person) => {
              window.console.log('person to be deleted', person);
              const {
                deletePerson,
                fetchSearch,
                personsState: {
                  search,
                  pagination,
                },
                locale: {
                  appLanguage: {
                    collation
                  }
                }
              } = this.props;

              deletePerson({
                id: person.idPerson,
                onComplete: ({ error }) => {
                  if (error) {
                    window.alert('Unable to remove person.');
                  }

                  fetchSearch({
                    page: pagination.page,
                    params: { ...search, collation }
                  });
                },
              });
            }}
            rowActionType={'REMOVE'}
            memberNoClickable={true}
            onMemberNoClick={(person: Person) => {
              this.props.history.push(`/admin/members/problematic-members/${person.idHomeClubMembership ? 
              person.idHomeClubMembership : person.idPerson}/${person.club.id}`);
            }}
          />
        </ContentWrap>
      </>
    );
  }
}

export default injectIntl(withRouter(connect((state: StoreState) => ({
  personsState: state.personsReducer,
  personRequesting: state.personReducer.requesting,
  locale: state.locale
}), {
  setTargetIdParams: personsActions.setTargetIdParams,
  fetchSearch: personsActions.fetchSearch,
  searchChanged: personsActions.searchChanged,
  resetSearch: personsActions.resetSearch,
  deletePerson: personActions.deletePerson,
})(AdminProblematicMembers)));