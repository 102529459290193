import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import LocalizedDateTimePicker from '@src/components/forms/LocalizedDateTimePicker';
import Button from '@material-ui/core/Button';

export interface DateTimePickerConfirmChildrenProps {
  showPicker(): void;
}

type Props = {
  titleId?: string;
  messageId?: string;
  minDate?: Date | string;
  onSuccess?(date: Date): void;
  onCancel?(): void;
  children(args: DateTimePickerConfirmChildrenProps): void;
};

function DateTimePickerConfirm({
  titleId = 'buttons.publish',
  messageId = 'strings.defaultPublishTitle',
  minDate,
  onSuccess,
  onCancel,
  children,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const [dateTime, setDateTime] = useState<Date>(new Date());

  const handleOnSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
    }
  };

  const handleOnCancel = () => {
    setOpen(false);
    if (onCancel) {
      onCancel();
    }
  };

  const handleOnSuccess = (date: Date) => {
    setOpen(false);
    if (onSuccess) {
      onSuccess(date);
    }
  };

  return (
    <>
      <Dialog open={open} disableBackdropClick={true} maxWidth={'xs'}>
        <DialogTitle style={{ marginBottom: 14 }}>
          <FormattedMessage id={titleId} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage id={messageId} />
          </DialogContentText>
          <form action="" onSubmit={handleOnSubmit}>
            <LocalizedDateTimePicker
              value={dateTime}
              minDate={minDate}
              onChange={val => {
                if (val !== null) {
                  setDateTime(val);
                }
              }}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnCancel}>
            <FormattedMessage id={'buttons.cancel'} />
          </Button>
          <Button onClick={() => handleOnSuccess(dateTime)} color="primary" autoFocus={true}>
            <FormattedMessage id={'buttons.publish'} />
          </Button>
        </DialogActions>
      </Dialog>

      {children({
        showPicker: () => setOpen(true),
      })}
    </>
  );
}

export default DateTimePickerConfirm;