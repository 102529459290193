import { take, fork } from 'redux-saga/effects';
import {
  ORGANIZATION_GET_REQUESTING,
  ORGANIZATION_ADD_REQUESTING,
  ORGANIZATION_EDIT_REQUESTING,
  ORGANIZATION_DELETE_REQUESTING,
  ORGANIZATION_PERSONS_REQUESTING,
  ORGANIZATION_PERSON_ATTRIBUTE_SET,
} from './constants';
import { APIRoute } from '@src/assets/config';
import { AUTH_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { ORGANIZATIONS_RESET } from '@src/store/organizations/constants';
import { fetchOrganizationPersonsFlow } from '@src/store/organization/flows/fetch-organization-persons';
import { putOrganizationPersonAttribute } from '@src/store/organization/flows/put-organization-person-attribute';

/*
  GET|POST|PUT|DELETE /admin/1/organization/<id>
 */
const fetchOrganizationCRUDFlow = SagaFlowFactory.createCRUDFlow({
  apiRoute: APIRoute.ORGANIZATION,
  typePrefix: 'ORGANIZATION',
  resetType: ORGANIZATIONS_RESET,
});

function * organizationWatcher() {
  let token: string = '';

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
      organizationId,
      params,
      personId,
      action,
      onComplete,
    } = yield take([
      ORGANIZATION_GET_REQUESTING,
      ORGANIZATION_ADD_REQUESTING,
      ORGANIZATION_EDIT_REQUESTING,
      ORGANIZATION_DELETE_REQUESTING,
      ORGANIZATION_PERSONS_REQUESTING,
      ORGANIZATION_PERSON_ATTRIBUTE_SET,
    ]);

    switch (type) {
      case ORGANIZATION_GET_REQUESTING:
        yield fork(fetchOrganizationCRUDFlow, token, 'GET', organizationId, params);
        break;
      case ORGANIZATION_ADD_REQUESTING:
        yield fork(fetchOrganizationCRUDFlow, token, 'ADD', null, params);
        break;
      case ORGANIZATION_EDIT_REQUESTING:
        yield fork(fetchOrganizationCRUDFlow, token, 'EDIT', organizationId, params);
        break;
      case ORGANIZATION_DELETE_REQUESTING:
        yield fork(fetchOrganizationCRUDFlow, token, 'DELETE', organizationId, params);
        break;
      case ORGANIZATION_PERSONS_REQUESTING:
        yield fork(fetchOrganizationPersonsFlow, token, organizationId);
        break;
      case ORGANIZATION_PERSON_ATTRIBUTE_SET:
        yield fork(putOrganizationPersonAttribute, token, organizationId, personId, action, onComplete);
        break;
      default:
    }
  }
}

export default organizationWatcher;