/*
  PUT /admin/1/club/{id}/activateIfBillsPaid
 */
import { stateTargetIdParams, tokenAPIPut } from '@src/utils/storeUtils';
import { API_ROOT, APIRoute } from '@src/assets/config';
import { call, put, select } from 'redux-saga/effects';
import {
  CLUB_UPDATE_ACTIVATE_IF_BILLS_PAID_SUCCESS,
  CLUB_UPDATE_ACTIVATE_IF_BILLS_PAID_ERROR,
  CLUB_MEMBERS_SEARCH_RESET
} from '@src/store/club/constants';
import { handleOnFunction } from '@src/utils/utils';

export function * setClubActivateIfBillsArePaidFlow(token: string, clubId: number, params: any) {
    try {
      const club = yield call(() => {
        const endpoint = (params?.activateIfBillsPaid ?? false) ? 'activateIfBillsPaid' : 'deactivateIfBillsPaid';

        const url = `${API_ROOT}${APIRoute.GET_CLUB}/${clubId}/${endpoint}`;
        return tokenAPIPut(url, token, params);
      });

      if (club) {
        const {
          id,
          status,
        } = club;

        handleOnFunction(params.onComplete, { data: club });

        yield put({
          type: CLUB_UPDATE_ACTIVATE_IF_BILLS_PAID_SUCCESS,
          id,
          status,
        });

        handleOnFunction(params.onComplete, { data: club });

        const targetIdParams: TargetIdParams = yield select(stateTargetIdParams('clubReducer'));

        yield put({
          type: CLUB_MEMBERS_SEARCH_RESET,
          clubId: targetIdParams.clubId,
        });
      }

    } catch (error) {
      window.console.error(error);
      handleOnFunction(params.onComplete, { error });
      yield put({ type: CLUB_UPDATE_ACTIVATE_IF_BILLS_PAID_ERROR, error });
    }

    return true;
}