
import {
  AddAcceptableScoreAction,
  AddAcceptableScoreSuccessAction,
  AddAcceptableScoreErrorAction,
  AddAcceptableScoreAwayAction,
  AddAcceptableScoreAwaySuccessAction,
  AddAcceptableScoreAwayErrorAction,
  EditAcceptableScoreAdjustmentAction,
  EditAcceptableScoreAdjustmentErrorAction,
  EditAcceptableScoreAdjustmentSuccessAction,
  EditAcceptableScoreHoleScoreAction,
  EditAcceptableScoreHoleScoreErrorAction,
  EditAcceptableScoreHoleScoreSuccessAction,
  EditAcceptableScoreStatusAction,
  EditAcceptableScoreStatusSuccessAction,
  EditAcceptableScoreStatusErrorAction,
  EditAcceptableScorePersonAction,
  EditAcceptableScorePersonSuccessAction,
  EditAcceptableScorePersonErrorAction,
} from '@src/store/acceptableScore/actions';
import {
  ADD_ACCEPTABLE_SCORE_REQUESTING,
  ADD_ACCEPTABLE_SCORE_AWAY_REQUESTING,
  ADD_ACCEPTABLE_SCORE_ERROR,
  ADD_ACCEPTABLE_SCORE_AWAY_ERROR,
  ADD_ACCEPTABLE_SCORE_SUCCESS,
  ADD_ACCEPTABLE_SCORE_AWAY_SUCCESS,
  EDIT_ACCEPTABLE_SCORE_ADJUSTMENT_ERROR,
  EDIT_ACCEPTABLE_SCORE_ADJUSTMENT_REQUESTING,
  EDIT_ACCEPTABLE_SCORE_ADJUSTMENT_SUCCESS,
  EDIT_ACCEPTABLE_SCORE_HOLE_SCORE_ERROR,
  EDIT_ACCEPTABLE_SCORE_HOLE_SCORE_REQUESTING,
  EDIT_ACCEPTABLE_SCORE_HOLE_SCORE_SUCCESS,
  EDIT_ACCEPTABLE_SCORE_STATUS_REQUESTING,
  EDIT_ACCEPTABLE_SCORE_STATUS_ERROR,
  EDIT_ACCEPTABLE_SCORE_STATUS_SUCCESS,
  EDIT_ACCEPTABLE_SCORE_PERSON_REQUESTING,
  EDIT_ACCEPTABLE_SCORE_PERSON_ERROR,
  EDIT_ACCEPTABLE_SCORE_PERSON_SUCCESS,
} from '@src/store/acceptableScore/constants';
import { formDefaultErrorState, formDefaultRequestingState } from '@src/utils/storeUtils';

const initialState: AcceptableScoreState = {
  requesting: false,
};

type Action = AddAcceptableScoreAction | AddAcceptableScoreSuccessAction | AddAcceptableScoreErrorAction
    | AddAcceptableScoreAwayAction | AddAcceptableScoreAwaySuccessAction | AddAcceptableScoreAwayErrorAction
    | EditAcceptableScoreAdjustmentAction | EditAcceptableScoreAdjustmentSuccessAction
    | EditAcceptableScoreAdjustmentErrorAction | EditAcceptableScoreHoleScoreAction
    | EditAcceptableScoreHoleScoreSuccessAction | EditAcceptableScoreHoleScoreErrorAction
    | EditAcceptableScoreStatusAction | EditAcceptableScoreStatusSuccessAction | EditAcceptableScoreStatusErrorAction
    | EditAcceptableScorePersonAction | EditAcceptableScorePersonSuccessAction | EditAcceptableScorePersonErrorAction;

export const acceptableScoreReducer = (state: AcceptableScoreState = initialState, action: Action) => {
  switch (action.type) {

    case ADD_ACCEPTABLE_SCORE_REQUESTING:
    case ADD_ACCEPTABLE_SCORE_AWAY_REQUESTING:
      return formDefaultRequestingState(state);

    case EDIT_ACCEPTABLE_SCORE_ADJUSTMENT_REQUESTING:
    case EDIT_ACCEPTABLE_SCORE_HOLE_SCORE_REQUESTING:
    case EDIT_ACCEPTABLE_SCORE_STATUS_REQUESTING:
    case EDIT_ACCEPTABLE_SCORE_PERSON_REQUESTING:
        return {
        ...state,
        requesting: true,
      };

    case ADD_ACCEPTABLE_SCORE_ERROR:
    case ADD_ACCEPTABLE_SCORE_AWAY_ERROR:
      return formDefaultErrorState(state, action);

    case ADD_ACCEPTABLE_SCORE_SUCCESS:
    case ADD_ACCEPTABLE_SCORE_AWAY_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
      };

    case EDIT_ACCEPTABLE_SCORE_ADJUSTMENT_SUCCESS:
    case EDIT_ACCEPTABLE_SCORE_ADJUSTMENT_ERROR:
    case EDIT_ACCEPTABLE_SCORE_HOLE_SCORE_SUCCESS:
    case EDIT_ACCEPTABLE_SCORE_HOLE_SCORE_ERROR:
    case EDIT_ACCEPTABLE_SCORE_STATUS_ERROR:
    case EDIT_ACCEPTABLE_SCORE_STATUS_SUCCESS:
    case EDIT_ACCEPTABLE_SCORE_PERSON_ERROR:
    case EDIT_ACCEPTABLE_SCORE_PERSON_SUCCESS:
          return {
        ...state,
        requesting: false,
      };
    default:
      return state;
  }
};