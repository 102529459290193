import React, { PureComponent } from 'react';
import Functionaries from '@src/components/scenes/functionaries/Functionaries';
import { connect } from 'react-redux';
import { getActiveFederationId } from '@src/store/auth/selectors';

type Props = {
  federationId: number;
};

class FederationFunctionaries extends PureComponent<Props, {}> {
  render() {
    const { federationId } = this.props;

    return (
      <Functionaries targetIdParams={{ federationId }}/>
    );
  }
}

export default connect((state: StoreState) => ({
  federationId: getActiveFederationId(state),
}), null)(FederationFunctionaries);