export const MEMBERSHIP_GET_REQUESTING = 'MEMBERSHIP_GET_REQUESTING';
export const MEMBERSHIP_GET_SUCCESS = 'MEMBERSHIP_GET_SUCCESS';
export const MEMBERSHIP_GET_ERROR = 'MEMBERSHIP_GET_ERROR';

export const MEMBERSHIP_ADD_REQUESTING = 'MEMBERSHIP_ADD_REQUESTING';
export const MEMBERSHIP_ADD_SUCCESS = 'MEMBERSHIP_ADD_SUCCESS';
export const MEMBERSHIP_ADD_ERROR = 'MEMBERSHIP_ADD_ERROR';

export const MEMBERSHIP_EDIT_REQUESTING = 'MEMBERSHIP_EDIT_REQUESTING';
export const MEMBERSHIP_EDIT_SUCCESS = 'MEMBERSHIP_EDIT_SUCCESS';
export const MEMBERSHIP_EDIT_POST_SUCCESS = 'MEMBERSHIP_EDIT_POST_SUCCESS';
export const MEMBERSHIP_EDIT_ERROR = 'MEMBERSHIP_EDIT_ERROR';
export const MEMBERSHIP_EDIT_POST_ERROR = 'MEMBERSHIP_EDIT_POST_ERROR';

export const MEMBERSHIP_INACTIVATE_REQUESTING = 'MEMBERSHIP_INACTIVATE_REQUESTING';
export const MEMBERSHIP_INACTIVATE_SUCCESS = 'MEMBERSHIP_INACTIVATE_SUCCESS';
export const MEMBERSHIP_INACTIVATE_ERROR = 'MEMBERSHIP_INACTIVATE_ERROR';

export const MEMBERSHIP_DELETE_REQUESTING = 'MEMBERSHIP_DELETE_REQUESTING';
export const MEMBERSHIP_DELETE_SUCCESS = 'MEMBERSHIP_DELETE_SUCCESS';
export const MEMBERSHIP_DELETE_ERROR = 'MEMBERSHIP_DELETE_ERROR';

export const MEMBERSHIP_HANDICAP_ROUNDS_REQUESTING = 'MEMBERSHIP_HANDICAP_ROUNDS_REQUESTING';
export const MEMBERSHIP_HANDICAP_ROUNDS_SUCCESS = 'MEMBERSHIP_HANDICAP_ROUNDS_SUCCESS';
export const MEMBERSHIP_HANDICAP_ROUNDS_ERROR = 'MEMBERSHIP_HANDICAP_ROUNDS_ERROR';

export const RESET_MEMBERSHIP_STATE = 'RESET_MEMBERSHIP_STATE';