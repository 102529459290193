import {
  CLUB_FEES_REQUESTING,
  CLUB_FEES_SUCCESS,
  CLUB_FEES_ERROR,
  CLUB_FEES_CHANGE_PAGE,
  CLUB_FEES_RESET,
  CLUB_FEES_SET_INITIAL_STATE,
  CLUB_FEES_SEARCH_CHANGED,
  CLUB_FEES_SEARCH_REQUEST,
  CLUB_FEES_SEARCH_RESET,
} from './constants';
import { SHARED_DEFAULT_REDUCER_STATE } from '@src/assets/config';
import {
  formDefaultErrorState,
  formDefaultPagedState,
  formDefaultRequestingState,
  fromDefaultPageChangeState,
  optimisticPagedObjectUpdate, searchStateChangedReducerHelper
} from '@src/utils/storeUtils';
import { CLUB_FEE_EDIT_SUCCESS } from '@src/store/clubFee/constants';

const initialState: ClubFeesState = {
  ...SHARED_DEFAULT_REDUCER_STATE,
  pagedFees: {},
  search: {
    name: '',
    status: ''
  }
};

export const clubFeesReducer = (state: ClubFeesState = initialState, action: any): ClubFeesState => {
  switch (action.type) {
    case CLUB_FEES_REQUESTING:
      return formDefaultRequestingState(state);

    case CLUB_FEES_SUCCESS:
      return formDefaultPagedState(state, action, 'pagedFees');

    case CLUB_FEES_CHANGE_PAGE:
      return fromDefaultPageChangeState(state, action);

    case CLUB_FEES_ERROR:
      return formDefaultErrorState(state, action);

    case CLUB_FEES_RESET:
      return {
        ...initialState,
        requesting: true
      };

    case CLUB_FEES_SEARCH_CHANGED:
      return searchStateChangedReducerHelper(state, action);

    case CLUB_FEES_SEARCH_REQUEST: {
      let searchActive = state.searchActive;
      let pagedFees = state.pagedFees;
      let pagination = state.pagination;

      if (!searchActive) {
        searchActive = true;
        pagedFees = {};
        pagination.totalCount = 0;
      }

      pagination.page = action.page;

      return {
        ...state,
        pagedFees,
        pagination,
        searchActive,
        requesting: true
      };
    }

    case CLUB_FEES_SEARCH_RESET:
      return {
        ...initialState,
        requesting: true
      };

    case CLUB_FEES_SET_INITIAL_STATE:
      return {
        ...initialState
      };

    case CLUB_FEE_EDIT_SUCCESS: {
      const {
        data,
      } = action;
      const page = state.pagination.page;

      const pagedFees = optimisticPagedObjectUpdate(state.pagedFees, page, { ...data });

      return {
        ...state,
        pagedFees
      };
    }

    default:
      return state;
  }
};