import {
  OFFERS_REQUESTING,
  OFFERS_SUCCESS,
  OFFERS_ERROR,
  OFFERS_CHANGE_PAGE,
  OFFERS_SEARCH_CHANGED,
  OFFERS_SEARCH_REQUESTING,
  OFFERS_SEARCH_RESET, OFFERS_SET_INITIAL_STATE,
} from './constants';
import { SHARED_DEFAULT_REDUCER_STATE } from '@src/assets/config';
import {
  formDefaultErrorState,
  formDefaultPagedState,
  formDefaultRequestingState, fromDefaultPageChangeState,
  optimisticPagedObjectUpdate,
  searchStateChangedReducerHelper
} from '@src/utils/storeUtils';
import { OFFER_EDIT_POST_SUCCESS, OFFER_EDIT_SUCCESS } from '@src/store/offer/constants';
import { AUTH_ACTIVE_STATE_CHANGE } from '@src/store/auth/constants';

const initialState: OffersState = {
  ...SHARED_DEFAULT_REDUCER_STATE,
  pagedOffers: {},
  search: {
    title: '',
    partner: undefined,
    starts: null,
    ends: null,
  }
};

const offersReducer = (state: OffersState = initialState, action: any): OffersState => {
  switch (action.type) {
    case OFFERS_REQUESTING:
      return formDefaultRequestingState(state);

    case OFFERS_SUCCESS:
      return formDefaultPagedState(state, action, 'pagedOffers');

    case OFFERS_CHANGE_PAGE:
      return fromDefaultPageChangeState(state, action);

    case OFFERS_ERROR:
      return formDefaultErrorState(state, action);

    case OFFERS_SEARCH_CHANGED:
      return searchStateChangedReducerHelper(state, action);

    case OFFERS_SEARCH_REQUESTING: {
      let searchActive = state.searchActive;
      let pagedOffers = state.pagedOffers;
      let pagination = state.pagination;

      if (!searchActive) {
        searchActive = true;
        pagedOffers = {};
        pagination.totalCount = 0;
      }

      pagination.page = action.page;

      return {
        ...state,
        pagedOffers,
        pagination,
        searchActive,
        requesting: true
      };
    }

    case OFFERS_SEARCH_RESET:
      return {
        ...initialState,
        requesting: true,
        targetIdParams: state.targetIdParams,
      };

    case OFFERS_SET_INITIAL_STATE:
      return {
        ...initialState
      };

    case OFFER_EDIT_POST_SUCCESS:
    case OFFER_EDIT_SUCCESS: {
      const {
        data,
      } = action;
      const page = state.pagination.page;

      const pagedOffers = optimisticPagedObjectUpdate(state.pagedOffers, page, { ...data });

      return {
        ...state,
        pagedOffers
      };
    }

    case AUTH_ACTIVE_STATE_CHANGE: {
      return initialState;
    }

    default:
      return state;
  }
};

export {
  offersReducer,
};