import ReportsCourseListScene from '@src/components/scenes/reports/ReportsCourseListScene';
import React, { Component } from 'react';

class AdminReportsCourseList extends Component {
  render() {
    return (
      <ReportsCourseListScene />
    );
  }
}

export {
  AdminReportsCourseList,
};