import { fork, put, all, takeEvery, select } from 'redux-saga/effects';
import {
  EDIT_HANDICAP_OVERRIDE_REQUESTING,
  HANDICAP_INFO_GET_REQUESTING,
  PERSON_ADD_REQUESTING,
  PERSON_DELETE_REQUESTING,
  PERSON_EDIT_REQUESTING, PERSON_GET_REQUESTING,
} from './constants';
import { APIRoute } from '@src/assets/config';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { USERS_SEARCH_RESET } from '@src/store/users/constants';
import {
  AddPersonAction,
  DeletePersonAction,
  EditPersonAction,
  GetPersonAction,
  getPersonHandicapInfo
} from '@src/store/person/actions';
import {
  fetchPersonHandicapInfoFlow
} from '@src/store/person/flows/fetch-person-handicap-info';
import { userAuthToken } from '@src/store/auth/selectors';
import { get } from 'lodash';
import { editPersonHandicapOverrideFlow } from '@src/store/person/flows/edit-person-handicap-override';

/*
  GET|POST|PUT|DELETE /admin/1/person/<id>
 */
const fetchUsersCRUDFlow = SagaFlowFactory.createCRUDFlow({
  apiRoute: APIRoute.PERSON,
  typePrefix: 'PERSON',
  resetType: USERS_SEARCH_RESET,
});

type Action = GetPersonAction | AddPersonAction | EditPersonAction | DeletePersonAction;

export function* personSpawnWatcher() {
  yield all([
    takeEvery([
      PERSON_GET_REQUESTING,
      PERSON_ADD_REQUESTING,
      PERSON_EDIT_REQUESTING,
      PERSON_DELETE_REQUESTING,
    ], function* ({ crudAction, params, ...rest }: Action) {
      const token = yield select(userAuthToken);
      const personId = get(rest, 'personId');

      yield fork(fetchUsersCRUDFlow, token, crudAction, personId, params);

      if (crudAction === 'GET' && personId) {
        yield put(getPersonHandicapInfo(personId));
      }
    }),
    takeEvery(HANDICAP_INFO_GET_REQUESTING, fetchPersonHandicapInfoFlow),
    takeEvery(EDIT_HANDICAP_OVERRIDE_REQUESTING, editPersonHandicapOverrideFlow),
  ]);
}