import React, { CSSProperties } from 'react';
import ClubCoursePartial, { ClubCoursePartialProps } from '@src/components/scenes/club/course/ClubCoursePartial';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';

interface OwnProps {
  className?: string;
  style?: CSSProperties;
  frontNinePartial?: ClubCourseSibling;
  backNinePartial?: ClubCourseSibling;
}

type Props = ClubCoursePartialProps & OwnProps;

function ClubCoursePartialsCard({
  className,
  style,
  frontNinePartial,
  backNinePartial,
  ...rest
}: Props) {
  return (
    <Card style={style} className={className}>
      <CardHeader title={<FormattedMessage id={'scenes.golfClub.course.children.title'} />} />
      <CardContent>
        <Grid container={true} spacing={4}>
          <Grid item={true} md={12} lg={6}>
            <ClubCoursePartial
              courseType={'FRONT'}
              partialCourseId={frontNinePartial?.idCourse}
              draftCourseId={frontNinePartial?.draftCourseId}
              {...rest}
            />
          </Grid>
          <Grid item={true} md={12} lg={6}>
            <ClubCoursePartial
              courseType={'BACK'}
              partialCourseId={backNinePartial?.idCourse}
              draftCourseId={backNinePartial?.draftCourseId}
              {...rest}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default ClubCoursePartialsCard;