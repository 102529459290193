import { call } from 'redux-saga/effects';
import { loginCheckApi } from '@src/store/login/api/login-check-api';
import { Path } from 'history';
import { unsetPersistedAuthToken, unsetPersistedRedirectRoute } from '@src/utils/authUtils';

export function* silentLoginCheckFlow (token: string, redirect?: (path: Path) => any) {
  try {
    yield call(loginCheckApi, token);

    return true;
  } catch (error) {
    if (error.toString() !== 'TypeError: Failed to fetch') {
      unsetPersistedAuthToken();
      unsetPersistedRedirectRoute();

      if (redirect) {
        redirect('/logout');
      }
    }

    return false;
  }
}