import {
  REPORTS_REQUESTING,
  REPORTS_CHANGE_PAGE
} from './constants';
import { DEFAULT_PAGINATION_LIMIT } from '@src/assets/config';

const fetchReports = ({
  page,
  limit = DEFAULT_PAGINATION_LIMIT,
  params
}: TablePaginationAction) => {
  return {
    type: REPORTS_REQUESTING,
    page,
    limit,
    params
  };
};

const changePage = ({
  page,
}: ChangePageAction) => {
  return {
    type: REPORTS_CHANGE_PAGE,
    page,
  };
};

export {
  fetchReports,
  changePage
};