export const CLUB_FEE_GET_REQUESTING = 'CLUB_FEE_GET_REQUESTING';
export const CLUB_FEE_GET_SUCCESS = 'CLUB_FEE_GET_SUCCESS';
export const CLUB_FEE_GET_ERROR = 'CLUB_FEE_GET_ERROR';

export const CLUB_FEE_ADD_REQUESTING = 'CLUB_FEE_ADD_REQUESTING';
export const CLUB_FEE_ADD_SUCCESS = 'CLUB_FEE_ADD_SUCCESS';
export const CLUB_FEE_ADD_ERROR = 'CLUB_FEE_ADD_ERROR';

export const CLUB_FEE_EDIT_REQUESTING = 'CLUB_FEE_EDIT_REQUESTING';
export const CLUB_FEE_EDIT_SUCCESS = 'CLUB_FEE_EDIT_SUCCESS';
export const CLUB_FEE_EDIT_ERROR = 'CLUB_FEE_EDIT_ERROR';

export const CLUB_FEE_DELETE_REQUESTING = 'CLUB_FEE_DELETE_REQUESTING';
export const CLUB_FEE_DELETE_SUCCESS = 'CLUB_FEE_DELETE_SUCCESS';
export const CLUB_FEE_DELETE_ERROR = 'CLUB_FEE_DELETE_ERROR';