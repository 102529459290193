import React, { createRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import { ModalContentOverlayLoader } from '@src/components/modals/ui';
import * as reportActions from '@src/store/report/actions';
import { ReportForm } from '@src/components/forms/ReportForm';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import { FormattedMessage } from 'react-intl';

interface Props {
  reportId: number | undefined;
  open: boolean;
  onClose: () => void;
  getReport: (params: Report2Get) => any;
  report: Report2State;
}

class ReportModalConnected extends React.Component<Props, {}> {
  actionsContainerRef = createRef<HTMLDivElement>();

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      reportId,
      getReport
    } = this.props;

    if (nextProps.reportId !== reportId) {
      if (nextProps.reportId) {
        getReport({
          id: nextProps.reportId,
        });
      }
    }
  }

  render() {
    const {
      open,
      onClose,
      reportId,
      report: {
        requesting,
      },
    } = this.props;

    return (
      <Dialog
        open={open}
        fullWidth={true}
      >
        <DialogTitle>
          {reportId && <FormattedMessage id={'scenes.reports.engine.modal.edit.title'} />}
          {!reportId && <FormattedMessage id={'scenes.reports.engine.modal.add.title'} />}
        </DialogTitle>
        <DialogContent>
          {requesting && <ModalContentOverlayLoader/>}
          <ReportForm
            reportId={reportId}
            parentContext={'MODAL'}
            onClose={onClose}
            actionsContainerRef={this.actionsContainerRef}
          />
        </DialogContent>

        <div ref={this.actionsContainerRef} />
      </Dialog>
    );
  }
}

const ReportModal = connect((state: any) => ({
  report: state.report2Reducer,
}), {
  getReport: reportActions.getReport
})(ReportModalConnected);

export {
  ReportModal,
};
