import React, { ChangeEvent, Component, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
} from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

interface Props {
  disabled?: boolean;
  initialValue: Array<TargetGender> | undefined;
  onChange: (values: Array<TargetGender> | undefined) => void;
  title?: ReactNode | string;
  groupProps?: Object;
}

class GenderSelect extends Component<Props, {}> {

  render() {
    const { initialValue, disabled, title, groupProps } = this.props;
    const gender = initialValue ? initialValue : [];

    return (
      <FormControl
        required={true}
        error={gender.length === 0}
        {...groupProps}
      >
        { title && (
          <FormLabel>
            {title}
          </FormLabel>
        )}

        <FormGroup row={true}>
          <FormControlLabel
            control={(
              <Checkbox
                color={'primary'}
                disabled={disabled}
                checked={gender.indexOf('MALE') !== -1}
                onChange={this._handleGenderChange}
                value="MALE"
              />
            )}
            label={(
              <FormattedMessage id={'scenes.offers.form.sections.targeting.gender.men'}/>
            )}
          />
          <FormControlLabel
            control={(
              <Checkbox
                color={'primary'}
                disabled={disabled}
                checked={gender.indexOf('FEMALE') !== -1}
                onChange={this._handleGenderChange}
                value="FEMALE"
              />
            )}
            label={(
              <FormattedMessage id={'scenes.offers.form.sections.targeting.gender.woman'}/>
            )}
          />
          <FormControlLabel
            control={(
              <Checkbox
                color={'primary'}
                disabled={disabled}
                checked={gender.indexOf('NONE') !== -1}
                onChange={this._handleGenderChange}
                value="NONE"
              />
            )}
            label={(
              <FormattedMessage id={'scenes.offers.form.sections.targeting.gender.none'}/>
            )}
          />
        </FormGroup>
      </FormControl>
    );
  }

  private _handleGenderChange = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    const { initialValue = [] } = this.props;
    const { value, checked } = currentTarget;

    let payload: TargetGender[] = [].concat(initialValue as any);

    if (checked) {
      payload.push(value as TargetGender);
    } else {
      payload = payload.filter((val) => (val !== value));
    }

    if (this.props.onChange) {
      this.props.onChange(payload);
    }
  }
}

export default GenderSelect;