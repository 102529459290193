/**
 * A maintenance comparison function for sort() with name ASC, id ASC ordering.
 *
 * @param a The first maintenance for comparison
 * @param b The second maintenance for comparison
 * @returns Number Negative when a < b, zero when a == b, positive when a > b
 */
export const maintenanceCompare = (a: Maintenance, b: Maintenance): number => {
  let c: number = !a.from ? !b.until ? 0 : -1 : b.from.toString().localeCompare(a.from.toString());

  if (c === 0) {
    c = b.id - a.id;
  }
  return c;
};