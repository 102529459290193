import React, { createRef, PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { ClubCourseHoleForm } from '@src/components/scenes/club/course/hole/ClubCourseHoleForm';
import { ModalContentOverlayLoader } from '@src/components/modals/ui';
import * as clubCourseHoleActions from '@src/store/clubCourseHole/actions';

interface Props {
  clubCourseId: number;
  holeId: number | undefined;
  open: boolean;
  onClose: (shouldRefetch?: boolean) => void;
  clubCourseHoleState: ClubCourseHoleState;
  getClubCourseHole: (params: ClubCourseHoleGet) => any;
}

class ClubCourseHoleModalConnected extends PureComponent<Props> {
  actionsContainerRef = createRef<HTMLDivElement>();

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      holeId,
      getClubCourseHole
    } = this.props;

    if (nextProps.holeId !== holeId) {
      if (nextProps.holeId) {
        getClubCourseHole({
          clubCourseId: nextProps.clubCourseId,
          holeId: nextProps.holeId,
        });
      }
    }
  }

  render() {
    const {
      open,
      onClose,
      clubCourseId,
      holeId,
      clubCourseHoleState: {
        requesting
      }
    } = this.props;

    return (
      <Dialog
        open={open}
        fullWidth={true}
      >
        <DialogTitle>
          {holeId && <FormattedMessage id={'scenes.golfClub.courses.holes.modal.edit.title'} />}
        </DialogTitle>
        <DialogContent>
          {requesting && <ModalContentOverlayLoader/>}
          <ClubCourseHoleForm
            clubCourseId={clubCourseId}
            holeId={holeId}
            parentContext={'MODAL'}
            onClose={onClose}
            actionsContainerRef={this.actionsContainerRef}
          />
        </DialogContent>

        <div ref={this.actionsContainerRef} />
      </Dialog>
    );
  }
}

const ClubCourseHoleModal = connect((state: any) => ({
  clubCourseHoleState: state.clubCourseHoleReducer,
}), {
  getClubCourseHole: clubCourseHoleActions.getClubCourseHole
})(ClubCourseHoleModalConnected);

export {
  ClubCourseHoleModal,
};