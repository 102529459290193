import { fork, take } from 'redux-saga/effects';
import { AUTH_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { FEDERATIONS_RESET } from '@src/store/federations/constants';
import { APIRoute } from '@src/assets/config';
import {
  FEDERATION_ADD_REQUESTING, FEDERATION_CONTACTS_GET_REQUESTING,
  FEDERATION_DELETE_REQUESTING,
  FEDERATION_EDIT_REQUESTING,
  FEDERATION_GET_REQUESTING,
} from './constants';
import { getFederationContactsFlow } from '@src/store/federation/flows/get-federation-contacts-flow';

/*
  GET|POST|PUT|DELETE /admin/1/federation/<id>
 */
const fetchCRUDFlow = SagaFlowFactory.createCRUDFlow({
  apiRoute: APIRoute.FEDERATION,
  typePrefix: 'FEDERATION',
  resetType: FEDERATIONS_RESET,
});

export default function * () {
  let token: string = '';

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
      federationId,
      params
    } = yield take([
      FEDERATION_GET_REQUESTING,
      FEDERATION_ADD_REQUESTING,
      FEDERATION_EDIT_REQUESTING,
      FEDERATION_DELETE_REQUESTING,
      FEDERATION_CONTACTS_GET_REQUESTING,
    ]);

    switch (type) {
      case FEDERATION_GET_REQUESTING:
        yield fork(fetchCRUDFlow, token, 'GET', federationId, params);
        break;
      case FEDERATION_ADD_REQUESTING:
        yield fork(fetchCRUDFlow, token, 'ADD', null, params);
        break;
      case FEDERATION_EDIT_REQUESTING:
        yield fork(fetchCRUDFlow, token, 'EDIT', federationId, params);
        break;
      case FEDERATION_DELETE_REQUESTING:
        yield fork(fetchCRUDFlow, token, 'DELETE', federationId, params);
        break;
      case FEDERATION_CONTACTS_GET_REQUESTING:
        yield fork(getFederationContactsFlow, token, federationId);
        break;
      default:
    }
  }
}