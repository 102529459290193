import {
  PERSONS_CHANGE_PAGE,
  PERSONS_REQUESTING,
  PERSONS_SEARCH_CHANGED,
  PERSONS_SEARCH_REQUESTING,
  PERSONS_SEARCH_RESET,
  PERSONS_SET_TARGET_ID_PARAMS,
} from './constants';
import { DEFAULT_PAGINATION_LIMIT } from '@src/assets/config';
import { createTargetIdParamsActionsWithType } from '@src/utils/storeUtils';

export const setTargetIdParams = createTargetIdParamsActionsWithType(PERSONS_SET_TARGET_ID_PARAMS);

export const fetchPersons = ({
  page,
  limit = DEFAULT_PAGINATION_LIMIT
}: TablePaginationAction) => {
  return {
    type: PERSONS_REQUESTING,
    page,
    limit
  };
};

export const changePage = ({
  page
}: ChangePageAction) => {
  return {
    type: PERSONS_CHANGE_PAGE,
    page,
  };
};

export const searchChanged = (search: PersonsSearch) => {
  return {
    type: PERSONS_SEARCH_CHANGED,
    search
  };
};

export const fetchSearch = ({
  page,
  limit = DEFAULT_PAGINATION_LIMIT,
  params
}: SearchPaginationAction) => {
  return {
    type: PERSONS_SEARCH_REQUESTING,
    page,
    limit,
    params,
  };
};

export const resetSearch = () => {
  return {
    type: PERSONS_SEARCH_RESET
  };
};