import { take, fork, select } from 'redux-saga/effects';
import {
  REPORTS_CONDENSED_SUCCESS,
  REPORTS_ERROR,
  REPORTS_REQUESTING,
  REPORTS_RESET,
  REPORTS_SUCCESS,
} from './constants';
import { API_ROOT, APIRoute, DEFAULT_PAGINATION_LIMIT, DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import { AUTH_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { LOGIN_SUCCESS } from '@src/store/login/constants';
import { userIsFederationAdmin } from '@src/store/auth/selectors';
import { REPORT_ADD_SUCCESS, REPORT_DELETE_SUCCESS } from '@src/store/report/constants';

/*
  GET /admin/1/report
 */
const fetchReportsFlow = SagaFlowFactory.createPagedGETFlow({
  apiRoute: APIRoute.GET_REPORTS,
  itemType: 'reports',
  successType: REPORTS_SUCCESS,
  errorType: REPORTS_ERROR
});

/*
  GET /admin/1/report?type=condensed
 */
const fetchReportsCondensed = SagaFlowFactory.createGETFlow({
  urlRoute: `${API_ROOT}${APIRoute.GET_REPORTS}`,
  successType: REPORTS_CONDENSED_SUCCESS,
  errorType: REPORTS_ERROR,
});

function* report2sWatcher() {
  let token: string = '';
  let isAdmin: boolean = false;

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
    isAdmin = yield select(userIsFederationAdmin);
  });

  while (true) {
    const {
      type,
      page,
      limit,
      params
    } = yield take([
      REPORTS_REQUESTING,
      LOGIN_SUCCESS,
      REPORTS_RESET,
      REPORT_ADD_SUCCESS,
      REPORT_DELETE_SUCCESS,
    ]);

    console.info('report2sWatcher', type);
    switch (type) {
      case LOGIN_SUCCESS:
      case REPORT_ADD_SUCCESS:
      case REPORT_DELETE_SUCCESS:
        if (isAdmin) {
          yield fork(fetchReportsCondensed, token, 0, 0, {type: 'condensed'});
        }
        break;
      case REPORTS_REQUESTING:
        yield fork(fetchReportsFlow, token, page, limit, { ...params });
        break;
      case REPORTS_RESET:
        yield fork(fetchReportsFlow, token, DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_LIMIT);
        break;
      default:
    }
  }
}

export default report2sWatcher;