import React, { PureComponent, ChangeEvent, RefObject } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import * as functionaryActions from '@src/store/functionary/actions';
import { renderFormActions } from '@src/components/modals/ui';
import {
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox, InputLabel, Select, MenuItem
} from '@material-ui/core';
import FunctionaryTitleSelect from '@src/components/forms/FunctionaryTitleSelect';
import { cleanDateIfItIsArray, transformStringNullToEmpty } from '@src/utils/storeUtils';
import { DeleteConfirmChildren } from '@src/components/headless/DeleteConfirm';
import DeleteConfirm from '@src/components/headless/DeleteConfirm';
import InputsValidator from '@src/components/forms/InputsValidator';
import AddressFields from '@src/components/forms/ui/AddressFields';
import memoizeOne from 'memoize-one';

interface OwnProps {
  functionaryId?: number | undefined;
  functionaryState: FunctionaryState;
  functionaryTitlesState: FunctionaryTitlesState;
  parentContext?: 'MODAL' | 'PAGE';
  onClose?: () => void;
  addFunctionary: (params: FunctionaryAdd) => any;
  editFunctionary: (params: FunctionaryEdit) => any;
  deleteFunctionary: (params: FunctionaryDelete) => any;
  actionsContainerRef?: RefObject<HTMLElement>;
  targetIdParams: TargetIdParams;
  personToSet?: Person;
}

type State = {
  personInfo: any;
  functionaryInfo: any;
  streetAddress: string;
  city: string;
  zip: string;
  phone: string;
  email: string;
  usePersonInfoAddressInfo: boolean;
  dateJoined: Date | null;
  dateResigned: Date | null;
  hasUserAccount: boolean;
  visibleOnFieldGuide: boolean;
  receivesClubLetter: boolean;
  functionaryTitles?: CondensedTitle | number | undefined;
  titlePrecision?: string;
  status?: ActivityStatus;
};

const initialState = {
  personInfo: {
    nameFirst: '',
    nameLast: '',
    addressInfo: {}
  },
  functionaryInfo: {
    addressInfo: {}
  },
  streetAddress: '',
  city: '',
  zip: '',
  phone: '',
  email: '',
  usePersonInfoAddressInfo: false,
  status: 'ACTIVE' as ActivityStatus,
  dateJoined: new Date(),
  dateResigned: null,
  hasUserAccount: false,
  visibleOnFieldGuide: false,
  receivesClubLetter: true,
  functionaryTitles: undefined,
  titlePrecision: '',
};

type Props = OwnProps & WrappedComponentProps;

type MemoizeFunctionaryTitleValueType = (titles: CondensedTitle[], selection: number) => CondensedTitle;

class FunctionaryFormConnected extends PureComponent<Props, State> {

  protected memoizeFunctionaryTitleValue =
      memoizeOne<MemoizeFunctionaryTitleValueType>((allTitles, functionaryTitle) =>
      allTitles.filter(allTitle => functionaryTitle === allTitle.id )[0]);

  constructor(props: Props) {
    super(props);

    const {
      functionaryId,
      functionaryState: {
        functionary,
      },
      personToSet,
    } = this.props;

    if (!functionaryId) {
      if (personToSet) {
        // Mask Person object as functionary's personInfo
        this.state = this._parseFunctionaryToState(this._parseStateValuesFromPersonToAdd(personToSet));
      } else {
        this.state = initialState;
      }
    } else if (functionary) {
      this.state = this._parseFunctionaryToState(functionary);
    } else {
      this.state = initialState;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      functionaryId,
      functionaryState: {
        functionary,
        requesting,
      },
      personToSet,
    } = nextProps;

    if (!functionaryId) {
      // todo: neeed refinement when settings saved
      if (personToSet && (requesting === nextProps.functionaryState.requesting)) {
        // Mask Person object as functionary's personInfo
        return this.setState({
          ...this._parseFunctionaryToState(this._parseStateValuesFromPersonToAdd(personToSet)),
        });
      }

      return;
    } else if (requesting) {
      return; // Do nothing state is loading
    } else if (functionary) {
      this.setState({
        ...this._parseFunctionaryToState(functionary),
      });
    }
  }

  render() {
    const {
      personInfo: {
        nameFirst,
        nameLast
      },
      city,
      zip,
      streetAddress,
      phone,
      email,
      usePersonInfoAddressInfo,
      receivesClubLetter,
      status,
      functionaryTitles,
      titlePrecision,
    } = this.state;

    const {
      functionaryId,
      parentContext,
      actionsContainerRef,
      functionaryTitlesState
    } = this.props;

    const functionaryValue =
        this.memoizeFunctionaryTitleValue(functionaryTitlesState.allTitles, functionaryTitles as number ?? []);

    return (
      <InputsValidator values={{ nameFirst, nameLast, functionaryTitles, email }}>
        {({ errorInputs, validateThenApply, validateValues }) => (
          <>
          {(this.props.functionaryId &&
            <FormControl required={true} fullWidth={true} margin="normal" error={errorInputs.state}>
              <InputLabel htmlFor="state">
                <FormattedMessage id={'strings.state'}/>
              </InputLabel>
              <Select
                inputProps={{
                  name: 'state',
                  id: 'state',
                }}
                value={status}
                onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                  let oldStatus = this.state.status;
                  let newStatus = event.target.value as ActivityStatus;
                  let dateResigned = this.state.dateResigned;

                  if (oldStatus === 'ACTIVE' && newStatus === 'INACTIVE') {
                    dateResigned = new Date();
                  } else if (oldStatus === 'INACTIVE' && newStatus === 'ACTIVE') {
                    dateResigned = null;
                  }
                  this.setState({
                    status: newStatus,
                    dateResigned: dateResigned
                  }, validateValues);
                }}
              >
                <MenuItem value={'ACTIVE'}><FormattedMessage id={'strings.statusActive'}/></MenuItem>
                <MenuItem value={'INACTIVE'}><FormattedMessage id={'strings.statusInactive'}/></MenuItem>
              </Select>
            </FormControl>)}

            <TextField
              fullWidth={true}
              required={true}
              error={errorInputs.nameFirst}
              id="nameFirst"
              label={<FormattedMessage id={'strings.firstName'}/>}
              value={nameFirst}
              margin="normal"
              disabled={this._nameFieldsDisabled()}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const { currentTarget: { value } } = e;
                this.setState({
                  personInfo: {
                    ...this.state.personInfo,
                    nameFirst: value,
                  },
                }, validateValues);
              }}
            />
            <TextField
              fullWidth={true}
              required={true}
              error={errorInputs.nameLast}
              id="nameLast"
              label={<FormattedMessage id={'strings.lastName'}/>}
              value={nameLast}
              margin="normal"
              disabled={this._nameFieldsDisabled()}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const { currentTarget: { value } } = e;
                this.setState({
                  personInfo: {
                    ...this.state.personInfo,
                    nameLast: value,
                  },
                }, validateValues);
              }}
            />

            <FormControl
              fullWidth={true}
              error={errorInputs.functionaryTitleId}
            >
              <FunctionaryTitleSelect
                error={errorInputs.functionaryTitleId}
                required={true}
                margin={'normal'}
                selectedTitles={functionaryValue}
                onChangeCallback={(value: any = { id: undefined }) => {
                  const { id } = value;
                  this.setState({ functionaryTitles: id }, validateValues);
                }}
              />
            </FormControl>

            <TextField
                fullWidth={true}
                id="titlePrecision"
                label={<FormattedMessage id={'strings.titlePrecision'}/>}
                value={titlePrecision}
                margin="normal"
                onChange={this._handleFieldChange}
            />

            <FormControlLabel
                control={
                  <Checkbox
                      checked={usePersonInfoAddressInfo || false}
                      onChange={this._handleCheckboxChange('usePersonInfoAddressInfo')}
                      value="usePersonInfoAddressInfo"
                  />
                }
                label={<FormattedMessage id={'strings.usePersonInfoAddressInfo'} />}
            />

            <AddressFields
              streetAddress={streetAddress || ''}
              city={city || ''}
              zip={zip || ''}
              disabledInputs={{
                streetAddress: this._addressInfoReadOnly(),
                city: this._addressInfoReadOnly(),
                zip: this._addressInfoReadOnly()
              }}
              onChange={(args) => this.setState({ ...args } as any)}
            />

            <TextField
              fullWidth={true}
              id="phone"
              label={<FormattedMessage id={'strings.phone'}/>}
              value={phone}
              margin="normal"
              type={'tel'}
              disabled={this._addressInfoReadOnly()}
              onChange={this._handleFieldChange}
            />
            <TextField
              required={true}
              fullWidth={true}
              id="email"
              label={<FormattedMessage id={'strings.email'}/>}
              type={'email'}
              value={email}
              margin="normal"
              disabled={this._addressInfoReadOnly()}
              onChange={this._handleFieldChange}
              error={errorInputs.email}
            />

            <FormControlLabel
                control={
                  <Checkbox
                      checked={receivesClubLetter}
                      onChange={this._handleCheckboxChange('receivesClubLetter')}
                      value="receivesClubLetter"
                  />
                }
                label={<FormattedMessage id={'strings.receivesClubLetter'} />}
            />

            <DeleteConfirm>
              {({ showConfirm }: DeleteConfirmChildren) => (
                renderFormActions({
                  id: functionaryId,
                  parentContext,
                  onClose: this._handleOnClose,
                  onUpdate: validateThenApply.bind(this, this._handleUpdate),
                  onCreate: validateThenApply.bind(this, this._handleCreate),
                  disabled: false,
                  containerRef: actionsContainerRef,
                  onDelete: () => {
                    showConfirm({
                      callback: this._handleDelete,
                    });
                  },
                })
              )}
            </DeleteConfirm>
          </>
        )}
      </InputsValidator>
    );
  }

  private _parseStateValuesFromPersonToAdd = (person: Person): any => {
    const {
      email,
      streetAddress,
      phone,
    } = person;

    return {
      personInfo: person,
      email,
      streetAddress,
      phone,
    };
  }

  private _handleCheckboxChange = name => event => {
    let personAddress = this.state.personInfo.addressInfo;
    let functionaryAddress = this.state.functionaryInfo.addressInfo;

    if (name === 'usePersonInfoAddressInfo' && personAddress && event.target.checked) {
      this.setState({
        [name]: event.target.checked,
        streetAddress: personAddress.streetAddress ? personAddress.streetAddress : '',
        city: personAddress.city ? personAddress.city : '',
        zip: personAddress.zip ? personAddress.zip : '',
        email: personAddress.email ? personAddress.email : '',
        phone: personAddress.phone ? personAddress.phone : ''
      } as any);
    } else if (name === 'usePersonInfoAddressInfo' && functionaryAddress && !event.target.checked) {
      this.setState({
        [name]: event.target.checked,
        streetAddress: functionaryAddress.streetAddress ? functionaryAddress.streetAddress : '',
        city: functionaryAddress.city ? functionaryAddress.city : '',
        zip: functionaryAddress.zip ? functionaryAddress.zip : '',
        email: functionaryAddress.email ? functionaryAddress.email : '',
        phone: functionaryAddress.phone ? functionaryAddress.phone : ''
      } as any);
    } else {
      this.setState({
        [name]: event.target.checked
      } as any);
    }
  }

  private _addressInfoReadOnly = (): boolean => {
    if (this.state.usePersonInfoAddressInfo && this.props.personToSet && this.props.personToSet.id) {
      return true;
    }
    if (this.state.usePersonInfoAddressInfo && this.props.functionaryId) {
      return true;
    }
    return false;
  }

  private _handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: {
        id,
        value,
      }
    } = e;
    this.setState({ [id as any]: value } as any);
  }

  private _handleOnClose = (): void => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  }

  private _handleDelete = (bool: boolean) => {
    const { deleteFunctionary, functionaryId } = this.props;
    if (bool && functionaryId) {
      deleteFunctionary({
        id: functionaryId,
        onComplete: this._handleOnClose,
      });
    }
  }

  private _handleCreate = (): any => {
    const { addFunctionary, personToSet } = this.props;
    let payload = {
      ...this._formParams(),
      onComplete: this._handleOnComplete,
    };

    if (personToSet) {
      payload = {
        ...payload,
        personId: personToSet.id,
      };
    }

    addFunctionary({
      ...payload,
    } as FunctionaryAdd);
  }

  private _handleUpdate = () => {
    const { editFunctionary } = this.props;
    const {
      functionaryState: {
        functionary
      }
    } = this.props;

    if (!functionary) {
      return;
    }

    const params = this._formParams();

    editFunctionary({
      id: functionary.id,
      ...params,
      onComplete: this._handleOnComplete,
    } as FunctionaryEdit);
  }

  private _formParams = () => {
    const {
      functionaryId,
      functionaryState: {
        functionary,
      },
      targetIdParams,
    } = this.props;

    const {
      dateJoined = new Date(),
      dateResigned,
      hasUserAccount,
      visibleOnFieldGuide,
      receivesClubLetter,
      functionaryTitles,
      titlePrecision,
      status,
      streetAddress,
      city,
      zip,
      phone,
      email,
      usePersonInfoAddressInfo,
      personInfo: {
        nameFirst,
        nameLast,
      }
    } = this.state;

    return {
      streetAddress,
      city,
      zip,
      phone,
      email,
      usePersonInfoAddressInfo,
      dateJoined,
      dateResigned,
      hasUserAccount,
      visibleOnFieldGuide,
      receivesClubLetter,
      functionaryTitleId: functionaryTitles,
      titlePrecision,
      status,
      personId: (functionaryId && functionary && functionary.personInfo) ? functionary.personInfo.id : undefined,
      nameFirst,
      nameLast,
      ...targetIdParams,
    };
  }

  private _handleOnComplete = (result: any): void => {
    if (result.error) {
      window.alert(result.error.message);
    } else {
      this._handleOnClose();
    }
  }

  private _parseFunctionaryToState = (functionary: Functionary): State => {
    const {
      personInfo,
      streetAddress,
      phone,
      email,
      usePersonInfoAddressInfo,
      dateJoined,
      dateResigned,
      hasUserAccount = false,
      visibleGuides = false,
      receivesClubLetter = true,
      functionaryTitle = {} as any,
      titlePrecision,
      status = 'ACTIVE' as ActivityStatus,
      city,
      zip,
    } = functionary;

    const {
      nameFirst,
      nameLast,
    } = personInfo;
    const addressInfo = functionary.personInfo;
    const { idTitle } = functionaryTitle;

    return {
      personInfo: {
        nameFirst: transformStringNullToEmpty(nameFirst),
        nameLast: transformStringNullToEmpty(nameLast),
        addressInfo: {
          streetAddress: addressInfo.streetAddress,
          city: addressInfo.city,
          zip: addressInfo.zip,
          email: addressInfo.email,
          phone: addressInfo.phone
        }
      },
      functionaryInfo: {
        addressInfo: functionary
      },
      streetAddress: transformStringNullToEmpty(streetAddress),
      city: transformStringNullToEmpty(city),
      zip: transformStringNullToEmpty(zip),
      phone: transformStringNullToEmpty(phone),
      email: transformStringNullToEmpty(email || personInfo.email),
      usePersonInfoAddressInfo: usePersonInfoAddressInfo,
      dateJoined: cleanDateIfItIsArray(dateJoined) ? new Date(dateJoined) : new Date(),
      dateResigned: cleanDateIfItIsArray(dateResigned) ? new Date(dateResigned) : null,
      hasUserAccount,
      visibleOnFieldGuide: visibleGuides,
      receivesClubLetter: receivesClubLetter,
      functionaryTitles: idTitle,
      titlePrecision: transformStringNullToEmpty(titlePrecision),
      status,
    };
  }

  private _nameFieldsDisabled = (): boolean => {
    const { functionaryId, personToSet } = this.props;
    return !(!functionaryId && !personToSet);
  }
}

const FunctionaryForm = injectIntl(connect((state: any) => ({
  functionaryState: state.functionaryReducer,
  functionaryTitlesState: state.functionaryTitlesReducer
}), {
  addFunctionary: functionaryActions.addFunctionary,
  editFunctionary: functionaryActions.editFunctionary,
  deleteFunctionary: functionaryActions.deleteFunctionary,
})(FunctionaryFormConnected));

export {
  FunctionaryForm
};