import React, { createRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import { ModalContentOverlayLoader } from '@src/components/modals/ui';
import * as offerActions from '@src/store/offer/actions';
import OfferForm from '@src/components/forms/ui/OfferForm';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import { FormattedMessage } from 'react-intl';

interface Props {
  offerId: number | undefined;
  open: boolean;
  onClose: () => void;
  getOffer: (params: OfferGet) => any;
  offer: OfferState;
}

class OfferModalConnected extends React.Component<Props, {}> {
  actionsContainerRef = createRef<HTMLDivElement>();

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      offerId,
      getOffer
    } = this.props;

    if (nextProps.offerId !== offerId) {
      if (nextProps.offerId) {
        getOffer({
          id: nextProps.offerId,
        });
      }
    }
  }

  render() {
    const {
      open,
      onClose,
      offerId,
      offer: {
        requesting
      },
    } = this.props;

    return (
      <Dialog
        open={open}
        fullWidth={true}
      >
        <DialogTitle>
          {offerId && <FormattedMessage id={'scenes.offers.modal.edit.title'} />}
          {!offerId && <FormattedMessage id={'scenes.offers.modal.add.title'} />}
        </DialogTitle>
        <DialogContent>
          {requesting && <ModalContentOverlayLoader/>}
          <OfferForm
            offerId={offerId}
            parentContext={'MODAL'}
            onClose={onClose}
            actionsContainerRef={this.actionsContainerRef}
          />
        </DialogContent>

        <div ref={this.actionsContainerRef} />
      </Dialog>
    );
  }
}

const OfferModal = connect((state: any) => ({
  offer: state.offerReducer,
}), {
  getOffer: offerActions.getOffer
})(OfferModalConnected);

export {
  OfferModal,
};
