export const CLUB_REQUESTING = 'CLUB_REQUESTING';
export const CLUB_SUCCESS = 'CLUB_SUCCESS';
export const CLUB_ERROR = 'CLUB_ERROR';

export const CLUB_COURSE_GUIDE_REQUESTING = 'CLUB_COURSE_GUIDE_REQUESTING';
export const CLUB_COURSE_GUIDE_SUCCESS = 'CLUB_COURSE_GUIDE_SUCCESS';
export const CLUB_COURSE_GUIDE_ERROR = 'CLUB_COURSE_GUIDE_ERROR';

export const CLUB_FUNCTIONARIES_REQUESTING = 'CLUB_FUNCTIONARIES_REQUESTING';
export const CLUB_FUNCTIONARIES_SUCCESS = 'CLUB_FUNCTIONARIES_SUCCESS';
export const CLUB_FUNCTIONARIES_ERROR = 'CLUB_FUNCTIONARIES_ERROR';

export const CLUB_ADD_REQUESTING = 'CLUB_ADD_REQUESTING';
export const CLUB_RESET = 'CLUB_RESET';
export const CLUB_MEMBERS_REQUESTING = 'CLUB_MEMBERS_REQUESTING';
export const CLUB_MEMBERS_PAGE_CHANGE = 'CLUB_MEMBERS_PAGE_CHANGE';
export const CLUB_MEMBERS_SUCCESS = 'CLUB_MEMBERS_SUCCESS';
export const CLUB_MEMBERS_ERROR = 'CLUB_MEMBERS_ERROR';

export const CLUB_MEMBERS_SEARCH_REQUEST = 'CLUB_MEMBERS_SEARCH_REQUEST';
export const CLUB_MEMBERS_SEARCH_CHANGED = 'CLUB_MEMBERS_SEARCH_CHANGED';
export const CLUB_MEMBERS_SEARCH_RESET = 'CLUB_MEMBERS_SEARCH_RESET';

export const CLUB_ACTIVATION_REQUEST = 'CLUB_ACTIVATION_REQUEST';
export const CLUB_ACTIVATION_SUCCESS = 'CLUB_ACTIVATION_SUCCESS';

export const CLUB_VALIDATION_REQUEST = 'CLUB_VALIDATION_REQUEST';
export const CLUB_VALIDATION_SUCCESS = 'CLUB_VALIDATION_SUCCESS';

export const CLUB_MEMBER_ACTIVATION_REQUEST = 'CLUB_MEMBER_ACTIVATION_REQUEST';
export const CLUB_MEMBER_ACTIVATION_SUCCESS = 'CLUB_MEMBER_ACTIVATION_SUCCESS';
export const CLUB_MEMBER_ACTIVATION_ERROR = 'CLUB_MEMBER_ACTIVATION_ERROR';

export const CLUB_MEMBERS_SET_INITIAL_STATE = 'CLUB_MEMBERS_SET_INITIAL_STATE';

export const CLUB_UPDATE_ACTIVATE_IF_BILLS_PAID_REQUESTING = 'CLUB_UPDATE_ACTIVATE_IF_BILLS_PAID_REQUESTING';
export const CLUB_UPDATE_ACTIVATE_IF_BILLS_PAID_SUCCESS = 'CLUB_UPDATE_ACTIVATE_IF_BILLS_PAID_SUCCESS';
export const CLUB_UPDATE_ACTIVATE_IF_BILLS_PAID_ERROR = 'CLUB_UPDATE_ACTIVATE_IF_BILLS_PAID_ERROR';

export const CLUB_MEMBERS_EXCEL_REQUESTING = 'CLUB_MEMBERS_EXCEL_REQUESTING';
export const CLUB_MEMBERS_EXCEL_SUCCESS = 'CLUB_MEMBERS_EXCEL_SUCCESS';
export const CLUB_MEMBERS_EXCEL_ERROR = 'CLUB_MEMBERS_EXCEL_ERROR';

export const ADMIN_CLUB_MEMBERS_REQUESTING = 'ADMIN_CLUB_MEMBERS_REQUESTING';
export const ADMIN_CLUB_MEMBERS_SEARCH_REQUEST = 'ADMIN_CLUB_MEMBERS_SEARCH_REQUEST';
export const ADMIN_CLUB_MEMBERS_SEARCH_RESET = 'ADMIN_CLUB_MEMBERS_SEARCH_RESET';

export const CLUB_BASIC_INFO_EDIT_REQUESTING = 'CLUB_BASIC_INFO_EDIT_REQUESTING';
export const CLUB_BASIC_INFO_EDIT_SUCCESS = 'CLUB_BASIC_INFO_EDIT_SUCCESS';
export const CLUB_BASIC_INFO_EDIT_ERROR = 'CLUB_BASIC_INFO_EDIT_ERROR';

export const CLUB_EXTRA_INFO_EDIT_REQUESTING = 'CLUB_EXTRA_INFO_EDIT_REQUESTING';
export const CLUB_EXTRA_INFO_EDIT_SUCCESS = 'CLUB_EXTRA_INFO_EDIT_SUCCESS';
export const CLUB_EXTRA_INFO_EDIT_ERROR = 'CLUB_EXTRA_INFO_EDIT_ERROR';

export const CLUB_SET_TARGET_ID_PARAMS = 'CLUB_SET_TARGET_ID_PARAMS';

export const UPLOAD_HIO_EXCEL_REQUESTING = 'UPLOAD_HIO_EXCEL_REQUESTING';
export const UPLOAD_HIO_EXCEL_SUCCESS = 'UPLOAD_HIO_EXCEL_SUCCESS';
export const UPLOAD_HIO_EXCEL_ERROR = 'UPLOAD_HIO_EXCEL_ERROR';

export const SET_HIO_INSURANCE_REQUESTING = 'SET_HIO_INSURANCE_REQUESTING';
export const SET_HIO_INSURANCE_SUCCESS = 'SET_HIO_INSURANCE_SUCCESS';
export const SET_HIO_INSURANCE_ERROR = 'SET_HIO_INSURANCE_ERROR';
