import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import * as membershipActions from '@src/store/membership/actions';
import { transformStringNullToEmpty, updateOptionValues } from '@src/utils/storeUtils';
import { Checkbox, Dialog, DialogTitle, FormControlLabel, FormGroup } from '@material-ui/core';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { ModalContentOverlayLoader, renderFormActions } from '@src/components/modals/ui';
import InputsValidator from '@src/components/forms/InputsValidator';
import StateSelect from '@src/components/forms/StateSelect';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';

interface Props extends WrappedComponentProps {
  membershipState: MembershipState;
  onClose?: () => void;
  open: boolean;
  editMembership: (params: MembershipEdit) => any;
}

type State = {
  isLoading: boolean;
  memberStatus: ActivityStatus;
  clubId: number | string;
  memberNo: number;
  isHomeClub: boolean;
  dateJoined: Date | string | null;
  dateResigned: Date | string | null;
  memberType: string;
  magazineSubscriber: boolean;
  magazineFlags: string[];
  isBillsPaid: boolean;
  isShareholder: boolean;
  otherInfo: string;
  vendorUpdated: string;
  timeUpdated: string;
  streetAddress: string;
  city: string;
  zip: string;
  phone: string;
  email: string;
};

const initialState: State = {
  isLoading: false,
  memberStatus: 'ACTIVE',
  clubId: '',
  memberNo: 0,
  isHomeClub: false,
  dateJoined: null,
  dateResigned: null,
  memberType: 'PERMANENT',
  magazineSubscriber: false,
  magazineFlags: [],
  isBillsPaid: false,
  isShareholder: false,
  otherInfo: '',
  vendorUpdated: '',
  timeUpdated: '',
  streetAddress: '',
  city: '',
  zip: '',
  phone: '',
  email: ''
};

class MembershipInfoModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const {
      membershipState: {
        membership,
      },
    } = this.props;

    if (membership) {
      this.state = {
        isLoading: false,
        ...this._parseAttributes(membership),
      } as any;
    } else {
      this.state = initialState;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      membershipState: {
        requesting,
        membership,
      },
      open,
    } = nextProps;

    if (requesting || open) {
      return; // Do nothing state is loading
    } else if (membership) {
      this.setState(this._parseAttributes(membership));
    }
  }

  render(): React.ReactNode {
    const {
      open,
      membershipState: {
        membership,
      }
    } = this.props;
    const {
      isLoading,
      memberStatus,
      memberNo,
      isHomeClub,
      dateJoined,
      dateResigned,
      memberType,
      magazineSubscriber,
      magazineFlags,
      isBillsPaid,
      otherInfo,
      vendorUpdated,
      timeUpdated,
      streetAddress,
      city,
      zip,
      phone,
      email
    } = this.state;

    return (
      <Dialog open={open}>
        <DialogTitle>
          <FormattedMessage id={'navigation.golfClubInfo'} />
        </DialogTitle>

          <InputsValidator values={{ memberStatus }}>
            {({ errorInputs, validateThenApply, validateValues }) => (
              <>
                <DialogContent>
                  {isLoading && <ModalContentOverlayLoader/>}

                  <StateSelect
                    error={errorInputs.memberStatus}
                    selectedState={memberStatus}
                    disabled={true}
                    onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                      this.setState({ memberStatus: event.target.value as ActivityStatus }, validateValues);
                    }}
                    formControlProps={{
                      required: true,
                      margin: 'normal',
                      fullWidth: true,
                    }}
                  />

                  {Boolean(membership) && (
                    <TextField
                      margin={'normal'}
                      fullWidth={true}
                      label={<FormattedMessage id={'strings.club'} />}
                      value={membership!.club.name}
                      disabled={true}
                    />
                  )}

                  <TextField
                    margin={'normal'}
                    fullWidth={true}
                    label={<FormattedMessage id={'strings.memberNo'} />}
                    value={memberNo}
                    disabled={true}
                  />

                  <TextField
                    margin={'normal'}
                    fullWidth={true}
                    label={<FormattedMessage id={'strings.streetAddress'} />}
                    value={streetAddress}
                    disabled={true}
                  />

                  <TextField
                    margin={'normal'}
                    fullWidth={true}
                    label={<FormattedMessage id={'strings.postalCode'} />}
                    value={zip}
                    disabled={true}
                  />

                  <TextField
                    margin={'normal'}
                    fullWidth={true}
                    label={<FormattedMessage id={'strings.city'} />}
                    value={city}
                    disabled={true}
                  />

                  <TextField
                    margin={'normal'}
                    fullWidth={true}
                    label={<FormattedMessage id={'strings.phone'} />}
                    value={phone}
                    disabled={true}
                  />

                  <TextField
                    margin={'normal'}
                    fullWidth={true}
                    label={<FormattedMessage id={'strings.email'} />}
                    value={email}
                    disabled={true}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isHomeClub}
                        disabled={true}
                        onChange={this._handleCheckboxChange('isHomeClub')}
                        value="isHomeClub"
                      />
                    }
                    label={<FormattedMessage id={'strings.homeClub'} />}
                  />

                  <TextField
                    margin={'normal'}
                    fullWidth={true}
                    disabled={true}
                    label={<FormattedMessage id={'strings.dateJoined'} />}
                    defaultValue={this._formatDateString(dateJoined)}
                  />

                  <TextField
                    margin={'normal'}
                    fullWidth={true}
                    disabled={true}
                    label={<FormattedMessage id={'strings.dateResigned'} />}
                    defaultValue={this._formatDateString(dateResigned)}
                  />

                  {/*
                  <FormControl margin={'normal'} fullWidth={true}>
                    <FormLabel>
                      <FormattedMessage id={'strings.dateJoined'} />
                    </FormLabel>
                    <LocalizedDatePicker
                      margin={'normal'}
                      value={dateJoined}
                      onChange={(newDate: Date) => {
                        this.setState({
                          dateJoined: newDate ? new Date(newDate) : '',
                        } as any);
                      }}
                      clearable={true}
                    />
                  </FormControl>

                  <FormControl margin={'normal'} fullWidth={true}>
                    <FormLabel>
                      <FormattedMessage id={'strings.dateResigned'} />
                    </FormLabel>
                    <LocalizedDatePicker
                      margin={'normal'}
                      value={dateResigned}
                      onChange={(newDate: Date) => {
                        this.setState({
                          dateResigned: newDate ? new Date(newDate) : null,
                        } as any);
                      }}
                      clearable={true}
                    />
                  </FormControl>
                  */}

                  <TextField
                    margin={'normal'}
                    fullWidth={true}
                    label={<FormattedMessage id={'strings.memberType'} />}
                    value={memberType}
                    disabled={true}
                  />

                  <InputLabel style={{ marginTop: '16px', display: 'block' }}>
                    <FormattedMessage id={'strings.preferences'}/>
                  </InputLabel>
                  <FormGroup row={true}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={true}
                          checked={magazineSubscriber}
                          onChange={this._handleCheckboxChange('magazineSubscriber')}
                          value="magazineSubscriber"
                        />
                      }
                      label={<FormattedMessage id={'userPreferences.magazineSubscription'}/>}
                    />
                    {/*
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isHomeClub}
                          onChange={this._handleCheckboxChange('magazineFlags')}
                          value="isHomeClub"
                        />
                      }
                      label={<FormattedMessage id={'strings.homeClub'} />}
                    />
                    */}
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={true}
                          checked={isBillsPaid}
                          onChange={this._handleCheckboxChange('isBillsPaid')}
                          value="isBillsPaid"
                        />
                      }
                      label={<FormattedMessage id={'userPreferences.isBillsPaid'}/>}
                    />
                    {/*
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={true}
                          checked={isShareholder}
                          onChange={this._handleCheckboxChange('isShareholder')}
                          value="isShareholder"
                        />
                      }
                      label={<FormattedMessage id={'userPreferences.isShareholder'}/>}
                    />
                    */}
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={true}
                          checked={this._checkCheckboxState(magazineFlags, 'PAPER_COPY')}
                          onChange={this._handleMagazineOptionsChange('PAPER_COPY')}
                        />
                      }
                      label={<FormattedMessage id={'userPreferences.magazineSubscriptionPaperCopy'}/>}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={true}
                          checked={this._checkCheckboxState(magazineFlags, 'DIGITAL_COPY')}
                          onChange={this._handleMagazineOptionsChange('DIGITAL_COPY')}
                        />
                      }
                      label={<FormattedMessage id={'userPreferences.magazineSubscriptionDigitalCopy'}/>}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={true}
                          checked={this._checkCheckboxState(magazineFlags, 'DUPLICATE')}
                          onChange={this._handleMagazineOptionsChange('DUPLICATE')}
                        />
                      }
                      label={<FormattedMessage id={'userPreferences.magazineSubscriptionDouble'}/>}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={true}
                          checked={this._checkCheckboxState(magazineFlags, 'FREE')}
                          onChange={this._handleMagazineOptionsChange('FREE')}
                        />
                      }
                      label={<FormattedMessage id={'userPreferences.magazineSubscriptionFree'}/>}
                    />
                  </FormGroup>

                  <TextField
                    margin={'normal'}
                    multiline={true}
                    fullWidth={true}
                    label={<FormattedMessage id={'strings.other'} />}
                    value={otherInfo}
                    disabled={true}
                    onChange={this._handleChange('otherInfo')}
                  />

                  <TextField
                    margin={'normal'}
                    fullWidth={true}
                    disabled={true}
                    label={<FormattedMessage id={'strings.vendorLastUpdate'} />}
                    defaultValue={this._formatDateString(vendorUpdated)}
                  />

                  <TextField
                    margin={'normal'}
                    fullWidth={true}
                    disabled={true}
                    label={<FormattedMessage id={'strings.latestUpdate'} />}
                    defaultValue={this._formatDateString(timeUpdated)}
                  />

                  {/*
                  <FormControl margin={'normal'} fullWidth={true}>
                    <FormLabel>
                      <FormattedMessage id={'strings.vendorLastUpdate'} />
                    </FormLabel>
                    <LocalizedDatePicker
                      margin={'normal'}
                      value={vendorUpdated}
                      onChange={() => { return void(0); }}
                    />
                  </FormControl>

                  <FormControl margin={'normal'} fullWidth={true} disabled={true}>
                    <FormLabel>
                      <FormattedMessage id={'strings.latestUpdate'} />
                    </FormLabel>
                    <LocalizedDatePicker

                      disableOpenOnEnter={true}
                      margin={'normal'}
                      value={timeUpdated}
                      onChange={() => { return void(0); }}
                    />
                  </FormControl>
                  */}

                </DialogContent>

                {renderFormActions({
                  id: 1,
                  parentContext: 'MODAL',
                  onClose: this._handleOnClose,
                  // onUpdate: validateThenApply.bind(this, this._handleUpdate),
                  disabled: false,
                })}
              </>
            )}
          </InputsValidator>
      </Dialog>
    );
  }

  private _parseAttributes = (membership: Membership) => {
    const {
      memberStatus,
      club,
      memberNo,
      isHomeClub,
      dateJoined,
      dateResigned,
      memberType,
      magazineSubscriber,
      magazineFlags,
      isBillsPaid,
      isShareholder,
      otherInfo,
      vendorUpdated,
      timeUpdated,
      streetAddress,
      city,
      zip,
      phone,
      email
    } = membership;

    return {
      memberStatus,
      clubId: club ? club.id : '',
      memberNo,
      isHomeClub,
      dateJoined: transformStringNullToEmpty(dateJoined),
      dateResigned: transformStringNullToEmpty(dateResigned),
      memberType,
      magazineSubscriber,
      magazineFlags: (magazineFlags !== null && magazineFlags) ? magazineFlags : [],
      isBillsPaid,
      isShareholder,
      otherInfo,
      vendorUpdated,
      timeUpdated,
      streetAddress,
      city,
      zip,
      phone,
      email
    };
  }

  private _handleOnClose = () => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  }

  /*
  private _handleUpdate = () => {
    const {
      editMembership,
      membershipState: {
        membership,
      }
    } = this.props;

    if (membership) {
      const {
        personInfo: {
          idPerson,
        },
        club: {
          id,
        },
      } = membership;

      this.setState({ isLoading: true }, () => {
        const {
          memberStatus,
          memberNo,
          isHomeClub,
          dateJoined,
          dateResigned,
          memberType,
          magazineSubscriber,
          magazineFlags,
          isBillsPaid,
          isShareholder,
          otherInfo,
        } = this.state;

        editMembership({
          personId: idPerson,
          clubId: id,
          params: {
            memberStatus,
            memberNo,
            isHomeClub,
            dateJoined,
            dateResigned,
            memberType,
            magazineSubscriber,
            magazineFlags,
            isBillsPaid,
            isShareholder,
            otherInfo,
          },
          onComplete: this._handleAfterUpdate,
        });
      });
    }
  }

  private _handleAfterUpdate = ({ error }) => {
    this.setState({ isLoading: false }, () => {
      if (error) {
        return window.alert(error);
      }

      this._handleOnClose();
    });
  }
  */

  private _handleChange = (name: string, fn?: () => any) => (
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      this.setState({
        [name as any]: event.target.value,
      } as any, fn);
    }
  )

  private _handleCheckboxChange = (name: string, fn?: () => any) => (
    (event: ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [name as any]: event.target.checked,
      } as any, fn);
    }
  )

  private _checkCheckboxState = (group: Array<string>, value: string): boolean => {
    return group && group.indexOf(value) !== -1;
  }

  private _handleMagazineOptionsChange = name => event => {
    const { magazineFlags } = this.state;
    this.setState({ magazineFlags: updateOptionValues([...magazineFlags], name, event.target.checked )});
  }

  private _formatDateString = (str?: Date | string | null) => {
    if (str === null || !str) {
      return '';
    }

    return this.props.intl.formatDate(str);
  }
}

export default injectIntl(connect((state: StoreState) => ({
  membershipState: state.membershipReducer,
}), {
  editMembership: membershipActions.editMembership
})(MembershipInfoModal));
