import { Component } from 'react';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { connect } from 'react-redux';
import { removeNotification } from '@src/store/notifications/actions';

interface StateProps {
  notifications: SnackNotification[];
}

interface DispatchProps {
  removeNotification: (key: number) => void;
}

type Props = StateProps & DispatchProps & WithSnackbarProps;

interface State {
  displayed: number[];
}

const initialState: State = {
  displayed: [],
};

class Notifier extends Component<Props, State> {
  readonly state: State = initialState;

  render() {
    return null;
  }

  shouldComponentUpdate({ notifications: newSnacks }) {
    const { notifications: currentSnacks } = this.props;
    let notExists = false;
    for (let i = 0; i < newSnacks.length; i += 1) {
      if (notExists) {
        continue;
      }

      notExists = notExists || !currentSnacks.filter(({ key }) => newSnacks[i].key === key).length;
    }
    return notExists;
  }

  componentDidUpdate() {
    const { notifications } = this.props;

    notifications.forEach((notification) => {
      // Do nothing if snackbar is already displayed
      if (this.state.displayed.includes(notification.key)) {
        return;
      }
      // Display snackbar using notistack
      this.props.enqueueSnackbar(notification.message, notification.options);
      // Keep track of snackbars that we've displayed
      this.storeDisplayed(notification.key);
      // Dispatch action to remove snackbar from redux store
      this.props.removeNotification(notification.key);
    });
  }

  storeDisplayed = (id) => {
    this.setState({
      ...this.state,
      displayed: [...this.state.displayed, id],
    });
  }
}

export default withSnackbar(connect<StateProps, DispatchProps, {}, StoreState>(
  (store) => ({
    notifications: store.notificationsReducer.notifications,
  }),
  { removeNotification },
)(Notifier));