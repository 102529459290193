export const isScopeInArray = ( roles: (RoleInfo['scope'])[], roleInfo?: RoleInfo): boolean => {
  if (!roleInfo) {
    return false;
  }

  const { scope } = roleInfo;

  return roles.indexOf(scope) !== -1;
};

export const isClubScope = (roleInfo?: RoleInfo): boolean => {
  return isScopeInArray(['CLUB'], roleInfo);
};

export const isPartnerScope = (roleInfo?: RoleInfo): boolean => {
  return isScopeInArray(['PARTNER'], roleInfo);
};

export const isClubOrPartnerScope = (roleInfo?: RoleInfo): boolean => {
  return isScopeInArray(['CLUB', 'PARTNER'], roleInfo);
};

export const isFederationScope = (roleInfo?: RoleInfo): boolean => {
  return isScopeInArray(['PROVIDER', 'ASSOCIATION', 'FEDERATION'], roleInfo);
};

export const isProviderScope = (roleInfo?: RoleInfo): boolean => {
  return isScopeInArray(['PROVIDER'], roleInfo);
};

export const isAdminRole = (roleInfo?: RoleInfo): boolean => {
  if (!roleInfo) {
    return false;
  }

  return roleInfo.role === 'ADMIN';
};

type PermissionsGuard = {
  children: any;
  permissions?: Permissions;
};

export const CanEditGuard = ({
  children,
  permissions,
}: PermissionsGuard) => {
  if (permissions && permissions.canEdit) {
    return (children);
  }

  return null;
};