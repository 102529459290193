import React, { ChangeEvent, Component, RefObject } from 'react';
import { renderFormActions } from '@src/components/modals/ui';
import { connect } from 'react-redux';
import * as maintenanceActions from '@src/store/maintenance/actions';
import InputsValidator from '@src/components/forms/InputsValidator';
import { FormControl, FormGroup, FormLabel, Grid, TextField } from '@material-ui/core';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import LocalizedDateTimePicker from '@src/components/forms/LocalizedDateTimePicker';
import moment from 'moment';
import MaintenanceScopeSelect from '@src/components/forms/ui/MaintenanceScopeSelect';

interface Props {
  maintenanceId?: number | undefined;
  maintenance: MaintenanceState;
  parentContext?: 'MODAL' | 'PAGE';
  onClose?: () => void;
  addMaintenance: (params: MaintenanceAdd) => any;
  editMaintenance: (params: MaintenanceEdit) => any;
  actionsContainerRef?: RefObject<HTMLElement>;
}

interface State {
  enTitle: string;
  enMessage: string;
  fiTitle: string;
  fiMessage: string;
  svTitle: string;
  svMessage: string;
  from: Date;
  until: Date;
  scope: MaintenanceScope[];
}
const tomorrow = new Date;
tomorrow.setDate(tomorrow.getDate() + 1);

const initialState: State = {
  enTitle: '',
  enMessage: '',
  fiTitle: '',
  fiMessage: '',
  svTitle: '',
  svMessage: '',
  from: new Date,
  until: tomorrow,
  scope: ['ADMIN_UI']
};

class MaintenanceFormConnected extends Component<Props & WrappedComponentProps, State> {
  constructor(props: Props & WrappedComponentProps) {
    super(props);
    const {
      maintenanceId,
      maintenance: {
        maintenance
      }
    } = this.props;

    if (!maintenanceId) {
      this.state = initialState;
    } else if (maintenance) {
      this.state = this._maintenanceToState(maintenance);
    } else {
      this.state = initialState;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      maintenanceId,
      maintenance: {
        maintenance,
        requesting,
      },
    } = nextProps;

    if (!maintenanceId) {
      return; // Do nothing if we are adding new person
    } else if (requesting) {
      return; // Do nothing state is loading
    } else if (maintenance) {
      this.setState({
        ...(this._maintenanceToState(maintenance) as any)
      });
    }
  }

  render() {
    const {
      maintenanceId,
      parentContext,
      actionsContainerRef,
      intl
    } = this.props;

    const {
      from,
      until,
      enTitle,
      enMessage,
      fiTitle,
      fiMessage,
      svTitle,
      svMessage,
      scope
    } = this.state;

    return (
        <InputsValidator values={{from, until, enTitle, enMessage, fiTitle, fiMessage, svTitle, svMessage, scope}}>
          {({errorInputs, validateValues, validateThenApply}) => (
              <>
                <FormGroup row={true}>
                  <FormLabel style={{marginTop: 30}}>
                    <FormattedMessage id={'scenes.maintenances.form.message.visibleDuring'} />
                  </FormLabel>
                  <Grid container={true} spacing={3}>

                    <Grid xs={6} item={true}>
                      <FormControl fullWidth={true} margin={'normal'}>
                        <LocalizedDateTimePicker
                            error={errorInputs.from}
                            required={true}
                            value={(from != null ? moment.utc(from).local(false).toDate() : null)}
                            // value={from instanceof Date ? from : (from ? from.date : null)}
                            onChange={(newDate: Date) => {
                              this.setState(
                                  { from: newDate }
                              );
                            }}
                            emptyLabel={intl.formatMessage({ id: 'scenes.maintenances.form.message.fromInput' })}
                            clearable={true}
                        />
                      </FormControl>
                    </Grid>

                    <Grid xs={6} item={true}>
                      <FormControl fullWidth={true} margin={'normal'}>
                        <LocalizedDateTimePicker
                            error={errorInputs.until}
                            required={true}
                            value={(until != null ? moment.utc(until).local(false).toDate() : null)}
                            // value={until instanceof Date ? until : (until ? until.date : null)}
                            onChange={(newDate: Date) => {
                              this.setState(
                                  { until: newDate }
                              );
                            }}
                            emptyLabel={intl.formatMessage({ id: 'scenes.maintenances.form.message.untilInput' })}
                            clearable={true}
                        />
                      </FormControl>
                    </Grid>

                  </Grid>
                </FormGroup>

                <FormGroup style={{marginTop: 20}}>
                  <MaintenanceScopeSelect
                      initialValue={scope}
                      title={<FormattedMessage id={'scenes.maintenances.form.message.scope'} />}
                      groupProps={{fullWidth: true}}
                      onChange={(value: any) => {
                        this.setState({ scope: value });
                      }}
                  />
                </FormGroup>

                <FormGroup>
                  <FormLabel style={{marginTop: 30}}>
                    <FormattedMessage id={'scenes.maintenances.form.message.title.en'} />
                  </FormLabel>
                  <TextField
                      error={errorInputs.enTitle}
                      required={true}
                      margin={'normal'}
                      fullWidth={true}
                      label={<FormattedMessage id={'scenes.maintenances.form.message.titleInput'}/>}
                      value={enTitle}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const {
                          currentTarget: {
                            value
                          }
                        } = e;
                        this.setState({ enTitle: value }, validateValues);
                      }}
                  />
                  <TextField
                      error={errorInputs.enMessage}
                      required={true}
                      margin={'normal'}
                      fullWidth={true}
                      multiline={true}
                      label={<FormattedMessage id={'scenes.maintenances.form.message.messageInput'}/>}
                      value={enMessage}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const {
                          currentTarget: {
                            value
                          }
                        } = e;
                        this.setState({ enMessage: value }, validateValues);
                      }}
                  />
                </FormGroup>

                <FormGroup>
                  <FormLabel style={{marginTop: 30}}>
                    <FormattedMessage id={'scenes.maintenances.form.message.title.fi'} />
                  </FormLabel>
                  <TextField
                      error={errorInputs.fiTitle}
                      required={true}
                      margin={'normal'}
                      fullWidth={true}
                      label={<FormattedMessage id={'scenes.maintenances.form.message.titleInput'}/>}
                      value={fiTitle}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const {
                          currentTarget: {
                            value
                          }
                        } = e;
                        this.setState({ fiTitle: value }, validateValues);
                      }}
                  />
                  <TextField
                      error={errorInputs.fiMessage}
                      required={true}
                      margin={'normal'}
                      fullWidth={true}
                      multiline={true}
                      label={<FormattedMessage id={'scenes.maintenances.form.message.messageInput'}/>}
                      value={fiMessage}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const {
                          currentTarget: {
                            value
                          }
                        } = e;
                        this.setState({ fiMessage: value }, validateValues);
                      }}
                  />
                </FormGroup>

                <FormGroup>
                  <FormLabel style={{marginTop: 30}}>
                    <FormattedMessage id={'scenes.maintenances.form.message.title.sv'} />
                  </FormLabel>
                  <TextField
                      error={errorInputs.svTitle}
                      required={true}
                      margin={'normal'}
                      fullWidth={true}
                      label={<FormattedMessage id={'scenes.maintenances.form.message.titleInput'}/>}
                      value={svTitle}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const {
                          currentTarget: {
                            value
                          }
                        } = e;
                        this.setState({ svTitle: value }, validateValues);
                      }}
                  />
                  <TextField
                      error={errorInputs.svMessage}
                      required={true}
                      margin={'normal'}
                      fullWidth={true}
                      multiline={true}
                      label={<FormattedMessage id={'scenes.maintenances.form.message.messageInput'}/>}
                      value={svMessage}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const {
                          currentTarget: {
                            value
                          }
                        } = e;
                        this.setState({ svMessage: value }, validateValues);
                      }}
                  />
                </FormGroup>

                {renderFormActions({
                  id: maintenanceId,
                  parentContext,
                  onClose: this._handleOnClose,
                  onUpdate: validateThenApply.bind(this, this._handleUpdate),
                  onCreate: validateThenApply.bind(this, this._handleCreate),
                  containerRef: actionsContainerRef,
                })}

              </>
          )}
        </InputsValidator>
    );
  }

  private _handleOnClose = () => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  }

  private _handleUpdate = () => {
    const { editMaintenance } = this.props;
    const params = this._formMaintenanceParams();
    const {
      maintenance: {
        maintenance
      }
    } = this.props;

    if (!maintenance) {
      return;
    }

    console.log(params);

    editMaintenance({
      ...params,
      id: maintenance.id,
      onComplete: this._handleOnComplete,
    });
  }

  private _handleCreate = () => {
    const { addMaintenance } = this.props;
    const params = this._formMaintenanceParams();

    addMaintenance({
      ...params,
      onComplete: this._handleOnComplete,
    });
  }

  private _formMaintenanceParams = () => {
    const { enTitle, enMessage, fiTitle, fiMessage, svTitle, svMessage, from, until, scope } = this.state;

    return {
      en: { title: enTitle, message: enMessage },
      fi: { title: fiTitle, message: fiMessage },
      sv: { title: svTitle, message: svMessage },
      from: from,
      until: until,
      scope: scope
    } as Maintenance;
  }

  private _maintenanceToState = (maintenance: Maintenance) => {
    return {
      from: maintenance.from,
      until: maintenance.until,
      enTitle: maintenance.en.title,
      enMessage: maintenance.en.message,
      fiTitle: maintenance.fi.title,
      fiMessage: maintenance.fi.message,
      svTitle: maintenance.sv.title,
      svMessage: maintenance.sv.message,
      scope: maintenance.scope
    };
  }

  private _handleOnComplete = (result: any) => {
    if (result.error) {
      window.alert(result.error.message);
    } else {
      this._handleOnClose();
    }
  }
}

const MaintenanceForm = injectIntl(connect((state: any) => ({
  maintenance: state.maintenanceReducer,
  locale: state.locale
}), {
  addMaintenance : maintenanceActions.addMaintenance,
  editMaintenance : maintenanceActions.editMaintenance
})(MaintenanceFormConnected));

export {
  MaintenanceForm
};
