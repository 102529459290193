import { defaultOnCompleteCall, tokenAPIPut } from '@src/utils/storeUtils';
import { API_ROOT, APIRoute } from '@src/assets/config';
import { call, put } from 'redux-saga/effects';
import {
  CLUB_EXTRA_INFO_EDIT_ERROR,
  CLUB_EXTRA_INFO_EDIT_SUCCESS,
} from '@src/store/club/constants';

export function * setClubExtraInfo(token: string, clubId: number, params: any) {
  try {
    const data = yield call(() => {
      const url = `${API_ROOT}${APIRoute.CLUB_INFOEXTRA}/${clubId}`;
      return tokenAPIPut(url, token, params);
    });

    if (data) {
      defaultOnCompleteCall(params.onComplete, data);
      yield put({
        type: CLUB_EXTRA_INFO_EDIT_SUCCESS,
        data,
      });
    }
  } catch (error) {
    defaultOnCompleteCall(params.onComplete, undefined, error);
    yield put({ type: CLUB_EXTRA_INFO_EDIT_ERROR, error });
  }

  return true;
}