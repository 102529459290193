import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { IntlProvider } from 'react-intl';
import localization from './localization';
import { flattenMessages } from './utils/flattenMessages';
import theme from './theme/theme';
import { Login } from '@src/scenes/authentication/Login';
import { ForgotPassword } from '@src/scenes/authentication/ForgotPassword';
import { AdminIndex } from '@src/scenes/admin';
import { Redirect, Switch } from 'react-router';
import { applyMiddleware, createStore, compose } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { indexReducer, indexSagas } from '@src/store';
import { LoginCheckRequest, loginCheckRequest } from '@src/store/login/actions';
import { connect } from 'react-redux';
import { LOCALSTORAGE_TOKEN_NAME, isProduction } from '@src/assets/config';
import { OverlayLoader } from '@src/components/layouts/ui';
import RouteLoginGuard from '@src/components/access-control/RouteLoginGuard';
import { Logout } from '@src/scenes/authentication/Logout';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import dateFnsLocaleFi from 'date-fns/locale/fi';
import dateFnsLocaleSv from 'date-fns/locale/fi';
import dateFnsLocaleEn from 'date-fns/locale/en-US';
import materialUITheme from './theme/materialUITheme';
import Layout from '@src/components/layouts/Layout';
import ResetPassword from '@src/scenes/authentication/ResetPassword';
import Utils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import NonProductionNotification from '@src/components/contexts/NonProductionNotification';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from 'react-query';

/*
  Unholy fi-SV hack due to the fact that window.Intl does not respoect sv-FI
 */
const dateFnsLocales = {
  'fi-FI': dateFnsLocaleFi,
  'fi-SV': {
    ...dateFnsLocaleSv,
    formatLong: {
      ...dateFnsLocaleSv.formatLong,
      date: (dateFnsLocaleFi.formatLong as any).date,
    },
  },
  'sv-SE': dateFnsLocaleSv,
  'en-US': dateFnsLocaleEn,
};

// Setup the middleware to watch between the Reducers and the Actions
const sagaMiddleware = createSagaMiddleware();

/* tslint:disable */
const composeSetup = !isProduction && typeof window === 'object' &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) : compose;
/* tslint:enable */

const store = createStore(
  indexReducer,
  composeSetup(applyMiddleware(sagaMiddleware)), // allows redux devtools to watch sagas
);

export const queryClient = new QueryClient();

sagaMiddleware.run(indexSagas);

const GlobalStyles = createGlobalStyle`
  body {
    font-size: 62.5% !important;
    font-family: 'Source Sans Pro', sans-serif !important;
    font-weight: 400;
    text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: transparent; 
    margin: 0;
  }
  
  table {
    tbody, thead {
      tr {
        td, th {
          vertical-align: middle;
        }
      }
    }
  }
  
  .Select-value,
  .Select-placeholder {
    padding-left: 0 !important;
  }
  
  .Select-value {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  
  .Select-menu {
	  [role="menuitem"] {
	    height: auto;
	  }
  }
  
  #select-portal-target > div {
    z-index: 3456;
  }
`;

interface StateProps {
  login: LoginState;
  locale: AppLocale;
}

interface DispatchProps {
  loginCheckRequest(args: LoginCheckRequest): void;
}

type Props = StateProps & DispatchProps;

class AppUnconnected extends React.Component<Props, {}> {

  constructor(props: Props) {
    super(props);
    try {
      const token = localStorage.getItem(LOCALSTORAGE_TOKEN_NAME);
      if (token) {
        this.props.loginCheckRequest({ token });
      }
    } catch (e) {
      window.console.warn('unable to parse token');
    }
  }

  render() {
    const {
      login: {
        checkRequesting
      },
      locale: {
        appLanguage
      },
    } = this.props;

    const { code } = appLanguage;
    // FIXME questionable cast
    const messages = flattenMessages(
      localization.find(i => i.code === code)?.data || {}
    ) as unknown as Record<string, string>;

    return (
      <ThemeProvider theme={theme}>
        <IntlProvider
          locale={code}
          messages={messages}
        >
          <MuiThemeProvider theme={materialUITheme}>
            <MuiPickersUtilsProvider utils={Utils} locale={dateFnsLocales[code]}>
              <SnackbarProvider maxSnack={3}>
                <>
                  <GlobalStyles />
                  <Router>
                    <Switch>
                      <Redirect from={`/`} to={`/login`} exact={true} />
                      <Route path={'/login'} component={Login} />
                      <Route path={'/logout'} component={Logout} />
                      <Route path={'/reset-password/:resetToken'} component={ResetPassword} />
                      <Route path={'/reset-password'} component={ForgotPassword} exact={true} />

                      <Layout>
                        {/*
                        Create Admin routes if admin. Login otherwise.
                      */}
                        <Route
                          path={'/admin'}
                          render={(props: any) => (
                            <RouteLoginGuard
                              {...props}
                              role={'admin'}
                              requiredRole={'admin'}
                              component={(
                                <AdminIndex />
                              )}
                            />
                          )}
                        />
                      </Layout>
                    </Switch>
                  </Router>

                  <NonProductionNotification />
                  <OverlayLoader visible={checkRequesting} />
                </>
              </SnackbarProvider>
            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        </IntlProvider>
      </ThemeProvider>
    );
  }
}

const AppConnected = connect<StateProps, DispatchProps, {}, StoreState>(state => ({
  login: state.loginReducer,
  locale: state.locale,
}), {
  loginCheckRequest,
})(AppUnconnected);

const App = () => (
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <AppConnected />
      <div id={'select-portal-target'} />
    </Provider>
  </QueryClientProvider>
);

export default App;
