import { take, fork } from 'redux-saga/effects';
import {
  OFFERS_REQUESTING,
  OFFERS_SUCCESS,
  OFFERS_ERROR, OFFERS_SEARCH_REQUESTING, OFFERS_SEARCH_RESET,
} from './constants';
import { APIRoute, DEFAULT_PAGINATION_LIMIT, DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import { AUTH_ACTIVE_STATE_CHANGE, AUTH_SET, INITIAL_AUTH_ACTIVE_STATE_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { normalizeSearchParams, parseTargettingParamsFromActiveState } from '@src/utils/storeUtils';

/*
  GET /api/1/offers
 */
const offersFetchFlow = SagaFlowFactory.createPagedGETFlow({
  apiRoute: APIRoute.GET_OFFERS,
  itemType: 'offers',
  successType: OFFERS_SUCCESS,
  errorType: OFFERS_ERROR
});

/*
  G>ET /api/1/offers/search
 */
const fetchOffersSearchFlow = SagaFlowFactory.createPagedGETFlow({
  apiRoute: APIRoute.SEARCH_OFFERS,
  itemType: 'offers',
  successType: OFFERS_SUCCESS,
  errorType: OFFERS_ERROR
});

function * offersWatcher() {
  let token: string = '';
  let targetingParams: any = {};

  yield fork(function* () {
    const {
      user,
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
      page,
      limit,
      params,
      activeState,
    } = yield take([
      OFFERS_REQUESTING,
      OFFERS_SEARCH_REQUESTING,
      OFFERS_SEARCH_RESET,
      AUTH_ACTIVE_STATE_CHANGE,
      INITIAL_AUTH_ACTIVE_STATE_SET,
    ]);

    switch (type) {
      case OFFERS_REQUESTING:
        yield fork(offersFetchFlow, token, page, limit, targetingParams);
        break;
      case OFFERS_SEARCH_REQUESTING:
        yield fork(fetchOffersSearchFlow, token, page, limit, {...normalizeSearchParams(params), ...targetingParams});
        break;
      case OFFERS_SEARCH_RESET:
        yield fork(offersFetchFlow, token, DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_LIMIT, targetingParams);
        break;
      case INITIAL_AUTH_ACTIVE_STATE_SET:
      case AUTH_ACTIVE_STATE_CHANGE:
        targetingParams = parseTargettingParamsFromActiveState(activeState);
        break;
      default:
    }
  }
}

export default offersWatcher;