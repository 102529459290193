export const FEDERATION_GET_REQUESTING = 'FEDERATION_GET_REQUESTING';
export const FEDERATION_GET_SUCCESS = 'FEDERATION_GET_SUCCESS';
export const FEDERATION_GET_ERROR = 'FEDERATION_GET_ERROR';

export const FEDERATION_ADD_REQUESTING = 'FEDERATION_ADD_REQUESTING';
export const FEDERATION_ADD_SUCCESS = 'FEDERATION_ADD_SUCCESS';
export const FEDERATION_ADD_ERROR = 'FEDERATION_ADD_ERROR';

export const FEDERATION_EDIT_REQUESTING = 'FEDERATION_EDIT_REQUESTING';
export const FEDERATION_EDIT_SUCCESS = 'FEDERATION_EDIT_SUCCESS';
export const FEDERATION_EDIT_ERROR = 'FEDERATION_EDIT_ERROR';

export const FEDERATION_DELETE_REQUESTING = 'FEDERATION_DELETE_REQUESTING';
export const FEDERATION_DELETE_SUCCESS = 'FEDERATION_DELETE_SUCCESS';
export const FEDERATION_DELETE_ERROR = 'FEDERATION_DELETE_ERROR';

export const FEDERATION_CONTACTS_GET_REQUESTING = 'FEDERATION_CONTACTS_GET_REQUESTING';
export const FEDERATION_CONTACTS_GET_SUCCESS = 'FEDERATION_CONTACTS_GET_SUCCESS';
export const FEDERATION_CONTACTS_GET_ERROR = 'FEDERATION_CONTACTS_GET_ERROR';