import React, { Component, CSSProperties } from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography/Typography';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { API_ROOT, APIRoute } from '@src/assets/config';
import Button from '@material-ui/core/Button/Button';
import ReactDOM from 'react-dom';
import listImagePlaceholder from '../../assets/images/200x200.png';
import contentImagePlaceholder from '../../assets/images/500x250.png';

const OfferImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4em;
  
  .fileContainer {
    box-shadow: none;
    padding: 0;
    align-items: flex-start;
    
    > *:first-child {
      display: none;
    }
  }
  
  .uploadPicturesWrapper {
    display: none !important;
  }
`;

const OfferImageContainer = styled.div`
  width: 48%;
`;

const OfferImagePreview = styled.img`
  max-width: 100%;
`;

export const parseCorrectImage = (type: 'ContentImage' | 'ListImage', images?: OfferImages): OfferImage | undefined => {
  if (!images || images.length === 0) {
    return undefined;
  }

  return images.find((image) => image.type === type);
};

export const getPreviewSrcForImage = (type: 'ContentImage' | 'ListImage', image?: OfferImage): string => {
  if (!image) {
    if (type === 'ContentImage') {
      return contentImagePlaceholder;
    }

    return listImagePlaceholder;
  }

  return image.url;
};

interface Props {
  style: CSSProperties | undefined;
  images?: OfferImages;
  auth: AuthState;
  onChange?: (images: OfferImages | undefined) => any;
}

interface State {
  listImage?: OfferImage;
  contentImage?: OfferImage;
}

class OfferImagesUpload extends Component<Props, State> {
  inputListImage: any;
  inputContentImage: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      listImage: undefined,
      contentImage: undefined,
    };

    this.inputListImage = React.createRef();
    this.inputContentImage = React.createRef();
  }

  render() {
    const { style, images } = this.props;
    const listImage = parseCorrectImage('ListImage', images);
    const contentImage = parseCorrectImage('ContentImage', images);

    return (
      <OfferImagesContainer style={style}>

        <OfferImageContainer>
          <Typography component="h2" style={{margin: 0}}>
            <FormattedMessage id={'scenes.offers.form.sections.content.form.listImage'} />
          </Typography>
          <Button onClick={() => this._openFileDialog(this.inputListImage.current)}>
            <FormattedMessage id={'scenes.offers.form.sections.content.form.uploadImage'} />
          </Button>
          <input
            type={'file'}
            multiple={false}
            ref={this.inputListImage}
            style={{ display: 'none' }}
            accept="image/*"
            onChange={(e: any) => {
              this._handleImageUpload(e.currentTarget.files, 'ListImage');
            }}
          />
          <OfferImagePreview src={getPreviewSrcForImage('ListImage', listImage)} />
        </OfferImageContainer>

        <OfferImageContainer>
          <Typography component="h2" style={{margin: 0}}>
            <FormattedMessage id={'scenes.offers.form.sections.content.form.contentImage'} />
          </Typography>
          <Button onClick={() => this._openFileDialog(this.inputContentImage.current)}>
            <FormattedMessage id={'scenes.offers.form.sections.content.form.uploadImage'} />
          </Button>
          <input
            type={'file'}
            multiple={false}
            ref={this.inputContentImage}
            style={{ visibility: 'hidden', width: 0, height: 0, position: 'absolute', 'margin': 0}}
            accept="image/*"
            onChange={(e: any) => {
              this._handleImageUpload(e.currentTarget.files, 'ContentImage');
            }}
          />
          <OfferImagePreview src={getPreviewSrcForImage('ContentImage', contentImage)} />
        </OfferImageContainer>
      </OfferImagesContainer>
    );
  }

  private _openFileDialog = (ref: any) => {
    const fileInputDom: any = ReactDOM.findDOMNode(ref);
    if (fileInputDom) {
      fileInputDom.click();
    }
  }

  private _handleImageUpload = (files: any, type: 'ContentImage' | 'ListImage'): any => {
    const {
      auth: {
        token,
      }
    } = this.props;

    if (!files || !files.length) {
      return;
    }

    const formData = new FormData();
    formData.append('image', files[0]);
    formData.append('type', type);

    fetch(`${API_ROOT}${APIRoute.OFFER_IMAGE_UPLOAD}`, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      body: formData,
    })
      .then(response => response.json() )
      .then(json => {
        if (json.error) {
          throw json.error;
        }

        this._handleChange(json, type);
      })
      .catch((error) => {
        window.console.log(error);
        window.alert(error.message);
      });
  }

  private _handleChange = (newImage: any, type: 'ContentImage' | 'ListImage') => {
    const { onChange } = this.props;
    const formattedImage = {
      ...newImage,
      type,
    };

    let images = [formattedImage];

    if (type === 'ContentImage') {
      const listImage = parseCorrectImage('ListImage', this.props.images);
      if (listImage) {
        images.push(listImage);
      }
    } else {
      const contentImage = parseCorrectImage('ContentImage', this.props.images);
      if (contentImage) {
        images.push(contentImage);
      }
    }

    if (onChange) {
      onChange(images);
    }
  }
}

export default connect((state: any) => ({
  auth: state.authReducer
}), {})(OfferImagesUpload);
