import {
  USER_ADD_ERROR,
  USER_ADD_REQUESTING, USER_ADD_SUCCESS,
  USER_DELETE_ERROR,
  USER_DELETE_REQUESTING, USER_DELETE_SUCCESS,
  USER_EDIT_ERROR,
  USER_EDIT_REQUESTING, USER_EDIT_SUCCESS,
  USER_GET_ERROR,
  USER_GET_REQUESTING,
  USER_GET_SUCCESS,
} from './constants';
import { formDefaultErrorState, formDefaultRequestingState } from '@src/utils/storeUtils';

const initialState: UserState = {
  user: undefined,
  requesting: false,
  successful: false,
  error: [],
};

const userReducer = (state: UserState = initialState, action: any) => {
  switch (action.type) {
    case USER_GET_REQUESTING:
      return {
        ...formDefaultRequestingState(state),
        user: undefined,
      };

    case USER_ADD_REQUESTING:
    case USER_DELETE_REQUESTING:
    case USER_EDIT_REQUESTING:
      return formDefaultRequestingState(state);

    case USER_GET_ERROR:
    case USER_ADD_ERROR:
    case USER_DELETE_ERROR:
    case USER_EDIT_ERROR:
      return formDefaultErrorState(state, action);

    case USER_GET_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
        user: action.data,
      };

    case USER_ADD_SUCCESS:
    case USER_DELETE_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
      };

    case USER_EDIT_SUCCESS:
      const { data, } = action;
      return {
        ...state,
        requesting: false,
        successful: true,
        user: data,
      };

    default:
      return state;
  }
};

export {
  userReducer,
};