import { Action } from 'redux';
import {
  ACCEPTABLE_SCORES_CHANGE_PAGE,
  ACCEPTABLE_SCORES_REQUESTING,
  PENDING_ACCEPTABLE_SCORES_REQUESTING,
  RESET_ACCEPTABLE_SCORES,
} from '@src/store/acceptableScores/constants';

/*
  GET ACCEPTABLE SCORES
 */
export interface GetAcceptableScoresActionPayload extends TablePaginationAction {
  personId: number;
}
export interface GetAcceptableScoresAction extends Action, GetAcceptableScoresActionPayload {
  type: 'ACCEPTABLE_SCORES_REQUESTING';
}
export interface GetAcceptableScoresSuccessAction extends Action, PermissionsACL, PagedPayload {
  type: 'ACCEPTABLE_SCORES_SUCCESS';
  pagedScores: AcceptableScoreMap;
}
export interface GetAcceptableScoresErrorAction extends Action {
  type: 'ACCEPTABLE_SCORES_ERROR';
}
export const getAcceptableScores = (params: GetAcceptableScoresActionPayload): GetAcceptableScoresAction => ({
  type: ACCEPTABLE_SCORES_REQUESTING,
  ...params,
});

/*
  CHANGE PAGE
 */
export interface ChangeAcceptableScoresPageActionPayload {
  page: number;
}
export interface ChangeAcceptableScoresPageAction extends Action, ChangeAcceptableScoresPageActionPayload {
  type: 'ACCEPTABLE_SCORES_CHANGE_PAGE';
}
export const changeAcceptableScoresPage = (page: number): ChangeAcceptableScoresPageAction => ({
  type: ACCEPTABLE_SCORES_CHANGE_PAGE,
  page,
});

/*
  GET PENDING ACCEPTABLE SCORES
 */
export interface GetPendingAcceptableScoresAction extends Action {
  type: 'PENDING_ACCEPTABLE_SCORES_REQUESTING';
  personId: number;
}
export interface GetPendingAcceptableScoresSuccessAction extends Action {
  type: 'PENDING_ACCEPTABLE_SCORES_SUCCESS';
  data: {
    scores: PendingAcceptableScore[];
  };
}
export interface GetPendingAcceptableScoresErrorAction extends Action {
  type: 'PENDING_ACCEPTABLE_SCORES_ERROR';
}
export const getPendingAcceptableScores = (personId: number) => ({
  type: PENDING_ACCEPTABLE_SCORES_REQUESTING,
  personId,
});

/*
  Reset AS State
 */
export interface ResetAcceptableScoreState extends Action {
  type: 'RESET_ACCEPTABLE_SCORES';
}
export const resetAcceptableScores = () => ({
  type: RESET_ACCEPTABLE_SCORES,
});