import React, { Component } from 'react';
import ReportsMemberStatisticsScene from '@src/components/scenes/reports/ReportsMemberStatisticsScene';

class AdminReportsMemberStatistics extends Component {
  render() {
    return (
      <ReportsMemberStatisticsScene />
    );
  }
}

export {
  AdminReportsMemberStatistics
};