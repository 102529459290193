import { Button, IconButton, makeStyles, Menu, MenuItem, TableCell, TableRow } from '@material-ui/core';
import { FormattedDate, FormattedMessage } from 'react-intl';
import React, { useState } from 'react';
import { ExpandMore, ExpandLess, MoreVert } from '@material-ui/icons';
import { getCourseChildren, getCourseParents } from '@src/utils/courseUtils';
import { get } from 'lodash';
import ClubCourseName from '@src/components/scenes/club/course/ClubCourseName';
import ClubCourseFairwayCount from '@src/components/scenes/club/course/ClubCourseFairwayCount';
import ClubCourseParCount from '@src/components/scenes/club/course/ClubCourseParCount';
import ClubCourseStatus from '@src/components/scenes/club/course/ClubCourseStatus';
import RoleScopeGuard from '@src/components/access-control/RoleScopeGuard';
import CourseEditAction from '@src/components/scenes/club/course/CourseEditAction';
import ClubCoursePCCDialog from '@src/components/scenes/club/course/ClubCoursePCCDialog';

const useStyles = makeStyles({
  anchor: {
    cursor: 'pointer',
  },
  anchorIcon: {
    verticalAlign: 'middle'
  },
  siblingTableName: {
    paddingLeft: 16,
    display: 'block',
  },
  ordinaryRow: {
    background: '#ffffff',
  },
  extraordinaryRow: {
    background: '#ffffff',
  },
  partialRow: {
    background: '#eeeeee',
  }
});

interface OwnProps {
  course: ClubCourse;
  onLoading?(isLoading: boolean): void;
  onClickView(courseId: number): void;
  onClickDelete(courseId: number): void;
  onChangeStatus(courseId: number, status: 'activate' | 'deactivate'): void;
}

type Props = OwnProps;

function ClubCourseTableRow({
  course,
  onLoading = () => null,
  onClickView,
  onClickDelete,
  onChangeStatus,
}: Props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const [open, setOpen] = useState<boolean>(false);
  const handleMenuClick = event => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const children = getCourseChildren(course);
  const parents = getCourseParents(course);
  const hasParentsOrSiblings = !!(children && children.length) || !!(parents && parents.length);

  function callOnChangeStatus() {
    const status = course.status !== 'ACTIVE' ? 'activate' : 'deactivate';
    onChangeStatus(course.id, status);
  }

  const icon = open
    ? <ExpandLess className={classes.anchorIcon} color={hasParentsOrSiblings ? 'primary' : undefined} />
    : <ExpandMore className={classes.anchorIcon} color={hasParentsOrSiblings ? 'primary' : undefined} />;

  const renderAttachedItem = (item: ClubCourseSibling) => (
    <TableRow
      key={`${item.idCourse}`}
      style={{ display: !open ? 'none' : undefined }}
      className={get(classes, `${get(item, 'type', 'ORDINARY').toLowerCase()}Row`)}
    >
      <TableCell />
      <TableCell>
        <span className={classes.siblingTableName}>
          <ClubCourseName name={item.name} type={item.type} />
        </span>
      </TableCell>
      <TableCell align="center">
        <ClubCourseFairwayCount courseType={item.courseType} />
      </TableCell>
      <TableCell align="center">
        <ClubCourseParCount par={item.holeParSum} />
      </TableCell>
      <TableCell />
      <TableCell />
      <TableCell align="right">
        <Button size={'small'} color={'primary'} onClick={() => onClickView(item.idCourse)}>
          <FormattedMessage id={'buttons.show'} />
        </Button>
      </TableCell>
      <TableCell />
    </TableRow>
  );

  return (
    <RoleScopeGuard>
        {({ isFederationScope }) =>
        ((course.type === 'PARTIAL' && isFederationScope) || course.type !== 'PARTIAL') && (
          <>
            <TableRow className={get(classes, `${course.type.toLowerCase()}Row`)}>
              <RoleScopeGuard>
                {({ isFederationScope }) => (
                  isFederationScope ? (
                    hasParentsOrSiblings ? (
                      <>
                        <TableCell
                          style={{ paddingRight: 0 }}
                          className={classes.anchor}
                          onClick={() => setOpen(!open)}
                        >
                          {icon}
                        </TableCell>
                        <TableCell className={classes.anchor} onClick={() => setOpen(!open)}>
                          <ClubCourseName name={course.name} type={course.type} />
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell style={{ paddingRight: 0, opacity: 0 }}>
                          {icon}
                        </TableCell>
                        <TableCell>
                          <ClubCourseName name={course.name} type={course.type} />
                        </TableCell>
                      </>
                    )
                  ) : (
                    <TableCell>
                      <ClubCourseName name={course.name} type={course.type} />
                    </TableCell>
                  )
                )}
              </RoleScopeGuard>
              <TableCell align="center" style={{ padding: '6px 12px 6px 10px' }}>
                <ClubCourseFairwayCount courseType={course.courseType} holes={course.holes} />
              </TableCell>
              <TableCell align="center">
                <ClubCourseParCount par={course.holeParSum} holes={course.holes} />
              </TableCell>
              <TableCell>
                {course.timeEffective ? (
                  <FormattedDate
                    value={course.timeEffective}
                    day={'numeric'}
                    month={'numeric'}
                    year={'numeric'}
                    minute={'numeric'}
                    hour={'numeric'}
                  />
                ) : '-'}
              </TableCell>
              <TableCell align={'center'}>
                <ClubCourseStatus status={course.status} draftCourseId={course.draftCourseId} />
              </TableCell>

              <TableCell align="right" style={{ whiteSpace: 'pre' }}>
                <Button
                  size={'small'}
                  color={'default'}
                  variant="contained"
                  onClick={() => onClickView(course.id)}
                >
                  <FormattedMessage id={'buttons.show'} />
                </Button>
              </TableCell>

              <TableCell>
                <CourseEditAction
                  courseId={course.id}
                  draftCourseId={course.draftCourseId}
                  onSuccess={onClickView}
                  onBeforeEdit={() => onLoading(true)}
                  onEditDone={() => onLoading(false)}
                >
                  {({ handleEditClick }) => (
                    <ClubCoursePCCDialog>
                      {({ showPCC }) => (
                        <>
                          <IconButton
                            size={'small'}
                            aria-controls="club-course-more"
                            aria-haspopup="true"
                            onClick={handleMenuClick}
                          >
                            <MoreVert />
                          </IconButton>
                          <Menu
                            id="club-course-more"
                            anchorEl={anchorEl}
                            open={menuOpen}
                            onClose={handleMenuClose}
                          >
                            <MenuItem onClick={() => [handleMenuClose(), handleEditClick()]}>
                              <FormattedMessage id={'buttons.edit'} />
                            </MenuItem>
                            <MenuItem onClick={() => [handleMenuClose(), showPCC(course.id)]}>
                              <FormattedMessage id={'buttons.showPCC'} />
                            </MenuItem>
                            <MenuItem onClick={() => [handleMenuClose(), callOnChangeStatus()]}>
                              <FormattedMessage
                                id={`buttons.${course.status !== 'ACTIVE' ? 'activate' : 'deactivate'}`}
                              />
                            </MenuItem>
                            <MenuItem onClick={() => [handleMenuClose(), onClickDelete(course.id)]}>
                              <FormattedMessage id={'buttons.remove'} />
                            </MenuItem>
                          </Menu>
                        </>
                      )}
                    </ClubCoursePCCDialog>
                  )}
                </CourseEditAction>
              </TableCell>
            </TableRow>

            {parents.map(renderAttachedItem)}
            {children.map(renderAttachedItem)}
          </>
        )}
    </RoleScopeGuard>
  );
}

export default ClubCourseTableRow;
