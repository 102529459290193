import React from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import { Button, CardContent, Grid, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { ButtonLoaderWrap, renderListItem, renderListWithGrid } from '@src/components/layouts/ui';
import { EditIconFab } from '@src/components/buttons/buttons';
import { connect } from 'react-redux';
import EditHandicapOverride from '@src/components/modals/EditHandicapOverride';
import { useMutation } from 'react-query';
import moment from 'moment';
import { downloadFile } from '@src/utils/utils';
import { APIRoute, API_ROOT } from '@src/assets/config';
import useAuthToken from '@src/hooks/useAuthToken';
import { useParams } from 'react-router-dom';

interface OwnProps {
  cardHeaderClassName?: string;
}

interface StateProps {
  handicapInfo?: HandicapInfo;
  personId?: number;
}

type Props = OwnProps & StateProps;

const fetchHandicapReview = async (token?: string, personId?: number, startDate?: string, endDate?: string) => {
  const response = await fetch(`${API_ROOT}${APIRoute.getHandicapReview(personId, startDate, endDate, 'membership')}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw {
      status: response.status
    };
  }
  return response.blob();
};

function WHSHandicapInfoCard(props: Props) {

  const intl = useIntl();

  const token = useAuthToken();

  const { memberId } = useParams<any>();

  const handicapReviewMutation = useMutation(
    (personId: number) =>
      fetchHandicapReview(token, personId, moment().subtract(365, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')),
    {
      onSuccess: (data) => {
        downloadFile(data, `handicap-review-${moment().format('YYYY-MM-DD')}.xlsx`);
      },
      onError: () => {
        window.alert(intl.formatMessage({ id: 'scenes.reports.handicapReview.failedToDownload' }));
      },
    }
  );

  const { handicapInfo, personId } = props;

  const hcpInfo = [
    {
      label: 'strings.hcp',
      value: handicapInfo?.handicapIndex || '-'
    },
    {
      label: 'strings.handicapIndexLow',
      value: handicapInfo?.handicapIndexLow || '-'
    },
    {
      label: 'strings.computedDate',
      value: handicapInfo?.computedDate
        ? (<FormattedDate value={handicapInfo.computedDate} />)
        : '-',
    },
  ];

  const overrideInfo = [
    {
      label: 'strings.override',
      value: handicapInfo?.handicapOverride?.type || '-',
    },
    {
      label: 'strings.timeStart',
      value: handicapInfo?.handicapOverride?.timeStart ? (
        <FormattedDate
          value={handicapInfo.handicapOverride.timeStart}
          day={'numeric'}
          month={'numeric'}
          year={'numeric'}
          minute={'numeric'}
          hour={'numeric'}
        />
      ) : '-'
    },
    {
      label: 'strings.timeEnd',
      value: handicapInfo?.handicapOverride?.timeEnd ? (
        <FormattedDate
          value={handicapInfo.handicapOverride.timeEnd}
          day={'numeric'}
          month={'numeric'}
          year={'numeric'}
          minute={'numeric'}
          hour={'numeric'}
        />
      ) : '-'
    },
    {
      label: 'strings.hcp',
      value: handicapInfo?.handicapOverride?.handicapIndex || '-'
    },
    {
      label: 'strings.handicapIndexLow',
      value: handicapInfo?.handicapOverride?.handicapIndexLow || '-'
    },
    {
      label: 'strings.computedDate',
      value: handicapInfo?.handicapOverride?.computedDate
        ? (<FormattedDate value={handicapInfo.handicapOverride.computedDate} />)
        : '-',
    },
  ];

  return (
    <Grid container={true} spacing={5}>
      <Grid item={true} xs={12} sm={6}>
        <Card>
          <CardHeader
            className={props.cardHeaderClassName}
            title={(
              <Typography gutterBottom={true} variant="h5">
                <FormattedMessage id={'strings.handicapInfo'} />
              </Typography>
            )}
          />
          <CardContent>
            {hcpInfo.map(renderListItem)}
            <ButtonLoaderWrap loading={false}>
              <Button
                disabled={false}
                variant="contained"
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  if (!memberId || isNaN(parseInt(memberId, 10))) { return; }
                  console.log('?');
                  handicapReviewMutation.mutate(parseInt(memberId, 10));
                }}
              >
                <FormattedMessage id={'buttons.download'} />
              </Button>
            </ButtonLoaderWrap>
          </CardContent>
        </Card>
      </Grid>
      <Grid item={true} xs={12} sm={6}>
        <Card>
          <CardHeader
            className={props.cardHeaderClassName}
            title={(
              <Typography gutterBottom={true} variant="h5">
                <FormattedMessage id={'strings.handicapOverride'} />
              </Typography>
            )}
            action={(handicapInfo?.handicapOverride && personId) && (
              <EditHandicapOverride>
                {({ editOverride, ref }) => (
                  <EditIconFab
                    ref={ref}
                    onClick={() => editOverride(personId, handicapInfo.handicapOverride)}
                  />
                )}
              </EditHandicapOverride>
            )}
          />
          <CardContent>
            {renderListWithGrid(overrideInfo, 2)}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default connect<StateProps, {}, OwnProps, StoreState>(state => ({
  handicapInfo: state.personReducer.handicapInfo,
  personId: state.personReducer.person?.id,
}))(WHSHandicapInfoCard);
