import {
  FUNCTIONARY_ADD_ERROR,
  FUNCTIONARY_ADD_REQUESTING,
  FUNCTIONARY_ADD_SUCCESS,
  FUNCTIONARY_DELETE_ERROR,
  FUNCTIONARY_DELETE_REQUESTING,
  FUNCTIONARY_DELETE_SUCCESS,
  FUNCTIONARY_EDIT_ERROR,
  FUNCTIONARY_EDIT_REQUESTING,
  FUNCTIONARY_EDIT_SUCCESS,
  FUNCTIONARY_GET_ERROR,
  FUNCTIONARY_GET_REQUESTING,
  FUNCTIONARY_GET_SUCCESS
} from '@src/store/functionary/constants';
import { formDefaultErrorState, formDefaultRequestingState } from '@src/utils/storeUtils';

const initialState: FunctionaryState = {
  functionary: undefined,
  requesting: false,
  successful: false,
  error: [],
};

export const functionaryReducer = (state: FunctionaryState = initialState, action: any) => {
  switch (action.type) {
    case FUNCTIONARY_GET_REQUESTING:
      return {
        ...formDefaultRequestingState(state),
        functionary: undefined,
      };

    case FUNCTIONARY_ADD_REQUESTING:
    case FUNCTIONARY_DELETE_REQUESTING:
    case FUNCTIONARY_EDIT_REQUESTING:
      return formDefaultRequestingState(state);

    case FUNCTIONARY_GET_ERROR:
    case FUNCTIONARY_ADD_ERROR:
    case FUNCTIONARY_DELETE_ERROR:
    case FUNCTIONARY_EDIT_ERROR:
      return formDefaultErrorState(state, action);

    case FUNCTIONARY_GET_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
        functionary: action.data,
      };

    case FUNCTIONARY_ADD_SUCCESS:
    case FUNCTIONARY_DELETE_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
      };

    case FUNCTIONARY_EDIT_SUCCESS:
      const { data } = action;
      return {
        ...state,
        requesting: false,
        successful: true,
        functionary: data,
      };

    default:
      return state;
  }
};