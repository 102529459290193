import React, { createRef, PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as functionaryActions from '@src/store/functionary/actions';
import {
  Dialog,
  DialogTitle,
  DialogContent
} from '@material-ui/core';
import { ModalContentOverlayLoader } from '@src/components/modals/ui';
import { FunctionaryForm } from '@src/components/scenes/federation/functionary/FunctionaryForm';

type Props = {
  functionaryId?: number;
  personToSet?: Person;
  open: boolean;
  onClose: () => void;
  getFunctionary: (params: FunctionaryGet) => any;
  functionaryState: FunctionaryState;
  targetIdParams: TargetIdParams;
};

class FunctionaryModalConnected extends PureComponent<Props> {
  actionsContainerRef = createRef<HTMLDivElement>();

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      functionaryId,
      getFunctionary
    } = this.props;

    if (nextProps.functionaryId !== functionaryId) {
      if (nextProps.functionaryId) {
        getFunctionary({
          id: nextProps.functionaryId,
        });
      }
    }
  }

  render() {
    const {
      open,
      functionaryId,
      onClose,
      functionaryState: {
        requesting
      },
      personToSet,
    } = this.props;

    return (
      <Dialog
        open={open}
        fullWidth={true}
      >
        <DialogTitle>
          {functionaryId && (
            <FormattedMessage id={'scenes.functionaries.modal.edit'} />
          )}
          {!functionaryId && (
            <FormattedMessage id={'scenes.functionaries.modal.add'} />
          )}
        </DialogTitle>

        <DialogContent>
          {requesting && <ModalContentOverlayLoader/>}
          <FunctionaryForm
            functionaryId={functionaryId}
            personToSet={personToSet}
            parentContext={'MODAL'}
            onClose={onClose}
            actionsContainerRef={this.actionsContainerRef}
            targetIdParams={this.props.targetIdParams}
          />
        </DialogContent>

        <div ref={this.actionsContainerRef} />
      </Dialog>
    );
  }
}

const FunctionaryModal = connect((state: any) => ({
  functionaryState: state.functionaryReducer
}), {
  getFunctionary: functionaryActions.getFunctionary
})(FunctionaryModalConnected);

export {
  FunctionaryModal
};
