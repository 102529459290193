export const PERSON_GET_REQUESTING = 'PERSON_GET_REQUESTING';
export const PERSON_GET_SUCCESS = 'PERSON_GET_SUCCESS';
export const PERSON_GET_ERROR = 'PERSON_GET_ERROR';

export const PERSON_ADD_REQUESTING = 'PERSON_ADD_REQUESTING';
export const PERSON_ADD_SUCCESS = 'PERSON_ADD_SUCCESS';
export const PERSON_ADD_ERROR = 'PERSON_ADD_ERROR';

export const PERSON_EDIT_REQUESTING = 'PERSON_EDIT_REQUESTING';
export const PERSON_EDIT_SUCCESS = 'PERSON_EDIT_SUCCESS';
export const PERSON_EDIT_ERROR = 'PERSON_EDIT_ERROR';

export const PERSON_DELETE_REQUESTING = 'PERSON_DELETE_REQUESTING';
export const PERSON_DELETE_SUCCESS = 'PERSON_DELETE_SUCCESS';
export const PERSON_DELETE_ERROR = 'PERSON_DELETE_ERROR';

export const HANDICAP_INFO_GET_REQUESTING = 'HANDICAP_INFO_GET_REQUESTING';
export const HANDICAP_INFO_GET_SUCCESS = 'HANDICAP_INFO_GET_SUCCESS';
export const HANDICAP_INFO_GET_ERROR = 'HANDICAP_INFO_GET_ERROR';

export const EDIT_HANDICAP_OVERRIDE_REQUESTING = 'EDIT_HANDICAP_OVERRIDE_REQUESTING';
export const EDIT_HANDICAP_OVERRIDE_SUCCESS = 'EDIT_HANDICAP_OVERRIDE_SUCCESS';
export const EDIT_HANDICAP_OVERRIDE_ERROR = 'EDIT_HANDICAP_OVERRIDE_ERROR';

export const RESET_PERSON_STATE = 'RESET_PERSON_STATE';