import { fork, take } from 'redux-saga/effects';
import { AUTH_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { APIRoute } from '@src/assets/config';
import {
  CLUB_SERVICE_ADD_REQUESTING,
  CLUB_SERVICE_DELETE_REQUESTING,
  CLUB_SERVICE_EDIT_REQUESTING,
  CLUB_SERVICE_GET_REQUESTING
} from '@src/store/clubService/constants';
import { CLUB_SERVICES_RESET } from '@src/store/clubServices/constants';

/*
  GET|POST|PUT|DELETE /admin/1/clubservice/<id>
 */
const clubServiceCRUDFlow = (clubId?: number) => {
  return SagaFlowFactory.createCRUDFlow({
    apiRoute: APIRoute.CLUB_SERVICE,
    typePrefix: 'CLUB_SERVICE',
    resetType: CLUB_SERVICES_RESET,
    options: {
      resetParams: {
        clubId: clubId
      }
    }
  });
};

function * clubServiceWatcher() {
  let token: string = '';

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
      clubServiceId,
      params = {},
    } = yield take([
      CLUB_SERVICE_GET_REQUESTING,
      CLUB_SERVICE_ADD_REQUESTING,
      CLUB_SERVICE_EDIT_REQUESTING,
      CLUB_SERVICE_DELETE_REQUESTING
    ]);

    switch (type) {
      case CLUB_SERVICE_GET_REQUESTING:
        yield fork(clubServiceCRUDFlow(params.clubId), token, 'GET', clubServiceId, params);
        break;
      case CLUB_SERVICE_ADD_REQUESTING:
        yield fork(clubServiceCRUDFlow(params.clubId), token, 'ADD', null, params);
        break;
      case CLUB_SERVICE_EDIT_REQUESTING:
        yield fork(clubServiceCRUDFlow(params.clubId), token, 'EDIT', clubServiceId, params);
        break;
      case CLUB_SERVICE_DELETE_REQUESTING:
        yield fork(clubServiceCRUDFlow(params.clubId), token, 'DELETE', clubServiceId, params);
        break;
      default:
    }
  }
}

export default clubServiceWatcher;