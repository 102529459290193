import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import ScoreAdjustmentsRow from '@src/components/scenes/member/components/ScoreAdjustmentsRow';
import { pick } from 'lodash';

const scoreAdjustmentValuesToShow = [
  'exceptionalScore',
  'handicapReview',
  'penaltyScore',
];

interface OwnProps {
  adjustments?: ScoreAdjustment[];
  inheritedAdjustments?: ScoreAdjustment[];
  readonly?: boolean;
}

type Props = OwnProps;

function ScoreAdjustments({
  inheritedAdjustments= [],
  readonly = false,
  ...restProps
}: Props) {
  const adjustments = (restProps.adjustments && restProps.adjustments.length)
    ? restProps.adjustments
    : [{ exceptionalScore: 0, handicapReview: 0, penaltyScore: 0, id: 0 }];

  const [rootAdjustment, ...rest] = adjustments;
  const rootValues = pick(rootAdjustment, scoreAdjustmentValuesToShow);

  const _renderRootAdjustmentValues = () => {
    if (!rootAdjustment) {
      return (
        <TableRow>
          <TableCell colSpan={4}>
            Root adjustment data missing.
          </TableCell>
        </TableRow>
      );
    }
    return Object.keys(rootValues).map(valueKey => (
      <ScoreAdjustmentsRow
        key={valueKey}
        type={valueKey}
        adjustmentId={rootAdjustment.id}
        value={rootValues[valueKey]}
        previousAffectedScoresAmount={rootAdjustment.previousAffectedScoresAmount}
        resetLowHandicapIndex={rootAdjustment.resetLowHandicapIndex}
        disableEdit={readonly}
      />
    ));
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell align={'center'}>
            <FormattedMessage id={'strings.value'} />
          </TableCell>
          <TableCell style={{ padding: 0 }} />
          <TableCell />
        </TableRow>
      </TableHead>

      <TableBody>
        {_renderRootAdjustmentValues()}
        {[...rest, ...inheritedAdjustments].map(adjustment => {
          const values = pick(adjustment, scoreAdjustmentValuesToShow);
          return Object.keys(values).map(valueKey => (
            values[valueKey] ? (
              <ScoreAdjustmentsRow
                key={valueKey}
                type={valueKey}
                value={values[valueKey]}
                disableEdit={true}
                originScoreId={adjustment.idWhsAcceptableScoreOrigin}
              />
            ) : null
          ));
        })}
      </TableBody>
    </Table>
  );
}

export default ScoreAdjustments;
