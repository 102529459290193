/**
 * A person comparison function for sort() with nameLast ASC, nameFirst ASC, idPerson ASC ordering.
 *
 * @param a The first person for comparison
 * @param b The second person for comparison
 * @returns Number Negative when a < b, zero when a == b, positive when a > b
 */
export const personCompare = (a: Person, b: Person, l?: string): number => {
  let c: number = !a.nameLast ? !b.nameLast ? 0 : -1 : a.nameLast.localeCompare(b.nameLast, l);
  if (c === 0) {
    c = !a.nameFirst ? !b.nameFirst ? 0 : -1 : a.nameFirst.localeCompare(b.nameFirst, l);
    if (c === 0) {
      c = b.idPerson - a.idPerson;
    }
  }
  return c;
};
