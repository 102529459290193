import React, { PureComponent, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import Select from '@src/components/forms/Select';
import { connect } from 'react-redux';
import * as clubActions from '@src/store/club/actions';
import { PropTypes } from '@material-ui/core';
import Margin = PropTypes.Margin;

interface Props {
  clubId: number;
  margin?: Margin;
  clubState: ClubState;
  fetchClubFunctionaries: (params: FetchClubFunctionaries) => any;
  selectedFunctionaries?: Array<Functionary> | Functionary | undefined;
  multi?: boolean;
  helpText?: React.ReactNode;
  error?: boolean;
  onChangeCallback?(functionary: Functionary): void;
}

class FunctionarySelectConnected extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  UNSAFE_componentWillMount(): void {
    const {
      fetchClubFunctionaries,
      clubId
    } = this.props;

    fetchClubFunctionaries({
      clubId: clubId
    });
  }

  render() {
    const {
      clubState: {
        functionaries
      },
      selectedFunctionaries,
      multi,
      helpText,
      margin,
      error,
    } = this.props;

    const labelText = (): ReactNode => {
      return (
        <FormattedMessage
          id={multi ? 'forms.clubFunctionarySelect.multi.label' : 'forms.clubFunctionarySelect.single.label'}
        />
      );
    };

    const placeholderText = (): ReactNode => {
      return (
        <FormattedMessage
          id={multi ?
            'forms.clubFunctionarySelect.multi.placeholder' :
            'forms.clubFunctionarySelect.single.placeholder'
          }
        />
      );
    };

    return (
      <Select
        error={error}
        margin={margin}
        options={functionaries}
        selected={selectedFunctionaries}
        multiSelect={multi}
        onChangeCallback={this._handleChange}
        labelText={labelText()}
        placeholderText={placeholderText()}
        helpText={helpText}
      />
    );
  }

  private _handleChange = (value: Functionary) => {
    const { onChangeCallback } = this.props;
    if (onChangeCallback) {
      onChangeCallback(value);
    }
  }
}

const ClubFunctionarySelect = connect((state: any) => ({
  clubState: state.clubReducer
}), {
  fetchClubFunctionaries: clubActions.fetchClubFunctionaries,
})(FunctionarySelectConnected);

export {
  ClubFunctionarySelect
};