import React, { ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import { connect } from 'react-redux';
import {
  ContentLoader,
  ContentHeader,
  ContentHeaderTitle,
  ContentWrap, renderListItem, ListItemType
} from '@src/components/layouts/ui';
import {
  Card,
  CardContent,
  CardHeader,
  List,
  withStyles,
  Typography,
  Theme, WithStyles, createStyles,
} from '@material-ui/core';
import {
  FunctionaryCardItem,
  functionaryVisibleInGuides
} from '@src/components/scenes/club/components/FunctionaryCardItem';
import { formatAddressGMapsUrl, formatGMapsStaticImageURL } from '@src/components/maps';
import classNames from 'classnames';
import { cleanTrailingSlash, formatURLProtocol } from '@src/utils/storeUtils';
import ClubSubNavigation from '@src/components/scenes/club/components/ClubSubNavigation';
import { EditIconFab } from '@src/components/buttons/buttons';
import { FormattedMessage } from 'react-intl';
import ClubBasicInfoModal from '@src/components/scenes/club/club-modals/ClubBasicInfoModal';
import ClubVisitorGuideModal from '@src/components/scenes/club/club-modals/ClubVisitorGuideModal';
import { isCustomContext, formCustomImageURL, URL_LOGO, URL_LOGO_2X } from '@src/assets/config';
import { fetchClubCourseGuide } from '@src/store/club/actions';
import { functionaryCompareWithPriority } from '@src/utils/FunctionaryUtils';

const styles = ({ palette }: Theme) => createStyles({
  topHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  twoColWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& > div': {
      width: '48%',
    },
    '@global': {
      '.functionary-card-item': {
        marginBottom: 30,
      },
      '.card-item': {
        marginBottom: 30,
      },
      '.card-item .card-item': {
        marginBottom: 15,
      },
    }
  },
  card: {
    minWidth: 275,
    marginBottom: 30,
    '@global': {
      'a': {
        color: palette.primary.main,
      },
    },
  },
});

interface State {
  basicInfoModalOpen: boolean;
  extraInfoModalOpen: boolean;
}

interface StateProps {
  clubState: ClubState;
}

interface DispatchProps {
  fetchClubCourseGuide(params: FetchClubCourseGuide): void;
}

type Props = StateProps & DispatchProps & RouteComponentProps<any> & WithStyles<typeof styles>;

class Club extends React.Component<Props, State> {
  readonly state: State = {
    basicInfoModalOpen: false,
    extraInfoModalOpen: false,
  };

  componentDidMount() {
    const {
      clubState: {
        club,
      },
      match: {
        params
      },
      fetchClubCourseGuide,
    } = this.props;

    const { clubId } = params;

    if (club && club.id === clubId) {
      return true;
    }

    return fetchClubCourseGuide({ clubId });
  }

  render() {
    const {
      clubState: {
        requesting,
        courseGuide
      },
      classes,
    } = this.props;

    let content: ReactNode = null;

    if (requesting) {
      content = <ContentLoader visible={true}/>;
    } else if (courseGuide) {
      const {
        match: {
          url
        },
      } = this.props;

      const {
        club,
      } = courseGuide;

      content = (
        <>
          <div className={classes.topHeader}>
            <ContentHeader>
              {club && <ContentHeaderTitle>{club.name} ({club.abbreviation})</ContentHeaderTitle>}
              <ClubSubNavigation url={url} />
            </ContentHeader>
            {isCustomContext && (
              <img
                src={formCustomImageURL(URL_LOGO)}
                srcSet={`${formCustomImageURL(URL_LOGO_2X)} 2x`}
                height={65}
              />
            )}
          </div>
          <div className={classes.twoColWrap}>
            {this._renderAddressCard()}
            {this._renderMapLocationCard()}
          </div>
          {false && this._renderVisitorsGuide()}
          {false && this._renderContactInfoCard()}
          {this._renderClubAndCourseInfoCard()}
        </>
      );
    }

    return (
      <>
        <HeadHelmet titleId={'navigation.golfClub'}/>
        <ContentWrap>
          {/*
          <Button variant="contained">
            <FormattedMessage id={'buttons.edit'}/>
          </Button>
          <Button variant="contained">
            <FormattedMessage id={'buttons.print'}/>
          </Button>
          */}
          {content}
        </ContentWrap>

        <ClubBasicInfoModal
          open={this.state.basicInfoModalOpen}
          onClose={this.setState.bind(this, { basicInfoModalOpen: false })}
        />

        <ClubVisitorGuideModal
          open={this.state.extraInfoModalOpen}
          onClose={this.setState.bind(this, { extraInfoModalOpen: false })}
        />
      </>
    );
  }

  private _renderAddressCard = (): ReactNode => {
    const {
      clubState: {
        courseGuide
      },
    } = this.props;

    if (courseGuide) {
      const {
        extraInfo,
        club
      } = courseGuide;

      let listItems: ListItemType[] = [];

      if (club && club.streetAddress && club.streetAddress !== '') {
        listItems.push({
          label: 'strings.address',
          value: `${club.streetAddress}, ${club.zip} ${club.city}`
        });
      }

      if (extraInfo && extraInfo.drivingInstructions && extraInfo.drivingInstructions !== '') {
        listItems.push({
          label: 'strings.drivingInstructions',
          value: extraInfo.drivingInstructions
        });
      }

      if (club && club.homepage && club.homepage !== '') {
        listItems.push({
          label: 'strings.homepage',
          value: <a href={formatURLProtocol(club.homepage, 'http')} target={'_blank'}>{club.homepage}</a>
        });
      }

      return this._renderCard(
        (<FormattedMessage id={'navigation.golfClubInfo'} />),
        [listItems],
        <EditIconFab onClick={() => this.setState({ basicInfoModalOpen: true })} />
      );
    }
    return null;
  }

  private _renderMapLocationCard = (): React.ReactNode => {
    const {
      clubState: {
        courseGuide
      },
      classes
    } = this.props;

    if (courseGuide) {
      const {
        club
      } = courseGuide;

      const address: string = `${club.streetAddress}, ${club.zip} ${club.city}`;

      return (
        <Card className={classes.card}>
          <a
            target={'_blank'}
            href={formatAddressGMapsUrl(address)}
            style={{
              display: 'flex',
              height: '100%',
              cursor: 'pointer',
            }}
          >
            <CardContent
              style={{
                backgroundImage: formatGMapsStaticImageURL(address),
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                width: '100%',
              }}
            />
          </a>
        </Card>
      );
    }
    return null;
  }

  private _renderVisitorsGuide = (): ReactNode => {
    const {
      clubState: {
        courseGuide
      },
      classes
    } = this.props;

    if (courseGuide) {
      const {
        fees,
        services,
        extraInfo,
        club
      } = courseGuide;

      return (
        <Card className={classes.card}>
          <CardHeader
            title={<FormattedMessage id={'strings.visitorsGuide'} />}
            action={(<EditIconFab onClick={() => this.setState({ extraInfoModalOpen: true })}/>)}
          />
          <CardContent className={classes.twoColWrap}>
            {club &&
            <div className={'card-item'}>
              <Typography variant="h6" gutterBottom={true}>
                <FormattedMessage id={'strings.caddiemaster'} />
              </Typography>
              {club.phone && <Typography gutterBottom={true}>{club.phone}</Typography>}
              {club.email && <Typography gutterBottom={true}>{club.email}</Typography>}
              {club.fax && <Typography gutterBottom={true}>{club.fax}</Typography>}
            </div>
            }

            {extraInfo && extraInfo.requiredHcp &&
            <div className={'card-item'}>
                <Typography variant="h6" gutterBottom={true}>
                  <FormattedMessage id={'strings.hcpRequirement'} />
                </Typography>
                <Typography gutterBottom={true}>{extraInfo.requiredHcp}</Typography>
            </div>
            }

            {fees && fees.length > 0 &&
            <div className={'card-item'}>
              <Typography variant="h6" gutterBottom={true}>
                <FormattedMessage id={'navigation.golfClubPayments'} />
              </Typography>
              {fees.map((fee: ClubFee) =>
                <div className={'card-item'} key={fee.id}>
                  <Typography variant="subtitle1" gutterBottom={true}>{fee.name} ({fee.price} €)</Typography>
                  {fee.description && <Typography gutterBottom={true}>{fee.description}</Typography>}
                </div>
              )}
            </div>
            }

            {extraInfo &&
            <div className={'card-item'}>
              {extraInfo.golfCarts && extraInfo.golfCarts !== '' &&
              <div className={'card-item'}>
                <Typography variant="h6" gutterBottom={true}>
                  <FormattedMessage id={'strings.carts'} />
                </Typography>
                <Typography gutterBottom={true}>{extraInfo.golfCarts}</Typography>
              </div>
              }

              {extraInfo.rentalClubs && extraInfo.rentalClubs !== '' &&
              <div className={'card-item'}>
                <Typography variant="h6" gutterBottom={true}>
                  <FormattedMessage id={'strings.rentalClubs'} />
                </Typography>
                <Typography gutterBottom={true}>{extraInfo.rentalClubs}</Typography>
              </div>
              }

              {extraInfo.steelSpikes && extraInfo.steelSpikes !== '' &&
              <div className={'card-item'}>
                <Typography variant="h6" gutterBottom={true}>
                  <FormattedMessage id={'strings.steelSpikes'} />
                </Typography>
                <Typography gutterBottom={true}>{extraInfo.steelSpikes}</Typography>
              </div>
              }

              {extraInfo.trainingPossibilities && extraInfo.trainingPossibilities !== '' &&
              <div className={'card-item'}>
                <Typography variant="h6" gutterBottom={true}>
                  <FormattedMessage id={'strings.trainingPossibilities'} />
                </Typography>
                <Typography gutterBottom={true}>{extraInfo.trainingPossibilities}</Typography>
              </div>
              }
            </div>
            }

            {services && services.length > 0 &&
            <div className={'card-item'}>
              <Typography variant="h6" gutterBottom={true}>
                <FormattedMessage id={'navigation.golfClubServices'} />
              </Typography>
              {services.map((service: ClubService) =>
                <div className={'card-item'} key={service.id}>
                  <Typography variant="subtitle1" gutterBottom={true}>{service.name}</Typography>
                  {service.description && <Typography gutterBottom={true}>{service.description}</Typography>}
                  {service.phone && <Typography gutterBottom={true}>{service.phone}</Typography>}
                  {service.email && <Typography gutterBottom={true}>{service.email}</Typography>}
                </div>
              )}
            </div>
            }

            {extraInfo && extraInfo.nearestHotel && extraInfo.nearestHotel !== '' &&
            <div className={'card-item'}>
              <Typography variant="h6" gutterBottom={true}>
                <FormattedMessage id={'strings.nearestHotel'} />
              </Typography>
              <Typography gutterBottom={true}>{extraInfo.nearestHotel}</Typography>
            </div>
            }
          </CardContent>
        </Card>
      );
    }
    return null;
  }

  private _renderContactInfoCard = (): ReactNode => {
    const {
      clubState: {
        courseGuide
      },
      classes
    } = this.props;

    if (courseGuide) {
      const {functionaries} = courseGuide;

      return (
        <Card className={classes.card}>
          <CardHeader
            title={<FormattedMessage id={'scenes.golfFederation.info.contacts'}/>}
            action={(
              <EditIconFab
                onClick={() => {
                  const {
                    history,
                    match: {
                      url,
                    },
                  } = this.props;
                  history.push(cleanTrailingSlash(url, '/functionaries'));
                }}
              />
            )}
          />
          <CardContent className={classes.twoColWrap}>{
            functionaries && functionaries
              .filter(functionaryVisibleInGuides)
              .sort(functionaryCompareWithPriority)
              .map(FunctionaryCardItem)
          }
          </CardContent>
        </Card>
      );
    }
    return null;
  }

  private _renderClubAndCourseInfoCard = (): ReactNode => {
    const {
      clubState: {
        courseGuide,
      }
    } = this.props;

    if (courseGuide) {
      const {
        club,
        extraInfo,
        memberCount,
        courses,
      } = courseGuide;

      let listItems: ListItemType[] = [];
      if (extraInfo) {
        listItems.push({
          label: 'strings.founded',
          value: extraInfo.yearFoundation ? String(extraInfo.yearFoundation) : '-'
        });
      }

      if (memberCount) {
        listItems.push({
          label: 'strings.memberCount',
          value: memberCount ? String(memberCount) : '-'
        });
      }

      if (club) {
        listItems.push({
          label: 'strings.companyName',
          value: club.nameCompany ? club.nameCompany : '-'
        });
      }

      if (extraInfo && extraInfo.rentalPlayingRights) {
        listItems.push({
          label: 'strings.rentalPlayingRights',
          value: extraInfo.rentalPlayingRights,
        });
      }

      let coursesList: ListItemType[] = courses.map((course: ClubCourse) => ({
        label: '',
        labelComp: (<FormattedMessage id={'strings.courseArchitect'} values={{ designerName: course.designerName }}/>),
        value: course.name,
      }));

      return this._renderCard(
        (<FormattedMessage id={'strings.clubAndCourses'} />),
        [listItems, coursesList],
        <EditIconFab onClick={() => this.setState({ basicInfoModalOpen: true })} />
      );
    }

    return null;
  }

  private _renderCard = (title: any, data: ListItemType[][], titleAction?: ReactNode): ReactNode => {
    const {classes} = this.props;

    return (
      <Card className={classes.card}>
        {title && title !== '' && <CardHeader title={title} action={titleAction} />}
        <CardContent className={classNames({ [`${classes.twoColWrap}`]: data.length > 1 })}>
          {data.map((list: ListItemType[], idx: number) => (
            <div className={'card-item'} key={idx}>
              <List>
                {list.map(renderListItem)}
              </List>
            </div>
          ))}
        </CardContent>
      </Card>
    );
  }
}

export default withRouter(withStyles(styles)(connect<StateProps, DispatchProps, {}, StoreState>(state => ({
  clubState: state.clubReducer,
}), {
  fetchClubCourseGuide
})(Club)));