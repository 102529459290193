import React, { PureComponent, Fragment, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Button
} from '@material-ui/core';
import {
  ContentHeader,
  ContentHeaderTitle,
  ContentLoader,
  ContentWrap
} from '@src/components/layouts/ui';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import { connect } from 'react-redux';
import * as functionaryTitlesActions from '@src/store/functionarytitles/actions';
import { DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import { Pagination } from '@src/components/pagination/Pagination';
import { FunctionaryTitleModal } from '@src/components/scenes/federation/functionaryTitle/FunctionaryTitleModal';
import { targetIdParamsChanged } from '@src/utils/storeUtils';
import { EditIconButton } from '@src/components/buttons/buttons';
import { functionaryTitleCompare } from '@src/utils/FunctionaryTitleUtils';
import RoleScopeGuard from '@src/components/access-control/RoleScopeGuard';

type Props = {
  targetIdParams: TargetIdParams;
  functionaryTitlesState: FunctionaryTitlesState;
  setTargetIdParams: (params: SetTargetIdParams) => any;
  fetchFunctionaryTitles: (params: TablePaginationAction) => any;
  changePage: (params: ChangePageAction) => any;
  locale: AppLocale;
};

type State = {
  modalOpen: boolean;
  functionaryTitleIdToEdit?: number;
};

class FunctionaryTitlesScene extends PureComponent<Props, State> {
  state = {
    modalOpen: false,
    functionaryTitleIdToEdit: undefined
  };

  UNSAFE_componentWillMount() {
    const {
      functionaryTitlesState: {
        requesting,
        successful,
        targetIdParams
      },
      fetchFunctionaryTitles,
      setTargetIdParams,
    } = this.props;

    if (targetIdParamsChanged(targetIdParams, this.props.targetIdParams)) {
      setTargetIdParams({
        resetState: true,
        targetIdParams: this.props.targetIdParams,
      });
      fetchFunctionaryTitles({
        page: DEFAULT_PAGINATION_PAGE,
      });
    } else if (!requesting && !successful) {
      fetchFunctionaryTitles({
        page: DEFAULT_PAGINATION_PAGE,
      });
    }
  }

  render() {
    const {
      modalOpen,
      functionaryTitleIdToEdit
    } = this.state;

    const {
      functionaryTitlesState: {
        requesting
      },
      targetIdParams
    } = this.props;

    return (
      <Fragment>
        <HeadHelmet titleId={'navigation.functionaryTitles'} />
        <ContentWrap>
          <ContentHeader>
            <ContentHeaderTitle>
              <FormattedMessage id={'navigation.functionaryTitles'} />
            </ContentHeaderTitle>
            <Button
              variant="contained"
              onClick={() => this.setState({functionaryTitleIdToEdit: undefined, modalOpen: true})}
            >
              <FormattedMessage id={'scenes.functionaryTitles.addNewButtonLabel'} />
            </Button>
          </ContentHeader>

          <ContentLoader visible={requesting}/>
          {this._renderFunctionaryTitles()}

          <FunctionaryTitleModal
            functionaryTitleId={functionaryTitleIdToEdit}
            open={modalOpen}
            params={{...targetIdParams}}
            onClose={() => {
              this.setState({modalOpen: false});
            }}
          />
        </ContentWrap>
      </Fragment>
    );
  }

  private _renderFunctionaryTitles = (): ReactNode => {
    const {
      functionaryTitlesState: {
        requesting,
        successful,
        pagedTitles,
        pagination: {
          page
        }
      }
    } = this.props;

    if (requesting) {
      return null;
    }

    return (
      <>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><FormattedMessage id={'strings.functionaryTitle'}/></TableCell>
              <TableCell><FormattedMessage id={'strings.description'}/></TableCell>
              <TableCell><FormattedMessage id={'strings.state'}/></TableCell>
              <TableCell><FormattedMessage id={'strings.global'}/></TableCell>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {successful &&
            pagedTitles[page] &&
            Object.keys(pagedTitles[page]).sort((a, b): number => {
              return functionaryTitleCompare(
                  pagedTitles[page][a],
                  pagedTitles[page][b],
                  this.props.locale.appLanguage.langName
              );
            }).map((key: string) => (
              <TableRow key={pagedTitles[page][key].id}>
                <TableCell>{pagedTitles[page][key].name}</TableCell>
                <TableCell>{pagedTitles[page][key].description}</TableCell>
                <TableCell>{pagedTitles[page][key].status}</TableCell>
                <TableCell>{pagedTitles[page][key].globalFor?.join(', ')}</TableCell>
                <TableCell size={'small'} align="right">
                  <RoleScopeGuard>
                    {({ isFederationScope, isClubScope }) =>
                        Boolean((isClubScope && !pagedTitles[page][key].globalForClubs)  || isFederationScope) && (
                            <EditIconButton
                                onClick={() => this.setState({
                                  functionaryTitleIdToEdit: pagedTitles[page][key].id,
                                  modalOpen: true,
                                })}
                            />
                        )}
                  </RoleScopeGuard>

                </TableCell>
              </TableRow>
            ))
            }
          </TableBody>
        </Table>

        {this._renderPagination()}
      </>
    );
  }

  private _renderPagination = (): ReactNode => {
    const {
      functionaryTitlesState: {
        pagination: {
          page,
          limit,
          totalCount
        }
      }
    } = this.props;

    if (totalCount > limit) {
      return <Pagination page={page} limit={limit} totalCount={totalCount} onPageChange={this._handlePageChange}/>;
    }

    return null;
  }

  private _handlePageChange = (page: number) => {
    const {
      functionaryTitlesState: {
        pagedTitles
      },
      fetchFunctionaryTitles,
      changePage,
      targetIdParams
    } = this.props;

    /*
      If the page was already fetched we don't need to get it again.
      Just dispatch an action to chage page.
     */
    if (pagedTitles[page]) {
      changePage({ page, params: {...targetIdParams} });
    } else {
      fetchFunctionaryTitles({
        page,
        params: {
          ...targetIdParams
        }
      });
    }
  }
}

export default connect((state: StoreState) => ({
  functionaryTitlesState: state.functionaryTitlesReducer,
  locale: state.locale
}), {
  setTargetIdParams: functionaryTitlesActions.setTargetIdParams,
  fetchFunctionaryTitles: functionaryTitlesActions.fetchFunctionaryTitles,
  changePage: functionaryTitlesActions.changePage,
})(FunctionaryTitlesScene);
