import React, { Fragment, PureComponent } from 'react';
import { UserSettingsForm } from '@src/components/forms/UserSettingsForm';
import { ContentLoader, ContentWrap } from '@src/components/layouts/ui';
import { connect } from 'react-redux';
import * as userActions from '@src/store/user/actions';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import { RouteComponentProps, withRouter } from 'react-router';

interface Props extends RouteComponentProps<any> {
  auth: AuthState;
  user: UserState;
  getUser: (params: UserGet) => any;
}

interface State {

}

class SettingsSceneConnected extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    if (props.auth) {
      props.getUser({
        id: props.auth.id as number,
      });
    }
  }

  render() {
    const {
      auth: {
        id,
      },
      user: {
        requesting,
      },
    } = this.props;

    return (
      <Fragment>
        <HeadHelmet titleId={'navigation.settings'} />
        <ContentWrap>
          <ContentLoader visible={requesting} />
          {!requesting &&
          <UserSettingsForm
            userId={id}
          />
          }
        </ContentWrap>
      </Fragment>
    );
  }
}

const SettingsScene = withRouter(connect((state: any) => ({
  auth: state.authReducer,
  user: state.userReducer,
}), {
  getUser: userActions.getUser
})(SettingsSceneConnected));

export {
  SettingsScene
};