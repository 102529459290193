import React from 'react';
import { Clubs } from '@src/components/scenes/clubs/Clubs';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import AdminClub from '@src/scenes/admin/AdminClub';
import ClubBasicInfo from '@src/components/scenes/club/ClubBasicInfo';
import ClubPlayers from '@src/components/scenes/club/ClubPlayers';
import { ClubCourse } from '@src/components/scenes/club/ClubCourse';
import ClubCourses from '@src/components/scenes/club/ClubCourses';
import ClubFunctionaries from '@src/components/scenes/club/ClubFunctionaries';
import ClubFunctionaryTitles from '@src/components/scenes/club/ClubFunctionaryTitles';
import ClubOrganizations from '@src/components/scenes/club/ClubOrganizations';
import ClubFees from '@src/components/scenes/club/ClubFees';
import ClubServices from '@src/components/scenes/club/ClubServices';
import ClubGreenCard from '@src/components/scenes/club/ClubGreenCard';
import { withBreadcrumb } from '@src/components/layouts/ui';
import ClubAddNewFunctionary from '@src/components/scenes/club/ClubAddNewFunctionary';
import Member from '@src/components/scenes/member/Member';
import { CaddyMessages } from '@src/scenes/caddy/CaddyMessages';
import { CaddyOffers } from '@src/scenes/caddy/CaddyOffers';

export const ClubSubRoutes = (props: RouteComponentProps<{}>) => {
  const {
    match: {
      path,
    },
  } = props;

  return (
    <Route
      path={path}
      render={() => (
        <Switch>
          <Route path={`${path}/:clubId/basic-info`} component={withBreadcrumb(ClubBasicInfo)} />
          <Route path={`${path}/:clubId/members/:memberId`} component={withBreadcrumb(Member)} />
          <Route path={`${path}/:clubId/members`} component={withBreadcrumb(ClubPlayers)} />
          <Route path={`${path}/:clubId/courses/:courseId`} component={withBreadcrumb(ClubCourse)} />
          <Route path={`${path}/:clubId/courses`} component={withBreadcrumb(ClubCourses)} />
          <Route
            path={`${path}/:clubId/functionaries/add-functionary`}
            component={withBreadcrumb(ClubAddNewFunctionary)}
          />
          <Route path={`${path}/:clubId/functionaries/titles`} component={withBreadcrumb(ClubFunctionaryTitles)} />
          <Route path={`${path}/:clubId/functionaries`} component={withBreadcrumb(ClubFunctionaries)} />
          <Route path={`${path}/:clubId/organizations`} component={withBreadcrumb(ClubOrganizations)} />
          <Route path={`${path}/:clubId/payments`} component={withBreadcrumb(ClubFees)} />
          <Route path={`${path}/:clubId/services`} component={withBreadcrumb(ClubServices)} />
          <Route path={`${path}/:clubId/green-card`} component={withBreadcrumb(ClubGreenCard)} />
          <Route path={`${path}/:clubId/messages`} component={withBreadcrumb(CaddyMessages)} />
          <Route path={`${path}/:clubId/offers`} component={withBreadcrumb(CaddyOffers)} />
          <Route path={`${path}/:clubId`} component={withBreadcrumb(AdminClub)} />
        </Switch>
      )}
    />
  );
};

export default withRouter((props: RouteComponentProps<{}>) => {
  const {
    match: {
      path,
    },
  } = props;

  return (
    <Switch>
      <Route
        path={path}
        exact={true}
        render={() => (<Clubs />)}
      />
      <ClubSubRoutes {...props} />
    </Switch>
  );
});