import React from 'react';
import { RouteComponentProps, Switch, withRouter } from 'react-router';
import { ClubSubRoutes } from '@src/scenes/admin/AdminClubs';

interface Props extends RouteComponentProps {}
interface State {}

class HomeClubRoutes extends React.Component<Props, State> {
  render(): React.ReactNode {
    return (
      <Switch>
        <ClubSubRoutes {...this.props} />
      </Switch>
    );
  }
}

export default withRouter(HomeClubRoutes);