import { call, select } from 'redux-saga/effects';
import { userAuthToken } from '@src/store/auth/selectors';
import { tokenAPIPut } from '@src/utils/storeUtils';
import { API_ROOT, APIRoute } from '@src/assets/config';
import { put } from '@redux-saga/core/effects';
import { ChangeCourseStateAction } from '@src/store/clubCourse/actions';
import { handleOnFunction } from '@src/utils/utils';
import { CHANGE_COURSE_STATE_ERROR, CHANGE_COURSE_STATE_SUCCESS } from '@src/store/clubCourse/constants';

export function * courseStateChangeFlow({
  courseId,
  status,
  onComplete,
}: ChangeCourseStateAction) {
  try {
    const token = yield select(userAuthToken);
    const data = yield call(() =>
      tokenAPIPut(`${API_ROOT}${APIRoute.setCourseStatus(courseId, status)}`, token)
    );

    handleOnFunction(onComplete, { data });
    yield put({ type: CHANGE_COURSE_STATE_SUCCESS, data });
  } catch (error) {
    handleOnFunction(onComplete, { error });
    yield put({ type: CHANGE_COURSE_STATE_ERROR, error });
  }
}
