import { call, put } from 'redux-saga/effects';
import { API_ROOT, APIRoute } from '@src/assets/config';
import { tokenAPIGet } from '@src/utils/storeUtils';
import { MESSAGES_FETCH_ALL_SENDERS_ERROR, MESSAGES_FETCH_ALL_SENDERS_SUCCESS } from '@src/store/messages/constants';

/*
  GET /admin/1/messages/senders
 */
export function* fetchAllSendersFlow(token: string) {
  try {
    const response = yield call(() => {
      const url = `${API_ROOT}${APIRoute.GET_MESSAGE_SENDERS}`;
      return tokenAPIGet(url, token);
    });

    if (response) {
      const { senders } = response;
      yield put({
        type: MESSAGES_FETCH_ALL_SENDERS_SUCCESS,
        senders,
      });
    }
  } catch (error) {
    yield put({ type: MESSAGES_FETCH_ALL_SENDERS_ERROR, error });
  }
}