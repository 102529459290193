import React, { Component } from 'react';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { AdminMessages } from '@src/scenes/admin/AdminMessages';
import { AdminOffers } from '@src/scenes/admin/AdminOffers';
import AdminClubs from '@src/scenes/admin/AdminClubs';
import { Partners } from '@src/scenes/admin/Partners';
import { Users } from '@src/scenes/admin/Users';
import { AdminSettings } from '@src/scenes/admin/AdminSettings';
import { AdminMembers } from '@src/scenes/admin/AdminMembers';
import AdminMember from '@src/scenes/admin/AdminMember';
import { AdminFederationBasicInfo } from '@src/scenes/admin/federation/AdminFederationBasicInfo';
import FederationFunctionaryTitles from '@src/components/scenes/federation/FederationFunctionaryTitles';
import { AdminFederationCompanies } from '@src/scenes/admin/federation/AdminFederationCompanies';
import { AdminFederationVendors } from '@src/scenes/admin/federation/AdminFederationVendors';
import FederationFunctionaries from '@src/components/scenes/federation/FederationFunctionaries';
import FederationOrganizations from '@src/components/scenes/federation/FederationOrganizations';
import { withBreadcrumb } from '@src/components/layouts/ui';
import FederationAddNewFunctionary from '@src/components/scenes/federation/FederationAddNewFunctionary';
import AdminProblematicMembers from '@src/scenes/admin/AdminProblematicMembers';
import HomeClubRoutes from '@src/scenes/home-club/HomeClubRoutes';
import * as loginActions from '@src/store/login/actions';
import { connect } from 'react-redux';
import { userAuthToken } from '@src/store/auth/selectors';
import { LoginCheckRequest } from '@src/store/login/actions';
import ReportsRoot from '@src/components/scenes/reports/ReportsRoot';
import Notifier from '@src/components/layouts/Notifier';
import { Maintenances } from '@src/scenes/admin/Maintenances';

interface StateProps {
  authToken?: string;
}

interface DispatchProps {
  silentLoginCheck(args: LoginCheckRequest): any;
}

type Props = StateProps & DispatchProps & RouteComponentProps<any>;

class UnroutedAdminIndex extends Component<Props> {
  componentDidMount(): void {
    window.addEventListener('focus', this._checkLoggedInOnFocus);
  }

  componentWillUnmount(): void {
    window.removeEventListener('focus', this._checkLoggedInOnFocus);
  }

  render() {
    const {
      match: {
        path,
      }
    } = this.props;

    return (
      <>
        <Notifier />

        <Switch>
          <Redirect from={`${path}`} to={`${path}/users`} exact={true} />
          <Route
            path={`${path}/members/problematic-members/:memberId/:clubId`}
            component={withBreadcrumb(AdminMember)}
          />
          <Route path={`${path}/members/problematic-members`} component={withBreadcrumb(AdminProblematicMembers)} />
          <Route path={`${path}/members/:memberId/:clubId`} component={withBreadcrumb(AdminMember)} />
          <Route path={`${path}/members`} component={AdminMembers} />
          <Route path={`${path}/management/users`} component={Users} />
          <Route path={`${path}/management/maintenances`} component={Maintenances} />
          <Route path={`${path}/partners`} component={Partners} />
          <Route path={`${path}/offers`} component={AdminOffers} />
          <Route path={`${path}/messages`} component={AdminMessages} />
          <Route path={`${path}/settings`} component={AdminSettings} />
          <Route path={`${path}/federation/basic-info`} component={AdminFederationBasicInfo} />
          <Route
            path={`${path}/federation/functionaries/titles`}
            component={withBreadcrumb(FederationFunctionaryTitles)}
          />
          <Route
            path={`${path}/federation/functionaries/add-functionary`}
            component={withBreadcrumb(FederationAddNewFunctionary)}
          />
          <Route path={`${path}/federation/functionaries`} component={FederationFunctionaries} />
          <Route path={`${path}/federation/organizations`} component={FederationOrganizations} />
          <Route path={`${path}/federation/companies`} component={AdminFederationCompanies} />
          <Route path={`${path}/federation/vendors`} component={AdminFederationVendors} />

          <Route path={`${path}/reports`} component={ReportsRoot} />
          <Route path={`${path}/clubs`} component={AdminClubs} />
          <Route path={`${path}/home-club`} component={HomeClubRoutes} />
        </Switch>
      </>
    );
  }

  _checkLoggedInOnFocus = () => {
    const { authToken, silentLoginCheck, history } = this.props;
    if (authToken) {
      silentLoginCheck({ token: authToken, redirect: history.push });
    }
  }
}

export const AdminIndex = withRouter(connect<StateProps, DispatchProps, {}, StoreState>(state => ({
  authToken: userAuthToken(state),
}), {
  silentLoginCheck: loginActions.silentLoginCheck,
})(UnroutedAdminIndex));