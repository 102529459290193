import { API_ROOT, APIRoute } from '@src/assets/config';
import { call, put } from 'redux-saga/effects';
import {
  formatPaginationParams,
  formatPaginationResults,
  formatURLParams,
  tokenAPIGet,
  tokenAPIPut,
  tokenAPIPost,
  tokenAPIDelete,
} from '@src/utils/storeUtils';
import { enqueueNotification } from '@src/store/notifications/actions';

const guessPagedType = (itemType: string) => {
  return `paged${itemType.charAt(0).toUpperCase() + itemType.slice(1)}`;
};

interface GETFlow {
  apiRoute?: typeof APIRoute | string;
  urlRoute?: string;
  successType: string;
  errorType: string;
}

interface PagedGETFlow {
  apiRoute?: typeof APIRoute | string;
  urlRoute?: string;
  itemType: string;
  pagedType?: string;
  successType: string;
  errorType: string;
}

interface GETUrlWithParams {
  apiRoute?: typeof APIRoute | string;
  urlRoute?: string;
  page: number;
  limit: number;
  params: any;
}

interface CRUDFlowOptions {
  resetParams?: any;
}

interface CRUDFlow {
  apiRoute: typeof APIRoute | string;
  typePrefix: string;
  resetType: string;
  options?: CRUDFlowOptions;
}

class SagaFlowFactory {

  static createGETUrlWithParams = ({
    apiRoute,
    urlRoute,
    page,
    limit,
    params,
  }: GETUrlWithParams) => {
    const base = urlRoute ? urlRoute : `${API_ROOT}${apiRoute}`;
    const paginationParams = formatPaginationParams(page, limit);
    const urlParams = formatURLParams(params, paginationParams ? undefined : '');
    const search = `${paginationParams}${urlParams !== '&' ? urlParams : ''}`;
    return `${base}${search ? '?' + search : ''}`;
  }

  static createPagedGETFlow = ({
    apiRoute,
    urlRoute,
    itemType,
    pagedType = guessPagedType(itemType),
    successType,
    errorType
  }: PagedGETFlow) => {
    return function* (token: string, page: number, limit: number, params: any = {}) {
      try {

        const data = yield call(() => {
          const url = SagaFlowFactory.createGETUrlWithParams({
            apiRoute,
            urlRoute,
            page,
            limit,
            params,
          });
          return tokenAPIGet(url, token);
        });

        if (data) {
          const { totalCount, _permissions } = data;
          const items = data[itemType];

          const formatted = formatPaginationResults(items, pagedType, page, limit, totalCount);

          yield put({
            type: successType,
            ...formatted,
            _permissions,
          });
        }

      } catch (error) {
        yield put(enqueueNotification(error));
        yield put({ type: errorType, error });
      }
    };
  }

  static createGETFlow = ({
    apiRoute,
    urlRoute,
    successType,
    errorType
  }: GETFlow) => {
    return function* (token: string, page: number = 0, limit: number = 0, params: any = {}) {
      try {
        const data = yield call(() => {
          const url = SagaFlowFactory.createGETUrlWithParams({
            apiRoute,
            urlRoute,
            page,
            limit,
            params,
          });
          return tokenAPIGet(url, token);
        });

        if (data) {
          yield put({
            type: successType,
            data
          });
        }
      } catch (error) {
        yield put(enqueueNotification(error));
        yield put({ type: errorType, error});
      }
    };
  }

  static createCRUDFlow = ({
    apiRoute,
    typePrefix,
    resetType,
    options = {},
  }: CRUDFlow) => {
    const getSuccessType = (action: string) => {
      return `${typePrefix}_${action}_SUCCESS`;
    };

    const getErrorType = (action: string) => {
      return `${typePrefix}_${action}_ERROR`;
    };

    return function* (
      token: string,
      action: 'ADD' | 'GET' | 'DELETE' | 'EDIT' | 'EDIT_POST',
      itemId: number | null,
      params: any = {}
      ) {
      try {
        let data: any;

        switch (action) {
          case 'ADD':
            data = yield call(() => { return tokenAPIPost(`${API_ROOT}${apiRoute}`, token, params); });
            break;
          case 'GET':
            data = yield call(() => {
              return tokenAPIGet(`${API_ROOT}${apiRoute}${itemId ? '/' + itemId : ''}`, token);
            });
            break;
          case 'DELETE':
            data = yield call(() => { return tokenAPIDelete(`${API_ROOT}${apiRoute}/${itemId}`, token, params); });
            break;
          case 'EDIT':
            data = yield call(() => { return tokenAPIPut(`${API_ROOT}${apiRoute}/${itemId}`, token, params); });
            break;
          case 'EDIT_POST':
            data = yield call(() => { return tokenAPIPost(`${API_ROOT}${apiRoute}/${itemId}`, token, params); });
            break;
          default:
        }

        if (data) {
          yield put({
            type: getSuccessType(action),
            data,
          });

          if (params.onComplete) {
            params.onComplete({ data });
          }

          if (action === 'ADD' || action === 'DELETE') {
            const { resetParams } = options;

            yield put({
              type: resetType,
              params: {...resetParams}
            });
          }
        }
      } catch (error) {
        if (params.onComplete) {
          params.onComplete({ error });
        }

        yield put(enqueueNotification(error));
        yield put({ type: getErrorType(action), error});
      }
    };
  }
}

export {
  SagaFlowFactory,
};