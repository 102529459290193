import { all, fork, take, takeEvery } from 'redux-saga/effects';
import { AUTH_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { APIRoute } from '@src/assets/config';
import {
  ATTACH_COURSE_REQUESTING, CHANGE_COURSE_STATE_REQUESTING,
  CLUB_COURSE_ADD_REQUESTING, CLUB_COURSE_COPY_REQUESTING,
  CLUB_COURSE_DELETE_REQUESTING,
  CLUB_COURSE_EDIT_REQUESTING,
  CLUB_COURSE_GET_REQUESTING, CLUB_COURSE_PUBLISH_REQUESTING, DETACH_COURSE_REQUESTING, GET_COURSE_PCC_REQUESTING
} from '@src/store/clubCourse/constants';
import {
  CLUB_COURSES_RESET,
} from '@src/store/clubCourses/constants';
import { copyCourseFlow } from '@src/store/clubCourse/flows/copy-course-flow';
import { publishCourseFlow } from '@src/store/clubCourse/flows/publish-course-flow';
import { attachCourseFlow } from '@src/store/clubCourse/flows/attach-course-flow';
import { detachCourseFlow } from '@src/store/clubCourse/flows/detach-course-flow';
import { fetchCoursePCCFlow } from '@src/store/clubCourse/flows/fetch-course-pcc-flow';
import { courseStateChangeFlow } from '@src/store/clubCourse/flows/course-state-change-flow';

/*
  GET|POST|PUT|DELETE /admin/1/course/<id>
 */
const clubCourseCRUDFlow = (clubId?: number) => {
  return SagaFlowFactory.createCRUDFlow({
    apiRoute: APIRoute.CLUB_COURSE,
    typePrefix: 'CLUB_COURSE',
    resetType: CLUB_COURSES_RESET,
    options: {
      resetParams: {
        clubId,
      },
    },
  });
};

export default function * clubCourseWatcher() {
  let token: string = '';

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
      clubCourseId,
      params = {},
    } = yield take([
      CLUB_COURSE_GET_REQUESTING,
      CLUB_COURSE_ADD_REQUESTING,
      CLUB_COURSE_EDIT_REQUESTING,
      CLUB_COURSE_DELETE_REQUESTING,
      CLUB_COURSE_COPY_REQUESTING,
      CLUB_COURSE_PUBLISH_REQUESTING,
      ATTACH_COURSE_REQUESTING,
      DETACH_COURSE_REQUESTING,
    ]);

    switch (type) {
      case CLUB_COURSE_GET_REQUESTING:
        yield fork(clubCourseCRUDFlow(), token, 'GET', clubCourseId, params);
        break;
      case CLUB_COURSE_ADD_REQUESTING:
        yield fork(clubCourseCRUDFlow(params.clubId), token, 'ADD', null, params);
        break;
      case CLUB_COURSE_EDIT_REQUESTING:
        yield fork(clubCourseCRUDFlow(params.clubId), token, 'EDIT', clubCourseId, params);
        break;
      case CLUB_COURSE_DELETE_REQUESTING:
        yield fork(clubCourseCRUDFlow(params.clubId), token, 'DELETE', clubCourseId, params);
        break;
      case CLUB_COURSE_COPY_REQUESTING:
        yield fork(copyCourseFlow, token, clubCourseId, params.onComplete);
        break;
      case CLUB_COURSE_PUBLISH_REQUESTING:
        yield fork(publishCourseFlow, token, clubCourseId, params.effectiveDate, params.onComplete);
        break;
      case ATTACH_COURSE_REQUESTING:
        yield fork(attachCourseFlow, token, params.courseId, params.partialCourseId, params.onComplete);
        break;
      case DETACH_COURSE_REQUESTING:
        yield fork(detachCourseFlow, token, params.courseId, params.partialCourseId, params.onComplete);
        break;
      default:
    }
  }
}

export function* spawnClubCourseWatcher() {
  yield all([
    takeEvery(GET_COURSE_PCC_REQUESTING, fetchCoursePCCFlow),
    takeEvery(CHANGE_COURSE_STATE_REQUESTING, courseStateChangeFlow),
  ]);
}
