import React, { PureComponent, ReactNode } from 'react';
import { FormattedDate, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Button, Switch
} from '@material-ui/core';
import { ContentLoader } from '@src/components/layouts/ui';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as clubActions from '@src/store/club/actions';
import { Pagination } from '@src/components/pagination/Pagination';
import moment from 'moment';
import { setHIOInsurance, SetHIOInsurancePayload } from '@src/store/club/actions';
import { memberCompare } from '@src/utils/MemberUtils';

interface OwnProps {
  locale: AppLocale;
  clubId?: number;
  showHIOColumn?: boolean;
  onPageChange?(page: number): void;
  onPlayerClicked?(memberId: number, clubId?: number): void;
}

interface StateProps {
  clubState: ClubState;
}

interface DispatchProps {
  fetchClubMembers(params: MembersTablePaginationAction): void;
  fetchSearch(params: SearchPaginationAction): void;
  changePage(params: MembersChangePageAction): void;
  setClubMemberActivation(params: SetUserActivation): void;
  setHIOInsurance(payload: SetHIOInsurancePayload): void;
}

type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps<{ clubId: string }> & WrappedComponentProps;

class PlayersTable extends PureComponent<Props> {
  render() {
    const {
      clubState: {
        requesting,
      }
    } = this.props;

    return (
      <>
        <ContentLoader visible={requesting}/>
        {this._renderPlayers()}
        {this._renderPagination()}
      </>
    );
  }

  _renderPlayers = (): ReactNode => {
    const {
      clubState: {
        requesting,
      },
    } = this.props;

    if (requesting) {
      return null;
    }

    return (
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell align="left"><FormattedMessage id={'strings.name'} /></TableCell>
            <TableCell align="right"><FormattedMessage id={'strings.memberNo'} /></TableCell>
            <TableCell align="right"><FormattedMessage id={'strings.dateOfBirth'} /></TableCell>
            <TableCell align="right"><FormattedMessage id={'strings.handicap'} /></TableCell>
            <TableCell align="right"><FormattedMessage id={'strings.homeClub'} /></TableCell>
            <TableCell align="right">
              <FormattedMessage id={'scenes.golfClub.players.table.headers.personStatus'} />
            </TableCell>
            <TableCell align="right">
              <FormattedMessage id={'scenes.golfClub.players.table.headers.memberStatus'} />
            </TableCell>
            <TableCell align="right">
              <FormattedMessage id={'scenes.golfClub.players.table.headers.validTo'} />
            </TableCell>
            {this._showHIOColumn && (
              <TableCell align="right">
                <FormattedMessage id={'scenes.golfClub.players.table.headers.hioValid'} />
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {this._renderPlayerRows()}
        </TableBody>
      </Table>
    );
  }

  _renderPlayerRows = () => {
    const {
      clubState: {
        successful,
        pagedMembers,
        pagination: {
          page
        }
      },
    } = this.props;

    return (
      successful && pagedMembers[page] && Object.keys(pagedMembers[page]).sort((a, b): number => {
        return memberCompare(
            pagedMembers[page][a],
            pagedMembers[page][b],
            this.props.locale.appLanguage.langName
        );
      }).map((key: string) => this._renderPlayerRow(pagedMembers[page][key]))
      );
  }

  _renderPlayerRow = (player: Member): ReactNode => {
    return (
      <TableRow key={player.id}>
        <TableCell align="left">
          {Boolean(player._permissions && player._permissions.canView) ? (
            <Button
              color={'primary'}
              onClick={(e: any) => {
                e.preventDefault();
                const { clubId, onPlayerClicked } = this.props;
                if (onPlayerClicked) {
                  const id = player.club ? player.club.id : clubId;
                  onPlayerClicked(player.id, id);
                }
              }}
            >
              {`${player.nameLast} ${player.nameFirst}`}
            </Button>
          ) : (
            (`${player.nameLast} ${player.nameFirst}`)
          )}
        </TableCell>
        <TableCell align="right">{player.memberNo ? player.memberNo : '-'}</TableCell>
        <TableCell align="right">
          {player.birthDate ? <FormattedDate value={player.birthDate}/> : '-'}
        </TableCell>
        <TableCell align="right">
          {player.hcp}
        </TableCell>
        <TableCell align="right">
          {player.club ? player.club.abbreviation : '-'}
        </TableCell>
        <TableCell align="right">{player.personStatus}</TableCell>
        <TableCell align="right">{player.memberStatus}</TableCell>
        <TableCell align="right">
          <Switch
            disabled={Boolean(player._noValidAllowed) ||
            !Boolean(player._permissions && player._permissions.canEdit)}
            color={'primary'}
            checked={!!(!Boolean(player._noValidAllowed) && player.validTo && moment().isBefore(player.validTo))}
            onChange={(event: Object, isInputChecked: boolean) => {
              this._updateClubMemberStatus(player, player.validTo);
            }}
          />
          {(player._noValidAllowed ? '*' : player.validTo ? <FormattedDate value={player.validTo}/> : '-')}
        </TableCell>
        {this._showHIOColumn && (
          <TableCell align="right">
            <Switch
              disabled={!Boolean(player._permissions && player._permissions.canEdit)}
              color={'primary'}
              checked={player.insuranceIsValid}
              onChange={(_, isInputChecked: boolean) => (
                this.props.setHIOInsurance({
                  action: isInputChecked ? 'enable' : 'disable',
                  idMembership: player.idMembership,
                })
              )}
            />
          </TableCell>
        )}
      </TableRow>
    );
  }

  _renderPagination = (): ReactNode => {
    const {
      clubState: {
        requesting,
        pagination: {
          page,
          limit,
          totalCount,
        },
      },
      onPageChange,
    } = this.props;

    if (totalCount > limit && !requesting) {
      return (
        <Pagination
          page={page}
          limit={limit}
          totalCount={totalCount}
          onPageChange={onPageChange ? onPageChange : this._handlePageChange}
        />
      );
    }
    return null;
  }

  _handlePageChange = (page: number) => {
    const {
      clubState: {
        pagedMembers,
        searchActive,
        search
      },
      match: {
        params
      },
      locale: {
        appLanguage: {
          collation
        }
      },
      fetchClubMembers,
      fetchSearch,
      changePage,
    } = this.props;

    const { clubId } = params;

    if (pagedMembers[page]) {
      changePage({ page });
    } else if (searchActive) {
      fetchSearch({ page, params: { ...search, clubId, collation } });
    } else {
      fetchClubMembers({ page, clubId: parseInt(clubId, 10) });
    }
  }

  _updateClubMemberStatus = (member: Member, validTo: string): void => {
    if (member && member.club) {
      const { setClubMemberActivation } = this.props;
      const { id, club } = member;

      const status = (!validTo || moment().isAfter(validTo)) ? 'ACTIVE' : 'INACTIVE';

      setClubMemberActivation({
        memberId: id,
        clubId: club.id,
        status,
        onComplete: ({ error }) => {
          // Error should only occur if the memberId is already attached to another membership
          if (error && error.code === 409) {
            window.alert(this.props.intl.formatMessage({ id: 'strings.memberIdAlreadyTaken' }));
          }
        }
      });
    }
  }

  get _showHIOColumn() {
    return this.props.showHIOColumn || false;
  }
}

export default injectIntl(withRouter(connect<StateProps, DispatchProps, OwnProps, StoreState>(state => ({
  clubState: state.clubReducer,
  locale: state.locale
}), {
  fetchClubMembers: clubActions.fetchClubMembers,
  fetchSearch: clubActions.fetchMembersSearch,
  changePage: clubActions.changePage,
  setClubMemberActivation: clubActions.setClubMemberActivation,
  setHIOInsurance,
})(PlayersTable)));
