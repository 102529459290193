import { tokenAPIPut } from '@src/utils/storeUtils';
import { call, put } from 'redux-saga/effects';
import { API_ROOT, APIRoute } from '@src/assets/config';
import {
  CLUB_COURSE_PUBLISH_ERROR,
  CLUB_COURSE_PUBLISH_SUCCESS
} from '@src/store/clubCourse/constants';
import { getClubCourse } from '@src/store/clubCourse/actions';

/*
  PUT /admin/1/course/<courseId>/publish
 */
export function * publishCourseFlow(
  token: string,
  courseId: number,
  effectiveDate: string,
  onComplete?: (args: APICallResult) => void
) {
  try {
    const response = yield call(() => {
      const url = `${API_ROOT}${APIRoute.CLUB_COURSE}/${courseId}/publish`;
      return tokenAPIPut(url, token, { effectiveDate });
    });

    if (response) {
      yield put({
        type: CLUB_COURSE_PUBLISH_SUCCESS,
        data: response,
      });

      yield put(getClubCourse({ id: courseId }));

      if (onComplete) {
        onComplete({ data: response });
      }
    }

  } catch (error) {
    yield put({ type: CLUB_COURSE_PUBLISH_ERROR, error });
    if (onComplete) {
      onComplete({ error });
    }
  }

  return true;
}