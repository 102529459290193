import { MessageDescriptor } from 'react-intl';
import { PrimitiveType } from 'intl-messageformat/lib/intl-messageformat';

// Contains handicap input field value and numeric representation
export class HandicapValue {
    public readonly value?: string;     // Input field value
    public readonly handicap?: number;  // Handicap numeric value, not null when value is valid

    public static parseValue(value?: string): HandicapValue {
        if (value === '' || value === undefined || value === null) {
            return {};
        }

        // Check for handicap prefix
        let isNegative = false;
        if (value[0] === '-' || value[0] === '+') {
            isNegative = true;
            value = '+' + value.slice(1); // Convert - prefix to +
        }

        // Replace , for .
        value = value.replace(',', '.');

        // Validation
        const regExp = /^\+?(\d+|\d+\.\d+)$/;

        if (!regExp.test(value)) {
            return {value};
        }

        let handicapNumber = parseFloat(value);
        
        // Positive handicaps are negative
        if (isNegative) {
            handicapNumber *= -1;
        }

        return {value: value, handicap: handicapNumber};
    }    
}

export const DefaultMinHandicap = HandicapValue.parseValue('+10');
export const DefaultMaxHandicap = HandicapValue.parseValue('54');

export interface ErrorType {
    descriptor: MessageDescriptor;
    values?: Record<string, PrimitiveType>;
}

//
// Error checking helpers

export const CheckFromGreaterThanToError = (from: HandicapValue, to: HandicapValue): ErrorType|undefined => {
    if (from.handicap !== undefined && to.handicap !== undefined) {
        if (from.handicap > to.handicap ) {
            return {descriptor: {id: 'scenes.messages.form.error.handicapFromGreaterThanTo'}};
        }
    }

    return undefined;
};

export const CheckIsInvalidError = (h: HandicapValue): ErrorType|undefined => {
    if (h.value !== undefined && h.handicap === undefined) {
        return {descriptor: {id: 'scenes.messages.form.error.handicapInvalid'}};
    }

    return undefined;
};

export const CheckOutOfBoundsError = (h: HandicapValue): ErrorType|undefined => {
    if (h.handicap !== undefined) {
        if (h.handicap < DefaultMinHandicap.handicap! || DefaultMaxHandicap.handicap! < h.handicap) {
            return {
                descriptor: {
                    id: 'scenes.messages.form.error.handicapOutOfBounds'
                }, 
                values: {
                    min: DefaultMinHandicap.value,
                    max: DefaultMaxHandicap.value,
                }
            };
        }
    }

    return undefined;
};

//
// Error check functions

// Check from/to handicap values for generic errors
export const GetHandicapError = (from: HandicapValue, to: HandicapValue, submitChecks?: boolean): ErrorType|undefined =>
    CheckFromGreaterThanToError(from, to);

// Check From handicap value for errors
export const GetFromHandicapError = (from: HandicapValue, to: HandicapValue, 
                                     submitChecks?: boolean): ErrorType|undefined => {
    return CheckIsInvalidError(from) ||
            CheckOutOfBoundsError(from) ||
            GetHandicapError(from, to);

};

// Check To handicap value for errors
export const GetToHandicapError = (from: HandicapValue, to: HandicapValue, 
                                   submitChecks?: boolean): ErrorType|undefined => {
    return CheckIsInvalidError(to) ||
            CheckOutOfBoundsError(to) ||
            GetHandicapError(from, to);
};