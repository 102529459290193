import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as authChecks from '@src/components/access-control/index';

export type RoleScopeGuardArgs = {
  isClubScope: boolean;
  isFederationScope: boolean;
  isProviderScope: boolean;
  isAdminRole: boolean;
};

interface OwnProps {
  children: (args: RoleScopeGuardArgs) => any;
}

interface StateProps {
  roleInfo?: RoleInfo;
}

type Props = OwnProps & StateProps & RouteComponentProps<any>;

class RoleScopeGuard extends React.Component<Props, RoleScopeGuardArgs> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isClubScope: this._isClubScope(props.roleInfo),
      isFederationScope: this._isFederationScope(props.roleInfo),
      isProviderScope: this._isProviderScope(props.roleInfo),
      isAdminRole: this._isAdminRole(props.roleInfo),
    };
  }

  render(): React.ReactNode {
    return this.props.children({
      isClubScope: this.state.isClubScope,
      isFederationScope: this.state.isFederationScope,
      isProviderScope: this.state.isProviderScope,
      isAdminRole: this.state.isAdminRole,
    });
  }

  _isClubScope = (roleInfo?: RoleInfo) => (
    authChecks.isClubScope(roleInfo)
  )

  _isFederationScope = (roleInfo?: RoleInfo) => (
    authChecks.isFederationScope(roleInfo)
  )

  _isProviderScope = (roleInfo?: RoleInfo) => (
    authChecks.isProviderScope(roleInfo)
  )

  _isAdminRole = (roleInfo?: RoleInfo) => (
      authChecks.isAdminRole(roleInfo)
  )

}

export default withRouter(connect<StateProps, {}, OwnProps, StoreState>(state => ({
  roleInfo: state.authReducer.roleInfo,
}))(RoleScopeGuard));