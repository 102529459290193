import { fork, take } from 'redux-saga/effects';
import { AUTH_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { APIRoute } from '@src/assets/config';
import {
  FUNCTIONARY_TITLE_ADD_REQUESTING,
  FUNCTIONARY_TITLE_DELETE_REQUESTING,
  FUNCTIONARY_TITLE_EDIT_REQUESTING,
  FUNCTIONARY_TITLE_GET_REQUESTING
} from '@src/store/functionarytitle/constants';
import { FUNCTIONARY_TITLES_SEARCH_RESET } from '@src/store/functionarytitles/constants';

/*
  GET|POST|PUT|DELETE /admin/1/functionary/title/<id>
 */
const fetchPartnerCRUDFlow = SagaFlowFactory.createCRUDFlow({
  apiRoute: APIRoute.FUNCTIONARY_TITLE,
  typePrefix: 'FUNCTIONARY_TITLE',
  resetType: FUNCTIONARY_TITLES_SEARCH_RESET,
});

function * functionaryTitleWatcher() {
  let token: string = '';

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
      functionaryTitleId,
      params
    } = yield take([
      FUNCTIONARY_TITLE_GET_REQUESTING,
      FUNCTIONARY_TITLE_ADD_REQUESTING,
      FUNCTIONARY_TITLE_EDIT_REQUESTING,
      FUNCTIONARY_TITLE_DELETE_REQUESTING
    ]);

    switch (type) {
      case FUNCTIONARY_TITLE_GET_REQUESTING:
        yield fork(fetchPartnerCRUDFlow, token, 'GET', functionaryTitleId, params);
        break;
      case FUNCTIONARY_TITLE_ADD_REQUESTING:
        yield fork(fetchPartnerCRUDFlow, token, 'ADD', null, params);
        break;
      case FUNCTIONARY_TITLE_EDIT_REQUESTING:
        yield fork(fetchPartnerCRUDFlow, token, 'EDIT', functionaryTitleId, params);
        break;
      case FUNCTIONARY_TITLE_DELETE_REQUESTING:
        yield fork(fetchPartnerCRUDFlow, token, 'DELETE', functionaryTitleId, params);
        break;
      default:
    }
  }
}

export default functionaryTitleWatcher;