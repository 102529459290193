import React, { PureComponent, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import Select from '@src/components/forms/Select';
import { connect } from 'react-redux';
import { PropTypes } from '@material-ui/core';
import Margin = PropTypes.Margin;

interface Props {
  regions: Array<RegionEntry>;
  selectedRegions?: Array<RegionEntry> | RegionEntry | undefined;
  disabled?: boolean;
  multi?: boolean;
  margin?: Margin;
  error?: boolean;
  onChangeCallback?(values: Array<{ id: string }> | string | RegionEntry): void;
  // onChangeCallback?(values: Array<{id: number}>): void;
}

class RegionSelectConnected extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      regions,
      selectedRegions,
      disabled,
      margin,
      error,
      multi,
    } = this.props;

    const regionOptions = regions.map(region => ({name: region.name, id: region.name}));
    const selectedValues = multi ?
        (selectedRegions as RegionEntry[]).map(region => ({name: region.name, id: region.name})) :
        selectedRegions;

    const labelText = (): ReactNode => {
      return (
        <FormattedMessage
          id={multi ? 'forms.regionSelect.multi.label' : 'forms.regionSelect.single.label'}
        />
      );
    };

    const placeholderText = (): ReactNode => {
      return (
        <FormattedMessage
          id={multi ? 'forms.regionSelect.multi.placeholder' : 'forms.regionSelect.single.placeholder'}
        />
      );
    };

    return (
      <Select
        error={error}
        margin={margin}
        disabled={disabled}
        options={regionOptions}
        selected={selectedValues}
        multiSelect={multi}
        onChangeCallback={this._handleChange}
        labelText={labelText()}
        placeholderText={placeholderText()}
      />
    );
  }
  // private _handleChange = (values: Array<{id: number, name: string}>) => {
  private _handleChange = (values: Array<{ id: string }> | string | RegionEntry) => {
    const { onChangeCallback } = this.props;
    // console.log('region props = ', this.props);
    // console.log('region props.regions = ', this.props.regions);
    // console.log('region values = ', values);
    // console.log('region values.name = ', values.name);
    // console.log('region values.valueOf() = ', values.valueOf());
    // console.log('region values.toString = ', values.toString);
 
    if (onChangeCallback) {
      onChangeCallback(values);
    }
  }
}

const RegionSelect = connect((state: any) => ({
  regions: state.regionsReducer.regions || [],
}))(RegionSelectConnected);

export {
  RegionSelect
};