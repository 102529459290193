import React, { createRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { ModalContentOverlayLoader } from '@src/components/modals/ui';
import { ClubCourseForm } from '@src/components/scenes/club/course/ClubCourseForm';
import { getClubCourse } from '@src/store/clubCourse/actions';

interface OwnProps {
  clubId: number;
  open: boolean;
  clubCourseId?: number;
  onClose(): void;
}

interface StateProps {
  clubCourseState: ClubCourseState;
}

interface DispatchProps {
  getClubCourse(params: ClubCourseGet): any;
}

type Props = OwnProps & StateProps & DispatchProps;

class ClubCoursesModalConnected extends React.Component<Props, {}> {
  actionsContainerRef = createRef<HTMLDivElement>();

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      clubCourseId,
      getClubCourse
    } = this.props;

    if (nextProps.clubCourseId !== clubCourseId) {
      if (nextProps.clubCourseId) {
        getClubCourse({
          id: nextProps.clubCourseId,
        });
      }
    }
  }

  render() {
    const {
      open,
      onClose,
      clubCourseId,
      clubId,
      clubCourseState: {
        requesting
      }
    } = this.props;

    return (
      <Dialog
        open={open}
        fullWidth={true}
        keepMounted={true}
      >
        <DialogTitle>
          {clubCourseId && <FormattedMessage id={'scenes.golfClub.courses.modal.edit.title'} />}
          {!clubCourseId && <FormattedMessage id={'scenes.golfClub.courses.modal.add.title'} />}
        </DialogTitle>
        <DialogContent>
          {requesting && <ModalContentOverlayLoader/>}
          <ClubCourseForm
            clubId={clubId}
            clubCourseId={clubCourseId}
            parentContext={'MODAL'}
            onClose={onClose}
            actionsContainerRef={this.actionsContainerRef}
          />
        </DialogContent>

        <div ref={this.actionsContainerRef} />
      </Dialog>
    );
  }
}

export const ClubCourseModal = connect<StateProps, DispatchProps, OwnProps, StoreState>(state => ({
  clubCourseState: state.clubCourseReducer,
}), {
  getClubCourse
})(ClubCoursesModalConnected);