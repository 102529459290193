import { fork, take } from 'redux-saga/effects';
import { AUTH_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { MAINTENANCES_RESET } from '@src/store/maintenances/constants';
import { APIRoute } from '@src/assets/config';
import {
  MAINTENANCE_ADD_REQUESTING, MAINTENANCE_DELETE_REQUESTING, MAINTENANCE_EDIT_REQUESTING,
  MAINTENANCE_GET_REQUESTING, MAINTENANCE_READ_REQUESTING
} from './constants';

/*
  GET|POST|PUT|DELETE /admin/1/maintenance/<id>
 */
const fetchMaintenanceCRUDFlow = SagaFlowFactory.createCRUDFlow({
  apiRoute: APIRoute.MAINTENANCE,
  typePrefix: 'MAINTENANCE',
  resetType: MAINTENANCES_RESET,
});

const fetchMaintenanceReadCRUDFlow = SagaFlowFactory.createCRUDFlow({
  apiRoute: APIRoute.MAINTENANCE_READ,
  typePrefix: 'MAINTENANCE_READ',
  resetType: MAINTENANCES_RESET,
});

function * maintenanceWatcher() {
  let token: string = '';

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
      maintenanceId,
      params
    } = yield take([
      MAINTENANCE_GET_REQUESTING,
      MAINTENANCE_ADD_REQUESTING,
      MAINTENANCE_EDIT_REQUESTING,
      MAINTENANCE_DELETE_REQUESTING,
      MAINTENANCE_READ_REQUESTING
    ]);

    switch (type) {
      case MAINTENANCE_GET_REQUESTING:
        yield fork(fetchMaintenanceCRUDFlow, token, 'GET', maintenanceId, params);
        break;
      case MAINTENANCE_ADD_REQUESTING:
        yield fork(fetchMaintenanceCRUDFlow, token, 'ADD', null, {...params, __json: true});
        break;
      case MAINTENANCE_EDIT_REQUESTING:
        yield fork(fetchMaintenanceCRUDFlow, token, 'EDIT', maintenanceId, {...params, __json: true});
        break;
      case MAINTENANCE_DELETE_REQUESTING:
        yield fork(fetchMaintenanceCRUDFlow, token, 'DELETE', maintenanceId, params);
        break;
      case MAINTENANCE_READ_REQUESTING:
        yield fork(fetchMaintenanceReadCRUDFlow, token, 'DELETE', maintenanceId, params);
        break;
      default:
    }
  }
}

export default maintenanceWatcher;