import {
  MEMBERSHIP_ADD_REQUESTING, RESET_MEMBERSHIP_STATE,
  MEMBERSHIP_DELETE_REQUESTING,
  MEMBERSHIP_EDIT_REQUESTING,
  MEMBERSHIP_GET_REQUESTING,
  MEMBERSHIP_HANDICAP_ROUNDS_REQUESTING,
  MEMBERSHIP_INACTIVATE_REQUESTING
} from './constants';

export const getMembership = ({
  personId,
  clubId,
  onComplete,
}: GetMembership) => ({
  type: MEMBERSHIP_GET_REQUESTING,
  personId,
  clubId,
  params: {
    onComplete,
  }
});

export const addMembership = (params: MembershipAdd) => ({
  type: MEMBERSHIP_ADD_REQUESTING,
  params
});

export const editMembership = ({
  personId,
  clubId,
  params,
  onComplete,
}: MembershipEdit) => ({
  type: MEMBERSHIP_EDIT_REQUESTING,
  personId,
  clubId,
  params: {
    ...params,
    freetext: params.otherInfo,
    onComplete
  }
});

export const inactivateMembership = ({
  idMembership,
  onComplete,
}: MembershipInactivate) => ({
  type: MEMBERSHIP_INACTIVATE_REQUESTING,
  idMembership,
  params: {
    onComplete,
  },
});

export const deleteMembership = ({
  idMembership,
  onComplete,
}: MembershipDelete) => ({
  type: MEMBERSHIP_DELETE_REQUESTING,
  idMembership,
  params: {
    onComplete,
  },
});

export const getHandicapRounds = ({
  personId
}: GetHandicapRounds) => ({
  type: MEMBERSHIP_HANDICAP_ROUNDS_REQUESTING,
  personId,
});

export const clearMembershipState = () => ({
  type: RESET_MEMBERSHIP_STATE,
});