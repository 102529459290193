import {
  MAINTENANCES_REQUESTING,
  MAINTENANCES_SUCCESS,
  MAINTENANCES_ERROR,
  MAINTENANCES_CHANGE_PAGE,
  MAINTENANCES_CONDENSED_SUCCESS,
  MAINTENANCES_RESET,
} from './constants';
import { SHARED_DEFAULT_REDUCER_STATE } from '@src/assets/config';
import {
  formDefaultErrorState,
  formDefaultPagedState,
  formDefaultRequestingState,
  fromDefaultPageChangeState,
  optimisticPagedObjectUpdate
} from '@src/utils/storeUtils';
import { MAINTENANCE_EDIT_SUCCESS } from '@src/store/maintenance/constants';

const initialState: MaintenancesState = {
  ...SHARED_DEFAULT_REDUCER_STATE,
  pagedMaintenances: {},
  allMaintenances: [],
};

const maintenancesReducer = (state: MaintenancesState = initialState, action: any): MaintenancesState => {
  switch (action.type) {
    case MAINTENANCES_REQUESTING:
      return formDefaultRequestingState(state);

    case MAINTENANCES_SUCCESS:
      return formDefaultPagedState(state, action, 'pagedMaintenances');

    case MAINTENANCES_CHANGE_PAGE:
      return fromDefaultPageChangeState(state, action);

    case MAINTENANCES_ERROR:
      return formDefaultErrorState(state, action);

    case MAINTENANCES_CONDENSED_SUCCESS: {
      return {
        ...state,
        allMaintenances: action.data.maintenances,
      };
    }

    case MAINTENANCES_RESET:
      return {
        ...initialState,
        requesting: true,
        targetIdParams: state.targetIdParams,
      };

    case MAINTENANCE_EDIT_SUCCESS: {
      const {
        data,
      } = action;
      const page = state.pagination.page;

      const pagedMaintenances = optimisticPagedObjectUpdate(state.pagedMaintenances, page, { ...data });

      return {
        ...state,
        pagedMaintenances
      };
    }

    default:
      return state;
  }
};

export {
  maintenancesReducer,
};