/* tslint:disable */

import React, { useState } from 'react';
import Dropzone, { DropzoneOptions } from 'react-dropzone';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormGroup, Typography } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { DropzoneLabel, DropzoneWrapper } from '@src/components/forms/ui/CodesForm';
import { IntlError } from '@src/utils/intlError';

const DEFAULT_MAX_SIZE = 1000000;
const Bytes2MegaBytes = (n:number) => n/1000000;

const DropzoneOverlay = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 2.5em 0;
    background: rgba(0,0,0,0.5);
    text-align: center;
    color: #fff;
`;

type DropHandler = DropzoneOptions["onDrop"];

export interface UserTargetingProps{
  receiversData: number[] | undefined;
  OnFileChange: (f:number[])=>void | undefined;
}

export const UserTargeting: React.FC<UserTargetingProps> = ({
  receiversData,
  OnFileChange,
}) => {
    const intl = useIntl();
    const [error, setError] = useState<string>();
    const disabled = false;

    const _onDrop: DropHandler = async (accepted, rejected, event) => {

      // Check for rejection errors
      if(rejected.length > 0){
        console.error("User targeting drop rejection:", rejected);

        const errorCode:string|undefined = rejected[0].errors[0]?.code;
        switch(errorCode){
          case "file-too-large":
            setError(intl.formatMessage({id: 'scenes.offers.errors.tooBig'}, {size: Bytes2MegaBytes(DEFAULT_MAX_SIZE)}))
          break;
            
          case "too-many-files":
            setError(intl.formatMessage({id: 'scenes.offers.errors.tooManyFiles'}));
          break;
            
          case "file-invalid-type":
          default:
            setError(intl.formatMessage({id: 'scenes.offers.errors.invalidFile'}));
          break;
        }
        
        return;
      }

      // Validate file TODO
      try
      {
        const codeSplitRegex = /\s*(?:,|;|\r\n|\n|\r|$)\s*/;
        
        const file:Blob = accepted[0];
        const text = await file['text']();
        const raw = text.split(codeSplitRegex);

        // Validate file entries
        const data: number[] = [];

        raw.forEach(userID=>{
          if(userID === "") // Skip empty lines
            return;

            const num = parseInt(userID);
            
            // Check that ids are valid
          if( userID !== num.toString()
           || !Number.isInteger(num) )
            throw new IntlError({id: 'scenes.messages.form.userTargeting.errorInvalidUserId'}, {userID});

          data.push(num);
        });

        OnFileChange && OnFileChange(data);
      }
      catch(e)
      {
        if(e instanceof IntlError){
          setError(e.formatError(intl));
          return;
        }

        // Something else was wrong with the file
        setError(intl.formatMessage({id: 'scenes.offers.errors.invalidFile'}));
      }

    };

    return(
      <FormControl disabled={disabled} style={{width: '100%'}}>
        <FormGroup row={true} style={{ marginBottom: '1.8em' }}>

          <Typography>
            <FormattedMessage id='scenes.messages.form.userTargeting.filePresent'/>
            {receiversData ? <FormattedMessage id='options.yes'/> : <FormattedMessage id='options.no'/>}
          </Typography>
          
          <DropzoneWrapper>
            <Dropzone
              disabled={disabled}
              onDrop={_onDrop}
              accept=".csv"
              multiple={false}
              maxFiles={1}
              maxSize={DEFAULT_MAX_SIZE}
            >
              {({ isDragActive, getRootProps, getInputProps }) => (
                <div className={'drop-zone'} {...getRootProps()}>
                  <input {...getInputProps()} />
                  {isDragActive && (
                    <DropzoneOverlay>
                      <FormattedMessage id={'scenes.offers.form.sections.codes.dropZoneActiveLabel'}/>
                    </DropzoneOverlay>
                  )}
                  <DropzoneLabel>
                    <FormattedMessage id={'scenes.offers.form.sections.codes.dropZoneLabel'}/>
                  </DropzoneLabel>
                </div>
              )}
            </Dropzone>
          </DropzoneWrapper>

          <Typography variant="body2">
            <FormattedMessage 
              id={'scenes.messages.form.userTargeting.helpLabel'}
              values={{ size: Bytes2MegaBytes(DEFAULT_MAX_SIZE) }}
            />
          </Typography>

          {/** Dialog for errors */}
          <Dialog
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            maxWidth="xs"
            fullWidth={true}
            aria-labelledby="confirmation-dialog-title"
            open={!!error}
          >
            <DialogTitle id="confirmation-dialog-title">
              <FormattedMessage id={'scenes.messages.form.userTargeting.errorTitle'} />
            </DialogTitle>
            <DialogContent dividers={true}>
              <Typography>
                {error}
              </Typography>
            </DialogContent>
            <DialogActions style={{display: 'flex'}}>
              <Button autoFocus={true} variant="contained" onClick={e => setError(undefined)}>
                <FormattedMessage id={'buttons.ok'} />
              </Button>
            </DialogActions>
          </Dialog>
  
        </FormGroup>
      </FormControl>
    );
  };