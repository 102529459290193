import { formatURLParams, tokenAPIGet } from '@src/utils/storeUtils';
import { call, put } from 'redux-saga/effects';
import { API_ROOT, APIRoute } from '@src/assets/config';
import {
  FUNCTIONARY_TITLES_CONDENSED_ERROR,
  FUNCTIONARY_TITLES_CONDENSED_SUCCESS
} from '@src/store/functionarytitles/constants';

export function * getCondensedFunctionaryTitles(token: string, targetIdParams: TargetIdParams) {
  try {
    const urlParams = formatURLParams({ ...targetIdParams, type: 'condensed' }, '?');

    const response = yield call(() => {
      const url = `${API_ROOT}${APIRoute.GET_FUNCTIONARY_TITLES}${urlParams}`;
      return tokenAPIGet(url, token);
    });

    if (response) {
      const {
        titles,
      } = response;
      yield put({
        type: FUNCTIONARY_TITLES_CONDENSED_SUCCESS,
        data: titles,
      });
    }

  } catch (error) {
    yield put({ type: FUNCTIONARY_TITLES_CONDENSED_ERROR, error });
  }

  return true;
}