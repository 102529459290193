export const handleOnFunction = (fn?: (...args) => void, ...args) => {
  if (fn) {
    fn(...args);
  }
};

export const tryToCapitalize = (s: any) => {
  if (typeof s !== 'string') {
    return s;
  }

  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const downloadFile = (blob, filename) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'download';
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
};