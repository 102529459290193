import React, { PureComponent } from 'react';
import { AddNewFunctionaryScene } from '@src/components/scenes/functionary/AddNewFunctionaryScene';
import { connect } from 'react-redux';
import { getActiveFederationId } from '@src/store/auth/selectors';

type Props = {
  federationId?: number;
};

class FederationAddNewFunctionary extends PureComponent<Props, {}> {
  render() {
    const { federationId } = this.props;

    return (
      <AddNewFunctionaryScene targetIdParams={{ federationId }}/>
    );
  }
}

export default connect((state: StoreState) => ({
  federationId: getActiveFederationId(state),
}), null)(FederationAddNewFunctionary);