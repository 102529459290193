import {
  CLUB_COURSE_ADD_ERROR,
  CLUB_COURSE_ADD_REQUESTING,
  CLUB_COURSE_ADD_SUCCESS,
  CLUB_COURSE_DELETE_ERROR,
  CLUB_COURSE_DELETE_REQUESTING,
  CLUB_COURSE_DELETE_SUCCESS,
  CLUB_COURSE_EDIT_ERROR,
  CLUB_COURSE_EDIT_REQUESTING,
  CLUB_COURSE_EDIT_SUCCESS,
  CLUB_COURSE_GET_ERROR,
  CLUB_COURSE_GET_REQUESTING,
  CLUB_COURSE_GET_SUCCESS,
  CLUB_COURSE_COPY_REQUESTING,
  CLUB_COURSE_COPY_SUCCESS,
  CLUB_COURSE_COPY_ERROR,
  CLUB_COURSE_PUBLISH_REQUESTING,
  CLUB_COURSE_PUBLISH_SUCCESS,
  CLUB_COURSE_PUBLISH_ERROR,
  DETACH_COURSE_REQUESTING,
  ATTACH_COURSE_REQUESTING,
  DETACH_COURSE_ERROR,
  ATTACH_COURSE_ERROR, DETACH_COURSE_SUCCESS, ATTACH_COURSE_SUCCESS,
} from '@src/store/clubCourse/constants';
import { formDefaultErrorState, formDefaultRequestingState } from '@src/utils/storeUtils';

const initialState: ClubCourseState = {
  clubCourse: undefined,
  requesting: false,
  successful: false,
  error: [],
};

export const clubCourseReducer = (state: ClubCourseState = initialState, action: any) => {
  switch (action.type) {
    case CLUB_COURSE_GET_REQUESTING:
    case ATTACH_COURSE_REQUESTING:
    case DETACH_COURSE_REQUESTING:
    case CLUB_COURSE_EDIT_REQUESTING:
    case CLUB_COURSE_PUBLISH_REQUESTING:
      return {
        ...state,
        requesting: true,
      };
    case CLUB_COURSE_COPY_REQUESTING:
    case CLUB_COURSE_ADD_REQUESTING:
    case CLUB_COURSE_DELETE_REQUESTING:
      return formDefaultRequestingState(state);

    case ATTACH_COURSE_ERROR:
    case DETACH_COURSE_ERROR:
    case CLUB_COURSE_COPY_ERROR:
    case CLUB_COURSE_PUBLISH_ERROR:
    case CLUB_COURSE_ADD_ERROR:
    case CLUB_COURSE_DELETE_ERROR:
    case CLUB_COURSE_EDIT_ERROR:
      return formDefaultErrorState(state, action);

    case CLUB_COURSE_GET_ERROR:
      return {
        ...formDefaultErrorState(state, action),
        clubCourse: undefined,
      };

    case CLUB_COURSE_GET_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
        clubCourse: action.data,
      };

    case ATTACH_COURSE_SUCCESS:
    case DETACH_COURSE_SUCCESS:
    case CLUB_COURSE_COPY_SUCCESS:
    case CLUB_COURSE_PUBLISH_SUCCESS:
    case CLUB_COURSE_ADD_SUCCESS:
    case CLUB_COURSE_DELETE_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
      };

    case CLUB_COURSE_EDIT_SUCCESS:
      const { data } = action;
      return {
        ...state,
        requesting: false,
        successful: true,
        clubCourse: data,
      };

    default:
      return state;
  }
};