import React, { CSSProperties, ErrorInfo, ReactNode } from 'react';
import styled from 'styled-components';
import {
  CircularProgress,
  Card,
  CardHeader,
  CardContent,
  ListItem,
  ListItemText,
  Fab,
  Typography,
  Grid,
} from '@material-ui/core';
import { Search, Close } from '@material-ui/icons';
import Breadcrumb from '@src/components/layouts/Breadcrumb';
import { FormattedMessage } from 'react-intl';
import EmailIcon from '@material-ui/icons/Email';
import ReplayIcon from '@material-ui/icons/Replay';
import { SUPPORT_EMAIL_ADDRESS } from '@src/assets/config';
import Button from '@material-ui/core/Button';

const ContentWrap = styled.section`
  box-sizing: border-box;
  //max-width: 144em;
  padding: 0 2em;
  margin: 0 auto;
  width: 100%;
`;

const ContentLoaderWrap = styled.div`
  display: block;
  margin-top: 3em;
  text-align: center;
`;

const LoaderOverlayWrap = styled.section<{ transparent: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.transparent ? 'rgba(255, 255, 255, .6)' : 'white'};
  z-index: 10000;
`;

export const MarkdownWithStyles = styled.div`
  * {
    margin-bottom: .45em;
  }
  
  p {
    font-size: 14px;
    margin-bottom: 10px;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
   font-size: 22px;
  }
  
  h3 {
   font-size: 20px;
  }
  
  h4 {
   font-size: 18px;
  }
  
  h5 {
   font-size: 16px;
  }
  
  a {
   color: #38a64e;
  }
  
  blockquote {
   margin-left: 20px;
  }
  
  ul {
   list-style-type: disc;
  }
  
  ol {
   list-style-type: decimal;
  }
  
  em {
   font-style: italic;
  }
  
  strong {
   font-weight: bold;
  }
`;

interface LoaderOverlayProps {
  visible?: boolean;
  transparent?: boolean;
  loaderSize?: number;
}

const ContentLoader = (
  {
    visible = true,
    transparent = false,
    loaderSize = 40,
  }: LoaderOverlayProps) => {
  if (!visible) {
    return null;
  }

  return (
    <ContentLoaderWrap>
      <CircularProgress
        size={loaderSize}
        thickness={2}
      />
    </ContentLoaderWrap>
  );
};

const OverlayLoader = (
  {
    visible,
    transparent = false,
    loaderSize = 80,
  }: LoaderOverlayProps) => {
  if (!visible) {
    return null;
  }

  return (
    <LoaderOverlayWrap transparent={transparent}>
      <CircularProgress
        size={loaderSize}
        thickness={3}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginLeft: `${-(loaderSize / 2)}px`,
          marginTop: `${-(loaderSize / 2)}px`
        }}
      />
    </LoaderOverlayWrap>
  );
};

export const TransparentOverlayLoader = (
  { visible }: { visible?: boolean }) => (
  <OverlayLoader visible={visible || false} transparent={true} loaderSize={40}/>
);

interface SearchActionProps {
  isSearchActive?: boolean;
  isApiRequestActive?: boolean;
  isSearchDisabled?: boolean;

  performSearchAction(): void;

  resetSearchAction(): void;
}

const SearchActions = (
  {
    performSearchAction,
    resetSearchAction,
    isSearchActive,
    isApiRequestActive,
    isSearchDisabled
  }: SearchActionProps) => {
  return (
    <div style={{ marginLeft: '1em', alignSelf: 'flex-end' }}>
      <div style={{ display: 'flex' }}>
        <Fab
          disabled={isSearchDisabled}
          onClick={performSearchAction}
          aria-label="search"
        >
          <Search/>
        </Fab>
        {isSearchActive &&
        <Fab
            color="primary"
            disabled={isApiRequestActive}
            onClick={resetSearchAction}
            style={{ marginLeft: '1em' }}
            aria-label="reset"
        >
            <Close/>
        </Fab>
        }
      </div>
    </div>
  );
};

type SearchWrapperProps = {
  children?: ReactNode;
  contentStyles?: any;
};

const SearchWrapper = (
  {
    children,
    contentStyles = { display: 'flex', alignItems: 'flex-end' }
  }: SearchWrapperProps) => {
  return (
    <Card style={{ marginTop: '3em' }}>
      <CardHeader
        title={<FormattedMessage id={'strings.searchTitle'}/>}
      />
      <CardContent style={...contentStyles}>
        {children}
      </CardContent>
    </Card>
  );
};

const ContentHeaderTitle = styled.h2`
  display: block;
  font-size: 36px;
  margin-bottom: 30px;
  
  .small {
    font-size: 22px;
    font-weight: normal;
  }
`;

const ContentHeader = styled.header`
  margin-bottom: 5em;
  
  .content-header-wrapper {
    display: flex;
    align-items: center;
    
    ${ContentHeaderTitle} {
      margin-bottom: 0;
    }
  }
`;

export const withBreadcrumb = (Component: any): any => {
  return class extends React.Component {
    render() {
      return (
        <>
          <Breadcrumb {...this.props} />
          <Component {...this.props} />
        </>
      );
    }
  };
};

export type ListItemType = {
  label: string;
  value: string | number | React.ReactNode;
  labelComp?: ReactNode;
};

export const renderListItem = (
  item: { label: string, value: string, labelComp?: ReactNode },
  idx: number,
) => {
  return (
    <ListItem key={idx} style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}>
      <ListItemText
        primary={item.value}
        secondary={item.labelComp ? (item.labelComp) : <FormattedMessage id={item.label}/>}
      />
    </ListItem>
  );
};

export const renderListWithGrid = (list: ListItemType[], columns = 1) => {
  const colItemCount = Math.ceil(list.length / columns);

  return (
    <Grid container={true}>
      {Array.from({ length: columns }).map((_, idx) => (
        <Grid item={true} md={12} lg={6} key={`${idx}`}>
          {list.slice((colItemCount * idx), colItemCount * (idx + 1)).map(renderListItem)}
        </Grid>
      ))}
    </Grid>
  );
};

const ButtonLoaderContainer = styled.div`
  position: relative;
  display: inline-flex;
`;

type ButtonLoaderWrap = {
  loading: boolean;
  children: ReactNode;
  style?: CSSProperties;
  size?: number;
};

export const ButtonLoaderWrap = (
  {
    loading,
    children,
    style,
    size = 24,
  }: ButtonLoaderWrap) => {
  return (
    <ButtonLoaderContainer style={style}>
      {children}
      {loading && <CircularProgress
          size={size}
          style={{
            position: 'absolute',
            top: `calc(50% - ${size / 2}px)`,
            left: `calc(50% - ${size / 2}px)`,
          }}
      />}
    </ButtonLoaderContainer>
  );
};

type GeneralErrorMessage = {
  children?: ReactNode;
  error?: Error;
  info?: ErrorInfo;
};

export const GeneralErrorMessage = (
  {
    children,
    error,
    info
  }: GeneralErrorMessage) => {
  return (
    <div>
      <Typography variant="h4" paragraph={true}>
        <FormattedMessage id={'system.error.topic'}/>
      </Typography>
      <hr/>
      <Typography variant="h5" paragraph={true}>
        <FormattedMessage id={'system.error.description'}/>
      </Typography>
      <Typography variant="body1" paragraph={true}>
        <Button
          startIcon={<ReplayIcon/>}
          onClick={() => location.reload()}
        >
          <FormattedMessage id={'system.error.reloadPage'}/>
        </Button>
      </Typography>
      <Typography variant="body1" paragraph={true}>
        <Button
          startIcon={<EmailIcon/>}
          onClick={() => window.open('mailto:' + SUPPORT_EMAIL_ADDRESS +
            '?subject=' + 'Software error report (' + location.hostname + ': ' +
            (error?.name ? error.name : '[Unknown error]') + ')' +
            '&body=' + location.hostname + ' | ' +
            (error?.name ? error.name : '[Unknown error]') + ' | ' +
            (error?.message ? error.message : '[Unknown message]') + ' | ' +
            (info?.componentStack ? info.componentStack : '[Unknown component stack]'))}
        >
          <FormattedMessage id={'system.error.supportEmailProblem'}/>
        </Button>
      </Typography>
      {children}
    </div>
  );
};

export {
  ContentWrap,
  OverlayLoader,
  ContentLoader,
  ContentHeader,
  ContentHeaderTitle,
  SearchActions,
  SearchWrapper,
};