import {
  VENDOR_ADD_REQUESTING,
  VENDOR_DELETE_REQUESTING,
  VENDOR_EDIT_REQUESTING,
  VENDOR_GET_REQUESTING,
  VENDOR_CLUBS_REQUESTING
} from '@src/store/vendor/constants';
import { fork, take } from 'redux-saga/effects';
import { AUTH_SET } from '@src/store/auth/constants';
import { fetchVendorCRUDFlow, getVendorClubsFlow } from '@src/store/vendor/flows/get-vendor-clubs-flow';

function * vendorWatcher() {
  let token: string = '';

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
      vendorId,
      params
    } = yield take([
      VENDOR_GET_REQUESTING,
      VENDOR_ADD_REQUESTING,
      VENDOR_EDIT_REQUESTING,
      VENDOR_DELETE_REQUESTING,
      VENDOR_CLUBS_REQUESTING
    ]);

    switch (type) {
      case VENDOR_GET_REQUESTING:
        yield fork(fetchVendorCRUDFlow, token, 'GET', vendorId, params);
        break;
      case VENDOR_ADD_REQUESTING:
        yield fork(fetchVendorCRUDFlow, token, 'ADD', null, params);
        break;
      case VENDOR_EDIT_REQUESTING:
        yield fork(fetchVendorCRUDFlow, token, 'EDIT', vendorId, params);
        break;
      case VENDOR_DELETE_REQUESTING:
        yield fork(fetchVendorCRUDFlow, token, 'DELETE', vendorId, params);
        break;
      case VENDOR_CLUBS_REQUESTING:
        yield fork(getVendorClubsFlow, token, vendorId);
        break;
      default:
    }
  }
}

export default vendorWatcher;