import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import * as clubActions from '@src/store/club/actions';
import { Dialog, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { ModalContentOverlayLoader, renderFormActions } from '@src/components/modals/ui';
import InputsValidator from '@src/components/forms/InputsValidator';

type Props = {
  clubState: ClubState;
  onClose?: () => void;
  open: boolean;
  editClubExtraInfo: (params: EditClubExtraInfo) => any;
};

type State = {
  isLoading: boolean;
  requiredHcp: string;
  maintenanceCharge: string;
  golfCarts: string;
  rentalClubs: string;
  steelSpikes: string;
  trainingPossibilities: string;
  nearestHotel: string;
};

const initialState = {
  isLoading: false,
  requiredHcp: '',
  maintenanceCharge: '',
  golfCarts: '',
  rentalClubs: '',
  steelSpikes: '',
  trainingPossibilities: '',
  nearestHotel: '',
};

class ClubVisitorGuideModal extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const {
      clubState: {
        courseGuide,
      },
    } = this.props;

    if (courseGuide) {
      this.state = {
        isLoading: false,
        ...this._parseAttributes(courseGuide),
      } as any;
    } else {
      this.state = initialState;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      clubState: {
        requesting,
        courseGuide,
      },
      open,
    } = nextProps;

    if (requesting || open) {
      return; // Do nothing state is loading
    } else if (courseGuide) {
      this.setState(this._parseAttributes(courseGuide));
    }
  }

  render() {
    const { open } = this.props;
    const {
      requiredHcp,
      maintenanceCharge,
      golfCarts,
      rentalClubs,
      steelSpikes,
      trainingPossibilities,
      nearestHotel,
      isLoading,
    } = this.state;

    return (
      <Dialog open={open}>
        <DialogTitle>
          <FormattedMessage id={'navigation.golfClubInfo'} />
        </DialogTitle>

        {isLoading && <ModalContentOverlayLoader/>}

        <InputsValidator values={{ requiredHcp, golfCarts, rentalClubs, steelSpikes }}>
          {({ errorInputs, validateThenApply, validateValues }) => (
            <>
              <DialogContent>
                <TextField
                  multiline={true}
                  rows={2}
                  required={true}
                  fullWidth={true}
                  label={<FormattedMessage id={'strings.hcpRequirement'} />}
                  value={requiredHcp}
                  margin="normal"
                  onChange={this._handleChange('requiredHcp', validateValues)}
                  error={errorInputs.requiredHcp}
                />

                <TextField
                  multiline={true}
                  rows={2}
                  fullWidth={true}
                  label={<FormattedMessage id={'strings.rentalPlayingRights'} />}
                  value={maintenanceCharge}
                  margin="normal"
                  onChange={this._handleChange('maintenanceCharge', validateValues)}
                />

                <TextField
                  multiline={true}
                  required={true}
                  fullWidth={true}
                  label={<FormattedMessage id={'strings.carts'} />}
                  value={golfCarts}
                  margin="normal"
                  onChange={this._handleChange('golfCarts', validateValues)}
                  error={errorInputs.golfCarts}
                />

                <TextField
                  multiline={true}
                  required={true}
                  fullWidth={true}
                  label={<FormattedMessage id={'strings.rentalClubs'} />}
                  value={rentalClubs}
                  margin="normal"
                  onChange={this._handleChange('rentalClubs', validateValues)}
                  error={errorInputs.rentalClubs}
                />

                <TextField
                  required={true}
                  fullWidth={true}
                  label={<FormattedMessage id={'strings.steelSpikes'} />}
                  value={steelSpikes}
                  margin="normal"
                  onChange={this._handleChange('steelSpikes', validateValues)}
                  error={errorInputs.steelSpikes}
                />

                <TextField
                  multiline={true}
                  fullWidth={true}
                  label={<FormattedMessage id={'strings.trainingPossibilities'} />}
                  value={trainingPossibilities}
                  margin="normal"
                  onChange={this._handleChange('trainingPossibilities')}
                />

                <TextField
                  multiline={true}
                  fullWidth={true}
                  label={<FormattedMessage id={'strings.nearestHotel'} />}
                  value={nearestHotel}
                  margin="normal"
                  onChange={this._handleChange('nearestHotel', validateValues)}
                />

              </DialogContent>

              {renderFormActions({
                id: 1,
                parentContext: 'MODAL',
                onClose: this._handleOnClose,
                onUpdate: validateThenApply.bind(this, this._handleUpdate),
                disabled: false,
              })}
            </>
          )}
        </InputsValidator>
      </Dialog>
    );
  }

  private _handleChange = (name: string, fn?: () => any) => (
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      this.setState({
        [name as any]: event.target.value,
      } as any, fn);
    }
  )

  private _parseAttributes = (courseGuide: ClubCourseGuide) => {
    const {
      club: {
        maintenanceCharge,
      },
      extraInfo: {
        requiredHcp,
        golfCarts,
        rentalClubs,
        steelSpikes,
        trainingPossibilities,
        nearestHotel,
      },
    } = courseGuide;

    return {
      requiredHcp,
      maintenanceCharge,
      golfCarts,
      rentalClubs,
      steelSpikes,
      trainingPossibilities,
      nearestHotel,
    };
  }

  private _handleOnClose = () => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  }

  private _handleUpdate = () => {
    const {
      editClubExtraInfo,
      clubState: {
        courseGuide,
      },
    } = this.props;

    this.setState({ isLoading: true }, () => {
      const {
        requiredHcp,
        maintenanceCharge,
        golfCarts,
        rentalClubs,
        steelSpikes,
        trainingPossibilities,
        nearestHotel,
      } = this.state;

      editClubExtraInfo({
        clubId: courseGuide!.club.id,
        params: {
          requiredHcp,
          maintenanceCharge,
          golfCarts,
          rentalClubs,
          steelSpikes,
          trainingPossibilities,
          nearestHotel,
        },
        onComplete: this._handleAfterUpdate,
      });
    });
  }

  private _handleAfterUpdate = ({ error }) => {
    this.setState({ isLoading: false }, () => {
      if (error) {
        return window.alert(error);
      }

      this._handleOnClose();
    });
  }
}

export default connect((state: StoreState) => ({
  clubState: state.clubReducer,
}), {
  editClubExtraInfo: clubActions.editClubExtraInfo,
})(ClubVisitorGuideModal);