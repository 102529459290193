import React, { PureComponent, Fragment, ReactNode, ChangeEvent } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Button,
  FormControl,
  TextField,
  FormGroup,
} from '@material-ui/core';
import Select from '@src/components/forms/Select';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import {
  ContentHeader,
  ContentHeaderTitle,
  ContentWrap,
  SearchWrapper,
  SearchActions,
  ContentLoader
} from '@src/components/layouts/ui';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import * as clubGreenCardsActions from '@src/store/clubGreencards/actions';
import { DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import { GreenCardModal } from '@src/components/scenes/club/greenCard/GreenCardModal';
import { Pagination } from '@src/components/pagination/Pagination';
import { handleSearchFieldOnKeyDownEnterSniff } from '@src/utils/storeUtils';
import LocalizedDatePicker from '@src/components/forms/LocalizedDatePicker';
import { EditIconButton } from '@src/components/buttons/buttons';
import { translateDate } from '@src/utils/localeUtils';
import moment from 'moment';

interface OwnProps {
  clubGreenCards: ClubGreenCardsState;
  changePage: (params: ChangePageAction) => any;
  fetchClubGreenCards: (params: FetchClubGreenCards) => any;
  searchChanged: (params: ClubsGreenCardSearch) => any;
  fetchSearch: (params: SearchPaginationAction) => any;
  resetSearch: (clubId: number) => any;
}

type State = {
  modalOpen: boolean;
  greenCardIdToEdit?: number;
};

type Props = OwnProps & RouteComponentProps<any> & WrappedComponentProps;

class ClubGreenCard extends PureComponent<Props, State> {
  state = {
    modalOpen: false,
    greenCardIdToEdit: undefined
  };

  UNSAFE_componentWillMount() {
    const {
      match: {
        params
      },
      fetchClubGreenCards,
    } = this.props;

    const { clubId } = params;
    fetchClubGreenCards({ clubId, page: DEFAULT_PAGINATION_PAGE });
  }

  render() {
    const { modalOpen, greenCardIdToEdit } = this.state;
    const {
      clubGreenCards: {
        requesting,
      }
    } = this.props;

    return (
      <Fragment>
        <HeadHelmet titleId={'navigation.golfClubGreenCard'} />
        <ContentWrap>
          <ContentHeader>
            <ContentHeaderTitle>
              <FormattedMessage id={'navigation.golfClubGreenCard'}/>
            </ContentHeaderTitle>
            <Button variant="contained" onClick={() => this.setState({greenCardIdToEdit: undefined, modalOpen: true})}>
              <FormattedMessage id={'scenes.golfClub.greenCard.addNewButtonLabel'}/>
            </Button>
            {this._renderGreenCardSearch()}
          </ContentHeader>

          <ContentLoader visible={requesting}/>
          {this._renderGreenCards()}
          {this._renderPagination()}

          <GreenCardModal
            greenCardId={greenCardIdToEdit}
            open={modalOpen}
            onClose={() => {
              this.setState({modalOpen: false});
            }}
          />
        </ContentWrap>
      </Fragment>
    );
  }

  private _renderGreenCards = () => {
    const {
      clubGreenCards: {
        requesting,
        successful,
        pagedGreenCards,
        pagination: {
          page
        }
      }
    } = this.props;

    if (requesting) {
      return null;
    }

    const sortByRegisteredDate = (a: string, b: string): number => {
      const a2 = pagedGreenCards[page][a];
      const b2 = pagedGreenCards[page][b];
      return b2.timeRegistration === a2.timeRegistration ? b2.id - a2.id 
        : moment.utc(b2.timeRegistration).diff(a2.timeRegistration);
    };

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><FormattedMessage id={'scenes.golfClub.greenCard.table.headers.nro'} /></TableCell>
            <TableCell><FormattedMessage id={'scenes.golfClub.greenCard.table.headers.registeredDate'} /></TableCell>
            <TableCell><FormattedMessage id={'strings.name'} /></TableCell>
            <TableCell><FormattedMessage id={'strings.phone'} /></TableCell>
            <TableCell><FormattedMessage id={'scenes.golfClub.greenCard.table.headers.recipient'} /></TableCell>
            <TableCell><FormattedMessage id={'strings.state'} /></TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {successful && pagedGreenCards
            && Object.keys(pagedGreenCards[page]).sort(sortByRegisteredDate).map((key: string) => (
          <TableRow key={pagedGreenCards[page][key].id}>
            <TableCell>{pagedGreenCards[page][key].id}</TableCell>
            <TableCell>
              {pagedGreenCards[page][key].timeRegistration ?
                translateDate(pagedGreenCards[page][key].timeRegistration) :
                '-'
              }
              </TableCell>
            <TableCell>
              {pagedGreenCards[page][key].nameFirst} {pagedGreenCards[page][key].nameLast}
            </TableCell>
            <TableCell>{pagedGreenCards[page][key].phone}</TableCell>
            <TableCell>
              {pagedGreenCards[page][key].proPersonInfo ?
                pagedGreenCards[page][key].proPersonInfo.nameFirst + ' ' +
                pagedGreenCards[page][key].proPersonInfo.nameLast :
              '-'}
            </TableCell>
            <TableCell>{pagedGreenCards[page][key].status}</TableCell>
            <TableCell size={'small'}>
              <EditIconButton
                onClick={() => this.setState({ greenCardIdToEdit: pagedGreenCards[page][key].id, modalOpen: true })}
              />
            </TableCell>
          </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  private _renderGreenCardSearch = (): ReactNode => {
    const { intl } = this.props;

    const {
      clubGreenCards: {
        requesting,
        search,
        searchActive
      },
    } = this.props;

    const disableSearch: boolean = this._disableSearch();

    return (
      <SearchWrapper>
        <FormControl disabled={requesting}>
          <TextField
            disabled={requesting}
            label={<FormattedMessage id={'scenes.golfClub.greenCard.search.textSearch'} />}
            value={search.searchTerm}
            style={{ marginRight: '1em' }}
            inputProps={{
              name: 'text-search',
              id: 'text-search',
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const { searchChanged } = this.props;
              const {
                currentTarget: {
                  value,
                }
              } = e;

              searchChanged({
                searchTerm: value
              });
            }}
            onKeyDown={(e: React.KeyboardEvent<any>) =>
              handleSearchFieldOnKeyDownEnterSniff(e, this._performSearch, disableSearch)
            }
          />
        </FormControl>
        <FormControl style={{ marginRight: '1em' }}>
          <Select
            disabled={requesting}
            selected={search.status}
            options={[
              {
                id: 'PENDING',
                name: this.props.intl.formatMessage({ id: 'scenes.golfClub.greenCard.search.stateOptions.pending' }),
              },
              {
                id: 'PASSED',
                name: this.props.intl.formatMessage({ id: 'scenes.golfClub.greenCard.search.stateOptions.approved' }),
              },
              {
                id: 'CANCELLED',
                name: this.props.intl.formatMessage({ id: 'scenes.golfClub.greenCard.search.stateOptions.canceled' }),
              },
              {
                id: 'FAILED',
                name: this.props.intl.formatMessage({ id: 'scenes.golfClub.greenCard.search.stateOptions.rejected' }),
              },
            ]}
            onChangeCallback={(value) => {
              const { searchChanged } = this.props;
              const status: GreenCardActivityStatus = value ? value : undefined;
              searchChanged({
                status
              });
            }}
            labelText={<FormattedMessage id={'scenes.golfClub.greenCard.search.state'}/>}
            placeholderText={<FormattedMessage id={'scenes.golfClub.greenCard.search.state'}/>}
          />
        </FormControl>
        <FormGroup>
          <FormGroup row={true}>
            <FormControl disabled={requesting}>
              <LocalizedDatePicker
                disabled={requesting}
                style={{ marginRight: '1em' }}
                value={search.startDate}
                disablePast={false}
                onChange={(newDate: Date) => {
                  const { searchChanged } = this.props;
                  searchChanged({
                    startDate: newDate
                  });
                }}
                clearable={true}
                emptyLabel={intl.formatMessage({ id: 'scenes.golfClub.greenCard.search.startDate' })}
              />
            </FormControl>
            <FormControl disabled={requesting}>
              <LocalizedDatePicker
                disabled={requesting}
                style={{ marginRight: '1em' }}
                value={search.endDate}
                disablePast={false}
                onChange={(newDate: Date) => {
                  const { searchChanged } = this.props;
                  searchChanged({
                    endDate: newDate
                  });
                }}
                clearable={true}
                emptyLabel={intl.formatMessage({ id: 'scenes.golfClub.greenCard.search.endDate' })}
              />
            </FormControl>
          </FormGroup>
        </FormGroup>
        <SearchActions
          isSearchActive={searchActive}
          isApiRequestActive={requesting}
          performSearchAction={this._performSearch}
          resetSearchAction={this._resetSearch}
          isSearchDisabled={disableSearch}
        />
      </SearchWrapper>
    );
  }

  private _disableSearch = (): boolean => {
    const {
      clubGreenCards: {
        requesting,
        search: {
          searchTerm,
          startDate,
          endDate,
          status
        }
      }
    } = this.props;

    return (requesting || (searchTerm === '' && status === '' && startDate === null && endDate === null));
  }

  private _performSearch = () => {
    const {
      fetchSearch,
      clubGreenCards: {
        search
      },
      match: {
        params
      },
    } = this.props;

    const { clubId } = params;

    fetchSearch({
      page: DEFAULT_PAGINATION_PAGE,
      params: {
        clubId,
        ...search
      }
    });
  }

  private _resetSearch = () => {
    const {
      resetSearch,
      match: {
        params
      }
    } = this.props;

    const { clubId } = params;

    resetSearch(clubId);
  }

  private _renderPagination = () => {
    const {
      clubGreenCards: {
        pagination: {
          page,
          limit,
          totalCount
        }
      }
    } = this.props;

    if (totalCount > limit) {
      return <Pagination page={page} limit={limit} totalCount={totalCount} onPageChange={this._handlePageChange}/>;
    }

    return null;
  }

  private _handlePageChange = (page: number) => {
    const {
      clubGreenCards: {
        pagedGreenCards,
        searchActive,
        search
      },
      fetchClubGreenCards,
      fetchSearch,
      changePage,
      match: {
        params
      },
    } = this.props;

    const { clubId } = params;

    if (pagedGreenCards[page]) {
      changePage({ page });
    } else if (searchActive) {
      fetchSearch({ page, params: {...search, clubId: clubId }});
    } else {
      fetchClubGreenCards({ page, clubId });
    }
  }
}

export default withRouter(injectIntl(connect((state: StoreState) => ({
  clubGreenCards: state.clubGreenCardsReducer,
}), {
  fetchClubGreenCards: clubGreenCardsActions.fetchClubGreenCards,
  changePage: clubGreenCardsActions.changePage,
  searchChanged: clubGreenCardsActions.searchChanged,
  fetchSearch: clubGreenCardsActions.fetchSearch,
  resetSearch: clubGreenCardsActions.resetSearch,
})(ClubGreenCard)));