import {
  EDIT_HANDICAP_OVERRIDE_ERROR, EDIT_HANDICAP_OVERRIDE_REQUESTING, EDIT_HANDICAP_OVERRIDE_SUCCESS,
  HANDICAP_INFO_GET_ERROR, HANDICAP_INFO_GET_REQUESTING, HANDICAP_INFO_GET_SUCCESS,
  PERSON_ADD_ERROR,
  PERSON_ADD_REQUESTING, PERSON_ADD_SUCCESS,
  PERSON_DELETE_ERROR,
  PERSON_DELETE_REQUESTING, PERSON_DELETE_SUCCESS,
  PERSON_EDIT_ERROR,
  PERSON_EDIT_REQUESTING, PERSON_EDIT_SUCCESS,
  PERSON_GET_ERROR,
  PERSON_GET_REQUESTING,
  PERSON_GET_SUCCESS, RESET_PERSON_STATE,
} from './constants';
import { formDefaultErrorState, formDefaultRequestingState } from '@src/utils/storeUtils';
import {
  AddPersonAction,
  AddPersonErrorAction,
  AddPersonSuccessAction,
  DeletePersonAction,
  DeletePersonErrorAction,
  DeletePersonSuccessAction,
  EditPersonAction,
  EditPersonErrorAction,
  EditPersonHandicapOverrideAction,
  EditPersonHandicapOverrideError,
  EditPersonHandicapOverrideSuccess,
  EditPersonSuccessAction,
  GetPersonAction,
  GetPersonErrorAction,
  GetPersonHandicapInfoAction,
  GetPersonHandicapInfoError, GetPersonHandicapInfoSuccess,
  GetPersonSuccessAction, ResetPersonStateAction
} from '@src/store/person/actions';

const initialState: PersonState = {
  requesting: false,
  successful: false,
  error: [],
  person: undefined,
  handicapInfo: undefined,
};

type Action = GetPersonAction | GetPersonSuccessAction | GetPersonErrorAction
| AddPersonAction | AddPersonSuccessAction | AddPersonErrorAction | EditPersonAction
| EditPersonSuccessAction | EditPersonErrorAction | DeletePersonAction | DeletePersonSuccessAction
| DeletePersonErrorAction | GetPersonHandicapInfoAction | GetPersonHandicapInfoSuccess
| GetPersonHandicapInfoError | EditPersonHandicapOverrideAction | EditPersonHandicapOverrideSuccess
| EditPersonHandicapOverrideError | ResetPersonStateAction;

export const personReducer = (state: PersonState = initialState, action: Action): PersonState => {
  switch (action.type) {
    case PERSON_GET_REQUESTING:
      return {
        ...formDefaultRequestingState(state),
        person: undefined,
        handicapInfo: undefined,
      };

    case PERSON_ADD_REQUESTING:
    case PERSON_DELETE_REQUESTING:
    case PERSON_EDIT_REQUESTING:
      return formDefaultRequestingState(state);

    case PERSON_GET_ERROR:
    case PERSON_ADD_ERROR:
    case PERSON_DELETE_ERROR:
    case PERSON_EDIT_ERROR:
      return formDefaultErrorState(state, action);

    case PERSON_EDIT_SUCCESS:
    case PERSON_GET_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
        person: action.data,
      };

    case PERSON_ADD_SUCCESS:
    case PERSON_DELETE_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
      };

    case HANDICAP_INFO_GET_REQUESTING:
    case EDIT_HANDICAP_OVERRIDE_REQUESTING:
      return {
        ...state,
        requesting: true,
      };

    case EDIT_HANDICAP_OVERRIDE_SUCCESS:
      return {
        ...state,
        requesting: false,
      };

    case HANDICAP_INFO_GET_SUCCESS:
      return {
        ...state,
        requesting: false,
        handicapInfo: action.handicapInfo,
      };

    case EDIT_HANDICAP_OVERRIDE_ERROR:
      return {
        ...state,
        requesting: false,
      };

    case HANDICAP_INFO_GET_ERROR:
      return {
        ...state,
        requesting: false,
        handicapInfo: undefined,
      };

    case RESET_PERSON_STATE:
      return {
        ...state,
        person: undefined,
        handicapInfo: undefined,
      };

    default:
      return state;
  }
};