import {
  CLUBS_REQUESTING,
  CLUBS_CHANGE_PAGE,
  CLUBS_SEARCH_CHANGED,
  CLUBS_SEARCH_REQUESTING,
  CLUBS_SEARCH_RESET
} from './constants';
import {
  DEFAULT_PAGINATION_LIMIT
} from '@src/assets/config';

const fetchClubs = ({
  page,
  limit = DEFAULT_PAGINATION_LIMIT
}: TablePaginationAction) => {

  return {
    type: CLUBS_REQUESTING,
    page,
    limit
  };
};

const changePage = ({
  page
}: ChangePageAction) => {
  return {
    type: CLUBS_CHANGE_PAGE,
    page,
  };
};

const searchChanged = (search: ClubsSearch) => {
  return {
    type: CLUBS_SEARCH_CHANGED,
    search
  };
};

const fetchSearch = ({
  page,
  limit = DEFAULT_PAGINATION_LIMIT,
  params
}: SearchPaginationAction) => {
  return {
    type: CLUBS_SEARCH_REQUESTING,
    page,
    limit,
    params,
  };
};

const resetSearch = () => {
  return {
    type: CLUBS_SEARCH_RESET
  };
};

export {
  fetchClubs,
  changePage,
  searchChanged,
  fetchSearch,
  resetSearch
};