import { SHARED_DEFAULT_REDUCER_STATE } from '@src/assets/config';
import {
  ChangeAcceptableScoresPageAction, GetAcceptableScoresAction, GetAcceptableScoresErrorAction,
  GetAcceptableScoresSuccessAction, ResetAcceptableScoreState,
} from '@src/store/acceptableScores/actions';
import {
  ACCEPTABLE_SCORES_CHANGE_PAGE,
  ACCEPTABLE_SCORES_ERROR,
  ACCEPTABLE_SCORES_REQUESTING, ACCEPTABLE_SCORES_SUCCESS, RESET_ACCEPTABLE_SCORES,
} from '@src/store/acceptableScores/constants';
import { formDefaultErrorState, formDefaultPagedState, fromDefaultPageChangeState } from '@src/utils/storeUtils';

const initialState: AcceptableScoresState = {
  ...SHARED_DEFAULT_REDUCER_STATE,
  pagedScores: {},
};

type Action = GetAcceptableScoresAction | GetAcceptableScoresSuccessAction | GetAcceptableScoresErrorAction
  | ChangeAcceptableScoresPageAction | ResetAcceptableScoreState;

export const acceptableScoresReducer = (state: AcceptableScoresState = initialState, action: Action) => {
  switch (action.type) {
    case ACCEPTABLE_SCORES_REQUESTING:
      return {
        ...state,
        requesting: true,
      };
    case ACCEPTABLE_SCORES_ERROR:
        return formDefaultErrorState(state, action);
    case ACCEPTABLE_SCORES_CHANGE_PAGE:
      return fromDefaultPageChangeState(state, action);
    case ACCEPTABLE_SCORES_SUCCESS:
      return formDefaultPagedState(state, action, 'pagedScores');
    case RESET_ACCEPTABLE_SCORES:
      return {
        ...initialState
      };
    default:
      return state;
  }
};