import { tokenAPIGet } from '../../../utils/storeUtils';
import { call, put } from 'redux-saga/effects';
import { API_ROOT, APIRoute } from '../../../assets/config/index';
import {
  VENDOR_CLUBS_ERROR,
  VENDOR_CLUBS_SUCCESS,
} from '../../vendor/constants';
import { VENDORS_RESET } from '@src/store/vendors/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';

/*
  GET /admin/1/vendor/{vendorId}/clubs
 */

export function * getVendorClubsFlow(token: string, vendorId: number) {
  try {
    const response = yield call(() => {
      const url = `${API_ROOT}${APIRoute.VENDOR}/${vendorId}/clubs`;
      return tokenAPIGet(url, token);
    });

    if (response) {
      yield put({
        type: VENDOR_CLUBS_SUCCESS,
        data: response,
      });
    }

  } catch (error) {
    yield put({ type: VENDOR_CLUBS_ERROR, error });
  }

  return true;
}
/*
  GET|POST|PUT|DELETE /admin/1/vendor/<id>
 */
export const fetchVendorCRUDFlow = SagaFlowFactory.createCRUDFlow({
  apiRoute: APIRoute.VENDOR,
  typePrefix: 'VENDOR',
  resetType: VENDORS_RESET,
});