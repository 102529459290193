export const VENDOR_GET_REQUESTING = 'VENDOR_GET_REQUESTING';
export const VENDOR_GET_SUCCESS = 'VENDOR_GET_SUCCESS';
export const VENDOR_GET_ERROR = 'VENDOR_GET_ERROR';

export const VENDOR_ADD_REQUESTING = 'VENDOR_ADD_REQUESTING';
export const VENDOR_ADD_SUCCESS = 'VENDOR_ADD_SUCCESS';
export const VENDOR_ADD_ERROR = 'VENDOR_ADD_ERROR';

export const VENDOR_EDIT_REQUESTING = 'VENDOR_EDIT_REQUESTING';
export const VENDOR_EDIT_SUCCESS = 'VENDOR_EDIT_SUCCESS';
export const VENDOR_EDIT_ERROR = 'VENDOR_EDIT_ERROR';

export const VENDOR_DELETE_REQUESTING = 'VENDOR_DELETE_REQUESTING';
export const VENDOR_DELETE_SUCCESS = 'VENDOR_DELETE_SUCCESS';
export const VENDOR_DELETE_ERROR = 'VENDOR_DELETE_ERROR';

export const VENDOR_CLUBS_REQUESTING = 'VENDOR_CLUBS_REQUESTING';
export const VENDOR_CLUBS_SUCCESS = 'VENDOR_CLUBS_SUCCESS';
export const VENDOR_CLUBS_ERROR = 'VENDOR_CLUBS_ERROR';