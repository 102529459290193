import {
  CLUB_COURSE_HOLE_EDIT_REQUESTING,
  CLUB_COURSE_HOLE_GET_REQUESTING
} from './constants';

export const getClubCourseHole = ({
  clubCourseId,
  holeId
}: ClubCourseHoleGet) => {
  return {
    type: CLUB_COURSE_HOLE_GET_REQUESTING,
    clubCourseId: clubCourseId,
    holeId: holeId,
  };
};

export const editClubCourseHole = (params: ClubCourseHoleEdit) => {
  return {
    type: CLUB_COURSE_HOLE_EDIT_REQUESTING,
    clubCourseId: params.clubCourseId,
    holeId: params.holeId,
    params
  };
};