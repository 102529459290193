import React, { PureComponent, ReactNode } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl, FormattedDate } from 'react-intl';
import {
  Button,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
} from '@material-ui/core';
import { connect } from 'react-redux';
import * as personsActions from '@src/store/persons/actions';
import * as membershipActions from '@src/store/membership/actions';
import { DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import { Pagination } from '@src/components/pagination/Pagination';
import { ContentLoader } from '@src/components/layouts/ui';
import { DeleteIconButton } from '@src/components/buttons/buttons';
import DeleteConfirm, { DeleteConfirmChildren } from '@src/components/headless/DeleteConfirm';
import { personCompare } from '@src/utils/PersonUtils';

interface Props extends WrappedComponentProps {
  personsReducer: PersonsState;
  fetchPersons: (params: TablePaginationAction) => any;
  inactivateMembership: (params: MembershipInactivate) => any;
  deleteMembership: (params: MembershipDelete) => any;
  changePage: (params: MembersChangePageAction) => any;
  searchChanged: (params: PersonsSearch) => any;
  fetchSearch: (params: SearchPaginationAction) => any;
  resetSearch: () => any;
  onPersonPicked?: (person: Person) => any;
  selectedPersonIds?: number[];
  deferInitialLoad?: boolean;
  rowActionType?: 'SELECT' | 'REMOVE' | 'EDIT' | 'NONE';
  forceLoader?: boolean;
  memberNoClickable?: boolean;
  onMemberNoClick?: (person: Person) => any;
  locale: AppLocale;
}

type State = {
  modalOpen: boolean;
};

class PersonsTable extends PureComponent<Props, State> {
  state = {
    modalOpen: false
  };

  UNSAFE_componentWillMount() {
    this._handleInitialChange();
  }

  render() {
    return this._renderPersons();
  }

  private _handleInitialChange = () => {
    const {
      fetchPersons,
      personsReducer: {
        requesting,
        successful
      },
      locale: {
        appLanguage: {
          collation
        }
      },
      deferInitialLoad,
    } = this.props;

    // Initial load can be deferred when used only as display. WE might use this
    // for problematic persons list
    if (!deferInitialLoad) {
      if (!requesting && !successful) {
        fetchPersons({
          page: DEFAULT_PAGINATION_PAGE,
          params: { collation }
        });
      }
    }
  }

  private _renderPersons = (): ReactNode => {
    const {
      personsReducer: {
        requesting,
        successful,
        pagedPersons,
        search,
        pagination: {
          page,
        },
      },
      forceLoader,
    } = this.props;

    if (requesting || forceLoader) {
      return <ContentLoader visible={true}/>;
    }

    return (
      <>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><FormattedMessage id={'strings.personId'} /></TableCell>
              <TableCell><FormattedMessage id={'strings.name'} /></TableCell>
              <TableCell><FormattedMessage id={'strings.dateOfBirth'} /></TableCell>
              <TableCell><FormattedMessage id={'strings.clubAbbreviation'} /></TableCell>
              <TableCell><FormattedMessage id={'strings.memberNo'} /></TableCell>
              {search.problemType?.id === 9
                ? <TableCell><FormattedMessage id={'strings.membership'} /></TableCell>
                : <TableCell><FormattedMessage id={'strings.personState'} /></TableCell>
              }
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              successful
              && pagedPersons
              && Object.keys(pagedPersons[page]).sort((a, b): number => {
                return personCompare(pagedPersons[page][a], pagedPersons[page][b]);
              }).map((key: string) =>
                this._renderPersonRow(pagedPersons[page][key], search)
              )
            }
          </TableBody>
        </Table>

        {this._renderPagination()}
      </>
    );
  }

  private _renderPersonRow = (person: Person, search: PersonsSearch): ReactNode => {
    return (
      <TableRow key={`${person.idPerson}-${person.email}-${person.club.id}`}>
        {this._renderPersonIdField(person)}
        <TableCell>{person.nameLast ? person.nameLast : '?'} {person.nameFirst ? person.nameFirst : '?'}</TableCell>
        <TableCell>
          {(person.birthDate !== null && person.birthDate) ? <FormattedDate value={person.birthDate} /> : '-'}
        </TableCell>
        <TableCell>{person.club && person.club.abbreviation ? person.club.abbreviation : '-'}</TableCell>
        <TableCell>{person.memberNo ? person.memberNo : '-'}</TableCell>
        {search.problemType?.id === 9
          ? <TableCell>{this._renderInactivateButton(person)}</TableCell>
          : <TableCell>{person.personStatus}</TableCell>
        }
        <TableCell align={'right'}>
          {this._renderCorrectRowAction(person, search)}
        </TableCell>
      </TableRow>
    );
  }

  private _renderPersonIdField = (person: Person): ReactNode => {
    const { memberNoClickable, onMemberNoClick } = this.props;

    if (memberNoClickable) {
      return (
        <TableCell>
          <Button
            color={'primary'}
            onClick={(e: any) => {
              e.preventDefault();
              if (onMemberNoClick) {
                onMemberNoClick(person);
              }
            }}
          >
            {person.idPerson}
          </Button>
        </TableCell>
      );
    }

    return (
      <TableCell>
        {person.memberNo}
      </TableCell>
    );
  }

  private _renderCorrectRowAction = (person: Person, search: PersonsSearch): ReactNode => {
    const {
      rowActionType = 'SELECT',
      intl
    } = this.props;

    const onClickAction = () => {
      const { onPersonPicked } = this.props;
      if (onPersonPicked) {
        onPersonPicked(person as Person);
      }
    };

    if (rowActionType === 'SELECT') {
      return (
        <Button
          disabled={this._isPersonAlreadySelected(person.idPerson)}
          variant="contained"
          onClick={onClickAction}
        >
          <FormattedMessage id={'buttons.set'} />
        </Button>
      );
    } else if (rowActionType === 'REMOVE') {
      return (
        <DeleteConfirm>
          {({ showConfirm }: DeleteConfirmChildren) => (
            <DeleteIconButton
              onClick={showConfirm.bind(this, {
                messageId: search.problemType?.id === 9
                          ? intl.formatMessage({ id: 'strings.membershipDeleteConfirm' })
                          : intl.formatMessage({ id: 'strings.defaultDeleteConfirm' }),
                callback: (result) => {
                  if (result) {
                    if (search.problemType?.id === 9) {
                      this._deleteMembership(person);
                    } else {
                      onClickAction();
                    }
                  }
                }
              })}
            />
          )}
        </DeleteConfirm>
      );
    }

    return null;
  }

  private _renderInactivateButton = (person: Person): ReactNode => {

    const { intl } = this.props;

    if (person.memberStatus === 'ACTIVE') {
      return (
        <DeleteConfirm>
          {({ showConfirm }: DeleteConfirmChildren) => (
            <Button
              variant={'contained'}
              color="primary"
              onClick={showConfirm.bind(this, {
                messageId: intl.formatMessage({ id: 'strings.membershipInactivateConfirm' }),
                callback: (result) => {
                  if (result) {
                    this._inactivateMembership(person);
                  }
                }
              })}
            >
              <FormattedMessage id={'strings.inactivate'} />
            </Button>
          )}
        </DeleteConfirm>
      );
    } else {
      return person.memberStatus;
    }
  }

  private _inactivateMembership = (person: Person): void => {

    const {
      inactivateMembership,
      fetchSearch,
      personsReducer: {
        pagination,
        search 
      },
      locale: {
        appLanguage: {
          collation
        }
      }
    } = this.props;

    inactivateMembership({
      idMembership: person.idHomeClubMembership,
      onComplete: ({ error }) => {
        if (error) {
          window.alert('Unable to inactivate membership.');
        }

        fetchSearch({
          page: pagination.page,
          params: { ...search, collation }
        });
      },
    });
  }

  private _deleteMembership = (person: Person): void => {

    const {
      deleteMembership,
      fetchSearch,
      personsReducer: {
        pagination,
        search 
      },
      locale: {
        appLanguage: {
          collation
        }
      }
    } = this.props;

    deleteMembership({
      idMembership: person.idHomeClubMembership,
      onComplete: ({ error }) => {
        if (error) {
          window.alert('Unable to delete membership.');
        }

        fetchSearch({
          page: pagination.page,
          params: { ...search, collation }
        });
      },
    });
  }

  private _handlePageChange = (page: number) => {
    const {
      personsReducer: {
        pagedPersons,
        searchActive,
        search
      },
      fetchPersons,
      fetchSearch,
      changePage,
      locale: {
        appLanguage: {
          collation
        }
      }
    } = this.props;

    /*
      If the page was already fetched we don't need to get it again.
      Just dispatch an action to change page.
     */
    if (pagedPersons[page]) {
      changePage({ page });
    } else if (searchActive) {
      fetchSearch({ page, params: { ...search, collation } });
    } else {
      fetchPersons({ page });
    }
  }

  private _renderPagination = () => {
    const {
      personsReducer: {
        pagination: {
          page,
          limit,
          totalCount
        }
      }
    } = this.props;

    if (totalCount > limit) {
      return <Pagination page={page} limit={limit} totalCount={totalCount} onPageChange={this._handlePageChange}/>;
    }
    return null;
  }

  private _isPersonAlreadySelected = (personId): boolean => {
    const {
      selectedPersonIds = [],
    } = this.props;

    return selectedPersonIds.indexOf(personId) !== -1;
  }
}

export default injectIntl(connect((state: any) => ({
  personsReducer: state.personsReducer,
  locale: state.locale
}), {
  fetchPersons: personsActions.fetchPersons,
  inactivateMembership: membershipActions.inactivateMembership,
  deleteMembership: membershipActions.deleteMembership,
  changePage: personsActions.changePage,
  searchChanged: personsActions.searchChanged,
  fetchSearch: personsActions.fetchSearch,
  resetSearch: personsActions.resetSearch
})(PersonsTable));