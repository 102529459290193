import { take, fork, select } from 'redux-saga/effects';
import {
  PERSONS_REQUESTING,
  PERSONS_SUCCESS,
  PERSONS_ERROR,
  PERSONS_SEARCH_RESET,
  PERSONS_SEARCH_REQUESTING,
} from './constants';
import { APIRoute } from '@src/assets/config';
import { AUTH_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { normalizeSearchParams, stateTargetIdParams } from '@src/utils/storeUtils';

/*
  GET /admin/1/persons
 */
const fetchPersonsFlow = SagaFlowFactory.createPagedGETFlow({
  apiRoute: APIRoute.GET_PERSONS,
  itemType: 'persons',
  successType: PERSONS_SUCCESS,
  errorType: PERSONS_ERROR
});

/*
  GET /api/1/persons/search
 */
const fetchPersonsSearchFlow = SagaFlowFactory.createPagedGETFlow({
  apiRoute: APIRoute.SEARCH_PERSONS,
  itemType: 'persons',
  successType: PERSONS_SUCCESS,
  errorType: PERSONS_ERROR
});

/*
  GET /admin/1/persons/problematic/search
 */
const fetchProblematicPersonsSearchFlow = SagaFlowFactory.createPagedGETFlow({
  apiRoute: APIRoute.PROBLEMATIC_PERSONS_SEARCH,
  itemType: 'persons',
  successType: PERSONS_SUCCESS,
  errorType: PERSONS_ERROR
});

export default function * personsWatcher() {
  let token: string = '';

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
      page,
      limit,
      params
    } = yield take([
      PERSONS_REQUESTING,
      PERSONS_SEARCH_REQUESTING,
      PERSONS_SEARCH_RESET
    ]);

    const targetIdParams = yield select(stateTargetIdParams('personsReducer'));

    switch (type) {
      case PERSONS_REQUESTING:
        // todo: maybe a better way, this is a bit hacketyhackhack...
        if (targetIdParams.clubId) {
          yield fork(fetchPersonsSearchFlow, token, page, limit, { ...params, ...targetIdParams });
        } else {
          yield fork(fetchPersonsFlow, token, page, limit, { ...params, ...targetIdParams });
        }
        break;
      case PERSONS_SEARCH_REQUESTING:
        const { problemType } = params;
        const searchFlow = problemType ? fetchProblematicPersonsSearchFlow : fetchPersonsSearchFlow;

        yield fork(
          searchFlow,
          token,
          page,
          limit,
          { ...normalizeSearchParams(params), ...targetIdParams },
        );
        break;
      default:
    }
  }
}