import {
  CLUB_ADD_REQUESTING,
  CLUB_MEMBERS_REQUESTING,
  CLUB_MEMBERS_PAGE_CHANGE,
  CLUB_REQUESTING,
  CLUB_ACTIVATION_REQUEST,
  CLUB_VALIDATION_REQUEST,
  CLUB_UPDATE_ACTIVATE_IF_BILLS_PAID_REQUESTING,
  CLUB_MEMBERS_SEARCH_REQUEST,
  CLUB_MEMBERS_SEARCH_CHANGED,
  CLUB_MEMBER_ACTIVATION_REQUEST,
  CLUB_MEMBERS_SET_INITIAL_STATE,
  CLUB_MEMBERS_EXCEL_REQUESTING,
  CLUB_COURSE_GUIDE_REQUESTING,
  CLUB_FUNCTIONARIES_REQUESTING,
  CLUB_BASIC_INFO_EDIT_REQUESTING,
  CLUB_EXTRA_INFO_EDIT_REQUESTING,
  CLUB_SET_TARGET_ID_PARAMS, UPLOAD_HIO_EXCEL_REQUESTING, SET_HIO_INSURANCE_REQUESTING,
} from './constants';
import {
  DEFAULT_PAGINATION_LIMIT
} from '@src/assets/config';
import {
  ADMIN_CLUB_MEMBERS_REQUESTING,
  ADMIN_CLUB_MEMBERS_SEARCH_REQUEST,
} from '@src/store/club/constants';
import { createTargetIdParamsActionsWithType } from '@src/utils/storeUtils';
import { Action } from 'redux';

export const fetchClubMembers = ({
  page,
  clubId,
  limit = DEFAULT_PAGINATION_LIMIT
}: MembersTablePaginationAction) => {
  return {
    type: CLUB_MEMBERS_REQUESTING,
    page,
    limit,
    clubId,
  };
};

export const fetchAdminClubMembers = ({
  page,
  limit = DEFAULT_PAGINATION_LIMIT
}: MembersTablePaginationAction) => {
  return {
    type: ADMIN_CLUB_MEMBERS_REQUESTING,
    page,
    limit,
  };
};

export const fetchClubFunctionaries = ({
  clubId
}: FetchClubFunctionaries) => {
  return {
    type: CLUB_FUNCTIONARIES_REQUESTING,
    clubId
  };
};

export const changePage = ({
  page
}: MembersChangePageAction) => {
  return {
    type: CLUB_MEMBERS_PAGE_CHANGE,
    page
  };
};

export const fetchClub = ({
  clubId
}: FetchClub) => {
  return {
    type: CLUB_REQUESTING,
    clubId
  };
};
export const addClub = (params: ClubAdd) => {
  return {
    type: CLUB_ADD_REQUESTING,
    params
  };
};

export const editClubBasicInfo = ({
  clubId,
  params,
  onComplete,
}: EditClubBasicInfo) => {
  return {
    type: CLUB_BASIC_INFO_EDIT_REQUESTING,
    clubId,
    params: {
      ...params,
      onComplete,
    },
  };
};

export const editClubExtraInfo = ({
  clubId,
  params,
  onComplete,
}: EditClubExtraInfo) => {
  return {
    type: CLUB_EXTRA_INFO_EDIT_REQUESTING,
    clubId,
    params: {
      ...params,
      onComplete,
    },
  };
};

export const fetchClubCourseGuide = ({
  clubId
}: FetchClubCourseGuide) => {
  return {
    type:  CLUB_COURSE_GUIDE_REQUESTING,
    clubId
  };
};

export const setClubActivation = ({
  clubId,
  status
}: SetClubActivation) => {
  return {
    type: CLUB_ACTIVATION_REQUEST,
    clubId,
    params: {
      status
    }
  };
};

export const setClubValidation = ({
  clubId,
  validity
}: SetClubValidation) => {
  return {
    type: CLUB_VALIDATION_REQUEST,
    clubId,
    params: {
      validity
    }
  };
};

export const setClubMemberActivation = ({
  memberId,
  clubId,
  status,
  onComplete,
}: SetUserActivation) => {
  return {
    type: CLUB_MEMBER_ACTIVATION_REQUEST,
    memberId,
    params: {
      clubId,
      status,
      onComplete,
    }
  };
};

export const fetchMembersSearch = ({
  page,
  limit = DEFAULT_PAGINATION_LIMIT,
  params
}: SearchPaginationAction) => {
  return {
    type: CLUB_MEMBERS_SEARCH_REQUEST,
    page,
    limit,
    params,
  };
};

export const fetchAdminMembersSearch = ({
  page,
  limit = DEFAULT_PAGINATION_LIMIT,
  params
}: SearchPaginationAction) => {
  return {
    type: ADMIN_CLUB_MEMBERS_SEARCH_REQUEST,
    page,
    limit,
    params,
  };
};

export const resetSearch = ({
  clubId,
}: { clubId?: number }) => {
  return {
    type: CLUB_MEMBERS_SET_INITIAL_STATE,
    clubId,
  };
};

export const resetAdminSearch = () => {
  return {
    type: CLUB_MEMBERS_SET_INITIAL_STATE,
  };
};

export const searchChanged = (search: ClubMembersSearch) => {
  return {
    type: CLUB_MEMBERS_SEARCH_CHANGED,
    search
  };
};

export const setClubMembersInitialState = () => {
  return {
    type: CLUB_MEMBERS_SET_INITIAL_STATE,
  };
};

export const uploadClubMembersExcel = ({
  clubId,
  excel,
  columnHeader,
  activateIfBillsPaid,
  onComplete,
}: MemberExcelImport) => {
  return {
    type: CLUB_MEMBERS_EXCEL_REQUESTING,
    clubId,
    params: {
      excel,
      columnHeader,
      activateIfBillsPaid,
      onComplete,
    }
  };
};

export const updateActivateIfBillsPaid = ({
  clubId,
  activateIfBillsPaid,
  onComplete,
}: ActivateIfBillsPaidUpdate) => {
  return {
    type: CLUB_UPDATE_ACTIVATE_IF_BILLS_PAID_REQUESTING,
    clubId,
    onComplete,
    params: {
      activateIfBillsPaid,
      idClub: clubId,
      onComplete
    }
  };
};

export interface UploadHIOExcelPayload extends APICall {
  excel: File;
  columnHeaderClubId: string;
  columnHeaderMemberNo: string;
}
export interface UploadHIOExcelAction extends Action, UploadHIOExcelPayload {
  type: 'UPLOAD_HIO_EXCEL_REQUESTING';
}
export interface UploadHIOExcelSuccessAction extends Action {
  type: 'UPLOAD_HIO_EXCEL_SUCCESS';
}
export interface UploadHIOExcelErrorAction extends Action {
  type: 'UPLOAD_HIO_EXCEL_ERROR';
}
export const uploadHIOExcel = (payload: UploadHIOExcelPayload): UploadHIOExcelAction => ({
  type: UPLOAD_HIO_EXCEL_REQUESTING,
  ...payload,
});

export interface SetHIOInsurancePayload {
  action: 'enable' | 'disable' | string;
  idMembership: number;
}
export interface SetHIOInsuranceAction extends Action, SetHIOInsurancePayload {
  type: 'SET_HIO_INSURANCE_REQUESTING';
}
export interface SetHIOInsuranceSuccessAction extends Action {
  type: 'SET_HIO_INSURANCE_SUCCESS';
  id: number;
  insuranceIsValid: boolean;
}
export interface SetHIOInsuranceErrorAction extends Action {
  type: 'SET_HIO_INSURANCE_ERROR';
}
export const setHIOInsurance = (payload: SetHIOInsurancePayload): SetHIOInsuranceAction => ({
  type: SET_HIO_INSURANCE_REQUESTING,
  ...payload,
});

export const setTargetIdParams = createTargetIdParamsActionsWithType(CLUB_SET_TARGET_ID_PARAMS);
