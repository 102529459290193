import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import { DialogTitle, MuiThemeProvider, TextField } from '@material-ui/core';
import materialUITheme from '@src/theme/materialUITheme';
import { useIntl } from 'react-intl';
import { useState } from 'react';

function PersonIdModal({ open, error, onCancel, onProceed }) {
  const [name, setName] = useState(null);
  const intl = useIntl();

  const handleCancel = () => onCancel();
  const handleOk = () => onProceed(name);
  const handleNameInput = e => {
    setName(e.target.value);
  };

  // TODO consider searching a person by name

  return (
    <MuiThemeProvider theme={materialUITheme}>
      <Dialog
        open={Boolean(open)}
        maxWidth="xs"
        fullWidth={true}
        disableBackdropClick={true}
      >
        <DialogTitle>
          {intl.formatMessage({ id: 'strings.moveScoreToOtherPlayerTitle' })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {intl.formatMessage({ id: 'strings.moveScoreToOtherPlayer' })}
          </DialogContentText>
          <TextField
            required={true}
            margin={'normal'}
            fullWidth={true}
            placeholder={intl.formatMessage({ id: 'strings.personId' })}
            value={name}
            onChange={handleNameInput}
          />
          <DialogContentText color="secondary">
            {error}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} >
            {intl.formatMessage({ id: 'buttons.cancel' })}
          </Button>
          <Button onClick={handleOk} color="secondary" autoFocus={true} >
            {intl.formatMessage({ id: 'buttons.ok' })}
          </Button>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  );
}

export default PersonIdModal;
