import {
  ATTACH_COURSE_REQUESTING, CHANGE_COURSE_STATE_REQUESTING,
  CLUB_COURSE_ADD_REQUESTING, CLUB_COURSE_COPY_REQUESTING,
  CLUB_COURSE_DELETE_REQUESTING,
  CLUB_COURSE_EDIT_REQUESTING,
  CLUB_COURSE_GET_REQUESTING, CLUB_COURSE_PUBLISH_REQUESTING, DETACH_COURSE_REQUESTING, GET_COURSE_PCC_REQUESTING
} from '@src/store/clubCourse/constants';
import { Action } from 'redux';

export const getClubCourse = ({
  id
}: ClubCourseGet) => ({
  type: CLUB_COURSE_GET_REQUESTING,
  clubCourseId: id,
});

export const addClubCourse = (params: ClubCourseAdd) => ({
  type: CLUB_COURSE_ADD_REQUESTING,
  params
});

export const editClubCourse = (params: ClubCourseEdit) => ({
  type: CLUB_COURSE_EDIT_REQUESTING,
  clubCourseId: params.id,
  params
});

export const deleteClubCourse = ({
  id,
  clubId,
  onComplete,
}: ClubCourseDelete) => ({
  type: CLUB_COURSE_DELETE_REQUESTING,
  clubCourseId: id,
  params: {
    clubId,
    onComplete,
  },
});

export const copyClubCourse = ({
  courseId,
  onComplete,
}: ClubCourseCopy) => ({
  type: CLUB_COURSE_COPY_REQUESTING,
  clubCourseId: courseId,
  params: {
    onComplete,
  },
});

export const publishClubCourse = ({
  courseId,
  effectiveDate,
  onComplete,
}: ClubCoursePublish) => ({
  type: CLUB_COURSE_PUBLISH_REQUESTING,
  clubCourseId: courseId,
  params: {
    effectiveDate,
    onComplete,
  },
});

export const addCourseChildToParent = ({
  courseId,
  partialCourseId,
  onComplete,
}: AddCourseChildToParent) => ({
  type: ATTACH_COURSE_REQUESTING,
  params: {
    courseId,
    partialCourseId,
    onComplete,
  }
});

export const removeCourseChildFromParent = ({
  courseId,
  partialCourseId,
  onComplete,
}: RemoveCourseChildFromParent) => ({
  type: DETACH_COURSE_REQUESTING,
  params: {
    courseId,
    partialCourseId,
    onComplete,
  }
});

export interface GetCoursePCCPayload extends APICall {
  courseId: number;
  page: number;
  date?: string | null;
  limit?: number;
}
export interface GetCoursePCCAction extends GetCoursePCCPayload, Action {
  type: 'GET_COURSE_PCC_REQUESTING';
}
export interface GetCoursePCCSuccessAction extends Action {
  type: 'GET_COURSE_PCC_SUCCESS';
}
export interface GetCoursePCCErrorAction extends Action {
  type: 'GET_COURSE_PCC_ERROR';
}
export const getCoursePCC = (args: GetCoursePCCPayload): GetCoursePCCAction => ({
  type: GET_COURSE_PCC_REQUESTING,
  ...args,
});

export interface ChangeCourseStatePayload extends APICall {
  courseId: number;
  status: 'activate' | 'deactivate';
}
export interface ChangeCourseStateAction extends ChangeCourseStatePayload, Action {
  type: 'CHANGE_COURSE_STATE_REQUESTING';
}
export interface ChangeCourseStateSuccessAction extends Action {
  type: 'CHANGE_COURSE_STATE_SUCCESS';
}
export interface ChangeCourseStateErrorAction extends Action {
  type: 'CHANGE_COURSE_STATE_ERROR';
}
export const changeCourseState = (args: ChangeCourseStatePayload): ChangeCourseStateAction => ({
  type: CHANGE_COURSE_STATE_REQUESTING,
  ...args,
});
