import React, { createRef, PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Dialog,
  DialogTitle,
  DialogContent
} from '@material-ui/core';
import { connect } from 'react-redux';
import * as organizationActions from '@src/store/organization/actions';
import { ModalContentOverlayLoader } from '@src/components/modals/ui';
import { OrganizationForm } from './OrganizationForm';

interface Props {
  targetIdParams: TargetIdParams;
  organizationId: number | undefined;
  open: boolean;
  onClose: () => void;
  getOrganization: (params: OrganizationGet) => any;
  organizationState: OrganizationState;
}

class OrganizationModalConnected extends PureComponent<Props, {}> {
  actionsContainerRef = createRef<HTMLDivElement>();

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      organizationId,
      getOrganization
    } = this.props;

    if (nextProps.organizationId !== organizationId) {
      if (nextProps.organizationId) {
        getOrganization({
          id: nextProps.organizationId,
        });
      }
    }
  }

  render() {
    const {
      open,
      organizationId,
      onClose,
      organizationState: {
        requesting
      }
    } = this.props;

    return (
      <Dialog
        open={open}
        fullWidth={true}
      >
        <DialogTitle>
          {organizationId && <FormattedMessage id={'scenes.organizations.modal.edit.title'} />}
          {!organizationId && <FormattedMessage id={'scenes.organizations.modal.add.title'} />}
        </DialogTitle>

        <DialogContent>
          {requesting && <ModalContentOverlayLoader/>}
          <OrganizationForm
            targetIdParams={this.props.targetIdParams}
            organizationId={organizationId}
            parentContext={'MODAL'}
            onClose={onClose}
            actionsContainerRef={this.actionsContainerRef}
          />
        </DialogContent>

        <div ref={this.actionsContainerRef} />
      </Dialog>
    );
  }
}

const OrganizationModal = connect((state: any) => ({
  organizationState: state.organizationReducer
}), {
  getOrganization: organizationActions.getOrganization
})(OrganizationModalConnected);

export {
  OrganizationModal
};