import { createMuiTheme } from '@material-ui/core/styles';

const materialUITheme = createMuiTheme({
  palette: {
    primary: {
      light: '#32a75e',
      main: '#268d51',
      dark: '#166939',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
  overrides: {
    MuiDialog: {
      paper: {
        zIndex: 1,
      },
    },
    MuiInputBase: {
      root: {
        minWidth: '12em',
      },
    },
    MuiDialogTitle: {
      root: {
        borderBottom: '1px solid rgb(238, 238, 238)',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0 24px 24px',
      },
    },
    MuiDialogActions: {
      root: {
        borderTop: '1px solid rgb(238, 238, 238)',
        padding: '14px 24px',
        margin: 0,
      },
    },
    MuiPickerDTToolbar: {
      toolbar: {
        '@global': {
          'h4': {
            fontSize: 28,
          },
        },
      },
    },
    MuiTypography: {
      root: {
        '@global': {
          'b, strong': {
            fontWeight: 500,
          },
        },
      },
    },
  },
});

export default materialUITheme;
