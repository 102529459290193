export const MESSAGES_REQUESTING = 'MESSAGES_REQUESTING';
export const MESSAGES_CHANGE_PAGE = 'MESSAGES_CHANGE_PAGE';
export const MESSAGES_SUCCESS = 'MESSAGES_SUCCESS';
export const MESSAGES_ERROR = 'MESSAGES_ERROR';

export const MESSAGES_RESET = 'MESSAGES_RESET';

export const MESSAGES_SET_INITIAL_STATE = 'MESSAGES_SET_INITIAL_STATE';

export const MESSAGES_SEARCH_CHANGED = 'MESSAGES_SEARCH_CHANGED';
export const MESSAGES_SEARCH_REQUESTING = 'MESSAGES_SEARCH_REQUESTING';
export const MESSAGES_SEARCH_RESET = 'MESSAGES_SEARCH_RESET';

export const MESSAGES_FETCH_ALL_SENDERS = 'MESSAGES_FETCH_ALL_SENDERS';
export const MESSAGES_FETCH_ALL_SENDERS_SUCCESS = 'MESSAGES_FETCH_ALL_SENDERS_SUCCESS';
export const MESSAGES_FETCH_ALL_SENDERS_ERROR = 'MESSAGES_FETCH_ALL_SENDERS_ERROR';

export const MESSAGES_GET_RECIPIENTS_REQUESTING = 'MESSAGES_GET_RECIPIENTS_REQUESTING';
export const MESSAGES_GET_RECIPIENTS_SUCCESS = 'MESSAGES_GET_RECIPIENTS_SUCCESS';
export const MESSAGES_GET_RECIPIENTS_ERROR = 'MESSAGES_GET_RECIPIENTS_ERROR';