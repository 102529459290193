import React, { ChangeEvent, Fragment, PureComponent, ReactNode } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import {
  Button,
  FormControl,
  TextField,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead
} from '@material-ui/core';
import Select from '@src/components/forms/Select';
import HeadHelmet from 'src/components/seo/HeadHelmet';
import {
  ContentHeader,
  ContentHeaderTitle,
  ContentWrap,
  SearchWrapper,
  SearchActions,
  ContentLoader
} from 'src/components/layouts/ui';
import * as organizationsActions from '@src/store/organizations/actions';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import { handleSearchFieldOnKeyDownEnterSniff, targetIdParamsChanged } from '@src/utils/storeUtils';
import { Pagination } from '@src/components/pagination/Pagination';
import { OrganizationModal } from '@src/components/scenes/organization/components/OrganizationModal';
import { OrganizationMembersModal } from '@src/components/scenes/organization/components/OrganizationMembersModal';
import { EditIconButton } from '@src/components/buttons/buttons';
import RoleScopeGuard from '@src/components/access-control/RoleScopeGuard';
import { organizationCompare } from '@src/utils/OrganizationUtils';
import { setLanguage } from '@src/store/locale/actions';

interface Props extends RouteComponentProps<any>, WrappedComponentProps {
  targetIdParams: TargetIdParams;
  organizationsState: OrganizationsState;
  fetchOrganizations: (params?: TablePaginationAction) => any;
  changePage: (params: ChangePageAction) => any;
  searchChanged: (params: OrganizationsSearch) => any;
  fetchSearch: (params: SearchPaginationAction) => any;
  resetSearch: (params: any) => any;
  setTargetIdParams: (params: SetTargetIdParams) => any;
  locale: AppLocale;
}

type State = {
  modalOpen: boolean;
  membersModalOpen: boolean;
  organizationIdToEdit?: number;
  organizationIdPersons?: number;
  organizationNameForPersonsModal?: string,
};

class OrganizationsConnected extends PureComponent<Props, State> {
  state = {
    modalOpen: false,
    membersModalOpen: false,
    organizationIdToEdit: undefined,
    organizationIdPersons: undefined,
    organizationNameForPersonsModal: undefined,
  };

  UNSAFE_componentWillMount() {
    const {
      organizationsState: {
        requesting,
        successful,
        targetIdParams,
      },
      fetchOrganizations,
      setTargetIdParams,
      locale: {
        appLanguage: {
          collation
        }
      }
    }
      = this.props;

    if (targetIdParamsChanged(targetIdParams, this.props.targetIdParams)) {
      setTargetIdParams({
        resetState: true,
        targetIdParams: this.props.targetIdParams,
      });
      fetchOrganizations({
        page: DEFAULT_PAGINATION_PAGE,
        params: { collation }
      });
    } else if (!requesting && !successful) {
      fetchOrganizations({
        page: DEFAULT_PAGINATION_PAGE,
        params: { collation }
      });
    }
  }

  render() {
    const { targetIdParams } = this.props;
    const {
      organizationIdPersons,
      organizationIdToEdit,
      modalOpen,
      membersModalOpen,
      organizationNameForPersonsModal,
    } = this.state;

    return (
      <Fragment>
        <HeadHelmet titleId={'navigation.organizations'} />
        <ContentWrap>
          <ContentHeader>
            <ContentHeaderTitle>
              <FormattedMessage id={'navigation.organizations'} />
            </ContentHeaderTitle>
            <RoleScopeGuard>
              {({ isFederationScope, isClubScope }) =>
                Boolean((isClubScope && targetIdParams.clubId) || isFederationScope) && (
                  <Button
                    variant="contained"
                    onClick={() => this.setState({
                      organizationIdToEdit: undefined,
                      modalOpen: true
                    })}
                  >
                    <FormattedMessage id={'scenes.organizations.addNewLabel'} />
                  </Button>
                )}
            </RoleScopeGuard>
            {this._renderSearch()}
          </ContentHeader>

          {this._renderTable()}

          <OrganizationModal
            targetIdParams={targetIdParams}
            organizationId={organizationIdToEdit}
            open={modalOpen}
            onClose={() => {
              this.setState({
                modalOpen: false
              });
            }}
          />

          <OrganizationMembersModal
            targetIdParams={targetIdParams}
            organizationId={organizationIdPersons}
            open={membersModalOpen}
            organizationName={organizationNameForPersonsModal}
            onClose={() => {
              this.setState({
                membersModalOpen: false,
                organizationNameForPersonsModal: undefined,
              });
            }}
          />
        </ContentWrap>
      </Fragment>
    );
  }

  private _renderSearch = (): ReactNode => {
    const {
      organizationsState: {
        requesting,
        search,
        searchActive
      },
    } = this.props;

    const disableSearch: boolean = this._disableSearch();

    return (
      <SearchWrapper>
        <TextField
          disabled={requesting}
          label={<FormattedMessage id={'strings.searchTerm'} />}
          value={search.searchTerm}
          style={{ marginRight: '1em' }}
          onChange={this._searchTermChanged}
          onKeyDown={(e: React.KeyboardEvent<any>) =>
            handleSearchFieldOnKeyDownEnterSniff(e, this._performSearch, disableSearch)
          }
        />
        <FormControl disabled={requesting}>
          <Select
            selected={search.status}
            options={[
              {
                id: 'INACTIVE',
                name: this.props.intl.formatMessage({ id: 'strings.statusInactive' }),
              },
              {
                id: 'ACTIVE',
                name: this.props.intl.formatMessage({ id: 'strings.statusActive' }),
              }
            ]}
            onChangeCallback={(value) => {
              this._searchStatusChanged(value || '');
            }}
            labelText={<FormattedMessage id={'scenes.organizations.search.state'}/>}
            placeholderText={<FormattedMessage id={'scenes.organizations.search.state'}/>}
          />
        </FormControl>
        <SearchActions
          isSearchActive={searchActive}
          isApiRequestActive={requesting}
          performSearchAction={this._performSearch}
          resetSearchAction={this._resetSearch}
          isSearchDisabled={disableSearch}
        />
      </SearchWrapper>
    );
  }

  private _disableSearch = (): boolean => {
    const {
      organizationsState: {
        requesting,
        search: {
          searchTerm,
          status
        }
      }
    } = this.props;

    return (requesting || (searchTerm === '' && status === ''));
  }

  private _searchStatusChanged = (value: any) => {
    const { searchChanged } = this.props;
    const status: ActivityStatus = value ? value : undefined;
    searchChanged({
      status
    });
  }

  private _searchTermChanged = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { searchChanged } = this.props;
    const {
      currentTarget: {
        value,
      }
    } = e;

    searchChanged({
      searchTerm: value
    });
  }

  private _performSearch = () => {
    const {
      fetchSearch,
      organizationsState: {
        search
      },
      targetIdParams,
      locale: {
        appLanguage: {
          collation
        }
      }
    } = this.props;

    fetchSearch({
      page: DEFAULT_PAGINATION_PAGE,
      params: { ...search, ...targetIdParams, collation }
    });
  }

  private _resetSearch = () => {
    const {
      resetSearch,
      targetIdParams,
    } = this.props;
    resetSearch({
      params: {
        ...targetIdParams
      }
    });
  }

  private _renderTable = (): ReactNode => {
    const {
      organizationsState: {
        requesting,
        successful,
        pagedOrganizations,
        pagination: {
          page
        }
      }
    } = this.props;

    if (!requesting) {
      return (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id={'strings.name'} />
                </TableCell>
                <TableCell>
                  <FormattedMessage id={'strings.email'} />
                </TableCell>
                <TableCell>
                  <FormattedMessage id={'scenes.organizations.table.headers.members'} />
                </TableCell>
                <TableCell>
                  <FormattedMessage id={'strings.state'} />
                </TableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                successful &&
                pagedOrganizations[page] &&
                Object.keys(pagedOrganizations[page]).sort((a, b): number => {
                    return organizationCompare(
                        pagedOrganizations[page][a],
                        pagedOrganizations[page][b],
                        this.props.locale.appLanguage.langName
                    );
                  }
                ).map((key: string) =>
                  this._renderOrganizationRow(pagedOrganizations[page][key])
                )
              }
            </TableBody>
          </Table>
          {this._renderPagination()}
        </>
      );
    } else {
      return (
        <ContentLoader visible={requesting} />
      );
    }
  }

  private _renderOrganizationRow = (organization: Organization) => {
    const { targetIdParams } = this.props;

    return (
      <TableRow key={organization.id}>
        <TableCell>{organization.name}</TableCell>
        <TableCell>{organization.email}</TableCell>
        <TableCell>
          <Button
            color="primary"
            onClick={() => this.setState({
              organizationIdPersons: organization.id,
              membersModalOpen: true,
              organizationNameForPersonsModal: organization.name,
            })}
          >
            <FormattedMessage id={'scenes.organizations.table.action.showMembers'} />
          </Button>
        </TableCell>
        <TableCell>
          {organization.status === 'ACTIVE' ?
            this.props.intl.formatMessage({ id: 'strings.statusActive' }) :
            this.props.intl.formatMessage({ id: 'strings.statusInactive' })
          }
        </TableCell>
        <RoleScopeGuard>
          {({ isFederationScope, isClubScope }) =>
              Boolean((isClubScope && targetIdParams.clubId) || isFederationScope) && (
        <TableCell>
          <EditIconButton
            onClick={() =>
              this.setState({
                organizationIdToEdit: organization.id,
                modalOpen: true,
              })
            }
          />
        </TableCell>
            )}
        </RoleScopeGuard>
      </TableRow>
    );
  }

  private _handlePageChange = (page: number) => {
    const {
      organizationsState: {
        pagedOrganizations,
        searchActive,
        search
      },
      fetchOrganizations,
      fetchSearch,
      changePage,
      targetIdParams,
      locale: {
        appLanguage: {
          collation
        }
      }
    } = this.props;

    /*
      If the page was already fetched we don't need to get it again.
      Just dispatch an action to change page.
     */
    if (pagedOrganizations[page]) {
      changePage({
        params: {
          ...targetIdParams
        },
        page
      });
    } else if (searchActive) {
      fetchSearch({ page, params: { ...search, ...targetIdParams, collation } });
    } else {
      fetchOrganizations({
        page,
        params: { collation }
      });
    }
  }

  private _renderPagination = (): ReactNode => {
    const {
      organizationsState: {
        pagination: {
          page,
          limit,
          totalCount
        }
      }
    } = this.props;

    if (totalCount > limit) {
      return <Pagination page={page} limit={limit} totalCount={totalCount} onPageChange={this._handlePageChange}/>;
    }

    return null;
  }
}

const Organizations = injectIntl(withRouter(connect((state: any) => ({
  organizationsState: state.organizationsReducer,
  locale: state.locale
}), {
  fetchOrganizations: organizationsActions.fetchOrganizations,
  changePage: organizationsActions.changePage,
  searchChanged: organizationsActions.searchChanged,
  fetchSearch: organizationsActions.fetchSearch,
  resetSearch: organizationsActions.resetSearch,
  setTargetIdParams: organizationsActions.setTargetIdParams,
  setLocale: setLanguage,
})(OrganizationsConnected)));

export default Organizations;