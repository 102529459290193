import {
  ORGANIZATION_GET_REQUESTING,
  ORGANIZATION_ADD_REQUESTING,
  ORGANIZATION_DELETE_REQUESTING,
  ORGANIZATION_EDIT_REQUESTING,
  ORGANIZATION_GET_ERROR,
  ORGANIZATION_ADD_ERROR,
  ORGANIZATION_DELETE_ERROR,
  ORGANIZATION_EDIT_ERROR,
  ORGANIZATION_GET_SUCCESS,
  ORGANIZATION_ADD_SUCCESS,
  ORGANIZATION_DELETE_SUCCESS,
  ORGANIZATION_EDIT_SUCCESS,
  ORGANIZATION_PERSONS_ERROR,
  ORGANIZATION_PERSONS_REQUESTING,
  ORGANIZATION_PERSONS_SUCCESS
} from './constants';
import { formDefaultErrorState, formDefaultRequestingState, parseErrorFromAction } from '@src/utils/storeUtils';

const initialState: OrganizationState = {
  organization: undefined,
  requesting: false,
  successful: false,
  error: [],
  organizationPersons: {
    persons: [],
    totalCount: 0,
    requesting: false,
    successful: true,
    error: [],
  }
};

export const organizationReducer = (state: OrganizationState = initialState, action: any) => {
  switch (action.type) {
    case ORGANIZATION_GET_REQUESTING:
      return {
        ...formDefaultRequestingState(state),
        organization: undefined,
      };

    case ORGANIZATION_ADD_REQUESTING:
    case ORGANIZATION_DELETE_REQUESTING:
    case ORGANIZATION_EDIT_REQUESTING:
      return formDefaultRequestingState(state);

    case ORGANIZATION_GET_ERROR:
    case ORGANIZATION_ADD_ERROR:
    case ORGANIZATION_DELETE_ERROR:
    case ORGANIZATION_EDIT_ERROR:
      return formDefaultErrorState(state, action);

    case ORGANIZATION_GET_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
        organization: action.data,
      };

    case ORGANIZATION_ADD_SUCCESS:
    case ORGANIZATION_DELETE_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
      };

    case ORGANIZATION_EDIT_SUCCESS:
      const { data } = action;
      return {
        ...state,
        requesting: false,
        successful: true,
        organization: data,
      };

    case ORGANIZATION_PERSONS_REQUESTING: {
      return {
        ...state,
        organizationPersons: {
          requesting: true,
          successful: false,
          persons: [],
          totalCount: 0,
          error: [],
        },
      };
    }

    case ORGANIZATION_PERSONS_SUCCESS: {
      return {
        ...state,
        requesting: false,
        successful: true,
        organizationPersons: {
          ...action.data,
          requesting: false,
          successful: true,
          error: [],
        },
      };
    }

    case ORGANIZATION_PERSONS_ERROR: {
      return {
        ...state,
        organizationPersons: {
          persons: [],
          requesting: false,
          successful: false,
          error: state.organizationPersons.error.concat([parseErrorFromAction(action)]),
        },
      };
    }

    default:
      return state;
  }
};