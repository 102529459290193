import { API_ROOT, APIRoute } from '@src/assets/config';

export function loginApi (email: string, password: string, clientId: string) {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('password', password);
  formData.append('client_id', clientId);
  return fetch(`${API_ROOT}${APIRoute.POST_LOGIN}`, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Accept': 'application/json'
    },
    body: formData,
  })
    .then(response => response.json() )
    .then(json => {
      if (json.error) {
        throw json.error;
      }

      return json;
    })
    .catch((error) => { throw error; });
}