import {
  OFFER_ADD_REQUESTING, OFFER_DELETE_REQUESTING, OFFER_EDIT_REQUESTING,
  OFFER_GET_REQUESTING
} from '@src/store/offer/constants';

export const getOffer = ({
  id
}: OfferGet) => {
  return {
    type: OFFER_GET_REQUESTING,
    offerId: id,
  };
};

export const addOffer = (params: OfferAdd) => {
  return {
    type: OFFER_ADD_REQUESTING,
    params
  };
};

export const editOffer = (params: OfferEdit) => {
  return {
    type: OFFER_EDIT_REQUESTING,
    offerId: params.id,
    params
  };
};

export const deleteOffer = ({
  id,
  onComplete,
}: OfferDelete) => {
  return {
    type: OFFER_DELETE_REQUESTING,
    offerId: id,
    params: {
      onComplete,
    },
  };
};