/*
  PUT /admin/1/club/{id}/validation
 */
import { tokenAPIPut } from '@src/utils/storeUtils';
import { API_ROOT, APIRoute } from '@src/assets/config';
import { call, put } from 'redux-saga/effects';
import { CLUB_VALIDATION_SUCCESS, CLUB_ERROR } from '@src/store/club/constants';

export function * setClubValidationFlow(token: string, clubId: number, params: any) {
  try {
    const club = yield call(() => {
      const url = `${API_ROOT}${APIRoute.GET_CLUB}/${clubId}/validation`;
      return tokenAPIPut(url, token, params);
    });

    if (club) {
      const {
        id,
        validTo,
      } = club;

      yield put({
        type: CLUB_VALIDATION_SUCCESS,
        id,
        validTo,
      });
    }

  } catch (error) {
    window.console.error(error);
    yield put({ type: CLUB_ERROR, error });
  }

  return true;
}