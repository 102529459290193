import React, { ChangeEvent, Component, Fragment } from 'react';
import { FormattedMessage, FormattedDate, WrappedComponentProps, injectIntl } from 'react-intl';
import {
  ContentWrap,
  ContentHeader,
  ContentHeaderTitle,
  ContentLoader,
  SearchWrapper,
  SearchActions
} from '@src/components/layouts/ui';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  Switch,
  Button,
  Select,
  FormControl,
  FormGroup,
  InputLabel, MenuItem
} from '@material-ui/core';
import * as clubsActions from '@src/store/clubs/actions';
import * as clubActions from '@src/store/club/actions';
import { connect } from 'react-redux';
import { DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import { Pagination } from '@src/components/pagination/Pagination';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import { handleSearchFieldOnKeyDownEnterSniff } from '@src/utils/storeUtils';
import { RouteComponentProps, withRouter } from 'react-router';
import moment from 'moment';
import styled from 'styled-components';
import { clubCompare } from '@src/utils/ClubUtils';
import { confirm } from '@src/components/modals/Confirm';
import { ClubModal } from '@src/components/modals/ClubModal';

interface Props extends RouteComponentProps<any> {
  clubsReducer: ClubsState;
  changePage: (params: ChangePageAction) => any;
  searchChanged: (params: ClubsSearch) => any;
  fetchSearch: (params: SearchPaginationAction) => any;
  resetSearch: () => any;
  setClubActivation: (params: SetClubActivation) => any;
  setClubValidation: (params: SetClubValidation) => any;
  locale: AppLocale;
}

interface State {
  searchActivatedValue: string;
  modalOpen: boolean;
}

const classes = styled.div ({
  innerWrap: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '650px',
    marginRight: '30px',
  },
});

class ClubsConnected extends Component<Props & WrappedComponentProps, State> {
  constructor(props: Props & WrappedComponentProps) {
    super(props);
    this.state = {
      searchActivatedValue: '',
      modalOpen: false,
    };
  }

  render() {
    const {
      clubsReducer: {
        requesting
      }
    } = this.props;
    const {
        modalOpen } = this.state;
    return (
      <Fragment>
        <HeadHelmet titleId={'navigation.clubs'} />
        <ContentWrap>
          <ContentHeader>
            <ContentHeaderTitle><FormattedMessage id={'scenes.clubs.sectionTitle'} /></ContentHeaderTitle>
            {/* <Button variant="contained" onClick={() => this.setState(
              {clubIdToEdit: undefined, modalOpen: true})}> */}
            <Button variant="contained" onClick={() => this.setState({modalOpen: true})}>
              <FormattedMessage id={'scenes.clubs.addNewClubButtonLabel'} />
            </Button>
            {this._renderClubSearch()}
            
          </ContentHeader>
          {this._renderClubsTable(this.props)}
          <ContentLoader visible={requesting}/>
          <ClubModal
            open={modalOpen}
            onClose={() => {
              this.setState({
                modalOpen: false,
              });
            }}
          />
          {!requesting && this._renderPagination()}
        </ContentWrap>
      </Fragment>
    );
  }

  private _renderClubsTable = (props: Props): React.ReactNode => {
    const {
      clubsReducer: {
        requesting,
        successful,
        pagedClubs,
        pagination: {
          page
        }
      },
    } = props;

    if (!requesting) {
      return (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><FormattedMessage id={'strings.name'} /></TableCell>
              <TableCell><FormattedMessage id={'strings.phone'} /></TableCell>
              <TableCell><FormattedMessage id={'strings.email'} /></TableCell>
              <TableCell><FormattedMessage id={'strings.city'} /></TableCell>
              <TableCell><FormattedMessage id={'strings.status'} /></TableCell>
              <TableCell><FormattedMessage id={'strings.season'} /></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              successful &&
              pagedClubs[page] &&
              Object.keys(pagedClubs[page]).sort((a, b): number => {
                return clubCompare(
                    pagedClubs[page][a],
                    pagedClubs[page][b],
                    this.props.locale.appLanguage.langName
                );
              }).map((key: string) =>
                this._renderClub(pagedClubs[page][key])
              )
            }
          </TableBody>
        </Table>
      );
    } else {
      return null;
    }
  }

  private _renderClub = (club: Club) => {
    const {
      match: {
        url,
      },
      history
    } = this.props;

    return (
      <TableRow key={club.id}>
        <TableCell>
          <Button
            color={'primary'}
            href={`${url}/${club.id}`}
            onClick={(e: any) => {
              e.preventDefault();
              history.push(`${url}/${club.id}`);
            }}
          >
            {club.name} ({club.nameAbbreviation})
          </Button>
        </TableCell>
        <TableCell>{club.phone}</TableCell>
        <TableCell>{club.email}</TableCell>
        <TableCell>{club.city ? club.city : '-'}</TableCell>
        <TableCell>
          <Switch
            color={'primary'}
            checked={club.status === 'ACTIVE'}
            onChange={(event: Object, isInputChecked: boolean) => {
              this._updateClubStatus(club.id, isInputChecked);
            }}
          />
          {club.status}
        </TableCell>
        <TableCell>
          <Switch
              color={'primary'}
              checked={!!(club.validTo && moment().isBefore(club.validTo))}
              onChange={(event: Object, isInputChecked: boolean) => {
                this._updateClubValidation(club.id, isInputChecked);
              }}
          />
          {club.validTo ? <FormattedDate value={club.validTo} /> : '-'}
        </TableCell>
      </TableRow>
    );
  }

  private _renderClubSearch = (): React.ReactNode => {
    const {
      clubsReducer: {
        requesting,
        search,
        searchActive
      },
      searchChanged
    } = this.props;

    const disableSearch: boolean = this._disableSearch();

    return (
      <SearchWrapper>
        <div className={classes.innerWrap}>
          <FormControl>
            <FormGroup row={true}>
              <TextField
                  disabled={requesting}
                  name="searchTerm"
                  label={<FormattedMessage id={'strings.searchTerm'}/>}
                  value={search.searchTerm}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const {
                      currentTarget: {
                        value,
                      }
                    } = e;

                    searchChanged({
                      searchTerm: value
                    });
                  }}
                  style={{marginRight: '1em'}}
                  onKeyDown={(e: React.KeyboardEvent<any>) =>
                      handleSearchFieldOnKeyDownEnterSniff(e, this._performSearch, disableSearch)
                  }
              />
              <TextField
                  disabled={requesting}
                  name="searchCity"
                  label={<FormattedMessage id={'strings.city'}/>}
                  value={search.city}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const {
                      currentTarget: {
                        value,
                      }
                    } = e;
                    searchChanged({city: value});
                  }}
                  style={{marginRight: '1em'}}
                  onKeyDown={(e: React.KeyboardEvent<any>) =>
                      handleSearchFieldOnKeyDownEnterSniff(e, this._performSearch, disableSearch)
                  }
              />
            </FormGroup>
        </FormControl>

          <FormGroup row={true}>
            <FormControl disabled={requesting} margin="normal" style={{marginRight: '1em'}}>
              <InputLabel htmlFor="search-club-status">
                <FormattedMessage id={'scenes.clubs.search.status'}/>
              </InputLabel>
              <Select
                  disabled={requesting}
                  value={search.clubStatus}
                  inputProps={{
                    name: 'club-status',
                    id: 'search-club-status',
                  }}
                  onChange={({target}: ChangeEvent<HTMLSelectElement>) => {
                    searchChanged({clubStatus: target.value as ClubStatus});
                  }}
              >
                <MenuItem value={'ACTIVE'}><FormattedMessage id={'strings.statusActive'}/></MenuItem>
                <MenuItem value={'INACTIVE'}><FormattedMessage id={'strings.statusPassive'}/></MenuItem>
                <MenuItem value={'ANY'}><FormattedMessage id={'strings.statusAny'}/></MenuItem>
              </Select>
            </FormControl>
            <FormControl disabled={requesting} margin="normal" style={{marginRight: '1em'}}>
              <InputLabel htmlFor="search-club-validity">
                <FormattedMessage id={'scenes.clubs.search.validity'}/>
              </InputLabel>
              <Select
                  disabled={requesting}
                  value={search.clubValidity}
                  inputProps={{
                    name: 'club-validity',
                    id: 'search-club-validity',
                  }}
                  onChange={({target}: ChangeEvent<HTMLSelectElement>) => {
                    searchChanged({clubValidity: target.value as ClubValidity});
                  }}
              >
                <MenuItem value={'VALID'}><FormattedMessage id={'strings.validityInEffect'}/></MenuItem>
                <MenuItem value={'INVALID'}><FormattedMessage id={'strings.validityExpired'}/></MenuItem>
                <MenuItem value={'ANY'}><FormattedMessage id={'strings.validityAny'}/></MenuItem>
              </Select>
            </FormControl>
            <SearchActions
                isSearchActive={searchActive}
                isApiRequestActive={requesting}
                performSearchAction={this._performSearch}
                resetSearchAction={this._resetSearch}
                isSearchDisabled={disableSearch}
            />
          </FormGroup>
        </div>
      </SearchWrapper>
    );
  }

  private _updateClubStatus = (clubId: number, isClubActive: boolean): void => {
    const { setClubActivation } = this.props;
    const status = isClubActive ? 'ACTIVE' : 'INACTIVE';
    setClubActivation({
      clubId,
      status,
    });
  }

  private _updateClubValidation = (clubId: number, isValid: boolean): void => {
    const { setClubValidation, intl } = this.props;

    if (!isValid) {
      confirm({
        message: intl.formatMessage({
          id: 'scenes.clubs.invalidateClubAndMembers',
        }),
        options: {
          cancelText: intl.formatMessage({ id: 'buttons.cancel' }),
          okText: intl.formatMessage({ id: 'buttons.ok' }),
        }
      }).then((result: any) => {
        if (result) {
          setClubValidation({ clubId: clubId, validity: false });
        }
      });
    } else {
      setClubValidation({ clubId: clubId, validity: true });
    }
  }

  private _handlePageChange = (page: number) => {
    const {
      clubsReducer: {
        pagedClubs,
        search
      },
      fetchSearch,
      changePage,
      locale: {
        appLanguage: {
          collation
        }
      }
    } = this.props;

    /*
      If the page was already fetched we don't need to get it again.
      Just dispatch an action to chage page.
     */
    if (pagedClubs[page]) {
      changePage({ page });
    } else {
      fetchSearch({ page, params: { ...search, collation } });
    }
  }

  private _renderPagination = () => {
    /*
      - Shared component of anchors for dispatching new page requests.
      - we know what the current page is from clubsReducer.pagination
      - on each click we dispatch { type: CLUBS_REQUEST_PAGINATED, page: 1..n }
     */
    const {
      clubsReducer: {
        pagination: {
          page,
          limit,
          totalCount
        }
      }
    } = this.props;

    if (totalCount > limit) {
      return <Pagination page={page} limit={limit} totalCount={totalCount} onPageChange={this._handlePageChange}/>;
    }

    return null;
  }

  private _disableSearch = (): boolean => {
    const {
      clubsReducer: {
        requesting,
        search: {
          searchTerm,
          city,
          clubStatus,
          clubValidity,
        }
      }
    } = this.props;

    return (requesting || (searchTerm === '' && clubStatus === '' && city === '' && clubValidity === ''));
  }

  private _performSearch = () => {
    const {
      fetchSearch,
      clubsReducer: { search },
      locale: {
        appLanguage: {
          collation
        }
      }
    } = this.props;

    fetchSearch({
      page: DEFAULT_PAGINATION_PAGE,
      params: { ...search, collation }
    });
  }

  private _resetSearch = () => {
    const { resetSearch } = this.props;
    resetSearch();
  }
}

const Clubs = injectIntl(withRouter(connect((state: any) => ({
  clubsReducer: state.clubsReducer,
  locale: state.locale
}), {
  changePage: clubsActions.changePage,
  searchChanged: clubsActions.searchChanged,
  fetchSearch: clubsActions.fetchSearch,
  resetSearch: clubsActions.resetSearch,
  setClubActivation: clubActions.setClubActivation,
  setClubValidation: clubActions.setClubValidation,
})(ClubsConnected)));

export {
  Clubs
};