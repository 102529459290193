import React, { PureComponent, Fragment } from 'react';
import { FormattedMessage, injectIntl, FormattedDate, WrappedComponentProps } from 'react-intl';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import {
  ButtonLoaderWrap,
  ContentHeader,
  ContentHeaderTitle,
  ContentWrap
} from '@src/components/layouts/ui';
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormHelperText,
  Button,
  withStyles,
  FormGroup, Theme
} from '@material-ui/core';
import moment from 'moment';
import { connect } from 'react-redux';
import * as reportsActions from '@src/store/reports/actions';
import LocalizedDatePicker from '@src/components/forms/LocalizedDatePicker';

const styles = (theme: Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
});

type Props = {
  classes: any;
  getGreenCardReport: (params: GetGreenCardReport) => any;
};

type State = {
  loading: boolean;
  timeSpan: string;
  reportType: string;
  startDate: Date;
  endDate: Date;
};

class ReportsGreenCardScene extends PureComponent<Props & WrappedComponentProps, State> {
  state = {
    loading: false,
    timeSpan: 'custom',
    reportType: 'pdf',
    startDate: new Date(),
    endDate: new Date()
  };

  render() {
    const { classes, intl } = this.props;
    const { startDate, endDate, loading } = this.state;
    // let isCustomTimeSpan: boolean = timeSpan === 'custom';

    const currentMonth = moment().startOf('month');
    const currentMonthName = <FormattedDate month={'long'} value={currentMonth.format()} />;

    const previousMonth: string = moment(currentMonth)
      .subtract(1, 'month')
      .format();
    const previousMonthName = <FormattedDate month={'long'} value={previousMonth} />;

    return (
      <Fragment>
        <HeadHelmet titleId={'navigation.reportGreenCard'} />
        <ContentWrap>
          <ContentHeader>
            <ContentHeaderTitle>
              <FormattedMessage id={'scenes.reports.greenCard.sectionTitle'} />
            </ContentHeaderTitle>
          </ContentHeader>

          <form autoComplete="off">
            <FormGroup row={true}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="timeSpan">
                  <FormattedMessage id={'scenes.reports.greenCard.timeFrame'}/>
                </InputLabel>
                <Select
                  value={this.state.timeSpan}
                  onChange={this._handleChange}
                  inputProps={{
                    name: 'timeSpan',
                    id: 'timeSpan',
                  }}
                >
                  <MenuItem value={'thismonth'}>
                    <FormattedMessage
                      id={'scenes.reports.greenCard.thisMonth'}
                      values={{ month: currentMonthName }}
                    />
                  </MenuItem>
                  <MenuItem value={'lastmonth'}>
                    <FormattedMessage
                      id={'scenes.reports.greenCard.lastMonth'}
                      values={{ month: previousMonthName }}
                    />
                  </MenuItem>
                  <MenuItem value={'thisyear'}><FormattedMessage id={'scenes.reports.greenCard.thisYear'}/></MenuItem>
                  <MenuItem value={'custom'}><FormattedMessage id={'scenes.reports.greenCard.custom'}/></MenuItem>
                </Select>
                <FormHelperText>
                  <FormattedMessage id={'scenes.reports.greenCard.chooseTimeSpan'}/>
                </FormHelperText>
              </FormControl>

              <Fragment>
                <FormControl className={classes.formControl}>
                  <LocalizedDatePicker
                    label={intl.formatMessage({ id: 'strings.dateStarts' })}
                    value={startDate}
                    onChange={(newStartDate: Date) => {
                      this.setState({ timeSpan: 'custom', startDate: newStartDate });
                    }}
                    emptyLabel={intl.formatMessage({ id: 'scenes.offers.search.startDate' })}
                    clearable={true}
                  />
                </FormControl>

                <FormControl className={classes.formControl}>
                  <LocalizedDatePicker
                    label={intl.formatMessage({ id: 'strings.dateEnds' })}
                    value={endDate}
                    onChange={(newEndDate: Date) => {
                      this.setState({ timeSpan: 'custom', endDate: newEndDate });
                    }}
                    emptyLabel={intl.formatMessage({ id: 'scenes.offers.search.endsDate' })}
                    clearable={true}
                  />
                </FormControl>
              </Fragment>

            </FormGroup>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="reportType">
                <FormattedMessage id={'strings.downloadableFileType'} />
              </InputLabel>
              <Select
                value={this.state.reportType}
                onChange={this._handleFileTypeChange}
                inputProps={{
                  name: 'reportType',
                  id: 'reportType',
                }}
              >
                <MenuItem value={'pdf'}>PDF</MenuItem>
                <MenuItem value={'excel'}>Excel</MenuItem>
                <MenuItem value={'csv'}>CSV</MenuItem>
              </Select>
              <FormHelperText>
                <FormattedMessage id={'strings.chooseDownloadableFileType'} />
              </FormHelperText>
            </FormControl>
          </form>
          <ButtonLoaderWrap loading={loading} style={{ marginTop: 30 }}>
            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={this._handleLoadOnClick}
            >
              <FormattedMessage id={'buttons.download'} />
            </Button>
          </ButtonLoaderWrap>
        </ContentWrap>
      </Fragment>
    );
  }

  private _handleFileTypeChange = event => {
    this.setState({[event.target.name]: event.target.value} as any);
  }

  private _handleChange = event => {
    const dates: {start: Date, end: Date} | null = event.target.value !== 'custom' ?
      this._generateDates(event.target.value) :
      null;

    if (!dates) {
      this.setState({[event.target.name]: event.target.value} as any);
    } else {
      this.setState({
        startDate: dates.start,
        endDate: dates.end,
        [event.target.name]: event.target.value
      } as any);
    }
  }

  private _generateDates = (value: string): {start: Date, end: Date} => {
    let start: Date | null = null;
    let end: Date | null = null;
    switch (value) {
      case 'thismonth':
        start = moment().startOf('month').toDate();
        end = moment().endOf('month').toDate();
        break;
      case 'lastmonth':
        start = moment().subtract(1, 'month').startOf('month').toDate();
        end = moment().subtract(1, 'month').endOf('month').toDate();
        break;
      case 'thisyear':
        start = moment().startOf('year').toDate();
        end = moment().endOf('year').toDate();
        break;
      default:
        start = null;
        end = null;
    }
    return { start, end } as any;
  }

  private _handleLoadOnClick = (): boolean => {
    const { startDate, endDate } = this.state;

    if (endDate < startDate) {
      return false;
    }

    this.setState({ loading: true }, this._handleSending);

    return true;
  }

  private _handleSending = () => {
    const { reportType } = this.state;
    const { getGreenCardReport } = this.props;

    const startDate = moment(new Date(this.state.startDate)).format('YYYY-MM-DD');
    const endDate = moment(new Date(this.state.endDate)).format('YYYY-MM-DD');

    getGreenCardReport({
      startDate,
      endDate,
      reportType,
      onComplete: () => {
        this.setState({ loading: false });
      }
    });
  }
}

export default withStyles(styles as any)(injectIntl(connect(
  null, {
    getGreenCardReport: reportsActions.getGreenCardReport,
  },
)(ReportsGreenCardScene)));