import React, { Fragment } from 'react';
import {
  Typography
} from '@material-ui/core';
import { FormattedDate, FormattedTime } from 'react-intl';
import { MarkdownWithStyles } from '@src/components/layouts/ui';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';

interface Props {
  title?: string;
  content?: string;
  published?: Date;
  images: MessageImage[];
}

export const MessagePreview = ({
  title,
  content,
  published,
  images,
}: Props) => {
  const contentImageURL = images.find(img => img.type === 'ContentImage')?.url;
  const contentImageStyles: React.CSSProperties = {
    width: '100%',
  };

  return (
    <Fragment>
      {published && (
        <Typography>
          <FormattedDate
            value={(published != null ? moment.utc(published).local(false).toDate() : undefined)}
          />&nbsp;
          <FormattedTime
            value={(published != null ? moment.utc(published).local(false).toDate() : undefined)}
          />
        </Typography>
      )}

      {contentImageURL &&
        <img src={contentImageURL} style={contentImageStyles}/>
      }

      {title && (
        <Typography
          variant={'h6'}
          gutterBottom={true}
        >
          {title}
        </Typography>
      )}

      {content && (
        <MarkdownWithStyles>
          <ReactMarkdown source={content} />
        </MarkdownWithStyles>
      )}

    </Fragment>
  );
};