import React, { Component } from 'react';
import { SettingsScene } from '@src/components/scenes/settings/SettingsScene';

class AdminSettings extends Component {
  render() {
    return (
      <SettingsScene />
    );
  }
}

export {
  AdminSettings,
};