export const MAINTENANCE_GET_REQUESTING = 'MAINTENANCE_GET_REQUESTING';
export const MAINTENANCE_GET_SUCCESS = 'MAINTENANCE_GET_SUCCESS';
export const MAINTENANCE_GET_ERROR = 'MAINTENANCE_GET_ERROR';

export const MAINTENANCE_ADD_REQUESTING = 'MAINTENANCE_ADD_REQUESTING';
export const MAINTENANCE_ADD_SUCCESS = 'MAINTENANCE_ADD_SUCCESS';
export const MAINTENANCE_ADD_ERROR = 'MAINTENANCE_ADD_ERROR';

export const MAINTENANCE_EDIT_REQUESTING = 'MAINTENANCE_EDIT_REQUESTING';
export const MAINTENANCE_EDIT_SUCCESS = 'MAINTENANCE_EDIT_SUCCESS';
export const MAINTENANCE_EDIT_ERROR = 'MAINTENANCE_EDIT_ERROR';

export const MAINTENANCE_DELETE_REQUESTING = 'MAINTENANCE_DELETE_REQUESTING';
export const MAINTENANCE_DELETE_SUCCESS = 'MAINTENANCE_DELETE_SUCCESS';
export const MAINTENANCE_DELETE_ERROR = 'MAINTENANCE_DELETE_ERROR';

export const MAINTENANCE_READ_REQUESTING = 'MAINTENANCE_READ_REQUESTING';
export const MAINTENANCE_READ_SUCCESS = 'MAINTENANCE_READ_SUCCESS';
export const MAINTENANCE_READ_ERROR = 'MAINTENANCE_READ_ERROR';
