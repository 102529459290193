import { SHARED_DEFAULT_REDUCER_STATE } from '@src/assets/config';
import {
  ChangeInvalidatedScoresPageAction,
  GetInvalidatedScoresAction,
  GetInvalidatedScoresErrorAction,
  GetInvalidatedScoresSuccessAction,
  ResetInvalidatedScoreState,
} from '@src/store/invalidatedScores/actions';
import {
  INVALIDATED_SCORES_CHANGE_PAGE,
  INVALIDATED_SCORES_ERROR,
  INVALIDATED_SCORES_REQUESTING,
  INVALIDATED_SCORES_SUCCESS,
  RESET_INVALIDATED_SCORES,
} from '@src/store/invalidatedScores/constants';
import { formDefaultErrorState, formDefaultPagedState, fromDefaultPageChangeState } from '@src/utils/storeUtils';

const initialState: InvalidatedScoresState = {
  ...SHARED_DEFAULT_REDUCER_STATE,
  pagedScores: {},
};

type Action = GetInvalidatedScoresAction | GetInvalidatedScoresSuccessAction | GetInvalidatedScoresErrorAction
  | ChangeInvalidatedScoresPageAction | ResetInvalidatedScoreState;

export const invalidatedScoresReducer = (state: InvalidatedScoresState = initialState, action: Action) => {
  switch (action.type) {
    case INVALIDATED_SCORES_REQUESTING:
      return {
        ...state,
        requesting: true,
      };
    case INVALIDATED_SCORES_ERROR:
        return formDefaultErrorState(state, action);
    case INVALIDATED_SCORES_CHANGE_PAGE:
      return fromDefaultPageChangeState(state, action);
    case INVALIDATED_SCORES_SUCCESS:
      return formDefaultPagedState(state, action, 'pagedScores');
    case RESET_INVALIDATED_SCORES:
      return {
        ...initialState
      };
    default:
      return state;
  }
};