import { take, fork, all, takeEvery } from 'redux-saga/effects';
import {
  CLUB_ADD_REQUESTING,
  CLUB_RESET,
  CLUB_MEMBERS_REQUESTING,
  CLUB_MEMBERS_SUCCESS,
  CLUB_MEMBERS_ERROR,
  CLUB_REQUESTING,
  CLUB_ACTIVATION_REQUEST,
  CLUB_MEMBERS_SEARCH_REQUEST,
  CLUB_MEMBER_ACTIVATION_REQUEST,
  CLUB_MEMBERS_SEARCH_RESET,
  CLUB_MEMBERS_EXCEL_REQUESTING,
  ADMIN_CLUB_MEMBERS_REQUESTING,
  ADMIN_CLUB_MEMBERS_SEARCH_REQUEST,
  ADMIN_CLUB_MEMBERS_SEARCH_RESET,
  CLUB_UPDATE_ACTIVATE_IF_BILLS_PAID_REQUESTING,
  CLUB_COURSE_GUIDE_REQUESTING,
  CLUB_FUNCTIONARIES_REQUESTING,
  CLUB_FUNCTIONARIES_SUCCESS,
  CLUB_FUNCTIONARIES_ERROR,
  CLUB_BASIC_INFO_EDIT_REQUESTING,
  CLUB_EXTRA_INFO_EDIT_REQUESTING,
  CLUB_VALIDATION_REQUEST,
  UPLOAD_HIO_EXCEL_REQUESTING, SET_HIO_INSURANCE_REQUESTING,
} from './constants';
import {
  APIRoute,
  API_ROOT,
  DEFAULT_PAGINATION_PAGE,
  DEFAULT_PAGINATION_LIMIT
} from '@src/assets/config';
import {
  AUTH_ACTIVE_STATE_CHANGE,
  AUTH_SET,
  INITIAL_AUTH_ACTIVE_STATE_SET
} from '@src/store/auth/constants';
import { normalizeSearchParams } from '@src/utils/storeUtils';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { fetchClubFlow } from '@src/store/club/flows/fetch-club-flow';
import { setClubActivationFlow } from '@src/store/club/flows/set-club-activation-flow';
import { setClubValidationFlow } from '@src/store/club/flows/set-club-validation-flow';
import { setClubMemberActivationFlow } from '@src/store/club/flows/set-club-member-activation-flow';
import { setClubMembersExcelActivationFlow } from '@src/store/club/flows/set-club-members-excel-activation-flow';
import { setClubActivateIfBillsArePaidFlow } from '@src/store/club/flows/set-club-activate-if-bills-paid-flow';
import { fetchClubCourseGuide } from '@src/store/club/flows/fetch-club-course-guide-flow';
import { setClubExtraInfo } from '@src/store/club/flows/set-club-extra-info-flow';
import { setClubBasicInfoFlow } from '@src/store/club/flows/set-club-basic-info-flow';
import { uploadHIOExcelFlow } from '@src/store/club/flows/upload-hio-excel-flow';
import { setHIOInsuranceFlow } from '@src/store/club/flows/set-hio-insurance-flow';

/*
  GET|POST|PUT|DELETE /admin/1/partner/<id>
 */
const fetchPartnerCRUDFlow = SagaFlowFactory.createCRUDFlow({
    apiRoute: APIRoute.CLUB,
    typePrefix: 'CLUB',
    resetType: CLUB_RESET,
  });

/*
  GET /admin/1/club/{id}/members
 */
function * fetchClubMembersFlow(
  clubId: number,
  token: string,
  page: number,
  limit: number,
  params?: any
) {
  yield fork(SagaFlowFactory.createPagedGETFlow({
    urlRoute: `${API_ROOT}${APIRoute.GET_CLUB}/${clubId}/members`,
    itemType: 'members',
    successType: CLUB_MEMBERS_SUCCESS,
    errorType: CLUB_MEMBERS_ERROR
  }), token, page, limit, params);
}

/*
  GET /admin/1/club/members/search
 */
// const fetchMembersSearch = SagaFlowFactory.createPagedGETFlow({
//   apiRoute: APIRoute.SEARCH_CLUB_MEMBERS,
//   itemType: 'members',
//   successType: CLUB_MEMBERS_SUCCESS,
//   errorType: CLUB_MEMBERS_ERROR
// });

/*
  GET /admin/1/members/search
*/
const fetchAdminMembersSearch = SagaFlowFactory.createPagedGETFlow({
  apiRoute: APIRoute.ADMIN_MEMBERS_SEARCH,
  itemType: 'members',
  successType: CLUB_MEMBERS_SUCCESS,
  errorType: CLUB_MEMBERS_ERROR,
});

/*
  GET /admin/1/members
*/
const fetchAdminClubMembersFlow = SagaFlowFactory.createPagedGETFlow({
  apiRoute: APIRoute.ADMIN_MEMBERS,
  itemType: 'members',
  successType: CLUB_MEMBERS_SUCCESS,
  errorType: CLUB_MEMBERS_ERROR
});

/*
  GET /admin/1/functionaries
 */
const fetchClubFunctionaries = SagaFlowFactory.createGETFlow({
  urlRoute: `${API_ROOT}${APIRoute.GET_FUNCTIONARIES}`,
  successType: CLUB_FUNCTIONARIES_SUCCESS,
  errorType: CLUB_FUNCTIONARIES_ERROR,
});

function * clubWatcher() {
  let token: string = '';

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {

    const {
      type,
      page,
      limit,
      clubId,
      memberId,
      params,
    } = yield take([
      CLUB_ADD_REQUESTING,
      CLUB_MEMBERS_REQUESTING,
      CLUB_MEMBERS_SEARCH_REQUEST,
      CLUB_REQUESTING,
      CLUB_FUNCTIONARIES_REQUESTING,
      CLUB_ACTIVATION_REQUEST,
      CLUB_VALIDATION_REQUEST,
      CLUB_MEMBER_ACTIVATION_REQUEST,
      CLUB_MEMBERS_SEARCH_RESET,
      AUTH_ACTIVE_STATE_CHANGE,
      INITIAL_AUTH_ACTIVE_STATE_SET,
      CLUB_MEMBERS_EXCEL_REQUESTING,
      ADMIN_CLUB_MEMBERS_REQUESTING,
      ADMIN_CLUB_MEMBERS_SEARCH_REQUEST,
      ADMIN_CLUB_MEMBERS_SEARCH_RESET,
      CLUB_COURSE_GUIDE_REQUESTING,
      CLUB_BASIC_INFO_EDIT_REQUESTING,
      CLUB_EXTRA_INFO_EDIT_REQUESTING,
      CLUB_UPDATE_ACTIVATE_IF_BILLS_PAID_REQUESTING,
    ]);

    switch (type) {
      case CLUB_ADD_REQUESTING: 
        yield fork(fetchPartnerCRUDFlow, token, 'ADD', null, params);
        break;
      case CLUB_MEMBERS_REQUESTING:
        yield fork(
          fetchClubMembersFlow,
          clubId,
          token,
          page,
          limit,
        );
        break;
      case ADMIN_CLUB_MEMBERS_REQUESTING:
        yield fork(fetchAdminClubMembersFlow, token, page, limit);
        break;
      case CLUB_MEMBERS_SEARCH_RESET: {
        if (clubId) {
          yield fork(
            fetchClubMembersFlow,
            clubId,
            token,
            DEFAULT_PAGINATION_PAGE,
            DEFAULT_PAGINATION_LIMIT,
          );
        } else {
          yield fork(
            fetchAdminClubMembersFlow,
            token,
            DEFAULT_PAGINATION_PAGE,
            DEFAULT_PAGINATION_LIMIT,
          );
        }
        break;
      }
      case ADMIN_CLUB_MEMBERS_SEARCH_RESET: {
        yield fork(
          fetchAdminClubMembersFlow,
          token,
          DEFAULT_PAGINATION_PAGE,
          DEFAULT_PAGINATION_LIMIT,
        );
        break;
      }
      case CLUB_FUNCTIONARIES_REQUESTING: {
        yield fork(fetchClubFunctionaries, token, 0, 0, { clubId: clubId, type: 'condensed' });
        break;
      }
      case CLUB_MEMBERS_SEARCH_REQUEST:
      case ADMIN_CLUB_MEMBERS_SEARCH_REQUEST:
        yield fork(
          fetchAdminMembersSearch, token, page, limit, { ...normalizeSearchParams(params) }
        );
        break;
      case CLUB_REQUESTING:
        yield fork(fetchClubFlow, token, clubId);
        break;
      case CLUB_COURSE_GUIDE_REQUESTING:
        yield fork(fetchClubCourseGuide, token, clubId);
        break;
      case CLUB_ACTIVATION_REQUEST:
        yield fork(setClubActivationFlow, token, clubId, params);
        break;
      case CLUB_VALIDATION_REQUEST:
        yield fork(setClubValidationFlow, token, clubId, params);
        break;
      case CLUB_MEMBER_ACTIVATION_REQUEST:
        yield fork(setClubMemberActivationFlow, token, memberId, params);
        break;
      case CLUB_MEMBERS_EXCEL_REQUESTING:
        yield fork(setClubMembersExcelActivationFlow, token, clubId, params);
        break;
      case CLUB_BASIC_INFO_EDIT_REQUESTING:
        yield fork(setClubBasicInfoFlow, token, clubId, params);
        break;
      case CLUB_EXTRA_INFO_EDIT_REQUESTING:
        yield fork(setClubExtraInfo, token, clubId, params);
        break;
      case CLUB_UPDATE_ACTIVATE_IF_BILLS_PAID_REQUESTING:
        yield fork(setClubActivateIfBillsArePaidFlow, token, clubId, params);
        break;
      default:
    }
  }
}

export function* clubSpawnWatcher() {
  yield all([
    takeEvery(UPLOAD_HIO_EXCEL_REQUESTING, uploadHIOExcelFlow),
    takeEvery(SET_HIO_INSURANCE_REQUESTING, setHIOInsuranceFlow)
  ]);
}

export default clubWatcher;
