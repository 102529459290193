import { tokenAPIPost } from '@src/utils/storeUtils';
import { API_ROOT, APIRoute } from '@src/assets/config';

export type HandleNewAPIKeyGeneration = {
  vendorId: number;
};

export const handleNewAPIKeyGeneration = ({
  vendorId,
}: HandleNewAPIKeyGeneration) => {
  return tokenAPIPost(
    `${API_ROOT}${APIRoute.VENDOR}/${vendorId}/apiKey`,
    window.localStorage.getItem('authToken') as string,
    {},
  );
};