export const CLUB_COURSE_GET_REQUESTING = 'CLUB_COURSE_GET_REQUESTING';
export const CLUB_COURSE_GET_SUCCESS = 'CLUB_COURSE_GET_SUCCESS';
export const CLUB_COURSE_GET_ERROR = 'CLUB_COURSE_GET_ERROR';

export const CLUB_COURSE_ADD_REQUESTING = 'CLUB_COURSE_ADD_REQUESTING';
export const CLUB_COURSE_ADD_SUCCESS = 'CLUB_COURSE_ADD_SUCCESS';
export const CLUB_COURSE_ADD_ERROR = 'CLUB_COURSE_ADD_ERROR';

export const CLUB_COURSE_EDIT_REQUESTING = 'CLUB_COURSE_EDIT_REQUESTING';
export const CLUB_COURSE_EDIT_SUCCESS = 'CLUB_COURSE_EDIT_SUCCESS';
export const CLUB_COURSE_EDIT_ERROR = 'CLUB_COURSE_EDIT_ERROR';

export const CLUB_COURSE_DELETE_REQUESTING = 'CLUB_COURSE_DELETE_REQUESTING';
export const CLUB_COURSE_DELETE_SUCCESS = 'CLUB_COURSE_DELETE_SUCCESS';
export const CLUB_COURSE_DELETE_ERROR = 'CLUB_COURSE_DELETE_ERROR';

export const CLUB_COURSE_COPY_REQUESTING = 'CLUB_COURSE_COPY_REQUESTING';
export const CLUB_COURSE_COPY_SUCCESS = 'CLUB_COURSE_COPY_SUCCESS';
export const CLUB_COURSE_COPY_ERROR = 'CLUB_COURSE_COPY_ERROR';

export const CLUB_COURSE_PUBLISH_REQUESTING = 'CLUB_COURSE_PUBLISH_REQUESTING';
export const CLUB_COURSE_PUBLISH_SUCCESS = 'CLUB_COURSE_PUBLISH_SUCCESS';
export const CLUB_COURSE_PUBLISH_ERROR = 'CLUB_COURSE_PUBLISH_ERROR';

export const ATTACH_COURSE_REQUESTING = 'ATTACH_COURSE_REQUESTING';
export const ATTACH_COURSE_SUCCESS = 'ATTACH_COURSE_SUCCESS';
export const ATTACH_COURSE_ERROR = 'ATTACH_COURSE_ERROR';

export const DETACH_COURSE_REQUESTING = 'DETACH_COURSE_REQUESTING';
export const DETACH_COURSE_SUCCESS = 'DETACH_COURSE_SUCCESS';
export const DETACH_COURSE_ERROR = 'DETACH_COURSE_ERROR';

export const GET_COURSE_PCC_REQUESTING = 'GET_COURSE_PCC_REQUESTING';
export const GET_COURSE_PCC_SUCCESS = 'GET_COURSE_PCC_SUCCESS';
export const GET_COURSE_PCC_ERROR = 'GET_COURSE_PCC_ERROR';

export const CHANGE_COURSE_STATE_REQUESTING = 'CHANGE_COURSE_STATE_REQUESTING';
export const CHANGE_COURSE_STATE_SUCCESS = 'CHANGE_COURSE_STATE_SUCCESS';
export const CHANGE_COURSE_STATE_ERROR = 'CHANGE_COURSE_STATE_ERROR';
