import fi from './localizations/fi.json';
import sv from './localizations/sv.json';
import en from './localizations/en.json';

// Let's swap the dateFormat for sv lang
const svFI = {
  ...sv,
  strings: {
    ...sv.strings,
    dateFormat: 'd.M.yyyy',
    dateTimeFormat: 'd.M.yyyy HH.mm'
  },
};

export default [
  {
    code: 'fi-FI',
    displayName: 'Suomi',
    collation: 'utf8_swedish_ci',
    data: fi,
    langName: 'fi'
  },
  {
    code: 'sv-FI',
    displayName: 'Svenska',
    collation: 'utf8_swedish_ci',
    data: svFI,
    langName: 'sv'
  },
  {
    code: 'en-US',
    displayName: 'English',
    collation: 'utf8_unicode_ci',
    data: en,
    langName: 'en'
  }
] as AppLanguage[];
