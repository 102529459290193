import React, { ErrorInfo, PureComponent, ReactNode } from 'react';
import {
  Drawer,
  AppBar,
  Toolbar,
  Divider,
  FormControl,
  NativeSelect,
  Input, Theme
} from '@material-ui/core';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import { GGBOfficeBox } from '@src/assets/images';
import Navigation from '@src/components/navigation/Navigation';
import ProfileMenu from '@src/components/menu/ProfileMenu';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import * as authActions from '@src/store/auth/actions';
import { connect } from 'react-redux';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { GeneralErrorMessage } from '@src/components/layouts/ui';
import { Alert, AlertTitle } from '@material-ui/lab';
import * as maintenanceActions from '@src/store/maintenance/actions';

const drawerWidth: number = 230;

const styles = (theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  appFrame: {
    zIndex: 1,
    overflow: 'hidden',
    overflowX: 'auto',
    position: 'relative',
    display: 'flex',
    width: '100%',
    minHeight: '100vh',
  },
  appBar: {
    backgroundColor: 'white',
    color: 'black',
    boxShadow: 'none'
  },
  appBarContentWrap: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  appBarContentRightCol: {
    display: 'flex'
  },
  logoWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  'appBar-with-drawer': {
    width: `calc(100% - ${drawerWidth}px)`,
  },
  'appBar-without-drawer': {
    width: '100%'
  },
  'appBar-left': {
    marginLeft: drawerWidth,
  },
  'appBar-right': {
    marginRight: drawerWidth,
  },
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: 'white',
    padding: theme.spacing(3),
  },
});

interface OwnProps {
  auth: AuthState;
  maintenances: MaintenancesState;
  locale: AppLocale;
  setActiveState: (activeState: AuthActiveState) => any;
  children?: ReactNode;
  classes: any;
  readMaintenance: (params: MaintenanceDelete) => any;
}

interface State {
  value: string;
  hasError: boolean;
  error?: Error;
  info?: ErrorInfo;
}

type Props = OwnProps & RouteComponentProps<any> & WrappedComponentProps;

class LayoutConnected extends PureComponent<Props, State> {

  state: State = {
    value: '',
    hasError: false,
    error: undefined,
    info: undefined
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      auth: {
        activeState
      }
    } = nextProps;

    const activeClubId = this._getActiveClubId(activeState);
    const activePartnerId = this._getActivePartnerId(activeState);

    if (activeClubId) {
      this.setState({
        value: `club_${activeClubId}`,
      });
    } else if (activePartnerId) {
      this.setState({
        value: `partner_${activePartnerId}`
      });
    } else {
      this.setState({
        value: '',
      });
    }
  }

  componentDidCatch(error, info) {
    console.error(error.name + ':' + error.message);
    console.error(info.componentStack);
    this.setState({
      hasError: true,
      error: error,
      info: info
    });
  }

  render() {
    const { hasError } = this.state;
    const {
      // auth: {
      //   activeState,
      // },
      classes,
      children,
    } = this.props;

    const logoWrapper: ReactNode = (
      <div className={classNames(classes.toolbar, classes.logoWrap)}>
        <NavLink
          to={'/'}
        >
          <img src={GGBOfficeBox} height={36} />
        </NavLink>
      </div>
    );

    let drawer: ReactNode = (
      <Drawer
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {logoWrapper}
        <Divider />
        <Navigation />
      </Drawer>
    );

    // if (activeState) {
    //   const { roleType } = activeState;
    //   if (roleType === 'partner') {
    //     drawer = undefined;
    //   }
    // }

    const appBarClasses: Object[] = drawer ?
      [
        classes['appBar-left'],
        classes['appBar-with-drawer']
      ] :
      [
        classes['appBar-without-drawer']
    ];

    return (
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <AppBar
            position="absolute"
            className={classNames(classes.appBar, ...appBarClasses)}
          >
            <Toolbar>
              <div className={classes.appBarContentWrap}>
                <div style={!drawer ? {display: 'flex', alignItems: 'center'} : {}}>
                  {!drawer && (
                    <div style={{marginRight: 50}}>
                      {logoWrapper}
                    </div>
                  )}
                  {this._maybeRenderClubsPartnersDropdown()}
                </div>

                <div className={classes.appBarContentRightCol}>
                  <ProfileMenu />
                </div>
              </div>
            </Toolbar>
            <Divider />

          </AppBar>

          {drawer}
          <main className={classes.content}>
            <div className={classes.toolbar} />
            {this._maybeRenderMaintenanceMessages()}
            {!Boolean(hasError) && children}
            {Boolean(hasError) && <GeneralErrorMessage error={this.state.error} info={this.state.info}/>}
          </main>
        </div>
      </div>
    );
  }

  private _maybeRenderMaintenanceMessages = () => {
    const {
      maintenances: {
        allMaintenances
      },
      locale: {
        appLanguage
      }
    } = this.props;

    const render = allMaintenances.map((message) => (
        <Alert
            onClose={() => { this._handleOnMaintenanceRead(message.id); }}
            key={message.id}
            severity={'warning'}
            style={{ margin: '20px 0px' }}
        >
          <AlertTitle>{message[appLanguage.langName].title}</AlertTitle>
          {message[appLanguage.langName].message}
        </Alert>
    ));
    return (allMaintenances.length > 0 && render);
  }

  private _handleOnMaintenanceRead = (id: number) => {
    const { readMaintenance } = this.props;
    readMaintenance({ id });
  }

  private _maybeRenderClubsPartnersDropdown = () => {
    const {
      auth: {
        clubs,
        partners,
      },
      intl,
    } = this.props;

    if ((clubs && clubs.length) || (partners && partners.length)) {
      return (
        <FormControl style={{ marginRight: '1.8em', }}>
          <NativeSelect
            value={this.state.value}
            onChange={this._handleActiveStateChange}
            input={<Input name="activeStateInput" id="active-state-input" />}
          >
            {(clubs && clubs.length) && (
              <optgroup label={intl.formatMessage({id: 'navigation.clubs'})}>
                {clubs.sort((a, b) => {
                  return a.name ? a.name.localeCompare(b.name) : -1;
                }).map(club => (
                  <option key={club.id} value={`club_${club.id}`}>{club.name}</option>
                ))}
              </optgroup>
            )}
            {(partners && partners.length) && (
              <optgroup label={intl.formatMessage({id: 'navigation.partners'})}>
                {partners.map(partner => (
                  <option key={partner.id} value={`partner_${partner.id}`}>{partner.name}</option>
                ))}
              </optgroup>
            )}
          </NativeSelect>
        </FormControl>
      );
    }

    return null;
  }

  private _handleActiveStateChange = (e: any) => {
    const { history, setActiveState, auth: { roleInfo } } = this.props;
    const { currentTarget: { value } } = e;

    const partnerSelected = e.currentTarget.value.indexOf('partner_') !== -1;
    const selectedId = parseInt(value.substr(value.indexOf('_') + 1, value.length), 10);

    window.console.log(setActiveState);

    if (partnerSelected) {
      const { auth: { partners } } = this.props;
      if (partners) {
        const selectedItm = partners.find((partner: AuthUserPartner) => partner.id === selectedId );
        setActiveState({ partner: selectedItm!, roleInfo: roleInfo! });
        history.push(`/admin/partner-account/${selectedId}`);
      }
    } else {
      const { auth: { clubs } } = this.props;
      if (clubs) {
        const selectedItm = clubs.find((club: AuthUserClub) => club.id === selectedId );
        setActiveState({ club: selectedItm!, roleInfo: roleInfo! });
        history.push(`/admin/home-club/${selectedId}`);
      }
    }
  }

  private _getActiveClubId = (activeState?: AuthActiveState): number | undefined => {
    if (activeState && activeState.club) {
      return activeState.club.id;
    }

    return undefined;
  }

  private _getActivePartnerId = (activeState?: AuthActiveState): number | undefined => {
    if (activeState && activeState.partner) {
      return activeState.partner.id;
    }

    return undefined;
  }
}

const Layout = withRouter(injectIntl(connect((state: any) => ({
  auth: state.authReducer,
  maintenances: state.maintenancesReducer,
  locale: state.locale
}), {
  setActiveState: authActions.setActiveState,
  readMaintenance: maintenanceActions.readMaintenance,
})(withStyles(styles as any)(LayoutConnected))));

export default Layout;
