import { Action } from 'redux';
import {
  INVALIDATED_SCORES_CHANGE_PAGE,
  INVALIDATED_SCORES_REQUESTING,
  RESET_INVALIDATED_SCORES,
} from '@src/store/invalidatedScores/constants';

/*
  GET INVALIDATED SCORES
 */
export interface GetInvalidatedScoresActionPayload extends TablePaginationAction {
  personId: number;
}
export interface GetInvalidatedScoresAction extends Action, GetInvalidatedScoresActionPayload {
  type: 'INVALIDATED_SCORES_REQUESTING';
}
export interface GetInvalidatedScoresSuccessAction extends Action, PermissionsACL, PagedPayload {
  type: 'INVALIDATED_SCORES_SUCCESS';
  pagedScores: AcceptableScoreMap;
}
export interface GetInvalidatedScoresErrorAction extends Action {
  type: 'INVALIDATED_SCORES_ERROR';
}
export const getInvalidatedScores = (params: GetInvalidatedScoresActionPayload): GetInvalidatedScoresAction => ({
  type: INVALIDATED_SCORES_REQUESTING,
  ...params,
});

/*
  CHANGE PAGE
 */
export interface ChangeInvalidatedScoresPageActionPayload {
  page: number;
}
export interface ChangeInvalidatedScoresPageAction extends Action, ChangeInvalidatedScoresPageActionPayload {
  type: 'INVALIDATED_SCORES_CHANGE_PAGE';
}
export const changeInvalidatedScoresPage = (page: number): ChangeInvalidatedScoresPageAction => ({
  type: INVALIDATED_SCORES_CHANGE_PAGE,
  page,
});

/*
  Reset IS State
 */
export interface ResetInvalidatedScoreState extends Action {
  type: 'RESET_INVALIDATED_SCORES';
}
export const resetInvalidatedScores = () => ({
  type: RESET_INVALIDATED_SCORES,
});