import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getCoursePCC, GetCoursePCCPayload } from '@src/store/clubCourse/actions';
import { handleOnFunction } from '@src/utils/utils';
import { DEFAULT_PAGINATION_LIMIT } from '@src/assets/config';
import {
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { Pagination } from '@src/components/pagination/Pagination';
import { useIntl } from 'react-intl';
import LocalizedDatePicker from '@src/components/forms/LocalizedDatePicker';

interface OwnProps {
  courseId?: number;
  onLoading?(isLoading: boolean): void;
}

interface DispatchProps {
  getCoursePCC(args: GetCoursePCCPayload): void;
}

type Props = OwnProps & DispatchProps;

function ClubCoursePCC({
  courseId,
  onLoading,
  getCoursePCC,
}: Props) {
  const [page, setPage] = useState<number>(1);
  const [date, setDate] = useState<string | null>(null);
  const [PCCs, setPCCs] = useState<CoursePCC[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const { formatDate: fd, formatMessage: f } = useIntl();

  const handleOnComplete = ({ data }: APICallResult<{ coursePccs: CoursePCC[], totalCount: number }>) => {
    handleOnFunction(onLoading, false);
    setPCCs(data?.coursePccs || []);
    setTotalCount(data?.totalCount || 0);
  };

  useEffect(() => {
    if (courseId) {
      handleOnFunction(onLoading, true);
      getCoursePCC({
        courseId,
        page,
        date,
        onComplete: handleOnComplete,
      });
    }
  }, [courseId, page, date]);

  return (
    <>
      <FormControl margin="normal" fullWidth={true}>
        <LocalizedDatePicker
          emptyLabel={f({ id: 'strings.date' })}
          value={date}
          clearable={true}
          disableFuture={true}
          onChange={(newDate: Date) => {
            setDate(newDate !== null ? newDate.toISOString() : newDate);
          }}
        />
      </FormControl>

      <Table style={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            <TableCell>
              {f({ id: 'strings.date' })}
            </TableCell>
            <TableCell>
              {f({ id: 'strings.playingConditionCalculation' })}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(PCCs && PCCs.length) ? PCCs.map(pcc => (
            <TableRow key={pcc.id}>
              <TableCell>
                {fd(pcc.timeStart)}
              </TableCell>
              <TableCell>
                {pcc.pcc}
              </TableCell>
            </TableRow>
          )) : (
            <TableRow>
              <TableCell colSpan={2}>
                {f({ id: 'strings.noResults' })}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <Pagination
        page={page}
        limit={DEFAULT_PAGINATION_LIMIT}
        totalCount={totalCount}
        onPageChange={(newPage) => setPage(newPage)}
      />
    </>
  );
}

export default connect<{}, DispatchProps, OwnProps, StoreState>(null, {
  getCoursePCC,
})(ClubCoursePCC);