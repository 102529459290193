import {
  MESSAGES_REQUESTING,
  MESSAGES_SUCCESS,
  MESSAGES_ERROR,
  MESSAGES_CHANGE_PAGE,
  MESSAGES_RESET,
  MESSAGES_SET_INITIAL_STATE,
  MESSAGES_SEARCH_CHANGED,
  MESSAGES_SEARCH_REQUESTING,
  MESSAGES_SEARCH_RESET,
  MESSAGES_FETCH_ALL_SENDERS_SUCCESS,
  MESSAGES_GET_RECIPIENTS_SUCCESS,
} from './constants';
import { SHARED_DEFAULT_REDUCER_STATE } from '@src/assets/config';
import {
  formDefaultErrorState,
  formDefaultPagedState,
  formDefaultRequestingState,
  fromDefaultPageChangeState,
  optimisticPagedObjectUpdate, searchStateChangedReducerHelper
} from '@src/utils/storeUtils';
import { MESSAGE_EDIT_SUCCESS } from '@src/store/message/constants';
import { AUTH_ACTIVE_STATE_CHANGE } from '@src/store/auth/constants';

const initialState: MessagesState = {
  ...SHARED_DEFAULT_REDUCER_STATE,
  pagedMessages: {},
  search: {
    title: '',
    sender: undefined,
    starts: null,
    ends: null,
  },
  allSenders: [],
  recipients: [],
};

const messagesReducer = (state: MessagesState = initialState, action: any): MessagesState => {
  switch (action.type) {
    case MESSAGES_REQUESTING:
      return formDefaultRequestingState(state);

    case MESSAGES_SUCCESS:
      return formDefaultPagedState(state, action, 'pagedMessages');

    case MESSAGES_CHANGE_PAGE:
      return fromDefaultPageChangeState(state, action);

    case MESSAGES_ERROR:
      return formDefaultErrorState(state, action);

    case MESSAGES_SEARCH_RESET:
    case MESSAGES_RESET:
      return {
        ...initialState,
        requesting: true,
        targetIdParams: state.targetIdParams,
        allSenders: state.allSenders,
        recipients: state.recipients,
      };

    case MESSAGES_SET_INITIAL_STATE:
      return {
        ...initialState
      };

    case MESSAGE_EDIT_SUCCESS: {
      const {
        data,
      } = action;
      const page = state.pagination.page;

      const pagedMessages = optimisticPagedObjectUpdate(state.pagedMessages, page, { ...data });

      return {
        ...state,
        pagedMessages
      };
    }

    case MESSAGES_SEARCH_CHANGED:
      return searchStateChangedReducerHelper(state, action);

    case MESSAGES_SEARCH_REQUESTING: {
        let searchActive = state.searchActive;
        let pagedMessages = state.pagedMessages;
        let pagination = state.pagination;

        if (!searchActive) {
          searchActive = true;
          pagedMessages = {};
          pagination.totalCount = 0;
        }

        pagination.page = action.page;

        return {
          ...state,
          pagedMessages,
          pagination,
          searchActive,
          requesting: true
        };
      }

    case AUTH_ACTIVE_STATE_CHANGE: {
      return initialState;
    }

    case MESSAGES_FETCH_ALL_SENDERS_SUCCESS: {
      return {
        ...state,
        allSenders: action.senders,
      };
    }

    case MESSAGES_GET_RECIPIENTS_SUCCESS: {
      return {
        ...state,
        recipients: action.response,
      };
    }

    default:
      return state;
  }
};

export {
  messagesReducer,
};