import React from 'react';
import { connect } from 'react-redux';
import { getAdjustmentsInheritance } from '@src/store/acceptableScores/selectors';
import AdjustmentsInheritanceBar from '@src/components/scenes/member/components/AdjustmentsInheritanceBar';
import AdjustmentsInheritanceWrap from '@src/components/scenes/member/components/AdjustmentsInheritanceWrap';

interface OwnProps {
  id: number;
  adjustments?: ScoreAdjustment[];
}

interface StateProps {
  adjustmentsInheritance: AdjustmentInheritances;
}

type Props = OwnProps & StateProps;

function AdjustmentsInheritance({
  id,
  adjustmentsInheritance
}: Props) {
  const { inheritanceMap } = adjustmentsInheritance;
  const inherited = inheritanceMap[id];

  const renderAdjustment = (adjustment: number, idx: number) => (
    <AdjustmentsInheritanceBar
      key={`${idx}`}
      adjustmentId={adjustment}
      isOriginBar={adjustment === id}
    />
  );

  return (
    <AdjustmentsInheritanceWrap>
      {inherited && inherited.map(renderAdjustment)}
    </AdjustmentsInheritanceWrap>
  );
}

export default connect<StateProps, {}, OwnProps, StoreState>(state => ({
  adjustmentsInheritance: getAdjustmentsInheritance(state),
}))(AdjustmentsInheritance);