/**
 * A functionary comparison function for sort() with titleName ASC, name ASC, id ASC ordering.
 *
 * @param a The first functionary for comparison
 * @param b The second functionary for comparison
 * @returns Number Negative when a < b, zero when a == b, positive when a > b
 */
export const functionaryCompare = (a: Functionary, b: Functionary, l?: string): number => {
  let c: number = !a.functionaryTitle?.titleName
    ? !b.functionaryTitle?.titleName
      ? 0
      : -1
    : a.functionaryTitle.titleName.localeCompare(b.functionaryTitle.titleName, l);
  if (c === 0) {
    c = !a.titlePrecision
        ? !b.titlePrecision
            ? 0
            : -1
        : a.titlePrecision.localeCompare(b.titlePrecision ? b.titlePrecision : '', l);
  }
  if (c === 0) {
    c = !a.name ? !b.name ? 0 : -1 : a.name.localeCompare(b.name, l);
    if (c === 0) {
      c = b.id - a.id;
    }
  }

  return c;
};
/**
 * A functionary comparison function for sort() with priority DESC, titleName ASC, name ASC, id ASC ordering.
 *
 * @param a The first functionary for comparison
 * @param b The second functionary for comparison
 * @returns Number Negative when a < b, zero when a == b, positive when a > b
 */
export const functionaryCompareWithPriority = (a: Functionary, b: Functionary): number => {
  let c: number = !a.functionaryTitle?.priority
    ? !b.functionaryTitle?.priority
      ? 0
      : 1
    : b.functionaryTitle.priority - a.functionaryTitle.priority;
  if (c === 0) {
    c = !a.functionaryTitle?.titleName
      ? !b.functionaryTitle?.titleName
        ? 0
        : -1
      : a.functionaryTitle.titleName.localeCompare(b.functionaryTitle.titleName);
    if (c === 0) {
      c = !a.name ? !b.name ? 0 : -1 : a.name.localeCompare(b.name);
      if (c === 0) {
        c = b.id - a.id;
      }
    }
  }
  return c;
};
