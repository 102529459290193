import React, { Component } from 'react';
import FederationCompanies from '@src/components/scenes/federation/FederationCompanies';

class AdminFederationCompanies extends Component {
  render() {
    return (
      <FederationCompanies />
    );
  }
}

export {
  AdminFederationCompanies,
};