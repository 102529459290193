import {
  COMPANIES_CHANGE_PAGE,
  COMPANIES_REQUESTING,
  FEDERATION_COMPANIES_SEARCH_CHANGED,
  FEDERATION_COMPANIES_SEARCH_REQUEST,
  FEDERATION_COMPANIES_SEARCH_RESET,
} from './constants';
import { DEFAULT_PAGINATION_LIMIT } from '@src/assets/config';

export const fetchCompanies = ({
  page,
  limit = DEFAULT_PAGINATION_LIMIT,
  params
}: TablePaginationAction) => {
  return {
    type: COMPANIES_REQUESTING,
    page,
    limit,
    params
  };
};

export const changePage = ({
  page,
  params
}: ChangePageAction) => {
  return {
    type: COMPANIES_CHANGE_PAGE,
    page,
    params
  };
};

export const fetchSearch = ({
  page,
  limit = DEFAULT_PAGINATION_LIMIT,
  params
}: SearchPaginationAction) => {
  return {
    type: FEDERATION_COMPANIES_SEARCH_REQUEST,
    page,
    limit,
    params,
  };
};

export const searchChanged = (search: FederationCompaniesSearch) => {
  return {
    type: FEDERATION_COMPANIES_SEARCH_CHANGED,
    search
  };
};

export const resetSearch = ({params}) => {
  return {
    type: FEDERATION_COMPANIES_SEARCH_RESET,
    params
  };
};