import React, { PureComponent, ChangeEvent, RefObject } from 'react';
import { connect } from 'react-redux';
import * as clubServiceAction from '@src/store/clubService/actions';
import { FormattedMessage } from 'react-intl';
import {
  TextField,
  FormControl,
  InputLabel,
  InputAdornment,
  Select,
  MenuItem,

} from '@material-ui/core';
import { renderFormActions } from '@src/components/modals/ui';
import InputsValidator from '@src/components/forms/InputsValidator';
import AddressFields from '@src/components/forms/ui/AddressFields';
import DeleteConfirm, { DeleteConfirmChildren } from '@src/components/headless/DeleteConfirm';

interface Props {
  clubId: number;
  clubServiceId?: number | undefined;
  clubServiceState: ClubServiceState;
  parentContext?: 'MODAL' | 'PAGE';
  onClose?: () => void;
  addClubService: (params: ClubServiceAdd) => any;
  editClubService: (params: ClubServiceEdit) => any;
  deleteClubService: (params: ClubServiceDelete) => any;
  auth: AuthState;
  actionsContainerRef?: RefObject<HTMLElement>;
}

type State = {
  status: ActivityStatus;
  name: string;
  description: string | null;
  streetAddress: string | null;
  city: string | null;
  zip: string | null;
  otherInfo: string | null;
  price: number;
  homepage: string | null;
  email: string | null;
  phone: string | null;
  fax: string | null;
};

const initialState = {
  status: 'ACTIVE' as ActivityStatus,
  name: '',
  description: null,
  streetAddress: null,
  zip: null,
  city: null,
  otherInfo: null,
  price: 0,
  homepage: null,
  email: null,
  phone: null,
  fax: null
};

class ClubServiceFormConnected extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const {
      clubServiceId,
      clubServiceState: {
        clubService,
      }
    } = this.props;

    if (!clubServiceId) {
      this.state = initialState;
    } else if (clubService) {
      this.state = {
        ...clubService,
      } as any;
    } else {
      this.state = initialState;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      clubServiceId,
      clubServiceState: {
        clubService,
        requesting,
      },
    } = nextProps;

    if (!clubServiceId) {
      return; // Do nothing if we are adding new person
    } else if (requesting) {
      return; // Do nothing state is loading
    } else if (clubService) {
      this.setState({
        ...clubService as any
      });
    }
  }

  render() {
    const {
      clubServiceId,
      parentContext,
      actionsContainerRef,
    } = this.props;

    const {
      status,
      name,
      description,
      streetAddress,
      otherInfo,
      zip,
      city,
      price,
      homepage,
      email,
      phone,
      fax
    } = this.state;

    return (
      <InputsValidator values={{ name }}>
        {({ errorInputs, validateThenApply, validateValues }) => (
          <>
            <FormControl required={true} margin="normal">
              <InputLabel htmlFor="service-status">
                <FormattedMessage id={'strings.state'}/>
              </InputLabel>
              <Select
                inputProps={{
                  name: 'status',
                  id: 'service-status',
                }}
                value={status}
                onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                  this.setState({status: event.target.value as ActivityStatus});
                }}
              >
                <MenuItem value={'ACTIVE'}><FormattedMessage id={'strings.statusActive'}/></MenuItem>
                <MenuItem value={'INACTIVE'}><FormattedMessage id={'strings.statusInactive'}/></MenuItem>
              </Select>
            </FormControl>
            <TextField
              required={true}
              fullWidth={true}
              label={<FormattedMessage id={'strings.name'}/>}
              value={name}
              name="name"
              margin="normal"
              onChange={this._handleChange('name', validateValues)}
              error={errorInputs.name}
            />
            <TextField
              fullWidth={true}
              multiline={true}
              rowsMax="4"
              label={<FormattedMessage id={'strings.description'}/>}
              value={description || ''}
              name="description"
              margin="normal"
              onChange={this._handleChange('description')}
            />
            <TextField
              label={<FormattedMessage id={'strings.price'}/>}
              id="price"
              margin="normal"
              value={price}
              name="price"
              onChange={this._handleChange('price')}
              InputProps={{
                startAdornment: <InputAdornment position="start" style={{minWidth: 'initial'}}>€</InputAdornment>,
              }}
            />
            <TextField
              fullWidth={true}
              multiline={true}
              rowsMax="4"
              label={<FormattedMessage id={'strings.other'}/>}
              value={otherInfo || ''}
              name="otherInfo"
              margin="normal"
              onChange={this._handleChange('otherInfo')}
            />

            <AddressFields
              streetAddress={streetAddress || ''}
              city={city || ''}
              zip={zip || ''}
              onChange={(args) => this.setState({ ...args } as any)}
            />

            <TextField
              fullWidth={true}
              label={<FormattedMessage id={'strings.phone'}/>}
              value={phone || ''}
              margin="normal"
              name="phone"
              onChange={this._handleChange('phone')}
            />
            <TextField
              fullWidth={true}
              label={<FormattedMessage id={'strings.fax'}/>}
              value={fax || ''}
              margin="normal"
              name="fax"
              onChange={this._handleChange('fax')}
            />
            <TextField
              fullWidth={true}
              label={<FormattedMessage id={'strings.email'}/>}
              value={email || ''}
              margin="normal"
              name="email"
              onChange={this._handleChange('email')}
            />
            <TextField
              fullWidth={true}
              label={<FormattedMessage id={'strings.website'}/>}
              value={homepage || ''}
              margin="normal"
              name="homepage"
              onChange={this._handleChange('homepage')}
            />

            <DeleteConfirm>
              {({ showConfirm }: DeleteConfirmChildren) => (
                renderFormActions({
                  id: clubServiceId,
                  parentContext,
                  onClose: this._handleOnClose,
                  onUpdate: validateThenApply.bind(this, this._handleUpdate),
                  onCreate: validateThenApply.bind(this, this._handleCreate),
                  disabled: false,
                  containerRef: actionsContainerRef,
                  onDelete: () => {
                    showConfirm({
                      callback: this._handleOnDelete,
                    });
                  },
                })
              )}
            </DeleteConfirm>
          </>
        )}
      </InputsValidator>
    );
  }

  // private _handleTextFieldChange = event => {
  //   const { currentTarget: { name, value } } = event;
  //   this.setState({ [name]: value } as any);
  // }

  private _handleChange = (name: string, fn?: () => any) => (
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      this.setState({
        [name as any]: event.target.value,
      } as any, fn);
    }
  )

  private _handleOnClose = () => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  }

  private _handleCreate = () => {
    const { addClubService } = this.props;
    const params = this._formParams();

    addClubService({
      ...params,
      onComplete: this._handleOnComplete,
    });
  }

  private _handleUpdate = () => {
    const { editClubService } = this.props;
    const {
      clubServiceState: {
        clubService
      }
    } = this.props;

    if (!clubService) {
      return;
    }

    const params = this._formParams();

    editClubService({
      id: clubService.id,
      ...params,
      onComplete: this._handleOnComplete,
    });
  }

  private _formParams = () => {
    const { clubId } = this.props;

    const {
      status,
      name,
      description,
      otherInfo,
      price,
      homepage,
      email,
      phone,
      fax,
      streetAddress,
      zip,
      city
    } = this.state;

    return {
      clubId: clubId,
      status,
      name,
      description,
      otherInfo,
      price,
      homepage,
      email,
      phone,
      fax,
      streetAddress,
      zip,
      city
    };
  }

  private _handleOnComplete = (result: any) => {
    if (result.error) {
      window.alert(result.error.message);
    } else {
      this._handleOnClose();
    }
  }

  private _handleOnDelete = (bool: boolean) => {
    const { deleteClubService, clubServiceId, clubId } = this.props;
    if (bool && clubServiceId) {
      deleteClubService({
        clubId,
        id: clubServiceId,
        onComplete: this._handleOnClose,
      });
    }
  }
}

const ClubServiceForm = connect((state: any) => ({
  clubServiceState: state.clubServiceReducer,
  auth: state.authReducer,
}), {
  addClubService: clubServiceAction.addClubService,
  editClubService: clubServiceAction.editClubService,
  deleteClubService: clubServiceAction.deleteClubService,
})(ClubServiceFormConnected);

export {
  ClubServiceForm
};
