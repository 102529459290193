import {
  PARTNERS_REQUESTING,
  PARTNERS_CHANGE_PAGE
} from './constants';
import { DEFAULT_PAGINATION_LIMIT } from '@src/assets/config';

const fetchPartners = ({
  page,
  limit = DEFAULT_PAGINATION_LIMIT,
  params
}: TablePaginationAction) => {
  return {
    type: PARTNERS_REQUESTING,
    page,
    limit,
    params
  };
};

const changePage = ({
  page,
}: ChangePageAction) => {
  return {
    type: PARTNERS_CHANGE_PAGE,
    page,
  };
};

export {
  fetchPartners,
  changePage
};