import { take, fork, select } from 'redux-saga/effects';
import {
  FUNCTIONARIES_REQUESTING,
  FUNCTIONARIES_SUCCESS,
  FUNCTIONARIES_ERROR,
  FUNCTIONARIES_SEARCH_RESET,
  FUNCTIONARIES_SEARCH_REQUESTING, FUNCTIONARIES_CONDENSED_REQUESTING
} from './constants';
import { APIRoute, DEFAULT_PAGINATION_LIMIT, DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import { AUTH_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import {
  handlePagedDataRefetch,
  normalizeSearchParams,
  stateTargetIdParams
} from '@src/utils/storeUtils';
import { FUNCTIONARY_ADD_SUCCESS, FUNCTIONARY_DELETE_SUCCESS } from '@src/store/functionary/constants';
import { getCondensedFunctionaries } from '@src/store/functionaries/flows/get-condensed-functionaries';

/*
  GET /admin/1/functionaries
 */
const fetchFunctionariesFlow = SagaFlowFactory.createPagedGETFlow({
  apiRoute: APIRoute.GET_FUNCTIONARIES,
  itemType: 'functionaries',
  successType: FUNCTIONARIES_SUCCESS,
  errorType: FUNCTIONARIES_ERROR
});

/*
  GET /admin/1/functionaries/search
 */
const fetchFunctionariesSearchFlow = SagaFlowFactory.createPagedGETFlow({
  apiRoute: APIRoute.SEARCH_FUNCTIONARIES,
  itemType: 'functionaries',
  successType: FUNCTIONARIES_SUCCESS,
  errorType: FUNCTIONARIES_ERROR
});

const REDUCER_NAME = 'functionariesReducer';

export default function * functionariesWatcher() {
  let token: string = '';

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
      page,
      limit,
      params,
    } = yield take([
      FUNCTIONARIES_REQUESTING,
      FUNCTIONARIES_SEARCH_REQUESTING,
      FUNCTIONARIES_SEARCH_RESET,
      FUNCTIONARY_ADD_SUCCESS,
      FUNCTIONARY_DELETE_SUCCESS,
      FUNCTIONARIES_CONDENSED_REQUESTING,
    ]);

    const targetIdParams = yield select(stateTargetIdParams(REDUCER_NAME));

    switch (type) {
      case FUNCTIONARIES_REQUESTING:
        yield fork(fetchFunctionariesFlow, token, page, limit, { ...params, ...targetIdParams});
        break;
      case FUNCTIONARIES_SEARCH_REQUESTING:
        yield fork(
          fetchFunctionariesSearchFlow,
          token,
          page,
          limit,
          normalizeSearchParams({ ...params, ...targetIdParams }),
        );
        break;
      case FUNCTIONARIES_SEARCH_RESET:
        yield fork(
          fetchFunctionariesFlow,
          token,
          DEFAULT_PAGINATION_PAGE,
          DEFAULT_PAGINATION_LIMIT,
          normalizeSearchParams({ ...params, ...targetIdParams }),
        );
        break;
      case FUNCTIONARY_ADD_SUCCESS:
      case FUNCTIONARY_DELETE_SUCCESS: {
        yield fork(
          handlePagedDataRefetch,
          REDUCER_NAME,
          FUNCTIONARIES_REQUESTING,
          FUNCTIONARIES_SEARCH_REQUESTING,
        );
        break;
      }
      case FUNCTIONARIES_CONDENSED_REQUESTING: {
        yield fork(getCondensedFunctionaries, token, params);
        break;
      }
      default:
    }
  }
}