import React from 'react';
import { FormattedDate, FormattedRelativeTime } from 'react-intl';
import moment from 'moment';

export const translateDate = (date: string): React.ReactNode => {
  return date ? <FormattedDate value={moment.utc(date).local(false).toISOString()} /> : null;
};

export const translateRelativeDate = (date: string): React.ReactNode => {
  return date ? (
    <FormattedRelativeTime
      unit={'day'}
      numeric={'auto'}
      value={moment.utc(date).local(false).diff( new Date(), 'days')}
    /> ) : null;
};