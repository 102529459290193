import React, { ChangeEvent, PureComponent, RefObject } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import { renderFormActions } from '@src/components/modals/ui';
import InputsValidator from '@src/components/forms/InputsValidator';
import { editClubCourseTee } from '@src/store/clubCourseTee/actions';

interface OwnProps {
  clubCourseId: number;
  clubCourseTeeId?: number | undefined;
  parentContext?: 'MODAL' | 'PAGE';
  actionsContainerRef?: RefObject<HTMLElement>;
  disableStatus?: boolean;
  disableDescription?: boolean;
  onClose?(shouldRefetch?: boolean): void;
}

interface StateProps {
  clubCourseTeeState: ClubCourseTeeState;
}

interface DispatchProps {
  editClubCourseTee(params: ClubCourseTeeEdit): void;
}

type Props = OwnProps & StateProps & DispatchProps;

type State = {
  description: TeeType;
  ratingMen: number;
  ratingWomen: number;
  slopeMen: number;
  slopeWomen: number;
  status: ActivityStatus | '';
};

const initialState: State = {
  description: '' as TeeType,
  ratingMen: 0,
  ratingWomen: 0,
  slopeMen: 0,
  slopeWomen: 0,
  status: 'ACTIVE' as ActivityStatus
};

class ClubCourseTeeFormConnected extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const {
      clubCourseTeeId,
      clubCourseTeeState: {
        clubCourseTee,
      }
    } = this.props;

    if (!clubCourseTeeId) {
      this.state = initialState;
    } else if (clubCourseTee) {
      this.state = {
        ...clubCourseTee,
      } as any;
    } else {
      this.state = initialState;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      clubCourseTeeId,
      clubCourseTeeState: {
        clubCourseTee,
        requesting,
      },
    } = nextProps;

    if (!clubCourseTeeId) {
      return; // Do nothing if we are adding new person
    } else if (requesting) {
      return; // Do nothing state is loading
    } else if (clubCourseTee) {
      this.setState({
        ...clubCourseTee as any
      });
    }
  }

  render() {
    const {
      parentContext,
      clubCourseTeeId,
      actionsContainerRef,
      disableStatus = false,
      disableDescription = false,
    } = this.props;

    const {
      status,
      ratingMen,
      slopeMen,
      ratingWomen,
      slopeWomen,
      description,
    } = this.state;

    return (
      <InputsValidator values={{ status, ratingMen, slopeMen, ratingWomen, slopeWomen }}>
        {({ errorInputs, validateThenApply, validateValues }) => (
          <>
            <FormControl required={true} fullWidth={true} margin={'normal'} disabled={disableStatus}>
              <InputLabel htmlFor="tee-status">
                <FormattedMessage id={'strings.state'}/>
              </InputLabel>
              <Select
                inputProps={{
                  name: 'status',
                  id: 'tee-status',
                }}
                value={status}
                onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                  this.setState({status: event.target.value as ActivityStatus});
                }}
              >
                <MenuItem value={'ACTIVE'}><FormattedMessage id={'strings.statusActive'}/></MenuItem>
                <MenuItem value={'INACTIVE'}><FormattedMessage id={'strings.statusPassive'}/></MenuItem>
              </Select>
            </FormControl>
            <TextField
              required={true}
              fullWidth={true}
              type="number"
              label={<FormattedMessage id={'scenes.golfClub.course.teeForm.ratingMen'} />}
              value={ratingMen}
              margin="normal"
              name={'ratingMen'}
              onChange={this._handleChange('ratingMen', validateValues)}
              error={errorInputs.ratingMen}
            />
            <TextField
              required={true}
              fullWidth={true}
              type="number"
              label={<FormattedMessage id={'scenes.golfClub.course.teeForm.slopeMen'} />}
              value={slopeMen}
              margin="normal"
              name={'slopeMen'}
              onChange={this._handleChange('slopeMen', validateValues)}
              error={errorInputs.slopeMen}
            />
            <TextField
              required={true}
              fullWidth={true}
              type="number"
              label={<FormattedMessage id={'scenes.golfClub.course.teeForm.ratingWomen'} />}
              value={ratingWomen}
              margin="normal"
              name={'ratingWomen'}
              onChange={this._handleChange('ratingWomen', validateValues)}
              error={errorInputs.ratingWomen}
            />
            <TextField
              required={true}
              fullWidth={true}
              type="number"
              label={<FormattedMessage id={'scenes.golfClub.course.teeForm.slopeWomen'} />}
              value={slopeWomen}
              margin="normal"
              name={'slopeWomen'}
              onChange={this._handleChange('slopeWomen', validateValues)}
              error={errorInputs.slopeWomen}
            />

            <TextField
              fullWidth={true}
              label={<FormattedMessage id={'strings.description'}/>}
              value={description}
              margin="normal"
              name={'description'}
              disabled={disableDescription}
              onChange={this._handleChange('description')}
            />

            {renderFormActions({
              id: clubCourseTeeId,
              parentContext,
              onUpdate: validateThenApply.bind(this, this._handleUpdate),
              onClose: this._handleOnClose,
              disabled: false,
              containerRef: actionsContainerRef,
            })}
          </>
        )}
      </InputsValidator>
    );
  }

  private _handleChange = (name: string, fn?: any) => (
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      this.setState({
        [name as any]: event.target.value,
      } as any, fn);
    }
  )

  private _handleOnClose = (shouldRefetch: boolean = false) => {
    const { onClose } = this.props;
    if (onClose) {
      onClose(shouldRefetch);
    }
  }

  private _handleUpdate = () => {
    const { editClubCourseTee, clubCourseId } = this.props;
    const {
      clubCourseTeeState: {
        clubCourseTee
      }
    } = this.props;

    if (!clubCourseTee) {
      return;
    }

    const params = this._formParams();

    editClubCourseTee({
      id: clubCourseTee.number,
      clubCourseId: clubCourseId,
      ...params,
      onComplete: this._handleOnComplete,
    });
  }

  private _formParams = () => {
    const {
      status,
      description,
      ratingMen,
      ratingWomen,
      slopeMen,
      slopeWomen,
    } = this.state;

    return {
      status,
      ratingMen,
      ratingWomen,
      slopeMen,
      slopeWomen,
      description
    };
  }

  private _handleOnComplete = (result: any) => {
    if (result.error) {
      window.alert(result.error.message);
    } else {
      this._handleOnClose(true);
    }
  }
}

export const ClubCourseTeeForm = connect<StateProps, DispatchProps, OwnProps, StoreState>(state => ({
  clubCourseTeeState: state.clubCourseTeeReducer,
}), {
  editClubCourseTee,
})(ClubCourseTeeFormConnected);