import React from 'react';
import { withRouter, RouteComponentProps, Route, Switch } from 'react-router';
import { connect } from 'react-redux';
import { getReportConfig } from '@src/store/reports/actions';
// import { AdminReportsEngine } from '@src/scenes/admin/AdminReportsEngine';
import { AdminReportsGreenCard } from '@src/scenes/admin/AdminReportsGreenCard';
import { AdminReportsMemberInfo } from '@src/scenes/admin/AdminReportsMemberInfo';
import { AdminReportsFunctionaries } from '@src/scenes/admin/AdminReportsFunctionaries';
import { AdminReportsMemberStatistics } from '@src/scenes/admin/AdminReportsMemberStatistics';
import { AdminReportsHandicapReview } from '@src/scenes/admin/AdminReportsHandicapReview';
import { AdminReportsCourseList } from '@src/scenes/admin/AdminReportsCourseList';
import ReportsEBirdieStatisticsScene from '@src/components/scenes/reports/ReportsEBirdieStatisticsScene';
import ReportsHandicapStatisticsScene from '@src/components/scenes/reports/ReportsHandicapStatisticsScene';
import { Report2sScene } from './Report2sScene';

interface StateProps {
  reportsConfig?: ReportsConfig;
}

interface DispatchProps {
  getReportConfig(): void;
}

type Props = StateProps & DispatchProps & RouteComponentProps<any>;

class UnconnectedReportsRoot extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    if (!props.reportsConfig) {
      props.getReportConfig();
    }
  }

  render() {
    const {
      match: {
        path,
      },
    } = this.props;

    return (
      <Switch>
        <Route path={`${path}/green-card`} component={AdminReportsGreenCard} />
        <Route path={`${path}/member-info`} component={AdminReportsMemberInfo} />
        <Route path={`${path}/member-statistics`} component={AdminReportsMemberStatistics} />
        <Route path={`${path}/eBirdie-statistics`} component={ReportsEBirdieStatisticsScene} />
        <Route path={`${path}/handicap-statistics`} component={ReportsHandicapStatisticsScene} />
        <Route path={`${path}/functionaries`} component={AdminReportsFunctionaries} />
        <Route path={`${path}/engine`} component={Report2sScene} />
        <Route path={`${path}/handicap-review`} component={AdminReportsHandicapReview} />
        <Route path={`${path}/course-list`} component={AdminReportsCourseList} />
      </Switch>
    );
  }
}
// <Route path={`${path}/engine`} component={AdminReportsEngine} />

export default withRouter(connect<StateProps, DispatchProps, {}, StoreState>(
  (state) => ({
    reportsConfig: state.reportsReducer.config,
  }), {
  getReportConfig,
}
)(UnconnectedReportsRoot));