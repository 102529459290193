import React, { PureComponent, ReactNode } from 'react';
import { connect } from 'react-redux';
import * as functionaryTitlesActions from '../../store/functionarytitles/actions';
import { PropTypes } from '@material-ui/core';
import Margin = PropTypes.Margin;
import Select from '@src/components/forms/Select';
import { FormattedMessage } from 'react-intl';

type PropsData = {
  multi: true,
  selectedTitles?: Array<CondensedTitle>,
} | {
  multi?: false | undefined,
  selectedTitles?: CondensedTitle,
};

type Props = {
  titles: Array<CondensedTitle>;
  disabled?: boolean;
  helpText?: React.ReactNode;
  margin?: Margin;
  error?: boolean;
  required?: boolean;
  onChangeCallback?(values: Array<{ id: number }> | number): void;
} & PropsData;

class FunctionaryTitleSelect extends PureComponent<Props> {

  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      titles,
      selectedTitles,
      multi,
      helpText,
      disabled,
      margin,
      error,
      required,
    } = this.props;

    const labelText = (): ReactNode => {
      return (
          <FormattedMessage
              id={multi ? 'forms.functionaryTitleSelect.multi.label' : 'forms.functionaryTitleSelect.single.label'}
          />
      );
    };

    const placeholderText = (): ReactNode => {
      return (
          <FormattedMessage
              id={multi ? 'forms.functionaryTitleSelect.multi.placeholder' : 'forms.functionaryTitleSelect.single.placeholder'}
          />
      );
    };

    return (
      <Select
          error={error}
          disabled={disabled}
          required={required}
          options={titles.sort((a, b) => {
            return a.name ? a.name.localeCompare(b.name, 'fi') : -1;
          })}
          selected={selectedTitles}
          multiSelect={multi}
          onChangeCallback={this._handleChange}
          labelText={labelText()}
          placeholderText={placeholderText()}
          helpText={helpText}
          margin={margin}
      />
    );
  }

  private _handleChange = (value: Array<{ id: number }> | number) => {
    const {onChangeCallback} = this.props;
    if (onChangeCallback) {
      onChangeCallback(value);
    }
  }
}

export default connect((state: StoreState) => ({
  titles: state.functionaryTitlesReducer.allTitles
}), {
  fetchCondensedTitles: functionaryTitlesActions.fetchCondensedFunctionaryTitles,
})(FunctionaryTitleSelect);