import {
  REPORTS_REQUESTING,
  REPORTS_SUCCESS,
  REPORTS_ERROR,
  REPORTS_CHANGE_PAGE,
  REPORTS_CONDENSED_SUCCESS,
  REPORTS_RESET,
} from './constants';
import { SHARED_DEFAULT_REDUCER_STATE } from '@src/assets/config';
import {
  formDefaultErrorState,
  formDefaultPagedState,
  formDefaultRequestingState,
  fromDefaultPageChangeState,
  optimisticPagedObjectUpdate
} from '@src/utils/storeUtils';
import { REPORT_EDIT_SUCCESS } from '@src/store/report/constants';

const initialState: Report2sState = {
  ...SHARED_DEFAULT_REDUCER_STATE,
  pagedReports: {},
  allReports: [],
};

const report2sReducer = (state: Report2sState = initialState, action: any): Report2sState => {
  console.info('report2sReducer', action.type);
  switch (action.type) {
    case REPORTS_REQUESTING:
      return formDefaultRequestingState(state);

    case REPORTS_SUCCESS:
      return formDefaultPagedState(state, action, 'pagedReports');

    case REPORTS_CHANGE_PAGE:
      return fromDefaultPageChangeState(state, action);

    case REPORTS_ERROR:
      return formDefaultErrorState(state, action);

    case REPORTS_CONDENSED_SUCCESS: {
      return {
        ...state,
        allReports: action.data.reports,
      };
    }

    case REPORTS_RESET:
      return {
        ...initialState,
        requesting: true,
        targetIdParams: state.targetIdParams,
      };

    case REPORT_EDIT_SUCCESS: {
      const {
        data,
      } = action;
      const page = state.pagination.page;

      const pagedReports = optimisticPagedObjectUpdate(state.pagedReports, page, { ...data });

      return {
        ...state,
        pagedReports
      };
    }

    default:
      return state;
  }
};

export {
  report2sReducer,
};