/**
 * A functionary title comparison function for sort() with titleName ASC, id ASC ordering.
 *
 * @param a The first functionary title for comparison
 * @param b The second functionary title for comparison
 * @returns Number Negative when a < b, zero when a == b, positive when a > b
 */
export const functionaryTitleCompare = (a: FunctionaryTitle, b: FunctionaryTitle, l?: string): number => {
  let c: number = !a.name ? !b.name ? 0 : -1 : a.name.localeCompare(b.name, l);
  if (c === 0) {
    c = b.id - a.id;
  }

  return c;
};
