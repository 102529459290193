import {
  VENDORS_REQUESTING,
  VENDORS_SUCCESS,
  VENDORS_ERROR,
  FEDERATION_VENDORS_CHANGE_PAGE,
  VENDORS_RESET,
} from './constants';
import { SHARED_DEFAULT_REDUCER_STATE } from '@src/assets/config';
import {
  formDefaultErrorState,
  formDefaultPagedState,
  formDefaultRequestingState,
  fromDefaultPageChangeState, optimisticPagedObjectUpdate
} from '@src/utils/storeUtils';
import { VENDOR_EDIT_SUCCESS } from '@src/store/vendor/constants';

const initialState: VendorsState = {
  ...SHARED_DEFAULT_REDUCER_STATE,
  pagedVendors: {},
};

export const vendorsReducer = (state: VendorsState = initialState, action: any): VendorsState => {
  switch (action.type) {
    case VENDORS_REQUESTING:
      return formDefaultRequestingState(state);

    case VENDORS_SUCCESS:
      return formDefaultPagedState(state, action, 'pagedVendors');

    case VENDORS_ERROR:
      return formDefaultErrorState(state, action);

    case FEDERATION_VENDORS_CHANGE_PAGE:
      return fromDefaultPageChangeState(state, action);

    case VENDOR_EDIT_SUCCESS: {
      try {
        const { data } = action;
        const { page } = state.pagination;

        const pagedVendors = optimisticPagedObjectUpdate(state.pagedVendors, page, data);

        return {
          ...state,
          pagedVendors,
        };
      } catch (e) {
        return state;
      }
    }

    case VENDORS_RESET: {
      return {
        ...formDefaultRequestingState(state),
        pagedVendors: {},
      };
    }

    default:
      return state;
  }
};