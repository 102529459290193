import { ButtonLoaderWrap, ContentHeader, ContentHeaderTitle } from '@src/components/layouts/ui';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { APIRoute, API_ROOT } from '@src/assets/config';
import useAuthToken from '@src/hooks/useAuthToken';

import {
  Button, FormControl, FormGroup, InputLabel, MenuItem,
  Select, Table, TableBody, TableCell, TableHead, TableRow, Theme, makeStyles
} from '@material-ui/core';
import LocalizedDatePicker from '@src/components/forms/LocalizedDatePicker';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { downloadFile } from '@src/utils/utils';

interface HandicapOverviewInputState {
  clubId?: number;
  startDate?: string;
  endDate?: string;
  order: 'high' | 'low';
}

interface HandicapOverviewRow {
  idPerson: number;
  nameFirst: string;
  nameLast: string;
  highFlagCount: number;
  lowFlagCount: number;
  acceptableScoreCount: number;
}

interface AuthInfoReducerPartial {
  roleInfo: RoleInfo;
  clubs: Club[];
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const fetchHandicapReviewOverview = async (token?: string, clubId?: number, startDate?: string, endDate?: string, order: 'high' | 'low' = 'high') => {
  const response = await fetch(`${API_ROOT}${APIRoute.getHandicapReviewOveriview(clubId, startDate, endDate, order, 1000, 0)}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  });
  if (!response.ok) {
    throw {
      status: response.status
    };
  }
  return response.json();
};

const fetchHandicapReview = async (token?: string, personId?: number, startDate?: string, endDate?: string) => {
  const response = await fetch(`${API_ROOT}${APIRoute.getHandicapReview(personId, startDate, endDate, 'person')}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw {
      status: response.status
    };
  }
  return response.blob();
};

const ReportsHandicapReviewScene = () => {

  const token = useAuthToken();
  const intl = useIntl();
  const styles = useStyles();
  const clubs = useSelector((state: any) => state.clubsReducer.allClubs as Array<Club>);
  const { roleInfo, clubs: userClubs } = useSelector((state: any) => (state.authReducer as AuthInfoReducerPartial));

  const [state, setState] = useState<HandicapOverviewInputState>({
    clubId: 0,
    startDate: moment().subtract(365, 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    order: 'high',
  });

  const handicapReviewQuery = useQuery<HandicapOverviewRow[], any>(`handicap-review-overview-${state.clubId}-${state.startDate}-${state.endDate}-${state.order}`,
    () => fetchHandicapReviewOverview(token, state.clubId, state.startDate, state.endDate, state.order),
    {
      enabled: false,
      retry: false,
      onError: (err) => {
        if (err.status === 400) {
          window.alert(intl.formatMessage({ id: 'scenes.reports.handicapReview.tooBigDateDifference' }));
        }
      }
    }
  );

  const handicapReviewMutation = useMutation(
    (personId: number) => fetchHandicapReview(token, personId, state.startDate, state.endDate),
    {
      onSuccess: (data) => {
        downloadFile(data, `handicap-review-${moment().format('YYYY-MM-DD')}.xlsx`);
      },
      onError: () => {
        window.alert(intl.formatMessage({ id: 'scenes.reports.handicapReview.failedToDownload' }));
      },
    }
  );

  const formatDate = (date: Date) => {
    return new Intl.DateTimeFormat('en-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }).format(date) || '';
  };

  const handleFetchHandicapReviewOverview = () => {
    if (!state.clubId || !state.startDate || !state.endDate || !state.order) {
      window.alert(intl.formatMessage({ id: 'scenes.reports.handicapReview.invalidValues' }));
      return;
    }
    if (moment(state.startDate, 'YYYY-MM-DD').diff(moment(state.endDate, 'YYYY-MM-DD'), 'days') > 365) {
      window.alert('scenes.reports.handicapReview.tooBigDateDifference');
      return;
    }
    handicapReviewQuery.refetch();
  };

  const getClubOptions = () => {
    if (roleInfo.scope === 'FEDERATION' && clubs) {
      return clubs.map((club: Club) => {
        return <MenuItem key={club.idClub} value={club.idClub}>{club.name || ''}</MenuItem>;
      });
    }
    if (userClubs) {
      return userClubs.map((club: Club) => {
        return <MenuItem key={club.idClub} value={club.idClub}>{club.name || ''}</MenuItem>;
      });
    }
    return <MenuItem key="" />;
  };

  return (
    <>
      <HeadHelmet titleId={'navigation.reportHandicapReview'} />
      <ContentHeader>
        <ContentHeaderTitle>
          <FormattedMessage id={'scenes.reports.handicapReview.sectionTitle'} />
        </ContentHeaderTitle>
        <form autoComplete="off">
          <FormGroup row={true}>
            <FormControl className={styles.formControl}>
              <LocalizedDatePicker
                label={intl.formatMessage({ id: 'scenes.reports.handicapReview.startTime' })}
                value={state.startDate}
                onChange={(date: Date) => {
                  setState({
                    ...state,
                    startDate: formatDate(date)
                  });
                }}
                emptyLabel={intl.formatMessage({ id: 'scenes.reports.handicapReview.startTime' })}
                clearable={false}
              />
            </FormControl>
            <FormControl className={styles.formControl}>
              <LocalizedDatePicker
                label={intl.formatMessage({ id: 'scenes.reports.handicapReview.endTime' })}
                value={state.endDate}
                onChange={(date: Date) => {
                  setState({
                    ...state,
                    endDate: formatDate(date)
                  });
                }}
                emptyLabel={intl.formatMessage({ id: 'scenes.reports.handicapReview.endTime' })}
                clearable={false}
              />
            </FormControl>
          </FormGroup>
          <FormGroup row={true}>
            <FormControl className={styles.formControl}>
              <InputLabel htmlFor="chooseClub">
                <FormattedMessage id={'scenes.reports.handicapReview.chooseClub'} />
              </InputLabel>
              <Select
                id="chooseClub"
                displayEmpty={true}
                value={state.clubId ?? ''}
                onChange={(event) => {
                  setState({
                    ...state,
                    clubId: event.target.value as number
                  });
                }}
              >
                {
                  getClubOptions()
                }
              </Select>
            </FormControl>
          </FormGroup>
          <ButtonLoaderWrap loading={handicapReviewQuery.isFetching} style={{ marginTop: 30 }}>
            <Button
              disabled={handicapReviewQuery.isFetching}
              variant="contained"
              color="primary"
              className={styles.button}
              onClick={
                () => handleFetchHandicapReviewOverview()
              }
            >
              <FormattedMessage id={'buttons.search'} />
            </Button>
          </ButtonLoaderWrap>
        </form>
        <Table className={styles.selectEmpty}>
          <TableHead>
            <TableRow>
              <TableCell size={'small'}>
                <FormattedMessage id={'strings.firstName'} />
              </TableCell>
              <TableCell size={'small'}>
                <FormattedMessage id={'strings.lastName'} />
              </TableCell>
              <TableCell size={'small'}>
                <FormattedMessage id={'scenes.reports.handicapReview.flagAmount'} />
              </TableCell>
              <TableCell size={'small'}>
                <FormattedMessage id={'scenes.reports.handicapReview.gamesPlayedAmount'} />
              </TableCell>
              <TableCell size={'small'}>
                <FormattedMessage id={'buttons.download'} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              handicapReviewQuery.data && handicapReviewQuery.data.map((reviewRow) => {
                const { idPerson, nameFirst, nameLast, highFlagCount, lowFlagCount, acceptableScoreCount } = reviewRow;
                return (
                  <TableRow key={idPerson}>
                    <TableCell size={'small'}>
                      {nameFirst}
                    </TableCell>
                    <TableCell size={'small'}>
                      {nameLast}
                    </TableCell>
                    <TableCell size={'small'}>
                      {highFlagCount + lowFlagCount}
                    </TableCell>
                    <TableCell size={'small'}>
                      {acceptableScoreCount}
                    </TableCell>
                    <TableCell size={'small'}>
                      <ButtonLoaderWrap loading={handicapReviewMutation.isLoading}>
                        <Button
                          disabled={handicapReviewMutation.isLoading}
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            handicapReviewMutation.mutate(idPerson);
                          }}
                        >
                          <FormattedMessage id={'buttons.download'} />
                        </Button>
                      </ButtonLoaderWrap>
                    </TableCell>
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
      </ContentHeader >
    </>
  );
};

export default ReportsHandicapReviewScene;