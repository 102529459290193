export const PERSONS_REQUESTING = 'PERSONS_REQUESTING';
export const PERSONS_SUCCESS = 'PERSONS_SUCCESS';
export const PERSONS_ERROR = 'PERSONS_ERROR';

export const PERSONS_CHANGE_PAGE = 'PERSONS_CHANGE_PAGE';

export const PERSONS_SEARCH_CHANGED = 'PERSONS_SEARCH_CHANGED';
export const PERSONS_SEARCH_REQUESTING = 'PERSONS_SEARCH_REQUESTING';
export const PERSONS_SEARCH_RESET = 'PERSONS_SEARCH_RESET';

export const PERSONS_SET_TARGET_ID_PARAMS = 'PERSONS_SET_TARGET_ID_PARAMS';