import {
  CLUB_FEE_ADD_ERROR, CLUB_FEE_ADD_REQUESTING, CLUB_FEE_ADD_SUCCESS, CLUB_FEE_DELETE_ERROR,
  CLUB_FEE_DELETE_REQUESTING, CLUB_FEE_DELETE_SUCCESS, CLUB_FEE_EDIT_ERROR, CLUB_FEE_EDIT_REQUESTING,
  CLUB_FEE_EDIT_SUCCESS, CLUB_FEE_GET_ERROR, CLUB_FEE_GET_REQUESTING,
  CLUB_FEE_GET_SUCCESS
} from './constants';
import { formDefaultErrorState, formDefaultRequestingState } from '@src/utils/storeUtils';

const initialState: ClubFeeState = {
  clubFee: undefined,
  requesting: false,
  successful: false,
  error: [],
};

export const clubFeeReducer = (state: ClubFeeState = initialState, action: any): ClubFeeState => {
  const {
    type,
  } = action;

  switch (type) {
    case CLUB_FEE_GET_REQUESTING:
      return {
        ...formDefaultRequestingState(state),
        clubFee: undefined,
      };

    case CLUB_FEE_ADD_REQUESTING:
    case CLUB_FEE_DELETE_REQUESTING:
    case CLUB_FEE_EDIT_REQUESTING:
      return formDefaultRequestingState(state);

    case CLUB_FEE_GET_ERROR:
    case CLUB_FEE_ADD_ERROR:
    case CLUB_FEE_DELETE_ERROR:
    case CLUB_FEE_EDIT_ERROR:
      return formDefaultErrorState(state, action);

    case CLUB_FEE_GET_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
        clubFee: action.data,
      };

    case CLUB_FEE_ADD_SUCCESS:
    case CLUB_FEE_DELETE_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
      };

    case CLUB_FEE_EDIT_SUCCESS:
      const { data } = action;
      return {
        ...state,
        requesting: false,
        successful: true,
        clubFee: data,
      };

    default:
      return state;
  }
};