import { LOCALSTORAGE_ROUTE_NAME, LOCALSTORAGE_TOKEN_NAME } from '@src/assets/config';

const unsetPersistedStorageKey = (key: string) => {
  try {
    window.localStorage.removeItem(key);
  } catch (e) {
    window.console.error(`Could not unset key: ${key} from localStorage`);
  }
};

export const unsetPersistedAuthToken = () => (
  unsetPersistedStorageKey(LOCALSTORAGE_TOKEN_NAME)
);

export const unsetPersistedRedirectRoute = () => (
  unsetPersistedStorageKey(LOCALSTORAGE_ROUTE_NAME)
);