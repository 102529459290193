import React from 'react';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

export const functionaryVisibleInGuides = (functionary: Functionary): boolean => functionary.visibleGuides;

export const FunctionaryCardItem = (
  functionary: Functionary
) => (
  <div key={functionary.id} className={'functionary-card-item'}>
    {functionary.personInfo &&
    <>
      <Typography variant="h6" gutterBottom={true}>
        {`${functionary.personInfo.nameFirst} ${functionary.personInfo.nameMiddle}
                      ${functionary.personInfo.nameLast}`}
      </Typography>
      {functionary.functionaryTitle &&
      <Typography
        variant="subtitle1"
        gutterBottom={true}
      >
        {functionary.functionaryTitle.titleName}
        {functionary.titlePrecision && <span> ({functionary.titlePrecision})</span>}
      </Typography>
      }
      <Typography variant="body1" gutterBottom={true}>
        <FormattedMessage id={'strings.phone'} />: {functionary.personInfo.phone}
      </Typography>
      <Typography variant="body1" gutterBottom={true}>
        <FormattedMessage id={'strings.email'} />: {functionary.personInfo.email}
      </Typography>
    </>
    }
  </div>
);