import React, { ReactNode } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import { ActionContainer, ModalContentLoader } from '@src/components/modals/ui';
import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { MessagePreview } from '@src/components/forms/ui/MessagePreview';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import moment from 'moment';

interface Props {
  messageId: number | undefined;
  open: boolean;
  onClose: () => void;
  message: MessageState;
}

class MessagePreviewModalConnected extends React.Component<Props, {}> {
  render() {
    const {
      open,
      onClose,
      message: {
        requesting,
        message
      }
    } = this.props;

    const _renderContent = (): ReactNode => {
      if (message) {

        const { title, content, images, published } = message;

        return (
          <MessagePreview
            title={title}
            content={content}
            images={images}
            published={moment(published).utc(true).toDate()}
          />
        );
      }
      return null;
    };

    return (
      <Dialog
        open={open}
        fullWidth={true}
      >
        <DialogContent>
          {requesting && <ModalContentLoader/>}
          {!requesting &&
           <>{_renderContent()}</>
          }
        </DialogContent>
        <DialogActions>
          <ActionContainer>
            <Button variant="contained" onClick={onClose}>
              <FormattedMessage id={'buttons.close'} />
            </Button>
          </ActionContainer>
        </DialogActions>
      </Dialog>
    );
  }
}

const MessagePreviewModal = connect((state: any) => ({
  message: state.messageReducer,
}))(MessagePreviewModalConnected);

export {
  MessagePreviewModal,
};