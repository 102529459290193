import {
  REPORT_CLUB_COURSE_GUIDE_REQUESTING,
  REPORT_FUNCTIONARIES_REQUESTING,
  REPORT_GREENCARD_REQUESTING,
  REPORT_MEMBERSHIP_STATISTICS_REQUESTING,
  REPORT_MEMBERSHIPS_REQUESTING,
  REPORT_JOIN_DATE_REQUESTING,
  REPORT_CLUB_MEMBERSHIPS_REQUESTING,
  REPORT_EBIRDIE_STATISTICS_REQUESTING,
  REPORT_CONFIG_REQUESTING,
  REPORT_SCORE_STATISTICS_REQUESTING,
  REPORT_SCORE_STATISTICS_FILE_REQUESTING,
  REPORT_VENDOR_STATISTICS_FILE_REQUESTING,
  REPORT_SCORE_HOLES_STATISTICS_FILE_REQUESTING,
} from '@src/store/reports/constants';
import moment from 'moment';

export const getFunctionariesReport = ({
  includedFields,
  filters,
  onComplete,
}: GetFunctionariesReport) => ({
  type: REPORT_FUNCTIONARIES_REQUESTING,
  onComplete,
  params: {
    includedFields,
    ...filters
  },
});

export const getMembershipsReport = ({
  includedFields,
  filters,
  onComplete,
}: GetMembershipsReport) => ({
  type: REPORT_MEMBERSHIPS_REQUESTING,
  onComplete,
  params: {
    includedFields,
    ...filters
  },
});

export const getClubMembershipsReport = ({
  clubId,
  includedFields,
  onComplete,
}: GetClubMembershipsReport) => ({
  type: REPORT_CLUB_MEMBERSHIPS_REQUESTING,
  clubId,
  onComplete,
  params: {
    clubId,
    includedFields,
  },
});

export const getJoinDateReport = ({
  dateFrom,
  dateTo,
  onComplete,
}: GetJoinDateReport) => ({
  type: REPORT_JOIN_DATE_REQUESTING,
  onComplete,
  params: {
    dateFrom,
    dateTo
  },
});

export const getMembershipStatisticsReport = ({
  date = new Date(),
  onComplete,
}: GetMembershipStatisticsReport) => ({
  type: REPORT_MEMBERSHIP_STATISTICS_REQUESTING,
  params: {
    date: moment(date).format('YYYY-MM-DD'),
  },
  onComplete,
});

export const getClubCourseGuideReport = ({
  clubId,
  onComplete,
}: GetClubCourseGuide) => ({
  type: REPORT_CLUB_COURSE_GUIDE_REQUESTING,
  onComplete,
  params: {
    clubId,
  },
});

export const getGreenCardReport = ({
  onComplete,
  reportType,
  startDate,
  endDate,
}: GetGreenCardReport) => ({
  type: REPORT_GREENCARD_REQUESTING,
  onComplete,
  params: {
    reportType,
    startDate,
    endDate,
  },
});

export const getEBirdieStatistics = (clubId?: number) => ({
  type: REPORT_EBIRDIE_STATISTICS_REQUESTING,
  clubId,
});

export const getReportConfig = () => ({
  type: REPORT_CONFIG_REQUESTING,
});

export const getScoreStatisticsReport = ({
  startDate = new Date(),
  endDate = new Date(),
  clubId,
  locale,
  onComplete,
}: ScoreStatisticsReport) => ({
  type: REPORT_SCORE_STATISTICS_FILE_REQUESTING,
  onComplete,
  params: {
    startDate: moment(startDate).format('YYYY-MM-DD'),
    endDate: moment(endDate).format('YYYY-MM-DD'),
    clubId,
    locale,
  },
});

export const getScoreStatistics = ({
  startDate = new Date(new Date().getFullYear(), 0, 1),
  endDate = new Date(),
  clubId,
  onComplete,
}: ScoreStatisticsReport) => ({
  type: REPORT_SCORE_STATISTICS_REQUESTING,
  onComplete,
  params: {
    startDate: moment(startDate).format('YYYY-MM-DD'),
    endDate: moment(endDate).format('YYYY-MM-DD'),
    clubId,
  },
});

export const getVendorStatisticsReport = ({
  year = new Date().getFullYear(),
  clubId,
  locale,
  onComplete,
}: ScoreStatisticsReport) => ({
  type: REPORT_VENDOR_STATISTICS_FILE_REQUESTING,
  onComplete,
  params: {
    year,
    clubId,
    locale,
  },
});

export const getScoreHolesStatisticsReport = ({
  year = new Date().getFullYear(),
  clubId,
  locale,
  onComplete,
}: ScoreStatisticsReport) => ({
  type: REPORT_SCORE_HOLES_STATISTICS_FILE_REQUESTING,
  onComplete,
  params: {
    year,
    clubId,
    locale,
  },
});