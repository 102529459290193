import React from 'react';

interface Props {
  courseType: CourseCourseType;
  holes?: CourseHole[];
}

function ClubCourseFairwayCount({
  courseType,
  holes,
}: Props) {
  const getHolesLabel = () => {
    switch (courseType) {
      case 'BACK':
        return '10-18';
      case 'FRONT':
        return '1-9';
      case 'FULL':
        return '18';
      default:
        return holes ? holes.length : '18';
    }
  };

  return (
    <span>
      {getHolesLabel()}
    </span>
  );
}

export default ClubCourseFairwayCount;