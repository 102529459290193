import {
  CLUB_SERVICES_REQUESTING,
  CLUB_SERVICES_SUCCESS,
  CLUB_SERVICES_ERROR,
  CLUB_SERVICES_CHANGE_PAGE,
  CLUB_SERVICES_RESET,
  CLUB_SERVICES_SET_INITIAL_STATE,
  CLUB_SERVICES_SEARCH_CHANGED,
  CLUB_SERVICES_SEARCH_REQUESTING,
  CLUB_SERVICES_SEARCH_RESET,
} from './constants';
import { SHARED_DEFAULT_REDUCER_STATE } from '@src/assets/config';
import {
  formDefaultErrorState,
  formDefaultPagedState,
  formDefaultRequestingState,
  fromDefaultPageChangeState,
  optimisticPagedObjectUpdate, searchStateChangedReducerHelper
} from '@src/utils/storeUtils';
import { CLUB_SERVICE_EDIT_SUCCESS } from '@src/store/clubService/constants';

const initialState: ClubServicesState = {
  ...SHARED_DEFAULT_REDUCER_STATE,
  pagedServices: {},
  search: {
    name: '',
    status: ''
  }
};

export const clubServicesReducer = (state: ClubServicesState = initialState, action: any): ClubServicesState => {
  switch (action.type) {
    case CLUB_SERVICES_REQUESTING:
      return formDefaultRequestingState(state);

    case CLUB_SERVICES_SUCCESS:
      return formDefaultPagedState(state, action, 'pagedServices');

    case CLUB_SERVICES_CHANGE_PAGE:
      return fromDefaultPageChangeState(state, action);

    case CLUB_SERVICES_ERROR:
      return formDefaultErrorState(state, action);

    case CLUB_SERVICES_RESET:
      return {
        ...initialState,
        requesting: true
      };

    case CLUB_SERVICES_SEARCH_CHANGED: {
      return searchStateChangedReducerHelper(state, action);
    }

    case CLUB_SERVICES_SEARCH_REQUESTING: {
      let searchActive = state.searchActive;
      let pagedServices = state.pagedServices;
      let pagination = state.pagination;

      if (!searchActive) {
        searchActive = true;
        pagedServices = {};
        pagination.totalCount = 0;
      }

      pagination.page = action.page;

      return {
        ...state,
        pagedServices,
        pagination,
        searchActive,
        requesting: true
      };
    }

    case CLUB_SERVICES_SEARCH_RESET:
      return {
        ...initialState,
        requesting: true
      };

    case CLUB_SERVICES_SET_INITIAL_STATE:
      return {
        ...initialState
      };

    case CLUB_SERVICE_EDIT_SUCCESS: {
      const {
        data,
      } = action;
      const page = state.pagination.page;

      const pagedServices = optimisticPagedObjectUpdate(state.pagedServices, page, { ...data });

      return {
        ...state,
        pagedServices
      };
    }

    default:
      return state;
  }
};