import { fork, select, takeEvery, all, put } from 'redux-saga/effects';
import { userAuthToken } from '@src/store/auth/selectors';
import {
  ACCEPTABLE_SCORES_ERROR, ACCEPTABLE_SCORES_REFETCH,
  ACCEPTABLE_SCORES_REQUESTING, ACCEPTABLE_SCORES_SUCCESS,
} from '@src/store/acceptableScores/constants';
import { APIRoute } from '@src/assets/config';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { GetAcceptableScoresAction } from '@src/store/acceptableScores/actions';
import { personStatePersonId } from '@src/store/person/selectors';
import { getAcceptableScoresActivePage } from '@src/store/acceptableScores/selectors';

/*
  GET ${APIRoute.HANDICAP}/person/${personId}/acceptable-score-rounds
 */
const pagedFlow = (personId: number) => (
  SagaFlowFactory.createPagedGETFlow({
    apiRoute: APIRoute.acceptableScores(personId),
    itemType: 'scores',
    successType: ACCEPTABLE_SCORES_SUCCESS,
    errorType: ACCEPTABLE_SCORES_ERROR,
  })
);

function* fetchPagedScoresFlow({
  personId,
  page,
  limit = 25,
}: GetAcceptableScoresAction) {
  const token = yield select(userAuthToken);
  yield fork(pagedFlow(personId), token, page, limit);
}

function* handleRefetch() {
  const personId = yield select(personStatePersonId);
  const page = yield select(getAcceptableScoresActivePage);
  yield put({
    type: ACCEPTABLE_SCORES_REQUESTING,
    personId,
    page,
  });
}

export function* acceptableScoresWatcher() {
  yield all([
    takeEvery(ACCEPTABLE_SCORES_REQUESTING, fetchPagedScoresFlow),
    takeEvery(ACCEPTABLE_SCORES_REFETCH, handleRefetch),
  ]);
}
