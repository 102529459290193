import {
  CLUB_COURSES_REQUESTING,
  CLUB_COURSES_SUCCESS,
  CLUB_COURSES_ERROR,
  CLUB_COURSES_CHANGE_PAGE,
  CLUB_COURSES_RESET,
  CLUB_COURSES_SET_INITIAL_STATE,
  CLUB_COURSES_SEARCH_CHANGED,
  CLUB_COURSES_SEARCH_REQUESTING,
  CLUB_COURSES_SEARCH_RESET, CONDENSED_CLUB_COURSES_SUCCESS,
} from './constants';
import { SHARED_DEFAULT_REDUCER_STATE } from '@src/assets/config';
import {
  formDefaultErrorState,
  formDefaultPagedState,
  formDefaultRequestingState,
  fromDefaultPageChangeState,
  optimisticPagedObjectUpdate, searchStateChangedReducerHelper
} from '@src/utils/storeUtils';
import { CLUB_FEE_EDIT_SUCCESS } from '@src/store/clubFee/constants';

const initialState: ClubCoursesState = {
  ...SHARED_DEFAULT_REDUCER_STATE,
  pagedCourses: {},
  condensedCourseClubs: [],
  search: {
    searchTerm: '',
    status: ''
  }
};

export const clubCoursesReducer = (state: ClubCoursesState = initialState, action: any): ClubCoursesState => {
  switch (action.type) {
    case CLUB_COURSES_REQUESTING:
      return formDefaultRequestingState(state);

    case CLUB_COURSES_SUCCESS:
      return formDefaultPagedState(state, action, 'pagedCourses');

    case CLUB_COURSES_CHANGE_PAGE:
      return fromDefaultPageChangeState(state, action);

    case CLUB_COURSES_ERROR:
      return formDefaultErrorState(state, action);

    case CLUB_COURSES_RESET:
      return {
        ...initialState,
        requesting: true
      };

    case CLUB_COURSES_SEARCH_CHANGED: {
      return searchStateChangedReducerHelper(state, action);
    }

    case CLUB_COURSES_SEARCH_REQUESTING: {
      let searchActive = state.searchActive;
      let pagedCourses = state.pagedCourses;
      let pagination = state.pagination;

      if (!searchActive) {
        searchActive = true;
        pagedCourses = {};
        pagination.totalCount = 0;
      }

      pagination.page = action.page;

      return {
        ...state,
        pagedCourses,
        pagination,
        searchActive,
        requesting: true
      };
    }

    case CLUB_COURSES_SEARCH_RESET:
      return {
        ...initialState,
        requesting: true
      };

    case CLUB_COURSES_SET_INITIAL_STATE:
      return {
        ...initialState,
      };

    case CLUB_FEE_EDIT_SUCCESS: {
      const {
        data,
      } = action;
      const page = state.pagination.page;

      const pagedCourses = optimisticPagedObjectUpdate(state.pagedCourses, page, { ...data });

      return {
        ...state,
        pagedCourses
      };
    }

    case CONDENSED_CLUB_COURSES_SUCCESS:
      return {
        ...state,
        condensedCourseClubs: action.data,
      };

    default:
      return state;
  }
};
