import {
  PARTNER_ADD_ERROR,
  PARTNER_ADD_REQUESTING,
  PARTNER_ADD_SUCCESS,
  PARTNER_DELETE_ERROR,
  PARTNER_DELETE_REQUESTING,
  PARTNER_DELETE_SUCCESS,
  PARTNER_EDIT_ERROR,
  PARTNER_EDIT_REQUESTING,
  PARTNER_EDIT_SUCCESS,
  PARTNER_GET_ERROR,
  PARTNER_GET_REQUESTING,
  PARTNER_GET_SUCCESS
} from './constants';
import { formDefaultErrorState, formDefaultRequestingState } from '@src/utils/storeUtils';

const initialState: PartnerState = {
  partner: undefined,
  requesting: false,
  successful: false,
  error: [],
};

export const partnerReducer = (state: PartnerState = initialState, action: any) => {
  switch (action.type) {
    case PARTNER_GET_REQUESTING:
      return {
        ...formDefaultRequestingState(state),
        partner: undefined,
      };

    case PARTNER_ADD_REQUESTING:
    case PARTNER_DELETE_REQUESTING:
    case PARTNER_EDIT_REQUESTING:
      return formDefaultRequestingState(state);

    case PARTNER_GET_ERROR:
    case PARTNER_ADD_ERROR:
    case PARTNER_DELETE_ERROR:
    case PARTNER_EDIT_ERROR:
      return formDefaultErrorState(state, action);

    case PARTNER_GET_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
        partner: action.data,
      };

    case PARTNER_ADD_SUCCESS:
    case PARTNER_DELETE_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
      };

    case PARTNER_EDIT_SUCCESS:
      const { data, } = action;
      return {
        ...state,
        requesting: false,
        successful: true,
        partner: data,
      };

    default:
      return state;
  }
};