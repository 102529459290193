import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import * as clubActions from '@src/store/club/actions';
import * as clubCourseActions from '@src/store/clubCourse/actions';
import * as acceptableScoreActions from '@src/store/acceptableScore/actions';
import {
  Dialog,
  DialogTitle, Table, TableBody, TableCell, TableHead, TableRow,
  TextField, Input, Card, CardHeader, CardContent,
} from '@material-ui/core';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { ModalContentOverlayLoader, renderFormActions } from '@src/components/modals/ui';
import AcceptableScoreInputsValidator, { ErrorInputs } from '@src/components/forms/AcceptableScoreInputsValidator';
import DialogContent from '@material-ui/core/DialogContent';
import moment from 'moment';
import LocalizedDateTimePicker from '@src/components/forms/LocalizedDateTimePicker';
import { ClubCourseSelect } from '@src/components/forms/CourseSelect';
import { ClubSelect } from '@src/components/forms/ClubSelect';
import  Select from '@src/components/forms/Select';
import { isNaN, omit } from 'lodash';

interface Jurisdiction {
  id: string | null;
  name: string;
}

interface TypeOfRound {
  id: string | null;
  name: string;
}

const initialTemporaryCourseHoles = [
  { holeNumber: 1, par: undefined, strokeIndex: undefined },
  { holeNumber: 2, par: undefined, strokeIndex: undefined },
  { holeNumber: 3, par: undefined, strokeIndex: undefined },
  { holeNumber: 4, par: undefined, strokeIndex: undefined },
  { holeNumber: 5, par: undefined, strokeIndex: undefined },
  { holeNumber: 6, par: undefined, strokeIndex: undefined },
  { holeNumber: 7, par: undefined, strokeIndex: undefined },
  { holeNumber: 8, par: undefined, strokeIndex: undefined },
  { holeNumber: 9, par: undefined, strokeIndex: undefined },
  { holeNumber: 10, par: undefined, strokeIndex: undefined },
  { holeNumber: 11, par: undefined, strokeIndex: undefined },
  { holeNumber: 12, par: undefined, strokeIndex: undefined },
  { holeNumber: 13, par: undefined, strokeIndex: undefined },
  { holeNumber: 14, par: undefined, strokeIndex: undefined },
  { holeNumber: 15, par: undefined, strokeIndex: undefined },
  { holeNumber: 16, par: undefined, strokeIndex: undefined },
  { holeNumber: 17, par: undefined, strokeIndex: undefined },
  { holeNumber: 18, par: undefined, strokeIndex: undefined },
];

interface Props extends WrappedComponentProps {
  onClose?: () => void;
  onComplete?: () => void;
  addAcceptableScore: (params: AcceptableScoreAdd) => any;
  addAcceptableScoreAway: (params: AcceptableScoreAwayAdd) => any;
  fetchClub: (params: Club) => any;
  fetchClubCourseGuide: (params: FetchClubCourseGuide) => any;
  getClubCourse: (params: ClubCourse) => any;

  open: boolean;
  resetState: boolean;
  locale: AppLocale;

  personState: PersonState;
  courseState: ClubCourseState;
  clubState: ClubState;
  scoreState: AcceptableScoreState;
}

type State = {
  playedAtClub: Club | undefined;
  playedAtCourse: ClubCourse | undefined;
  personId: number;
  namePlayer: string;
  nameOfMarker: string;
  selectedTee: CourseHoleTee | null;
  listOfHoleScores: AcceptableScoreHoleAdd[];
  strokesEntered: any[];
  courseJurisdiction: Jurisdiction;
  roundType: TypeOfRound;
  markerName: string;
  // 20.02.2020 min date.
  roundDate: Date | null;
  competitionName?: string;
  temporaryCourse: TemporaryCourseAdd;
  scoreAdjustmentWithPcc: ScoreAdjustmentWithPccAdd;
  stateReset: boolean;
};

const teeColorMap = ['UNKNOWN', 'GREEN', 'RED', 'BLUE', 'YELLOW', 'WHITE', 'BLACK', 'GOLD'];
const teeColors = ['⚪', '🟢', '🔴', '🔵', '🟡', '⚪', '⚫', '🟠'];

const initialState: State = {
  playedAtClub: undefined,
  playedAtCourse: undefined,
  personId: 0,
  namePlayer: '',
  nameOfMarker: '',
  selectedTee: null,
  listOfHoleScores: Array(),
  strokesEntered: Array(),
  courseJurisdiction: { id: null, } as Jurisdiction,
  roundType: { id: null, } as TypeOfRound,
  markerName: '',
  roundDate: null,
  competitionName: '',
  stateReset: true,
  temporaryCourse: {
    country: '',
    courseName: '',
    teeName: '',
    par: undefined,
    courseRating: undefined,
    slopeRating: undefined,
    numberOfHoles: 18,
    listOfHoles: initialTemporaryCourseHoles,
  } as TemporaryCourseAdd,
  scoreAdjustmentWithPcc: {
    playingConditionCalculation: 0,
  } as ScoreAdjustmentWithPccAdd,
};

class AcceptableScoreModal extends React.Component<Props, State> {

  /**
   * Sets the state to initial
   * @param props
   */
  constructor(props: Props) {
    super(props);
    console.log('__constructor__');
    this.state = initialState;
  }

  /**
   * Pre loads defaults
   */
  componentDidMount() {
    console.log('__component_did_mount__');
    this._loadDefaults();
  }

  /**
   */
  componentDidUnmount() {
    console.log('__component_did_unmount__');
  }

  /**
   * @param nextProps
   * @param nextState
   */
  shouldComponentUpdate(nextProps, nextState): boolean {
    console.log('__should_component_update__');
    console.log(nextProps);
    console.log(nextState);
    return true;
  }

  /**
   *
   * @param prevProps
   */
  componentDidUpdate(prevProps) {
    console.log('__component_did_update__');

    if (!prevProps.open && this.props.open && this.props.resetState) {
      console.log('__calling_for_reset__');
      this._handleOnReset();
      return;
    }

    if (
        (!prevProps.clubState.courseGuide && this.props.clubState.courseGuide) ||
        (prevProps.clubState.courseGuide?.club?.idClub !== this.props.clubState.courseGuide?.club.idClub)
    ) {
      console.log('__course_guide_loaded__');

      if (this._getParentCourses() && this._getParentCourses().length === 1) {
        console.log('__only_1_course_so_loading_it__');
        this.setState({ playedAtCourse: this.props.clubState.courseGuide.courses[0] });
        const {
          getClubCourse
        } = this.props;

        if (this.props.courseState.clubCourse?.id !== this.props.clubState.courseGuide.courses[0].id) {
          getClubCourse({
            id: this.props.clubState.courseGuide.courses[0].id,
          } as ClubCourse);
        }
      }
    }
  }

  render(): React.ReactNode {
    const {
      open,
    } = this.props;

    const {
      playedAtClub,
      namePlayer,
      markerName,
      roundDate,
      roundType,
      competitionName,
      courseJurisdiction,
      playedAtCourse,
      selectedTee,
      listOfHoleScores,
      temporaryCourse: {
        country,
        courseName,
        numberOfHoles,
        courseRating,
        slopeRating,
        teeName,
        listOfHoles,
      },
      scoreAdjustmentWithPcc: {
        playingConditionCalculation,
      },
    }: Readonly<State> = this.state;
    const validated = this.state.courseJurisdiction.id === 'HOME' ?
        { playedAtClub, playedAtCourse, markerName, roundDate, courseJurisdiction, roundType,
          selectedTee, listOfHoleScores } :
        { markerName, roundDate, courseJurisdiction, roundType, country, courseName, numberOfHoles,
          playingConditionCalculation, courseRating, slopeRating, teeName, listOfHoles, listOfHoleScores };
    return (
        <Dialog open={open}>
          <style>
            {`
              .strokeInput input[type="text"] {
                text-align: center !important;
                align: center !important;
              }
          ` }
          </style>
          <DialogTitle>
            <FormattedMessage id={'navigation.golfClubAddScore'} />
          </DialogTitle>
          {this._isLoading() && <ModalContentOverlayLoader/>}
          <AcceptableScoreInputsValidator
              values={validated}
          >
            {({ errorInputs, validateThenApply, validateValues }) => <>
              <DialogContent>

                <Select
                    options={[
                      {id: 'HOME', name: this.props.locale.appLanguage.data.strings.jurisdictionHome},
                      {id: 'AWAY', name: this.props.locale.appLanguage.data.strings.jurisdictionAway},
                    ]}
                    error={errorInputs.courseJurisdiction}
                    required={true}
                    selected={courseJurisdiction}
                    multiSelect={false}
                    onChangeCallback={(values: any) => {
                      if (values) {
                        this.setState({
                          courseJurisdiction: values as Jurisdiction,
                        }, validateValues);
                      }
                    }}
                    labelText={<FormattedMessage id={'forms.clubCourseJurisdictionSelect.single.label'} />}
                    placeholderText={<FormattedMessage id={'forms.clubCourseJurisdictionSelect.single.placeholder'} />}
                />

                <TextField
                    margin={'normal'}
                    fullWidth={true}
                    label={<FormattedMessage id={'strings.name'} />}
                    value={namePlayer}
                    required={true}
                    inputProps={{ readOnly: true }}
                />

                <TextField
                    margin={'normal'}
                    fullWidth={true}
                    error={errorInputs.markerName}
                    label={<FormattedMessage id={'strings.nameOfMarker'} />}
                    value={markerName}
                    onChange={(e) => {
                      this.setState({
                        markerName: e.target.value,
                      }, validateValues);
                    }}
                    required={true}
                    name={'nameOfMarker'}
                />

                <LocalizedDateTimePicker
                    margin={'normal'}
                    style={{ width: '45%', }}
                    error={errorInputs.roundDate}
                    value={roundDate != null
                        ? moment.utc(roundDate as Date).local(false).toDate()
                        : null}
                    disablePast={false}
                    // This is not working in Safari
                    // minDate={new Date('2020-02-20 00:00:00')}
                    disableFuture={true}
                    onChange={(newDate: Date) => {
                      let date =  moment(newDate).utc(false).toDate();
                      let now = new Date();
                      let start = moment('2020-02-20 00:00:00').utc(false).toDate();
                      if (date > now) {
                        date = now;
                      } else if (date < start) {
                        date = start;
                      }
                      this.setState({
                        roundDate: date,
                      }, validateValues);
                    }}
                    fullWidth={false}
                    label={<FormattedMessage id={'strings.timePlayed'} />}
                    clearable={true}
                    required={true}
                    name={'roundDate'}
                />

                <LocalizedDateTimePicker
                    margin={'normal'}
                    style={{ float: 'right', width: '45%', }}
                    value={new Date()}
                    disablePast={true}
                    fullWidth={false}
                    onChange={(newDate: Date) => {  /* should never be reached */ console.log(newDate); }}
                    label={<FormattedMessage id={'strings.timePosted'} />}
                    clearable={false}
                    required={true}
                    inputProps={{ readOnly: true }}
                    name={'postedDate'}
                />

                <Select
                    options={[
                      // {id: 'ARTIFICIAL', name: this.props.locale.appLanguage.data.strings.typeOfRoundArtificial},
                      {id: 'GENERALPLAY', name: this.props.locale.appLanguage.data.strings.typeOfRoundGeneralPlay},
                      {id: 'COMPETITION', name: this.props.locale.appLanguage.data.strings.typeOfRoundCompetition},
                    ]}
                    required={true}
                    error={errorInputs.roundType}
                    selected={roundType}
                    multiSelect={false}
                    onChangeCallback={(values: any) => {
                      if (values) {
                        this.setState({
                          roundType: values as TypeOfRound,
                        }, validateValues);
                      }
                    }}
                    labelText={<FormattedMessage id={'forms.typeOfRoundSelect.single.label'} />}
                    placeholderText={<FormattedMessage id={'forms.typeOfRoundSelect.single.placeholder'} />}
                />

                {this.state.roundType.id === 'COMPETITION' &&  <TextField
                    margin={'normal'}
                    fullWidth={true}
                    label={<FormattedMessage id={'strings.nameOfCompetition'} />}
                    value={competitionName}
                    onChange={(e) => {
                      this.setState({
                        competitionName: e.target.value,
                      }, validateValues);
                    }}
                    required={false}
                    name={'competitionName'}
                />}

                {this.state.courseJurisdiction.id === 'HOME' && this.renderHome(errorInputs, validateValues)}

                {this.state.courseJurisdiction.id === 'AWAY' && this.renderAway(errorInputs, validateValues)}

              </DialogContent>
              {renderFormActions({
                parentContext: 'MODAL',
                onClose: this._handleOnCancel,
                onCreate: validateThenApply.bind(this, this._handleOnCreate),
              })}
            </>}
          </AcceptableScoreInputsValidator>
        </Dialog>
    );
  }

  renderHome(errorInputs: ErrorInputs, validateValues: any): React.ReactNode {
    const {
      playedAtClub,
      playedAtCourse,
    }: Readonly<State> = this.state;

    return (
        <>
          <ClubSelect
              error={errorInputs.playedAtClub}
              required={true}
              margin={'normal'}
              multi={false}
              selectedClubs={playedAtClub}
              onChangeCallback={(values: any) => {
                if (!values) {
                  return;
                } else {
                  console.log('select club:');
                  console.log(values);
                  this.setState({
                    playedAtClub: values, playedAtCourse: undefined, selectedTee: null
                  }, validateValues);
                }
              }}
          />
          {this.props.clubState.courseGuide && <ClubCourseSelect
              error={errorInputs.playedAtCourse}
              required={true}
              club={playedAtClub}
              margin={'normal'}
              selectedCourse={playedAtCourse as ClubCourse}
              onChangeCallback={(values: any) => {
                if (!values) {
                  return;
                } else {
                  this.setState({
                    playedAtCourse: values, selectedTee: null,
                  }, validateValues);
                  const {
                    getClubCourse
                  } = this.props;
                  getClubCourse({
                    id: values.id,
                  } as ClubCourse);
                }
              }}
          />}
          {this.props.courseState.clubCourse && this.state.playedAtCourse &&
           this._renderHomeCourseForm(errorInputs, validateValues)}
        </>
    );
  }

  renderAway(errorInputs: ErrorInputs, validateValues: any): React.ReactNode {

    const {
      temporaryCourse: {
        country,
        courseName,
        numberOfHoles,
        courseRating,
        slopeRating,
        teeName,
      },
      scoreAdjustmentWithPcc: {
        playingConditionCalculation,
      }
    }: Readonly<State> = this.state;

    return (
        <>
          <TextField
              margin={'normal'}
              fullWidth={true}
              error={errorInputs.country}
              label={<FormattedMessage id={'strings.country'} />}
              value={country ?? ''}
              onChange={(e) => {
                let state = this.state;
                state.temporaryCourse.country = e.target.value;
                console.log(state);
                this.setState(state, validateValues);
              }}
              required={true}
              name={'temporaryCourse[country]'}
          />

          <TextField
              margin={'normal'}
              fullWidth={true}
              error={errorInputs.courseName}
              label={<FormattedMessage id={'strings.course'} />}
              value={courseName ?? ''}
              onChange={(e) => {
                let state = this.state;
                state.temporaryCourse.courseName = e.target.value;
                console.log(state);
                this.setState(state, validateValues);
              }}
              required={true}
              name={'temporaryCourse[courseName]'}
          />

          <div style={{ float : 'left', width: '45%'}}><Select
              margin={'normal'}
              options={[{ id: '9', name: '9' }, { id: '18', name: '18' }]}
              selected={{ id: numberOfHoles, name: numberOfHoles }}
              error={errorInputs.numberOfHoles}
              labelText={<FormattedMessage id={'strings.numberOfHoles'} />}
              onChangeCallback={(values: any) => {
                let state = this.state;
                state.temporaryCourse.numberOfHoles = values ? parseInt(values.id, 10) : 18;
                console.log(state);
                this.setState(state, validateValues);

              }}
              required={true}
          /></div>

          <div style={{ float : 'right', width: '45%'}}><Select
              margin={'normal'}
              options={[
                { id: '-1', name: '-1' }, { id: '0', name: '0' }, { id: '1', name: '1' },
                { id: '2', name: '2' }, { id: '3', name: '3' },
              ]}
              selected={{ id: playingConditionCalculation, name: playingConditionCalculation }}
              error={errorInputs.playingConditionCalculation}
              labelText={<FormattedMessage id={'strings.playingConditionCalculation'} />}
              onChangeCallback={(values: any) => {
                let state = this.state;
                state.scoreAdjustmentWithPcc.playingConditionCalculation = values ? parseInt(values.id, 10) : 18;
                console.log(state);
                this.setState(state, validateValues);
              }}
              required={false}
          /></div>

          <TextField
              style={{ width: '45%' }}
              margin={'normal'}
              fullWidth={false}
              error={errorInputs.par}
              label={<FormattedMessage id={'strings.par'} />}
              value={this._getCoursePar()}
              onChange={(e) => { }}
              inputProps={{ readOnly: true }}
              type={'number'}
              required={true}
              name={'temporaryCourse[par]'}
          />

          <TextField
              style={{ float: 'right', width: '45%' }}
              margin={'normal'}
              fullWidth={false}
              error={errorInputs.courseRating}
              label={<FormattedMessage id={'strings.courseRating'} />}
              value={courseRating ?? ''}
              onChange={(e) => {
                let state = this.state;
                state.temporaryCourse.courseRating = parseFloat(e.target.value);
                console.log(state);
                this.setState(state, validateValues);
              }}
              type={'number'}
              required={true}
              name={'temporaryCourse[courseRating]'}
              inputProps={{ step: '.1' }}
          />

          <TextField
              style={{ width: '45%' }}
              margin={'normal'}
              fullWidth={false}
              error={errorInputs.slopeRating}
              label={<FormattedMessage id={'strings.slopeRating'} />}
              value={slopeRating ?? ''}
              onChange={(e) => {
                let state = this.state;
                state.temporaryCourse.slopeRating = parseInt(e.target.value, 10);
                console.log(state);
                this.setState(state, validateValues);
              }}
              type={'number'}
              required={true}
              name={'temporaryCourse[slopeRating]'}
          />

          <TextField
              style={{ float: 'right', width: '45%' }}
              margin={'normal'}
              fullWidth={false}
              error={errorInputs.teeName}
              label={<FormattedMessage id={'strings.tee'} />}
              value={teeName ?? ''}
              onChange={(e) => {
                let state = this.state;
                state.temporaryCourse.teeName = e.target.value;
                console.log(state);
                this.setState(state, validateValues);
              }}
              required={true}
              name={'temporaryCourse[teeName]'}
          />

          {this._renderCourseHoles(errorInputs, validateValues)}
        </>
    );
  }

  private _getCoursePar(): number {
    let par = 0;
    let holes = this.state.temporaryCourse.numberOfHoles ? this.state.temporaryCourse.numberOfHoles : 18;
    for (let i = 0; i < holes; i++) {
      let holePar = this._getHolePar(i);
      if (holePar) {
        console.log(this._getHolePar(i));
        par += parseInt('' + this._getHolePar(i), 10);
      }
    }
    console.log('PAR: ' + par);
    return par;
  }

  private _initializeWithPerson(person: Person) {
    console.log('__init_with_person__');

    const {
      locale: {
        appLanguage
      },
    } = this.props;
    this.state.strokesEntered.length = 0;
    const state = {
      ...initialState,
      ...this._getPersonState(person),
      courseJurisdiction: {
        id: 'HOME',
        name: appLanguage.data.strings.jurisdictionHome,
      },
      roundType: {
        id: 'GENERALPLAY',
        name: appLanguage.data.strings.typeOfRoundGeneralPlay,
      },
    };

    this.setState(state);

  }

  private _initializeWithoutPerson() {
    console.log('__init_without_person__');
    const {
      locale: {
        appLanguage
      }
    } = this.props;

    const state = {
      ...initialState,
      courseJurisdiction: {
        id: 'HOME',
        name: appLanguage.data.strings.jurisdictionHome,
      },
      roundType: {
        id: 'GENERALPLAY',
        name: appLanguage.data.strings.typeOfRoundGeneralPlay,
      },
    };

    this.setState(state);

  }

  private _handleOnCreate = () => {
    if (this.state.courseJurisdiction.id === 'HOME') {
      const params = this._getFormAcceptableScoreParams();
      console.log(params);

      const { addAcceptableScore } = this.props;
      addAcceptableScore({
        ...params,
        onComplete: this._handleOnComplete,
      });
    } else {
      const params = this._getFormAcceptableScoreAwayParams();
      console.log(params);

      const { addAcceptableScoreAway } = this.props;
      addAcceptableScoreAway({
        ...params,
        onComplete: this._handleOnComplete,
      });
    }
  }

  private _getParentCourses(): ClubCourse[] {
    return this.props.clubState.courseGuide?.courses.map(option => {
      if (option.parents && option.parents.length > 0) {
        return null;
      }
      if (option.status !== 'ACTIVE') {
        return null;
      }
      return option;
    }).filter(Boolean) as ClubCourse[];
  }

  private _getFormAcceptableScoreParams() {
    let {
      personId,
      competitionName,
      markerName,
      listOfHoleScores,
    } = this.state;

    const acceptableScore = {
      postedDate: new Date(),
      roundDate: this.state.roundDate ? this.state.roundDate : new Date(),
      courseId: this.props.courseState.clubCourse ? this.props.courseState.clubCourse.id : 0,
      teeColorId: teeColorMap.indexOf(this.state.selectedTee?.color as string),
      roundType: this.state.roundType.id as string,
      playFormat: 'STROKEPLAY',
      holesPlayed: this._getHolesPlayed(),
      scoreAdjustment: null,
    };
    console.log(this.state.listOfHoleScores);

    listOfHoleScores = this._getConvertedListOfHoleScores(listOfHoleScores);

    const {
      postedDate,
      roundDate,
      courseId,
      teeColorId,
      roundType,
      playFormat,
      holesPlayed,
      scoreAdjustment,
    } = acceptableScore;

    return {
      personId,
      roundDate,
      postedDate,
      courseId,
      teeColorId,
      roundType,
      playFormat,
      competitionName,
      markerName,
      holesPlayed,
      listOfHoleScores,
      scoreAdjustment,
    };
  }

  private _getFormAcceptableScoreAwayParams() {
    let {
      personId,
      competitionName,
      markerName,
      listOfHoleScores,
      temporaryCourse,
      scoreAdjustmentWithPcc,
    } = this.state;

    const acceptableScoreAway = {
      postedDate: new Date(),
      roundDate: this.state.roundDate ? this.state.roundDate : new Date(),
      roundType: this.state.roundType.id as string,
      playFormat: 'STROKEPLAY',
      holesPlayed: this._getHolesPlayedAway(),
    };
    console.log(this.state.temporaryCourse.listOfHoles);
    console.log(this.state.listOfHoleScores);

    listOfHoleScores = this._getConvertedListOfHoleScores(listOfHoleScores);

    const {
      postedDate,
      roundDate,
      roundType,
      playFormat,
      holesPlayed,
    } = acceptableScoreAway;

    this.state.temporaryCourse.listOfHoles.length = this.state.temporaryCourse.numberOfHoles as number;
    this.state.temporaryCourse.par = this._getCoursePar();

    return {
      personId,
      roundDate,
      postedDate,
      temporaryCourse,
      roundType,
      playFormat,
      competitionName,
      markerName,
      holesPlayed,
      listOfHoleScores,
      scoreAdjustmentWithPcc,
    };
  }

  private _getConvertedListOfHoleScores(listOfHoleScores: AcceptableScoreHoleAdd[]): AcceptableScoreHoleAdd[] {
    return listOfHoleScores.map(option => {
      let strokes = option.strokes;
      if (strokes === '0' || strokes === '' || strokes === 0 || strokes === undefined) {
        strokes = -1;
      } else if (strokes === '-') {
        strokes = 0;
      } else {
        strokes = parseInt('' + strokes, 10);
      }
      return {...option, strokes: strokes };
    }).filter(option => option.strokes !== -1);
  }

  private _handleOnPartialReset = (): void => {
    console.log('__handle_on_partial_reset__');

    this.state.strokesEntered.length = 0;
    let state = omit(initialState,
          ['namePlayer', 'playedAtClub', 'playedAtCourse', 'personId', 'selectedTee', 'courseJurisdiction',
            'roundType', 'temporaryCourse', 'scoreAdjustmentWithPcc']);
    state.stateReset = false;

    console.log(state);
    this.setState(state);

    this.props.scoreState.requesting = false;
    const { onClose, onComplete } = this.props;
    if (onClose) {
      onClose();
    }

    if (onComplete) {
      onComplete();
    }
  }

  private _handleOnComplete = (result: any): void => {
    console.log('ON COMPLETE!!!');
    if (result.error) {
      window.alert(result.error.message);

      if (this._isLoading()) {
        this.props.scoreState.requesting = false;
        this.forceUpdate();
      }
    } else {
      this._handleOnPartialReset();
    }
  }

  private _getTeeName(color: string) {
    const colorNames = [
      this.props.locale.appLanguage.data.strings.color.UNKNOWN,
      this.props.locale.appLanguage.data.strings.color.GREEN,
      this.props.locale.appLanguage.data.strings.color.RED,
      this.props.locale.appLanguage.data.strings.color.BLUE,
      this.props.locale.appLanguage.data.strings.color.YELLOW,
      this.props.locale.appLanguage.data.strings.color.WHITE,
      this.props.locale.appLanguage.data.strings.color.BLACK,
      this.props.locale.appLanguage.data.strings.color.GOLD,
    ];
    const index = teeColorMap.indexOf(color);
    return teeColors[index] + ' ' + colorNames[index];
  }

  private _renderHomeCourseForm(errorInputs: ErrorInputs, validateValues: any) {

    const {
      selectedTee,
    }: Readonly<State> = this.state;

    // console.log(this.props);

    let teeOptions = this.props.courseState.clubCourse?.holes[0].tees.map(option => {
      let gender = this.props.personState.person?.gender;
      let slopeRatingGender = 0;
      let courseRatingGender = 0;
      if (gender === 'FEMALE') {
        slopeRatingGender = option.slopeWomen;
        courseRatingGender = option.ratingWomen;
      } else {
        slopeRatingGender = option.slopeMen;
        courseRatingGender = option.ratingMen;
      }
      if (option.status === 'ACTIVE' && slopeRatingGender && courseRatingGender) {
        return {...option, name: option.description, altName: this._getTeeName(option.color) };
      }
      return null;
    }).filter(Boolean);

    return (
        <>
          <Select
              margin={'normal'}
              required={true}
              error={errorInputs.selectedTee}
              options={teeOptions as []}
              selected={selectedTee}
              multiSelect={false}
              onChangeCallback={(values: any) => {
                this.setState({
                  selectedTee: values as CourseHoleTee,
                }, validateValues);
              }}
              labelText={<FormattedMessage id={'forms.clubCourseTeeSelect.single.label'} />}
              placeholderText={<FormattedMessage id={'forms.clubCourseTeeSelect.single.placeholder'} />}
          />
          {this.state.selectedTee && this._renderCourseHoles(errorInputs, validateValues)}
          {!this.state.selectedTee && <div style={{ height: 400 }}>&nbsp;</div>}
        </>
    );
  }

  private _getCourseNumberOfHoles() {
    if (this.state.courseJurisdiction.id === 'HOME') {
      return this.props.courseState.clubCourse?.holes.length as number;
    } else {
      return this.state.temporaryCourse.numberOfHoles;
    }
  }

  private _renderCourseHoles(errorInputs: ErrorInputs, validateValues: any) {

    const numberOfHoles = this._getCourseNumberOfHoles() as number;
    const numberOfRows = numberOfHoles > 9 ? 2 : 1;
    const holesPerRow = numberOfHoles / numberOfRows;
    const width = 100 / (holesPerRow + 2);

    console.log('Holes: ' + numberOfHoles + ', rows: ' + numberOfRows + ', holesPerRow: ' + holesPerRow);

    const tables: any[] = [];

    tables.push(
        <Card
            key={'explanation-card-0'}
            style={{ marginBottom: '20px' }}
        >
          <CardHeader
              title={<FormattedMessage id={'strings.scoreExplanations'} />}
          />
          <CardContent>
            <dl style={{ display: 'grid', gridTemplateColumns: '20% 80%', }}>
              <dt style={{ textAlign: 'right', marginRight: 20, }}><FormattedMessage id={'strings.hcp'} /></dt>
              <dd><FormattedMessage id={'strings.hcpExplanation'} /></dd>
              <dt style={{ textAlign: 'right', marginRight: 20, }}><FormattedMessage id={'strings.par'} /></dt>
              <dd><FormattedMessage id={'strings.parExplanation'} /></dd>
              <dt style={{ textAlign: 'right', marginRight: 20, }}><FormattedMessage id={'strings.strokes'} /></dt>
              <dd><FormattedMessage id={'strings.strokesExplanation'} /></dd>
            </dl>
          </CardContent>
        </Card>);

    for (let i = 0; i < numberOfRows; i++) {
      const holeNoCells: any[] = [];
      holeNoCells.push(
          <TableCell key={'holeNoCell0'} style={{color: 'white', width: width + '%', textAlign: 'center'}}>
            <FormattedMessage id={'strings.hole'} />
          </TableCell>
      );

      const hcpCells: any[] = [];
      hcpCells.push(
          <TableCell key={'hcpCell0'} style={{width: width + '%', textAlign: 'center'}}>
            <FormattedMessage id={'strings.hcp'} />
          </TableCell>
      );

      const parCells: any[] = [];
      parCells.push(
          <TableCell key={'parCell0'} style={{width: width + '%', textAlign: 'center'}}>
            <FormattedMessage id={'strings.par'} />
          </TableCell>
      );

      const strokeCells: any[] = [];
      strokeCells.push(
          <TableCell key={'strokeCell0'} style={{width: width + '%', textAlign: 'center'}}>
            <FormattedMessage id={'strings.strokes'} />
          </TableCell>
      );

      for (let j = 0; j < holesPerRow; j++) {
        holeNoCells.push(
            <TableCell
                key={'holeNoCell' + j + 1 + i * holesPerRow}
                style={{ color: 'white', width: width + '%', padding: 4, textAlign: 'center' }}
            >
              {j + 1 + i * holesPerRow}
            </TableCell>
        );

        hcpCells.push(this._getHcpInput(j + i * holesPerRow, width, errorInputs, validateValues));
        parCells.push(this._getParInput(j + i * holesPerRow, width, errorInputs, validateValues));
        strokeCells.push(this._getStrokeInput(j + i * holesPerRow, width, errorInputs, validateValues));

      }

      let inOut = '';
      if (i === 0 && numberOfHoles > 9) {
        inOut = 'OUT';
      } else if (i === 1 && numberOfHoles > 9) {
        inOut = 'IN';
      } else {
        inOut = 'TOT';
      }
      holeNoCells.push(
          <TableCell
              key={'inOutCell' + i}
              style={{color: 'white', width: width + '%', textAlign: 'center'}}
          >
            {inOut}
          </TableCell>
      );
      hcpCells.push(
          <TableCell
            key={'hcpCellEnd' + i}
          >
            &nbsp;
          </TableCell>);
      parCells.push(
          <TableCell
              key={'parCellEnd' + i}
          >
            {this._countPar(i * holesPerRow, i * holesPerRow + holesPerRow)}
          </TableCell>);
      strokeCells.push(
          <TableCell
              key={'strokeCellEnd' + i}
          >
            {this._countStrokes(i * holesPerRow, i * holesPerRow + holesPerRow)}
          </TableCell>);

      tables.push(
          <Table key={'scoreTable' + i}>
            <TableHead style={{backgroundColor: 'green', color: 'white'}}>
              <TableRow>{holeNoCells}</TableRow>
            </TableHead>
            <TableBody>
              <TableRow>{hcpCells}</TableRow>
              <TableRow>{parCells}</TableRow>
              <TableRow>{strokeCells}</TableRow>
            </TableBody>
          </Table>
      );
    }

    return (
        <>{tables}</>
    );
  }

  private _countPar(from: number, to: number) {
    let sum = 0;
    for (let i = from; i < to; i++) {
      if (this.state.courseJurisdiction.id === 'AWAY') {
        const listOfHoles = this.state.temporaryCourse.listOfHoles;
        if (!listOfHoles) {
          return 0;
        }
        const hole = listOfHoles[i];
        let test = (hole ? hole.par : 0);
        if (test && !isNaN(test)) {
          sum = parseInt('' + sum, 10) + parseInt('' + test, 10);
        }
      } else {
        const hole = this.props.courseState.clubCourse?.holes[i];
        sum += hole?.holePar as number;
      }
    }
    return sum;
  }

  private _getHolesPlayed() {
    if (!this.props.courseState.clubCourse) {
      return 0;
    }
    let sum: number = 0;

    for (let i = 1; i <= this.props.courseState.clubCourse.holes.length; i++) {
      const strokes = this.state.listOfHoleScores.find( obj => { return obj.holeNumber === i; } );
      if (!strokes || strokes.strokes === '0' || strokes.strokes === 0) {
        continue;
      }
      let test = parseInt(strokes.strokes, 10);
      if (!isNaN(test) || strokes.strokes === '-') {
        sum++;
      }
    }
    return sum;
  }

  private _getHolesPlayedAway() {
    return this.state.listOfHoleScores.filter(hole => (hole.strokes !== '0' && hole.strokes !== 0)).length;
  }

  private _countStrokes(from: number, to: number) {
    let sum: number = 0;
    for (let i = from + 1; i <= to; i++) {
      const strokes = this.state.listOfHoleScores.find( obj => { return obj.holeNumber === i; } );
      if (!strokes || strokes.strokes === '-' || strokes.strokes === '0') {
        continue;
      }
      let test = parseInt(strokes.strokes, 10);
      if (!isNaN(test) && strokes.strokes !== '' && test > 0) {
        sum = (sum + test);
      }
    }
    return sum;
  }

  private _getStrokeIndex(index: number) {
    const list = this.state.temporaryCourse.listOfHoles;
    const hole = list ? list[index] as TemporaryCourseHoleAdd : null;
    return hole ? hole.strokeIndex : '';
  }

  private _setStrokeIndex(index: number, e: any, validateValues: any) {
    let course = this.state.temporaryCourse;
    let hole = course.listOfHoles[index];

    let test = parseInt(e.target.value, 10);

    // value should be an integer between 1 and number of holes
    if (isNaN(test) || test > (course.numberOfHoles ? course.numberOfHoles : course.listOfHoles.length) || test < 1) {
      return;
    }

    if (hole && !isNaN(test)) {
      hole.strokeIndex = e.target.value;
    } else if (!isNaN(test)) {
      course.listOfHoles[index] = {
        holeNumber: index + 1,
        strokeIndex: e.target.value,
      } as TemporaryCourseHoleAdd;
    }
    this.setState({ temporaryCourse: course }, validateValues);

    let next = document.getElementById('stroke-index-' + (index + 1));
    if (!next) {
      next = document.getElementById('pars-0');
    }
    if (!isNaN(test) && test === 1) {
      setTimeout(function() {
        next?.focus();
      }, 1000);
    } else if  (!isNaN(test) && test > 1) {
      next?.focus();
    }

  }

  private _getHolePar(index: number) {
    const list = this.state.temporaryCourse.listOfHoles;
    const hole = list ? list[index] as TemporaryCourseHoleAdd : null;
    return hole ? hole.par : '';
  }

  private _setHolePar(index: number, e: any, validateValues: any) {
    let course = this.state.temporaryCourse;
    let hole = course.listOfHoles[index];
    let value = parseInt(e.target.value, 10);

    // console.log(e.target.value);
    if (e.target.value === '') {
      e.target.value = undefined;
    } else if (isNaN(value)) {
      e.target.value = '';
      return;
    } else {
      if (value < 3) {
        e.target.value = '3';
        value = 3;
      } else if (value > 6) {
        e.target.value = '6';
        value = 6;
      }
    }

    if (hole) {
      if (isNaN(value)) {
        hole.par = undefined;
      } else {
        hole.par = parseInt(e.target.value, 10);
      }
    } else {
      course.listOfHoles[index] = {
        holeNumber: index + 1,
        par: isNaN(value) ? undefined : value,
      } as TemporaryCourseHoleAdd;
    }
    let next = document.getElementById('pars-' + (index + 1));
    if (!next) {
      next = document.getElementById('strokes-0');
    }
    if (!isNaN(value)) {
      next?.focus();
    }

    this.setState({ temporaryCourse: course }, validateValues);
  }

  private _getHcpInput(index: number, width: number, errorInputs: ErrorInputs, validateValues: any) {

    const hole = this.props.courseState.clubCourse?.holes[index] as CourseHole;
    let key = 'listOfHoles.' + index + '.' + 'strokeIndex';
    return (
        <TableCell
            key={'hcpCell' + (index + 1)}
            style={{width: width + '%', paddingLeft: 8, paddingRight: 8, textAlign: 'center'}}
        >
          <Input
              className={'strokeInput'}
              style={this._getStyleForCourseInput()}
              placeholder={''}
              margin={'none'}
              fullWidth={true}
              value={(this.state.courseJurisdiction.id === 'HOME' ?
                  hole?.holeStrokeHcp :
                  this._getStrokeIndex(index))  ?? ''}
              required={true}
              id={'stroke-index-' + index}
              name={'strokeindexes[' + index + ']'}
              inputProps={{ readOnly: this.state.courseJurisdiction.id === 'HOME' }}
              onChange={(e) => this._setStrokeIndex(index, e, validateValues)}
              error={errorInputs[key]}
          />
        </TableCell>
    );
  }

  private _getParInput(index: number, width: number, errorInputs: ErrorInputs, validateValues: any) {
    const hole = this.props.courseState.clubCourse?.holes[index];
    let key = 'listOfHoles.' + index + '.' + 'par';
    return (
        <TableCell
            key={'parCell' + (index + 1)}
            style={{width: width + '%', paddingLeft: 8, paddingRight: 8, textAlign: 'center'}}
        >
          <Input
              className={'strokeInput'}
              style={this._getStyleForCourseInput()}
              placeholder={''}
              margin={'none'}
              fullWidth={true}
              value={(this.state.courseJurisdiction.id === 'HOME' ? hole?.holePar : this._getHolePar(index)) ?? ''}
              required={true}
              id={'pars-' + index}
              name={'pars[' + index + ']'}
              inputProps={{ readOnly: this.state.courseJurisdiction.id === 'HOME' }}
              onChange={(e) => this._setHolePar(index, e, validateValues)}
              error={errorInputs[key]}
          />
        </TableCell>
    );
  }

  private _getStrokeInput(index: number, width: number, errorInputs: ErrorInputs, validateValues: any) {
    return (
        <TableCell
            key={'strokeCell' + (index + 1)}
            style={{width: width + '%', paddingLeft: 8, paddingRight: 8, textAlign: 'center'}}
        >
          <Input
              className={'strokeInput'}
              style={this._getStyleForStrokeInput(index)}
              placeholder={''}
              margin={'none'}
              fullWidth={true}
              value={this._getStrokesForHole(index + 1) ?? ''}
              required={true}
              id={'strokes-' + index}
              name={'strokes[' + index + ']'}
              onChange={(e) => this._handleStrokeEntered(e, index, validateValues)}
              error={errorInputs.playedHoles}
          />
        </TableCell>
    );
  }

  private _getStrokesForHole(holeNumber: number) {
    let holeScore = this.state.listOfHoleScores.find(
        obj => { return obj.holeNumber === holeNumber; }
    );
    if (holeScore) {
      return holeScore.strokes;
    }
    return '';
  }

  private _setStrokesForHole(holeNumber: number, strokes: any, validateValues: any) {
    let scores = [...this.state.listOfHoleScores];
    let holeScore = scores.find(
        obj => { return obj.holeNumber === holeNumber; }
    );
    if (holeScore) {
      holeScore.strokes = strokes;
      this.setState({
        listOfHoleScores: scores,
      }, validateValues);
    } else {
      scores.push({
        holeNumber: holeNumber,
        strokes: strokes
      });
      this.setState({
        listOfHoleScores: scores,
      }, validateValues);
    }
  }

  private _getStyleForCourseInput(): CSSProperties {
    return {
      minWidth: 0,
      maxWidth: '100%',
      width: '100%',
      textAlign: 'center',
      alignContent: 'center',
      borderRadius: 0,
      color: 'rgb(0,0,0)',
      backgroundColor: 'rgb(255,255,255)',
    } as CSSProperties;
  }

  private _getStyleForStrokeInput(index: number): CSSProperties {
    let styles = {
      minWidth: 0,
      maxWidth: '100%',
      width: '100%',
      textAlign: 'center',
      alignContent: 'center',
      borderRadius: 0,
      color: 'rgb(0,0,0)',
      backgroundColor: 'rgb(255,255,255)',
    };
    let result = this.state.listOfHoleScores.find( obj => { return obj.holeNumber === index + 1; });
    let strokes = result?.strokes as string;
    let test = parseInt(strokes, 10);
    if (!this.props.courseState.clubCourse || strokes === '' || test <= 0 || isNaN(test)) {
      return styles as CSSProperties;
    }

    const par = this.state.courseJurisdiction.id === 'AWAY' ?
        '' + this._getHolePar(index) :
        '' + this.props.courseState.clubCourse.holes[index].holePar;

    let diff = test - parseInt(par, 10);

    if (diff < 0) {
      styles.borderRadius = '50%' as unknown as number;
      styles.color = 'rgb(255,255,255)';
      let red = 'rgb(200,0,0)';
      let yellow = 'rgb(225,200,0)';
      styles.backgroundColor = (diff === -1) ? red : yellow;
      return styles as CSSProperties;
    } else if (diff > 0) {
      styles.borderRadius = 0;
      styles.color = 'rgb(255,255,255)';
      let other = (200 - Math.min(diff - 1, 2) * 100);
      let blue =  diff < 3 ? 255 : 200;
      styles.backgroundColor = 'rgb(0,' + other + ',' + blue + ')';
      return styles as CSSProperties;
    }

    return styles as CSSProperties;
  }

  private _handleStrokeEntered(e: any, index: number, validateValues: any) {
    let input = e.target.value;
    console.log(input, this.state.strokesEntered);

    if (input === ' ' || (this._isNaN(input) && input !== '-')) {
      input = '';
    }

    if (input.startsWith('0') && input !== '0') {
      return;
    } else if (input.startsWith('-') && input !== '-') {
      return;
    } else if (this._isNaN(input) && input !== '-' && input !== '') {
      return;
    }
    if (!this._isNaN(input) && input > 99) {
      return;
    }

    this._setStrokesForHole(
        index + 1, (input !== '' && input !== '-' ? '' + parseInt(input, 10) : input), validateValues
    );

    let next = document.getElementById('strokes-' + (index + 1));
    if (!this._isNaN(input) && input > 1 && !this.state.strokesEntered.find(obj => { return obj.index === index; })) {
      this.state.strokesEntered.push({index: index});
      if (next) {
        next.focus();
      } else {
        e.target.blur();
      }
    } else if (input === '0' || input === '-') {
      if (!this.state.strokesEntered.find(obj => { return obj.index === index; })) {
        this.state.strokesEntered.push({index: index});
      }
      if (next) {
        next.focus();
      } else {
        e.target.blur();
      }
    } else if (input === '1' && !this.state.strokesEntered.find(obj => { return obj.index === index; })) {
      let modal = this;
      setTimeout(function() {
        modal.state.strokesEntered.push({ index: index});
        if (next) {
          next.focus();
        } else {
          e.target.blur();
        }
      }, 1000);
    }
  }

  private _isLoading() {
    const {
      personState,
      courseState,
      clubState,
      scoreState,
    } = this.props;

    console.log('__is_loading__');
    console.log(personState.requesting);
    console.log(courseState.requesting);
    console.log(clubState.requesting);
    console.log(scoreState.requesting);

    return (
        personState.requesting || courseState.requesting || clubState.requesting || scoreState.requesting
    );
  }

  private _handleOnReset = () => {
    const modal = this;
    this.state.temporaryCourse.listOfHoles.length = 0;
    this.state.temporaryCourse.numberOfHoles = 18;
    this.state.scoreAdjustmentWithPcc.playingConditionCalculation = 0;
    this.state.temporaryCourse.courseName = '';
    this.state.temporaryCourse.country = '';
    this.state.temporaryCourse.teeName = '';
    this.state.temporaryCourse.courseRating = undefined;
    this.state.temporaryCourse.slopeRating = undefined;
    this.state.temporaryCourse.par = undefined;

    this.setState(initialState, function () {
      modal.props.clubState.courseGuide = undefined;
      modal._loadDefaults();
    });
  }

  private _handleOnCancel = () => {
    console.log('__handle_on_cancel__');

    const { onClose, } = this.props;
    if (onClose) {
      onClose();
    }
  }

  private _loadDefaults() {
    const {
      personState: {
        person,
      },
      fetchClubCourseGuide,
    } = this.props;

    if (person?.idPerson && !this.state.personId) {
      this._initializeWithPerson(person);
    } else {
      this._initializeWithoutPerson();
    }

    if (!this.props.clubState.courseGuide) {
      fetchClubCourseGuide({
        clubId: person?.club?.id,
      } as FetchClubCourseGuide);
    }
  }

  private _getPersonState = (person: Person) => {
    console.log('__get_person_state__');
    const {
      nameFirst,
      nameLast,
      idPerson,
    } = person;

    return {
      personId: idPerson,
      playedAtClub: ((!this.state || this.state.namePlayer === '') ? person.club : this.state.playedAtClub),
      namePlayer: nameFirst + ' ' + nameLast,
    };
  }

  private _isNaN(input: any): boolean {
    return isNaN(parseInt(input, 10));
  }

}

export default injectIntl(connect((state: StoreState) => ({
  personState: state.personReducer,
  clubState: state.clubReducer,
  courseState: state.clubCourseReducer,
  scoreState: state.acceptableScoreReducer,
  locale: state.locale
}), {
  fetchClub: clubActions.fetchClub,
  fetchClubCourseGuide: clubActions.fetchClubCourseGuide,
  getClubCourse: clubCourseActions.getClubCourse,
  addAcceptableScore: acceptableScoreActions.addAcceptableScore,
  addAcceptableScoreAway: acceptableScoreActions.addAcceptableScoreAway,
})(AcceptableScoreModal));
