import {
  MAINTENANCE_ADD_REQUESTING,
  MAINTENANCE_DELETE_REQUESTING,
  MAINTENANCE_EDIT_REQUESTING,
  MAINTENANCE_GET_REQUESTING,
  MAINTENANCE_READ_REQUESTING
} from './constants';

export const getMaintenance = ({
  id
}: MaintenanceGet) => {
  return {
    type: MAINTENANCE_GET_REQUESTING,
    maintenanceId: id,
  };
};

export const addMaintenance = (params: MaintenanceAdd) => {
  return {
    type: MAINTENANCE_ADD_REQUESTING,
    params
  };
};

export const editMaintenance = (params: MaintenanceEdit) => {
  return {
    type: MAINTENANCE_EDIT_REQUESTING,
    maintenanceId: params.id,
    params
  };
};

export const deleteMaintenance = ({
  id,
  onComplete,
}: MaintenanceDelete) => {
  return {
    type: MAINTENANCE_DELETE_REQUESTING,
    maintenanceId: id,
    params: {
      onComplete,
    },
  };
};

export const readMaintenance = ({
  id
}: MaintenanceDelete) => {
  return {
    type: MAINTENANCE_READ_REQUESTING,
    maintenanceId: id
  };
};