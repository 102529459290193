import React, { Component } from 'react';
import ReportsGreenCardScene from '@src/components/scenes/reports/ReportsGreenCardScene';

class AdminReportsGreenCard extends Component {
  render() {
    return (
      <ReportsGreenCardScene />
    );
  }
}

export {
  AdminReportsGreenCard,
};