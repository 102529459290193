import { call, put } from 'redux-saga/effects';
import { setAuth, setInitialActiveState } from '@src/store/auth/actions';
import { getInitialActiveState } from '@src/store/auth/reducer';
import { LOGIN_ERROR, LOGIN_SUCCESS } from '@src/store/login/constants';
import { LOCALSTORAGE_TOKEN_NAME } from '@src/assets/config';
import { loginCheckApi } from '@src/store/login/api/login-check-api';
import { unsetAuthFlow } from '@src/store/login/flows/unset-auth-flow';

export function* loginCheckFlow (token: string) {
  try {
    const user = yield call(loginCheckApi, token);

    if (user) {
      yield put(setAuth(user));
      yield put(setInitialActiveState(getInitialActiveState(user)));

      yield put({ type: LOGIN_SUCCESS });

      localStorage.setItem(LOCALSTORAGE_TOKEN_NAME, user.token);
    } else {
      yield call(unsetAuthFlow);
    }
  } catch (error) {
    yield call(unsetAuthFlow);
    yield put({ type: LOGIN_ERROR, error: { ...error, withToken: true} });
  }
}