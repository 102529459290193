import { call, select } from 'redux-saga/effects';
import { userAuthToken } from '@src/store/auth/selectors';
import { tokenAPIGet } from '@src/utils/storeUtils';
import { API_ROOT, APIRoute, DEFAULT_PAGINATION_LIMIT } from '@src/assets/config';
import { put } from '@redux-saga/core/effects';
import { GetCoursePCCAction } from '@src/store/clubCourse/actions';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { handleOnFunction } from '@src/utils/utils';
import { GET_COURSE_PCC_ERROR, GET_COURSE_PCC_SUCCESS } from '@src/store/clubCourse/constants';

export function * fetchCoursePCCFlow({
  courseId,
  page,
  date,
  limit = DEFAULT_PAGINATION_LIMIT,
  onComplete,
}: GetCoursePCCAction) {
  try {
    const token = yield select(userAuthToken);
    const url = SagaFlowFactory.createGETUrlWithParams({
      urlRoute: `${API_ROOT}${APIRoute.coursePCC(courseId)}`,
      page,
      limit,
      params: {
        date,
      },
    });

    const data = yield call(() => tokenAPIGet(url, token));

    handleOnFunction(onComplete, { data });
    yield put({ type: GET_COURSE_PCC_SUCCESS, data });
  } catch (error) {
    handleOnFunction(onComplete, { error });
    yield put({ type: GET_COURSE_PCC_ERROR, error });
  }
}
