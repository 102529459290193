import React from 'react';
import CourseDataIncompleteNotice from '@src/components/scenes/club/components/CourseDataIncompleteNotice';
import { GolfCourse } from '@material-ui/icons';

interface Props {
  name: string;
  isDraft?: boolean;
  type?: CourseType;
}

function ClubCourseName({
  name,
  isDraft = false,
  type = 'ORDINARY'
}: Props) {
  const prefixIcon = type === 'EXTRAORDINARY' ? (
    <GolfCourse color={'primary'} style={{ verticalAlign: 'middle', marginRight: 16 }} />
  ) : null;

  return (
    <>
      {prefixIcon}{name} <CourseDataIncompleteNotice visible={isDraft} />
    </>
  );
}

export default ClubCourseName;