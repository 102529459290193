import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardContent, CardHeader, Grid, IconButton, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import ClubCoursePartialSelect from '@src/components/scenes/club/course/ClubCoursePartialSelect';
import { SaveRounded, RemoveCircle } from '@material-ui/icons';
import {
  addClubCourse,
  addCourseChildToParent,
  copyClubCourse,
  removeCourseChildFromParent
} from '@src/store/clubCourse/actions';
import DetachCourseChildAction, { ChildrenProps } from '@src/components/headless/DetachCourseChildAction';
import CourseEditAction from '@src/components/scenes/club/course/CourseEditAction';

export interface ClubCoursePartialProps {
  clubId: number;
  parentCourse: ClubCourse;
  disabled?: boolean;
  draftCourseId?: number | null;
  onViewClick?(courseId: number): void;
  onChangeSuccess?(): void;
}

interface OwnProps {
  partialCourseId?: number;
  courseType: 'FRONT' | 'BACK';
}

interface StateProps {
  condensedCourseClubs: CondensedClubCourse[];
}

interface DispatchProps {
  addCourseChildToParent(args: AddCourseChildToParent): void;
  removeCourseChildFromParent(args: RemoveCourseChildFromParent): void;
  addClubCourse(args: ClubCourseAdd): void;
  copyClubCourse(params: ClubCourseCopy): void;
}

type Props = ClubCoursePartialProps & OwnProps & StateProps & DispatchProps;

function ClubCoursePartial({
  clubId,
  parentCourse,
  partialCourseId,
  courseType,
  addCourseChildToParent,
  addClubCourse,
  disabled = false,
  draftCourseId,
  onViewClick,
  onChangeSuccess,
}: Props) {
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [pickedCourseId, setPickedCourseId] = useState<number | string>('');

  const notifyChange = () => {
    if (onChangeSuccess) {
      onChangeSuccess();
    }
  };

  const handleRedirectToNewCourseWithId = (id:  number) => {
    if (onViewClick) {
      onViewClick(id);
    }
  };

  const handleOnCompleteWithCourseData = ({ data }: APICallResult<ClubCourse>) => {
    if (data && data.id) {
      handleRedirectToNewCourseWithId(data.id);
    }
  };

  const handleAttach = () => {
    if (pickedCourseId === 'new') {
      addClubCourse({
        clubId,
        name: `${parentCourse.name}: ${courseType === 'FRONT' ? '1-9' : '10-18'}`,
        status: 'ACTIVE',
        holeAmount: 9,
        designerName: parentCourse.designerName,
        completionTime: parentCourse.completionTime,
        description: parentCourse.description,
        characterOfCourse: parentCourse.characterOfCourse,
        courseType,
        parentCourseIds: [parentCourse.id],
        onComplete: handleOnCompleteWithCourseData,
      });
    } else {
      addCourseChildToParent({
        courseId: parentCourse.id,
        partialCourseId: parseInt(`${pickedCourseId}`, 10),
        onComplete: notifyChange,
      });
    }
  };

  return (
    <Card elevation={!!partialCourseId ? 1 : 0}>
      <CardHeader title={<FormattedMessage id={`strings.${courseType.toLowerCase()}Nine`} />} />
      {!!partialCourseId ? (
        <CardContent>
          <table style={{ width: '100%' }}>
            <tbody>
              <tr>
                <td>
                  <ClubCoursePartialSelect
                    readOnly={true}
                    value={partialCourseId}
                    courseType={courseType}
                  />
                </td>
                <td style={{ textAlign: 'right', whiteSpace: 'pre' }}>
                  {onViewClick && (
                    <Button
                      variant={'contained'}
                      onClick={() => {
                        onViewClick(partialCourseId);
                      }}
                    >
                      <FormattedMessage id={'buttons.show'} />
                    </Button>
                  )}

                  {!disabled && (
                    <>
                      <CourseEditAction
                        courseId={partialCourseId}
                        draftCourseId={draftCourseId ? draftCourseId : undefined}
                        onSuccess={handleRedirectToNewCourseWithId}
                      >
                        {({ handleEditClick }) => (
                          <Button
                            style={{ marginLeft: 16 }}
                            variant={'contained'}
                            color={'primary'}
                            onClick={handleEditClick}
                          >
                            <FormattedMessage id={'buttons.edit'} />
                          </Button>
                        )}
                      </CourseEditAction>
                      <DetachCourseChildAction
                        parentId={parentCourse.id}
                        childId={partialCourseId}
                        onSuccess={onChangeSuccess}
                      >
                        {({ handleDetach }: ChildrenProps) => (
                          <IconButton
                            style={{ marginLeft: 8 }}
                            color={'secondary'}
                            onClick={handleDetach}
                          >
                            <RemoveCircle />
                          </IconButton>
                        )}
                      </DetachCourseChildAction>
                    </>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </CardContent>
      ) : (
        <CardContent>
          {showAdd ? (
            <Grid container={true} spacing={2} alignItems={'center'}>
              <Grid item={true} xs={10}>
                <ClubCoursePartialSelect
                  value={pickedCourseId}
                  courseType={courseType}
                  onChange={value => setPickedCourseId(value)}
                  margin={'none'}
                />
              </Grid>
              <Grid item={true} xs={2}>
                <IconButton
                  disabled={!pickedCourseId}
                  color={'primary'}
                  onClick={handleAttach}
                >
                  <SaveRounded />
                </IconButton>
              </Grid>
              <Grid item={true} xs={12}>
                <Button onClick={() => setShowAdd(false)}>
                  <FormattedMessage id={'buttons.cancel'} />
                </Button>
              </Grid>
            </Grid>
          ) : (
            !disabled ? (
              <Button variant={'contained'} color={'primary'} onClick={() => setShowAdd(true)}>
                <FormattedMessage id={'buttons.add'} />
              </Button>
            ) : (
              <Typography variant="h6" gutterBottom={true}>
                -
              </Typography>
            )
          )}
        </CardContent>
      )}
    </Card>
  );
}

export default connect<StateProps, DispatchProps, OwnProps, StoreState>(state => ({
  condensedCourseClubs: state.clubCoursesReducer.condensedCourseClubs,
}), {
  addCourseChildToParent, removeCourseChildFromParent, addClubCourse, copyClubCourse
})(ClubCoursePartial);
