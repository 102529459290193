import {
  VENDOR_ADD_ERROR,
  VENDOR_ADD_REQUESTING,
  VENDOR_ADD_SUCCESS,
  VENDOR_EDIT_ERROR,
  VENDOR_EDIT_REQUESTING,
  VENDOR_EDIT_SUCCESS,
  VENDOR_GET_ERROR,
  VENDOR_GET_REQUESTING,
  VENDOR_GET_SUCCESS,
  VENDOR_DELETE_REQUESTING,
  VENDOR_DELETE_ERROR,
  VENDOR_DELETE_SUCCESS,
  VENDOR_CLUBS_REQUESTING,
  VENDOR_CLUBS_SUCCESS,
  VENDOR_CLUBS_ERROR
} from '@src/store/vendor/constants';
import { formDefaultErrorState, formDefaultRequestingState } from '@src/utils/storeUtils';

const initialState: VendorState = {
  vendor: undefined,
  requesting: false,
  successful: false,
  error: [],
  vendorClubs: {
    clubs: [],
    totalCount: 0,
    requesting: false,
    successful: true,
  }
};

export const vendorReducer = (state: VendorState = initialState, action: any) => {
  switch (action.type) {
    case VENDOR_GET_REQUESTING:
      return {
        ...formDefaultRequestingState(state),
        vendor: undefined,
      };

    case VENDOR_ADD_REQUESTING:
    case VENDOR_DELETE_REQUESTING:
    case VENDOR_EDIT_REQUESTING:
      return formDefaultRequestingState(state);

    case VENDOR_GET_ERROR:
    case VENDOR_ADD_ERROR:
    case VENDOR_DELETE_ERROR:
    case VENDOR_EDIT_ERROR:
    case VENDOR_CLUBS_ERROR:
      return formDefaultErrorState(state, action);

    case VENDOR_GET_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
        vendor: action.data,
      };

    case VENDOR_ADD_SUCCESS:
    case VENDOR_DELETE_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
      };

    case VENDOR_EDIT_SUCCESS:
      const { data } = action;
      return {
        ...state,
        requesting: false,
        successful: true,
        vendor: data,
      };

    case VENDOR_CLUBS_REQUESTING: {
      return {
        ...state,
        vendorClubs: {
          requesting: true,
          successful: false,
          clubs: [],
          totalCount: 0
        },
      };
    }

    case VENDOR_CLUBS_SUCCESS: {
      return {
        ...state,
        requesting: false,
        successful: true,
        vendorClubs: {
          ...action.data,
          requesting: false,
          successful: true,
        },
      };
    }

    default:
      return state;
  }
};