import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { APIRoute, DEFAULT_PAGINATION_LIMIT, DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import {
  FEDERATIONS_RESET, FEDERATIONS_ERROR, FEDERATIONS_REQUESTING, FEDERATIONS_SUCCESS
} from '@src/store/federations/constants';
import { fork, take } from 'redux-saga/effects';
import { AUTH_SET } from '@src/store/auth/constants';

/*
  GET /admin/1/federations/
 */
const fetchFederationsFlow = SagaFlowFactory.createPagedGETFlow({
  apiRoute: APIRoute.GET_FEDERATIONS,
  itemType: 'federations',
  successType: FEDERATIONS_SUCCESS,
  errorType: FEDERATIONS_ERROR
});

export default function * federationWatcher() {
  let token: string = '';

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
      page,
      limit,
    } = yield take([
      FEDERATIONS_REQUESTING,
    ]);

    switch (type) {
      case FEDERATIONS_REQUESTING:
        yield fork(fetchFederationsFlow, token, page, limit);
        break;
      case FEDERATIONS_RESET:
        yield fork(fetchFederationsFlow, token, DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_LIMIT);
        break;
      default:
    }
  }
}