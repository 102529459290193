import { call, put, select } from '@redux-saga/core/effects';
import { API_ROOT, APIRoute } from '@src/assets/config';
import { defaultOnCompleteCall, tokenAPIPut } from '@src/utils/storeUtils';
import { userAuthToken } from '@src/store/auth/selectors';
import {
  EDIT_ACCEPTABLE_SCORE_STATUS_ERROR,
  EDIT_ACCEPTABLE_SCORE_STATUS_SUCCESS
} from '@src/store/acceptableScore/constants';
import { ACCEPTABLE_SCORES_REFETCH } from '@src/store/acceptableScores/constants';
import { INVALIDATED_SCORES_REFETCH } from '@src/store/invalidatedScores/constants';
import { EditAcceptableScoreStatusAction } from '../actions';

export function* editScoreStatusFlow({
  acceptableScoreId,
  onComplete,
  status
}: EditAcceptableScoreStatusAction) {
  try {
    const token = yield select(userAuthToken);
    const response = yield call(() => {
      const url = `${API_ROOT}${APIRoute.acceptableScoreStatus(acceptableScoreId)}`;
      return tokenAPIPut(
        url,
        token,
        { status }
      );
    });

    defaultOnCompleteCall(onComplete, response);
    yield put({ type: EDIT_ACCEPTABLE_SCORE_STATUS_SUCCESS });
    yield put({ type: ACCEPTABLE_SCORES_REFETCH });
    yield put({ type: INVALIDATED_SCORES_REFETCH });
  }  catch (error) {
    defaultOnCompleteCall(onComplete, undefined, error);
    yield put({ type: EDIT_ACCEPTABLE_SCORE_STATUS_ERROR, error });
  }
}