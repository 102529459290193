import React from 'react';
import { connect } from 'react-redux';
import * as reportsActions from '@src/store/reports/actions';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import { ContentHeader, ContentHeaderTitle, ContentLoader, ContentWrap } from '@src/components/layouts/ui';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TableHead from '@material-ui/core/TableHead';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import { get } from 'lodash';
import { getActiveClubId, userIsFederationAdmin } from '@src/store/auth/selectors';

interface Props {
  clubId?: number;
  reports: ReportsState;
  federationAdminUser: boolean;
  getEBirdieStatistics(clubId?: number): void;
}

class ReportsEBirdieStatisticsScene extends React.Component<Props> {
  constructor(props) {
    super(props);
    // target with clubId. Can be undefined for federation or a number for club
    props.getEBirdieStatistics(props.clubId);
  }

  render() {
    const {
      federationAdminUser,
      reports: {
        successful,
        requesting,
      },
    } = this.props;

    return (
      <>
        <HeadHelmet titleId={'navigation.reportEbirdie'} />

        <ContentWrap>
          <ContentHeader>
            <ContentHeaderTitle>
              <FormattedMessage id={'navigation.reportEbirdie'} />
            </ContentHeaderTitle>
          </ContentHeader>

          <ContentLoader visible={requesting} />

          {(successful && !requesting) && (
            <Grid container={true} spacing={3}>
              <Grid item={true} xs={12}>
                <Card>
                  <CardContent>
                    {this._appUsersTable}
                  </CardContent>
                </Card>
              </Grid>

              {federationAdminUser && (
                <Grid item={true}>
                  <Card>
                    <CardContent>
                      {this._messagesAndOffersTable}
                    </CardContent>
                  </Card>
                </Grid>
              )}
            </Grid>
          )}
        </ContentWrap>
      </>
    );
  }

  private get _appUsersTable() {
    return (
      <>
        <Typography variant={'h5'}>
          <FormattedMessage id={'scenes.reports.eBirdie.appUsers.title'} />
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell size={'small'} />
              <TableCell size={'small'}>
                <FormattedMessage id={'strings.totalCount'} />
              </TableCell>
              <TableCell size={'small'}>
                <FormattedMessage id={'strings.males'} />
              </TableCell>
              <TableCell size={'small'}>
                <FormattedMessage id={'strings.females'} />
              </TableCell>
              <TableCell size={'small'}>
                <FormattedMessage id={'strings.boys'} />
              </TableCell>
              <TableCell size={'small'}>
                <FormattedMessage id={'strings.girls'} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this._renderAppUsersTableRows}
          </TableBody>
        </Table>
      </>
    );
  }

  private get _renderAppUsersTableRows() {
    const { reports } = this.props;
    const labels = [
      'memberships',
      'ebirdieUsers',
      'ebirdieUsersCurrentYear',
      'ebirdieUsersIn30Days',
    ];

    return labels.map(label => (
      <TableRow key={label}>
        <TableCell size={'small'}>
          <FormattedMessage id={`scenes.reports.eBirdie.appUsers.${label}`} />
        </TableCell>
        {['total', 'male', 'female', 'boys', 'girls'].map(item => (
          <TableCell size={'small'} key={`${label}-${item}`}>
            {get(reports, `eBirdieStatistics.${label}.${item}`, 0)}
          </TableCell>
        ))}
      </TableRow>
    ));
  }

  private get _messagesAndOffersTable() {
    return (
      <>
        <Typography variant={'h5'}>
          <FormattedMessage id={'scenes.reports.eBirdie.appMessagesAndOffers.title'} />
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell size={'small'}>
                <FormattedMessage id={'strings.golfClubs'} />
              </TableCell>
              <TableCell size={'small'}>
                <FormattedMessage id={'strings.totalCount'} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell size={'small'}>
                <FormattedMessage id={'scenes.reports.eBirdie.appMessagesAndOffers.clubs'} />
              </TableCell>
              <TableCell>
                {this._getValuefor(['clubs.total'])}
              </TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell size={'small'}>
                <FormattedMessage id={'scenes.reports.eBirdie.appMessagesAndOffers.sentMessage'} />
              </TableCell>
              <TableCell>
                {this._getValuefor(['clubs.hasSentMessage'])}
              </TableCell>
              <TableCell>
                {this._getValuefor(['messages.total'])}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size={'small'}>
                <FormattedMessage id={'scenes.reports.eBirdie.appMessagesAndOffers.sentMessageThisYear'} />
              </TableCell>
              <TableCell>
                {this._getValuefor(['clubs.hasSentMessageCurrentYear'])}
              </TableCell>
              <TableCell>
                {this._getValuefor(['messages.currentYear'])}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size={'small'}>
                <FormattedMessage id={'scenes.reports.eBirdie.appMessagesAndOffers.sentMessage30days'} />
              </TableCell>
              <TableCell>
                {this._getValuefor(['clubs.hasSentMessageIn30Days'])}
              </TableCell>
              <TableCell>
                {this._getValuefor(['messages.in30days'])}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size={'small'}>
                <FormattedMessage id={'scenes.reports.eBirdie.appMessagesAndOffers.sentOffer'} />
              </TableCell>
              <TableCell>
                {this._getValuefor(['clubs.hasSentOffer'])}
              </TableCell>
              <TableCell>
                {this._getValuefor(['offers.total'])}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size={'small'}>
                <FormattedMessage id={'scenes.reports.eBirdie.appMessagesAndOffers.sentOfferThisYear'} />
              </TableCell>
              <TableCell>
                {this._getValuefor(['clubs.hasSentOfferCurrentYear'])}
              </TableCell>
              <TableCell>
                {this._getValuefor(['offers.currentYear'])}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size={'small'}>
                <FormattedMessage id={'scenes.reports.eBirdie.appMessagesAndOffers.sentOffer30days'} />
              </TableCell>
              <TableCell>
                {this._getValuefor(['clubs.hasSentOfferIn30Days'])}
              </TableCell>
              <TableCell>
                {this._getValuefor(['offers.in30days'])}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </>
    );
  }

  private _getValuefor = (paths: string[]) => {
    const basePath = 'reports.eBirdieStatistics';
    const value = paths.reduce((acc: number, path: string) => (
      acc + get(this.props, `${basePath}.${path}`, 0)
    ), 0);

    return value;
  }
}

export default connect((store: StoreState) => ({
  reports: store.reportsReducer,
  clubId: getActiveClubId(store),
  federationAdminUser: userIsFederationAdmin(store),
}), {
  getEBirdieStatistics: reportsActions.getEBirdieStatistics,
})(ReportsEBirdieStatisticsScene);