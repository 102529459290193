import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setLanguage } from '@src/store/locale/actions';
import localization from '@src/localization';
import styled from 'styled-components';
import {
  Select,
  MenuItem
} from '@material-ui/core';

const SelectWrapper = styled.div`
  > div {
    min-width: 0;
  }
`;

interface Props {
  locale: AppLocale;
  setLanguage(code: string);
}

interface State {
  value?: string;
}

class LanguageSwitcherMenu extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { locale } = this.props;
    const { appLanguage } = locale;

    this.state = {
      value: appLanguage.code
    };
  }

  render() {
    const { locale } = this.props;
    const { appLanguage, availableLanguages } = locale;

    return (
      <SelectWrapper>
        { availableLanguages && availableLanguages.length > 0 &&
          <Select value={appLanguage.code} onChange={this._handleChange}>
          { availableLanguages.map((language: AppLanguage, index: number) =>
            <MenuItem key={index} value={language.code}>
              {localization.find(i => i.code === language.code)?.displayName}
            </MenuItem>
          )}
          </Select>
        }
      </SelectWrapper>
    );
  }

  private _handleChange = event => {
    this.props.setLanguage(event.target.value);
  }
}

export default connect(
  (state: StoreState) => ({locale: state.locale}),
  {setLanguage}
)(LanguageSwitcherMenu);