import { take, fork } from 'redux-saga/effects';
import {
  MESSAGES_REQUESTING,
  MESSAGES_SUCCESS,
  MESSAGES_ERROR,
  MESSAGES_RESET,
  MESSAGES_SEARCH_RESET,
  MESSAGES_SEARCH_REQUESTING,
  MESSAGES_FETCH_ALL_SENDERS,
  MESSAGES_GET_RECIPIENTS_REQUESTING,
} from './constants';
import { APIRoute, DEFAULT_PAGINATION_LIMIT, DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import { INITIAL_AUTH_ACTIVE_STATE_SET, AUTH_ACTIVE_STATE_CHANGE, AUTH_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import {
  getDownloadMimeType,
  normalizeSearchParams,
  parseTargettingParamsFromActiveState
} from '@src/utils/storeUtils';
import { fetchAllSendersFlow } from '@src/store/messages/flows/fetch-all-senders-flow';
import { getRecipientsFlow } from '@src/store/messages/flows/get-recipients-flow';

/*
  GET /api/1/messages
 */
const fetchMessagesFlow = SagaFlowFactory.createPagedGETFlow({
  apiRoute: APIRoute.GET_MESSAGES,
  itemType: 'messages',
  successType: MESSAGES_SUCCESS,
  errorType: MESSAGES_ERROR
});

/*
  G>ET /api/1/messages/search
 */
const fetchMessagesSearchFlow = SagaFlowFactory.createPagedGETFlow({
  apiRoute: APIRoute.SEARCH_MESSAGES,
  itemType: 'messages',
  successType: MESSAGES_SUCCESS,
  errorType: MESSAGES_ERROR
});

function * messagesWatcher() {
  let token: string = '';
  let targetingParams: any = {};

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
      page,
      limit,
      params,
      activeState,
      onComplete,
    } = yield take([
      MESSAGES_REQUESTING,
      MESSAGES_RESET,
      MESSAGES_SEARCH_RESET,
      AUTH_ACTIVE_STATE_CHANGE,
      INITIAL_AUTH_ACTIVE_STATE_SET,
      MESSAGES_SEARCH_REQUESTING,
      MESSAGES_FETCH_ALL_SENDERS,
      MESSAGES_GET_RECIPIENTS_REQUESTING
    ]);

    switch (type) {
      case MESSAGES_REQUESTING:
        yield fork(fetchMessagesFlow, token, page, limit, targetingParams);
        break;
      case MESSAGES_SEARCH_REQUESTING:
        yield fork(fetchMessagesSearchFlow, token, page, limit, {...normalizeSearchParams(params), ...targetingParams});
        break;
      case MESSAGES_SEARCH_RESET:
      case MESSAGES_RESET:
        yield fork(fetchMessagesFlow, token, DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_LIMIT, targetingParams);
        break;
      case INITIAL_AUTH_ACTIVE_STATE_SET:
      case AUTH_ACTIVE_STATE_CHANGE:
        targetingParams = parseTargettingParamsFromActiveState(activeState);
        break;
      case MESSAGES_FETCH_ALL_SENDERS:
        yield fork(fetchAllSendersFlow, token);
        break;
      case MESSAGES_GET_RECIPIENTS_REQUESTING:
        yield fork(getRecipientsFlow, token, params, 'recipients.xlsx',
            getDownloadMimeType('xlsx'), onComplete);
        break;
      default:
    }
  }
}

export default messagesWatcher;