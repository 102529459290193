import {
  CLUB_GREENCARD_ADD_ERROR,
  CLUB_GREENCARD_ADD_REQUESTING,
  CLUB_GREENCARD_ADD_SUCCESS,
  CLUB_GREENCARD_DELETE_ERROR,
  CLUB_GREENCARD_DELETE_REQUESTING,
  CLUB_GREENCARD_DELETE_SUCCESS,
  CLUB_GREENCARD_EDIT_ERROR,
  CLUB_GREENCARD_EDIT_REQUESTING,
  CLUB_GREENCARD_EDIT_SUCCESS,
  CLUB_GREENCARD_GET_ERROR,
  CLUB_GREENCARD_GET_REQUESTING,
  CLUB_GREENCARD_GET_SUCCESS
} from '@src/store/clubGreencard/constants';
import { formDefaultErrorState, formDefaultRequestingState } from '@src/utils/storeUtils';

const initialState: ClubGreenCardState = {
  clubGreenCard: undefined,
  requesting: false,
  successful: false,
  error: [],
};

export const clubGreenCardReducer = (state: ClubGreenCardState = initialState, action: any) => {
  switch (action.type) {
    case CLUB_GREENCARD_GET_REQUESTING:
      return {
        ...formDefaultRequestingState(state),
        clubGreenCard: undefined,
      };

    case CLUB_GREENCARD_ADD_REQUESTING:
    case CLUB_GREENCARD_DELETE_REQUESTING:
    case CLUB_GREENCARD_EDIT_REQUESTING:
      return formDefaultRequestingState(state);

    case CLUB_GREENCARD_GET_ERROR:
    case CLUB_GREENCARD_ADD_ERROR:
    case CLUB_GREENCARD_DELETE_ERROR:
    case CLUB_GREENCARD_EDIT_ERROR:
      return formDefaultErrorState(state, action);

    case CLUB_GREENCARD_GET_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
        clubGreenCard: action.data,
      };

    case CLUB_GREENCARD_ADD_SUCCESS:
    case CLUB_GREENCARD_DELETE_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
      };

    case CLUB_GREENCARD_EDIT_SUCCESS:
      const { data } = action;
      return {
        ...state,
        requesting: false,
        successful: true,
        clubGreenCard: data,
      };

    default:
      return state;
  }
};