import React from 'react';
import { put, select } from 'redux-saga/effects';
import { LOCAL_STORAGE_LANG_KEY, isProduction } from '@src/assets/config';
import { unsetPersistedAuthToken, unsetPersistedRedirectRoute } from '@src/utils/authUtils';

export const keyNotFunctionalParam = (key: string): boolean => {
  return key.substring(0, 2) !== '__' && key !== 'onComplete';
};

const testParamSuitability = (param: any, key: string) => {
  return ((param || param === false || param === 0) && keyNotFunctionalParam(key));
};

/*
  String format for object with parameter keys. Undefnied keys will
  be excluded
 */
export const formatURLParams = (params: any, prefix: string = '&') => {
  return `${prefix}${Object.keys(params).filter(key => {
    /*
      We want false Boolean even if they are false, we don't want values of undefined or null
     */
    return testParamSuitability(params[key], key);
  }).map((key) => {
    if (params[key] instanceof Array) {
      /*
        Arrays will be split into multiple variables
       */
      return params[key].map(
        (param, idx: number) => {
          // something other than number, string or bool
          if (param instanceof Object) {
            let formatted: any[] = [];
            
            Object.keys(param).forEach((paramKey: string) => {
              formatted.push([`${key}[${idx}][${paramKey}]`, param[paramKey]].map(encodeURIComponent).join('='));
            });
            
            return formatted.join('&');
          }
          
          return [`${key}[]`, param].map(encodeURIComponent).join('=');
        } 
      ).join('&');
    } else if (params[key] instanceof Date) {
      /*
        Format dates properly
      */
      return [key, params[key].toISOString()].map(encodeURIComponent).join('=');
    }
    return [key, params[key]].map(encodeURIComponent).join('=');
  }).filter(item => item !== '').join('&')}`;
};

/*
  With multi-part data we need FormData
 */
export const formatFormDataParams = (params: any) => {
  const formData = new FormData();

  const keys = Object.keys(params).filter(key => {
    return testParamSuitability(params[key], key);
  });

  keys.forEach((key: any) => {
    if (params[key] instanceof Array) {
      params[key].forEach((paramArrItem) => {
        formData.append(`${key}[]`, paramArrItem);
      });
    } else if (params[key] instanceof Date) {
      formData.append(key, params[key].toISOString());
    } else {
      formData.append(key, params[key]);
    }
  });

  return formData;
};

/*
  Array normalizer which takes in an array and returns an
  object with item ids as keys
 */
export const normalizeArray = (array: Array<any>) => {
  return array.reduce((obj: Object, item: any, currentIndex: number) => {
    (item as any)._sequence = currentIndex;
    obj[item.id] = item;
    return obj;
  }, {});
};

export const normalizeSearchParams = (inputParams: Array<any>) => {
  let params: any = {};

  const keys = Object.keys(inputParams);

  keys.forEach(key => {
    /*
      We don't want any undefineds
     */
    if (inputParams[key]) {
      /*
      Search state stores objects and we want IDs for those objects
     */
      if (key === 'status') {
        params[key] = inputParams[key].id;
      } else if (typeof inputParams[key] === 'object') {
        if (inputParams[key] !== null) {
          if (inputParams[key] instanceof Date) {
            /*
            Here we format all the search dates going towards API
           */
            params[key] = inputParams[key].toISOString();
          } else if (inputParams[key].id) {
            /*
              For objects we want to form id mappings
             */
            params[`${key}Id`] = inputParams[key].id;
          } else {
            /*
              Default guess for handling item
             */
            params[key] = inputParams[key];
          }
        }
      } else {
        if (inputParams[key]) {
          params[key] = inputParams[key];
        }
      }
    }
  });

  return params;
};

/*
  Basic API GET builder
 */
export const baseAuthorizedTokenAPICall = (url: string, token: string) => {
  return fetch(url, {
    method: 'GET',
    cache: 'no-cache',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });
};

export const tokenGenericRequestBuilder = (method: string, url: string, token: string, params: any = {}) => {

  let options: any = {
    method,
    cache: 'no-cache',
    headers: {
      'Authorization': `Bearer ${token}`
    },
  };

  /*
    Exclusion handling for content type headers. Mainly for Offer POST,PUT due to codes file
   */
  if (params.__json) {
    const {__json, onComplete, ...rest} = params;
    options.body = JSON.stringify({...rest});
  } else if (params.__multiPart) {
    const formData = formatFormDataParams(params);
    options.body = formData;
  } else {
    options.headers = {
      ...options.headers,
      'Content-Type': 'application/x-www-form-urlencoded',
    };

    const urlParams = formatURLParams(params, '');

    if (method !== 'GET') {
      options.body = urlParams.toString();
    }
  }

  return fetch(url, options)
    .then(response => {
      if (!isProduction) {
        window.console.log(response);
      }

      const { status } = response;
      switch (status) {
        case 500:
          throw { status, message: 'internal-server-error' };
        case 401:
        case 404:
          throw { status, message: 'Some of the data was not found!' };
        case 403:
          // API call unathorized so the login is no longer valid. Force logout user
          try {
            unsetPersistedAuthToken();
            unsetPersistedRedirectRoute();
          } catch (e) {
            window.console.log('could-not-clear-but-proceeding');
          } finally {
            window.location.href = '/login';
          }
          return false;
        default:
      }

      return response.json();
    })
    .then(json => {
      if (json.error) {
        throw json.error;
      }

      return json;
    })
    .catch((error) => {
      window.console.log(error);
      throw error;
    });
};

/*
  Basic tokenized API GET builder
 */
export const tokenAPIGet = (url: string, token: string, params?: any) => {
  let _url = url;

  if (params) {
    const urlParams = formatURLParams(params, '');
    _url = `${_url}?${urlParams}`;
  }

  return tokenGenericRequestBuilder('GET', _url, token, params);
};

/*
  Basic tokenized API PUT builder
 */
export const tokenAPIPut = (url: string, token: string, params: any = {}) => {
  return tokenGenericRequestBuilder('PUT', url, token, params);
};

/*
  Basic tokenized API DELETE builder
 */
export const tokenAPIDelete = (url: string, token: string, params: any) => {
  return tokenGenericRequestBuilder('DELETE', url, token, params);
};

/*
  Basic tokenized API POST builder
 */
export const tokenAPIPost = (url: string, token: string, params: any = {}) => {
  return tokenGenericRequestBuilder('POST', url, token, params);
};

/*
  Normalize offset calculation based on page number and limit
 */
export const normalizeOffset = (page: number, limit: number): number => {
  return (page - 1) * limit;
};

/*
  String format for normalized pages and limits for query vars
 */
export const formatPaginationParams = (page: number, limit: number) => {
  if ((!page || page === 0) && (!limit || limit === 0)) {
    return '';
  }

  return `offset=${normalizeOffset(page, limit)}&limit=${limit}`;
};

/*
  Normalize pagination result
 */
export const formatPaginationResults = (
  responseItems: any,
  pagedType: string,
  page: number,
  limit: number,
  totalCount: number
) => {
  return {
    [pagedType]: {[page]: normalizeArray(responseItems)},
    pagination: {
      page,
      limit,
      totalCount,
    }
  };
};

/*
  Reduce state based on current search and search state that is to be
 */
export const searchStateChangedReducerHelper = (state: any, action: any) => {
  const search = { ...state.search, ...action.search };
  return {
    ...state,
    search
  };
};

/*
  Optimistic update for state object by id
 */
export const optimisticPagedObjectUpdate = (paged: any, page: number, object: any): any => {
  const {
    id,
  } = object;
  if (paged[page]) {
    paged[page][id] = { ...paged[page][id], ...object };
  }

  const allPages: Array<string> = Object.keys(paged);

  allPages.forEach((pageNum: string) => {
    if (paged[pageNum][id]) {
      paged[pageNum][id] = { ...paged[pageNum][id], ...object };
    }
  });

  return paged;
};

/*
  localStorageControl for language
 */
export const storeSelectedLanguage = (code: string): void => {
  try {
    window.localStorage.setItem(LOCAL_STORAGE_LANG_KEY, code);
  } catch (e) {
    window.console.warn('could not store lang');
  }
};

export const getSelectedLanguageFromStorage = (): string | null => {
  try {
    return window.localStorage.getItem(LOCAL_STORAGE_LANG_KEY);
  } catch (e) {
    window.console.warn('could not store lang');
  }

  return null;
};

export const parseTargettingParamsFromActiveState = (activeState: AuthActiveState): any => {
  if (activeState) {
    if (activeState.club) {
      return {
        clubId: activeState.club.id,
      };
    } else if (activeState.partner) {
      return {
        partnerId: activeState.partner.id,
      };
    }
  }

  return {};
};

export const parseCorrectPartnerObject = (state: any, activeState?: AuthActiveState): Partner | undefined => {
  const { partner } = state;

  if (activeState && activeState.partner) {
    return activeState.partner as Partner;
  }

  return partner;
};

export const parseCorrectClubObject = (activeState?: AuthActiveState): Club | undefined => {
  if (activeState && activeState.club) {
    return activeState.club as Club;
  }

  return undefined;
};

export const parseCorrectMessageSenderObject = (activeState?: AuthActiveState): MessageSender | undefined => {
  if (activeState && activeState.club) {
    let club = activeState.club as Club;
    return { id: 'club:' + club.id, entityId: club.id , name: club.name, type: 'club' };
  }

  return { id: 'federation:1', entityId: 1, name: 'Suomen Golfliitto', type: 'federation' };
};

export const handleSearchFieldOnKeyDownEnterSniff = (
  event: React.KeyboardEvent<any>,
  cb: () => any,
  disableSearch: boolean = false,
) => {
  const {
    keyCode,
  } = event;

  switch (keyCode) {
    case 13: {
      if (!disableSearch) {
        cb();
      }
      break;
    }
    default:
  }
};

export const defaultOnCompleteCall = (
  fn?: (params: APICallResult) => any,
  data?: any,
  error?: any,
) => {
  if (fn) {
    fn({
      data,
      error,
    });
  }
};

export const formDefaultRequestingState = (state: any) => {
  return {
    ...state,
    requesting: true,
    successful: false,
    error: [],
  };
};

export const parseErrorFromAction = (action: any) => {
  return {
    body: action.error.toString(),
    time: new Date(),
    type: action.type,
  };
};

export const formDefaultErrorState = (state: any, action: any) => {
  return {
    ...state,
    requesting: false,
    successful: false,
    error: state.error.concat([parseErrorFromAction(action)])
  };
};

export const formDefaultPagedState = (state: any, action: any, pagedProp: string) => {
  const formattedState = {
    ...state,
    requesting: false,
    successful: true,
    error: [],
    pagination: action.pagination,
  };

  formattedState[pagedProp] = { ...state[pagedProp], ...action[pagedProp] };

  return formattedState;
};

export const fromDefaultPageChangeState = (state: any, action: any) => {
  return {
    ...state,
    pagination: {
      page: action.page,
      limit: state.pagination.limit,
      totalCount: state.pagination.totalCount,
    },
  };
};

export const formDefaultTargetIdState = (initialState: any, state: any, action: any) => {
  const { targetIdParams } = action;

  if (action.resetState) {
    return {
      ...initialState,
      targetIdParams,
    };
  }

  return {
    ...state,
    targetIdParams,
  };
};

export const getDownloadMimeType = (type: string): string => {
  switch (type) {
    case 'csv':
      return 'text/csv';
    case 'xls':
      return 'application/vnd.ms-excel';
    case 'excel':
    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    case 'pdf':
    default:
      return 'pdf_asia';
  }
};

export const formatURLProtocol = (url: string, protocol: string = 'https'): string => {
  if (url.match(/http(s)?:/)) {
    return url;
  }

  return `${protocol}://${url}`;
};

export const targetIdParamsChanged = (current: TargetIdParams, next: TargetIdParams): boolean => {
  return current.federationId !== next.federationId ||
    current.clubId !== next.clubId ||
    current.associationId !== next.associationId;
};

export const createTargetIdParamsActionsWithType = (type: string) => ({
  resetState = false,
  targetIdParams,
}: SetTargetIdParams) => {
  return {
    type,
    targetIdParams,
    resetState,
  };
};

export const stateTargetIdParams = (reducer: string) =>
  (store: StoreState): TargetIdParams => store[reducer].targetIdParams;

export const statePaginationParas = (reducer: string) =>
  (store: StoreState): TablePagination => store[reducer].pagination;

export const isSearchActive = (reducer: string) =>
  (store: StoreState): boolean => store[reducer].searchActive;

export const stateSearchParams = (reducer: string) =>
  (store: StoreState): boolean => store[reducer].search;

export const cleanDateIfItIsArray = (item?: Array<any> | string | null): string | undefined => {
  if (item === null) {
    return undefined;
  }

  if (Array.isArray(item)) {
    return undefined;
  }

  return item;
};

export const transformStringNullToEmpty = (value: string | null | undefined) => {
  if (value === null) {
    return '';
  }

  if (!value) {
    return '';
  }

  return value;
};

export class FunctionaryObject {
  functionary: Functionary ;

  constructor(functionary: Functionary) {
    this.functionary = functionary;
  }

  getId(): number {
    return this.functionary.id;
  }

  getFunctionaryTitle(): string {
    const { functionaryTitle} = this.functionary;
    return functionaryTitle && functionaryTitle !== null ? functionaryTitle.titleName : '';
  }

  getName(): string {
    const {
      name,
      personInfo: {
        nameLast,
        nameFirst,
      },
    } = this.functionary;

    return name ? name : `${nameLast} ${nameFirst}`;
  }

  getPhone(): string {
    const { phone, personInfo } = this.functionary;
    return phone ? phone : personInfo.phone;
  }

  getEmail(): string {
    const { email, personInfo } = this.functionary;
    return email ? email : personInfo.email;
  }

  getStatus(): ActivityStatus {
    return this.functionary.status;
  }

  getTitlePrecision(): string|null {
    const { titlePrecision } = this.functionary;
    return titlePrecision ? titlePrecision : null;
  }
}

export function * handlePagedDataRefetch(reducerName: string, requestingType: string, searchType?: string) {
  const pagination: TablePagination = yield select(statePaginationParas(reducerName));

  if (searchType) {
    const searchActive = yield select(isSearchActive(reducerName));

    if (searchActive) {
      const searchParams = yield select(stateSearchParams(reducerName));
      return yield put({
        type: searchType,
        page: pagination.page,
        limit: pagination.limit,
        params: searchParams,
      });
    }
  }

  return yield put({
    type: requestingType,
    page: pagination.page,
    limit: pagination.limit,
  });
}

export const formatStatusToStringId = (status: ActivityStatus): string => {
  return `strings.status${status.substring(0, 1)}${status.toLocaleLowerCase().substring(1)}`;
};

export const cleanTrailingSlash = (path: string, append: string = ''): string => {
  const trailingSlash = path.charAt(path.length - 1) === '/';

  if (trailingSlash) {
    return `${path.substring(0, path.length - 1)}${append}`;
  }

  return `${path}${append}`;
};

export const updateOptionValues = (state: string[], name: string, value: boolean): string[] => {
  if (value) {
    state.push(name);
  } else {
    let removableIndex: number = state.indexOf(name);
    if (removableIndex !== -1) {
      state.splice(removableIndex, 1);
    }
  }
  return state || [];
};

export const sortById = (ids: string[]): string[] => {
  return ids.sort();
};

export const sortByAsc = (ids: string[]): string[] => {
  return sortById(ids);
};

export const sortByDesc = (ids: string[]): string[] => {
  return sortById(ids).reverse();
};

export const isNumeric = (n: any): boolean =>  {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const sortBySequence = (a: { _sequence: number }, b: { _sequence: number }): any => {
  return a._sequence - b._sequence;
};

export const getPageSequence = (items?: { [id: string]: any }) => {
  if (!items) {
    return [];
  }

  return Object.keys(items).map(key => items[key]).sort(sortBySequence);
};

export const arrayToItemsOfTwo = (array: any[]) => (
  array.reduce(function (r, a, i) {
    if (i % 2) {
      r[r.length - 1].push(a);
    } else {
      r.push([a]);
    }
    return r;
  }, [])
);
