import React, { Component } from 'react';
import { MessagesScene } from '@src/components/scenes/messages/MessagesScene';

class AdminMessages extends Component {
  render() {
    return (
      <MessagesScene />
    );
  }
}

export {
  AdminMessages,
};
