import {
  USER_ADD_REQUESTING,
  USER_DELETE_REQUESTING,
  USER_EDIT_REQUESTING, USER_GET_REQUESTING,
  USER_SEND_ACTIVATION_REQUESTING
} from './constants';

const getUser = ({
  id
}: UserGet) => {
  return {
    type: USER_GET_REQUESTING,
    userId: id,
  };
};

const addUser = (params: UserAdd) => {
  return {
    type: USER_ADD_REQUESTING,
    params
  };
};

const editUser = (params: UserEdit) => {
  return {
    type: USER_EDIT_REQUESTING,
    userId: params.id,
    params,
  };
};

const deleteUser = ({
  id,
  onComplete,
}: UserDelete) => {
  return {
    type: USER_DELETE_REQUESTING,
    userId: id,
    params: {
      onComplete,
    },
  };
};

const sendActivationMail = ({
  id,
}: UserSendActivationMail) => {
  return {
    type: USER_SEND_ACTIVATION_REQUESTING,
    userId: id,
  };
};

export {
  getUser,
  addUser,
  editUser,
  deleteUser,
  sendActivationMail
};