export const CLUB_SERVICES_REQUESTING = 'CLUB_SERVICES_REQUESTING';
export const CLUB_SERVICES_CHANGE_PAGE = 'CLUB_SERVICES_CHANGE_PAGE';
export const CLUB_SERVICES_SUCCESS = 'CLUB_SERVICES_SUCCESS';
export const CLUB_SERVICES_ERROR = 'CLUB_SERVICES_ERROR';

export const CLUB_SERVICES_SEARCH_REQUESTING = 'CLUB_SERVICES_SEARCH_REQUESTING';
export const CLUB_SERVICES_SEARCH_RESET = 'CLUB_SERVICES_SEARCH_RESET';
export const CLUB_SERVICES_SEARCH_CHANGED = 'CLUB_SERVICES_SEARCH_CHANGED';

export const CLUB_SERVICES_RESET = 'CLUB_SERVICES_RESET';

export const CLUB_SERVICES_SET_INITIAL_STATE = 'CLUB_SERVICES_SET_INITIAL_STATE';