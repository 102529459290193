import {
  AUTH_SET,
  AUTH_UNSET,
  AUTH_ACTIVE_STATE_CHANGE,
  INITIAL_AUTH_ACTIVE_STATE_SET,
} from './constants';

export const setAuth = (auth: AuthState) => ({
  type: AUTH_SET,
  user: auth,
});

export const unsetAuth = () => ({
  type: AUTH_UNSET,
});

export const setActiveState = (activeState: AuthActiveState) => ({
  type: AUTH_ACTIVE_STATE_CHANGE,
  activeState,
});

export const setInitialActiveState = (activeState: AuthActiveState) => ({
  type: INITIAL_AUTH_ACTIVE_STATE_SET,
  activeState
});