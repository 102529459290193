import {
  LOGIN_CHECK_REQUESTING,
  LOGIN_REQUESTING,
  LOGOUT_REQUESTED,
  MAINTENANCES_CURRENT_REQUESTING,
  SILENT_LOGIN_CHECK_REQUESTING,
} from './constants';
import { Path } from 'history';

export interface LoginRequest {
  email: string;
  password: string;
}

export interface LoginCheckRequest {
  token: string;
  redirect?: (path: Path) => any;
}

// In order to perform an action of type LOGIN_REQUESTING
// we need an email and password
export const loginRequest = ({ email, password }: LoginRequest): any => ({
  type: LOGIN_REQUESTING,
  email,
  password,
});

export const loginCheckRequest = ({ token }: LoginCheckRequest): any => ({
  type: LOGIN_CHECK_REQUESTING,
  token,
});

export const silentLoginCheck = ({ token, redirect }: LoginCheckRequest): any => ({
  type: SILENT_LOGIN_CHECK_REQUESTING,
  token,
  redirect,
});

export const logoutRequest = (onLoggedOut?: () => void): any => ({
  type: LOGOUT_REQUESTED,
  onLoggedOut,
});

export const fetchCurrentMaintenances = (): any => ({
  type: MAINTENANCES_CURRENT_REQUESTING
});