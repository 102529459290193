import * as React from 'react';
import { connect } from 'react-redux';
import { FormControl, InputLabel, MenuItem, PropTypes, Select } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Margin = PropTypes.Margin;

type Props = {
  selectedRoleId?: number;
  onChange: (roleId?: number) => any;
  required?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  margin?: Margin;
  permittedRoles?: PermittedRole[];
  disabled?: boolean;
};

class UserRoleAndScopePicker extends React.Component<Props, {}> {
  render() {
    const {
      selectedRoleId,
      required,
      fullWidth,
      error,
      margin = 'normal',
      onChange,
      disabled,
    } = this.props;

    return (
      <FormControl required={required} margin={margin} fullWidth={fullWidth} error={error} disabled={disabled}>
        <InputLabel htmlFor="user-role-and-scope-picker">
          <FormattedMessage id={'scenes.users.form.roleInputLabel'}/>
        </InputLabel>
        <Select
          value={selectedRoleId || ''}
          inputProps={{ id: 'user-role-and-scope-picker' }}
          onChange={(event: React.ChangeEvent<{ name?: string; value: string }>) => {
            if (event && event.target && event.target.value && onChange) {
              onChange(parseInt(event.target.value, 10));
            } else if (onChange) {
              onChange(undefined);
            }
          }}
        >
          {this._renderOptions()}
        </Select>
      </FormControl>
    );
  }

  private _renderOptions = () => {
    const { permittedRoles } = this.props;
    if (!permittedRoles) {
      return null;
    }

    return permittedRoles.map(this._renderOption);
  }

  private _renderOption = (role: PermittedRole) => {
    const { selectedRoleId } = this.props;
    return (
      <MenuItem key={role.id} value={role.id} selected={role.id === selectedRoleId}>
        {role.name}
      </MenuItem>
    );
  }
}

export default connect((store: StoreState) => ({
  permittedRoles: store.authReducer.permittedRoles,
}), null)(UserRoleAndScopePicker);