export const FUNCTIONARY_TITLE_GET_REQUESTING = 'FUNCTIONARY_TITLE_GET_REQUESTING';
export const FUNCTIONARY_TITLE_GET_SUCCESS = 'FUNCTIONARY_TITLE_GET_SUCCESS';
export const FUNCTIONARY_TITLE_GET_ERROR = 'FUNCTIONARY_TITLE_GET_ERROR';

export const FUNCTIONARY_TITLE_ADD_REQUESTING = 'FUNCTIONARY_TITLE_ADD_REQUESTING';
export const FUNCTIONARY_TITLE_ADD_SUCCESS = 'FUNCTIONARY_TITLE_ADD_SUCCESS';
export const FUNCTIONARY_TITLE_ADD_ERROR = 'FUNCTIONARY_TITLE_ADD_ERROR';

export const FUNCTIONARY_TITLE_EDIT_REQUESTING = 'FUNCTIONARY_TITLE_EDIT_REQUESTING';
export const FUNCTIONARY_TITLE_EDIT_SUCCESS = 'FUNCTIONARY_TITLE_EDIT_SUCCESS';
export const FUNCTIONARY_TITLE_EDIT_ERROR = 'FUNCTIONARY_TITLE_EDIT_ERROR';

export const FUNCTIONARY_TITLE_DELETE_REQUESTING = 'FUNCTIONARY_TITLE_DELETE_REQUESTING';
export const FUNCTIONARY_TITLE_DELETE_SUCCESS = 'FUNCTIONARY_TITLE_DELETE_SUCCESS';
export const FUNCTIONARY_TITLE_DELETE_ERROR = 'FUNCTIONARY_TITLE_DELETE_ERROR';