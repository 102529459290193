export const FUNCTIONARY_TITLES_REQUESTING = 'FUNCTIONARY_TITLES_REQUESTING';
export const FUNCTIONARY_TITLES_SUCCESS = 'FUNCTIONARY_TITLES_SUCCESS';
export const FUNCTIONARY_TITLES_ERROR = 'FUNCTIONARY_TITLES_ERROR';
export const FUNCTIONARY_TITLES_CHANGE_PAGE = 'FUNCTIONARY_TITLES_CHANGE_PAGE';

export const FUNCTIONARY_TITLES_SEARCH_CHANGED = 'FUNCTIONARY_TITLES_SEARCH_CHANGED';
export const FUNCTIONARY_TITLES_SEARCH_REQUESTING = 'FUNCTIONARY_TITLES_SEARCH_REQUESTING';
export const FUNCTIONARY_TITLES_SEARCH_RESET = 'FUNCTIONARY_TITLES_SEARCH_RESET';

export const FUNCTIONARY_TITLES_SET_INITIAL_STATE = 'FUNCTIONARY_TITLES_SET_INITIAL_STATE';

export const FUNCTIONARY_TITLES_SET_TARGET_ID_PARAMS = 'FUNCTIONARY_TITLES_SET_TARGET_ID_PARAMS';

export const FUNCTIONARY_TITLES_CONDENSED_REQUESTING = 'FUNCTIONARY_TITLES_CONDENSED_REQUESTING';
export const FUNCTIONARY_TITLES_CONDENSED_SUCCESS = 'FUNCTIONARY_TITLES_CONDENSED_SUCCESS';
export const FUNCTIONARY_TITLES_CONDENSED_ERROR = 'FUNCTIONARY_TITLES_CONDENSED_ERROR';