export const REPORT_GET_REQUESTING = 'REPORT_GET_REQUESTING';
export const REPORT_GET_SUCCESS = 'REPORT_GET_SUCCESS';
export const REPORT_GET_ERROR = 'REPORT_GET_ERROR';

export const REPORT_ADD_REQUESTING = 'REPORT_ADD_REQUESTING';
export const REPORT_ADD_SUCCESS = 'REPORT_ADD_SUCCESS';
export const REPORT_ADD_ERROR = 'REPORT_ADD_ERROR';

export const REPORT_EDIT_REQUESTING = 'REPORT_EDIT_REQUESTING';
export const REPORT_EDIT_SUCCESS = 'REPORT_EDIT_SUCCESS';
export const REPORT_EDIT_ERROR = 'REPORT_EDIT_ERROR';

export const REPORT_DELETE_REQUESTING = 'REPORT_DELETE_REQUESTING';
export const REPORT_DELETE_SUCCESS = 'REPORT_DELETE_SUCCESS';
export const REPORT_DELETE_ERROR = 'REPORT_DELETE_ERROR';