import React, { Fragment } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { logoutRequest } from '@src/store/login/actions';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Menu,
  MenuItem
} from '@material-ui/core';
import { confirm } from '@src/components/modals/Confirm';
import { RouteComponentProps } from 'react-router';
import LanguageSwitcherMenu from '@src/components/menu/LanguageSwitcherMenu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PersonIcon from '@material-ui/icons/Person';
import LanguageIcon from '@material-ui/icons/Language';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import styled from 'styled-components';

const IconWrapper = styled.div`
  margin-top: 4px;
  margin-right: 8px;
`;

interface Props extends WrappedComponentProps, RouteComponentProps<any> {
  auth: AuthState;
  login: LoginState;
  doLogout: () => void;
}

interface State {
  anchorEl: any;
}

class ProfileMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      anchorEl: null
    };
  }

  render() {
    const {
      auth = {name: 'asd'},
      login,
    } = this.props;
    const { anchorEl } = this.state;

    if (login.successful) {
      return (
        <Fragment>
          {auth.name &&
            <Button
              aria-haspopup="true"
              onClick={this._handleClick}
              endIcon={<ArrowDropDownIcon/>}
            >
              {auth.name}
            </Button>
          }
            <Menu
              id="profile-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this._handleClose}
            >
              <MenuItem>
                <IconWrapper><LanguageIcon color="primary"/></IconWrapper><LanguageSwitcherMenu/>
              </MenuItem>
              <MenuItem onClick={this._handleSettingsClick}>
                <IconWrapper><PersonIcon color="primary"/></IconWrapper><FormattedMessage id="buttons.myInfo"/>
              </MenuItem>
              <MenuItem onClick={this._handleLogout}>
                <IconWrapper><ExitToAppIcon/></IconWrapper><FormattedMessage id="buttons.logout"/>
              </MenuItem>
            </Menu>
        </Fragment>
      );
    } else {
      return null;
    }
  }

  private _handleClick = event => {
    this.setState({anchorEl: event.currentTarget});
  }

  private _handleClose = () => {
    this.setState({anchorEl: null});
  }

  private _handleSettingsClick = (): void => {
    this._handleClose();
    this.props.history.push(`/admin/settings`);
  }

  private _handleLogout = (): void => {
    const {
      history,
      intl
    } = this.props;

    this._handleClose();

    confirm({
      message: intl.formatMessage({
        id: 'buttons.logout',
      }),
      options: {
        cancelText: intl.formatMessage({ id: 'buttons.cancel' }),
        okText: intl.formatMessage({ id: 'buttons.ok' }),
      }
    }).then((result: any) => {
      if (result) {
        this._handleClose();
        history.push('/logout');
      }
    }, () => {
      window.console.log('cancelled');
    });
  }
}

export default withRouter(injectIntl(connect((state: any) => ({
  auth: state.authReducer,
  login: state.loginReducer,
}), { doLogout: logoutRequest })(ProfileMenu)));
