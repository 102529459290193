import {
  LOGIN_REQUESTING,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGIN_CHECK_REQUESTING,
  LOGOUT_REQUESTED,
  MAINTENANCES_CURRENT_REQUESTING,
  MAINTENANCES_CURRENT_SUCCESS,
} from './constants';

const initialState: LoginState = {
  requesting: false,
  checkRequesting: false,
  maintenanceRequesting: false,
  successful: false,
  messages: [],
  errors: [],
  currentMaintenances: [],
};

const loginReducer = (state: LoginState = initialState, action: any): LoginState => {
  switch (action.type) {

    case LOGIN_REQUESTING:
      return {
        requesting: true,
        checkRequesting: false,
        maintenanceRequesting: false,
        successful: false,
        messages: [{ body: 'Logging in...', time: new Date() }],
        errors: [],
        currentMaintenances: []

      };

    case LOGIN_SUCCESS:
      return {
        errors: [],
        messages: [],
        requesting: false,
        checkRequesting: false,
        maintenanceRequesting: false,
        successful: true,
        currentMaintenances: []
      };

    case LOGIN_ERROR:
      return {
        errors: state.errors.concat([{
          withToken: action.error.withToken,
          body: JSON.stringify(action.error),
          time: new Date(),
        }]),
        messages: [],
        requesting: false,
        checkRequesting: false,
        maintenanceRequesting: false,
        successful: false,
        currentMaintenances: []
      };

    case LOGIN_CHECK_REQUESTING:
      return {
        requesting: false,
        checkRequesting: true,
        maintenanceRequesting: false,
        successful: false,
        messages: [{ body: 'Checking token...', time: new Date() }],
        errors: [],
        currentMaintenances: []
      };

    case LOGOUT_REQUESTED:
      return initialState;

    case MAINTENANCES_CURRENT_REQUESTING:
      return {
        requesting: false,
        checkRequesting: false,
        maintenanceRequesting: true,
        successful: false,
        messages: [{ body: 'Fetching current maintenances...', time: new Date() }],
        errors: [],
        currentMaintenances: []
      };

    case MAINTENANCES_CURRENT_SUCCESS:
      return {
        ...state,
        currentMaintenances: action.data.maintenances,
      };

    default:
      return state;
  }
};

export {
  loginReducer,
};