import {
  CLUB_ADD_REQUESTING,
  CLUB_MEMBERS_REQUESTING,
  CLUB_MEMBERS_SUCCESS,
  CLUB_MEMBERS_ERROR,
  CLUB_MEMBERS_PAGE_CHANGE,
  CLUB_MEMBERS_SEARCH_CHANGED,
  CLUB_MEMBERS_SEARCH_REQUEST,
  CLUB_MEMBERS_SEARCH_RESET,
  CLUB_ERROR,
  CLUB_SUCCESS,
  CLUB_MEMBER_ACTIVATION_SUCCESS,
  CLUB_MEMBERS_SET_INITIAL_STATE,
  ADMIN_CLUB_MEMBERS_SEARCH_RESET,
  ADMIN_CLUB_MEMBERS_SEARCH_REQUEST,
  ADMIN_CLUB_MEMBERS_REQUESTING,
  CLUB_COURSE_GUIDE_REQUESTING,
  CLUB_COURSE_GUIDE_SUCCESS,
  CLUB_COURSE_GUIDE_ERROR,
  CLUB_FUNCTIONARIES_SUCCESS,
  CLUB_EXTRA_INFO_EDIT_SUCCESS,
  CLUB_BASIC_INFO_EDIT_SUCCESS,
  CLUB_SET_TARGET_ID_PARAMS, SET_HIO_INSURANCE_SUCCESS,
} from './constants';
import { SHARED_DEFAULT_REDUCER_STATE } from '@src/assets/config';
import {
  formDefaultErrorState,
  formDefaultPagedState,
  formDefaultRequestingState, formDefaultTargetIdState,
  fromDefaultPageChangeState,
  optimisticPagedObjectUpdate,
  searchStateChangedReducerHelper
} from '@src/utils/storeUtils';
import {
  SetHIOInsuranceAction, SetHIOInsuranceErrorAction, SetHIOInsuranceSuccessAction,
  UploadHIOExcelAction,
  UploadHIOExcelErrorAction,
  UploadHIOExcelSuccessAction
} from '@src/store/club/actions';

const initialState: ClubState = {
  ...SHARED_DEFAULT_REDUCER_STATE,
  club: undefined,
  pagedMembers: {},
  courseGuide: undefined,
  search: {
    searchTerm: '',
    club: undefined,
    personStatus: 'ACTIVE',
    memberStatus: 'ACTIVE',
    memberValidity: '',
    minHandicap: '',
    maxHandicap: '',
  },
  functionaries: []
};

type Action = any
  | UploadHIOExcelAction
  | UploadHIOExcelSuccessAction
  | UploadHIOExcelErrorAction
  | SetHIOInsuranceAction
  | SetHIOInsuranceSuccessAction
  | SetHIOInsuranceErrorAction;

export const clubReducer = (state: ClubState = initialState, action: Action): ClubState => {
  switch (action.type) {

    case CLUB_SET_TARGET_ID_PARAMS:
      return formDefaultTargetIdState(initialState, state, action);

    case CLUB_SUCCESS: {
      const {
        club,
      } = action;

      return {
        ...state,
        requesting: false,
        successful: true,
        club,
      };
    }
    case CLUB_ADD_REQUESTING: {
      console.log('käsitelläään CLUB_ADD_REQUESTING');
      return formDefaultRequestingState(state);
    }
      
    case ADMIN_CLUB_MEMBERS_REQUESTING:
    case CLUB_MEMBERS_REQUESTING:
    case CLUB_COURSE_GUIDE_REQUESTING:
      return {
        ...formDefaultRequestingState(state),
        searchActive: false,
        search: initialState.search,
      };

    case CLUB_MEMBERS_SUCCESS:
      return formDefaultPagedState(state, action, 'pagedMembers');

    case CLUB_MEMBERS_PAGE_CHANGE:
      return fromDefaultPageChangeState(state, action);

    case CLUB_ERROR:
    case CLUB_MEMBERS_ERROR:
    case CLUB_COURSE_GUIDE_ERROR:
      return formDefaultErrorState(state, action);

    case CLUB_MEMBERS_SEARCH_CHANGED: {
      return searchStateChangedReducerHelper(state, action);
    }

    case ADMIN_CLUB_MEMBERS_SEARCH_REQUEST:
    case CLUB_MEMBERS_SEARCH_REQUEST: {
      let searchActive = state.searchActive;
      let pagedMembers = state.pagedMembers;
      let pagination = state.pagination;

      if (!searchActive) {
        searchActive = true;
        pagedMembers = {};
        pagination.totalCount = 0;
      }

      pagination.page = action.page;

      return {
        ...state,
        pagedMembers,
        pagination,
        searchActive,
        requesting: true
      };
    }

    case CLUB_FUNCTIONARIES_SUCCESS: {
      return {
        ...state,
        functionaries: action.data.functionaries
      };
    }

    case ADMIN_CLUB_MEMBERS_SEARCH_RESET:
    case CLUB_MEMBERS_SEARCH_RESET:
      return {
        ...initialState,
        requesting: true,
        targetIdParams: state.targetIdParams,
      };

    case CLUB_MEMBER_ACTIVATION_SUCCESS: {
      const { id, memberStatus, validTo } = action;
      const page = state.pagination.page;
      const pagedMembers = optimisticPagedObjectUpdate(state.pagedMembers, page, { id, memberStatus, validTo });

      return {
        ...state,
        pagedMembers
      };
    }

    case CLUB_COURSE_GUIDE_SUCCESS: {
      return {
        ...state,
        requesting: false,
        successful: true,
        courseGuide: action.courseGuide
      };
    }

    case CLUB_MEMBERS_SET_INITIAL_STATE:
      return {
        ...initialState
      };

    case CLUB_EXTRA_INFO_EDIT_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        courseGuide: {
          ...state.courseGuide,
          extraInfo: {
            ...data,
          },
        } as any,
      };
    }

    case CLUB_BASIC_INFO_EDIT_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        courseGuide: {
          ...state.courseGuide,
          club: {
            ...data,
          },
        } as any,
      };
    }

    case SET_HIO_INSURANCE_SUCCESS: {
      const { id, insuranceIsValid } = action;
      const page = state.pagination.page;
      const pagedMembers = optimisticPagedObjectUpdate(state.pagedMembers, page, { id, insuranceIsValid });

      return {
        ...state,
        pagedMembers
      };
    }

    default:
      return state;
  }
};
