import { API_ROOT, APIRoute } from '@src/assets/config';

export async function logoutAPICall(token: string, onLoggedOut?: () => void) {
  return fetch(`${API_ROOT}${APIRoute.POST_LOGOUT}`, {
    method: 'GET',
    cache: 'no-cache',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => response.json() )
    .then(json => {
      if (json.error) {
        throw json.error;
      }

      if (onLoggedOut) {
        onLoggedOut();
      }

      return json;
    })
    .catch((error) => {
      if (onLoggedOut) {
        onLoggedOut();
      }

      throw error;
    });
}