import {
  COMPANY_ADD_ERROR,
  COMPANY_ADD_REQUESTING,
  COMPANY_ADD_SUCCESS,
  COMPANY_DELETE_ERROR,
  COMPANY_DELETE_REQUESTING,
  COMPANY_DELETE_SUCCESS,
  COMPANY_EDIT_ERROR,
  COMPANY_EDIT_REQUESTING,
  COMPANY_EDIT_SUCCESS,
  COMPANY_GET_ERROR,
  COMPANY_GET_REQUESTING,
  COMPANY_GET_SUCCESS
} from '@src/store/company/constants';
import { formDefaultErrorState, formDefaultRequestingState } from '@src/utils/storeUtils';

const initialState: CompanyState = {
  company: undefined,
  requesting: false,
  successful: false,
  error: [],
};

export const companyReducer = (state: CompanyState = initialState, action: any) => {
  switch (action.type) {
    case COMPANY_GET_REQUESTING:
      return {
        ...formDefaultRequestingState(state),
        company: undefined,
      };

    case COMPANY_ADD_REQUESTING:
    case COMPANY_DELETE_REQUESTING:
    case COMPANY_EDIT_REQUESTING:
      return formDefaultRequestingState(state);

    case COMPANY_GET_ERROR:
    case COMPANY_ADD_ERROR:
    case COMPANY_DELETE_ERROR:
    case COMPANY_EDIT_ERROR:
      return formDefaultErrorState(state, action);

    case COMPANY_GET_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
        company: action.data,
      };

    case COMPANY_ADD_SUCCESS:
    case COMPANY_DELETE_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
      };

    case COMPANY_EDIT_SUCCESS:
      const { data } = action;
      return {
        ...state,
        requesting: false,
        successful: true,
        company: data,
      };

    default:
      return state;
  }
};