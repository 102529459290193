import {
  CLUB_SERVICE_ADD_ERROR,
  CLUB_SERVICE_ADD_REQUESTING,
  CLUB_SERVICE_ADD_SUCCESS,
  CLUB_SERVICE_DELETE_ERROR,
  CLUB_SERVICE_DELETE_REQUESTING,
  CLUB_SERVICE_DELETE_SUCCESS,
  CLUB_SERVICE_EDIT_ERROR,
  CLUB_SERVICE_EDIT_REQUESTING,
  CLUB_SERVICE_EDIT_SUCCESS,
  CLUB_SERVICE_GET_ERROR,
  CLUB_SERVICE_GET_REQUESTING,
  CLUB_SERVICE_GET_SUCCESS
} from '@src/store/clubService/constants';
import { formDefaultErrorState, formDefaultRequestingState } from '@src/utils/storeUtils';

const initialState: ClubServiceState = {
  clubService: undefined,
  requesting: false,
  successful: false,
  error: [],
};

export const clubServiceReducer = (state: ClubServiceState = initialState, action: any) => {
  switch (action.type) {
    case CLUB_SERVICE_GET_REQUESTING:
      return {
        ...formDefaultRequestingState(state),
        clubService: undefined,
      };

    case CLUB_SERVICE_ADD_REQUESTING:
    case CLUB_SERVICE_DELETE_REQUESTING:
    case CLUB_SERVICE_EDIT_REQUESTING:
      return formDefaultRequestingState(state);

    case CLUB_SERVICE_GET_ERROR:
    case CLUB_SERVICE_ADD_ERROR:
    case CLUB_SERVICE_DELETE_ERROR:
    case CLUB_SERVICE_EDIT_ERROR:
      return formDefaultErrorState(state, action);

    case CLUB_SERVICE_GET_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
        clubService: action.data,
      };

    case CLUB_SERVICE_ADD_SUCCESS:
    case CLUB_SERVICE_DELETE_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
      };

    case CLUB_SERVICE_EDIT_SUCCESS:
      const { data } = action;
      return {
        ...state,
        requesting: false,
        successful: true,
        clubService: data,
      };

    default:
      return state;
  }
};