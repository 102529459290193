export const OFFER_GET_REQUESTING = 'OFFER_GET_REQUESTING';
export const OFFER_GET_SUCCESS = 'OFFER_GET_SUCCESS';
export const OFFER_GET_ERROR = 'OFFER_GET_ERROR';

export const OFFER_ADD_REQUESTING = 'OFFER_ADD_REQUESTING';
export const OFFER_ADD_SUCCESS = 'OFFER_ADD_SUCCESS';
export const OFFER_ADD_ERROR = 'OFFER_ADD_ERROR';

export const OFFER_EDIT_REQUESTING = 'OFFER_EDIT_REQUESTING';
export const OFFER_EDIT_SUCCESS = 'OFFER_EDIT_SUCCESS';
export const OFFER_EDIT_POST_SUCCESS = 'OFFER_EDIT_POST_SUCCESS';
export const OFFER_EDIT_ERROR = 'OFFER_EDIT_ERROR';
export const OFFER_EDIT_POST_ERROR = 'OFFER_EDIT_POST_ERROR';

export const OFFER_DELETE_REQUESTING = 'OFFER_DELETE_REQUESTING';
export const OFFER_DELETE_SUCCESS = 'OFFER_DELETE_SUCCESS';
export const OFFER_DELETE_ERROR = 'OFFER_DELETE_ERROR';