import React from 'react';
import { useQuery, useMutation } from 'react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import { APIRoute, API_ROOT } from '@src/assets/config';
import useAuthToken from '@src/hooks/useAuthToken';
import { ButtonLoaderWrap, ContentHeader, ContentHeaderTitle } from '@src/components/layouts/ui';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import {
  Button, Table, TableBody, TableCell, TableHead, TableRow, Theme, makeStyles
} from '@material-ui/core';
import moment from 'moment';
import { downloadFile } from '@src/utils/utils';

interface CourseFile {
  filename: string;
  created_at: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing(1),
  },
  table: {
    marginTop: theme.spacing(2),
  },
}));

const fetchCoursesList = async (token?: string) => {
  const response = await fetch(`${API_ROOT}${APIRoute.GET_COURSES_LIST}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  });
  if (!response.ok) {
    throw {
      status: response.status
    };
  }
  return response.json();
};

const createCoursesList = async (token?: string) => {
  const response = await fetch(`${API_ROOT}${APIRoute.CREATE_COURSES_LIST}`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  });
  if (!response.ok) {
    throw {
      status: response.status
    };
  }
  return response.json();
};

const downloadCourseListContent = async (token?: string, filename?: string) => {
  const response = await fetch(`${API_ROOT}${APIRoute.getCourseListContent(filename)}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw {
      status: response.status
    };
  }
  return response.blob();
};

const CourseListTable: React.FC = () => {
  const styles = useStyles();
  const intl = useIntl();
  const token = useAuthToken();

  const coursesListQuery = useQuery<CourseFile[], any>('courses-list',
    () => fetchCoursesList(token),
    {
      retry: false,
      onError: (err) => {
        if (err.status === 400) {
          window.alert(intl.formatMessage({ id: 'errors.failedGeneric' }));
        }
      }
    }
  );

  const createCoursesListMutation = useMutation(
    () => createCoursesList(token),
    {
      onSuccess: () => {
        coursesListQuery.refetch();
      },
      onError: () => {
        window.alert(intl.formatMessage({ id: 'errors.failedGeneric' }));
      },
    }
  );

  const downloadCoursesListMutation = useMutation(
    (filename: string) => downloadCourseListContent(token, filename),
    {
      onSuccess: (data, filename) => {
        downloadFile(data, filename);
      },
      onError: () => {
        window.alert(intl.formatMessage({ id: 'errors.failedGeneric' }));
      },
    }
  );

  const handleCreateCoursesList = () => {
    createCoursesListMutation.mutate();
  };

  const handleDownload = (filename: string) => {
    downloadCoursesListMutation.mutate(filename);
  };

  return (
    <>
      <HeadHelmet titleId={'navigation.coursesList'} />
      <ContentHeader>
        <ContentHeaderTitle>
          <FormattedMessage id={'scenes.courseList.sectionTitle'} />
        </ContentHeaderTitle>
        <ButtonLoaderWrap loading={createCoursesListMutation.isLoading}>
          <Button
            variant="contained"
            color="primary"
            className={styles.button}
            onClick={handleCreateCoursesList}
            disabled={createCoursesListMutation.isLoading}
          >
            <FormattedMessage id="scenes.courseList.createNew" />
          </Button>
        </ButtonLoaderWrap>
        <Table className={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage id="table.filename" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="table.createdAt" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="table.actions" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {coursesListQuery.data && coursesListQuery.data.map((file) => (
              <TableRow key={file.filename}>
                <TableCell>{file.filename}</TableCell>
                <TableCell>{moment(file.created_at).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                <TableCell>
                  <ButtonLoaderWrap loading={downloadCoursesListMutation.isLoading}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={styles.button}
                      onClick={() => handleDownload(file.filename)}
                      disabled={downloadCoursesListMutation.isLoading}
                    >
                      <FormattedMessage id="buttons.download" />
                    </Button>
                  </ButtonLoaderWrap>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ContentHeader>
    </>
  );
};

export default CourseListTable;