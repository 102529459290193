import React from 'react';
import { makeStyles } from '@material-ui/core';
import randomColor from 'randomcolor';

const useStyles = makeStyles({
  item: {
    width: 4,
    marginRight: 2,
    marginBottom: -1,
  },
});

interface Props {
  adjustmentId: -1 | number;
  isOriginBar?: boolean;
  height?: number;
}

function AdjustmentsInheritanceBar({
  adjustmentId,
  isOriginBar = false,
  height,
}: Props) {
  const classes = useStyles();
  const backgroundColor = adjustmentId !== -1 ? randomColor({ seed: adjustmentId }) : undefined;
  let marginTop: number = 0;

  if (isOriginBar) {
    marginTop = 12;
  }

  return (
    <span
      className={classes.item}
      style={{
        backgroundColor,
        marginTop,
        height,
      }}
    />
  );
}

export default AdjustmentsInheritanceBar;