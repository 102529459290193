import React, { PureComponent, ReactNode, Fragment, ChangeEvent } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Button,
  FormControl,
  TextField,
} from '@material-ui/core';
import Select from '@src/components/forms/Select';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import {
  ContentHeader,
  ContentHeaderTitle,
  ContentWrap,
  SearchWrapper,
  SearchActions,
  ContentLoader
} from '@src/components/layouts/ui';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import * as clubFeesActions from '@src/store/clubFees/actions';
import { DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import { ClubFeeModal } from '@src/components/scenes/club/fees/components/ClubFeeModal';
import { Pagination } from '@src/components/pagination/Pagination';
import { handleSearchFieldOnKeyDownEnterSniff } from '@src/utils/storeUtils';
import { EditIconButton } from '@src/components/buttons/buttons';

interface Props extends RouteComponentProps<any>, WrappedComponentProps {
  clubFees: ClubFeesState;
  fetchClubFees: (params: FetchClubFees) => any;
  changePage: (params: ChangePageAction) => any;
  searchChanged: (params: ClubFeeSearch) => any;
  fetchSearch: (params: ClubSearchPaginationAction) => any;
  resetSearch: (clubId: number) => any;
}

type State = {
  modalOpen: boolean;
  clubFeeIdToEdit?: number;
};

class ClubFees extends PureComponent<Props, State> {
  state = {
    modalOpen: false,
    clubFeeIdToEdit: undefined
  };

  UNSAFE_componentWillMount() {
    const {
      match: {
        params
      },
      fetchClubFees,
    } = this.props;

    const { clubId } = params;
    fetchClubFees({ clubId, page: DEFAULT_PAGINATION_PAGE });
  }

  render() {
    const { modalOpen, clubFeeIdToEdit } = this.state;
    const {
      clubFees: {
        requesting
      },
      match: {
        params: {
          clubId
        }
      },
    } = this.props;

    return (
      <Fragment>
        <HeadHelmet titleId={'navigation.golfClubPayments'} />
        <ContentWrap>
          <ContentHeader>
            <ContentHeaderTitle>
              <FormattedMessage id={'navigation.golfClubPayments'}/>
            </ContentHeaderTitle>
            <Button variant="contained" onClick={() => this.setState({clubFeeIdToEdit: undefined, modalOpen: true})}>
              <FormattedMessage id={'scenes.golfClub.fees.addNewButtonLabel'}/>
            </Button>
            {this._renderPaymentSearch()}
          </ContentHeader>

          <ContentLoader visible={requesting}/>
          {this._renderPayments()}
          {this._renderPagination()}

          <ClubFeeModal
            clubId={clubId}
            clubFeeId={clubFeeIdToEdit}
            open={modalOpen}
            onClose={() => {
              this.setState({modalOpen: false});
            }}
          />
        </ContentWrap>
      </Fragment>
    );
  }

  private _renderPayments = (): ReactNode => {
    const {
      clubFees: {
        requesting,
        successful,
        pagedFees,
        pagination: {
          page
        }
      }
    } = this.props;

    if (requesting) {
      return null;
    }

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><FormattedMessage id={'scenes.golfClub.fees.table.headers.nro'} /></TableCell>
            <TableCell><FormattedMessage id={'scenes.golfClub.fees.table.headers.name'} /></TableCell>
            <TableCell><FormattedMessage id={'strings.price'} /></TableCell>
            <TableCell><FormattedMessage id={'strings.state'} /></TableCell>
            <TableCell/>
          </TableRow>
        </TableHead>
        <TableBody>
          {successful && pagedFees && Object.keys(pagedFees[page]).map((key: string) => (
          <TableRow key={pagedFees[page][key].id}>
            <TableCell>{pagedFees[page][key].id}</TableCell>
            <TableCell>{pagedFees[page][key].name}</TableCell>
            <TableCell>{pagedFees[page][key].price} €</TableCell>
            <TableCell>{pagedFees[page][key].status}</TableCell>
            <TableCell size={'small'}>
              <EditIconButton
                onClick={() => this.setState({clubFeeIdToEdit: pagedFees[page][key].id, modalOpen: true})}
              />
            </TableCell>
          </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  private _renderPaymentSearch = (): ReactNode => {
    const {
      clubFees: {
        requesting,
        search,
        searchActive
      },
    } = this.props;

    const disableSearch: boolean = this._disableSearch();

    return (
      <SearchWrapper>
        <FormControl disabled={requesting}>
          <TextField
            disabled={requesting}
            label={<FormattedMessage id={'strings.searchTerm'} />}
            value={search.name}
            style={{ marginRight: '1em' }}
            onChange={this._searchTermChanged}
            onKeyDown={(e: React.KeyboardEvent<any>) =>
              handleSearchFieldOnKeyDownEnterSniff(e, this._performSearch, disableSearch)
            }
          />
        </FormControl>
        <FormControl>
          <Select
            disabled={requesting}
            selected={search.status}
            options={[
              {
                id: 'INACTIVE',
                name: this.props.intl.formatMessage({ id: 'strings.statusInactive' }),
              },
              {
                id: 'ACTIVE',
                name: this.props.intl.formatMessage({ id: 'strings.statusActive' }),
              }
            ]}
            onChangeCallback={(value) => {
              this._searchStatusChanged(value || '');
            }}
            labelText={<FormattedMessage id={'scenes.golfClub.fees.search.state'}/>}
            placeholderText={<FormattedMessage id={'scenes.golfClub.fees.search.state'}/>}
          />
        </FormControl>
        <SearchActions
          isSearchActive={searchActive}
          isApiRequestActive={requesting}
          performSearchAction={this._performSearch}
          resetSearchAction={this._resetSearch}
          isSearchDisabled={disableSearch}
        />
      </SearchWrapper>
    );
  }

  private _searchTermChanged = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { searchChanged } = this.props;
    const {
      currentTarget: {
        value,
      }
    } = e;

    searchChanged({
      name: value
    });
  }

  private _searchStatusChanged = (value: any) => {
    const { searchChanged } = this.props;
    const status: ActivityStatus = value ? value : undefined;
    searchChanged({
      status
    });
  }

  private _disableSearch = (): boolean => {
    const {
      clubFees: {
        requesting,
        search: {
          name,
          status
        }
      }
    } = this.props;

    return (requesting || (name === '' && status === ''));
  }

  private _performSearch = () => {
    const {
      fetchSearch,
      clubFees: {
        search
      },
      match: {
        params
      },
    } = this.props;

    const { clubId } = params;

    fetchSearch({
      clubId: clubId,
      page: DEFAULT_PAGINATION_PAGE,
      params: {...search }
    });
  }

  private _resetSearch = () => {
    const {
      resetSearch,
      match: {
        params
      }
    } = this.props;

    const { clubId } = params;
    resetSearch(clubId);
  }

  private _renderPagination = (): ReactNode => {
    const {
      clubFees: {
        pagination: {
          page,
          limit,
          totalCount
        }
      }
    } = this.props;

    if (totalCount > limit) {
      return <Pagination page={page} limit={limit} totalCount={totalCount} onPageChange={this._handlePageChange}/>;
    }

    return null;
  }

  private _handlePageChange = (page: number) => {
    const {
      clubFees: {
        pagedFees,
        searchActive,
        search
      },
      fetchClubFees,
      fetchSearch,
      changePage,
      match: {
        params
      },
    } = this.props;

    const { clubId } = params;

    if (pagedFees[page]) {
      changePage({ page });
    } else if (searchActive) {
      fetchSearch({ page, clubId: clubId, params: search });
    } else {
      fetchClubFees({ page, clubId });
    }
  }
}

export default injectIntl(withRouter(connect((state: StoreState) => ({
  clubFees: state.clubFeesReducer,
}), {
  fetchClubFees: clubFeesActions.fetchClubFees,
  changePage: clubFeesActions.changePage,
  searchChanged: clubFeesActions.searchChanged,
  fetchSearch: clubFeesActions.fetchSearch,
  resetSearch: clubFeesActions.resetSearch,
})(ClubFees)));