import React, { createRef, PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as clubFeeActions from '@src/store/clubFee/actions';
import {
  Dialog,
  DialogTitle,
  DialogContent
} from '@material-ui/core';
import { ModalContentOverlayLoader } from '@src/components/modals/ui';
import { ClubFeeForm } from '@src/components/scenes/club/fees/components/ClubFeeForm';

type Props = {
  clubFeeId: number | undefined;
  clubId: number;
  open: boolean;
  onClose: () => void;
  getClubFee: (params: ClubFeeGet) => any;
  clubFeeState: ClubFeeState;
};

class FeeModalConnected extends PureComponent<Props> {
  actionsContainerRef = createRef<HTMLDivElement>();

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      clubFeeId,
      getClubFee
    } = this.props;

    if (nextProps.clubFeeId !== clubFeeId) {
      if (nextProps.clubFeeId) {
        getClubFee({
          clubId: nextProps.clubId,
          clubFeeId: nextProps.clubFeeId
        });
      }
    }
  }

  render() {
    const {
      open,
      clubId,
      clubFeeId,
      onClose,
      clubFeeState: {
        requesting
      }
    } = this.props;

    return (
      <Dialog
        open={open}
        fullWidth={true}
      >
        <DialogTitle>
          {clubFeeId && <FormattedMessage id={'scenes.golfClub.fees.modal.edit.title'} />}
          {!clubFeeId && <FormattedMessage id={'scenes.golfClub.fees.modal.add.title'} />}
        </DialogTitle>
        <DialogContent>
          {requesting && <ModalContentOverlayLoader/>}
          <ClubFeeForm
            clubId={clubId}
            clubFeeId={clubFeeId}
            parentContext={'MODAL'}
            onClose={onClose}
            actionsContainerRef={this.actionsContainerRef}
          />
        </DialogContent>

        <div ref={this.actionsContainerRef} />
      </Dialog>
    );
  }
}

const ClubFeeModal = connect((state: any) => ({
  clubFeeState: state.clubFeeReducer
}), {
  getClubFee: clubFeeActions.getClubFee
})(FeeModalConnected);

export {
  ClubFeeModal
};
