import React from 'react';
import FunctionaryTitlesScene from '@src/components/scenes/functionary-titles/FunctionaryTitlesScene';
import { connect } from 'react-redux';
import { getActiveFederationId } from '@src/store/auth/selectors';

const FederationFunctionaryTitles = ({
  federationId,
}: { federationId: number }) => {
  return (
    <FunctionaryTitlesScene targetIdParams={{ federationId }}/>
  );
};

export default connect((state: StoreState) => ({
  federationId: getActiveFederationId(state),
}), null)(FederationFunctionaryTitles);