import { fork, take } from 'redux-saga/effects';
import { AUTH_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import {
  CLUB_FEE_ADD_REQUESTING,
  CLUB_FEE_DELETE_REQUESTING,
  CLUB_FEE_EDIT_REQUESTING,
  CLUB_FEE_GET_REQUESTING
} from './constants';
import { CLUB_FEES_RESET } from '@src/store/clubFees/constants';

/*
  GET|POST|PUT|DELETE /admin/1/club/<clubId>/clubfee/<clubFeeId>
 */
const clubFeeCRUDFlow = (clubId: number) => {
  return SagaFlowFactory.createCRUDFlow({
    apiRoute: `admin/1/club/${clubId}/clubfee`,
    typePrefix: 'CLUB_FEE',
    resetType: CLUB_FEES_RESET,
    options: {
      resetParams: {
        clubId: clubId
      }
    }
  });
};

export default function * clubFeeWatcher() {
  let token: string = '';

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
      clubFeeId,
      clubId,
      params
    } = yield take([
      CLUB_FEE_GET_REQUESTING,
      CLUB_FEE_ADD_REQUESTING,
      CLUB_FEE_EDIT_REQUESTING,
      CLUB_FEE_DELETE_REQUESTING
    ]);

    switch (type) {
      case CLUB_FEE_GET_REQUESTING:
        yield fork(clubFeeCRUDFlow(clubId), token, 'GET', clubFeeId, params);
        break;
      case CLUB_FEE_ADD_REQUESTING:
        yield fork(clubFeeCRUDFlow(clubId), token, 'ADD', null, params);
        break;
      case CLUB_FEE_EDIT_REQUESTING:
        yield fork(clubFeeCRUDFlow(clubId), token, 'EDIT', clubFeeId, params);
        break;
      case CLUB_FEE_DELETE_REQUESTING:
        yield fork(clubFeeCRUDFlow(clubId), token, 'DELETE', clubFeeId, params);
        break;
      default:
    }
  }
}