export const PARTNER_GET_REQUESTING = 'PARTNER_GET_REQUESTING';
export const PARTNER_GET_SUCCESS = 'PARTNER_GET_SUCCESS';
export const PARTNER_GET_ERROR = 'PARTNER_GET_ERROR';

export const PARTNER_ADD_REQUESTING = 'PARTNER_ADD_REQUESTING';
export const PARTNER_ADD_SUCCESS = 'PARTNER_ADD_SUCCESS';
export const PARTNER_ADD_ERROR = 'PARTNER_ADD_ERROR';

export const PARTNER_EDIT_REQUESTING = 'PARTNER_EDIT_REQUESTING';
export const PARTNER_EDIT_SUCCESS = 'PARTNER_EDIT_SUCCESS';
export const PARTNER_EDIT_ERROR = 'PARTNER_EDIT_ERROR';

export const PARTNER_DELETE_REQUESTING = 'PARTNER_DELETE_REQUESTING';
export const PARTNER_DELETE_SUCCESS = 'PARTNER_DELETE_SUCCESS';
export const PARTNER_DELETE_ERROR = 'PARTNER_DELETE_ERROR';