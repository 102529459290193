import { take, fork, select } from 'redux-saga/effects';
import {
  MAINTENANCES_CONDENSED_SUCCESS,
  MAINTENANCES_ERROR,
  MAINTENANCES_REQUESTING,
  MAINTENANCES_RESET,
  MAINTENANCES_SUCCESS,
} from './constants';
import { API_ROOT, APIRoute, DEFAULT_PAGINATION_LIMIT, DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import { AUTH_SET } from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';
import { LOGIN_SUCCESS } from '@src/store/login/constants';
import { userIsFederationAdmin } from '@src/store/auth/selectors';
import {
  MAINTENANCE_ADD_SUCCESS,
  MAINTENANCE_DELETE_SUCCESS,
  MAINTENANCE_EDIT_SUCCESS
} from '@src/store/maintenance/constants';

/*
  GET /admin/1/maintenances
 */
const fetchMaintenancesFlow = SagaFlowFactory.createPagedGETFlow({
  apiRoute: APIRoute.GET_MAINTENANCES,
  itemType: 'maintenances',
  successType: MAINTENANCES_SUCCESS,
  errorType: MAINTENANCES_ERROR
});

/*
  GET /admin/1/maintenances?type=condensed
 */
const fetchMaintenancesCondensed = SagaFlowFactory.createGETFlow({
  urlRoute: `${API_ROOT}${APIRoute.GET_MAINTENANCES}`,
  successType: MAINTENANCES_CONDENSED_SUCCESS,
  errorType: MAINTENANCES_ERROR,
});

function* maintenancesWatcher() {
  let token: string = '';
  let isAdmin: boolean = false;

  yield fork(function* () {
    const {
      user
    } = yield take(AUTH_SET);
    token = user.token;
    isAdmin = yield select(userIsFederationAdmin);
  });

  while (true) {
    const {
      type,
      page,
      limit,
      params
    } = yield take([
      MAINTENANCES_REQUESTING,
      LOGIN_SUCCESS,
      MAINTENANCES_RESET,
      MAINTENANCE_ADD_SUCCESS,
      MAINTENANCE_EDIT_SUCCESS,
      MAINTENANCE_DELETE_SUCCESS,
    ]);

    switch (type) {
      case LOGIN_SUCCESS:
      case MAINTENANCE_ADD_SUCCESS:
      case MAINTENANCE_EDIT_SUCCESS:
      case MAINTENANCE_DELETE_SUCCESS:
        if (isAdmin) {
          console.log(token);
          yield fork(fetchMaintenancesCondensed, token, 0, 0, {type: 'current'});
        }
        break;
      case MAINTENANCES_REQUESTING:
        yield fork(fetchMaintenancesFlow, token, page, limit, { ...params });
        break;
      case MAINTENANCES_RESET:
        yield fork(fetchMaintenancesFlow, token, DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_LIMIT);

        break;
      default:
    }
  }
}

export default maintenancesWatcher;