import { take, fork } from 'redux-saga/effects';
import {
    REGIONS_REQUESTING,
    REGIONS_SUCCESS,
    REGIONS_ERROR,
} from './constants';
import { API_ROOT, APIRoute } from '@src/assets/config';
import {
  AUTH_SET,
} from '@src/store/auth/constants';
import { SagaFlowFactory } from '@src/utils/SagaFlowFactory';

/*
  GET /admin/1/regions
 */
const regionsFetchFlow = SagaFlowFactory.createGETFlow({
  apiRoute: APIRoute.GET_REGIONS,
  successType: REGIONS_SUCCESS,
  errorType: REGIONS_ERROR,
});

const fetchRegionsCondensed = SagaFlowFactory.createGETFlow({
    urlRoute: `${API_ROOT}${APIRoute.GET_REGIONS}`,
    successType: REGIONS_SUCCESS,
    errorType: REGIONS_ERROR,
});
function* regionsWatcher() {
  let token: string = '';

  yield fork(function* () {
    const {
      user,
    } = yield take(AUTH_SET);
    token = user.token;
  });

  while (true) {
    const {
      type,
    } = yield take([
        REGIONS_REQUESTING,
    ]);

    switch (type) {
      case REGIONS_REQUESTING:
        yield fork(
          regionsFetchFlow,
          token,
        );
        break;

      default:
        console.log('regions/sagas regionsWatcher: default case');
        yield fork(
          fetchRegionsCondensed, 
          token
          );
        break;
    }
  }
}

export default regionsWatcher;