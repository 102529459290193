import {
  PARTNERS_REQUESTING,
  PARTNERS_SUCCESS,
  PARTNERS_ERROR,
  PARTNERS_CHANGE_PAGE,
  PARTNERS_CONDENSED_SUCCESS,
  PARTNERS_RESET,
} from './constants';
import { SHARED_DEFAULT_REDUCER_STATE } from '@src/assets/config';
import {
  formDefaultErrorState,
  formDefaultPagedState,
  formDefaultRequestingState,
  fromDefaultPageChangeState,
  optimisticPagedObjectUpdate
} from '@src/utils/storeUtils';
import { PARTNER_EDIT_SUCCESS } from '@src/store/partner/constants';

const initialState: PartnersState = {
  ...SHARED_DEFAULT_REDUCER_STATE,
  pagedPartners: {},
  allPartners: [],
};

const partnersReducer = (state: PartnersState = initialState, action: any): PartnersState => {
  switch (action.type) {
    case PARTNERS_REQUESTING:
      return formDefaultRequestingState(state);

    case PARTNERS_SUCCESS:
      return formDefaultPagedState(state, action, 'pagedPartners');

    case PARTNERS_CHANGE_PAGE:
      return fromDefaultPageChangeState(state, action);

    case PARTNERS_ERROR:
      return formDefaultErrorState(state, action);

    case PARTNERS_CONDENSED_SUCCESS: {
      return {
        ...state,
        allPartners: action.data.partners,
      };
    }

    case PARTNERS_RESET:
      return {
        ...initialState,
        requesting: true,
        targetIdParams: state.targetIdParams,
      };

    case PARTNER_EDIT_SUCCESS: {
      const {
        data,
      } = action;
      const page = state.pagination.page;

      const pagedPartners = optimisticPagedObjectUpdate(state.pagedPartners, page, { ...data });

      return {
        ...state,
        pagedPartners
      };
    }

    default:
      return state;
  }
};

export {
  partnersReducer,
};