import {
  MESSAGE_ADD_ERROR,
  MESSAGE_ADD_REQUESTING,
  MESSAGE_ADD_SUCCESS,
  MESSAGE_DELETE_ERROR,
  MESSAGE_DELETE_REQUESTING,
  MESSAGE_DELETE_SUCCESS,
  MESSAGE_EDIT_ERROR,
  MESSAGE_EDIT_REQUESTING,
  MESSAGE_EDIT_SUCCESS,
  MESSAGE_GET_ERROR,
  MESSAGE_GET_REQUESTING,
  MESSAGE_GET_SUCCESS,
} from '@src/store/message/constants';
import { formDefaultErrorState, formDefaultRequestingState } from '@src/utils/storeUtils';

const initialState: MessageState = {
  message: undefined,
  requesting: false,
  successful: false,
  error: [],
};

export const messageReducer = (state: MessageState = initialState, action: any) => {
  switch (action.type) {
    case MESSAGE_GET_REQUESTING:
      return {
        ...formDefaultRequestingState(state),
        message: undefined,
      };

    case MESSAGE_ADD_REQUESTING:
    case MESSAGE_DELETE_REQUESTING:
    case MESSAGE_EDIT_REQUESTING:
      return formDefaultRequestingState(state);

    case MESSAGE_GET_ERROR:
    case MESSAGE_ADD_ERROR:
    case MESSAGE_DELETE_ERROR:
    case MESSAGE_EDIT_ERROR:
      return formDefaultErrorState(state, action);

    case MESSAGE_GET_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
        message: action.data,
      };

    case MESSAGE_ADD_SUCCESS:
    case MESSAGE_DELETE_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
      };

    case MESSAGE_EDIT_SUCCESS:
      const { data } = action;
      return {
        ...state,
        requesting: false,
        successful: true,
        message: data,
      };

    default:
      return state;
  }
};