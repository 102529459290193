import React, { ChangeEvent, Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  TextField,
  Card,
  CardContent,
  CardActions
} from '@material-ui/core';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { ErrorMessage, LoginFooter, LoginPageWrapper, LogoContainer } from '@src/scenes/authentication/ui';
import { connect } from 'react-redux';
import { LoginRequest, loginRequest, fetchCurrentMaintenances } from '@src/store/login/actions';

import {
  formCustomImageURL,
  LOCALSTORAGE_ROUTE_NAME,
  LOCALSTORAGE_REMEMBER_ME_SET,
  LOCALSTORAGE_REMEMBER_ME_USER,
  URL_HEADER,
  URL_HEADER_2X,
  URL_LOGO,
  URL_LOGO_2X,
  isCustomContext
} from '@src/assets/config';
import Collapse from '@material-ui/core/Collapse';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { handleSearchFieldOnKeyDownEnterSniff } from '@src/utils/storeUtils';
import LanguageSwitcherMenu from '@src/components/menu/LanguageSwitcherMenu';
import withStyles from '@material-ui/core/styles/withStyles';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { GGBOfficeBox, GGBOfficeBox2x } from '@src/assets/images';
import { isClubScope, isPartnerScope } from '@src/components/access-control';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { unsetPersistedRedirectRoute } from '@src/utils/authUtils';
import { Alert, AlertTitle } from '@material-ui/lab';

const TopLoginContainer = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1em;
`;

const styles = theme => ({
  languageSelect: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '1em'
  }
});

interface Props {
  doLogin: (reg: LoginRequest) => void;
  fetchCurrentMaintenances: () => any;
  login: LoginState;
  locale: AppLocale;
  auth: AuthState;
  classes: any;
}

interface State {
  email: string;
  password: string;
  rememberMe: boolean;
  requesting: boolean;
}

class LoginRouted extends Component<Props & RouteComponentProps<any>, State> {

  constructor(props: Props & RouteComponentProps<any>) {
    super(props);

    this.state = {
      email: '',
      password: '',
      rememberMe: false,
      requesting: false
    };
  }

  UNSAFE_componentWillMount(): void {
    const rememberMe = localStorage.getItem(LOCALSTORAGE_REMEMBER_ME_SET);
    const email = localStorage.getItem(LOCALSTORAGE_REMEMBER_ME_USER);

    const { fetchCurrentMaintenances } = this.props;

    fetchCurrentMaintenances();

    console.log(this.props.login);

    this.setState({
      requesting: true,
      email: email ? email : '',
      rememberMe: rememberMe && rememberMe === 'true' ? true : false
    });
  }

  render() {
    const {
      classes,
      login: {
        successful,
        requesting,
        errors
      },
      auth: {
        activeState
      },
    } = this.props;
    const { password, email, rememberMe } = this.state;

    if (successful) {
      try {
        const path = window.localStorage.getItem(LOCALSTORAGE_ROUTE_NAME);

        if (path) {
          unsetPersistedRedirectRoute();
          return this._handlePathRedirect(path);
        } else {
          return this._handleRoleRedirect(activeState);
        }
      } catch (e) {
        return this._handleRoleRedirect(activeState);
      }
    }

    const disableLogin = requesting || !(password !== '' && email !== '');

    return (
        <Fragment>
          {this._renderCustomTopBar(classes.languageSelect)}
          <LoginPageWrapper>
            <HeadHelmet titleId={'navigation.login'} />
            <LogoContainer>
              {this._renderCustomLogo()}
            </LogoContainer>
            <Card style={{padding: '1em'}}>
              <CardContent>
                <form action="login">
                  <TextField
                      name={'email'}
                      disabled={requesting}
                      fullWidth={true}
                      label={<FormattedMessage id={'login.emailFieldLabel'} />}
                      value={this.state.email}
                      onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        const {
                          currentTarget: {
                            value
                          }
                        } = e;
                        this.setState({ email: value });
                      }}
                      onKeyDown={(e: React.KeyboardEvent<any>) =>
                          handleSearchFieldOnKeyDownEnterSniff(e, this._handleLogin, disableLogin)
                      }
                  />
                  <TextField
                      style={{marginTop: '1em'}}
                      name={'password'}
                      disabled={requesting}
                      fullWidth={true}
                      label={<FormattedMessage id={'login.passwordFieldLabel'} />}
                      type={'password'}
                      value={this.state.password}
                      onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        const {
                          currentTarget: {
                            value
                          }
                        } = e;
                        this.setState({ password: value });
                      }}
                      onKeyDown={(e: React.KeyboardEvent<any>) =>
                          handleSearchFieldOnKeyDownEnterSniff(e, this._handleLogin, disableLogin)
                      }
                  />
                </form>
              </CardContent>
              <CardActions style={{justifyContent: 'space-between', display: 'flex'}}>
                <FormControlLabel
                    label={(<FormattedMessage id={'login.remember.me'}/>)}
                    control={
                      <Switch
                          checked={rememberMe}
                          onChange={(event) => {
                            this.setState({rememberMe: event.target.checked});
                          }}
                          color={'primary'}
                      />
                    }
                />
                <Button
                    disabled={password.length < 1 || requesting}
                    variant={'contained'}
                    color={'primary'}
                    onClick={this._handleLogin}
                >
                  <FormattedMessage id={'login.submitButtonLabel'}/>
                </Button>
              </CardActions>

              <Collapse
                  in={requesting}
                  timeout={'auto'}
                  unmountOnExit={true}
              >
                <div style={{ margin: '2em 0', textAlign: 'center'}}>
                  <CircularProgress
                      size={28}
                      thickness={2}
                  />
                </div>
              </Collapse>

              <Collapse
                  in={errors && errors.length > 0}
                  timeout={'auto'}
                  unmountOnExit={true}
              >
                <CardContent>
                  <ErrorMessage>
                    <FormattedMessage id={this._getErrorMessageId(errors)} />
                  </ErrorMessage>
                </CardContent>
              </Collapse>

            </Card>

            {this._maybeRenderMaintenanceMessages()}

            <LoginFooter>
              <Button disabled={requesting} onClick={this._handleForgot}>
                <p>
                  <FormattedMessage id={'login.forgotPasswordButtonLabel'} />
                </p>
              </Button>
            </LoginFooter>
          </LoginPageWrapper>
        </Fragment>
    );
  }
  private _maybeRenderMaintenanceMessages = () => {

    const {
      login: {
        currentMaintenances
      },
      locale: {
        appLanguage
      }
    } = this.props;

    const render = currentMaintenances.map((message) => (
        <Alert key={message.id} severity={'warning'} style={{ margin: '20px 0px' }}>
          <AlertTitle>{message[appLanguage.langName].title}</AlertTitle>
          {message[appLanguage.langName].message}
        </Alert>
    ));

    return (currentMaintenances.length > 0 && render);
  }

  /**
   * This function renders language selection or GGB logo and language selection depending are we
   * in GGB or customized context.
   *
   * @private
   */
  private _renderCustomTopBar = (languageSelect: any) => {
    // If content will be customized, make GGB Office logo visible in top-left corner
    if (isCustomContext) {
      return (
          <TopLoginContainer>
            <NavLink to={'/'}>
              <img src={GGBOfficeBox} srcSet={`${GGBOfficeBox2x} 2x`} height={32} />
            </NavLink>
            <LanguageSwitcherMenu/>
          </TopLoginContainer>
      );
    }
    return (
        <div className={languageSelect}>
          <LanguageSwitcherMenu/>
        </div>
    );
  }

  /**
   * This function renders GGB logo or custom logo depending are we
   * in GGB or customized context.
   *
   * Custom logo dimensions are 130x130.
   * GGB Office header is 265x65.
   *
   * @private
   */
  private _renderCustomLogo = () => {
    if (isCustomContext) {
      return (
          <img
              src={formCustomImageURL(URL_LOGO)}
              srcSet={`${formCustomImageURL(URL_LOGO_2X)} 2x`}
              width={130}
              height={130}
          />
      );
    }
    return (
        <img
            src={formCustomImageURL(URL_HEADER)}
            srcSet={`${formCustomImageURL(URL_HEADER_2X)} 2x`}
            // width={265}
            height={65}
        />
    );
  }

  private _handleForgot = () => {
    const {
      history
    } = this.props;

    history.push('/reset-password');
  }

  private _handleLogin = () => {
    const {
      doLogin
    } = this.props;
    const {
      email,
      password,
      rememberMe
    } = this.state;

    localStorage.setItem(LOCALSTORAGE_REMEMBER_ME_SET, rememberMe ? 'true' : 'false');
    localStorage.setItem(LOCALSTORAGE_REMEMBER_ME_USER, rememberMe && email ? email : '');

    doLogin({email, password});
  }

  private _getErrorMessageId = (errors): string => {
    if (errors && errors[0] && errors[0].withToken) {
      return 'login.errorWithToken';
    }

    return 'login.error';
  }

  private _handlePathRedirect = (path: string) => {
    if (path) {
      return <Redirect to={path}/>;
    } else {
      const { activeState } = this.props.auth;
      return this._handleRoleRedirect(activeState);
    }
  }

  private _handleRoleRedirect = (activeState: AuthActiveState | undefined) => {
    if (activeState) {
      const {
        roleInfo,
        club,
      } = activeState;

      if (isClubScope(roleInfo) && club) {
        return <Redirect to={`/admin/home-club/${club.id}`}/>;
      } else if (isPartnerScope(roleInfo)) {
        return <Redirect to={`/admin/offers`}/>;
      }
    }

    return <Redirect to={`/admin/federation/basic-info`}/>;
  }
}

const Login = connect((state: any) => ({
  login: state.loginReducer,
  auth: state.authReducer,
  locale: state.locale,
}), {
  doLogin: loginRequest,
  fetchCurrentMaintenances: fetchCurrentMaintenances
})(withRouter(withStyles(styles as any)(LoginRouted)));

export {
  Login,
};
